import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import envConfig from "../../../envConfig";

const uploadBatchFile = async ({
  workspaceid,
  filename,
  type,
  token,
  file,
  userid,
  config,
}) => {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", token);
  var formdata = new FormData();
  formdata.append("filename", file.files[0], filename);
  formdata.append("workspaceid", workspaceid);
  formdata.append("type", type);
  formdata.append("userid", userid);
  formdata.append(
    "config",
    JSON.stringify({ ...config, fileUploadRequired: true })
  );
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return fetch(
    `${envConfig.REACT_APP_REST_ENDPOINT}/documents/uploadBatchParsing`,
    requestOptions
  );
};

export default {
  uploadBatchFile,
};
