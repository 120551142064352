import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import envConfig from "../../../envConfig";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";

const getAvailableLanguages = async () => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/languages/getAvailableLanguages`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

export default {
  getAvailableLanguages,
};
