import API from "@aws-amplify/api";
import {
  createHosting,
  deleteHosting,
  updateHosting,
} from "../../graphql/mutations";
import { hostingsByWorkspace, getHosting } from "../../graphql/queries";
const createHosting_ = async (object) => {
  return API.graphql({
    query: createHosting,
    variables: { input: object },
  });
};
const getHosting_ = async (id) => {
  return API.graphql({
    query: getHosting,
    variables: { id },
  });
};
const updateHosting_ = async (id, _properties = {}) => {
  const original = await getHosting_(id);
  return API.graphql({
    query: updateHosting,
    variables: {
      input: {
        id,
        _version: original?.data?.getHosting?._version,
        ..._properties,
      },
    },
  });
};

const deleteHosting_ = async (id) => {
  const original = await getHosting_(id);
  return API.graphql({
    query: deleteHosting,
    variables: {
      input: {
        id,
        _version: original?.data?.getHosting?._version,
      },
    },
  });
};

const getHostings_ = async (workspaceID, nextToken) => {
  return API.graphql({
    query: hostingsByWorkspace,
    variables: {
      workspaceID,
      nextToken,
    },
  });
};

export default {
  createHosting: createHosting_,
  updateHosting: updateHosting_,
  deleteHosting: deleteHosting_,
  getHosting: getHosting_,
  getHostings: getHostings_,
};
