import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isLargeScreen } from "../../../MainViewContainer";
import QaSettingsAIView from "./SubComponents/QaSettingsAIView";
import Agents from "../../../../API/Agents";
import useWorkspaces from "../../../../Hooks/useWorkspaces";

const QaSettingsAI = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onMappingCreated,
      selectedId,
      handleGenerationItemSave,
      selectedIndex,
      setShowQaRoleModal,
      displayedRoles,
      availableFunctions,
      isChatBot,
      isBusinessAnalyst,
      isContentCreator,
      customCodeExecutionEnvironments,
      agentsChannels,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();
    const { getActiveWorkspace } = useWorkspaces();
    const { state, dispatch } = AppStateManager.useAppStateManager();
    const validationSchema = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      label: AppForms.yup
        .string()
        .required("The agent name is required")
        .label()
        .max(50),
      interpolationString: AppForms.yup.string().label().max(4096).nullable(),
      customToolingInstructions: AppForms.yup
        .string()
        .label()
        .max(4096)
        .nullable(),
      description: AppForms.yup.string().label().max(255).nullable(),
      temperature: AppForms.yup.number().required().min(0.1).max(1).label(),
      qaUrl: AppForms.yup.string().label().nullable(),
      subject: AppForms.yup.string().label().max(50),
      internetSearchLocation: AppForms.yup.string().label().max(100).nullable(),
      goals: AppForms.yup.string().required().label().max(2048),
      pertinencePassage: AppForms.yup.string().label().max(255).nullable(),
      inhibitionPassage: AppForms.yup.string().label().max(255).nullable(),
      showAgentName: AppForms.yup.bool().label().nullable(),
      maxTokens: AppForms.yup
        .number()
        .required()
        .label()
        .min(1)
        .max(
          getActiveWorkspace()?.isExtendedContentCreatorEnabled ? 13000 : 4096
        ),
      maxTopics: AppForms.yup.number().label().min(1).max(5),
      charting: AppForms.yup.bool().label().nullable(),
      icon: AppForms.yup.string().label().nullable(),
      largeLogo: AppForms.yup.string().label().nullable(),
      voiceFileKey: AppForms.yup.string().label().nullable(),
      icoUrl: AppForms.yup
        .string()
        .label()
        .nullable()
        .test(
          "is-domain",
          "Must be a valid url (e.g. https://mywebsite.com) with a .ico or .png extension",
          (value) => {
            if (!value) return true;
            const regex =
              /^https:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](?:\/[\w-]+)*\.(ico|png)$/i;
            return regex.test(value);
          }
        ),
      blender: AppForms.yup.bool().label().nullable(),
      hasTopicsContext: AppForms.yup.bool().label().nullable(),
      allowFeedback: AppForms.yup.bool().label().nullable(),
      agentsPool: AppForms.yup.array().max(10).label().nullable(),
      maxPlanningSteps: AppForms.yup.number().label().min(1).max(10).nullable(),
      plannerExecutionAttempts: AppForms.yup
        .number()
        .label()
        .min(1)
        .max(5)
        .nullable(),
      planningInstructions: AppForms.yup.string().label().max(4096).nullable(),
      reviewInstructions: AppForms.yup.string().label().max(2048).nullable(),
      preventWidgetUsage: AppForms.yup.bool().label().nullable(),
      plannerRequiresApprovalForPlanExecution: AppForms.yup
        .bool()
        .label()
        .nullable(),
      disclaimer: AppForms.yup.string().label().nullable().max(255),
      quickQuestions: AppForms.yup
        .string()
        .label()
        .nullable()
        .max(255)
        .test(
          "no-more-than-2-semicolon",
          "No more than 3 initial questions allowed",
          (value) => {
            if (value) {
              const semicolonCount = value.split(";").length - 1;
              return semicolonCount <= 2;
            }
            return true;
          }
        ),
      color: AppForms.yup
        .string()
        .matches(
          /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
          "Must be a valid hex color"
        )
        .nullable(),
      darkColor: AppForms.yup
        .string()
        .matches(
          /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
          "Must be a valid hex color"
        )
        .nullable(),
      logoBackground: AppForms.yup
        .string()
        .matches(
          /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
          "Must be a valid hex color"
        )
        .nullable(),
      darkLogoBackground: AppForms.yup
        .string()
        .matches(
          /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
          "Must be a valid hex color"
        )
        .nullable(),
      placeholderInputMessage: AppForms.yup.string().label().nullable().max(50),
      placeholderMessageOnLoading: AppForms.yup
        .string()
        .label()
        .nullable()
        .max(50),
      messageOnLaunch: AppForms.yup.string().label().nullable().max(255),
      // add allowed topics as array of strings with a max of 5 topics
      allowedTopics: AppForms.yup.array().label().max(10).nullable(),
      mode: AppForms.yup.string().required().label(),
      topK: AppForms.yup
        .number()
        .required()
        .label()
        .min(1)
        .max(isBusinessAnalyst ? 50 : 20),
      summarisation: AppForms.yup.bool().label(),
      compressor: AppForms.yup.bool().label().nullable(),
      docTopK: AppForms.yup
        .number()
        .label()
        .required()
        .min(1)
        .max(isBusinessAnalyst ? 50 : 20),
      recencyImportance: AppForms.yup.number().label().nullable().min(0).max(5),
      maxRecencyIncrease: AppForms.yup
        .number()
        .label()
        .nullable()
        .min(0)
        .max(1),
      recencyTopKIncreaseFactor: AppForms.yup
        .number()
        .label()
        .nullable()
        .min(0)
        .max(5),
      maxHistory: AppForms.yup.number().label().required().min(1).max(50),
      hasMemory: AppForms.yup.bool().label(),
      hasModeration: AppForms.yup.bool().label().nullable(),
      generateExtendedReport: AppForms.yup.bool().label().nullable(),
      minRetrievalScore: AppForms.yup
        .number()
        .label()
        .nullable()
        .min(0.01)
        .max(0.99),
      topicEnhancer: AppForms.yup.bool().label(),
      hasFunctions: AppForms.yup.bool().label().nullable(),
      hasImages: AppForms.yup.bool().label().nullable(),
      hasCode: AppForms.yup.bool().label().nullable(),
      allowImagesUpload: AppForms.yup.bool().label().nullable(),
      allowVideosUpload: AppForms.yup.bool().label().nullable(),
      allowAudiosUpload: AppForms.yup.bool().label().nullable(),
      allowDocsUpload: AppForms.yup.bool().label().nullable(),
      allowInternetSearch: AppForms.yup.bool().label().nullable(),
      allowImagesGeneration: AppForms.yup.bool().label().nullable(),
      allowDeepInternetSearch: AppForms.yup.bool().label().nullable(),
      internetSearchModes: AppForms.yup.array().label().nullable(),
      bannedDomains: AppForms.yup.array().label().nullable(),
      maxUrlsForInternetSearch: AppForms.yup
        .number()
        .label()
        .nullable()
        .min(1)
        .max(10),
      channelCallbackDelay: AppForms.yup
        .number()
        .label()
        .nullable()
        .min(0)
        .max(120),
      hasNER: AppForms.yup.bool().label().nullable(),
      showReferencesToInternalDocs: AppForms.yup.bool().label().nullable(),
      advancedLanguageDetection: AppForms.yup.bool().label().nullable(),
      agentFunctions: AppForms.yup.array().label().nullable(),
      keyWordsForKnowledgeBase: AppForms.yup.bool().label().nullable(),
      plainTextOutput: AppForms.yup.bool().label().nullable(),
      extendedOutput: AppForms.yup.bool().label().nullable(),
      showTimeForResponse: AppForms.yup.bool().label().nullable(),
      showDetectedLanguage: AppForms.yup.bool().label().nullable(),
      isMultiLanguageEnabled: AppForms.yup.bool().label().nullable(),
      restrictedAccess: AppForms.yup.bool().label().nullable(),
      restrictedAccessUsers: AppForms.yup.array().label().nullable(),
      showSplashWhenEmpty: AppForms.yup.bool().label().nullable(),
      splashTitle: AppForms.yup.string().label().nullable().max(50),
      allowAudiosGeneration: AppForms.yup.bool().label().nullable(),
      autoVoiceMode: AppForms.yup.bool().label().nullable(),
      promptTopKeywords: AppForms.yup
        .number()
        .nullable()
        .label()
        .min(1)
        .max(10),
      llmProvider: AppForms.yup.string().label().nullable(),
      llmBaseModel: AppForms.yup.string().label().nullable(),
      functionCallingProvider: AppForms.yup.string().label().nullable(),
      functionCallingModel: AppForms.yup.string().label().nullable(),
      customModerations: AppForms.yup
        .object()
        .label()
        .nullable()
        .test(
          "custom-moderations",
          "Custom Moderations must be up to 20 items with key and value as strings",
          (value) => {
            if (value && Object.keys(value) && Object.keys(value).length < 20) {
              // check if all keys are strings and all values are strings
              const keys = Object.keys(value);
              const values = Object.values(value);
              const allKeysAreStrings = keys.every(
                (key) => typeof key === "string"
              );
              const allValuesAreStrings = values.every(
                (value) => typeof value === "string"
              );
              return allKeysAreStrings && allValuesAreStrings;
            } else if (value == null) return true;
            else return false;
          }
        ),
    });

    const handleDeletion = async (item, configType) => {
      try {
        const roleToDelete = displayedRoles[selectedIndex];
        const deletionResult = await Agents.deleteAgent(roleToDelete?.id);
        const newAgents = state?.agents?.filter(
          (agent) => agent.id !== roleToDelete?.id
        );
        dispatch("SET_AGENTS", newAgents);
        setShowQaRoleModal(false);
        // navigateBack();
        AppToasty.show(t("qaDeletionSuccessCaption"), {
          type: "success",
        });
      } catch (error) {
        console.error("", error);
        AppToasty.show(t("qaDeletionFailedCaption"), {
          type: "danger",
        });
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };

    const _view = useMemo(() => (
      <AppFadeView
        style={{
          horizontalMargin: 10,
          paddingHorizontal: 20,
          minWidth: isLargeScreen() ? "100%" : 300,
          paddingBottom: 10,
          height: 500,
        }}
      >
        {backTo && (
          <AppMenu.DefaultBackOptionComponent
            navigateBack={navigateBack}
            sectionTitle={"Role : " + sectionTitle}
            isClickable
            isBackIconDisplayed
          />
        )}

        <AppForms.AppForm
          initialValues={initialMapValues}
          onSubmit={handleGenerationItemSave}
          validationSchema={validationSchema}
          enableReinitialize
          ref={ref}
        >
          <QaSettingsAIView
            isChatBot={isChatBot}
            initialMapValues={initialMapValues}
            availableFunctions={availableFunctions}
            customCodeExecutionEnvironments={customCodeExecutionEnvironments}
            handleDeletion={handleDeletion}
            isEdit={isEdit}
            settingType={settingType}
            isBusinessAnalyst={isBusinessAnalyst}
            isContentCreator={isContentCreator}
            agentsChannels={agentsChannels}
          />
        </AppForms.AppForm>
      </AppFadeView>
    ));
    return _view;
  }
);

export default QaSettingsAI;
