import useAPI from "@toothfairy/shared-api/useApi";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import axios from "axios";
import React, { useEffect, useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import envConfig from "../../../envConfig";
import appLogHelper from "../../API/AppLog/appLogHelper";
import Files from "../../API/Files";
import trainingAPI from "../../API/Training/trainingAPI";
import useFileSelector from "../../Hooks/useFileSelector";
import usePath from "../../Hooks/usePath";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory } from "../../Router";
import DocumentsScreen from "./DocumentsScreen";
import { initialEditorValue } from "../TestExample/commonUtils";
import downloader from "../Common/downloader";

const formValidationSchema = AppForms.yup.object().shape({
  importType: AppForms.yup.string().required().label("Data type"),
  fileType: AppForms.yup.string().required().label("File type"),
});

const initialFormValues = {
  importType: "TOPIC",
  fileType: "TXT",
};

const DocumentsScreenContainer = ({ navigation }) => {
  const routes = [];
  const {
    getActiveWorkspace,
    handleTabSelection,
    getAppLogs,
    getLogicalTabSelection,
    getPlanConfig,
    getWorkspaceEntities,
  } = useWorkspaces();
  routes.push({
    key: "generativeTraining",
    title: "Generative AI",
    routeId: "gn",
    shortTitle: "GN",
  });
  routes.push({
    key: "conversationalTraining",
    title: "Conversational AI",
    routeId: "cn",
    shortTitle: "CN",
  });
  routes.push({
    key: "nlpTraining",
    title: "Sentiment reviewer",
    routeId: "nlp",
    shortTitle: "SR",
  });

  const {
    data: createAPIResult,
    error: createAPIError,
    loading: createAPIInProgress,
    apiRequest: createAPIRequest,
    lastUpdate: lastCreationTime,
  } = useAPI(trainingAPI.postTrainingData, envConfig);
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const [showModal, setShowModal] = useState(false);
  const {
    handleFileSelect: handleFileSelectGer_,
    filePath: filePathGeneric,
    handleImports: handleImportsGeneric,
    isFileUploadInProgress,
    batchUploadStatus,
  } = useFileSelector({
    _importType: "imported-training-data",
    _importFile: "gen_ai_training",
    contentType: "gen_ai_training",
    additionalFilenameParams: ``,
    _appLogImportType: "documentImport",
    _appLogImportEvent: "bulkImportGenAi file import",
    showSuccessOnUpload: false,
    flat: false,
    createDocument: true,
    uploadOnSelection: true,
    showPreview: true,
    allowMultiple: true,
    onShowPreview: () => {
      setShowModal(true);
      setSelectedTopicsForImport(null);
    },
    onDocumentsCreated: (files) => {
      loadTrainingData();
      setShowModal(false);
    },
    onDocumentCreated: (file) => {
      loadTrainingData();
      setShowModal(false);
    },
  });
  const { params } = usePath();
  const history = useHistory();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isValidationOnly, setIsValidationOnly] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [topicsForImport, setSelectedTopicsForImport] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportInProgress, setIsImportInProgress] = useState(false);
  const [importCount, setImportCount] = useState(0);
  const [toImportCount, setToImportCount] = useState(1);
  const [importType, setImportType] = useState("TOPIC");
  const [importFile, setImportFile] = useState("TXT");
  const [fileSelector, SetFileSelector] = useState(null);
  const [items, setItems] = useState(state.aiEntitiesConfig);

  const { t } = useTranslation();
  const { user } = AppUser.useUser();
  const [NERs, setNERs] = useState(
    state?.aiEntitiesConfig?.filter((u) => u.type === "ner") || []
  );
  const [Intents, setIntents] = useState(
    state?.aiEntitiesConfig?.filter((u) => u.type === "intent") || []
  );
  const [textString, setTextString] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentIdString, setDocumentIdString] = useState("");
  const onTrainingDataChange = (data) => {};
  const AppToasty = AppToast.useToast();
  const {
    data: trainingData,
    error: trainingAPIError,
    loading: trainingAPIInProgress,
    apiRequest: fetchTraining,
    lastUpdate: lastTrainingDataUpdate,
    optimisticDataUpdate,
  } = useAPI(trainingAPI.getDocumentsByWorkspace, null, onTrainingDataChange);

  const {
    error: trainingDeletionError,
    loading: trainingDeletionInProgress,
    apiRequest: deleteTrainingData,
  } = useAPI(trainingAPI.deleteTrainingData);

  const {
    data: S3uploadUrlData,
    loading: urlUploadGenerationInProgress,
    apiRequest: S3uploadUrlRequest,
    response: S3uploadResponse,
  } = useAPI(trainingAPI.bulkImportUrlGeneration, envConfig);
  const mapTabWithRoute = () => {
    const type = getLogicalTabSelection(routes, "documents");
    switch (type) {
      case "nlpTraining":
        return "nlp";
      case "readComprehensionTraining":
        return "rc";
      case "generativeTraining":
        return "gn";
      case "conversationalTraining":
        return "cn";
      default:
        return "nlp";
    }
  };

  const { loading: UploadInProgress, apiRequest: S3uploadRequest } = useAPI(
    Files.bulkImportFileUpload,
    envConfig
  );
  const handleDocumentCreation = async (key) => {
    try {
      const trainingDataPostResult = await createAPIRequest({
        data: [
          {
            workspaceid: getActiveWorkspace()?.id,
            document: JSON.stringify(initialEditorValue),
            rawtext: "",
            trainingData: {},
            userID: user?.id,
            type: getLogicalTabSelection(routes, "documents"),
            status: "published",
            topics: [],
            scope: "training",
          },
        ],
      });
      AppToasty.show("Training document created", {
        placement: "top",
        type: "success",
      });
      setTimeout(() => {
        history.push(
          mapTabWithRoute() === "nlp"
            ? `/workspaces/${getActiveWorkspace()?.id}/${mapTabWithRoute()}/${
                trainingDataPostResult?.data?.[0]?.id
              }`
            : `/workspaces/${
                getActiveWorkspace()?.id
              }/documents/${mapTabWithRoute()}/${
                trainingDataPostResult?.data?.[0]?.id
              }`
        );
      }, [1000]);
    } catch (error) {
      console.error("Error while creating training data", error);
      AppToasty.show(t("TrainingDataCreationFailedCaption"), {
        placement: "top",
        type: "danger",
      });
    }
  };
  const onItemPress = (item) => {
    setSelectedItems(item);
  };
  const handleValidationOnly = (v) => {
    setIsValidationOnly(v);
  };
  const handleDocumentSelection = async ({ id, type, item }) => {
    const obj = state.trainingData?.data?.find((u) => u?.id == id);
    if (obj?.external_path && obj?.scope === "training")
      try {
        AppToasty.show(t("TrainingDataDownloadCaption"), {
          placement: "bottom",
          type: "success",
        });
        const result = await S3downloadUrlRequest({
          workspaceid: getActiveWorkspace()?.id,
          logId: null,
          type: obj?.external_path?.split(".").pop(),
          filename: obj?.external_path.replace(
            `s3://files-to-import-aus-${envConfig.REACT_APP_ENV}/`,
            ""
          ),
          context: "pdf",
        });

        downloader.download_files(
          [
            {
              download: result.url,
              filename: obj?.title,
            },
          ],
          true
        );
      } catch (error) {
        console.error("Training data download error", error);
        AppToasty.show(t("TrainingDataDownloadFailedCaption"), {
          placement: "bottom",
          type: "danger",
        });
      }
    else {
      if (type === "nlpTraining")
        history.push(`/workspaces/${state.activeWorkspaceID}/nlp/${id}`);
      else if (type === "readComprehensionTraining")
        history.push(
          `/workspaces/${state.activeWorkspaceID}/documents/rc/${id}`
        );
      else if (type === "generativeTraining")
        history.push(
          `/workspaces/${state.activeWorkspaceID}/documents/gn/${id}`
        );
      else if (type === "conversationalTraining")
        history.push(
          `/workspaces/${state.activeWorkspaceID}/documents/cn/${id}`
        );
    }
  };
  const handleChangeText = (text) => {
    setTextString(text);
  };
  const handleChangeDocumentIdString = (text) => {
    setDocumentIdString(text);
  };
  const handleDocumentDeletion = async (document) => {
    try {
      await deleteTrainingData(document.id, state.activeWorkspaceID);
      optimisticDataUpdate({
        ...state.trainingData,
        rows: state.trainingData?.data.filter((i) => i.id !== document.id),
      });
      getDocuments();
      AppToasty.show(t("DocumentDeletionCaption"), { type: "success" });
    } catch (error) {
      AppToasty.show(t("DocumentDeletionFailedCaption"), {
        type: "danger",
      });
    }
  };
  const onTopicCreated = () => {
    setTopics(
      getWorkspaceEntities("topic")?.map((topic) => {
        topic.name = topic?.label;
        topic.category = topic.type;
        return topic;
      })
    );
  };
  useEffect(() => {
    if (
      state.activeWorkspaceID &&
      !routes?.find((r) => r.key === "nlpTraining")
    )
      history.push(`/workspaces/${state.activeWorkspaceID}/documents/rc`);
  }, [routes?.length, state.activeWorkspaceID]);
  const getDocuments = (page) => {
    fetchTraining({
      workspaceID: state.activeWorkspaceID,
      page: page || currentPage,
      intents: selectedItems
        .filter((i) => i.category === "intent")
        .map((i) => i.label),
      entities: selectedItems
        .filter((i) => i.category === "ner")
        .map((i) => i.label),
      text: textString,
      topics: selectedItems
        .filter((i) => i.category === "topic")
        .map((i) => i.id),
      type:
        documentType ||
        getLogicalTabSelection(routes, "documents") ||
        "nlpTraining",
      documentIdString: documentIdString,
      scope: isValidationOnly ? "validation" : "training",
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    getDocuments();
  };

  const generateItems = (selectedItems) => {
    let newItems = [...items];
    if (newItems)
      for (let i = 0; i < newItems.length; i++) {
        let sItem = newItems[i];
        const sItemIndex = selectedItems.map((i) => i.id).indexOf(sItem.id);
        if (sItemIndex > -1) sItem.selected = true;
        else sItem.selected = false;
        newItems[sItemIndex] = sItem;
      }
    return newItems;
  };
  const onDeleteSelectedItem = (item) => {
    const indexOfItemInSelectedItems = selectedItems
      .map((i) => i.id)
      .indexOf(item.id);
    selectedItems.splice(indexOfItemInSelectedItems, 1);
    setSelectedItems([...selectedItems]);
  };
  useEffect(() => {
    if (selectedItems?.length > 0) setItems(generateItems(selectedItems));
  }, [selectedItems]);
  function buildFileSelector(importType, importFile) {
    const handleImport = async (file, importType, importFile) => {
      try {
        AppToasty.show(t("DocumentImportUploadStartedCaption"), {
          type: "success",
        });

        const _appLog = await appLogHelper.createAppLog({
          status: "dispatched",
          workspaceID: state?.activeWorkspaceID,
          type: "import",
          event: "BULK_IMPORT",
          filename: file.name,
          createdBy: user?.id,
        });
        const filename = `${state?.activeWorkspaceID}/${
          _appLog?.id
        }.${importFile?.toLowerCase()}`;

        const result = await S3uploadUrlRequest({
          filename,
          importType: importType?.toLowerCase(),
        });
        const upload = await axios.request({
          method: "put",
          url: JSON.parse(result?.body)?.uploadURL,
          data: file,
        });
      } catch (error) {
        AppToasty.show(t("DocumentImportUploadFailedCaption"), {
          type: "danger",
        });
        console.error("Upload result", error);
      }
    };

    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("single", "single");
    fileSelector.addEventListener("change", function (e) {
      handleImport(e?.target?.files[0], importType, importFile);
    });
    return fileSelector;
  }
  useEffect(() => {
    if (
      Platform.OS === "web" &&
      state?.activeWorkspaceID &&
      user?.id &&
      state?.appLogsReady
    ) {
      SetFileSelector(buildFileSelector(importType, importFile));
    }
  }, [
    importType,
    importFile,
    state?.activeWorkspaceID,
    user?.id,
    state?.appLogsReady,
  ]);
  useEffect(() => {
    setItems(state.aiEntitiesConfig);
    setNERs(state?.aiEntitiesConfig?.filter((u) => u.type === "ner") || []);
    setIntents(
      state?.aiEntitiesConfig?.filter((u) => u.type === "intent") || []
    );
  }, [JSON.stringify(state.aiEntitiesConfig)]);
  useEffect(() => {
    if (
      state.redirectToDoc &&
      state.redirectToDoc.type === "readComprehensionTraining"
    ) {
      history.push(
        `/workspaces/${state.activeWorkspaceID}/answerer/${state.redirectToDoc.id}`
      );
      dispatch("SET_REDIRECT_TO_DOC", null);
    }
  }, [state.redirectToDoc]);
  const loadTrainingData = async () => {
    await getDocuments(1);
  };
  const handleFileSelect = () => {
    if (Platform.OS === "web") {
      // e.preventDefault();
      fileSelector.value = null;
      fileSelector.click();
    } else {
      AppToasty.show(`Bulk import not available on ${Platform.OS}`, {
        type: "warning",
      });
    }
  };
  const handleSearch = () => {
    setCurrentPage(1);
    getDocuments(1);
  };
  const handleTabChange = (route) => {
    setSelectedItems([]);
    setDocumentType(route?.key);
    handleTabSelection(route, routes, "documents");
  };
  useEffect(() => {
    handleSearch();
  }, [documentType]);
  const handleFormSubmit = (v) => {
    setIsModalOpen(false);
    if (v.importType === "CONV" || v.importType === "TOPIC")
      handleFileSelectGer_();
    else handleFileSelect();
  };
  useEffect(() => {
    if (state?.activeWorkspaceID) loadTrainingData();
  }, [state?.activeWorkspaceID]);
  useEffect(() => {
    dispatch("SET_TRAINING_DATA", trainingData);
  }, [JSON.stringify(lastTrainingDataUpdate)]);
  const stopImportProcess = () => {
    const appLog = getAppLogs("import", "dispatched")[0];
    if (appLog) {
      appLogHelper.deleteAppLog(appLog?.id);
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <DocumentsScreen
        documentIdString={documentIdString}
        handleChangeDocumentIdString={handleChangeDocumentIdString}
        formValidationSchema={formValidationSchema}
        initialFormValues={initialFormValues}
        handleValidationOnly={handleValidationOnly}
        isValidationOnly={isValidationOnly}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        trainingData={state.trainingData}
        requestInProgress={
          trainingAPIInProgress ||
          trainingDeletionInProgress ||
          isImportInProgress
        }
        handleDocumentCreation={handleDocumentCreation}
        createAPIInProgress={createAPIInProgress}
        importProgress={`${AppUtilities.math.roundTo(
          (importCount / toImportCount) * 100,
          2
        )}%`}
        isImportEnabled={
          state?.appLogsReady && getAppLogs("import")?.length < 1
        }
        handleChangeOfImportType={(b) => setImportType(b)}
        handleChangeOfImportFile={(b) => setImportFile(b)}
        isImportInProgress={getAppLogs("import")?.length > 0}
        isImportStarted={getAppLogs("import", false, "dispatched")?.length > 0}
        onItemPress={onItemPress}
        stopImportProcess={stopImportProcess}
        onDeleteSelectedItem={onDeleteSelectedItem}
        selectedItems={selectedItems}
        items={items}
        NERStyles={NERs}
        IntentStyles={Intents}
        textString={textString}
        documentType={documentType}
        handleDocumentTypeChange={(v) => setDocumentType(v)}
        handleChangeText={handleChangeText}
        handleDocumentSelection={handleDocumentSelection}
        handleDocumentDeletion={handleDocumentDeletion}
        handleFileSelect={handleFileSelect}
        handleSearch={handleSearch}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleFormSubmit={handleFormSubmit}
        handleTabChange={handleTabChange}
        routes={routes}
        initialTabFromRouting={params.sectionId}
        handleImportsGeneric={() =>
          handleImportsGeneric(
            getLogicalTabSelection(routes, "documents"),
            null,
            "draft",
            topicsForImport,
            "generic",
            "gen_ai_training",
            "training",
            "dataImporter"
          )
        }
        isFileUploadInProgress={isFileUploadInProgress}
        batchUploadStatus={batchUploadStatus}
        filePathGeneric={filePathGeneric}
        showModal={showModal}
        setShowModal={setShowModal}
        topicsForImport={topicsForImport}
        setSelectedTopicsForImport={setSelectedTopicsForImport}
        onTopicCreated={onTopicCreated}
      />
    </Suspense>
  );
};

export default DocumentsScreenContainer;
