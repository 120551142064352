import React from "react";
import ToothFairyModal from "./ToothFairyModal";
import ToothFairyOutput from "./ToothFairyOutput";

const ToothFairyOutputModal = ({
  isModalOpen = false,
  setIsModalOpen,
  requestPayload,
  height = 500,
  ...props
}) => {
  return (
    <ToothFairyModal
      modalStyle={{
        height: "auto",
        margin: "auto",
        width: "95%",
        maxHeight: "99%",
        maxWidth: 900,
        marginLeft: "auto",
        marginRight: "auto",
      }}
      modalInnerContentStyle={{
        padding: 30,
        justifyContent: "space-evenly",
        gap: 15,
      }}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <ToothFairyOutput requestPayload={requestPayload} isInModal {...props} />
    </ToothFairyModal>
  );
};

export default ToothFairyOutputModal;
