import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";

const getWorkspace = ({ id }) =>
  API.graphql(graphqlOperation(queries.getWorkspace, { id }));

const getWorkspaceUsers = (id) =>
  API.graphql(graphqlOperation(queries.getUsersWorkspace, { id }));

const getPlanWorkspace = (id) =>
  API.graphql(graphqlOperation(queries.getPlanWorkspace, { id }));

export default {
  getWorkspace,
  getWorkspaceUsers,
  getPlanWorkspace,
};
