/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import envConfig from "../../envConfig";
const awsmobile = {
  aws_project_region: envConfig.REACT_APP_aws_region,
  aws_cognito_identity_pool_id:
    envConfig.REACT_APP_aws_cognito_identity_pool_id,
  aws_cognito_region: envConfig.REACT_APP_aws_region,
  aws_user_pools_id: envConfig.REACT_APP_aws_user_pools_id,
  aws_user_pools_web_client_id:
    envConfig.REACT_APP_aws_user_pools_web_client_id,
  oauth: {
    domain: envConfig.REACT_APP_aws_cognito_domain,
    scope: ["email", "openid", "profile"],
    redirectSignIn: envConfig.REACT_APP_redirect_signin_uri,
    redirectSignOut: envConfig.REACT_APP_redirect_signout_uri,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint: envConfig.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: envConfig.REACT_APP_aws_appsync_region,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: envConfig.REACT_APP_GRAPHQL_TOKEN,
};

export default awsmobile;
