import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React from "react";
import { Platform, View } from "react-native";
import ToothFairySupportButton from "../../../../Components/ToothFairyComponents/ToothFairySupportButton";
import ToothFairySwitch from "../../../../Components/ToothFairyComponents/ToothFairySwitch";
import Constants from "../../../../Constants";
import useSections from "../../../../Hooks/useSections";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen, minAppWidth } from "../../../MainViewContainer";


const AnalysisPreferencesNLP = ({ handleUpdate, showReplyLanguage = true }) => {
  const { currentTheme } = AppTheme.useTheme();
  const { marginLeftUnderAppExpander } = useSections();
  const { user } = AppUser.useUser();
  const { NLP_OPTIONS } = Constants;
  const { state } = AppStateManager.useAppStateManager();
  const { getActiveWorkspace } = useWorkspaces();
  return (
    <View
      style={{
        width:
          Platform.OS === "web" && isLargeScreen() ? 450 : minAppWidth() - 50,
        marginTop: 20,
        marginLeft: marginLeftUnderAppExpander,
      }}
    >
      {NLP_OPTIONS.map((u) => {
        return (
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "space-between",
              marginBottom: 30,
            }}
          >
            <ToothFairySwitch
              wrapperStyle={{
                flex: 1,
                marginRight: 20,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
              }}
              label={u.label}
              onChange={(v) =>
                handleUpdate("parsingConfig", {
                  ...getActiveWorkspace().parsingConfig,
                  [u?.logicalPropertyName]: v,
                })
              }
              isTicked={
                getActiveWorkspace()?.parsingConfig &&
                getActiveWorkspace()?.parsingConfig[u?.logicalPropertyName]
              }
            />
            <ToothFairySupportButton _pageId={34} />
          </View>
        );
      })}
      {showReplyLanguage && (
        <AppDropDown
          showTextInputLabel
          label="Reply Language"
          inputLabelStyle={{
            marginLeft: 0,
          }}
          dropDownStyle={{
            minWidth: 100,
            borderColor: currentTheme?.theme?.border_color,
            maxWidth: !isLargeScreen() && 250,
          }}
          isElevated={false}
          dropdownIconColor="transparent"
          items={[
            { id: "def", name: "Default to question language" },
            ...state?.languagesConfig,
          ]}
          propertyLabel="name"
          propertyValue="id"
          selectedValue={user.replyLanguage}
          onValueChange={(itemValue, itemIndex) => {
            handleUpdate("replyLanguage", itemValue);
          }}
        />
      )}
    </View>
  );
};

export default AnalysisPreferencesNLP;
