import API, { graphqlOperation } from "@aws-amplify/api";
import { DataStore } from "@aws-amplify/datastore";
import useAPI from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import { useCallback, useEffect, useState } from "react";
import { reservedConfigs, userType } from "../../App";
import envConfig from "../../envConfig";
import chatsHelper from "../API/Chats/chatsHelper";
import Generator from "../API/Generator";
import Constants from "../Constants";
import { useHistory } from "../Router";
import { getSubscriptionBaseConfig } from "../graphql/queries";
import { AppLog, UserType } from "../models";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import { generateRowId } from "../Utility";
import Prompts from "../API/Prompts";

const useWorkspaces = () => {
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { user } = AppUser.useUser();
  const history = useHistory();
  const AppToasty = AppToast.useToast();
  const [loadMoreChatsToken, setLoadMoreChatsToken] = useState(null);
  const [isChatHistoryLoaded, setIsChatHistoryLoaded] = useState(false);
  const [isWorkspaceEnabled, setIsWorkspaceEnabled] = useState(true);
  const {
    loading: generatorConfigInProgress,
    apiRequest: generatorConfigRequest,
  } = useAPI(Generator.getConfig, envConfig, true);

  const {
    loading: generatorUpdateConfigInProgress,
    apiRequest: generatorUpdateConfigRequest,
  } = useAPI(Generator.updateConfig, envConfig, true);

  const isWorkspaceDisabled = (workspace) => {
    return (
      workspace?.disabled === true ||
      (workspace?.activeUntil && new Date(workspace?.activeUntil) < Date.now())
    );
  };
  const isWorkspaceInCreation = (workspace) => {
    return !workspace?.activeUntil;
  };

  const returnCleanedWorkspaces = useCallback((workspaces, includeDisabled) => {
    return workspaces
      ?.sort(AppUtilities.sortBy("title"))
      ?.filter((u) =>
        includeDisabled
          ? u
          : isWorkspaceDisabled(u) === false ||
            isWorkspaceInCreation(u) === true
      );
  }, []);
  const getUserName = (id) => {
    return state?.workspaceUsers
      ? state?.workspaceUsers[
          state?.workspaceUsers.map((e) => e?.id).indexOf(id)
        ]?.username
      : "";
  };
  const canUpdateSubscription = () => {
    let canUpdateSubscription = true;
    if (getActiveWorkspace()?.lastChangeSubscriptionDate) {
      // Creating date object from the ISO string
      let isoDate = new Date(getActiveWorkspace()?.lastChangeSubscriptionDate);
      // Getting the current date
      let currentDate = new Date();
      // Comparing the months
      if (currentDate.getUTCMonth() === isoDate.getUTCMonth()) {
        canUpdateSubscription = false;
      }
    }
    return canUpdateSubscription;
  };
  const getDisabledWorkspaces = (workspaces) => {
    return workspaces?.filter(
      (u) =>
        isWorkspaceDisabled(u) === true && isWorkspaceInCreation(u) === false
    );
  };
  const getWorkspaceUsers = useCallback(() => {
    return state?.workspaceUsers;
  }, [state?.activeWorkspaceID, state?.workspaceUsers]);
  const handleStateUpdateFromSubMessage = (msg, stateProperty, dispatchFn) => {
    const objectIndex = state[stateProperty]?.findIndex(
      (log) => log.id === msg?.element?.id
    );
    switch (msg?.opType) {
      case "UPDATE":
        if (objectIndex >= 0) {
          const updatedProperty = [...state[stateProperty]];
          updatedProperty[objectIndex] = msg?.element;
          dispatch(dispatchFn, updatedProperty);
        }
        break;
      case "INSERT":
        dispatch(dispatchFn, [...state[stateProperty], msg?.element]);
        break;
      case "DELETE":
        if (objectIndex >= 0) {
          state[stateProperty].splice(objectIndex, 1);
          dispatch(dispatchFn, [...state[stateProperty]]);
        }
        break;
      default:
        break;
    }
  };
  const getActiveWorkspace = () => {
    return state?.workspaces?.filter(
      (u) => u?.id === state?.activeWorkspaceID
    )[0];
  };
  const getContentMultiplier = () => {
    return getActiveWorkspace()?.gnUrl?.includes("curie") ? 1 : 2;
  };
  useEffect(() => {
    let appLogsSubscription;

    appLogsSubscription = DataStore.observe(AppLog, (c) =>
      c.workspaceID("eq", state?.activeWorkspaceID)
    ).subscribe((msg) => {
      handleStateUpdateFromSubMessage(msg, "appLogs", "SET_APP_LOGS");
      notifyAppLog(msg?.element);
    });

    return () => {
      appLogsSubscription?.unsubscribe();
    };
  }, [state?.activeWorkspaceID, JSON.stringify(state?.appLogs)]);
  useEffect(() => {
    if (getActiveWorkspace()) {
      setIsWorkspaceEnabled(_isWorkspaceEnabled());
    }
  }, [getActiveWorkspace()]);

  const getAgent = (id) => {
    return state?.agents?.find((r) => r?.id === id);
  };
  const getAgents = () => {
    return state?.agents;
  };
  const notifyAppLog = (appLog) => {
    if (
      appLog?.type === "import" &&
      appLog?.status === "completed" &&
      state.appLogs.find((log) => log.id === appLog.id).status !== "completed"
    )
      AppToasty.show("DocumentImportCompletedCaption", { type: "success" });
    else if (
      appLog?.type === "import" &&
      appLog?.status === "inError" &&
      state.appLogs.find((log) => log.id === appLog.id).status !== "inError"
    )
      AppToasty.show("DocumentImportFailedCaption", { type: "danger" });
    if (
      appLog?.type === "documentImport" &&
      appLog?.status === "inError" &&
      state.appLogs.find((log) => log.id === appLog.id)?.status !== "inError"
    )
      AppToasty.show("DocumentImportFailedCaption", { type: "danger" });
    else if (
      appLog?.type === "documentImport" &&
      appLog?.status === "completed" &&
      state.appLogs.find((log) => log.id === appLog.id)?.status !==
        "completed" &&
      appLog.event
    ) {
      AppToasty.show("DocumentImportCompletedCaption", { type: "success" });
      dispatch("SET_REDIRECT_TO_DOC", JSON.parse(appLog.event));
    }
  };
  const isTrainingLogIdActive = (id) => {
    if (id == null || id == undefined || id === "") return false;
    return (
      getActiveWorkspace()?.nlpUrl?.includes(id) ||
      getActiveWorkspace()?.qaUrl?.includes(id) ||
      state?.trainingLogs.find(
        (log) =>
          log?.id === id &&
          log?.type === "generativeTraining" &&
          log?.trainingMeta?.model_id?.length > 0
      )?.trainingMeta?.model_id === getActiveWorkspace()?.gnUrl
    );
  };
  const isBatchEnabled = () => {
    return state?.workspacePlan?.isBatchJobsEnabled;
  };
  const isModelDownloadEnabled = () => {
    return state?.workspacePlan?.isDownloadModelEnabled;
  };
  const getMaxUsers = () => {
    return state?.workspacePlan?.maxUsers;
  };
  const isWorkspaceOnDemand = () => {
    return (
      state?.workspacePlan?.isPredictionOnDemandGlobal &&
      state?.workspacePlan?.isTrainingOnDemandGlobal
    );
  };
  const isPriorityTrainingEnabled = () => {
    return state?.workspacePlan?.isPriorityTrainingEnabled;
  };
  const isTF_API_AccessEnabled = () => {
    return state?.workspacePlan?.isAPIAccessEnabled;
  };
  const getActiveTrainingLog = (type) => {
    if (type === "nlp")
      return state?.trainingLogs?.find((log) =>
        getActiveWorkspace()?.nlpUrl?.includes(log.id)
      );
    else if (type === "qa")
      return state?.trainingLogs?.find((log) =>
        getActiveWorkspace()?.qaUrl?.includes(log.id)
      );
    else if (type === "gn")
      return state?.trainingLogs?.find(
        (log) => log?.trainingMeta?.model_id == getActiveWorkspace()?.gnUrl
      );
  };
  const getLastWorkspaceModelMeta = () => {
    return state?.trainingLogs
      ?.sort((a, b) => (a?._lastChangedAt < b?._lastChangedAt ? 1 : -1))
      ?.filter((u) => {
        return (
          u?.status === "completed" &&
          u?.type === "nlpTraining" &&
          u?.trainingConfig?.datasetOnly !== true
        );
      })[0]?.trainingMeta;
  };
  const getLastWorkspaceReadingComprehensionMeta = () => {
    return state?.trainingLogs
      ?.sort((a, b) => (a?._lastChangedAt < b?._lastChangedAt ? 1 : -1))
      ?.filter(
        (u) =>
          u?.status === "completed" &&
          u?.type === "readComprehensionTraining" &&
          u?.trainingConfig?.datasetOnly !== true
      )[0]?.trainingMeta;
  };
  const getLastCompletedTrainingLog = () => {
    return state?.trainingLogs
      ?.filter((u) => u.status === "completed")
      ?.sort((a, b) => (a?._lastChangedAt < b?._lastChangedAt ? 1 : -1))[0];
  };
  const updateWorkspaceInContext = (workspace) => {
    const idx = state?.workspaces
      ?.map((i) => i?.id)
      ?.indexOf(state?.activeWorkspaceID);
    state.workspaces[idx] = workspace;
    dispatch("SET_WORKSPACES", [...state.workspaces]);
  };
  const isNLPModelTrained = () => {
    return (
      state?.trainingLogs?.filter((u) => u?.type === "nlpTraining")?.length > 0
    );
  };
  const isRCModelTrained = () => {
    return (
      state?.trainingLogs?.filter(
        (u) => u?.type === "readComprehensionTraining"
      )?.length > 0
    );
  };
  const isGNModelTrained = () => {
    return (
      state?.trainingLogs?.filter((u) => u?.type === "generativeTraining")
        ?.length > 0
    );
  };
  const getWorkspaceBaseModel = () => {
    return state?.workspaces?.filter(
      (u) => u?.id === state?.activeWorkspaceID
    )[0].baseModel;
  };
  const getWorkspaceEntities = (type) => {
    const entities =
      state?.aiEntitiesConfig?.filter((i) => (type ? i.type === type : true)) ||
      [];
    entities.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );
    return entities;
  };
  const getAppLogs = (type, inProgressOnly = true, status) => {
    let appLogs = state?.appLogs?.filter((i) => i.type === type) || [];
    if (status) {
      appLogs = appLogs?.filter((i) => i.status === status);
    } else if (inProgressOnly) {
      appLogs = appLogs?.filter(
        (i) => i.status === "inProgress" || i.status === "dispatched"
      );
    }
    appLogs.sort((a, b) => (a._lastChangedAt > b._lastChangedAt ? 1 : -1));
    return appLogs;
  };
  const handleTabSelection = (route, routes, screen) => {
    const indexToUpdateInAppTabSelection = state?.appTabSelection
      .map((u) => u.key)
      .indexOf(screen);
    state.appTabSelection[indexToUpdateInAppTabSelection].lastIndex = routes
      .map((x) => x?.key)
      .indexOf(route?.key);
    dispatch("SET_TAB_SELECTION", [...state.appTabSelection]);
    if (getActiveWorkspace()?.id)
      history.push(
        `/workspaces/${getActiveWorkspace()?.id}/${screen}/${route?.routeId}`
      );
  };
  const handleSectionSelection = (section, screen) => {
    const indexToUpdateInAppTabSelection = state?.appTabSelection
      .map((u) => u.key)
      .indexOf(screen);
    state.appTabSelection[indexToUpdateInAppTabSelection].lastIndex = section;
    dispatch("SET_TAB_SELECTION", [...state.appTabSelection]);
  };
  const getLastTabSelectedByScreen = (screen, fallback = 0) => {
    const match = state?.appTabSelection.filter((x) => x.key === screen)[0]
      ?.lastIndex;
    if (match) return match;
    else return fallback;
  };
  const getLogicalTabSelection = (routes, screen) => {
    const match = state?.appTabSelection.filter((x) => x.key === screen)[0]
      ?.lastIndex;
    return routes[match]?.key;
  };
  const getRawTabSelection = (routes, screen) => {
    const match = state?.appTabSelection.filter((x) => x.key === screen)[0]
      ?.lastIndex;
    return routes[match];
  };
  const _isWorkspaceEnabled = () => {
    return (
      getActiveWorkspace()?.disabled !== true &&
      new Date(getActiveWorkspace()?.activeUntil) > Date.now()
    );
  };
  const isUserAdmin = () => {
    return (
      !userType() ||
      userType() === UserType.ROOT ||
      userType() === UserType.SUPER_ADMIN
    );
  };
  const isUserRoot = () => {
    return (
      user.id &&
      getActiveWorkspace()?.userID &&
      user.id === getActiveWorkspace()?.userID &&
      userType() === UserType.ROOT
    );
  };
  const isUserContentAdmin = () => {
    return (
      isUserRoot() || userType() === UserType.CONTENT_ADMIN || isUserAdmin()
    );
  };
  const isUserAIEngineer = () => {
    return isUserRoot() || userType() === UserType.AI_ENGINEER || isUserAdmin();
  };
  const isUserLoadedInWorkspace = () => {
    return user.id && getActiveWorkspace()?.userID;
  };
  const getTrainingLogInfo = (id) => {
    return state?.trainingLogs?.filter((i) => i.id === id)[0];
  };
  const getTrainingLogsWithModels = (type) => {
    return state?.trainingLogs?.filter(
      (u) =>
        u?.type === type &&
        u?.status === "completed" &&
        u?.trainingConfig?.datasetOnly !== true
    );
  };
  const generateBaseWorkspaceTrainingConfig = (subscriptionType) => {
    return {
      enableManagedSpotTraining: true,
      includeReadComprehensionDataInTraining: true,
      patienceNLP: 1600.0,
      maxQueueWaitTime: 360,
      maxStepsNLP: 40000.0,
      trainingEpochsNLP: 200.0,
      learningRateNLP: 0.001,
      datasetTestSizeThresholdNLP: 50.0,
      learningRateRC: 0.01,
      trainingEpochsRC: 20.0,
      trainingIterations: 5.0,
      batchSizeNLP: 100,
      batchSizeRC: 32,
      testSizeRC: 0.2,
      testSizeNLP: 0.2,
      enableManagedSpotTraining: subscriptionType === "base" ? true : false,
      maxRuntimeWaitTime: 86400,
      maxRuntime: 86400,
      generateDockerImageForRCengine: false,
      generateDockerImageForNLPengine: false,
      emailsListForTrainingConfirmation: [],
    };
  };
  const generateAdaptedTopics = (topics) => {
    const adaptedTopics = [];
    for (let i = 0; i < topics.length; i++) {
      let newTopic = topics[i];
      if (newTopic) {
        adaptedTopics.push({
          ...newTopic,
          name: topics[i].label,
          category: topics[i].type,
        });
      }
    }
    return adaptedTopics;
  };
  // CONTINUE
  const generateWorkspaceParsingConfig = (subscriptionType) => {
    return {
      answerAsNER: true,
      answerByParagraph: false,
      isImplicitSelfEnabled: false,
      isSentenceSplitEnabled: false,
      questionsLimit: 10.0,
      uniqueAnswer: true,
    };
  };
  const getTypeFromTrainingType = (trainingType, trainingTypeCode = "gn") => {
    if (trainingType === "nlpTraining" || trainingType === "nlp") return "NLP";
    else if (
      trainingType === "readComprehensionTraining" ||
      trainingType === trainingTypeCode
    )
      return trainingTypeCode.toUpperCase();
    else if (trainingType === "generativeTraining" || trainingType === "gn")
      return "GN";
    else if (trainingType === "conversationalTraining" || trainingType === "cn")
      return "CN";
  };
  const generateWorkspaceNotificationConfig = (subscriptionType) => {
    return {
      emailOnTrainingCompleted: true,
      emailOnTrainingFailed: true,
      emailOnTrainingStart: false,
      notifyAllUsersInWorkspace: false,
      notificationThresholdConsumption: 90.0,
      notifyOnThreasholdConsumption: true,
      emailsList: [],
    };
  };
  const getPlanConfig = () => {
    return reservedConfigs()?.plans
      ? reservedConfigs().plans?.find(
          (u) => u.type === getActiveWorkspace()?.subscriptionType
        )
      : null;
  };
  const getPromptsByAgent = (agentId) => {
    let mergedItems = [
      {
        id: "generation",
        name: "Manual",
        isCustom: false,
      },
    ];
    if (state?.customPrompts?.length > 0) {
      const agentPrompts = state?.customPrompts?.filter((u) =>
        u?.availableToAgents?.includes(agentId)
      );
      mergedItems = [
        ...mergedItems,
        ...agentPrompts.map((u) => {
          return {
            id: u.type,
            name: u?.label?.charAt(0).toUpperCase() + u?.label?.slice(1),
            isCustom: true,
          };
        }),
      ];
    }
    return mergedItems;
  };
  const handleGenerationOfCustomPrompt = async (
    value,
    availableToAgents,
    isEditor = true
  ) => {
    try {
      let mergedItems = [
        ...state?.customPrompts.map((item) => ({ ...item, isCustom: true })),
      ];
      promptData = mergedItems.find(
        (u) => u.type?.toString() === prompt?.toString()
      );
      let _interpolationString;
      if (isEditor)
        _interpolationString =
          AppQuantumEditorUtils.Helpers.getPlainText(value);
      else {
        _interpolationString = value;
      }
      if (!_interpolationString || _interpolationString?.length === 0) {
        return AppToasty.show("No prompt to save", {
          type: "warning",
        });
      }
      const _type = generateRowId()?.toString();
      const _promptCode = `${generateRowId()?.toString()}`;
      let newPrompt = {
        type: _type,
        label:
          _interpolationString?.length > 20
            ? _interpolationString?.substring(0, 20) + "..."
            : _interpolationString,
        promptLength: 256,
        scope: _promptCode,
        type: _promptCode,
        interpolationString: _interpolationString,
        workspaceID: getActiveWorkspace()?.id,
        availableToAgents,
      };
      if (state.customPrompts?.find((item) => item.type === _promptCode)) {
        return AppToasty.show("promptCreationErrorCaptionBecauseDuplicated", {
          type: "danger",
        });
      }
      const _newRole = await Prompts.createPrompt(newPrompt);
      _lastPrompt = prompt;
      dispatch("SET_CUSTOM_PROMPTS", [...state.customPrompts, _newRole]);
      AppToasty.show("promptCreationSuccessCaption", {
        type: "success",
        position: "bottom",
      });
      // }
      // else {
      //   const _editedRole = await Prompts.updatePrompt(promptData.id, [
      //     {
      //       property: "interpolationString",
      //       value: _interpolationString,
      //     },
      //   ]);
      //   let bufferPrompts = [...state.customPrompts];
      //   const selectedIndex = bufferPrompts.findIndex(
      //     (u) => u.id === promptData?.id
      //   );
      //   bufferPrompts[selectedIndex] = _editedRole;
      //   dispatch("SET_CUSTOM_PROMPTS", [...bufferPrompts]);
      //   AppToasty.show(t("promptUpdateSuccessCaption"), {
      //     type: "success",
      //     position: "bottom",
      //   });
      // }
    } catch (error) {
      console.error("", error);
      AppToasty.show(t("promptCreationErrorCaption"), {
        type: "danger",
      });
    }
  };
  const loadChats = async (showWidget = false, forceCleanup = false) => {
    setIsChatHistoryLoaded(false);
    let _loadMoreChatsToken = loadMoreChatsToken;
    if (forceCleanup) {
      setLoadMoreChatsToken(null);
      _loadMoreChatsToken = null;
    }
    const isAppend = _loadMoreChatsToken != null;
    let resultChats = null;

    resultChats = await chatsHelper.getChats(
      state?.activeWorkspaceID,
      _loadMoreChatsToken,
      showWidget
    );

    if (!resultChats?.data?.chatsByWorkspace?.nextToken && isAppend) {
      dispatch("SET_CHATS_LOADED", true);
      return state?.chats;
    } else if (!resultChats?.data?.chatsByWorkspace?.nextToken)
      dispatch("SET_CHATS_LOADED", true);
    setLoadMoreChatsToken(resultChats?.data?.chatsByWorkspace?.nextToken);
    resultChats = resultChats?.data?.chatsByWorkspace?.items;
    resultChats = resultChats?.map((u) => {
      return {
        ...u,
        topics: u?.topics ? JSON.parse(u?.topics) : [],
      };
    });
    if (!showWidget)
      resultChats = resultChats?.filter(
        (u) =>
          u?._deleted != true &&
          (u?.visibility === "public" ||
            !u?.visibility ||
            (u?.visibility === "private" && u?.createdBy === user?.id) ||
            isUserAIEngineer())
      );
    else {
      resultChats = resultChats?.filter(
        (u) => u?._deleted != true && u?.visibility === "widget"
      );
    }
    if (Constants.showProgrammaticChats === false)
      resultChats = resultChats.filter((u) => u.name);

    const finalChats = isAppend
      ? [...state?.chats, ...resultChats]
      : resultChats;
    // ensure chats have unique ids
    const uniqueChats = finalChats.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    dispatch("SET_CHATS", uniqueChats);
    setIsChatHistoryLoaded(true);
    return uniqueChats;
  };
  const getChat = (id) => {
    return state?.chats?.filter((u) => u.id === id)[0];
  };
  const getBaseWorkspaceConfig = async (type = "base", domain = "default") => {
    if (!reservedConfigs()?.workspaceBaseConfigs)
      throw new Error("No configuration data found");
    let baseConfig =
      reservedConfigs()?.workspaceBaseConfigs &&
      reservedConfigs()?.workspaceBaseConfigs.find(
        (u) => u.type === type && u.planConfig?.domain === domain
      );
    if (!baseConfig)
      baseConfig = reservedConfigs()?.workspaceBaseConfigs.find(
        (u) => u.type === type
      );
    if (baseConfig) {
      return API.graphql(
        graphqlOperation(getSubscriptionBaseConfig, {
          id: baseConfig.id,
        })
      );
    } else {
      throw new Error("No base config found");
    }
  };
  return {
    isTrainingEnabled:
      getPlanConfig()?.isTrainingNLPEnabled ||
      getPlanConfig()?.isTrainingQAEnabled ||
      getPlanConfig()?.isTrainingGNEnabled,
    agcEnabled:
      getPlanConfig()?.agcEnabled &&
      getActiveWorkspace()?.parsingConfig?.agcEnabled,
    getPlanConfig,
    getBaseWorkspaceConfig,
    getActiveWorkspace,
    updateWorkspaceInContext,
    getLastWorkspaceModelMeta,
    isNLPModelTrained,
    getLastCompletedTrainingLog,
    getWorkspaceBaseModel,
    getWorkspaceEntities,
    getAppLogs,
    handleTabSelection,
    getLastTabSelectedByScreen,
    handleSectionSelection,
    getLogicalTabSelection,
    getRawTabSelection,
    isWorkspaceEnabled,
    isTrainingLogIdActive,
    getLastWorkspaceReadingComprehensionMeta,
    isUserAdmin,
    isUserLoadedInWorkspace,
    returnCleanedWorkspaces,
    getDisabledWorkspaces,
    generateBaseWorkspaceTrainingConfig,
    isRCModelTrained,
    getLastOptionSelectedByScreen: () => {},
    generateWorkspaceParsingConfig,
    generateWorkspaceNotificationConfig,
    getTrainingLogInfo,
    generateAdaptedTopics,
    getActiveTrainingLog,
    isBatchEnabled,
    isModelDownloadEnabled,
    getMaxUsers,
    isWorkspaceOnDemand,
    isPriorityTrainingEnabled,
    isTF_API_AccessEnabled,
    getTypeFromTrainingType,
    getContentMultiplier,
    getWorkspaceUsers,
    generatorConfigData: state?.customPrompts,
    generatorConfigInProgress,
    getUserName,
    isWorkspaceDisabled,
    isWorkspaceInCreation,
    isGNModelTrained,
    isUserRoot,
    isUserAIEngineer,
    isUserContentAdmin,
    getTrainingLogsWithModels,
    loadChats,
    getAgent,
    getChat,
    canUpdateSubscription,
    isChatHistoryLoaded,
    setIsChatHistoryLoaded,
    handleGenerationOfCustomPrompt,
    getPromptsByAgent,
    getAgents,
  };
};

export default useWorkspaces;
