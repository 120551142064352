import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import execEnvsHelper from "../../../../API/ExecutionEnvironments/execEnvsHelper";
import secretGenerator from "../../../../API/SecretGenerator";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import ExecEnvSettingsAIView from "../SubComponents/CustomExecutionSettingsAIView";

const ExecEnvsSettings = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onExecEnvCreated,
      selectedId,
      onExecEnvDeleted = () => {},
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();

    const { state, dispatch } = AppStateManager.useAppStateManager();
    const { getActiveWorkspace } = useWorkspaces();
    const [isSaveInProgress, setIsSaveInProgress] = React.useState(false);
    const validationSchema = AppForms.yup.object().shape({
      name: AppForms.yup
        .string()
        .required()
        .label("Name")
        .matches(
          /^[a-zA-Z0-9_]*$/,
          "Only alphanumeric characters and underscores are allowed without spaces."
        )
        .max(64, "Too long, maximum of 64 characters are allowed."),
      description: AppForms.yup
        .string()
        .required()
        .label("Description")
        .max(255),
    });

    const handleDeletion = async () => {
      try {
        const result =
          await execEnvsHelper.deleteCustomCodeExecutionEnvironment(opt.id);
        dispatch("SET_EXEC_ENVS", [
          ...state?.execEnvs?.filter((u) => u?.id !== opt.id),
        ]);
        onExecEnvDeleted();
        AppToasty.show(`Execution environment deleted`, {
          type: "success",
        });
      } catch (error) {
        console.log("Error deleting auth", error);
        AppToasty.show(`Execution environment could not be deleted`, {
          type: "danger",
        });
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };
    const identifyOptByID = (id) => {
      return state?.execEnvs.find((u) => u?.id === id);
    };
    const handleExecEnvItemSave = async (v) => {
      setIsSaveInProgress(true);
      v.workspaceID = getActiveWorkspace()?.id;
      delete v?._lastChangedAt;
      delete v?.createdAt;
      delete v?.updatedAt;
      delete v?._deleted;
      delete v?.__typename;
      let newExecEnvs = [...state?.execEnvs];
      console.log("New exec envs", v);
      try {
        let result;
        if (isEdit) {
          result = await execEnvsHelper.updateCustomCodeExecutionEnvironment(
            opt.id,
            v
          );
          const _updatedObject = identifyOptByID(
            result.data?.updateCustomCodeExecutionEnvironment?.id
          );
          const updateIndex = newExecEnvs.findIndex(
            (u) => u?.id === _updatedObject?.id
          );
          newExecEnvs[updateIndex] =
            result?.data?.updateCustomCodeExecutionEnvironment;
        } else {
          if (state?.execEnvs?.find((u) => u?.name === v?.name)) {
            AppToasty.show(
              "Execution environment with this name already exists",
              {
                type: "danger",
              }
            );
            setIsSaveInProgress(false);
            return;
          }
          result = await execEnvsHelper.createCustomCodeExecutionEnvironment({
            ...v,
          });

          newExecEnvs.push(result?.data?.createCustomCodeExecutionEnvironment);
        }

        dispatch("SET_EXEC_ENVS", [...newExecEnvs]);
        backTo && navigateBack();
        onExecEnvCreated && onExecEnvCreated("success");
      } catch (error) {
        console.error(error);
        onExecEnvCreated && onExecEnvCreated("error");
      } finally {
        setIsSaveInProgress(false);
      }
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            height: "auto",
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Execution environment : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={handleExecEnvItemSave}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <ExecEnvSettingsAIView
              handleDeletion={handleDeletion}
              isEdit={isEdit}
              settingType={settingType}
              isSaveInProgress={isSaveInProgress}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [
        state.activeWorkspaceID,
        initialMapValues,
        state?.execEnvs,
        isEdit,
        AppToasty,
        isSaveInProgress,
      ]
    );
    return _view;
  }
);

export default ExecEnvsSettings;
