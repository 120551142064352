import React from "react";
import ToothFairySwitch from "../../../Components/ToothFairyComponents/ToothFairySwitch";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import useSections from "../../../Hooks/useSections";
import workspacesHelper from "../../../API/Workspaces/workspacesHelper";
import { useTranslation } from "react-i18next";
import Constants from "../../../Constants";
import { Platform, View } from "react-native";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppExpander from "@toothfairy/shared-ui/AppExpander";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import ToothFairySlider from "../../../Components/ToothFairyComponents/ToothFairySlider";
import { isLargeScreen } from "../../MainViewContainer";

const NotificationsContainer = () => {
  const {
    getActiveWorkspace,
    updateWorkspaceInContext,
    isBatchEnabled,
    isWorkspaceOnDemand,
    getPlanConfig,
  } = useWorkspaces();
  const { currentTheme } = AppTheme.useTheme();
  const { marginLeftUnderAppExpander } = useSections();
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const { NOTIFICATION_OPTIONS } = Constants;
  const { state } = AppStateManager.useAppStateManager();

  const handleWorkspaceUpdate = async (property, value) => {
    try {
      const result = await workspacesHelper.updateWorkspace(
        getActiveWorkspace()?.id,
        [{ property, value }]
      );
      updateWorkspaceInContext(result);
      AppToasty.show(t("WorkspaceUpdateCaption"), {
        type: "success",
      });
    } catch (error) {
      AppToasty.show(t("WorkspaceUpdateFailedCaption"), {
        type: "danger",
      });
      console.error("An error occured while updating the workspace", error);
    }
  };
  return (
    <View
      style={{
        width: Platform.OS === "web" && isLargeScreen() ? 450 : 350,
        marginTop: 20,
        marginLeft: marginLeftUnderAppExpander,
        alignSelf: "center",
      }}
    >
      <AppExpander
        title="Consumption notifications"
        icon={AppAssets.icons.parser}
        style={{ minWidth: isLargeScreen() ? 530 : 250 }}
        isExpandedOnLoad={true}
      >
        {NOTIFICATION_OPTIONS.filter((u) => u.type === "PR").map((u) => (
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              justifyContent: "space-between",
              marginBottom: 0,
              paddingLeft: !isLargeScreen() && 0,
            }}
          >
            <ToothFairySwitch
              disabled={isWorkspaceOnDemand() && u.disabledIfBase === true}
              wrapperStyle={{
                flex: 1,
                marginRight: 20,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                marginBottom: 20,
              }}
              label={u.label}
              onChange={(v) => {
                handleWorkspaceUpdate("notificationConfig", {
                  ...getActiveWorkspace().notificationConfig,
                  [u?.logicalPropertyName]: v,
                });
              }}
              isTicked={
                getActiveWorkspace()?.notificationConfig &&
                getActiveWorkspace()?.notificationConfig[u?.logicalPropertyName]
              }
            />
            {getActiveWorkspace()?.notificationConfig &&
              getActiveWorkspace()?.notificationConfig[
                u?.logicalPropertyName
              ] &&
              u?.showSlider && (
                <ToothFairySlider
                  maximumValue={100}
                  minimumValue={0}
                  unitLabel={" %"}
                  label="Threshold (%)"
                  onSubmit={(v) =>
                    handleWorkspaceUpdate("notificationConfig", {
                      ...getActiveWorkspace().notificationConfig,
                      [u?.sliderPropertyName]: v,
                    })
                  }
                  initialValue={
                    getActiveWorkspace()?.notificationConfig[
                      u?.sliderPropertyName
                    ] || Constants.baseThreasholdConsumption
                  }
                />
              )}
          </View>
        ))}
      </AppExpander>

      <AppExpander
        title="Fine-tune notifications"
        icon={AppAssets.icons.slidersH}
        style={{ minWidth: isLargeScreen() ? 530 : 250 }}
        isExpandedOnLoad={getActiveWorkspace()?.subscriptionType === "base"}
      >
        {NOTIFICATION_OPTIONS.filter((u) => u.type === "TR").map((u) => (
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "space-between",
              marginBottom: 30,
              paddingLeft: !isLargeScreen() && 0,
            }}
          >
            <ToothFairySwitch
              wrapperStyle={{
                flex: 1,
                marginRight: 20,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
              }}
              label={u.label}
              onChange={(v) => {
                handleWorkspaceUpdate("notificationConfig", {
                  ...getActiveWorkspace().notificationConfig,
                  [u?.logicalPropertyName]: v,
                });
              }}
              isTicked={
                getActiveWorkspace()?.notificationConfig &&
                getActiveWorkspace()?.notificationConfig[u?.logicalPropertyName]
              }
            />
          </View>
        ))}
      </AppExpander>

      {isBatchEnabled() && (
        <AppExpander
          title="Batch notifications"
          icon={AppAssets.icons.duplicate}
          style={{ minWidth: isLargeScreen() ? 530 : 250 }}
          isExpandedOnLoad={false}
        >
          {NOTIFICATION_OPTIONS.filter((u) => u.type === "BT").map((u) => (
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between",
                marginBottom: 30,
                paddingLeft: !isLargeScreen() && 0,
              }}
            >
              <ToothFairySwitch
                wrapperStyle={{
                  flex: 1,
                  marginRight: 20,
                  backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                }}
                label={u.label}
                onChange={(v) => {
                  handleWorkspaceUpdate("notificationConfig", {
                    ...getActiveWorkspace().notificationConfig,
                    [u?.logicalPropertyName]: v,
                  });
                }}
                isTicked={
                  getActiveWorkspace()?.notificationConfig &&
                  getActiveWorkspace()?.notificationConfig[
                    u?.logicalPropertyName
                  ]
                }
              />
            </View>
          ))}
        </AppExpander>
      )}
    </View>
  );
};

export default NotificationsContainer;
