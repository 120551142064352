import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppList from "@toothfairy/shared-ui/AppList";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import { isLargeScreen } from "../../MainViewContainer";

const BillingInvoices = ({
  stripeClientInvoicesData,
  loadMoreInvoices,
  invoices,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const styles = styleGenerator(currentTheme);
  const { t } = useTranslation();
  return (
    <View
      style={{
        flexDirection: "column",
      }}
    >
      <AppList
        style={{ paddingLeft: 0, backgroundColor: "transparent" }}
        contentContainerStyle={styles.listContentContainer}
        isSeparatorVisible={true}
        items={invoices?.filter((invoice) => invoice.status !== "draft")}
        keyExtractor={"id"}
        renderItem={({ item, index }) => (
          <>
            {index === 0 && (
              <View style={[styles.listItemContainer, styles.listHeader]}>
                <AppText
                  style={{ fontWeight: "bold" }}
                  wrapperStyle={
                    !isLargeScreen()
                      ? styles.largeCellWrapperStyle
                      : styles.cellWrapperStyle
                  }
                >
                  Invoice number
                </AppText>
                <AppText
                  style={{ fontWeight: "bold" }}
                  wrapperStyle={
                    !isLargeScreen()
                      ? styles.largeCellWrapperStyle
                      : styles.cellWrapperStyle
                  }
                >
                  Status
                </AppText>
                {isLargeScreen() && (
                  <AppText
                    style={{ fontWeight: "bold" }}
                    wrapperStyle={styles.cellWrapperStyle}
                  >
                    Amount
                  </AppText>
                )}

                {isLargeScreen() && (
                  <AppText
                    style={{ fontWeight: "bold" }}
                    wrapperStyle={styles.cellWrapperStyle}
                  >
                    Period
                  </AppText>
                )}

                <AppText
                  style={{ fontWeight: "bold" }}
                  wrapperStyle={styles.cellWrapperStyle}
                >
                  Actions
                </AppText>
              </View>
            )}
            <View style={styles.listItemContainer}>
              <AppText
                wrapperStyle={
                  !isLargeScreen()
                    ? styles.largeCellWrapperStyle
                    : styles.cellWrapperStyle
                }
              >
                {item.number}
              </AppText>
              <AppText
                style={{ textTransform: "capitalize" }}
                color={currentTheme?.theme?.primary}
                fontWeight={"bold"}
                wrapperStyle={
                  !isLargeScreen()
                    ? styles.largeCellWrapperStyle
                    : styles.cellWrapperStyle
                }
              >
                {item.status}
              </AppText>
              {isLargeScreen() && (
                <AppText
                  fontWeight={"bold"}
                  wrapperStyle={styles.cellWrapperStyle}
                  style={{ textTransform: "uppercase" }}
                >
                  {`${item.amount_due / 100} ${item.currency}`}
                </AppText>
              )}

              {isLargeScreen() && (
                <AppText
                  fontWeight={"bold"}
                  wrapperStyle={styles.cellWrapperStyle}
                >
                  {`${AppUtilities.dateConverter.ConvertTimeStampToDateString(
                    item.period_start * 1000,
                    "en",
                    t,
                    false,
                    "MMM YYYY"
                  )}`}
                </AppText>
              )}

              <AppIcon
                style={{
                  width: isLargeScreen() ? 120 : 60,
                  flexDirection: "row",
                }}
                icon={AppAssets.icons.link}
                size={16}
                isClickable
                onPress={() => window.open(item.hosted_invoice_url, "_blank")}
              />
            </View>
          </>
        )}
      />
      {stripeClientInvoicesData?.invoices?.has_more && (
        <ToothFairyButton
          title="Load more"
          wrapperStyle={{
            alignSelf: "center",
          }}
          onPress={loadMoreInvoices}
        />
      )}
    </View>
  );
};
const styleGenerator = (currentTheme) =>
  StyleSheet.create({
    cellWrapperStyle: {
      justifyContent: "center",
      width: "20%",
    },
    largeCellWrapperStyle: {
      justifyContent: "center",
      width: "40%",
    },
    listContentContainer: {
      paddingBottom: 20,
    },
    listHeader: {
      backgroundColor: currentTheme?.theme?.lightGrey,
    },
    listItemContainer: {
      flexDirection: "row",
      padding: 10,
    },
  });

export default BillingInvoices;
