import API, { graphqlOperation } from "@aws-amplify/api";
import useAPI from "@toothfairy/shared-api/useApi";
import { DataStore } from "@aws-amplify/datastore";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import workspacesHelper from "../../API/Workspaces/workspacesHelper";
import Constants, { defaultConsumptionObject } from "../../Constants";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory } from "../../Router";
import { listSubscriptionPricingPlans } from "../../graphql/queries";
import { EntityAI, UserType } from "../../models";
import WorkspacesScreen from "./WorkspacesScreen";
import { createUserWorkspaceType } from "../../graphql/mutations";
import API_Consumption from "../../API/API_Consumption";
import envConfig from "../../../envConfig";
import Agents from "../../API/Agents";
import { useUser } from "../../Hooks/useUser";
import useUserHelperPreferences from "../../Hooks/useUserHelperPreferences";

export const formValidationSchema = AppForms.yup.object().shape({
  workspaceName: AppForms.yup.string().required().label("Workspace name"),
  // workspaceType: AppForms.yup
  //   .string()
  //   .required()
  //   .label("Workspace intent structure"),
  workspaceMed: AppForms.yup
    .boolean()
    .required()
    .label("Workspace medical setting"),
});

const WorkspacesScreenContainer = () => {
  const { t } = useTranslation();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [_workspaceName, setWorkspaceName] = useState(null);
  const [targetPlan, setTargetPlan] = useState(false);
  const [workspaceMutationInProgress, setWorkspaceMutationInProgress] =
    useState(false);
  const { user } = AppUser.useUser();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState();
  const [modalContentKey, setModalContentKey] = useState();
  const {
    generateBaseWorkspaceTrainingConfig,
    generateWorkspaceParsingConfig,
    generateWorkspaceNotificationConfig,
    getBaseWorkspaceConfig,
  } = useWorkspaces();
  const {
    data,
    loading,
    apiRequest: updateUsageForWorkspaceRequest,
    response,
  } = useAPI(API_Consumption.updateUsageForWorkspace, envConfig);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(
    Constants.initialWorkspaceValues
  );
  const [_values, _setValues] = useState({});
  const AppToasty = AppToast.useToast();

  const identifyWorkspaceByID = (id) => {
    return state.workspaces.map((u) => u.id).indexOf(id);
  };
  const handleWorkspaceSelection = (id) => {
    if (
      user?.stripeCustomerData?.invoice_settings?.default_payment_method !==
        null ||
      user?.stripeCustomerData?.balance < 0
    ) {
      dispatch("SET_ACTIVE_WORKSPACE_ID", id);
      history.push(`/workspaces/${id}/chats`);
    } else {
      AppToasty.show(t("WorkspaceSelectionFailedDueToMissingPaymentMethod"), {
        type: "danger",
      });
    }
  };
  const handleWorkSpaceCreation = () => {
    setModalContentKey("NEW");
    setInitialValues({
      workspaceMed: false,
      baseModel: "NONE",
      domain: "DEFAULT",
      subscriptionType: "Select Plan",
      maxUsers: 1,
    });
    setIsModalOpen(true);
  };
  const handleWorkspaceEdit = (item) => {
    setSelectedWorkspace(item);
    setInitialValues({
      workspaceID: item.id,
      workspaceName: item.title,
      workspaceType: item.type,
      workspaceMed: item.isMed ? true : false,
      subscriptionType: item.subscriptionType,
      targetSubscriptionType: item.targetSubscriptionType,
      domain: item.domain,
      baseModel: item.baseModel,
    });
    setModalContentKey("UPDATE");
    setIsModalOpen(true);
  };
  const handleWorkspaceDeletion = async () => {
    try {
      await workspacesHelper.deleteWorkspace(selectedWorkspace.id);
      setIsModalOpen(false);
      dispatch(
        "SET_WORKSPACES",
        state.workspaces.filter((u) => u.id !== selectedWorkspace.id)
      );
      AppToasty.show(t("WorkspaceDeletionCaption"), { type: "success" });
    } catch (error) {
      AppToasty.show(t("WorkspaceDeletionFailedCaption"), { type: "danger" });
      console.error("Error while deleting the workspace", error);
    }
  };
  const cancelPlanChange = async () => {
    const payload = [
      {
        property: "targetSubscriptionType",
        value: null,
      },
    ];
    const result = await workspacesHelper.updateWorkspace(
      selectedWorkspace.id,
      payload
    );
    state.workspaces[identifyWorkspaceByID(result.id)] = result;
    setInitialValues({
      ...initialValues,
      targetSubscriptionType: null,
    });
    AppToasty.show(t("WorkspaceChangeOfPlanAbort"), { type: "success" });
  };
  const onSubmit = async () => {
    if (!_workspaceName) {
      return AppToasty.show("Please provide a workspace name", {
        type: "danger",
      });
    } else if (_values.subscriptionType === "Select Plan" || !_values.maxUsers)
      return AppToasty.show("Please choose the plan for your workspace", {
        type: "danger",
      });
    else {
      handleFormSubmit({
        ..._values,
        workspaceName: _workspaceName,
      });
    }
  };
  const handleValuesChange = (values) => {
    _setValues(values);
    // update only if the domain and the baseModel are changed
    if (
      values.domain !== initialValues.domain ||
      values.baseModel !== initialValues.baseModel
    ) {
      setInitialValues((prev) => ({
        ...prev,
        domain: values?.domain,
        baseModel: values?.baseModel,
        workspaceName: values?.workspaceName,
      }));
    } else if (values.workspaceName !== initialValues.workspaceName) {
      setWorkspaceName(values?.workspaceName);
    }
  };
  const onPlanSelection = (subscriptionType, seats) => {
    setInitialValues((prev) => ({
      ...prev,
      subscriptionType,
      maxUsers: seats,
    }));
  };
  const handleFormSubmit = async (values) => {
    let result;
    try {
      setWorkspaceMutationInProgress(true);
      if (modalContentKey === "NEW") {
        const configResult = await getBaseWorkspaceConfig(
          values.subscriptionType?.toLowerCase(),
          values.domain?.toLowerCase()
        );

        const result_pricing_plan = await API.graphql(
          graphqlOperation(listSubscriptionPricingPlans, {})
        );

        const pricing_plans =
          result_pricing_plan?.data?.listSubscriptionPricingPlans?.items;
        const config_based_on_sub = pricing_plans.find(
          (u) => u.type === values.subscriptionType
        );

        const config =
          configResult?.data?.getSubscriptionBaseConfig?.planConfig;
        if (!config) {
          AppToasty.show(t("WorkspaceCreationFailedCaption"), {
            type: "danger",
          });
          return;
        }

        result = await workspacesHelper.createWorkspace({
          title: values.workspaceName,
          isMed: values.workspaceMed === "true",
          userID: user.id,
          visibility: "shared",
          type: "flat",
          users: [user.id],
          qaUrl: config_based_on_sub?.baseModelQA,
          nlpUrl: config_based_on_sub?.baseModelNLP,
          gnUrl: config_based_on_sub?.baseModelGN,
          subscriptionType: values.subscriptionType,
          baseModel: !values.baseModel.toLowerCase()
            ? "none"
            : values.baseModel.toLowerCase(),
          domain: !values.domain ? "default" : values.domain.toLowerCase(),
          trainingConfig: config.trainingConfig,
          parsingConfig: config.parsingConfig,
          notificationConfig: config.notificationConfig,
          aiConsumption: defaultConsumptionObject,
          customGenerationConfig: {
            styles: [],
            domains: [],
            scopes: [],
            prompts: [],
          },
          maxUsers: values.maxUsers,
        });
        // add user as admin to workspaceUser table
        const _userAsAdminResult = await API.graphql(
          graphqlOperation(createUserWorkspaceType, {
            input: {
              userID: user.id,
              workspaceID: result.id,
              userType: UserType.ROOT,
            },
          })
        );

        const resultAIEntityNER = await Promise.all(
          AppUtilities.baseEntities.map((ner) =>
            DataStore.save(
              new EntityAI({
                label: ner.label,
                backgroundColor: ner.backgroundColor,
                type: "ner",
                workspaceID: result.id,
                emoji: ner.icon,
              })
            )
          )
        );

        if (values.workspaceMed) {
          const medresultAIEntityNER = await Promise.all(
            AppUtilities.medEntities.map((ner) =>
              DataStore.save(
                new EntityAI({
                  label: ner.label,
                  backgroundColor: ner.backgroundColor,
                  type: "ner",
                  workspaceID: result.id,
                  emoji: ner.icon,
                })
              )
            )
          );
        }

        const resultAIEntityIntent = await Promise.all(
          Constants.generateBaseIntentsFromBaseModel(
            !values.baseModel?.toLowerCase()
              ? "none"
              : values.baseModel?.toLowerCase()
          )?.map((intent) =>
            DataStore.save(
              new EntityAI({
                label: intent.label,
                backgroundColor: intent.backgroundColor,
                type: "intent",
                workspaceID: result.id,
              })
            )
          )
        );
        // create 3 topics for the workspace WEBSITE, PRIVATE AND GENERAL
        const resultTopics = await Promise.all(
          Constants.baseTopics.map((topic) =>
            DataStore.save(
              new EntityAI({
                label: topic.label,
                backgroundColor: topic.backgroundColor,
                type: "topic",
                workspaceID: result.id,
              })
            )
          )
        );
        // get the id of the topics and create an array of topics
        const topics = resultTopics
          .filter((i) => i?.label != "PRIVATE")
          .map((z) => {
            return {
              ...z,
              value: z?.id,
            };
          });
        const resultBaseAgents = await Agents.getBaseAgentsByWorkspaceID();
        for (const agent of resultBaseAgents?.data?.agentsByWorkspace?.items) {
          let newAgent = {
            ...agent,
            workspaceID: result.id,
            allowedTopics: topics,
            // subject: values.workspaceName,
          };
          delete newAgent.id;
          delete newAgent.createdAt;
          delete newAgent.updatedAt;
          delete newAgent._version;
          await Agents.createAgent(newAgent);
        }
        setIsModalOpen(false);
        dispatch("SET_WORKSPACES", [...state.workspaces, result]);
        try {
          const resultUpdateStripe = await updateUsageForWorkspaceRequest({
            workspaceId: result?.id,
            usage: result?.maxUsers,
          });
        } catch (error) {
          console.error(
            "Error while updating the usage for the workspace",
            error
          );
        }
        AppToasty.show(t("WorkspaceCreationCaption"), { type: "success" });
      } else if (modalContentKey === "UPDATE") {
        const payload = [
          {
            property: "title",
            value: values.workspaceName,
          },
          {
            property: "isMed",
            value: values.workspaceMed,
          },
          {
            property: "baseModel",
            value: !values.baseModel.toLowerCase()
              ? "none"
              : values.baseModel.toLowerCase(),
          },
          {
            property:
              targetPlan !== "base"
                ? "subscriptionType"
                : "targetSubscriptionType",
            value: values.subscriptionType,
          },
        ];

        result = await workspacesHelper.updateWorkspace(
          selectedWorkspace.id,
          payload
        );
        state.workspaces[identifyWorkspaceByID(result.id)] = result;
        setIsModalOpen(false);
        dispatch("SET_WORKSPACES", state.workspaces);
        AppToasty.show(t("WorkspaceUpdateCaption"), { type: "success" });
      }
    } catch (error) {
      AppToasty.show(`Error - ${error}`, { type: "danger" });
      console.error("Error while saving the workspace", error);
    } finally {
      setWorkspaceMutationInProgress(false);
    }
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <WorkspacesScreen
        setIsModalOpen={setIsModalOpen}
        setInitialValues={setInitialValues}
        isModalOpen={isModalOpen}
        modalContentKey={modalContentKey}
        workspaceMutationInProgress={workspaceMutationInProgress}
        setModalContentKey={setModalContentKey}
        handleWorkSpaceCreation={handleWorkSpaceCreation}
        handleWorkspaceEdit={handleWorkspaceEdit}
        handleWorkspaceDeletion={handleWorkspaceDeletion}
        handleWorkspaceSelection={handleWorkspaceSelection}
        workspaces={state.workspaces}
        selectedWorkspace={selectedWorkspace}
        setSelectedWorkspace={setSelectedWorkspace}
        formValidationSchema={formValidationSchema}
        initialValues={initialValues}
        handleFormSubmit={() => {}}
        cancelPlanChange={cancelPlanChange}
        targetPlan={targetPlan}
        setTargetPlan={setTargetPlan}
        onPlanSelection={onPlanSelection}
        onValuesChange={handleValuesChange}
        onSubmit={onSubmit}
        forceEnabled
      />
    </Suspense>
  );
};

export default WorkspacesScreenContainer;
