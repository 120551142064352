import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import React from "react";

const SiteTokenComponent = ({ values, wrapperStyle }) => {
  const AppToasty = AppToast.useToast();
  const { state } = AppStateManager.useAppStateManager();
  return (
    <AppTable
      customEditIcon={AppAssets?.icons?.quickCopy}
      handleDocumentDeletion={null}
      actionButtons
      handleDocumentSelection={() => {
        // copy to clipboard
        navigator.clipboard.writeText(
          state?.sites?.find((u) => u?.id === values?.id)?.validationToken
        );
        AppToasty.show(`Value copied to clipboard`, {
          type: "success",
        });
      }}
      style={[
        {
          zIndex: -1,
          maxHeight: 200,
          overflowY: "auto",
          overflowX: "hidden",
        },
        wrapperStyle,
      ]}
      headers={[
        {
          title: " ",
          width: 0.1,
        },
        {
          title: " ",
          width: 0.8,
        },
        {
          title: " ",
          width: 0.1,
        },
      ]}
      items={[
        {
          key: "Hostname",
          value: "toothfairyai._domainkey",
          action: " ",
        },
        {
          key: "Type",
          value: "TXT",
          action: " ",
        },
        {
          key: "Value",
          value:
            state?.sites?.find((u) => u?.id === values?.id)?.validationToken ||
            "",
          action: " ",
        },
      ]}
    />
  );
};

export default SiteTokenComponent;
