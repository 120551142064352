import React from "react";
import useWorkspaces from "../Hooks/useWorkspaces";
import { Redirect, Route } from "./index";
import { routes } from "./routes";

const BusinessAnalystRoute = (props) => {
  const { getPlanConfig } = useWorkspaces();

  return getPlanConfig() && !getPlanConfig()?.isBusinessAnalystEnabled ? (
    <Redirect
      to={{
        pathname: routes.accessDenied,
      }}
    />
  ) : (
    <Route {...props} />
  );
};

export default BusinessAnalystRoute;
