import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import authorisationsHelper from "../../../../API/Authorisations/authorisationsHelper";
import secretGenerator from "../../../../API/SecretGenerator";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import AuthorisationSettingsAIView from "../SubComponents/AuthorisationSettingsAIView";

const AuthorisationsSettingsAI = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onAuthCreated,
      selectedId,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();

    const { state, dispatch } = AppStateManager.useAppStateManager();
    const { getActiveWorkspace } = useWorkspaces();
    const [isSaveInProgress, setIsSaveInProgress] = React.useState(false);
    const validationSchema = AppForms.yup.object().shape({
      type: AppForms.yup.string().required().label("Authentication Type"),
      name: AppForms.yup
        .string()
        .required()
        .label("Name")
        .max(255, "Too long, maximum of 255 characters are allowed."),
      description: AppForms.yup.string().label("Description").max(255),
      tokenSecret: AppForms.yup.string().when("type", {
        is: (type) => type === "apiKey",
        then: AppForms.yup.string().required().label("API Key"),
        otherwise: AppForms.yup.string().notRequired().nullable(),
      }),
      clientId: AppForms.yup.string().when("type", {
        is: (type) => type === "oauth",
        then: AppForms.yup.string().required().label("Client ID"),
        otherwise: AppForms.yup.string().notRequired(),
      }),
      clientSecret: AppForms.yup.string().when("type", {
        is: (type) => type === "oauth",
        then: AppForms.yup.string().required().label("Client Secret"),
        otherwise: AppForms.yup.string().nullable().notRequired(),
      }),
      scope: AppForms.yup.string().notRequired(),
      grantType: AppForms.yup.string().when("type", {
        is: (type) => type === "oauth",
        then: AppForms.yup.string().required().label("Grant Type"),
        otherwise: AppForms.yup.string().notRequired(),
      }),
      authorizationBaseUrl: AppForms.yup.string().when("type", {
        is: (type) => type === "oauth",
        then: AppForms.yup.string().required().label("Authorization URL"),
        otherwise: AppForms.yup.string().notRequired(),
      }),
    });

    const handleDeletion = async () => {
      try {
        console.log("result of deletion", opt);
        const result = await authorisationsHelper.deleteAuthorisation(opt.id);
        dispatch("SET_AUTHORISATIONS", [
          ...state.authorisations?.filter((u) => u?.id !== opt.id),
        ]);
        navigateBack();
        AppToasty.show(`Authorisation deleted`, {
          type: "success",
        });
      } catch (error) {
        console.log("Error deleting auth", error);
        AppToasty.show(`Authorisation could not be deleted`, {
          type: "danger",
        });
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };
    const identifyOptByID = (id) => {
      return state.authorisations.find((u) => u?.id === id);
    };
    const handleAuthorisationItemSave = async (v) => {
      setIsSaveInProgress(true);
      v.workspaceID = getActiveWorkspace()?.id;
      v.type = v.type.toLowerCase();
      if (v?.staticArgs) v.staticArgs = JSON.stringify(v.staticArgs, null, 2);
      delete v?._lastChangedAt;
      delete v?.createdAt;
      delete v?.updatedAt;
      delete v?._deleted;
      delete v?.__typename;
      const psw = v?.type == "oauth" ? v?.clientSecret : v?.tokenSecret;
      delete v?.clientSecret;
      delete v?.tokenSecret;
      let newAuthorisations = [...state.authorisations];
      try {
        let result;
        console.log("Is EDIT", isEdit, v);
        if (isEdit) {
          result = await authorisationsHelper.updateAuthorisation(opt.id, v);
          console.log("Edit result", result);
          const _updatedObject = identifyOptByID(
            result.data?.updateAuthorisation?.id
          );
          const updateIndex = newAuthorisations.findIndex(
            (u) => u?.id === _updatedObject?.id
          );
          newAuthorisations[updateIndex] = result?.data?.updateAuthorisation;
        } else {
          result = await authorisationsHelper.createAuthorisation({
            ...v,
          });
          const _id = result?.data?.createAuthorisation?.id;
          const secretResult = await secretGenerator.storeSecret({
            workspaceid: v.workspaceID,
            id: _id,
            passwordSecretValue: psw,
          });
          if (secretResult?.data?.status === "Success") {
            const payload =
              v?.type == "oauth"
                ? {
                    clientSecret: secretResult?.data?.name,
                  }
                : {
                    tokenSecret: secretResult?.data?.name,
                  };
            const _updatedObject =
              await authorisationsHelper.updateAuthorisation(_id, payload);
          } else {
            throw new Error("Secret could not be created");
          }
          newAuthorisations.push(result?.data?.createAuthorisation);
        }

        dispatch("SET_AUTHORISATIONS", [...newAuthorisations]);
        backTo && navigateBack();
        onAuthCreated && onAuthCreated("success");
      } catch (error) {
        console.error(error);
        onAuthCreated && onAuthCreated("error");
      } finally {
        setIsSaveInProgress(false);
      }
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            height: "auto",
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Authorisation : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={handleAuthorisationItemSave}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <AuthorisationSettingsAIView
              handleDeletion={handleDeletion}
              isEdit={isEdit}
              settingType={settingType}
              isSaveInProgress={isSaveInProgress}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [
        state.activeWorkspaceID,
        initialMapValues,
        state.authorisations,
        isEdit,
        AppToasty,
        isSaveInProgress,
      ]
    );
    return _view;
  }
);

export default AuthorisationsSettingsAI;
