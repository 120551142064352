import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  defaults,
  LogarithmicScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Common from "./Common";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  LogarithmicScale
);

defaults.font.family = "normalFont";
defaults.font.size = 14;
defaults.scale.ticks.precision = 0;

const AppBarChart = ({
  dataset,
  vertical = true,
  maxHeight,
  options,
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <Bar
      style={{
        // backgroundColor: currentTheme?.theme.softBackgroundColor,
        borderRadius: 10,
        maxHeight,
      }}
      options={
        vertical
          ? { ...Common.verticalBarOptions(currentTheme), ...options }
          : { ...Common.horizontalBarOptions(currentTheme), ...options }
      }
      data={dataset}
      {...props}
    />
  );
};

export default AppBarChart;
