import { DataStore } from "@aws-amplify/datastore";
import { Agent } from "../../models";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";

const getBaseAgentsByWorkspaceID = () =>
  API.graphql(
    graphqlOperation(queries.agentsByWorkspace, { workspaceID: "root" })
  );

const createAgent = async (object) => {
  return DataStore.save(new Agent(object));
};

const updateAgent = async (id, args) => {
  const original = await DataStore.query(Agent, id);
  return DataStore.save(
    Agent.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteAgent = async (id) => {
  return DataStore.delete(Agent, (ent) => ent.id("eq", id));
};

export default {
  createAgent,
  updateAgent,
  deleteAgent,
  getBaseAgentsByWorkspaceID,
};
