import Clipboard from "@react-native-clipboard/clipboard";
import { AppText } from "@toothfairy/shared-ui";
import AppTextChip from "@toothfairy/shared-ui/AppTextChip";
import React from "react";
import { View } from "react-native";
import envConfig from "../../../envConfig";
import AdminInfoLeaf from "../../Screens/Admin/Components/SubComponents/AdminInfoLeaf";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import { useTranslation } from "react-i18next";

const ToothFairyUrlViewer = ({
  label = "Base path",
  baseUrl = envConfig.REACT_APP_REST_ENDPOINT,
  caption = "Value copied to clipboard",
  path,
  method = "POST",
  urlInputWidth = 400,
  showSimpleText = false,
}) => {
  const getChipColor = (method) => {
    switch (method) {
      case "GET":
        return "green";
      case "POST":
        return "orange";
      case "DELETE":
        return "red";
    }
  };
  const { currentTheme } = AppTheme.useTheme();
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 10,
      }}
    >
      <AppText
        wrapperStyle={{
          marginRight: 20,
        }}
        style={{
          fontFamily: "boldFont",
          color: currentTheme?.theme?.primary,
        }}
      >
        {label}
      </AppText>
      <AdminInfoLeaf
        showSimpleText={showSimpleText}
        marginLeft={10}
        width={urlInputWidth}
        handleClick={() => {
          Clipboard.setString(baseUrl);
          AppToasty.show(t(caption), {
            type: "success",
          });
        }}
        value={`${envConfig.REACT_APP_REST_ENDPOINT}/${path}`}
      />
      <AppTextChip
        fontWeight="bold"
        backgroundColor={currentTheme?.theme[getChipColor(method)]}
        borderColor={currentTheme?.theme[getChipColor(method)]}
        label={method}
        styleContainer={{
          minWidth: 100,
        }}
      />
    </View>
  );
};

export default ToothFairyUrlViewer;
