import React, { useEffect, useMemo, useState } from "react";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { AppText, AppIcon } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import ArenaContainer from "./ArenaContainer";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import { View, useWindowDimensions } from "react-native";
import ChatWebInput from "./Components/ChatWebInput";
import battleHelper from "../../API/Battle/battleHelper";
import Confetti from "react-confetti";
import { useHistory } from "../../Router";
import usePath from "../../Hooks/usePath";
import ArenaHeader from "./Components/ArenaHeader";
import useWorkspaces from "../../Hooks/useWorkspaces";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";

const Controller = () => {
  const { height, width } = useWindowDimensions();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { params } = usePath();
  const { getActiveWorkspace } = useWorkspaces();
  const [quickQuestions, setQuickQuestions] = useState([]);
  const history = useHistory();
  const [runConfetti, setRunConfetti] = useState(false);
  const AppToasty = AppToast.useToast();
  const [firstChatId, setFirstChatId] = useState(null);
  const [secondChatId, setSecondChatId] = useState(null);
  const [battleId, setBattleId] = useState(null);
  const [firstAgent, setFirstAgent] = useState(null);
  const [secondAgent, setSecondAgent] = useState(null);
  const [isFirstAIStreaming, setIsFirstAIStreaming] = useState(false);
  const [isSecondAIStreaming, setIsSecondAIStreaming] = useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const [winner, setWinner] = useState(null);
  const [inputText, setInputText] = useState("");
  const [isAIStreaming, setIsAIStreaming] = useState(false);
  const { currentTheme, mode, setMode } = AppTheme.useTheme();
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const getRandomAgent = (type, existingAgent) => {
    const filteredAgents = state?.agents?.filter(
      (agent) => agent.mode === type && agent?.id !== existingAgent?.id
    );

    // If no agents are found after filtering, return null
    if (filteredAgents.length === 0) {
      return null;
    }

    // Assign equal probability to each filtered agent
    const equalProbability = 1 / filteredAgents.length;

    // Select a random agent with equal probability
    const randomIndex = Math.floor(Math.random() * filteredAgents.length);
    return filteredAgents[randomIndex];
  };
  const onMessageSent = (message) => {
    setInputText(message);
    setShowSplash(false);
    setIsAIStreaming(true);
    setIsFirstAIStreaming(true);
    setIsSecondAIStreaming(true);
    setTimeout(() => {
      setInputText("");
    }, 1000);
  };
  const _webInput = useMemo(() => {
    return (
      <ChatWebInput
        placeholder={"Type your message here"}
        setText={setInputText}
        isStreaming={isFirstAIStreaming || isSecondAIStreaming}
        onCancel={() => {}}
        onSend={onMessageSent}
        allowStop={!isAIStreaming}
        isChatEmpty={showSplash}
      />
    );
  }, [isFirstAIStreaming, isSecondAIStreaming, setInputText, showSplash]);
  const setupNewBattle = async (randomAgents = false) => {
    try {
      // const agentTypes = ["retriever", "chatter", "coder"];
      // const randomIndex = Math.floor(Math.random() * agentTypes.length);
      const firstAgentType = state?.selectedArenaMode;

      if (randomAgents) {
        const _firstAgent = getRandomAgent(firstAgentType, firstAgent);
        const _secondAgent = getRandomAgent(firstAgentType, secondAgent);
        if (!_firstAgent?.id || !_secondAgent?.id) {
          AppToasty.show(
            "You must have at least two agents of the same type in the workspace",
            {
              placement: "bottom",
              type: "warning",
            }
          );
          return;
        }
        setFirstAgent(_firstAgent);
        setSecondAgent(_secondAgent);
      } else {
        const _firstAgent = firstAgent;
        const _secondAgent = secondAgent;

        setFirstAgent(null);
        setSecondAgent(null);
        setTimeout(() => {
          setFirstAgent(_firstAgent);
          setSecondAgent(_secondAgent);
        }, [1000]);
      }
      setIsFirstAIStreaming(false);
      setIsSecondAIStreaming(false);
      setWinner(null);
    } catch (error) {
      AppToasty.show(
        "An error occured while setting up the battle. Please try again.",
        {
          placement: "bottom",
          type: "danger",
        }
      );
      return;
    }
  };
  const createBattle = async () => {
    setIsFirstMessage(true);
    const result = await battleHelper.createBattle_(
      {
        firstAgent: firstAgent.id,
        secondAgent: secondAgent.id,
        createdAt: new Date().toISOString(),
        workspaceID: getActiveWorkspace()?.id,
      },
      getActiveWorkspace()?.workspaceToken
    );
    setBattleId(result?.data?.createArenaBattle?.id);
  };
  useEffect(() => {
    if (
      firstAgent?.id &&
      secondAgent?.id &&
      firstAgent?.id === secondAgent?.id
    ) {
      AppToasty.show("You cannot battle an agent against itself", {
        placement: "bottom",
        type: "warning",
      });
      return;
    }
    if (firstAgent?.id && secondAgent?.id && getActiveWorkspace()?.id) {
      createBattle();
    }
  }, [secondAgent?.id, firstAgent?.id, getActiveWorkspace()?.id]);
  useEffect(() => {
    if (
      getActiveWorkspace()?.id &&
      state?.agents?.length > 0 &&
      state?.selectedArenaMode != "none"
    ) {
      setupNewBattle(true);
    }
  }, [
    getActiveWorkspace()?.id,
    state?.agents?.length,
    state?.selectedArenaMode,
  ]);
  useEffect(() => {
    if (firstChatId && secondChatId && battleId) {
      battleHelper.updateBattle(battleId, [
        { property: "firstAgentChatID", value: firstChatId },
        { property: "secondAgentChatID", value: secondChatId },
      ]);
    }
  }, [firstChatId, secondChatId, battleId]);
  // useEffect(() => {
  //   setQuickQuestions([
  //     "Can you generate a simple mindmap explaining LLMs?",
  //     "What is the best way to learn AI?",
  //     "Can you help me plan a trip to Rome of 5 days?",
  //   ]);
  // }, []);
  useEffect(() => {
    if (winner && battleId) {
      battleHelper.updateBattle(battleId, [
        { property: "winnerID", value: winner },
      ]);
      if (
        state?.agents.find(
          (agent) => agent.id === winner && agent.type === "open"
        )
      ) {
        AppToasty.show("🧚 ToothFairyAI won! Yay!", {
          type: "success",
          duration: 4000,
          placement: "top",
        });
        setRunConfetti(true);
      } else {
        AppToasty.show("🤖 A closed sourced model won!", {
          type: "danger",
          duration: 4000,
          placement: "center",
        });
      }
    }
  }, [winner]);
  return (
    <View
      style={{
        height: height - 45,
      }}
    >
      <Confetti
        width={width}
        height={height}
        recycle={false}
        numberOfPieces={runConfetti ? 2000 : 0}
        wind={0}
        gravity={0.1}
        initialVelocityX={4}
        initialVelocityY={12}
        opacity={1}
        onConfettiComplete={(confetti) => {
          confetti.reset();
          setRunConfetti(false);
        }}
        colors={[
          currentTheme?.theme?.primary,
          currentTheme?.theme?.primary_soft,
          currentTheme?.theme?.primary_medium,
          currentTheme?.theme?.white,
        ]}
      />
      <ArenaHeader />

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 5,
          padding: 5,
          flex: 1,
        }}
        key={battleId}
      >
        {state?.selectedArenaMode != "none" ? (
          <>
            <ArenaContainer
              key={`first-arena`}
              setWinner={setWinner}
              isWinner={winner === firstAgent?.id}
              agentId={firstAgent?.id}
              workspaceId={getActiveWorkspace()?.id}
              onChatCreated={(chatId) => {
                setFirstChatId(chatId);
              }}
              chatId_prop={firstChatId}
              setAgent={setFirstAgent}
              onStreamFinished={() => {
                setIsFirstAIStreaming(false);
              }}
              _inputText={inputText}
              _agentName={firstAgent?.label}
              isFirstMessage={isFirstMessage}
              setIsFirstMessage={setIsFirstMessage}
            />
            <ArenaContainer
              key={`second-arena`}
              setWinner={setWinner}
              isWinner={winner === secondAgent?.id}
              agentId={secondAgent?.id}
              chatId_prop={secondChatId}
              workspaceId={getActiveWorkspace()?.id}
              onChatCreated={(chatId) => {
                setSecondChatId(chatId);
              }}
              setAgent={setSecondAgent}
              _inputText={inputText}
              onStreamFinished={() => {
                setIsSecondAIStreaming(false);
              }}
              _agentName={secondAgent?.label}
              isFirstMessage={isFirstMessage}
              setIsFirstMessage={setIsFirstMessage}
            />
          </>
        ) : (
          <AppPlaceholderView
            wrapperStyle={{
              top: "30%",
            }}
            accessibilityLabel="arena-placeholder"
            size={60}
            icon={AppAssets.icons.orbit}
            mainCaption={"Please select an agent mode"}
            subCaption={"The arena compares only agents of the same type"}
          />
        )}
      </View>
      {state?.selectedArenaMode != "none" && (
        <>
          {_webInput}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              paddingHorizontal: 10,
            }}
          >
            <AppText
              wrapperStyle={{
                alignSelf: "center",
                marginBottom: 10,
                gap: 5,
              }}
              style={{
                marginTop: 3,
              }}
              color={currentTheme?.theme?.primary}
              isClickable
              onPress={() => setupNewBattle(false)}
              leftIcon={AppAssets?.icons?.newBattle}
            >
              New round
            </AppText>
            {quickQuestions && quickQuestions?.length > 0 && (
              <div
                style={{
                  width: "100%",

                  display: "flex",
                  // alignSelf: "flex-end",
                  flexDirection: "row",
                  justifyContent: "center",
                  // alignItems: "flex-end",
                  flexWrap: "wrap",
                  marginBottom: 5,
                  gap: 5,
                }}
              >
                {quickQuestions?.map((question, index) => (
                  <AppText
                    isClickable
                    key={index}
                    onPress={() => onMessageSent(question)}
                    wrapperStyle={{
                      paddingVertical: 5,
                      paddingRight: 10,
                      paddingLeft: 5,
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: currentTheme?.theme?.transparent,
                      backgroundColor: currentTheme?.theme?.primary_soft,
                    }}
                    style={{
                      marginLeft: 5,
                      fontSize: currentTheme?.theme?.extra_small_font_size,
                      textDecorationColor: currentTheme?.theme?.primary,
                    }}
                    color={currentTheme?.theme?.primary}
                    leftIcon={AppAssets?.icons?.question_icon}
                    iconSize={16}
                  >
                    {question}
                  </AppText>
                ))}
              </div>
            )}

            <AppIcon
              onPress={() => {
                setMode(mode == "dark" ? "light" : "dark");
              }}
              icon={
                mode == "dark"
                  ? AppAssets?.icons?.theme_adjust
                  : AppAssets?.icons?.theme_adjust
              }
              color={currentTheme?.theme?.primary}
              isClickable
              size={16}
              wrapperStyle={{
                cursor: "pointer",
                rotate: mode == "dark" && "180deg",
                marginRight: 5,
              }}
            />
          </View>
        </>
      )}
    </View>
  );
};

export default Controller;
