import API, { graphqlOperation } from "@aws-amplify/api";
import { DataStore } from "@aws-amplify/datastore";
import { useWhisper } from "@chengsokdara/use-whisper";
import useAPI from "@toothfairy/shared-api/useApi";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { displayedToasties } from "../../../App";
import envConfig from "../../../envConfig";
import foldersHelper from "../../API/Folders/foldersHelper";
import Generator from "../../API/Generator";
import Searcher from "../../API/Searcher";
import trainingAPI from "../../API/Training/trainingAPI";
import useAutoSave from "../../Hooks/useAutoSave";
import useFileSelector from "../../Hooks/useFileSelector";
import useTrainModeSwitcher from "../../Hooks/useTrainModeSwitcher";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory, useParams } from "../../Router";
import { onUpdateDocumentEmbeddingStatus } from "../../graphql/subscriptions";
import { Folders } from "../../models";
import { isMobileScreen } from "../MainViewContainer";
import MrcDocumentsView from "./MrcDocumentsView";
import { debounce } from "lodash-es";
import useRecording from "../../Hooks/useRecording";

const MrcDocumentsContainer = () => {
  const { documentId } = useParams();
  const [searchText, setSearchText] = useState();
  const [publishOnUpload, setPublishOnUpload] = useState(true);
  const history = useHistory();
  const { goToTrainMode } = useTrainModeSwitcher({
    route: "mrc_contents",
    isMrc: true,
  });
  const { transcript, startRecording, stopRecording } = useRecording({
    onForcedStop: () => {},
    maxTimeForRecording: 3600,
  });
  const [embeddingStatus, setEmbeddingStatus] = useState("not_ready");
  const [isExtractImagesAllowed, setIsExtractImagesAllowed] = useState(false);
  const [embeddingProgress, setEmbeddingProgress] = useState(0);
  const [selectedNodeForTreeview, setSelectedNodeForTreeView] = useState(null);
  function findParentById(tree, id, parent = null) {
    for (const node of tree) {
      if (node.id === id) {
        return parent;
      }

      if (node.children && node.children.length > 0) {
        const result = findParentById(node.children, id, node);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }
  // create a similar function that finds mrcRoot with the id of the node and returns the entire node
  function findNodeById(tree, id) {
    for (const node of tree) {
      if (node.id === id) {
        return node;
      }

      if (node.children && node.children.length > 0) {
        const result = findNodeById(node.children, id);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  const {
    loading: vectorSearchInProgress,
    apiRequest: vectorSearchRequest,
    wasInvoked: vectorSearchInvoked,
    data: vectorSearchResult,
  } = useAPI(Searcher.getDocumentsByVectorDB, null, () => {});
  const {
    loading: embeddingsByDocumentInProgress,
    apiRequest: embeddingsByDocumentRequest,
    wasInvoked: embeddingsByDocumentInvoked,
    data: embeddingsByDocumentResult,
  } = useAPI(trainingAPI.getEmbeddingByDocumentID, null, () => {});
  const { getActiveWorkspace, getWorkspaceEntities, agcEnabled } =
    useWorkspaces();
  const fetchEmbeddingsByDocument = () => {
    embeddingsByDocumentRequest(documentId, getActiveWorkspace()?.id);
  };

  // Debounced function to handle API call
  const _loadSuggestions = (query, workspaceid, token) => {
    return vectorSearchRequest({
      text: query,
      workspaceid: workspaceid,
      topK: 5,
      token: token,
    }).then((data) => {
      if (data && data?.length > 0) {
        // Map the results to the expected format for react-select
        return data.map((chunk) => ({
          value: chunk.chunk_id,
          label: `${Math.floor(chunk.cosinesim * 100)}% - ${chunk.title}`,
          title: chunk.title,
        }));
      } else {
        return [];
      }
    });
  };
  const loadSuggestions = React.useCallback(
    debounce((inputText, callback) => {
      _loadSuggestions(
        inputText,
        getActiveWorkspace()?.id,
        getActiveWorkspace()?.workspaceToken
      ).then((options) => callback(options));
    }, 300),
    [getActiveWorkspace()?.id, getActiveWorkspace()?.workspaceToken]
  );

  // const loadSuggestions = throttle(_loadSuggestions, 300);

  const [showModal, setShowModal] = React.useState(false);
  const [deletionInProgress, setDeletionInProgress] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(null);
  const [treeViewActionContext, setTreeViewActionContext] = useState("");
  const [topics, setTopics] = useState([]);
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const { user } = AppUser.useUser();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const documents = state?.mrcDocuments;
  const folders = state?.mrcFolders;
  const setDocuments = (documents) => {
    dispatch("SET_MRC_DOCUMENTS", documents);
  };
  const setFolders = (folders) => {
    dispatch("SET_MRC_FOLDERS", folders);
  };
  useEffect(() => {
    setIsPDFLoaded(false);
  }, []);
  useEffect(() => {
    setTopics(
      getWorkspaceEntities("topic")?.map((topic) => {
        topic.name = topic?.label;
        topic.category = topic.type;
        return topic;
      })
    );
  }, [JSON.stringify(getWorkspaceEntities("topic"))]);
  const onTopicCreated = () => {
    setTopics(
      getWorkspaceEntities("topic")?.map((topic) => {
        topic.name = topic?.label;
        topic.category = topic.type;
        return topic;
      })
    );
  };
  const {
    loading: documentFetchInProgress,
    apiRequest: fetchMRCData,
    wasInvoked: fetchMRCDataInvoked,
  } = useAPI(trainingAPI.getDocumentsByWorkspace, null, () => {});
  const {
    loading: documentTreeStructureSavingInProgress,
    apiRequest: updateDocumentTreeStructure,
  } = useAPI(trainingAPI.updateTrainingData, null, () => {});
  const { loading: documentDeletionInProgress, apiRequest: deleteDocument } =
    useAPI(trainingAPI.deleteTrainingData, null, () => {});

  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeToDelete, setSelectedNodeToDelete] = useState(null);
  const getSelectedFolder = () => {
    if (selectedNode?.data?.droppable) return selectedNode?.id;
    else if (selectedNode && !selectedNode?.data?.droppable)
      return selectedNode?.parent;
    else return "root";
  };
  const onPDFDocumentLoad = (pages) => {
    setIsPDFLoaded(true);
    setIsExtractImagesAllowed(pages < 51);
  };
  const [selectedTopicsForPdfUpload, setSelectedTopicsForPdfUpload] = useState(
    []
  );
  const onSelectedTopicsChangeForPdfUpload = (v) => {
    setSelectedTopicsForPdfUpload(v);
  };
  const {
    value,
    editor,
    isInactive,
    updateAPIError,
    updateAPIInProgress,
    createAPIInProgress,
    getAPIRequest,
    saveDocument,
    handleSocketConnectionChange,
    handleNewMRCDocument,
    saveDocumentTitle,
    setValue,
    setDocumentStatus,
    documentStatus,
    getAPIResult,
    selectedTopics,
    setSelectedTopics,
    isSaved,
    setIsLoading,
    setEditor,
    isLoading,
    isReadyForRTC,
    isDocumentValid,
    generateAdaptedTopics,
    creationofNewDocumentInProgress,
    wordsCount,
    charactersCount,
    fakeCheckAPIResult,
    fakeCheckAPIInProgress,
    isTopicUpdateModeEnabled,
    setIsTopicUpdateModeEnabled,
    setIsPDFLoaded,
    isPDFLoaded,
    isPDF,
    extractImages,
    setExtractImages,
    documentImgRetrievalInstructions,
    setDocumentImgRetrievalInstructions,
    setLastCancellation,
    lastrequestTime,
    setIsSaveRequested,
  } = useAutoSave({
    documentId,
    workspaceid: getActiveWorkspace()?.id,
    user,
    updateMethod: trainingAPI.updateTrainingData,
    createMethod: trainingAPI.postTrainingData,
    route: "mrc_contents",
    documentType: "readComprehensionContent",
    backupProperty: "initialMRCValue",
    backupCommand: "SET_INITIAL_MRC_VALUE",
    savesSkipsForBooting: 0,
    isAGCEnabled: agcEnabled,
    fakeCheckMethod: Generator.fakeCheck,
    fakeCheckScope: "fakeContentCheck",
    fakeCheckExecutionInterval: 1000,
    fakeCheckSkipsForBooting: 0,
  });
  const handleImagesExtraction = (v) => {
    setDocumentImgRetrievalInstructions(
      v?.documentImgRetrievalInstructions || ""
    );
    setIsSaveRequested(Date.now()?.toString());
  };
  const handleOnRecorderClick = (v) => {
    try {
      if (v) {
        startRecording();
        // show the toast message
        AppToasty.show(t("recordingStarted"), {
          placement: "bottom",
          type: "success",
        });
      } else {
        stopRecording();
        AppToasty.show(t("recordingStopped"), {
          placement: "bottom",
          type: "success",
        });
      }
    } catch (error) {
      AppToasty.show(t("recordingError"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  useEffect(() => {
    if (transcript) {
      AppQuantumEditorUtils.Utilities.insertTextAtSelection(
        editor,
        transcript || ""
      );

      setEditor(editor);
      setValue(editor?.children);
    }
  }, [transcript]);
  useEffect(() => {
    if (isMobileScreen()) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isMobileScreen()]);

  useEffect(() => {
    if (getAPIResult?.data?.length && getActiveWorkspace()?.id && !isLoading) {
      const sTopics = getAPIResult?.data[0]?.topics?.map((u) => {
        return {
          name: topics.filter((z) => z.id === u)[0]?.label,
          category: topics.filter((z) => z.id === u)[0]?.type,
          id: u,
        };
      });
      setSelectedTopics(sTopics || []);
      setDocumentStatus(getAPIResult?.data[0]?.status);
      setDocumentImgRetrievalInstructions(
        getAPIResult?.data[0]?.img_retrieval_prompt
      );
      setEmbeddingStatus(getAPIResult?.data[0]?.embedding_status);
      setEmbeddingProgress(getAPIResult?.data[0]?.completion_percentage || 0);
      setIsTopicUpdateModeEnabled(false);
      // saveDocument({ documentId, value, topics: sTopics?.map((u) => u.id) });
    }
  }, [
    lastrequestTime,
    JSON.stringify(getAPIResult?.data),
    JSON.stringify(topics),
    documentId,
    isLoading,
  ]);
  useEffect(() => {
    let subscriptionObj;
    if (documentId) {
      subscriptionObj = API.graphql(
        graphqlOperation(onUpdateDocumentEmbeddingStatus, {
          id: documentId.toString(),
        })
      ).subscribe({
        next: async (data) => {
          if (
            data?.value?.data?.onUpdateDocumentEmbeddingStatus?.id?.toString() !==
            documentId.toString()
          )
            return;
          const embeddingStatus =
            data?.value?.data?.onUpdateDocumentEmbeddingStatus
              ?.embedding_status || "not_ready";
          const embeddingProgress =
            data?.value?.data?.onUpdateDocumentEmbeddingStatus
              ?.completion_percentage || 0;

          setEmbeddingProgress(
            embeddingStatus === "ready" ? 100 : embeddingProgress
          );
          setEmbeddingStatus(embeddingStatus);
        },
      });
    }
    return () => {
      if (subscriptionObj) subscriptionObj.unsubscribe();
    };
  }, [documentId]);
  useEffect(() => {
    if (documentId && getActiveWorkspace()?.id) {
      setSelectedNode({
        id: documentId,
        data: {
          ...selectedNode?.data,
          external_path: documents?.find((u) => u.id == documentId)
            ?.external_path,
        },
      });
    }
  }, [documentId, getActiveWorkspace()?.id, documents?.length]);
  const handleDocumentSelection = (v) => {
    const resultObject = vectorSearchResult?.find(
      (u) => u.chunk_id === v?.value
    );

    const _selectedDocument = state.treeViewData?.find(
      (u) => u.id === resultObject?.doc_id
    );
    if (!_selectedDocument && v?.title?.startsWith("http")) {
      window.open(v?.title, "_blank");
    } else if (_selectedDocument?.id) {
      handleClickOnNode({
        id: _selectedDocument?.id,
        data: _selectedDocument,
      });
    }
  };
  const removeNode = async (node) => {
    setShowModal(false);
    if (state.treeViewData?.find((u) => u.id == node?.id)?.children?.length > 0)
      return AppToasty.show(t("folderNotEmpty"), {
        placement: "bottom",
        type: "danger",
      });
    setDeletionInProgress(true);
    setIsLoading(true);
    if (selectedNode?.id === selectedNodeToDelete.id) {
      history.push(`/workspaces/${getActiveWorkspace()?.id}/mrc_contents`);
      handleClickOnNode(null);
    }
    if (node?.data?.droppable) {
      setFolders(folders.filter((u) => u.id?.toString() != node?.id));
      const result = await foldersHelper.deleteFolder(node?.id);
    } else {
      setDocuments(documents.filter((u) => u.id?.toString() != node?.id));
      const result = await deleteDocument(node?.id, getActiveWorkspace()?.id);
    }
    setDeletionInProgress(false);
    setIsLoading(false);
  };
  const updateNodeName = (node, name) => {
    let itemIndex;
    if (node?.data?.droppable) {
      itemIndex = state?.mrcFolders.findIndex((u) => u.id == node.id);
      let newFolder = { ...state.mrcFolders[itemIndex] };
      newFolder.name = name;
      state.mrcFolders[itemIndex] = newFolder;
      dispatch("SET_MRC_FOLDERS", [...state.mrcFolders]);
    } else {
      itemIndex = state?.mrcDocuments.findIndex((u) => u.id == node.id);
      state.mrcDocuments[itemIndex].title = name;
      dispatch("SET_MRC_DOCUMENTS", [...state.mrcDocuments]);
    }
  };

  const SaveFolder = useCallback(
    async (node, folderName, isExisting = false) => {
      if (isExisting) {
        try {
          await foldersHelper.updateFolder(selectedNodeForTreeview?.id, [
            { property: "name", value: folderName },
          ]);
          updateNodeName(selectedNodeForTreeview, folderName);
        } catch (error) {
          console.error(error);
          AppToasty.show("errorUpdatingFolder", {
            type: "danger",
          });
        }
      } else {
        try {
          // this method break if the selectedFolder is the one selected in teh treeview based on the route
          const newFolder = {
            parent: selectedNodeForTreeview?.data?.droppable
              ? selectedNodeForTreeview?.id
              : selectedNodeForTreeview?.data?.parent || "root",
            droppable: true,
            name: folderName,
            workspaceID: getActiveWorkspace()?.id,
          };

          const result = await foldersHelper.createFolder(newFolder);
          dispatch("SET_MRC_TREEVIEW_DATA", [
            { ...newFolder, id: result?.id },
            ...state?.treeViewData,
          ]);
          setFolders([...folders, { ...newFolder, id: result?.id }]);
        } catch (error) {
          console.error(error);
          AppToasty.show("errorCreatingFolder", {
            type: "danger",
          });
        }
      }
      setShowModal(false);
    },
    [state?.treeViewData, selectedNodeForTreeview?.id]
  );
  const handleDocumentStatusChange = (status) => {
    setDocumentStatus(status);
  };
  const handleClickOnNode = async (node, toDelete = false) => {
    if (toDelete) setSelectedNodeToDelete(node);
    else {
      setSelectedNodeForTreeView(node);
      if (node?.data?.droppable === true) return;
      setSelectedNode(node);
      if (node?.data?.type) {
        setIsPDFLoaded(node?.id === selectedNode?.id);

        history.push(
          `/workspaces/${getActiveWorkspace()?.id}/mrc_contents/${node?.id}`
        );
      } else if (
        node &&
        !node?.data?.droppable &&
        node?.id !== selectedNode?.id &&
        node?.id !== documentId
      ) {
        setIsLoading(true);
        setSelectedTopics([]);
        goToTrainMode({
          docID: node.id,
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    }
  };
  const setDialogActionOnTree = useCallback(
    (action) => {
      setShowModal(true);
      setTreeViewActionContext(action);
    },
    [setShowModal, setTreeViewActionContext]
  );
  const deleteNode = async (node) => {
    try {
      setDeletionInProgress(true);
      setShowModal(false);
      setIsLoading(true);
      if (selectedNode?.id === selectedNodeToDelete.id) {
        // const indexOfDeletedNode =
        //   state?.treeViewData
        //     ?.filter((u) => !u.droppable)
        //     .map((u) => u.id)
        //     ?.indexOf(selectedNodeToDelete.id) - 1;
        handleClickOnNode(null);
        history.push(`/workspaces/${getActiveWorkspace()?.id}/mrc_contents`);
      }

      const treeToDelete = treeify(
        state?.treeViewData,
        "id",
        "parent",
        "children",
        node?.id
      );
      const flattenedList = treeToDelete.flat();
      await Promise.all(
        flattenedList.map((u) => {
          if (u?.data?.droppable) return foldersHelper.deleteFolder(u.id);
          else
            return trainingAPI.deleteTrainingData(
              u.id,
              getActiveWorkspace()?.id
            );
        })
      );
      dispatch("SET_MRC_TREEVIEW_DATA", [
        ...state?.treeViewData?.filter((u) => u?.id !== node?.id),
      ]);

      setTimeout(() => {
        setDeletionInProgress(false);
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.error(error);
      AppToasty.show("errorDeletingFolder", {
        type: "danger",
      });
    }
  };
  const createMRCDocument = async (title, topics, status) => {
    setIsLoading(true);
    let locationNode = selectedNodeForTreeview;
    if (!selectedNodeForTreeview?.data?.droppable) {
      const parent = findParentById(
        state.treeViewData,
        selectedNodeForTreeview?.id
      );
      locationNode = {
        id: parent?.id,
        data: parent,
      };
    }
    if (!locationNode?.id) {
      const parent = findNodeById(state.treeViewData, "mrcRoot");
      locationNode = {
        id: parent?.id,
        data: parent,
      };
    }
    await handleNewMRCDocument(true, locationNode, title, topics, status);
    setShowModal(false);
    setIsLoading(false);
    setSelectedTopics(topics);
  };
  const editDocumentTitle = async (title) => {
    try {
      await saveDocumentTitle({
        documentId,
        workspaceid: getActiveWorkspace()?.id,
        title,
      });
      updateNodeName(selectedNodeForTreeview, title);
      setShowModal(false);
    } catch (error) {
      console.error(error);
      AppToasty.show("errorUpdatingDocument", {
        type: "danger",
      });
    }
  };
  const onSelectedTopicsChange = useCallback(
    (selectedItems) => {
      setSelectedTopics(selectedItems);
    },
    [setSelectedTopics, isTopicUpdateModeEnabled]
  );
  useEffect(() => {
    if (getActiveWorkspace()?.id) loadTrainingData();
  }, [getActiveWorkspace()?.id]);
  const loadTrainingData = async () => {
    await getDocuments();
  };
  const getDocuments = async () => {
    const folders = await DataStore.query(Folders, (e) =>
      e.workspaceID("eq", getActiveWorkspace()?.id)
    );
    const workspaceID = getActiveWorkspace()?.id;
    const promise1 = fetchMRCData({
      workspaceID,
      type: "readComprehensionContent",
      fields: ["id", "folderid", "title"],
      page: -1,
      pageLimit: 500,
    });

    const promise2 = fetchMRCData({
      workspaceID,
      type: "questionAnswering",
      fields: ["id", "folderid", "title"],
      page: -1,
      pageLimit: 500,
    });

    const promise3 = fetchMRCData({
      workspaceID,
      type: "readComprehensionPdf",
      fields: ["id", "folderid", "title", "external_path"],
      page: -1,
      pageLimit: 500,
    });
    const promise4 = fetchMRCData({
      workspaceID,
      type: "readComprehensionFile",
      fields: ["id", "folderid", "title", "external_path"],
      page: -1,
      pageLimit: 500,
    });
    // Enable the retrieval of the html and website pages
    // const promise5 = fetchMRCData({
    //   workspaceID,
    //   type: "readComprehensionUrl",
    //   fields: ["id", "folderid", "title", "external_path"],
    //   page: -1,
    //   pageLimit: 500,
    // });

    const [
      result,
      qaResult,
      pdfResult,
      fileResult,
      // urlResult,
    ] = await Promise.all([promise1, promise2, promise3, promise4]);

    const documents = [
      ...result?.data,
      ...qaResult?.data,
      ...pdfResult?.data,
      ...fileResult?.data,
      // ...urlResult?.data,
    ]?.map((u) => {
      return {
        external_path: u?.external_path,
        folderid: u?.folderid,
        id: u?.id,
        title: `${u?.title}${
          u?.external_path ? "." + u?.external_path?.split(".")?.pop() : ""
        }`,
      };
    });
    setDocuments(documents);
    setFolders(folders);
    dispatch("SET_FOLDERS_LOADED", true);
  };
  useEffect(() => {
    if (state?.foldersLoaded) adaptFlatListToTreeView(documents, folders);
  }, [documents, folders, state?.foldersLoaded]);
  const adaptFlatListToTreeView = (documents, folders) => {
    const adaptedFiles =
      documents?.map((u) => {
        return {
          id: u.id.toString(),
          parent: u.folderid || "mrcRoot",
          name: u.title || "No title",
          droppable: false,
          type: u?.external_path && u?.external_path?.split(".")?.pop(),
          external_path: u?.external_path,
        };
      }) || [];

    dispatch("SET_MRC_TREEVIEW_DATA", [
      ...(folders?.map((u) => ({ ...u, droppable: true, children: [] })) || []),
      {
        id: "mrcRoot",
        droppable: true,
        name: "General content",
        status: "active",
        parent: "root",
        readOnly: true,
      },
      {
        id: "qaRoot",
        droppable: true,
        name: "Q&A",
        status: "active",
        parent: "root",
        readOnly: true,
      },
      ...adaptedFiles,
    ]);
  };
  const handleEntityCreation = useCallback(
    (event) => {
      if (event === "success")
        AppToasty.show(t("topicCreationSuccessCaption"), {
          type: "success",
        });
      else
        AppToasty.show(t("topicCreationFailedCaption"), {
          type: "danger",
        });
    },
    [AppToasty, t]
  );
  const handleEditorError = (event) => {
    console.error("error", event);
    if (event.message) {
      if (!displayedToasties()) {
        const id = AppToasty.show(t(event.message), {
          type: "danger",
        });
        displayedToasties(id);
      }
    }
  };
  const {
    handleFileSelect: handleFileSelectGer_,
    filePath: filePathGeneric,
    handleImports: handleImportsGeneric,
    isFileUploadInProgress,
    batchUploadStatus: batchUploadStatusGeneric,
  } = useFileSelector({
    _importType: "imported-files",
    _importFile: "generic",
    contentType: "generic",
    additionalFilenameParams: ``,
    _appLogImportType: "documentImport",
    _appLogImportEvent: "readComprehensionContent file import",
    showSuccessOnUpload: false,
    flat: false,
    createDocument: true,
    uploadOnSelection: true,
    showPreview: true,
    allowMultiple: true,
    topics: topics,
    onShowPreview: (topics) => {
      setShowModal(true);
      setPublishOnUpload(true);
      if (topics?.length == 1) {
        setSelectedTopicsForPdfUpload(generateAdaptedTopics([topics[0]]));
      } else {
        setSelectedTopicsForPdfUpload(null);
      }
      setTreeViewActionContext("IMPORT_FILE");
    },
    onDocumentsCreated: (files) => {
      setDocuments([...documents, ...files]);
      setShowModal(false);
      history.push(
        `/workspaces/${getActiveWorkspace()?.id}/mrc_contents/${files[0]?.id}`
      );
    },
    onDocumentCreated: (file) => {
      setDocuments([...documents, file?.data[0]]);
      setShowModal(false);
      history.push(
        `/workspaces/${getActiveWorkspace()?.id}/mrc_contents/${
          file?.data[0]?.id
        }`
      );
    },
  });

  const {
    handleFileSelect: handleFileSelectPdf,
    isFileUploadInProgress: isPDFUploadInProgress,
    filePath,
    importFileFromHook,
    fileUploadProgress,
    handleImports,
    batchUploadStatus,
  } = useFileSelector({
    _importType: "imported-pdf",
    _importFile: "pdf",
    contentType: "application/pdf",
    additionalFilenameParams: ``,
    _appLogImportType: "documentImport",
    _appLogImportEvent: "readComprehensionContent pdf import",
    showSuccessOnUpload: false,
    flat: false,
    createDocument: true,
    uploadOnSelection: true,
    showPreview: true,
    allowMultiple: true,
    topics: topics,
    onShowPreview: (topics) => {
      setShowModal(true);
      setPublishOnUpload(true);
      if (topics?.length == 1) {
        setSelectedTopicsForPdfUpload(generateAdaptedTopics([topics[0]]));
      } else {
        setSelectedTopicsForPdfUpload(null);
      }
      setTreeViewActionContext("UPLOAD_PDF");
    },
    onDocumentsCreated: (files) => {
      setDocuments([...documents, ...files]);
      setShowModal(false);
      // redirect
      history.push(
        `/workspaces/${getActiveWorkspace()?.id}/mrc_contents/${files[0]?.id}`
      );
    },
    onDocumentCreated: (file) => {
      setDocuments([...documents, file?.data[0]]);
      setShowModal(false);
      history.push(
        `/workspaces/${getActiveWorkspace()?.id}/mrc_contents/${
          file?.data[0]?.id
        }`
      );
    },
  });

  const onMove = async (dropPayload) => {
    try {
      const object = state?.treeViewData?.find(
        (u) => u.id === dropPayload.dragIds[0]
      );
      if (object?.droppable) {
        const index = folders.map((u) => u.id?.toString()).indexOf(object.id);
        let folder = { ...folders[index] };
        folder.parent = dropPayload.parentId;
        folders[index] = folder;
        setFolders([...folders]);
        await foldersHelper.updateFolder(object.id, [
          { property: "parent", value: dropPayload.parentId },
        ]);
      } else {
        // find the index of the document in the documents array
        const index = documents.map((u) => u.id?.toString()).indexOf(object.id);
        documents[index].folderid = dropPayload.parentId;
        setDocuments([...documents]);
        await updateDocumentTreeStructure({
          fields: {
            id: object.id,
            workspaceid: getActiveWorkspace()?.id,
            folderid: dropPayload.parentId,
          },
        });
      }
    } catch (error) {
      AppToasty.show(t("errorMovingFolder"), {
        type: "danger",
      });
    }
  };
  const onDrop = async ({ newTreeData, dragSourceId, dropTargetId, rest }) => {
    if (rest.dragSource?.data?.droppable) {
      await foldersHelper.updateFolder(dragSourceId, [
        { property: "parent", value: dropTargetId },
      ]);
    } else {
      await updateDocumentTreeStructure({
        fields: {
          id: dragSourceId,
          workspaceid: getActiveWorkspace()?.id,
          folderid: dropTargetId,
        },
      });
    }
    dispatch("SET_MRC_TREEVIEW_DATA", [...newTreeData]);
  };

  function treeify(list, idAttr, parentAttr, childrenAttr, targetNodeId) {
    if (!idAttr) idAttr = "id";
    if (!parentAttr) parentAttr = "parent";
    if (!childrenAttr) childrenAttr = "children";
    const _targetList = list?.filter((u) => u.id === targetNodeId);
    var treeList = [];
    var lookup = {};

    _targetList.forEach(function (obj) {
      lookup[obj[idAttr]] = obj;
      obj[childrenAttr] = [];
    });
    _targetList.forEach(function (obj) {
      if (obj[parentAttr] != null) {
        if (lookup[obj[parentAttr]] !== undefined) {
          lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
          treeList.push(obj);
        }
      } else {
        treeList.push(obj);
      }
    });
    return treeList;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <MrcDocumentsView
        setLastCancellation={setLastCancellation}
        isExtractImagesAllowed={isExtractImagesAllowed}
        embeddingsByDocumentInProgress={embeddingsByDocumentInProgress}
        extractImages={extractImages}
        setExtractImages={setExtractImages}
        embeddingStatus={embeddingStatus}
        embeddingsByDocumentResult={embeddingsByDocumentResult}
        folders={folders}
        deleteNode={removeNode}
        wordsCount={wordsCount}
        charactersCount={charactersCount}
        isInactive={isInactive}
        creationofNewDocumentInProgress={creationofNewDocumentInProgress}
        isReadyForRTC={isReadyForRTC}
        isDocumentValid={isDocumentValid && selectedNode}
        isLoading={isLoading}
        selectedNode={selectedNode}
        selectedNodeToDelete={selectedNodeToDelete}
        _coreTreeViewData={state.treeViewData}
        documentId={documentId}
        createDocument={createMRCDocument}
        SaveFolder={SaveFolder}
        handleEditorError={handleEditorError}
        value={value}
        onMove={onMove}
        handleOnRecorderClick={handleOnRecorderClick}
        setEditor={setEditor}
        documentStatus={documentStatus}
        onDocumentStatusChange={handleDocumentStatusChange}
        // initialOpen={getFolderHierarchyUpWards()}
        setValue={setValue}
        onSocketConnectionChange={handleSocketConnectionChange}
        handleClickOnNode={handleClickOnNode}
        setSelectedNodeForTreeView={setSelectedNodeForTreeView}
        setDialogActionOnTree={setDialogActionOnTree}
        showModal={showModal}
        setShowModal={setShowModal}
        treeViewActionContext={treeViewActionContext}
        documentFetchInProgress={documentFetchInProgress}
        fetchMRCDataInvoked={fetchMRCDataInvoked}
        storeInError={updateAPIError}
        storeInProgress={updateAPIInProgress || createAPIInProgress}
        isSaved={isSaved}
        selectedNodeForTreeview={selectedNodeForTreeview}
        selectedTopics={selectedTopics}
        setSelectedTopics={setSelectedTopics}
        onSelectedTopicsChange={onSelectedTopicsChange}
        topics={generateAdaptedTopics(topics)}
        handleEntityCreation={handleEntityCreation}
        handleFileSelectPdf={handleFileSelectPdf}
        onDrop={onDrop}
        isTreeDataLoading={
          documentFetchInProgress ||
          !fetchMRCDataInvoked ||
          !state?.foldersLoaded ||
          deletionInProgress ||
          isPDFUploadInProgress ||
          isFileUploadInProgress
        }
        editDocumentTitle={editDocumentTitle}
        agcEnabled={agcEnabled}
        fakeCheckInProgress={fakeCheckAPIInProgress}
        fakeCheckData={fakeCheckAPIResult}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isTopicUpdateModeEnabled={isTopicUpdateModeEnabled}
        setIsTopicUpdateModeEnabled={setIsTopicUpdateModeEnabled}
        filePath={filePath}
        filePathGeneric={filePathGeneric}
        importFileFromHook={importFileFromHook}
        onPDFDocumentLoad={onPDFDocumentLoad}
        isPDF={selectedNode?.data?.external_path != null}
        isPDFLoaded={isPDFLoaded}
        isPDFUploadInProgress={isPDFUploadInProgress || isFileUploadInProgress}
        embeddingProgress={embeddingProgress}
        fileUploadProgress={fileUploadProgress}
        setTreeViewActionContext={setTreeViewActionContext}
        setPublishOnUpload={setPublishOnUpload}
        publishOnUpload={publishOnUpload}
        handleImports={() =>
          handleImports(
            "readComprehensionPdf",
            selectedNodeForTreeview?.data?.droppable
              ? selectedNodeForTreeview?.data?.id
              : selectedNodeForTreeview?.data?.parent,
            publishOnUpload ? "published" : "draft",
            selectedTopicsForPdfUpload
          )
        }
        handleFileSelectGeneric={handleFileSelectGer_}
        handleImportsGeneric={() =>
          handleImportsGeneric(
            "readComprehensionFile",
            selectedNodeForTreeview?.data?.droppable
              ? selectedNodeForTreeview?.data?.id
              : selectedNodeForTreeview?.data?.parent,
            publishOnUpload ? "published" : "draft",
            selectedTopicsForPdfUpload,
            "generic",
            "imported_doc_files"
          )
        }
        batchUploadStatus={{
          ...batchUploadStatus,
          ...batchUploadStatusGeneric,
        }}
        documentImgRetrievalInstructions={documentImgRetrievalInstructions}
        setDocumentImgRetrievalInstructions={
          setDocumentImgRetrievalInstructions
        }
        selectedTopicsForPdfUpload={selectedTopicsForPdfUpload}
        setSelectedTopicsForPdfUpload={setSelectedTopicsForPdfUpload}
        onSelectedTopicsChangeForPdfUpload={onSelectedTopicsChangeForPdfUpload}
        searchText={searchText}
        setSearchText={setSearchText}
        promiseOptions={loadSuggestions}
        handleDocumentSelection={handleDocumentSelection}
        onTopicCreated={onTopicCreated}
        fetchEmbeddingsByDocument={fetchEmbeddingsByDocument}
        setIsSaveRequested={setIsSaveRequested}
        handleImagesExtraction={handleImagesExtraction}
      />
    </Suspense>
  );
};

export default MrcDocumentsContainer;
