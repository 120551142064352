const logStatusColors = {
  dispatched: "darkGrey",
  swapModelRequested: "darkGrey",
  inProgress: "orangeBtnColor",
  completed: "green",
  inError: "red",
  cancellationRequested: "orangeBtnColor",
  cancelled: "red",
  swapping: "orangeBtnColor",
  swapModelFailed: "red",
};
const mapBackgroundColorWithAgentTypeAndMode = (agent, currentTheme) => {
  const _mode = agent.mode;

  switch (_mode) {
    case "retriever":
      return currentTheme?.theme?.primary;
    case "chatter":
      return currentTheme?.theme?.green;
    case "coder":
      return currentTheme?.theme?.orange;
    default:
      return currentTheme?.theme?.primary;
  }
};

export default { logStatusColors, mapBackgroundColorWithAgentTypeAndMode };
