import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import sitesHelper from "../../../../API/Sites/sitesHelper";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import SiteSettingsView from "../SubComponents/SiteSettingsView";

let prestring = "toothfairyai-verification=";

function generateRandomString(length) {
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return prestring + result;
}

const SitesSettings = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onFunctionCreated,
      selectedId,
      setShowSiteModal,
      requestValidation,
      displayTokenSection,
      setDisplayTokenSection,
      setInitialMapValues,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();
    const { state, dispatch } = AppStateManager.useAppStateManager();
    const { getActiveWorkspace } = useWorkspaces();

    const validationSchema = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      description: AppForms.yup.string().nullable().label().max(255),
      name: AppForms.yup.string().nullable().label().max(255),
      freeRoaming: AppForms.yup.boolean().nullable().label(),
      useProxy: AppForms.yup.boolean().nullable().label(),
      extractImages: AppForms.yup.boolean().nullable().label(),
      imgRetrievalPrompt: AppForms.yup.string().nullable().label().max(255),
      scrapingCycle: AppForms.yup.number().nullable().label(),
      url: AppForms.yup
        .string()
        .required()
        .label()
        .test(
          "is-domain",
          "Must be a valid url (e.g. https://mywebsite.com)",
          (value) => {
            const regex =
              /^https:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](?:\/[\w-]+)*\/?$/i;
            return regex.test(value);
          }
        )
        .max(255)
        .nullable(),
      allowedTopics: AppForms.yup.array().label().max(10).nullable(), // allowedTopics can be an empty array
      // add promptLength which must be a number between 128 to 512
    });

    const handleDeletion = async (id) => {
      try {
        const result = await sitesHelper.deleteSite(id);
        dispatch("SET_SITES", [...state.sites?.filter((u) => u?.id !== id)]);
        AppToasty.show(`Site deleted successfully`, {
          type: "success",
        });
        setTimeout(() => {
          setShowSiteModal(false);
        }, [1000]);
      } catch (error) {
        console.error("siteDeleteErrorCaption", error);
        AppToasty.show(`Site deletion failed`, {
          type: "danger",
        });
      }
    };
    const identifyOptByID = (id) => {
      return state.sites.find((u) => u?.id === id);
    };

    const handleSiteItemSave = async (v) => {
      if (!v?.topics || v.topics?.length === 0)
        return AppToasty.show(`Please select at least one topic`, {
          type: "danger",
        });
      let newSites = [...state.sites];
      const _randomString = generateRandomString(40);
      try {
        let result;
        if (isEdit) {
          result = await sitesHelper.updateSite(selectedId, [
            {
              property: "name",
              value: v.url,
            },
            {
              property: "description",
              value: v.description,
            },
            {
              property: "freeRoaming",
              value: v.freeRoaming,
            },
            {
              property: "useProxy",
              value: v.useProxy,
            },
            {
              property: "extractImages",
              value: v?.extractImages,
            },
            {
              property: "imgRetrievalPrompt",
              value: v.imgRetrievalPrompt,
            },
            {
              property: "scrapingCycle",
              // convert string to number
              value: Number(v?.scrapingCycle) || 0,
            },
            {
              property: "topics",
              value: v.topics?.map((t) => t?.id),
            },
          ]);
          const _updatedObject = identifyOptByID(result?.id);
          const updateIndex = newSites.findIndex(
            (u) => u?.id === _updatedObject?.id
          );
          newSites[updateIndex] = result;
        } else {
          let allowedPaths = null;
          let refinedUrl = `${v.url}`;
          const purifiedUrl = v.url.replace("https://", "");
          // split the url taking the path after the / and use it for the allowed_paths
          if (purifiedUrl.includes("/")) {
            refinedUrl = `https://${purifiedUrl.split("/")[0]}`;
            allowedPaths = [v.url];
          }
          result = await sitesHelper.createSite({
            ...v,
            url: refinedUrl,
            allowedPaths,
            validationToken: _randomString,
            status: "readyForValidation",
            name: v.url,
            scrapingCycle: Number(v?.scrapingCycle) || 0,
            topics: v.topics?.map((t) => t?.id),
            freeRoaming: v.freeRoaming,
            useProxy: v.useProxy,
            extractImages: v?.extractImages,
            imgRetrievalPrompt: v.imgRetrievalPrompt,
            workspaceID: getActiveWorkspace()?.id,
          });

          newSites.push(result);
        }

        dispatch("SET_SITES", [...newSites]);

        if (!isEdit) {
          setInitialMapValues({
            ...result,
          });
          setDisplayTokenSection(true);
        } else setShowSiteModal(false);
        backTo && navigateBack();
        onFunctionCreated && onFunctionCreated("success");
      } catch (error) {
        console.error(error);
        onFunctionCreated && onFunctionCreated("error");
      } finally {
      }
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            // height: 500,
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Site : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={handleSiteItemSave}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <SiteSettingsView
              setDisplayTokenSection={setDisplayTokenSection}
              displayTokenSection={displayTokenSection}
              requestValidation={requestValidation}
              handleDeletion={handleDeletion}
              isEdit={isEdit}
              settingType={settingType}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [
        state.activeWorkspaceID,
        JSON.stringify(initialMapValues),
        JSON.stringify(state.sites),
        isEdit,
        displayTokenSection,
        AppToasty,
      ]
    );
    return _view;
  }
);

export default SitesSettings;
