import API from "@aws-amplify/api";
import { getSSOproviderByDomain } from "../../graphql/queries";
import envConfig from "../../../envConfig";

const getSSOproviderName = async (domain) => {
  return API.graphql({
    query: getSSOproviderByDomain,
    variables: { domain },
    authMode: "API_KEY",
    authToken: envConfig.REACT_APP_GRAPHQL_TOKEN,
  });
};

export default {
  getSSOproviderName,
};
