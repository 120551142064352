import { DataStore } from "@aws-amplify/datastore";
import AppForms from "@toothfairy/shared-ui/AppForms";
import OnBoarding from "@toothfairy/shared-ui/AppOnBoardingScreen";
import AppSelection from "@toothfairy/shared-ui/AppSelection";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo, useState } from "react";
import { Platform, View } from "react-native";
import ToothFairySubmitButton from "../../Components/ToothFairyComponents/ToothFairySubmitButton";
import Constants from "../../Constants";
import { User } from "../../models";
import AppToast from "@toothfairy/shared-ui/AppToast";
import { useTranslation } from "react-i18next";
import { isLargeScreen } from "../MainViewContainer";

const OnboardingScreen = ({
  user,
  themeMode,
  setIsIncognito,
  updateUser,
  handleThemeSelect,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const [sliderIndex, setSliderIndex] = useState(1);
  const { onboardingData } = Constants;
  const { t } = useTranslation();
  const inputFieldsWidth = Platform.OS === "web" ? 300 : 250;
  const AppToasty = AppToast.useToast();
  const [values, setValues] = useState({});
  const handleonBoardingSubmit = async (_values) => {
    try {
      const finalV = { ...values, ..._values };
      const userModel = await DataStore.query(User, user.id);

      await DataStore.save(
        User.copyOf(userModel, (updated) => {
          updated.isOnboarded = true;
          updated.username = finalV.name;
          updated.timezone = finalV.timezone;
          updated.theme = finalV.theme;
          updated.user_phone_number = user?.user_phone_number;
          updated.user_phone_number_verified = user?.user_phone_number_verified;
        })
      );
      setIsIncognito(false);
      updateUser({
        ...user,
        isOnboarded: true,
        username: finalV.name,
        timezone: finalV.timezone,
        theme: finalV.theme,
      });
      AppToasty.show(t("UserCreationSuccessCaption"), {
        type: "success",
      });
    } catch (error) {
      console.log("user creation error", error);
      AppToasty.show(t("UserCreationFailedCaption"), {
        type: "danger",
      });
    }
  };

  const handleFormSubmit = (_values) => {
    setSliderIndex(sliderIndex + 1);
    setValues({ ...values, ..._values });
  };

  const steps = useMemo(() => [
    <OnBoarding.OnBoardingScreen
      isEnabledOnFilled
      logo={require("../../assets/logoTitle.png")}
      formInitialValues={
        values?.name
          ? {
              name: values.name,
            }
          : Constants.onboardingData.name.fieldInitialValue
      }
      formValidationSchema={onboardingData.name.fieldValidation}
      ContainerActionComponent={ToothFairySubmitButton}
      icon={onboardingData.name.icon}
      onFormSubmit={handleFormSubmit}
      title={onboardingData.name.title}
    >
      <AppForms.AppFormField
        style={{ textAlign: "center" }}
        isMandatory
        width={inputFieldsWidth}
        name={onboardingData.name.fieldName}
        placeholder={onboardingData.name.fieldPlaceholder}
      />
    </OnBoarding.OnBoardingScreen>,
    <OnBoarding.OnBoardingScreen
      isEnabledOnFilled
      logo={require("../../assets/logoTitle.png")}
      formInitialValues={
        values?.timezone
          ? {
              timezone: values.timezone,
            }
          : onboardingData.timezone.fieldInitialValue
      }
      formValidationSchema={onboardingData.timezone.fieldValidation}
      ContainerActionComponent={ToothFairySubmitButton}
      icon={onboardingData.timezone.icon}
      onFormSubmit={handleFormSubmit}
      title={onboardingData.timezone.title}
    >
      <AppForms.AppFormDropDown
        formFieldContainerStyle={{
          flex: 1,
        }}
        isElevated={false}
        dropDownStyle={{
          borderColor: currentTheme?.theme?.border_color,
          maxWidth: !isLargeScreen() && 250,
        }}
        isMandatory
        name={onboardingData.timezone.fieldName}
        values={onboardingData.timezone.fieldOptions}
        items={onboardingData.timezone.fieldOptions}
        propertyLabel="name"
        propertyValue="id"
      />
    </OnBoarding.OnBoardingScreen>,
    <OnBoarding.OnBoardingScreen
      isEnabledOnFilled
      logo={require("../../assets/logoTitle.png")}
      formInitialValues={
        values?.theme
          ? {
              theme: values.theme,
            }
          : onboardingData.theme.fieldInitialValue
      }
      formValidationSchema={onboardingData.theme.fieldValidation}
      ContainerActionComponent={ToothFairySubmitButton}
      icon={onboardingData.theme.icon}
      onFormSubmit={handleFormSubmit}
      title={onboardingData.theme.title}
      //   Added here to submit the info to DataStore at this step instead of the last step
      isLast
      onOnBoardingSubmit={handleonBoardingSubmit}
    >
      <AppForms.AppFormMultipleSelection
        width={250}
        formOptionValue={"id"}
        height={Platform.OS === "web" ? "auto" : 66}
        selectedItem={[themeMode]}
        name={onboardingData.theme.fieldName}
        onSelectItem={(item) => handleThemeSelect(item.id)}
        options={onboardingData.theme.fieldOptions}
        ContainerActionComponent={ToothFairySubmitButton}
        OptionItem={AppSelection.AppSimpleThemeSelectionItem}
        placeholder={onboardingData.theme.fieldPlaceholder}
      />
    </OnBoarding.OnBoardingScreen>,
  ]);

  return (
    <View
      style={{
        width: "100%",
      }}
    >
      <OnBoarding.AppOnBoardingScreen
        sliderItems={steps}
        sliderIndex={sliderIndex}
        setSliderIndex={setSliderIndex}
      />
    </View>
  );
};

export default OnboardingScreen;
