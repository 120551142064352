import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useEffect } from "react";
import { View } from "react-native";
import ToothFairySwitch from "../../../Components/ToothFairyComponents/ToothFairySwitch";
import Constants from "../../../Constants";
import { isLargeScreen } from "../../MainViewContainer";
import useWorkspaces from "../../../Hooks/useWorkspaces";

const InputSwitcher = ({
  isTopicModeOn,
  setIsTopicModeOn,
  isAutoQuestionOn,
  setIsAutoQuestionModeOn,
  isParser,
  parseInProgress,
  isInModal = false,
  agent,
  setAgent,
  scope,
  setScope = () => {},
  setPrompt,
  setQValue,
  forceEditorWithValue,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { state } = AppStateManager.useAppStateManager();
  const AppToasty = AppToast.useToast();
  const { getPromptsByAgent } = useWorkspaces();
  const routes = [
    { key: "topic", title: "Topic" },
    { key: "text", title: "Text" },
  ];
  const businessAnalysts = state?.agents?.filter(
    (u) => u?.agentType === "BusinessAnalyst"
  );
  useEffect(() => {
    if (isTopicModeOn) {
      setIsAutoQuestionModeOn(false);
    }
  }, [isTopicModeOn]);
  const coreComponent = (
    <>
      <View
        style={{
          flexDirection: isInModal ? "column" : "row",
          alignItems: !isInModal && "center",
        }}
      >
        {businessAnalysts && (
          <>
            <AppDropDown
              showTextInputLabel
              label="Agent"
              inputLabelStyle={{
                marginLeft: 0,
                marginRight: 10,
                marginTop: 10,
              }}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: 250,
                minWidth: 170,
              }}
              wrapperContainer={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 0,
                marginRight: 15,
              }}
              isElevated={false}
              dropdownIconColor="transparent"
              // uppercase only first letter
              // label={}
              items={businessAnalysts || []}
              propertyLabel="label"
              propertyValue="id"
              selectedValue={agent}
              onValueChange={(itemValue, itemIndex) => {
                setAgent(itemValue);
              }}
            />
            <AppDropDown
              showTextInputLabel
              label="Prompt  "
              inputLabelStyle={{
                marginLeft: 0,
                marginRight: 10,
                marginTop: 10,
              }}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: 250,
                minWidth: 170,
              }}
              wrapperContainer={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 0,
              }}
              isElevated={false}
              dropdownIconColor="transparent"
              // uppercase only first letter
              // label={}
              items={getPromptsByAgent(agent)}
              propertyLabel="name"
              propertyValue="id"
              selectedValue={scope}
              onValueChange={(itemValue, itemIndex) => {
                if (itemValue != "generation")
                  forceEditorWithValue([
                    {
                      type: "paragraph",
                      children: [
                        {
                          text: state?.customPrompts?.find(
                            (u) => u?.type == itemValue
                          )?.interpolationString,
                        },
                      ],
                    },
                  ]);
              }}
            />
          </>
        )}
        {/* <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: isInModal && 25,
          }}
        >
          <AppText
            wrapperStyle={{
              marginRight: 10,
            }}
            style={{
              fontWeight: "bold",
            }}
          >
            Input mode
          </AppText>
          <AppSelectionSwitch
            onItemSelection={(route) => {
              setIsTopicModeOn(route?.key === "topic");
            }}
            unSelectionBackgroundColor={currentTheme?.theme?.transparent}
            routes={routes}
            isLargeScreen={isLargeScreen()}
          />
        </View>*/}
      </View>

      {Constants.isAIQuestionsEnabled && (
        <ToothFairySwitch
          disabled={parseInProgress}
          wrapperStyle={{ marginLeft: isLargeScreen() && 30, marginTop: 5 }}
          showTextInputLabel={true}
          label={"AI questions"}
          isTicked={isParser && isAutoQuestionOn}
          displayIsTicked={true}
          accessibilityLabel={"questionModeSwitch"}
          activeText="On"
          inActiveText="Off"
          onChange={(v) => {
            if (isTopicModeOn)
              AppToasty.show(
                "AI generated questions are allowed only for text input or batch",
                {
                  type: "warning",
                }
              );
            else setIsAutoQuestionModeOn(!isAutoQuestionOn);
          }}
        />
      )}
    </>
  );
  return isInModal ? (
    coreComponent
  ) : (
    <View
      style={{
        flexDirection: isInModal ? "column" : "row",
        alignItems: "flex-start",
        marginTop: -5,
      }}
    >
      {coreComponent}
    </View>
  );
};

export default InputSwitcher;
