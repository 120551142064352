import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { AppText } from "@toothfairy/shared-ui";
import React from "react";
import { Platform, View } from "react-native";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../MainViewContainer";

const chunkingStrategyOptions = ["keywords", "summary"];
const imageExtractionStrategyOptions = ["safe", "brute"];

const EmbeddingsSettingsView = ({
  onSubmit = () => {},
  initialValues,
  saveInProgress,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { getActiveWorkspace } = useWorkspaces();
  const AppToasty = AppToast.useToast();

  const chartingSchema = AppForms.yup.object().shape({
    maxChunkWords: AppForms.yup.number().min(200).max(1500),
    maxOverlapSentences: AppForms.yup.number().min(0).max(5),
    chunkingStrategy: AppForms.yup.mixed().oneOf(chunkingStrategyOptions),
    imageExtractionStrategy: AppForms.yup
      .mixed()
      .oneOf(imageExtractionStrategyOptions),
    minImageWidth: AppForms.yup.number().min(100),
    minImageHeight: AppForms.yup.number().min(100),
    aspectRatioMin: AppForms.yup.number().min(0.1),
    aspectRatioMax: AppForms.yup.number().min(1),
  });

  return (
    <View
      style={{
        marginHorizontal: "auto",
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 340,
      }}
    >
      <AppForms.AppForm
        style={{
          minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 250,
          maxWidth: 350,
          alignSelf: "center",
        }}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={chartingSchema}
      >
        <AppForms.AppFormDropDown
          isElevated={false}
          dropDownStyle={{
            borderColor: currentTheme?.theme?.border_color,
          }}
          inputLabelStyle={{
            marginLeft: -5,
          }}
          showTextInputLabel
          label="Chunk strategy"
          name="chunkingStrategy"
          items={[
            { name: "Keywords", id: "keywords" },
            { name: "Summary", id: "summary" },
          ]}
          propertyLabel="name"
          propertyValue="id"
        />
        <AppForms.AppFormDropDown
          isElevated={false}
          dropDownStyle={{
            borderColor: currentTheme?.theme?.border_color,
          }}
          inputLabelStyle={{
            marginLeft: -5,
          }}
          showTextInputLabel
          label="Image extraction strategy"
          name="imageExtractionStrategy"
          items={[
            { name: "Safe", id: "safe" },
            { name: "Brute force", id: "brute" },
          ]}
          propertyLabel="name"
          propertyValue="id"
        />
        <AppForms.AppFormSlider
          step={1}
          maximumValue={1500}
          minimumValue={200}
          label="Chunk max words"
          name="maxChunkWords"
          showEditButtons={true}
        />
        <AppForms.AppFormSlider
          step={1}
          maximumValue={5}
          minimumValue={1}
          label="Chunk sentences overlap"
          name="maxOverlapSentences"
          showEditButtons={true}
        />

        <AppForms.AppFormSlider
          step={1}
          maximumValue={1500}
          minimumValue={100}
          label="Min image width"
          name="minImageWidth"
          showEditButtons={true}
        />
        <AppForms.AppFormSlider
          step={1}
          maximumValue={1500}
          minimumValue={100}
          label="Min image height"
          name="minImageHeight"
          showEditButtons={true}
        />
        <AppForms.AppFormSlider
          step={0.1}
          maximumValue={6}
          minimumValue={0.1}
          label="Min Aspect ratio"
          name="aspectRatioMin"
          showEditButtons={true}
        />
        <AppForms.AppFormSlider
          step={0.1}
          maximumValue={6}
          minimumValue={1}
          label="Max Aspect ratio"
          name="aspectRatioMax"
          showEditButtons={true}
        />

        <AppForms.AppSubmitButton
          title={"Save"}
          isDisabled={saveInProgress}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 30,
              alignSelf: "center",
              height: Constants.appButtonHeight,
            },
          ]}
        />
      </AppForms.AppForm>
      <AppText
        leftIcon={AppAssets?.icons?.question_icon}
        iconSize={18}
        color={currentTheme?.theme?.orange}
        style={{
          marginLeft: 10,
          textDecorationColor: currentTheme?.theme?.orange,
        }}
        wrapperStyle={{
          padding: 15,
        }}
      >
        After each change, you should re-launch the learning process in the
        Knowledge Hub for optimal results.
      </AppText>
    </View>
  );
};

export default EmbeddingsSettingsView;
