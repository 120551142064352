import React from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import ToothFairyLanguageSelectionDropdown from "../../../../Components/ToothFairyComponents/ToothFairyLanguageSelectionDropdown";

const SettingsToolbar = ({
  searchBtnLabel = "Search",
  searchBtnAccLabel = "searchButton",
  handleSearch = () => {},
  handleCreation = () => {},
}) => {
  const [sourceText, setSourceText] = React.useState("");
  const [targetText, setTargetText] = React.useState("");
  const [sourceLang, setSourceLang] = React.useState("");
  const [targetLang, setTargetLang] = React.useState("");
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: -10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <ToothFairyLanguageSelectionDropdown
          value={sourceLang}
          onValueChange={(value) => setSourceLang(value)}
          showTextInputLabel={false}
          label="Source Language"
        />
        <AppTextInput
          value={sourceText}
          onChangeText={setSourceText}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Source text"
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <ToothFairyLanguageSelectionDropdown
          value={targetLang}
          onValueChange={(value) => setTargetLang(value)}
          showTextInputLabel={false}
          label="Target Language"
        />
        <AppTextInput
          value={targetText}
          onChangeText={setTargetText}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Target text"
        />
      </View>
      <ToothFairyButton
        wrapperStyle={{ marginTop: 10 }}
        title={searchBtnLabel}
        accessibilityLabel={searchBtnAccLabel}
        onPress={() =>
          handleSearch(sourceText, targetText, sourceLang, targetLang)
        }
      />
      <ToothFairyButton
        wrapperStyle={{
          alignSelf: "center",
          marginTop: 10,
        }}
        title="Create"
        onPress={handleCreation}
      />
    </View>
  );
};

export default SettingsToolbar;
