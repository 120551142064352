import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";
import Charting from "../../../Components/Charting";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import CurrentModelView from "./CurrentModelView";
import DashboardItem from "./DashboardItem";
import DashboardPlaceholder from "./DashboardPlaceholder";

const ModelView = ({
  intentsBreakDown,
  nersBreakDown,
  topicsBreakDown,
  documentsBreakdown,
  trainingAPIInProgress,
}) => {
  const { isNLPModelTrained, isRCModelTrained, getActiveWorkspace } =
    useWorkspaces();
  const { currentTheme } = AppTheme.useTheme();
  const doughnutChartOptions = {
    legend: {
      labels: {
        color: currentTheme?.theme?.font_color,
      },
    },
  };
  const commonOptions = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
        labels: {
          color: currentTheme?.theme?.font_color,
        },
      },
    },
    scales: {
      x: {
        type: "logarithmic",
        display: true,
        position: "left",
        ticks: {
          color: currentTheme?.theme?.font_color,
          callback: function (value, index, values) {
            if (value >= 1)
              //needed to change the scientific notation results from using logarithmic scale
              return Number(value.toString()); //pass tick values as a string into Number function
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: currentTheme?.theme?.font_color,
        },
      },
    },
  };
  return (
    <View style={{ paddingBottom: 40 }}>
      <CurrentModelView />

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 30,
          flexWrap: "wrap",
        }}
      >
        <DashboardItem title="Topics">
          {topicsBreakDown?.length > 0 ? (
            <Charting.AppBarChart
              vertical={false}
              maxHeight={Constants.chartMaxHeight}
              options={commonOptions}
              dataset={{
                labels: topicsBreakDown?.map(({ name }) => name),
                datasets: [
                  {
                    label: "Records",
                    data: topicsBreakDown?.map(({ count }) => count),
                    borderColor: topicsBreakDown?.map(({ color }) => color),
                    borderRadius: 10,
                    backgroundColor: topicsBreakDown?.map(({ color }) => color),
                    maxBarThickness: 50,
                  },
                ],
              }}
            />
          ) : (
            <DashboardPlaceholder
              mainCaption="Nothing to show here yet..."
              subCaption="Create your first topic under settings and associate a document to it to see the results here."
            />
          )}
        </DashboardItem>
        {
          <DashboardItem
            isFetchInProgress={trainingAPIInProgress}
            title="Documents by type"
            isHalfWidth={true}
          >
            {documentsBreakdown.reduce((a, b) => a + b, 0) > 0 ? (
              <Charting.AppDoughnut
                options={doughnutChartOptions}
                maxHeight={Constants.chartMaxHeight}
                dataset={{
                  labels: [
                    "Sentiment reviewer training",
                    "Reasoning training",
                    "Knowledge hub",
                    "Content creator training",
                    "Knowledge hub (QA)",
                    "Knowledge hub (PDF)",
                    "Knowledge hub (FILES)",
                    "Knowledge hub (URL)",
                    "Conversational training",
                  ],
                  datasets: [
                    {
                      label: "Documents",
                      data: documentsBreakdown,
                      backgroundColor: [
                        currentTheme?.theme?.primary,
                        currentTheme?.theme?.orange,
                        currentTheme?.theme?.green,
                        currentTheme?.theme?.blue,
                        currentTheme?.theme?.purple,
                        currentTheme?.theme?.red,
                        currentTheme?.theme?.yellow,
                      ],
                    },
                  ],
                }}
              />
            ) : (
              <DashboardPlaceholder
                mainCaption="Nothing to show here yet..."
                subCaption="Create your first document to see the results here."
              />
            )}
          </DashboardItem>
        }
      </View>
      {getActiveWorkspace()?.isSentimentReviewerEnabled && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <DashboardItem title="Sentiments">
            {isNLPModelTrained() && intentsBreakDown ? (
              <Charting.AppBarChart
                vertical={false}
                maxHeight={Constants.chartMaxHeight}
                options={commonOptions}
                dataset={{
                  labels: intentsBreakDown?.map(({ name }) => name),
                  datasets: [
                    {
                      label: "Records",
                      data: intentsBreakDown?.map(({ count }) => count),
                      borderColor: intentsBreakDown?.map(({ color }) => color),
                      borderRadius: 10,
                      backgroundColor: intentsBreakDown?.map(
                        ({ color }) => color
                      ),
                      maxBarThickness: 50,
                    },
                  ],
                }}
              />
            ) : (
              <DashboardPlaceholder
                mainCaption="Nothing to show here yet..."
                subCaption="Create your first document setting an intent and run nlp training to see the results here."
              />
            )}
          </DashboardItem>
          <DashboardItem title="Keywords">
            {isNLPModelTrained() && nersBreakDown ? (
              <Charting.AppBarChart
                vertical={false}
                maxHeight={Constants.chartMaxHeight}
                options={commonOptions}
                dataset={{
                  labels: nersBreakDown?.map(({ name }) => name),
                  datasets: [
                    {
                      label: "Records",
                      data: nersBreakDown?.map(({ count }) => count),
                      borderColor: nersBreakDown?.map(({ color }) => color),
                      borderRadius: 10,
                      backgroundColor: nersBreakDown?.map(({ color }) => color),
                      maxBarThickness: 50,
                    },
                  ],
                }}
              />
            ) : (
              <DashboardPlaceholder
                mainCaption="Nothing to show here yet..."
                subCaption="Create your first document setting an NER and run nlp training to see the results here."
              />
            )}
          </DashboardItem>
        </View>
      )}
    </View>
  );
};

export default ModelView;
