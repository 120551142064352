import AppModal from "@toothfairy/shared-ui/AppModal";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useEffect } from "react";
import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { default as AppConfig } from "../../Constants";
import { isLargeScreen, isMobileScreen } from "../../Screens/MainViewContainer";

const ToothFairyModal = ({
  isModalOpen = false,
  setIsModalOpen,
  children,
  modalStyle,
  modalInnerContentStyle,
  scrollViewStyle,
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { height } = useWindowDimensions();
  const _styles = styleGenerator({
    currentTheme,
    isLargeScreen: isLargeScreen(),
  });
  const _modalBackdrop = true;
  const _modalBackdropStatic = true;
  const _modalStyles = _styles.modalInputHelp;
  const _modalTitle = "";
  const _modalTransparency = true;

  useEffect(() => {
    const closeOnEscapeKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        props?.closeModal();
      }
    };
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [setIsModalOpen]);

  return (
    <AppModal
      modalScrollContainer={{
        flex: 1,
      }}
      modalInnerContentStyle={[
        {
          flex: 1,
        },
        modalInnerContentStyle,
      ]}
      modalBackdrop={_modalBackdrop}
      modalBackdropStatic={_modalBackdropStatic}
      modalStyle={[_modalStyles, modalStyle]}
      modalTitle={_modalTitle}
      isCloseButtonVisible
      closeModal={() => {
        setIsModalOpen(false);
      }}
      isModalVisible={isModalOpen}
      transparent={_modalTransparency}
      scrollViewStyle={[
        {
          marginBottom: 20,
          maxHeight: height - (isMobileScreen ? 20 : 100),
          overflowY: "auto",
        },
        scrollViewStyle,
      ]}
      {...props}
    >
      <AppToast.ToastProvider
        {...AppConfig.notificationConfig}
        // offset for both top and bottom toasts
      >
        {children}
      </AppToast.ToastProvider>
    </AppModal>
  );
};
const styleGenerator = ({ currentTheme, isLargeScreen }) => {
  return StyleSheet.create({
    modalInputHelp: {
      height: "95%",
      marginHorizontal: Platform.OS === "web" && isLargeScreen ? "auto" : 1,
      marginVertical: "auto",
      width: Platform.OS === "web" && isLargeScreen ? "60%" : "100%",
      maxWidth: Platform.OS === "web" && isLargeScreen ? 1024 : "100%",
    },
  });
};

export default ToothFairyModal;
