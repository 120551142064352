import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";

import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import envConfig from "../../../envConfig";

const getUser = ({ id }) =>
  API.graphql(graphqlOperation(queries.getUser, { id }));
const createUser = (userData) =>
  API.graphql({ query: mutations.createUser, variables: { input: userData } });
const listUsers = (limit = 100) =>
  API.graphql(graphqlOperation(queries.listUsers, { limit }));
const updateUser = (userData) =>
  API.graphql({ query: mutations.updateUser, variables: { input: userData } });
const permanentlyDeleteUser = (id, _version) =>
  API.graphql({
    query: mutations.deleteUser,
    variables: { input: { id, _version } },
  });

const getUserByEmail = (email) =>
  API.graphql(graphqlOperation(queries.getUserIDByEmail, { email }));

const getAvailableChats = async (userid, workspaces) => {
  const payload = await AppAuth.Auth.currentSession();
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/users/get-available-chats-to-user?userid=${userid}&workspaces=${workspaces}`;
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const createWorkspaceUser = (workspaceID, userID, userType) =>
  API.graphql({
    query: mutations.createUserWorkspaceType,
    variables: { input: { workspaceID, userID, userType } },
  });

const deleteWorkspaceUser = (id, _version) =>
  API.graphql({
    query: mutations.deleteUserWorkspaceType,
    variables: { input: { id, _version } },
  });

const updateWorkspaceUser = (id, _version, userType) =>
  API.graphql({
    query: mutations.updateUserWorkspaceType,
    variables: { input: { id, userType, _version } },
  });
const getWorkspaceUsersByWorkspaceID = (workspaceID) =>
  API.graphql({
    query: queries.userToWorkspaceMapping,
    variables: { workspaceID },
  });

export default {
  getUser,
  createUser,
  updateUser,
  listUsers,
  getUserByEmail,
  permanentlyDeleteUser,
  getAvailableChats,
  createWorkspaceUser,
  deleteWorkspaceUser,
  updateWorkspaceUser,
  getWorkspaceUsersByWorkspaceID,
};
