import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { Platform, View } from "react-native";
import ToothFairySupportButton from "../../../../Components/ToothFairyComponents/ToothFairySupportButton";
import ToothFairySwitch from "../../../../Components/ToothFairyComponents/ToothFairySwitch";
import useSections from "../../../../Hooks/useSections";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen, minAppWidth } from "../../../MainViewContainer";
import PreferencesGPT from "./PreferencesGPT";
import ToothFairySlider from "../../../../Components/ToothFairyComponents/ToothFairySlider";
const AnalysisPreferencesGN = ({
  handleUpdate,
  showReplyLanguage = true,
  isWorkspaceSetting = true,
  config,
}) => {
  const { marginLeftUnderAppExpander } = useSections();
  const { getActiveWorkspace, getPlanConfig } = useWorkspaces();
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View
      style={{
        width:
          Platform.OS === "web" && isLargeScreen() ? 450 : minAppWidth() - 50,
        marginTop: 20,
        marginLeft: marginLeftUnderAppExpander,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <ToothFairySwitch
          wrapperStyle={{
            flex: 1,
            marginRight: 20,
            marginTop: 10,
            backgroundColor: currentTheme?.theme?.inputBackgroundColor,
          }}
          label="Authenticity check (coming soon)"
          disabled={!getPlanConfig()?.agcEnabled}
          onChange={(v) =>
            handleUpdate("parsingConfig", {
              ...getActiveWorkspace().parsingConfig,
              agcEnabled: v,
            })
          }
          isTicked={
            getPlanConfig()?.agcEnabled &&
            getActiveWorkspace()?.parsingConfig &&
            getActiveWorkspace()?.parsingConfig?.agcEnabled
          }
        />
        <ToothFairySupportButton _pageId={34} />
      </View>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <ToothFairySwitch
          wrapperStyle={{
            flex: 1,
            marginRight: 20,
            marginTop: 10,
            backgroundColor: currentTheme?.theme?.inputBackgroundColor,
          }}
          label="Domain knowledge"
          onChange={(v) =>
            handleUpdate("customGenerationConfig", {
              ...getActiveWorkspace().customGenerationConfig,
              hasTopicsContext: v,
            })
          }
          isTicked={
            getActiveWorkspace()?.customGenerationConfig &&
            getActiveWorkspace()?.customGenerationConfig?.hasTopicsContext
          }
        />
        <ToothFairySupportButton _pageId={34} />
      </View>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <ToothFairySwitch
          wrapperStyle={{
            flex: 1,
            marginRight: 20,
            marginTop: 10,
            backgroundColor: currentTheme?.theme?.inputBackgroundColor,
          }}
          label="Compressed knowledge"
          onChange={(v) =>
            handleUpdate("customGenerationConfig", {
              ...getActiveWorkspace().customGenerationConfig,
              compressor: v,
            })
          }
          isTicked={
            getActiveWorkspace()?.customGenerationConfig &&
            getActiveWorkspace()?.customGenerationConfig?.compressor
          }
        />
        <ToothFairySupportButton _pageId={34} />
      </View>
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <ToothFairySwitch
          wrapperStyle={{
            flex: 1,
            marginRight: 20,
            marginTop: 10,
            backgroundColor: currentTheme?.theme?.inputBackgroundColor,
          }}
          label="knReply"
          onChange={(v) =>
            handleUpdate("customGenerationConfig", {
              ...getActiveWorkspace().customGenerationConfig,
              aiKnowledge: v,
            })
          }
          isTicked={
            getActiveWorkspace()?.customGenerationConfig &&
            getActiveWorkspace()?.customGenerationConfig?.aiKnowledge
          }
        />
        <ToothFairySupportButton _pageId={34} />
      </View>
      <ToothFairySlider
        minimumValue={1}
        maximumValue={20}
        step={1}
        label="TopK"
        onSubmit={(v) => {
          handleUpdate("customGenerationConfig", {
            ...getActiveWorkspace().customGenerationConfig,
            topK: v,
          });
        }}
        initialValue={getActiveWorkspace()?.customGenerationConfig?.topK || 5}
      />
      <ToothFairySlider
        label="Doc TopK"
        minimumValue={1}
        maximumValue={20}
        step={1}
        onSubmit={(v) =>
          handleUpdate("customGenerationConfig", {
            ...getActiveWorkspace()?.customGenerationConfig,
            docTopK: v,
          })
        }
        initialValue={
          getActiveWorkspace()?.customGenerationConfig?.docTopK || 2
        }
      />

      <PreferencesGPT
        frequencyPenaltyProperty={"frequencyPenalty"}
        isQA={false}
        handleUpdate={handleUpdate}
        topPProperty={"topP"}
        maxTokensProperty={"maxTokens"}
        presencePenaltyProperty={"presencePenalty"}
        temperatureProperty={"temperature"}
        step={50}
      />
    </View>
  );
};

export default AnalysisPreferencesGN;
