import AppTextChip from "@toothfairy/shared-ui/AppTextChip";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";

const WorkspaceChip = ({
  label,
  borderColor,
  fontColor,
  backgroundColor,
  leftIcon,
  showProgressIndicator,
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();

  return (
    <AppTextChip
      showProgressIndicator={showProgressIndicator}
      fontColor={fontColor || currentTheme?.theme?.primary}
      borderColor={borderColor || currentTheme?.theme?.transparent}
      borderRadius={5}
      backgroundColor={backgroundColor || currentTheme?.theme?.lightGrey}
      label={label}
      leftIcon={!showProgressIndicator && leftIcon}
      leftIconColor={fontColor}
      leftIconStyle={{ marginRight: 3 }}
      iconSize={currentTheme.theme.extra_small_font_size}
      styleContainer={{
        width: "fit-content",
        margin: 3,
      }}
      fontWeight="bold"
      styleLabel={{
        fontSize: currentTheme.theme.extra_small_font_size,
        marginLeft: 5,
        maxWidth: 225,
        overflow: "hidden",
        textWrap: "nowrap",
        textOverflow: "ellipsis",
      }}
      {...props}
    />
  );
};

export default WorkspaceChip;
