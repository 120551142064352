import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import Constants from "../../../Constants";

const ImagesExtraction = ({
  initialValues,
  savingInProgress,
  handleFormSubmit = () => {},
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const formValidationSchema = AppForms.yup.object().shape({
    documentImgRetrievalInstructions: AppForms.yup.string().nullable().max(512),
  });
  return (
    <AppForms.AppForm
      style={{
        minWidth: 350,
        // maxWidth: 600,
        alignItems: "center",
      }}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={formValidationSchema}
      enableReinitialize
    >
      <AppForms.AppFormField
        showTextInputLabel
        formFieldContainerStyle={{
          width: "100%",
        }}
        disabled={savingInProgress}
        wrapperInputStyle={{
          height: 340,
          marginBottom: 10,
          padding: 10,
        }}
        multiline
        textContainer={{
          padding: 10,
          marginHorizontal: 20,
        }}
        label={`Images extracted`}
        placeholder={`Filter images to extract by providing instructions here.`}
        keyboardtype="default"
        textContextType="label"
        autoCorrect={false}
        name="documentImgRetrievalInstructions"
      />
      <AppForms.AppSubmitButton
        title={"Update"}
        isDisabled={savingInProgress}
        awesomeIconColor={currentTheme?.theme?.white}
        backgroundColor={currentTheme?.theme?.primary}
        btnContentStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        style={[
          {
            height: Constants.appButtonHeight,
            width: "auto",
            shadowColor: currentTheme?.theme?.transparent,
            marginHorizontal: 10,
          },
        ]}
        btnLabelStyle={{ fontSize: 16 }}
        wrapperStyle={[
          {
            width: "fit-content",
            minWidth: 150,

            height: Constants.appButtonHeight,
          },
        ]}
      />
    </AppForms.AppForm>
  );
};

export default ImagesExtraction;
