import AppForms from "@toothfairy/shared-ui/AppForms";
import AppSecureTextInput from "@toothfairy/shared-ui/AppSecureTextInput";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppSelection from "@toothfairy/shared-ui/AppSelection";
import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppModal from "@toothfairy/shared-ui/AppModal";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo, useState } from "react";
import { Platform, StyleSheet, View, useWindowDimensions } from "react-native";
import Constants from "../../../Constants";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import useSections from "../../../Hooks/useSections";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import AdminInfoLeaf from "../../Admin/Components/SubComponents/AdminInfoLeaf";
import { isLargeScreen } from "../../MainViewContainer";

const ProfileSettingsScreen = ({
  handleFormSubmit,
  handleUserUpdate,
  formValidationSchema,
  onboardingData,
  user,
  workspace,
  handleUserDeletion,
}) => {
  const { width } = useWindowDimensions();
  const { currentTheme, setMode, mode } = AppTheme.useTheme();
  const styles = styleGenerator(currentTheme);
  const [isEditable, setIsEditable] = useState(false);
  const [showDeletionWarning, setShowDeletionWarning] = useState(false);
  const userDeletionModal = useMemo(() => {
    return (
      <AppModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                maxWidth: 600,
                maxHeight: "80%",
                margin: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={showDeletionWarning}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle=" "
        modalInnerContentStyle={{
          paddingHorizontal: 5,
          paddingVertical: 20,
        }}
        closeModal={() => setShowDeletionWarning(false)}
      >
        <AppChoice
          wrapperStyle={{ alignSelf: "center", maxWidth: 600 }}
          caption="You are about to delete your account. This action cannot be undone."
          subCaption="Are you sure you want to delete your account and all the workspaces where this account is Admin?"
          cancelCaption="Click 'Cancel' to abort"
          confirmCaption="Click 'Confirm' to permanently delete your account"
          onConfirmButtonPress={() => {
            handleUserDeletion();
            setShowDeletionWarning(false);
          }}
          onCancelButtonPress={() => {
            setShowDeletionWarning(false);
          }}
          AppChoiceButton={ToothFairyButton}
        />
      </AppModal>
    );
  });
  return (
    <View style={styles.container}>
      {user?.id && (
        <>
          <AppForms.AppForm
            style={{
              minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 300,
              maxWidth: 600,
            }}
            initialValues={{
              name: user?.username,
              timezone: user?.timezone,
              theme: user?.theme,
              workspaceName: workspace?.title,
              workspaceType: workspace?.type,
            }}
            onSubmit={handleFormSubmit}
            validationSchema={formValidationSchema}
          >
            <AdminInfoLeaf
              textContainer={{
                marginLeft: 0,
                maxWidth: Math.min(width - 55, 600),
              }}
              wrapperStyle={{
                marginBottom: 10,
              }}
              titleStyle={{
                marginTop: 0,
              }}
              title={"Account ID"}
              value={user?.id}
              width={420}
            />
            <AppSecureTextInput
              wrapperStyleSecureTextInput={{
                paddingBottom: 10,
                minHeight: 72,
                marginRight: 13,
              }}
              wrapperInputStyle={{ flex: 1 }}
              actionsButtonsViewStyle={{ paddingRight: 10 }}
              showTextInputLabel
              label="Name"
              isMandatory
              isEditable={isEditable}
              onEditIconPress={() => setIsEditable(true)}
              onCancelIconPress={() => setIsEditable(false)}
              onSubmitIconPress={(v) => {
                setIsEditable(false);
                handleUserUpdate("username", v);
              }}
              initialValue={user?.username}
            />

            <AppSecureTextInput
              wrapperStyleSecureTextInput={{
                paddingBottom: 10,
                minHeight: 72,
              }}
              wrapperInputStyle={{ flex: 1 }}
              actionsButtonsViewStyle={{ paddingRight: 10 }}
              showTextInputLabel
              label="Email"
              isEditEnabled={false}
              initialValue={user?.user_email}
            />
            <AppDropDown
              showTextInputLabel
              label="Theme"
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: 150,
              }}
              wrapperContainer={{
                paddingBottom: 15,
              }}
              inputLabelStyle={{
                marginLeft: 0,
              }}
              isElevated={false}
              dropdownIconColor="transparent"
              items={onboardingData?.theme?.fieldOptions}
              propertyLabel="title"
              propertyValue="id"
              selectedValue={mode}
              onValueChange={(itemValue, itemIndex) => {
                setMode(itemValue);
                handleUserUpdate("theme", itemValue);
              }}
            />
          </AppForms.AppForm>

          <ToothFairyButton
            title={"Permanently delete account"}
            onPress={() => setShowDeletionWarning(true)}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
          {userDeletionModal}
        </>
      )}
    </View>
  );
};

const styleGenerator = (currentTheme) => {
  return StyleSheet.create({
    container: {
      alignItems: "center",
      flex: 1,
      paddingTop: 0,
    },
    label: {
      fontWeight: currentTheme?.theme?.font_bold,
      marginTop: 20,
    },
    submitBotton: {
      marginTop: 20,
    },
    title: {
      fontSize: currentTheme?.theme?.large_font_size,
      fontWeight: currentTheme?.theme?.font_bold,
      marginBottom: 30,
    },
  });
};

export default ProfileSettingsScreen;
