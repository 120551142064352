import { DataStore } from "@aws-amplify/datastore";
import { AppConfig } from "../../models";

const createAppConfig = async (object) => {
  return DataStore.save(new AppConfig(object));
};

const updateAppConfig = async (id, args) => {
  const original = await DataStore.query(AppConfig, id);
  return DataStore.save(
    AppConfig.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteAppConfig = async (id) => {
  return DataStore.delete(AppConfig, (ent) => ent.id("eq", id));
};

const listAppConfig = async (args) => {
  return DataStore.query(AppConfig);
};

export default {
  createAppConfig,
  updateAppConfig,
  deleteAppConfig,
  listAppConfig,
};
