import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { matchPath, generatePath } from "react-router";
import { lastPathInWorkspace } from "../../App";
import { useLocation, useHistory } from "../Router";
import { routes } from "../Router/routes";

const usePath = () => {
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = useState({});
  const [path, setPath] = useState({});
  const [section, setSection] = useState();
  const [lastNLPdocumentOpened, setLastNLPdocumentOpened] = useState();
  const [lastRCdocumentOpened, setLastRCdocumentOpened] = useState();
  const [lastMRCdocumentOpened, setLastMRCdocumentOpened] = useState();
  const [lastGNdocumentOpened, setLastGNdocumentOpened] = useState();
  const [lastDashboardTabOpened, setLastDashboardTabOpened] = useState();
  const [lastDocumentsTabOpened, setLastDocumentsTabOpened] = useState("");
  const [lastTrainingTabOpened, setLastTrainingTabOpened] = useState("");
  const [lastBatchTabOpened, setLastBatchTabOpened] = useState("");
  const [lastSettingsTabOpened, setLastSettingsTabOpened] = useState("");
  const [lastProfileTabOpened, setLastProfileTabOpened] = useState("");
  const [lastAdminTabOpened, setLastAdminTabOpened] = useState("");
  const [lastChatOpened, setLastChatOpened] = useState("");
  const [isInWorkspace, setInWorkspace] = useState(false);
  const { state } = AppStateManager.useAppStateManager();
  const _tabSelection = state?.appTabSelection;

  useEffect(() => {
    if (!isInWorkspace) {
      setPath({});
      setParams({});
      setSection(undefined);
      setLastNLPdocumentOpened(undefined);
      setLastRCdocumentOpened(undefined);
      setLastMRCdocumentOpened(undefined);
      setLastGNdocumentOpened(undefined);
      setLastDashboardTabOpened(undefined);
      setLastDocumentsTabOpened("");
      setLastTrainingTabOpened("");
      setLastBatchTabOpened("");
      setLastSettingsTabOpened("");
      setLastProfileTabOpened("");
      setLastAdminTabOpened("");
      setLastChatOpened("");
    }
  }, [isInWorkspace]);

  const extractSectionFromPath = (pathname) => {
    const getIndexFromKey = (key) => {
      return _tabSelection.filter((item) => item.key === key)[0].lastIndex;
    };
    switch (pathname) {
      case routes.workspaces:
        return "WORKSPACES_GENERAL";
      case routes.answerer:
        return "RC_PARSER";
      case routes.trainerAnswerer:
        return "RC_TRAINER";
      case routes.mrc_contents:
        return "MRC_DOCUMENTS";
      case routes.generator:
        return "GN_DOCUMENTS";
      case routes.chats:
        return "CHATS";
      case routes.nlp:
        return "NLP_PARSER";
      case routes.trainerDocument:
        return "NLP_TRAINER";
      case routes.dashboard:
        if (getIndexFromKey("dashboard") === 0) {
          return "DASHBOARD_MODEL";
        } else if (getIndexFromKey("dashboard") === 1) {
          return "DASHBOARD_COST";
        }
        break;
      case routes.documents:
        if (getIndexFromKey("documents") === 0) {
          return "NLP_DOCUMENTS";
        } else if (getIndexFromKey("documents") === 1) {
          return "RC_DOCUMENTS";
        }
        break;
      case routes.training:
        if (getIndexFromKey("training") === 0) {
          return "NLP_TRAINING";
        } else if (getIndexFromKey("training") === 1) {
          return "RC_TRAINING";
        }
        break;
      case routes.settings:
        if (getIndexFromKey("settings") === "WORKSPACE_SETTINGS") {
          return "SETTINGS_GENERAL";
        } else if (getIndexFromKey("settings") === "INTENT") {
          return "SETTINGS_INTENT";
        } else if (getIndexFromKey("settings") === "NER") {
          return "SETTINGS_NER";
        } else if (getIndexFromKey("settings") === "TOPIC") {
          return "SETTINGS_TOPIC";
        } else if (getIndexFromKey("settings") === "TRAINING") {
          return "SETTINGS_TRAINING";
        }
        break;
      case routes.profile:
        if (getIndexFromKey("profile") === "PROFILE_SETTINGS") {
          return "PROFILE_GENERAL";
        } else if (getIndexFromKey("profile") === "BILLING") {
          return "PROFILE_BILLING";
        } else if (getIndexFromKey("profile") === "NLP") {
          return "PROFILE_ANALYSIS";
        }
        break;
      case routes.admin:
        if (getIndexFromKey("admin") === "API_INTEGRATION") {
          return "ADMIN_API";
        }
        if (getIndexFromKey("admin") === "LOGS") {
          return "ADMIN_LOGS";
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location?.pathname) {
      for (const [_, path] of Object.entries(routes)) {
        const match = matchPath(location?.pathname, {
          path,
          exact: true,
        });
        if (match?.isExact) {
          setParams(match.params);
          setPath(match);
          setSection(extractSectionFromPath(match.path));
          if (match.path === routes.nlp) setLastNLPdocumentOpened(match.url);

          if (match.path === routes.answerer)
            setLastRCdocumentOpened(match.url);
          if (match.path === routes.chats) setLastChatOpened(match.url);
          if (match.path === routes.mrc_contents)
            setLastMRCdocumentOpened(match.url);
          if (match.path === routes.dashboard)
            setLastDashboardTabOpened(match.url);
          if (match.path === routes.generator)
            setLastGNdocumentOpened(match.url);
          if (match.path === routes.documents && state?.activeWorkspaceID)
            setLastDocumentsTabOpened(match.url);
          if (match.path === routes.training)
            setLastTrainingTabOpened(match.url);
          if (match.path === routes.batch) setLastBatchTabOpened(match.url);
          if (match.path === routes.settings)
            setLastSettingsTabOpened(match.url);
          if (match.path === routes.profile) setLastProfileTabOpened(match.url);
          if (match.path === routes.admin) setLastAdminTabOpened(match.url);

          if (
            match.path === routes.profile ||
            match.path === routes.workspaces ||
            match.path === routes.error ||
            history?.location?.pathname === routes.accessDenied
          ) {
            setInWorkspace(false);
          } else {
            setInWorkspace(true);
            lastPathInWorkspace({
              workspaceid: state?.activeWorkspaceID,
              path: location.pathname,
            });
          }
        }
      }
    }
  }, [location.pathname, _tabSelection]);

  const updatePath = useCallback(
    (args) => {
      const _path = generatePath(path.path, { ...args });
      history.push(_path);
    },
    [path.path, history]
  );

  return {
    params,
    path,
    section,
    updatePath,
    lastRCdocumentOpened,
    lastNLPdocumentOpened,
    lastDashboardTabOpened,
    lastDocumentsTabOpened,
    lastMRCdocumentOpened,
    lastGNdocumentOpened,
    lastTrainingTabOpened,
    lastSettingsTabOpened,
    lastAdminTabOpened,
    lastProfileTabOpened,
    lastBatchTabOpened,
    isInWorkspace,
    lastChatOpened,
  };
};

export default usePath;
