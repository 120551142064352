import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import AdminInfoLeaf from "../../../Admin/Components/SubComponents/AdminInfoLeaf";

const ConnectionSettingsAIView = ({ isEdit, settingType, handleDeletion }) => {
  const { currentTheme, mode } = AppTheme.useTheme();
  const entityName = "Connections mapping";
  const { setFieldValue, values, validationSchema } =
    AppForms.useFormikContext();
  const { state } = AppStateManager.useAppStateManager();
  const { getWorkspaceBaseModel } = useWorkspaces();
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  // const { setFieldValue, values } = AppForms.useFormikContext();
  // useEffect(() => {
  //   if (values?.sourceLanguage !== "en") setFieldValue("targetLanguage", "en");
  //   if (values?.targetLanguage !== "en") setFieldValue("sourceLanguage", "en");
  // }, [values]);
  return (
    <>
      <>
        <View>
          {isEdit && (
            <AdminInfoLeaf
              wrapperStyle={{
                alignSelf: "flex-start",
                marginBottom: 10,
              }}
              textContainer={{
                marginLeft: 5,
                marginTop: 10,
                marginBottom: 0,
              }}
              titleStyle={{
                marginTop: 0,
                marginLeft: 20,
              }}
              textStyle={{
                marginLeft: 0,
              }}
              title={"ID"}
              value={values?.id}
              width={420}
            />
          )}
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Database name`}
            placeholder={`Database name`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="name"
          />
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            label={`Description`}
            placeholder={`Description of what the connection is used for`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="description"
          />
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Host`}
            placeholder={`DB host address`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="host"
          />
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Port`}
            placeholder={`DB port`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="port"
          />
        </View>

        <View
          style={{
            zIndex: -1,
          }}
        >
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="DB type"
            isMandatory
            name="type"
            items={[
              {
                id: "sql",
                name: "Microsoft SQL Server",
              },
              {
                id: "oracle",
                name: "Oracle",
              },
              {
                id: "mysql",
                name: "MySQL",
              },
              {
                id: "postgressql",
                name: "PostgresSQL",
              },
            ]}
            propertyLabel="name"
            propertyValue="id"
          />

          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Username`}
            placeholder={`The username to use to connect to the DB`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="username"
          />

          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Password`}
            placeholder={
              isEdit
                ? "Passwords cannot be changed after connections creation"
                : `The password to use to connect to the DB`
            }
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="password"
            disabled={isEdit}
          />
        </View>
      </>
      <View style={{ alignItems: "center" }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default ConnectionSettingsAIView;
