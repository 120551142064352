import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import envConfig from "../../../envConfig";

const getDocumentsByWorkspace = async ({
  workspaceID,
  page,
  intents,
  entities,
  text,
  topics,
  type,
  documentIdString,
  countOnly,
  fields,
  scope,
  pageLimit,
}) => {
  let url;
  const payload = await AppAuth.Auth.currentSession();
  url = `${envConfig.REACT_APP_REST_ENDPOINT}/trainingdata/getDocumentsByWorkspace?workspaceid=${workspaceID}`;
  if (page !== -1) url = url + `&page=${page}`;
  if (intents?.length > 0) url = url + `&intents=${JSON.stringify(intents)}`;
  if (entities?.length > 0) url = url + `&entities=${JSON.stringify(entities)}`;
  if (text?.length) url = url + `&text=${text}`;
  if (topics?.length > 0) url = url + `&topics=${JSON.stringify(topics)}`;
  if (type) url = url + `&type=${type}`;
  if (documentIdString) url = url + `&id=${documentIdString}`;
  if (countOnly) url = url + `&countOnly=${countOnly}`;
  if (fields) url = url + `&fields=${JSON.stringify(fields)}`;
  if (scope) url = url + `&scope=${scope}`;
  if (pageLimit) url = url + `&pageLimit=${pageLimit}`;

  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getDocumentById = async (documentId, fields, workspaceID) => {
  if (!documentId) return { data: null };

  const payload = await AppAuth.Auth.currentSession();
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/trainingdata/getDocumentByID?id=${documentId}&workspaceid=${workspaceID}`;
  if (fields) url = url + `&fields=${JSON.stringify(fields)}`;
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getEmebddingById = async (id, workspaceID) => {
  if (!id) return { data: null };

  const payload = await AppAuth.Auth.currentSession();
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/trainingdata/getEmbeddingByID?id=${id}&workspaceid=${workspaceID}`;
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getEmbeddingByDocumentID = async (id, workspaceID) => {
  if (!id) return { data: null };

  const payload = await AppAuth.Auth.currentSession();
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/trainingdata/getEmbeddingByDocumentID?id=${id}&workspaceid=${workspaceID}`;
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const postTrainingData = async (body) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/trainingdata/createDocument`;
  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    body,
    true,
    null,
    payload
  );
};

const updateTrainingData = async (body) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/trainingdata/updateDocumentByID`;
  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    body,
    true,
    null,
    payload
  );
};

const deleteTrainingData = async (id, workspaceid) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/trainingdata/deleteDocumentByID?id=${id}&workspaceid=${workspaceid}`;
  return secureRequest(
    apiClient.apiClient,
    "DELETE",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const bulkImportUrlGeneration = async ({
  filename,
  importType,
  contentType,
}) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${
    envConfig.REACT_APP_REST_ENDPOINT
  }/documents/requestUploadURL?filename=${filename}&importType=${importType}${
    contentType ? `&contentType=${contentType}` : ""
  }`;
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

export default {
  getDocumentsByWorkspace,
  postTrainingData,
  updateTrainingData,
  deleteTrainingData,
  getDocumentById,
  bulkImportUrlGeneration,
  getEmebddingById,
  getEmbeddingByDocumentID,
};
