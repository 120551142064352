import API from "@aws-amplify/api";
import {
  createDatabaseConnection,
  deleteDatabaseConnection,
  updateDatabaseConnection,
} from "../../graphql/mutations";
import {
  connectionsByWorkspace,
  getDatabaseConnection,
} from "../../graphql/queries";
const createDatabaseConnection_ = async (object) => {
  return API.graphql({
    query: createDatabaseConnection,
    variables: { input: object },
  });
};
const getDatabaseConnection_ = async (id) => {
  return API.graphql({
    query: getDatabaseConnection,
    variables: { id },
  });
};
const updateDatabaseConnection_ = async (id, _properties = {}) => {
  const original = await getDatabaseConnection_(id);
  return API.graphql({
    query: updateDatabaseConnection,
    variables: {
      input: {
        id,
        _version: original?.data?.getDatabaseConnection?._version,
        ..._properties,
      },
    },
  });
};

const deleteDatabaseConnection_ = async (id) => {
  const original = await getDatabaseConnection_(id);
  return API.graphql({
    query: deleteDatabaseConnection,
    variables: {
      input: {
        id,
        _version: original?.data?.getDatabaseConnection?._version,
      },
    },
  });
};

const getDatabaseConnections_ = async (workspaceID, nextToken) => {
  return API.graphql({
    query: connectionsByWorkspace,
    variables: {
      workspaceID,
      nextToken,
    },
  });
};

export default {
  createDatabaseConnection: createDatabaseConnection_,
  updateDatabaseConnection: updateDatabaseConnection_,
  deleteDatabaseConnection: deleteDatabaseConnection_,
  getDatabaseConnection: getDatabaseConnection_,
  getDatabaseConnections: getDatabaseConnections_,
};
