import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppChoice from "@toothfairy/shared-ui/AppChoice";
import { AppText } from "@toothfairy/shared-ui";
import AppModal from "@toothfairy/shared-ui/AppModal";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { Popable } from "react-native-popable";
import React, { useMemo } from "react";
import { Platform, View } from "react-native";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { isLargeScreen, isMobileScreen } from "../../Screens/MainViewContainer";
import ToothFairySwitch from "../ToothFairyComponents/ToothFairySwitch";
import SavingNotification from "./SavingNotification";

const TrainSwitcher = ({
  storeInError,
  storeInProgress,
  isSaved,
  isParser,
  handleNewDocument,
  routeBackToParser,
  setIsParser,
  documentId,
  setIsTrainerNoDocumentId,
  title,
  children,
  childrenComponentRelativePosition = "left",
  parseInProgress,
  isTrainingEnabled = false,
  setIsValidationData,
  isValidationData,
  isTrainerNoDocumentId,
  savingNotificationWrapperStyle,
  handleShowTrData = () => {},
}) => {
  const [showChoice, setShowChoice] = React.useState(false);
  const { currentTheme } = AppTheme.useTheme();
  const { isUserAdmin, isUserAIEngineer } = useWorkspaces();
  const _coreModalView = useMemo(() => {
    return (
      <AppModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                maxWidth: 600,
                maxHeight: "80%",
                margin: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={showChoice}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle=" "
        modalInnerContentStyle={{
          paddingHorizontal: 50,
          paddingVertical: 20,
        }}
        closeModal={() => setShowChoice(false)}
      >
        <AppChoice
          wrapperStyle={{ alignSelf: "center", maxWidth: 600 }}
          caption="mainSaveDocumentCaption"
          subCaption="secondarySaveDocumentCaption"
          cancelCaption="cancelSaveDocumentCaption"
          confirmCaption="confirmSaveDocumentCaption"
          onConfirmButtonPress={() => {
            handleNewDocument();
            setIsTrainerNoDocumentId(false);
            setIsParser(false);
            setShowChoice(false);
          }}
          onCancelButtonPress={() => {
            setShowChoice(false);
            setIsTrainerNoDocumentId(true);
            setIsParser(false);
          }}
          AppChoiceButton={ToothFairyButton}
        />
      </AppModal>
    );
  }, [
    showChoice,
    handleNewDocument,
    setIsTrainerNoDocumentId,
    setIsParser,
    currentTheme,
  ]);

  const _saveStatus = useMemo(() => {
    if (!documentId && parseInProgress)
      return {
        label: "Processing",
        color: "primary",
        dotValue: 1,
        isProgress: true,
        icon: AppAssets.icons.save,
      };
    else if (isTrainerNoDocumentId && isTrainingEnabled)
      return {
        label: "Train mode",
        color: "primary",
        dotValue: 0,
        isProgress: true,
      };
    else if ((!documentId && !parseInProgress) || isParser)
      return {
        label: "Parse mode",
        color: "mediumGrey",
        dotValue: 0,
        isProgress: true,
      };
    else {
      if (!storeInError && !storeInProgress && isSaved)
        return {
          label: "Saved",
          color: "green",
          dotValue: 1,
          isProgress: false,
          icon: AppAssets.icons.save,
        };
      else if (storeInProgress)
        return {
          label: "Saving...",
          color: "orange",
          dotValue: 1,
          isProgress: true,
          icon: AppAssets.icons.clock,
        };
      else if (!isSaved)
        return {
          label: "Not saved",
          color: "red",
          dotValue: 1,
          isProgress: true,
          icon: AppAssets.icons.risk,
        };
      else if (storeInError)
        return {
          label: "Error while saving",
          color: "red",
          dotValue: 1,
          isProgress: true,
          icon: AppAssets.icons.risk,
        };
      else
        return {
          label: "",
          dotValue: 0,
        };
    }
  }, [
    documentId,
    isSaved,
    storeInError,
    storeInProgress,
    parseInProgress,
    isTrainerNoDocumentId,
  ]);
  const _savingNotification = useMemo(() => {
    return (
      <SavingNotification
        color={_saveStatus?.color}
        dotValue={_saveStatus?.dotValue}
        icon={_saveStatus?.icon}
        isProgress={_saveStatus?.isProgress}
        label={_saveStatus?.label}
        isParser={isParser}
      />
    );
  }, [_saveStatus, isParser]);
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "baseline",
            flex: 1,
          },
          savingNotificationWrapperStyle,
        ]}
      >
        {isMobileScreen() && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            {_savingNotification}
          </View>
        )}

        {childrenComponentRelativePosition === "left" && children}
      </View>
      <View
        style={{
          flexDirection: !isMobileScreen() ? "row" : "column",
        }}
      >
        {!isParser && isLargeScreen() && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Popable
              numberOfLines={2}
              backgroundColor={currentTheme?.theme?.tooltipBackgroundColor}
              style={[
                {
                  top: 30,
                  zIndex: 20,
                  maxWidth: 300,
                },
              ]}
              content={"Show training data"}
              action="hover"
              position="bottom"
            >
              <AppText
                leftIcon={AppAssets?.icons?.info}
                isClickable
                color={currentTheme?.theme?.primary}
                onPress={handleShowTrData}
                style={{
                  fontSize: 10,
                  marginRight: 10,
                }}
                iconSize={18}
              >
                {" "}
              </AppText>
            </Popable>

            <ToothFairySwitch
              wrapperStyle={{
                flex: 1,
                marginRight: 0,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
              }}
              label={"Validation"}
              onChange={(v) => {
                setIsValidationData(v);
              }}
              isTicked={isValidationData}
            />
          </View>
        )}
        {childrenComponentRelativePosition === "right" && children}
        {!isMobileScreen() && _savingNotification}
      </View>
      {showChoice && _coreModalView}
    </View>
  );
};

export default TrainSwitcher;
