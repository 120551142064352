import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import envConfig from "../../../envConfig";
import Common from "../Common";

const generateRequestPayload = ({
  passage,
  token,
  topics,
  isTopicModeOn,
  workspaceid,
  sourceNERs = [],
  limit,
  includeReadComprehensionData,
  forceQuestions,
}) => {
  let body = {
    passage,
    topics,
    workspaceid,
    sourceNERs,
    limit,
    type: isTopicModeOn ? "topic" : "passage",
    includeReadComprehensionData,
    forceQuestions,
  };

  return Common.objectCleaner(body);
};

const getQuestions = async ({
  passage,
  token,
  topics,
  isTopicModeOn,
  workspaceid,
  sourceNERs = [],
  limit = 4,
  includeReadComprehensionData,
  forceQuestions,
}) => {
  if (!token) return { data: null };
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/questioner`;
  const body = generateRequestPayload({
    passage,
    token,
    topics,
    isTopicModeOn,
    workspaceid,
    sourceNERs,
    limit,
    includeReadComprehensionData,
    forceQuestions,
  });
  return secureRequest(client, "POST", url, null, body, false, headers);
};

export default {
  getQuestions,
  generateRequestPayload,
};
