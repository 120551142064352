import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  //   indexAxis: "y",
  //   elements: {
  //     bar: {
  //       borderWidth: 2,
  //     },
  //   },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};
defaults.font.family = "normalFont";
defaults.font.size = 14;
defaults.scale.ticks.precision = 0;

const AppLineChart = ({ dataset }) => {
  return (
    <Line
      style={{
        // backgroundColor: "rgba(29, 184, 198,0.05)",
        borderRadius: 10,
      }}
      options={options}
      data={dataset}
    />
  );
};

export default AppLineChart;
