import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppList from "@toothfairy/shared-ui/AppList";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, useWindowDimensions, View } from "react-native";
import Notification from "../../Components/Notification";
import PageTitle from "../../Components/PageTitle";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairyModal from "../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairyOutputModal from "../../Components/ToothFairyComponents/ToothFairyOutputModal";
import ToothFairySearch from "../../Components/ToothFairyComponents/ToothFairySearch";
import ToothFairySupportButton from "../../Components/ToothFairyComponents/ToothFairySupportButton";
import ToothFairyTab from "../../Components/ToothFairyComponents/ToothFairyTab";
import useSections from "../../Hooks/useSections";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { BatchJobStatus } from "../../models";
import DateFilter from "../Admin/SubComponents/Leafs/DateFilter";
import { isLargeScreen, minAppWidth } from "../MainViewContainer";
import Styles from "../Styles/Styles";
import MetaSelection from "./Components/MetaSelection";

dayjs.extend(relativeTime);

const BatchScreen = ({
  onUpdateStartDate = () => {},
  onUpdateEndDate = () => {},
  onFilterChange = () => {},
  handleFileSelect = () => {},
  statusFilter,
  startDate,
  endDate,
  handleSearch = () => {},
  handleTabChange = () => {},
  handleFieldUpdate = () => {},
  selectedTab,
  currentPage,
  handlePageChange = () => {},
  handleBatchInitiation = () => {},
  handleCancelBatch = () => {},
  batchLogs,
  logStatusColors,
  batchParsingInProgress,
  routes,
  initialTabFromRouting,
  handleBatchOutputDataDownload,
  batchConfig,
  setBatchConfig,
  ...props
}) => {
  const pageSize = 25;
  const dimensions = useWindowDimensions();
  const { currentTheme } = AppTheme.useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const [isBatchInitiationModalOpen, setIsBatchInitiationModalOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const styles = styleGenerator(currentTheme);
  const { state } = AppStateManager.useAppStateManager();
  const {
    getActiveWorkspace,
    getWorkspaceEntities,
    generateAdaptedTopics,
    getLastTabSelectedByScreen,
    getUserName,
  } = useWorkspaces();
  const topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic?.label;
    topic.category = topic.type;
    return topic;
  });

  const tabIndexFromRouting = routes
    .map((u) => u.routeId)
    .indexOf(initialTabFromRouting);

  const _initialIndex =
    tabIndexFromRouting > -1
      ? tabIndexFromRouting
      : getLastTabSelectedByScreen("batch");
  const handleItemSelection = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  //   const generateColorForItem = (item) => {
  //     if (item?.status === "swapping") return currentTheme?.theme?.orange;
  //     else if (isTrainingLogIdActive(item?.id))
  //       return currentTheme?.theme?.primary;
  //     else if (item.status !== "dispatched" && item.status !== "inProgress")
  //       return currentTheme?.theme?.grey;
  //     else return currentTheme?.theme?.red;
  //   };
  const batchInitiationModal = useMemo(() => {
    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                maxWidth: 600,
                maxHeight: "80%",
                margin: "auto",
                borderRadius: 20,
                marginHorizontal: "auto",
                height: "auto",
              }
            : {}
        }
        isModalVisible={isBatchInitiationModalOpen}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle=" "
        modalInnerContentStyle={{
          paddingHorizontal: 50,
          paddingVertical: 20,
        }}
        closeModal={() => {
          setIsBatchInitiationModalOpen(false);
          setBatchConfig({
            name: "",
            description: "",
          });
        }}
      >
        <MetaSelection
          batchConfig={batchConfig}
          selectedTab={selectedTab}
          setBatchConfig={setBatchConfig}
          setIsBatchInitiationModalOpen={setIsBatchInitiationModalOpen}
          topics={topics}
          handleBatchInitiation={handleBatchInitiation}
        />
      </ToothFairyModal>
    );
  }, [
    handleBatchInitiation,
    selectedTab?.routeId,
    state.activeWorkspaceID,
    isBatchInitiationModalOpen,
    setIsBatchInitiationModalOpen,
  ]);
  const batchLogsList = () => {
    return (
      <View
        style={{
          height: dimensions.height - 210,
          minHeight: 200,
          maxWidth: !isLargeScreen() && dimensions.width - 30,
        }}
      >
        <ToothFairySearch handleSearch={handleSearch}>
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            onUpdateEndDate={onUpdateEndDate}
            onUpdateStartDate={onUpdateStartDate}
          />

          <AppDropDown
            translateLabels
            showTextInputLabel
            label="Status"
            inputLabelStyle={{
              marginBottom: 0,
              marginLeft: 5,
            }}
            dropDownStyle={{
              minWidth: 184,
              maxWidth: 184,
              textAlign: "center",
              marginLeft: 20,
              borderColor: currentTheme?.theme?.border_color,
              zIndex: -1,
            }}
            wrapperContainer={{
              flexDirection: "row",
            }}
            inputLabelFontWeight={"normal"}
            isElevated={false}
            dropdownIconColor="transparent"
            items={[...Object.values(BatchJobStatus), "noFilter"]}
            selectedValue={statusFilter}
            onValueChange={(itemValue, itemIndex) => {
              onFilterChange(itemValue);
            }}
          />
        </ToothFairySearch>
        {batchLogs?.length > 0 ? (
          <View
            style={{
              height: dimensions.height - 357,
              minHeight: 400,
              zIndex: -1,
            }}
          >
            <AppList
              contentContainerStyle={styles.listContentContainer}
              isSeparatorVisible={true}
              items={batchLogs
                ?.sort((a, b) =>
                  a?._lastChangedAt < b?._lastChangedAt ? 1 : -1
                )
                ?.filter((u) => u?.type === selectedTab?.routeId)
                .slice(
                  (currentPage - 1) * pageSize,
                  (currentPage - 1) * pageSize + pageSize
                )}
              keyExtractor={"id"}
              renderItem={({ item, index }) => (
                <>
                  {index == 0 && (
                    <View style={[styles.listItemContainer, styles.listHeader]}>
                      <AppText
                        style={{ fontWeight: "bold" }}
                        wrapperStyle={{ width: 20 }}
                      >
                        {"  "}
                      </AppText>

                      <AppText
                        style={{ fontWeight: "bold" }}
                        wrapperStyle={{
                          width: isLargeScreen() ? "25%" : "50%",
                        }}
                      >
                        Name
                      </AppText>
                      {isLargeScreen() && (
                        <>
                          <AppText
                            style={{ fontWeight: "bold" }}
                            wrapperStyle={{ width: "15%" }}
                          >
                            Created by
                          </AppText>
                          <AppText
                            style={{ fontWeight: "bold" }}
                            wrapperStyle={{ width: "15%" }}
                          >
                            Updated at
                          </AppText>
                          <AppText
                            style={{ fontWeight: "bold" }}
                            wrapperStyle={{ width: "15%" }}
                          >
                            Records
                          </AppText>
                          <AppText
                            style={{ fontWeight: "bold" }}
                            wrapperStyle={{ width: "10%" }}
                          >
                            Duration (min.)
                          </AppText>
                        </>
                      )}

                      <AppText
                        style={{ fontWeight: "bold" }}
                        wrapperStyle={{
                          width: "15%",
                          maxWidth: 200,
                          justifyContent: "center",
                        }}
                      >
                        Status
                      </AppText>
                    </View>
                  )}
                  <View style={styles.listItemContainer}>
                    <AppIcon
                      style={{ width: "5%" }}
                      icon={AppAssets.icons.close}
                      color={
                        item.status === "dispatched"
                          ? currentTheme?.theme?.red
                          : currentTheme?.theme?.grey
                      }
                      size={16}
                      isClickable={item.status === "dispatched"}
                      onPress={() => {
                        handleCancelBatch(item.id);
                      }}
                    />
                    <AppText
                      wrapperStyle={{ width: isLargeScreen() ? "25%" : "50%" }}
                    >
                      {item.name || "No name"}
                    </AppText>
                    {isLargeScreen() && (
                      <>
                        <AppText wrapperStyle={{ width: "15%" }}>
                          {getUserName(item.createdBy)}
                        </AppText>
                        <AppText wrapperStyle={{ width: "15%" }}>
                          {dayjs(item._lastChangedAt).fromNow()}
                        </AppText>
                        <AppText wrapperStyle={{ width: "15%" }}>
                          {item?.batchMeta?.parsedSentencesCount}
                        </AppText>
                        <AppText
                          wrapperStyle={{ width: "15%", marginLeft: 10 }}
                        >
                          {item?.batchMeta?.duration &&
                            parseFloat(
                              (item?.batchMeta?.duration || 0) / 60
                            ).toFixed(2)}
                        </AppText>
                      </>
                    )}
                    <AppText
                      rightIcon={
                        (item.status === "completed" ||
                          item.status === "inError") &&
                        AppAssets.icons.info
                      }
                      iconSize={16}
                      isClickable={
                        item.status === "completed" || item.status === "inError"
                      }
                      onRightIconPress={() => handleItemSelection(item)}
                      color={currentTheme?.theme[logStatusColors[item.status]]}
                      wrapperStyle={{
                        maxWidth: 200,
                        justifyContent: "flex-center",
                      }}
                      fontWeight="bold"
                    >
                      {item.status === "dispatched" ? "In queue" : item.status}
                    </AppText>
                  </View>
                </>
              )}
            />

            <AppPagination
              onPageChange={handlePageChange}
              totalCount={batchLogs?.length}
              siblingCount={1}
              currentPage={currentPage}
              pageSize={pageSize}
              wrapperStyle={[styles.paginationWrapper]}
            />
          </View>
        ) : (
          <AppPlaceholderView
            wrapperStyle={{
              width: "100%",
              height: "100%",
              maxHeight: 300,
              marginVertical: "auto",
              marginHorizontal: "auto",
              justifyContent: "center",
            }}
            size={80}
            icon={AppAssets.icons.orbit}
            mainCaption="EmptyPlaceholder"
            subCaption="batchLogsubCaption"
          />
        )}
      </View>
    );
  };

  return (
    <AppBaseScreen
      isScrollable={true}
      contentContainerStyle={{
        overflow: "auto",
      }}
      style={{ minHeight: 700 }}
    >
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <PageTitle>Batch</PageTitle>
          <ToothFairySupportButton />
          <ToothFairyButton
            {...Styles.minifiedButtonStyle}
            isDisabled={batchParsingInProgress || !state?.isWorkspaceReady}
            onPress={() => {
              setIsBatchInitiationModalOpen(true);
            }}
            title="Start batch"
            accessibilityLabel="batchButton"
          />
          {batchParsingInProgress && (
            <Notification
              style={{
                position: "absolute",
                right: 0,
                top: 10,
              }}
              color={"primary"}
              dotValue={1}
              label="Batching in progress"
              isProgress={true}
            />
          )}
        </View>
        <ToothFairyTab
          onTabChange={handleTabChange}
          isAnimated={false}
          renderSceneObject={{
            nlpTraining: batchLogsList,
            generativeTraining: batchLogsList,
            readComprehensionTraining: batchLogsList,
          }}
          routes={routes}
          initialIndex={_initialIndex}
        />
        <ToothFairyOutputModal
          isEventDriven={true}
          requestBodyType="form-data"
          path={"documents/uploadBatchParsing"}
          resultObjectTitle=" Batch output"
          responseCopyBtnTitle="Copy output"
          resultObjectIcon={AppAssets.icons.duplicate}
          requestPayload={{
            filename: "your_batch_file.csv",
            workspaceid: getActiveWorkspace()?.id,
            type: selectedItem?.type,
            name: selectedItem?.name,
            description: selectedItem?.description,
          }}
          handleFieldUpdate={(id, property, value) => {
            handleFieldUpdate(id, property, value);
            setSelectedItem({
              ...selectedItem,
              [property]: value,
            });
          }}
          createdBy={selectedItem?.createdBy}
          lastChangedAt={selectedItem?._lastChangedAt}
          meta={selectedItem?.batchMeta}
          showSummary={true}
          name={selectedItem?.name}
          description={selectedItem?.description}
          urlInputWidth={550}
          isOutputUpToDate={true}
          handleOutputResize={() => {}}
          isOutputExpanded={true}
          batchParsingInProgress={batchParsingInProgress}
          parsingInProgress={false}
          batchLogId={selectedItem?.id}
          data={selectedItem?.batchMeta}
          subCaption={"BatchMetaSubCaption"}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          checkLength={false}
          handleDataDownload={handleBatchOutputDataDownload}
          includeConfig={true}
          configObjectTitle="Batch config"
          configObjectIcon={AppAssets.icons.cogWheel}
          configObject={selectedItem?.batchConfig}
        />
      </View>
      {batchInitiationModal}
    </AppBaseScreen>
  );
};

const styleGenerator = (currentTheme) => {
  return StyleSheet.create({
    container: {
      paddingHorizontal: 20,
      paddingVertical: 10,
      minWidth: minAppWidth(),
    },
    listContentContainer: {
      padding: 0,
    },
    listHeader: {
      // backgroundColor: currentTheme?.theme?.lightGrey,
    },
    listItemContainer: {
      flexDirection: "row",
      padding: 10,
    },
    paginationWrapper: {
      marginTop: 10,
      alignSelf: "flex-end",
    },
    title: {
      fontSize: currentTheme?.theme?.largest_font_size,
      fontWeight: currentTheme?.theme?.font_bold,
      marginRight: 20,
    },
    titleContainer: {
      alignItems: "center",
      flexDirection: "row",
    },
  });
};

export default BatchScreen;
