import React, { useEffect, useRef, useState } from "react";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { AppIcon } from "@toothfairy/shared-ui";

const AudioRecorder = ({
  onForcedStop = () => {},
  onRecordingCompleted = () => {},
  onRecordingStarted = () => {},
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const [recordedUrl, setRecordedUrl] = useState("");
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const [isRecording, setIsRecording] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [recordedBlob, setRecordedBlob] = useState(null);
  //   track the time elapsed every second
  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimeElapsed((prev) => prev + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setTimeElapsed(0);
    }
    return () => {
      clearInterval(interval);
      setTimeElapsed(0);
    };
  }, [isRecording]);
  useEffect(() => {
    if (timeElapsed > 600) {
      stopRecording();
      onForcedStop();
    }
  }, [timeElapsed]);
  const startRecording = async () => {
    try {
      setIsRecording(true);
      onRecordingStarted();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/wav" });
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        setRecordedBlob(recordedBlob);
        chunks.current = [];
        setIsRecording(false);
        onRecordingCompleted();
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };
  useEffect(() => {
    return () => {
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (recordedUrl != "" && recordedBlob != null) {
      onRecordingCompleted(recordedBlob, recordedUrl);
      setRecordedBlob(null);
      setRecordedUrl("");
    }
  }, [recordedUrl]);

  return (
    <AppIcon
      onPress={isRecording ? stopRecording : startRecording}
      isClickable
      icon={
        isRecording
          ? AppAssets?.icons?.stopGeneration
          : AppAssets?.icons?.microphone
      }
      color={
        isRecording ? currentTheme?.theme?.red : currentTheme?.theme?.primary
      }
      {...props}
    />
  );
};
export default AudioRecorder;
