import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import usersHelper from "../../API/Users/usersHelper";
import usePath from "../../Hooks/usePath";
import { SectionsProvider } from "../../Hooks/useSections";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory } from "../../Router";
import AccountScreen from "./AccountScreen";
import { DataStore } from "@aws-amplify/datastore";
import Utility from "../../Utility";

const AccountScreenContainer = ({ setCurrentView }) => {
  const { i18n } = useTranslation();
  const AppToasty = AppToast.useToast();
  const history = useHistory();
  const { params } = usePath();
  const { user, updateUser } = AppUser.useUser();
  const { getLastTabSelectedByScreen, handleSectionSelection } =
    useWorkspaces();
  let _defaultView;
  if (params.sectionId) {
    _defaultView =
      params.sectionId?.toUpperCase() ||
      getLastTabSelectedByScreen("profile", "PROFILE");
  }
  const clearCache = async () => {
    await DataStore.clear();
    window.location.reload();
  };
  const optionsDataGenerator = useCallback(
    () => [
      {
        isNavigation: true,
        icon: AppAssets.icons.userInfo,
        isBackHidden: false,
        uid: "PROFILE_SETTINGS",
        label: "Profile settings",
        subOptions: [],
      },
      {
        isNavigation: true,
        icon: AppAssets.icons.settings,
        uid: "NLP",
        label: "User preferences",
        subOptions: [],
      },
      {
        isNavigation: true,
        icon: AppAssets.icons.billing,
        uid: "BILLING",
        label: "Billing",
        subOptions: [],
      },
    ],
    [JSON.stringify(user)]
  );

  const handleUserUpdate = (property, value) => {
    try {
      if (user[property] !== value) {
        usersHelper.updateUser(user?.id, [
          {
            property,
            value,
          },
        ]);
        updateUser({ ...user, [property]: value });
        if (property === "language") i18n.changeLanguage(value);
        // #332 to do
        AppToasty.show(`${Utility.camelCaseToSpaced(property)} updated`, {
          type: "success",
        });
      }
    } catch (error) {
      AppToasty.show(
        `${Utility.camelCaseToSpaced(property)} could not update`,
        { type: "danger" }
      );
      console.error("Error while updating profile", error);
    }
  };

  return _defaultView ? (
    <SectionsProvider
      defaultViewUID={_defaultView}
      onDisplayedViewChanged={(section) => {
        handleSectionSelection(section, "profile");
        if (section) history.push(`/profile/${section.toLowerCase()}`);
      }}
      sectionsMainTitle="Profile"
      optionsDataGenerator={optionsDataGenerator}
    >
      <AccountScreen
        clearCache={clearCache}
        setCurrentView={setCurrentView}
        handleUserUpdate={handleUserUpdate}
      />
    </SectionsProvider>
  ) : (
    <></>
  );
};

export default AccountScreenContainer;
