import API, { graphqlOperation } from "@aws-amplify/api";
import useApi from "@toothfairy/shared-api/useApi";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../../shared/src/styles/sharedStyles.css";
import { displayedToasties } from "../../../App";
import envConfig from "../../../envConfig";
import Answerer from "../../API/Answerer";
import Questioner from "../../API/Questioner";
import streamHelper from "../../API/Stream/streamHelper";
import trainingAPI from "../../API/Training/trainingAPI";
import useAutoSave from "../../Hooks/useAutoSave";
import useFileSelector from "../../Hooks/useFileSelector";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory, useParams } from "../../Router";
import { onUpdateOutputStreamByIDEvent } from "../../graphql/subscriptions";
import { initialEditorValueForQuestions } from "../TestExample/commonUtils";
import AnswererScreen from "./AnswererScreen";
import Files from "../../API/Files";
import * as unified from "unified";
import markdown from "remark-parse";
import slate from "remark-slate";
import Utility from "../../Utility";

// create a function that removes a substring from a string if exists at the start
const removeSubstring = (str, subStr) => {
  if (str.startsWith(subStr)) {
    return str.substring(subStr.length);
  }
  return str;
};

const AnswererScreenContainer = ({ navigation }) => {
  const history = useHistory();
  const [streamid, setStreamid] = useState(null);
  const [agent, setAgent] = useState(null);
  const [outputValue, setOutputValue] = useState([]);
  const [streamStatus, setStreamStatus] = useState(null);
  const [streamObject, setStreamObject] = useState(null);
  const { documentId } = useParams();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [isTrainerNoDocumentId, setIsTrainerNoDocumentId] = useState(false);
  const [readyForNextQuestion, setReadyForNextQuestion] = useState(true);
  const [prompt, setPrompt] = useState("");
  const { t } = useTranslation();
  const {
    getActiveWorkspace,
    getWorkspaceEntities,
    generateAdaptedTopics,
    generatorConfigData,
  } = useWorkspaces();
  const AppToasty = AppToast.useToast();
  const { user } = AppUser.useUser();
  const [nlpData, setNLPData] = useState([]);
  const [nlpConfig, setNlpConfig] = useState({});
  const [qValue, setQValue] = useState(initialEditorValueForQuestions);
  const [forcedLanguage, setForcedLanguage] = useState(user?.replyLanguage);
  const [requestPayload, setRequestPayload] = useState(null);
  const [questionsWordsCount, setQuestionsWordsCount] = useState(0);
  const [questionsCharactersCount, setQuestionsCharactersCount] = useState(0);
  const [showDictionaryModal, setShowDictonaryModal] = React.useState(false);
  const [initialMapValues, setInitialMapValues] = useState({
    sourceLanguage: "en",
    targetLanguage: "it",
    sourceText: "",
    targetText: "",
    description: "",
    pos: "",
  });
  const [lastQuestionsDataTimeStamp, setLastQuestionsDataTimeStamp] =
    useState(0);
  const [lastNlPDataTimeStamp, setLastNLPDataTimeStamp] = useState(0);
  const topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic?.label;
    topic.category = topic.type;
    return topic;
  });
  const ners = getWorkspaceEntities("ner")?.map((entity) => {
    entity.name = entity?.label;
    entity.category = entity.type;
    return entity;
  });
  let _lastPrompt;
  useEffect(() => {
    if (prompt && prompt !== _lastPrompt) {
      console.log("prompt", prompt);
      console.log("_lastPrompt", _lastPrompt);
      _lastPrompt = prompt;
      let mergedItems = [
        // ...state?.generationConfigData?.prompts,
        ...generatorConfigData.map((item) => ({ ...item, isCustom: true })),
      ];

      // console.log("prompt", sty);
      promptData = mergedItems.find(
        (u) => u.id?.toString() === prompt?.toString()
      );
      console.log("promptData", promptData);
      if (promptData && !promptData?.isCustom)
        setQValue([
          {
            type: "paragraph",
            children: [
              {
                text: "",
              },
            ],
          },
        ]);
      else {
        setQValue([
          {
            type: "paragraph",
            children: [{ text: promptData?.interpolationString || "" }],
          },
        ]);
      }
      _lastPrompt = promptData?.interpolationString || "";
    }
  }, [
    prompt,
    JSON.stringify(state?.configData?.generator),
    JSON.stringify(generatorConfigData),
    getActiveWorkspace()?.domain,
    state?.generatorUserSelection?.styles,
    _lastPrompt,
  ]);
  const { handleFileSelect, isUploading } = useFileSelector({
    _importType: "imported-pdf",
    _importFile: "pdf",
    // Character Margin and Line margin
    additionalFilenameParams: `_${"0~6"}_${"0~5"}_false_readComprehensionTraining`,
    _appLogImportType: "documentImport",
    _appLogImportEvent: "readComprehensionTraining pdf import",
    showSuccessOnUpload: false,
  });

  useEffect(() => {
    setIsTrainerNoDocumentId(!documentId && !isParser);
  }, [documentId, isParser]);
  useEffect(() => {
    if (!agent) {
      setAgent(
        state?.agents?.find(
          (u) => u?.agentType === "BusinessAnalyst" && u?.isGlobal === true
        )?.id
      );
    }
  }, [state?.agents?.length]);

  const {
    isInactive,
    value,
    editor,
    updateAPIResult,
    updateAPIError,
    createAPIError,
    updateAPIInProgress,
    createAPIInProgress,
    setIsValidationData,
    isValidationData,
    updateAPIRequest,
    createAPIRequest,
    getAPIRequest,
    lastUpdateTime,
    lastCreationTime,
    lastChange,
    isEditorLoaded,
    saveDocument,
    handleSocketConnectionChange,
    createDocument,
    setValue,
    setEditor,
    editorQA,
    setEditorQA,
    getAPIResult,
    goToParser,
    selectedTopics,
    setSelectedTopics,
    isDirty,
    isParser,
    setIsParser,
    rawText,
    charactersCount,
    wordsCount,
    wordsAndCharactersCountFromEditorValue,
    isSaved,
    trainingData,
    handleCreationOfDocumentFromGnOutput: handleCreationOfDocumentFromQaOutput,
  } = useAutoSave({
    documentId,
    workspaceid: getActiveWorkspace()?.id,
    user,
    updateMethod: trainingAPI.updateTrainingData,
    createMethod: trainingAPI.postTrainingData,
    isRCTraining: true,
    route: "rc",
    documentType: "readComprehensionTraining",
    backupProperty: "initialRCValue",
    backupCommand: "SET_INITIAL_RC_VALUE",
    savesSkipsForBooting: 0,
    initialValue: initialEditorValueForQuestions,
  });
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useApi(Files.downloadUrlGeneration, envConfig);
  const handleCreationOfDocument = ({
    documentTitle,
    selectedTopics,
    isInclusionOfPassageRequired,
    isRedirectRequired,
  }) => {
    let _outputValue = null;
    const value = unified
      .unified()
      .use(markdown)
      .use(slate)
      .process(markdownString, (err, file) => {
        if (err) throw err;
        _outputValue = file?.result;
      });

    if (isInclusionOfPassageRequired) {
      _outputValue = [...qValue, ..._outputValue];
    }
    handleCreationOfDocumentFromQaOutput(
      Utility.purifySlateObject(_outputValue),
      documentTitle || "No title",
      "questionAnswering",
      isRedirectRequired,
      selectedTopics
    );
  };
  useEffect(() => {
    setLastQuestionsDataTimeStamp(new Date().getTime());
    const result = wordsAndCharactersCountFromEditorValue(qValue);
    setQuestionsWordsCount(result.wordsCount);
    setQuestionsCharactersCount(result.charactersCount);
  }, [qValue]);

  const forceEditorWithValue = (value) => {
    if (editor?.children) {
      setQValue(value);
      editor.children = value;
      setEditor(editor);
    }
  };

  const [selectedNers, setSelectedNers] = useState([]);
  const [isTopicModeOn, setIsTopicModeOn] = useState(true);
  const [isAutoQuestionOn, setIsAutoQuestionModeOn] = useState(false);
  const [isTopicTrainingScope, setisTopicTrainingScopeAsTopic] =
    useState(false);
  const onSelectedTopicsChange = (selectedItems) => {
    setSelectedTopics(selectedItems);
  };
  const onSelectedNersChange = (selectedItems) => {
    setSelectedNers(selectedItems);
  };
  useEffect(() => {
    if (isTopicModeOn) {
      setIsAutoQuestionModeOn(false);
    }
  }, [isTopicModeOn]);
  const [
    showConfirmationModalForTraining,
    setShowConfirmationModalForTraining,
  ] = useState(false);
  // this.state = { editorState: EditorState.createEmpty() };
  // let editorHTML;
  const {
    data: answerResult,
    error: answerError,
    loading: answerInProgress,
    apiRequest: getAnswersRequest,
  } = useApi(Answerer.getAnswers, envConfig, true);

  const {
    data: questionResult,
    error: questionError,
    loading: questionInProgress,
    apiRequest: getQuestionsRequest,
  } = useApi(Questioner.getQuestions, envConfig, true);

  const generateQuestionsFromQValue = () => {
    const questions = [];
    for (let i = 0; i < qValue.length; i++) {
      const block = qValue[i];
      if (block.type === "title") continue;
      if (block.isAnswer === true) continue;
      const nodeText =
        AppQuantumEditorUtils.Helpers.generateStringFromElement(block);
      if (nodeText?.trim() === "" && nodeText.split(" ").length < 3) continue;
      questions.push({
        id: i,
        question: removeSubstring(nodeText, "Question:"),
        answerLanguage: forcedLanguage,
      });
    }
    return questions;
  };
  const _output = outputValue?.filter(
    (o) => o?.children?.length > 0 && o?.children[0]?.text?.trim() !== ""
  );
  const markdownString =
    _output
      .map((item, index) => {
        // If it's neither the first nor the last item, add the bullet point
        const prefix = "";
        return `${prefix}${item.children[0]?.text}  \n`;
      })
      .join("\n") || "";
  const generateRawPayload = () => {
    if (getActiveWorkspace())
      return {
        passage: !isTopicModeOn ? rawText || "" : "",
        questions: isAutoQuestionOn ? [] : generateQuestionsFromQValue(),
        token: getActiveWorkspace()?.workspaceToken,
        topics: selectedTopics?.map((topic) => topic.id),
        isTopicModeOn,
        workspaceid: getActiveWorkspace()?.id,
        uniqueAnswer: getActiveWorkspace()?.parsingConfig?.uniqueAnswer,
        answerByParagraph:
          getActiveWorkspace()?.parsingConfig?.answerByParagraph,
        forcedLanguage: forcedLanguage,
        sourceNERs: selectedNers?.map((ner) => ner.name),
        limit: getActiveWorkspace()?.parsingConfig?.questionsLimit,
        includeReadComprehensionData: getActiveWorkspace()?.trainingConfig
          ?.includeReadComprehensionDataInTraining
          ? "true"
          : "false",
      };
  };
  const handleOutputCopy = () => {
    AppToasty.show(t("Copied to clipboard"), {
      type: "success",
    });
  };
  useEffect(() => {
    const rawPayload = generateRawPayload();
    if (!rawPayload) return;
    if (isAutoQuestionOn)
      setRequestPayload(Questioner.generateRequestPayload(rawPayload));
    else {
      setRequestPayload(Answerer.generateRequestPayload(rawPayload));
    }
  }, [
    isAutoQuestionOn,
    rawText,
    selectedTopics,
    isTopicModeOn,
    selectedNers,
    qValue,
    forcedLanguage,
  ]);
  const textToDisplay = "**...**";
  const delay = 0;

  const isQuestionsEditorDirty =
    qValue?.filter(
      (item) =>
        item?.isAnswer === false &&
        item?.children &&
        item?.children[0]?.text?.trim() != ""
    )?.length > 0;
  const isAnswersEditorDirty =
    qValue?.filter((item) => item?.isAnswer === true)?.length > 0;

  let timerId;
  let lastItem = null;
  let lastActiveDoc = null;
  let streamSubscription;
  let streamedDocs = [];
  const handleCancellation = () => {
    if (streamid) {
      streamObject?.unsubscribe();
      setStreamStatus("fulfilled");
      clearTimeout(timerId);
      setReadyForNextQuestion(true);
      setStreamid(null);
      if (streamStatus !== "inProgress") handleReadinessForNextQuestion();
    }
  };
  useEffect(() => {
    if (streamid) {
      streamSubscription = API.graphql(
        graphqlOperation(onUpdateOutputStreamByIDEvent, {
          id: streamid,
        })
      ).subscribe({
        next: (data) => {
          let rawMessage = data?.value?.data?.onUpdateOutputStreamByIDEvent;
          rawMessage.metadata = JSON.parse(rawMessage.metadata);
          const msg = { element: rawMessage };
          setStreamStatus(msg?.element?.status);

          if (msg?.element?.status === "inProgress") {
            let text = msg?.element?.content?.trim();
            let editorChildren = outputValue;
            // check if the activeDoc has changed
            if (!isTopicModeOn) {
              const itemIndex = editorChildren?.length - 1;
              outputValue[itemIndex] = {
                type: "paragraph",
                children: [{ text: text }],
                isAnswer: true,
              };
            } else {
              if (
                msg?.element?.metadata?.activeDoc &&
                lastActiveDoc != msg?.element?.metadata?.activeDoc &&
                (streamedDocs?.length === 0 ||
                  !streamedDocs.find(
                    (item) => item.docId == msg?.element?.metadata?.activeDoc
                  ) ||
                  msg?.element?.metadata?.activeDoc === "conclusion")
              ) {
                lastItem = editorChildren?.length;
                streamedDocs.push({
                  docId: msg?.element?.metadata?.activeDoc,
                  index: lastItem,
                });
                lastItem += 1;
                // docId has changed, append a new paragraph
                outputValue.push({
                  type: "paragraph",
                  children: [{ text: text }],
                  isAnswer: true,
                });

                lastActiveDoc = msg?.element?.metadata?.activeDoc;
              } else {
                const itemIndex = streamedDocs.find(
                  (item) => item.docId === msg?.element?.metadata?.activeDoc
                )?.index;
                // docId has not changed, update the last paragraph
                outputValue[itemIndex] = {
                  type: "paragraph",
                  children: [{ text: text }],
                  isAnswer: true,
                };
              }
            }
            setOutputValue([...editorChildren]);
          }
          if (msg?.element?.status === "fulfilled") {
            lastItem = null;
            lastActiveDoc = null;
            streamedDocs = [];
            streamSubscription?.unsubscribe();
            setReadyForNextQuestion(false);
          }
        },
      });
      setStreamObject(streamSubscription);
    }
    return () => {
      streamSubscription?.unsubscribe();
      clearTimeout(timerId);
    };
  }, [streamid, isTopicModeOn]);
  useEffect(() => {
    if (editor && streamid) {
      lastItem = editor?.children?.length;
      for (let i = 0; i <= textToDisplay.length; i++) {
        const _newEditor = [
          {
            type: "paragraph",
            children: [{ text: textToDisplay.slice(0, i) }],
            isAnswer: true,
          },
        ];
        timerId = setTimeout(() => {
          setOutputValue(_newEditor);
        }, i * delay);
      }
    }
  }, [streamid]);
  const handleReadinessForNextQuestion = () => {
    forceEditorWithValue(initialEditorValueForQuestions);
    setQuestionsCharactersCount(0);
    setQuestionsWordsCount(0);
  };
  const handleParse = async () => {
    try {
      let bufferData = [];
      setOutputValue([]);
      const passage = AppQuantumEditorUtils.Helpers.getPlainText(value);

      const isPassageEmpty = !isTopicModeOn && passage?.split(" ")?.length < 3;

      if (isPassageEmpty && !isTopicModeOn && !isAutoQuestionOn)
        return AppToasty.show(
          t("NLPTransformationCannotPerformPassageFailedCaption"),

          {
            type: "danger",
          }
        );
      if (selectedNers?.length > 3)
        return AppToasty.show(
          t("NLPTransformationCannotPerformNerFailedCaption"),
          {
            type: "danger",
          }
        );

      let questions = [];
      let EditorQuestions;
      if (isAutoQuestionOn) {
        const rawPayload = {
          passage: !isTopicModeOn ? passage : "",
          questions,
          token: getActiveWorkspace().workspaceToken,
          topics: selectedTopics?.map((topic) => topic.id),
          isTopicModeOn,
          workspaceid: getActiveWorkspace().id,
          sourceNERs: selectedNers?.map((ner) => ner.name),
          forceQuestions: !selectedNers?.length > 0 && true,
          limit: 4,
          includeReadComprehensionData: getActiveWorkspace()?.trainingConfig
            ?.includeReadComprehensionDataInTraining
            ? "true"
            : "false",
        };
        setRequestPayload(Questioner.generateRequestPayload(rawPayload));
        const result = await getQuestionsRequest(rawPayload);
        if (result?.errorCode) {
          throw new Error(result.errorMessage + " - " + result.errorDetail);
        }
        if (result && result["q&a"] && result["q&a"]?.length > 0) {
          questions = result["q&a"];
          EditorQuestions = questions.map((q) => {
            const questions = [
              {
                children: [
                  {
                    text: q.question,
                  },
                ],
                type: "paragraph",
                // ANSWER: {
                //   answer: q.answer,
                //   id: q.id,
                //   documentId: q.documentid,
                //   type: q.type,
                // },
                isParsed: q.answer != null,
              },
            ];
            if (getActiveWorkspace()?.parsingConfig?.answerAsNER) {
              const bufferEditorData = [
                {
                  text: `${q.answer} `,
                },
              ];
              if (isTopicModeOn) {
                bufferEditorData.push({
                  type: "link",
                  url: `${
                    envConfig?.REACT_APP_ENV === "dev"
                      ? `https://app.toothfairy.link`
                      : `https://app.toothfairyai.com`
                  }/workspaces/${
                    getActiveWorkspace()?.id
                  }/mrc_contents/${q.documentid.toString()}`,
                  children: [
                    {
                      text: "Link to document",
                    },
                  ],
                });
              }
              questions.push({
                type: "paragraph",
                children: [...bufferEditorData],
                isAnswer: true,
              });
            }
            return questions;
          });
          EditorQuestions = [].concat.apply([], EditorQuestions);
          if (getActiveWorkspace()?.parsingConfig?.answerAsNER) {
            forceEditorWithValue(EditorQuestions);
            return AppToasty.show(
              questions && questions.length > 0
                ? t("NLPTransformationCaption")
                : t("NLPTransformationCannotFindQuestionsFailedCaption"),

              {
                type: questions && questions.length > 0 ? "success" : "danger",
              }
            );
          }

          // flatten the array
        } else {
          setNLPData([]);
          forceEditorWithValue(initialEditorValueForQuestions);

          return AppToasty.show(
            t("NLPTransformationCannotFindQuestionsFailedCaption"),

            {
              type: "danger",
            }
          );
        }
      } else {
        for (let i = 0; i < qValue.length; i++) {
          const block = qValue[i];
          if (block.type === "title") continue;
          if (block.isAnswer === true) continue;
          const nodeText =
            AppQuantumEditorUtils.Helpers.generateStringFromElement(block);
          if (nodeText?.trim() === "" && nodeText.split(" ").length < 3)
            continue;
          questions.push({
            id: i,
            question: removeSubstring(nodeText, "Question:"),
            answerLanguage: forcedLanguage,
          });
        }
        EditorQuestions = [
          ...qValue.filter((q) => q.type !== "title" && q.isAnswer !== true),
        ];
      }
      if (questions.length === 0 && !isAutoQuestionOn)
        return AppToasty.show(
          t("NLPTransformationCannotPerformPQuestionsFailedCaption"),

          {
            type: "danger",
          }
        );
      if (questions.length > 3 && !isAutoQuestionOn)
        return AppToasty.show(
          t("NLPTransformationCannotPerformToomManyQuestionsFailedCaption"),
          {
            type: "danger",
          }
        );
      const aggregatedQuestion = questions.map((q) => q.question).join(" ");
      questions = [
        {
          id: qValue.length - 1,
          question: aggregatedQuestion,
          answerLanguage: forcedLanguage,
        },
      ];

      let bufferQValue = [...EditorQuestions];
      let QAeditor = [];

      if (!isAutoQuestionOn) {
        let streamid = null;
        setStreamStatus("pending");
        setStreamObject(null);
        const stream = await streamHelper.createOutputStream({
          content: "",
          workspaceID: getActiveWorkspace().id,
          type: "answerer",
          status: "pending",
        });
        if (stream && stream?.id) {
          setStreamid(stream?.id);
          streamid = stream?.id;
        } else {
          throw new Error(
            "Content could not be generated. Please try again later."
          );
        }
        const rawPayload = {
          passage,
          questions,
          token: getActiveWorkspace().workspaceToken,
          topics: selectedTopics?.map((topic) => topic.id),
          isTopicModeOn,
          agentid: agent,
          workspaceid: getActiveWorkspace().id,
          includeReadComprehensionData: getActiveWorkspace()?.trainingConfig
            ?.includeReadComprehensionDataInTraining
            ? "true"
            : "false",
          forcedLanguage,
          uniqueAnswer: getActiveWorkspace()?.parsingConfig?.uniqueAnswer
            ? "true"
            : "false",
          answerByParagraph: getActiveWorkspace()?.parsingConfig
            ?.answerByParagraph
            ? "true"
            : "false",
          stream: "true",
          streamid: streamid,
        };
        setRequestPayload(Answerer.generateRequestPayload(rawPayload));
        let data = await getAnswersRequest(rawPayload);
        return;
      }

      forceEditorWithValue(QAeditor);
      setLastNLPDataTimeStamp(new Date().getTime());
      AppToasty.show(
        answerError
          ? t("NLPTransformationFailedCaption")
          : t("NLPTransformationCaption"),
        {
          type: answerError ? "danger" : "success",
        }
      );
    } catch (error) {
      console.error("error while answering", error);
      AppToasty.show(t(error), {
        type: "danger",
      });
    }
  };
  useEffect(() => {
    if (documentId && getActiveWorkspace()?.id)
      getAPIRequest(
        documentId,
        ["topics", "workspaceid"],
        getActiveWorkspace().id
      );
  }, [documentId, getActiveWorkspace()?.id]);
  useEffect(() => {
    if (!isParser) setIsAutoQuestionModeOn(false);
  }, [isParser]);
  useEffect(() => {
    if (isAutoQuestionOn) forceEditorWithValue(initialEditorValueForQuestions);
  }, [isAutoQuestionOn]);
  useEffect(() => {
    if (getAPIResult?.data?.length && getActiveWorkspace()?.id) {
      const sTopics = getAPIResult?.data[0]?.topics?.map((u) => {
        return {
          name: topics.filter((z) => z.id === u)[0]?.label,
          category: topics.filter((z) => z.id === u)[0]?.type,
          id: u,
        };
      });

      setSelectedTopics(sTopics);
      saveDocument({ documentId, value, topics: sTopics?.map((u) => u.id) });
    }
  }, [getAPIResult?.data?.length, JSON.stringify(topics)]);

  const handleEditorError = (event) => {
    if (event.message)
      if (!displayedToasties()) {
        const id = AppToasty.show(t(event.message), {
          type: "danger",
        });
        displayedToasties(id);
      }
  };
  const handleEntityCreation = useCallback(
    (event) => {
      if (event === "success")
        AppToasty.show(t("topicCreationSuccessCaption"), {
          type: "success",
        });
      else
        AppToasty.show(t("topicCreationFailedCaption"), {
          type: "danger",
        });
    },
    [AppToasty, t]
  );
  const handleAddDictionary = useCallback(
    (text) => {
      setShowDictonaryModal(true);
      setInitialMapValues({
        ...initialMapValues,
        sourceText: text,
      });
    },
    [setShowDictonaryModal, initialMapValues]
  );
  const handleMappingCreation = useCallback(
    (event) => {
      setShowDictonaryModal(false);
      if (event === "success")
        AppToasty.show(t("mappingCreationSuccessCaption"), {
          type: "success",
        });
      else
        AppToasty.show(t("mappingCreationFailedCaption"), {
          type: "danger",
        });
    },
    [AppToasty, t, setShowDictonaryModal]
  );
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AnswererScreen
        requestPayload={requestPayload}
        passageForcedLanguage={forcedLanguage}
        setPassageForcedLanguage={setForcedLanguage}
        showDictionaryModal={showDictionaryModal}
        setShowDictonaryModal={setShowDictonaryModal}
        handleAddDictionary={handleAddDictionary}
        initialMapValues={initialMapValues}
        isInactive={isInactive}
        isTopicTrainingScope={isTopicTrainingScope}
        setisTopicTrainingScopeAsTopic={setisTopicTrainingScopeAsTopic}
        questionInProgress={questionInProgress}
        answerInProgress={
          (answerInProgress ||
            streamStatus === "inProgress" ||
            streamStatus === "pending") &&
          streamStatus !== "fulfilled"
        }
        outputContainsAnswer={qValue.some((block) => block.isAnswer === true)}
        createAPIInProgress={createAPIInProgress}
        setIsTopicModeOn={setIsTopicModeOn}
        isTopicModeOn={isTopicModeOn}
        onSelectedTopicsChange={onSelectedTopicsChange}
        selectedTopics={selectedTopics}
        topics={generateAdaptedTopics(topics)}
        parseInProgress={updateAPIInProgress}
        storeInProgress={updateAPIInProgress}
        isSaved={isSaved}
        storeInError={updateAPIError}
        onSocketConnectionChange={handleSocketConnectionChange}
        answerResult={answerResult}
        questionResult={questionResult}
        user={user}
        handleParse={handleParse}
        setEditor={setEditor}
        setEditorQA={setEditorQA}
        value={value}
        setValue={setValue}
        qValue={qValue}
        setPrompt={setPrompt}
        setQValue={setQValue}
        isOutputUpToDate={
          lastChange < lastNlPDataTimeStamp + 1000 &&
          lastQuestionsDataTimeStamp < lastNlPDataTimeStamp + 1000
        }
        nlpData={nlpData}
        nlpConfig={nlpConfig}
        setNlpConfig={setNlpConfig}
        showConfirmationModalForTraining={showConfirmationModalForTraining}
        setShowConfirmationModalForTraining={
          setShowConfirmationModalForTraining
        }
        documentId={documentId}
        isEditorLoaded={isEditorLoaded}
        isParser={isParser}
        setIsParser={setIsParser}
        handleNewDocument={createDocument}
        routeToParsing={goToParser}
        setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
        isTrainerNoDocumentId={isTrainerNoDocumentId}
        isDirty={isDirty}
        handleFileSelect={handleFileSelect}
        isUploading={isUploading}
        handleEditorError={handleEditorError}
        isAutoQuestionOn={isAutoQuestionOn}
        setIsAutoQuestionModeOn={setIsAutoQuestionModeOn}
        ners={ners}
        selectedNers={selectedNers}
        onSelectedNersChange={onSelectedNersChange}
        rawText={rawText}
        charactersCount={charactersCount}
        wordsCount={wordsCount}
        questionsWordsCount={questionsWordsCount}
        questionsCharactersCount={questionsCharactersCount}
        handleEntityCreation={handleEntityCreation}
        onMappingCreated={handleMappingCreation}
        workspaceid={getActiveWorkspace()?.id}
        trainingData={trainingData}
        setIsValidationData={setIsValidationData}
        isValidationData={isValidationData}
        handleCreationOfDocumentFromQaOutput={handleCreationOfDocument}
        setReadyForNextQuestion={handleReadinessForNextQuestion}
        readyForNextQuestion={!isQuestionsEditorDirty}
        isAnswersEditorDirty={isAnswersEditorDirty}
        handleCancellation={handleCancellation}
        agent={agent}
        setAgent={setAgent}
        outputValue={outputValue}
        handleOutputCopy={handleOutputCopy}
        S3downloadUrlRequest={S3downloadUrlRequest}
        markdownString={markdownString}
        forceEditorWithValue={forceEditorWithValue}
      />
    </Suspense>
  );
};

export default AnswererScreenContainer;
