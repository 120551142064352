import { DataStore } from "@aws-amplify/datastore";
import { TrainingLog } from "../../models";

const createTrainingLog = async (object) => {
  return DataStore.save(new TrainingLog(object));
};

const updateTrainingLog = async (id, args) => {
  const original = await DataStore.query(TrainingLog, id);
  return DataStore.save(
    TrainingLog.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteTrainingLog = async (id) => {
  return DataStore.delete(TrainingLog, (ent) => ent.id("eq", id));
};

export default {
  createTrainingLog,
  updateTrainingLog,
  deleteTrainingLog,
};
