import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import React from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";

const GeneratorConfigToolbar = ({
  searchBtnLabel = "Search",
  createBtnLabel = "Create",
  searchBtnAccLabel = "searchButton",
  createBtnAccLabel = "createButton",
  handleSearch = () => {},
  handleCreation = () => {},
}) => {
  const [typeText, setTypeText] = React.useState("");
  const [labelText, setLabelText] = React.useState("");
  const [valueText, setValueText] = React.useState("");
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: -10,
      }}
    >
      {/* <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={typeText}
          onChangeText={setTypeText}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Type"
        />
      </View> */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={labelText}
          onChangeText={setLabelText}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Name"
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={valueText}
          onChangeText={setValueText}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Prompt"
        />
      </View>
      <ToothFairyButton
        wrapperStyle={{ marginTop: 10 }}
        title={searchBtnLabel}
        accessibilityLabel={searchBtnAccLabel}
        onPress={() => handleSearch(typeText, labelText, valueText)}
      />
      <ToothFairyButton
        wrapperStyle={{ marginTop: 10 }}
        title={createBtnLabel}
        accessibilityLabel={createBtnAccLabel}
        onPress={() => handleCreation()}
      />
    </View>
  );
};

export default GeneratorConfigToolbar;
