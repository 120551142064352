import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import React from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../../Components/ToothFairyComponents/ToothFairyButton";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppButtonMenu from "@toothfairy/shared-ui/AppButtonMenu";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const QaConfigToolbar = ({
  searchBtnLabel = "Search",
  createBtnLabel = "Create",
  searchBtnAccLabel = "searchButton",
  createBtnAccLabel = "createButton",
  options = [],
  handleSearch = () => {},
  handleCreation = () => {},
}) => {
  const [labelText, setLabelText] = React.useState("");
  const [valueText, setValueText] = React.useState("");
  const [modelText, setModelText] = React.useState("");
  const [forceOpening, setForceOpening] = React.useState(false);
  const { currentTheme } = AppTheme?.useTheme();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: -10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={labelText}
          onChangeText={setLabelText}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Name"
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={valueText}
          onChangeText={setValueText}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Agent role"
        />
      </View>

      <ToothFairyButton
        wrapperStyle={{ marginTop: 10 }}
        title={searchBtnLabel}
        accessibilityLabel={searchBtnAccLabel}
        onPress={() => handleSearch(modelText, labelText, valueText)}
      />
      <View
        style={{
          maxWidth: 175,
          marginTop: 10,
        }}
      >
        <AppButtonMenu
          mainActionLabel={"New Agent"}
          onClickMainAction={() => {
            setForceOpening(Date.now().toString());
          }}
          forceOpening={forceOpening}
          wrapperStyle={{
            backgroundColor: currentTheme?.theme?.primary,
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderRadius: 20,
          }}
          labelStyle={{
            fontWeight: "bold",
          }}
          style={{
            top: 35,
            fontFamily: "boldFont",
            minWidth: 130,
            maxWidth: 175,
            textAlign: "center",
          }}
          menuItemStyle={{
            marginLeft: 10,
            fontSize: currentTheme?.theme?.small_font_size,
          }}
          color={currentTheme?.theme?.black}
          rightIconColor={currentTheme?.theme?.black}
          onClickMenuItem={(id) => {
            handleCreation(
              id,
              options.find((option) => option?.id === id)?.label
            );
          }}
          options={options}
        />
      </View>
    </View>
  );
};

export default QaConfigToolbar;
