import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { generateRowId } from "../Utility";
import trainingAPI from "../API/Training/trainingAPI";
import useAPI from "@toothfairy/shared-api/useApi";
import envConfig from "../../envConfig";
import useWorkspaces from "./useWorkspaces";
import Media from "../API/Media";

const useRecording = ({
  onForcedStop = () => {},
  maxTimeForRecording = 600,
}) => {
  const { getActiveWorkspace } = useWorkspaces();
  const [recordedUrl, setRecordedUrl] = useState("");
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const [transcript, setTranscript] = useState("");
  const chunks = useRef([]);
  const [isRecording, setIsRecording] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [recordingUploadInProgress, setRecordingUploadInProgress] =
    useState(false);
  const [recordingUploadProgress, setRecordingUploadProgress] = useState(0);
  const {
    data: S3uploadUrlData,
    loading: urlUploadGenerationInProgress,
    apiRequest: S3uploadUrlRequest,
    response: S3uploadResponse,
  } = useAPI(trainingAPI.bulkImportUrlGeneration, envConfig);
  const {
    loading: audioProcessingInProgress,
    apiRequest: audioProcessingRequest,
    wasInvoked: audioProcessingInvoked,
    data: audioProcessingResult,
  } = useAPI(Media.processAudio, null, () => {});
  const onRecordingUploaded = async (url) => {
    const result = await audioProcessingRequest({
      token: getActiveWorkspace()?.workspaceToken,
      data: {
        audio_file: url,
        workspaceid: getActiveWorkspace()?.id,
      },
    });

    setTranscript(`${result?.contents?.text}`);
  };
  const handleRecordingCompleted = async (blob) => {
    const _file = new File([blob], "audio.wav", {
      type: "audio/wav",
    });
    const audioId = generateRowId();
    const result = await S3uploadUrlRequest({
      filename: `${getActiveWorkspace()?.id}/${audioId}.wav`,
      importType: "imported_audio_files",
      contentType: "wav",
    });
    const upload = await axios.request({
      method: "put",
      url: JSON.parse(result?.body)?.uploadURL,
      data: _file,
      headers: {
        "Content-Type": _file.type,
      },
      onUploadProgress: (p) => {
        // if (controller.signal.aborted) return;
        setRecordingUploadInProgress(true);
        setRecordingUploadProgress(Math.round((p.loaded * 100) / p.total));
        if (p.loaded === p.total) {
          setRecordingUploadInProgress(false);
          setRecordingUploadProgress(100);
        }
      },
    });
    if (JSON.parse(result?.body).uploadURL) {
      onRecordingUploaded(
        `imported_audio_files/${getActiveWorkspace()?.id}/${audioId}.wav`
      );
    }
  };
  //   track the time elapsed every second
  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimeElapsed((prev) => prev + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setTimeElapsed(0);
    }
    return () => {
      clearInterval(interval);
      setTimeElapsed(0);
    };
  }, [isRecording]);
  useEffect(() => {
    if (timeElapsed > maxTimeForRecording) {
      stopRecording();
      onForcedStop();
    }
  }, [timeElapsed]);
  const startRecording = async () => {
    try {
      setIsRecording(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/wav" });
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        setRecordedBlob(recordedBlob);
        chunks.current = [];
        setIsRecording(false);
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };
  useEffect(() => {
    return () => {
      if (mediaStream.current) {
        mediaStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (recordedUrl != "" && recordedBlob != null) {
      handleRecordingCompleted(recordedBlob, recordedUrl);
      setRecordedBlob(null);
      setRecordedUrl("");
    }
  }, [recordedUrl]);

  return {
    startRecording,
    stopRecording,
    isRecording,
    recordedUrl,
    timeElapsed,
    transcript,
  };
};

export default useRecording;
