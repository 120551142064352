import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import { useWindowDimensions, View } from "react-native";
import React from "react";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import useSections from "../../../Hooks/useSections";
import useWorkspaces from "../../../Hooks/useWorkspaces";

const EntitiesList = ({
  entities,
  buttonTitle,
  createEntitityUID,
  editEntityUID,
  showEmoji,
  isCreateEnabled = true,
  settingType,
}) => {
  const { height } = useWindowDimensions();
  const { setDisplayedView, setDisplayedOption } = useSections();
  const { getPlanConfig } = useWorkspaces();

  return (
    <View>
      <View
        style={{
          maxHeight: height - 200,
          overflow: "auto",
        }}
      >
        {entities?.length > 0 ? (
          entities?.map((opt) => (
            <AppMenu.AppDefaultOption
              isNavigation
              onPress={() => {
                setDisplayedView(editEntityUID);
                setDisplayedOption(opt);
              }}
              wrapperStyle={{
                padding: 10,
              }}
              rightIcon={AppAssets.icons.navigationTo}
              item={showEmoji && { emoji: showEmoji && opt?.emoji }}
              leftIconStyle={{
                backgroundColor: !showEmoji && opt?.backgroundColor,
                borderRadius: !showEmoji && 20,
                width: showEmoji && 10,
              }}
              key={opt?.id}
              icon={AppAssets.icons.simpleCircle}
              leftIconColor={opt?.backgroundColor}
              preventTranslation
              isTextLeftAligned
            >
              {opt.label}
            </AppMenu.AppDefaultOption>
          ))
        ) : (
          <AppPlaceholderView
            accessibilityLabel="json-placeholder"
            wrapperStyle={{
              width: 400,
              height: 200,
              marginTop: 40,
              margin: "auto",
            }}
            size={60}
            icon={AppAssets.icons.orbit}
            mainCaption="EmptyPlaceholder"
          />
        )}
      </View>
      <ToothFairyButton
        title={buttonTitle}
        onPress={() => {
          setDisplayedView(createEntitityUID);
          setDisplayedOption(null);
        }}
        isDisabled={!isCreateEnabled}
        wrapperStyle={{
          alignSelf: "center",
          marginTop: 10,
          padding: 0,
        }}

        // backgroundColor={currentTheme?.theme?.green}
      />
    </View>
  );
};

export default EntitiesList;
