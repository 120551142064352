import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import { showSideMenu } from "../../../../../App";
import sitesHelper from "../../../../API/Sites/sitesHelper";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import SitesSettings from "./SitesSettings";
import SitesSettingsToolbar from "./SitesSettingsToolbar";
import envConfig from "../../../../../envConfig";

const SitesList = ({ isFunctionsLoading }) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const { width } = useWindowDimensions();
  const { getPlanConfig, getWorkspaceEntities } = useWorkspaces();
  const [displayTokenSection, setDisplayTokenSection] = React.useState(false);
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [showSiteModal, setShowSiteModal] = React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedSites, setDisplayedSites] = React.useState([]);
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { height } = useWindowDimensions();
  const [initialMapValues, setInitialMapValues] = useState({
    name: "",
    description: "",
    status: "",
    validationToken: "",
    url: "",
    freeRoaming: false,
    useProxy: false,
  });
  const _topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic["label"];
    topic.category = topic["type"];
    topic.value = topic["id"];
    return { ...topic };
  });
  const columns = [
    {
      title: "ID",
      width: 0.2,
    },
    {
      title: "Domain",
      width: 0.5,
    },
    {
      title: "Synced pages",
      width: 0.1,
    },
    {
      title: "Status",
      width: 0.1,
    },
    {
      title: "Actions",
      isAction: true,
      width: 0.1,
    },
  ];
  const onSiteCreated = useCallback(
    (event) => {
      if (event === "success") {
        AppToasty.show(t("siteCreationSuccessCaption"), {
          type: "success",
        });
        setShowSiteModal(false);
      } else
        AppToasty.show(t("siteCreationFailedCaption"), {
          type: "danger",
        });
      formRef?.current?.resetForm();
      setShowSiteModal(false);
      setSelectedIndex(null);
    },
    [AppToasty, t, setShowSiteModal.formRef?.current, state?.activeWorkspaceID]
  );

  useEffect(() => {
    setDisplayedSites([...state?.sites]);
  }, [JSON.stringify([...state?.sites])]);
  const requestValidation = async (id, status) => {
    let newSites = [...state.sites];
    try {
      let result;
      // TRIGGER VALIDATION OF SITE
      result = await sitesHelper.updateSite(id, [
        { property: "status", value: "pending" },
      ]);
      const _updatedObject = identifyOptByID(result?.id);
      const updateIndex = newSites.findIndex(
        (u) => u?.id === _updatedObject?.id
      );
      newSites[updateIndex] = result;
      dispatch("SET_SITES", [...newSites]);
      if (status != "active")
        AppToasty.show(`Site validation requested successfully`, {
          type: "success",
        });
      else {
        AppToasty.show(`Site sync requested successfully`, {
          type: "success",
        });
      }
    } catch (error) {
      console.error(error);
      AppToasty.show(`Site validation request failed`, {
        type: "danger",
      });
    } finally {
      setShowSiteModal(false);
    }
  };
  const siteItemCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          maxHeight: isLargeScreen() ? "95%" : 500,
          height: "auto",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        key={(Math.floor(Date.now() / 5000) * 5000).toString()}
        isModalOpen={showSiteModal}
        setIsModalOpen={setShowSiteModal}
        modalTitle={isEditMode ? "siteEditTitle" : "siteCreationTitle"}
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowSiteModal(false);
          setSelectedIndex(null);
          setDisplayTokenSection(false);
        }}
      >
        <SitesSettings
          ref={formRef}
          initialMapValues={initialMapValues}
          onSiteCreated={onSiteCreated}
          isEdit={isEditMode}
          selectedId={displayedSites[selectedIndex]?.id}
          setShowSiteModal={setShowSiteModal}
          requestValidation={requestValidation}
          displayTokenSection={displayTokenSection}
          setDisplayTokenSection={setDisplayTokenSection}
          setInitialMapValues={setInitialMapValues}
        />
      </ToothFairyModal>
    );
  }, [
    showSiteModal,
    setShowSiteModal,
    JSON.stringify(initialMapValues),
    setInitialMapValues,
    isEditMode,
    selectedIndex,
    displayedSites,
    displayTokenSection,
    setDisplayTokenSection,
    displayedSites[selectedIndex]?.id,
  ]);
  const identifyOptByID = (id) => {
    return state?.sites?.find((u) => u.id === id);
  };
  const handleSiteItemSelection = ({ id, type, item, index }) => {
    setSelectedIndex(index);
    setIsEditMode(true);
    let extendedItem = identifyOptByID(id);
    setInitialMapValues({
      ...extendedItem,
      url: extendedItem?.url,
      topics: extendedItem?.topics?.map((z) => {
        const _topic = _topics.find((t) => t.id === z);
        return {
          ..._topic,
          value: _topic?.id,
        };
      }),
    });

    setShowSiteModal(true);
  };
  const handleSiteItemDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        const _id = displayedSites[selectedIndex]?.id;
        const result = await sitesHelper.deleteSite(_id);
        dispatch("SET_SITES", [...state?.sites.filter((u) => u.id !== _id)]);
        AppToasty.show(t("siteDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      console.log(error);
      AppToasty.show(t("siteDeletionErrorCaption"), {
        type: "danger",
      });
    }
  };
  const handleSearch = async (url) => {
    // find items in the list which cointain either the sourceText or targetText
    const filteredItems = state?.sites.filter((item) => {
      return item.url.toLowerCase().includes(url.toLowerCase());
    });
    setDisplayedSites(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleCreation = () => {
    setInitialMapValues({
      url: "",
      status: "",
      name: "",
      description: "",
      validationToken: "",
    });
    setIsEditMode(false);
    setShowSiteModal(true);
  };
  const isItemActionable = (item) => {
    const _item = item?.item;
    if (
      _item?.status !== "pending" &&
      _item?.status !== "inProgress" &&
      _item?.status !== "syncing" &&
      _item?.status !== "inactive"
    )
      return true;
    else return false;
  };
  const wasTheLastScrapeDoneInTheLastxHours = (item, _hours_ = 24) => {
    const _item = state.sites?.find((u) => u.id === item?.id);
    let now = new Date();
    let now_string = _item?.lastScraped || "2021-07-01T00:00:00.000Z";
    let pastDate = new Date(now_string);
    let diff = now - pastDate;
    let hours = diff / (1000 * 60 * 60);
    if (hours >= _hours_) {
      return true;
    } else {
      return false;
    }
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"siteItemCancelConfirmation"}
        caption="siteItemCancelCaption"
        subCaption="siteItemCancelSubCaption"
        confirmCaption={"siteItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={() => handleSiteItemDeletion()}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [setShowChoice, showChoice, selectedItem, setSelectedItem]);

  return (
    <View
      style={{
        minWidth: "100%",
        paddingLeft: 10,
        flex: 1,
      }}
    >
      {getPlanConfig()?.isSitesBindingEnabled ? (
        <>
          <SitesSettingsToolbar
            handleSearch={handleSearch}
            handleCreation={handleCreation}
          />

          <>
            {displayedSites.length > 0 ? (
              <AppTable
                actionButtons
                mapIconWithStatus={(item) => {
                  if (item?.status == "active" || item?.status == "syncing")
                    return AppAssets.icons.reload;
                  else return AppAssets.icons.activeModel;
                }}
                mainActionLabel="View"
                showDropDownMenuForActions
                onClickMainAction={(item, index) => {
                  handleSiteItemSelection({
                    id: item?.id,
                    type: "site",
                    item: item,
                    index: index,
                  });
                }}
                actionButtonStyle={{
                  minWidth: 50,
                }}
                onActionItemPress={(id, item, index) => {
                  if (id === "EDIT") {
                    handleSiteItemSelection({
                      id: item?.id,
                      type: "site",
                      item: item,
                      index: index,
                    });
                  } else if (id === "DELETE") {
                    if (
                      item?.status === "pending" ||
                      item?.status === "inProgress" ||
                      item?.status === "syncing"
                    ) {
                      AppToasty.show(
                        `You can't delete a site while the status is ${item?.status}`,
                        {
                          type: "danger",
                        }
                      );
                      return;
                    } else {
                      setSelectedItem(item);
                      setSelectedIndex(index);
                      setShowChoice(true);
                    }
                  } else if (id === "SYNC") {
                    if (isItemActionable(item)) {
                      if (
                        item?.status == "active" &&
                        !wasTheLastScrapeDoneInTheLastxHours(
                          item,
                          envConfig.REACT_APP_ENV == "dev" ? 0 : 24
                        )
                      )
                        AppToasty.show(
                          "You can only request the sync for sites that were synced more than 24 hours ago",
                          {
                            type: "danger",
                          }
                        );
                      else {
                        requestValidation(item?.id, item?.status);
                      }
                    }
                  }
                }}
                actions={[
                  {
                    label: "Edit",
                    id: "EDIT",
                    icon: AppAssets.icons.edit,
                  },
                  {
                    label: "Sync",
                    id: "SYNC",
                    icon: AppAssets.icons.reload,
                  },
                  {
                    label: "Delete",
                    id: "DELETE",
                    icon: AppAssets.icons.delete,
                  },
                ]}
                isItemActionable={(item) =>
                  isItemActionable({
                    item: item,
                  })
                }
                style={{
                  zIndex: -1,
                  height: height - 180,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                headers={columns}
                items={displayedSites
                  ?.slice(
                    (currentPage - 1) * pageSize,
                    (currentPage - 1) * pageSize + pageSize
                  )
                  ?.map((u) => {
                    return {
                      id: u?.id || " ",
                      url: u?.url || " ",

                      pagesCount: u?.siteTree?.length?.toString() || "0",
                      status: u?.status || " ",
                      actions: " ",
                    };
                  })}
              />
            ) : (
              <AppPlaceholderView
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  marginVertical: 20,
                  marginHorizontal: "auto",
                  flex: 1,
                }}
                size={80}
                icon={AppAssets.icons.orbit}
                mainCaption="EmptyPlaceholder"
                subCaption="siteSubCaption"
              />
            )}
            {displayedSites?.length > pageSize && (
              <AppPagination
                onPageChange={handlePageChange}
                totalCount={displayedSites?.length}
                siblingCount={1}
                currentPage={currentPage}
                pageSize={pageSize}
                wrapperStyle={{
                  marginTop: 10,
                  alignSelf: "flex-end",
                }}
              />
            )}
          </>
        </>
      ) : (
        <AppPlaceholderView
          wrapperStyle={{
            width: "100%",
            height: "100%",
            marginVertical: 20,
            marginHorizontal: "auto",
            flex: 1,
          }}
          size={80}
          icon={AppAssets.icons.orbit}
          mainCaption="EmptyPlaceholder"
          subCaption="SettingsSubCaption"
        />
      )}
      {siteItemCreationComponent}
      {_coreChoice}
    </View>
  );
};

export default SitesList;
