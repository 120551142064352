import React from "react";
import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
const ToothFairyClickableOption = ({
  children,
  onPress,
  leftIcon,
  clickableViewWrapperStyle,
  isClickable = true,
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <AppText
      clickableViewWrapperStyle={clickableViewWrapperStyle}
      isHoverable
      isClickable={isClickable}
      onPress={onPress}
      leftIcon={leftIcon}
      leftIconColor={
        isClickable
          ? currentTheme?.theme?.primary
          : currentTheme?.theme?.mediumGrey
      }
      style={{
        marginLeft: 10,
      }}
      fontWeight="bold"
      color={
        isClickable
          ? currentTheme?.theme?.primary
          : currentTheme?.theme?.mediumGrey
      }
      {...props}
    >
      {children}
    </AppText>
  );
};

export default ToothFairyClickableOption;
