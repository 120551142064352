import React from "react";
import useWorkspaces from "../Hooks/useWorkspaces";
import { Redirect, Route } from "./index";
import { routes } from "./routes";

const SentimentReviewerRoute = (props) => {
  const { getPlanConfig, getActiveWorkspace } = useWorkspaces();

  return getPlanConfig() && <Route {...props} />;
};

export default SentimentReviewerRoute;
