import { DataStore } from "@aws-amplify/datastore";
import { AppLog } from "../../models";

const createAppLog = async (object) => {
  return DataStore.save(new AppLog(object));
};

const updateAppLog = async (id, args) => {
  const original = await DataStore.query(AppLog, id);
  return DataStore.save(
    AppLog.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteAppLog = async (id) => {
  return DataStore.delete(AppLog, (ent) => ent.id("eq", id));
};

export default {
  createAppLog,
  updateAppLog,
  deleteAppLog,
};
