import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../Constants";
import btnStyleGenerator from "../../../Constants/btnStyleGenerator";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { isLargeScreen, isVeryLargeScreen } from "../../MainViewContainer";

const GeneratorActionButtons = ({
  handleParse,
  isParser,
  passageForcedLanguage,
  setPassageForcedLanguage,
  setIsModalOpen,
  isMainParsingFnDisabled,
  isPassageNil,
  data,
  isTopicModeOn,
  handleCreationOfDocumentFromGnOutput,
  eValue,
  setOptionsModalOpen,
  setIsSaveOutputModalOpen,
  parseInProgress,
  handleCancellation,
  value,
  handleGenerationOfCustomPrompt = () => {},
  agentId,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const commonBtnStyle = useMemo(
    () => btnStyleGenerator.commonBtnStyleGenerator(currentTheme),
    [currentTheme]
  );
  const { state } = AppStateManager.useAppStateManager();
  const { isUserContentAdmin } = useWorkspaces();
  const _coreActionsButtons = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: isVeryLargeScreen() ? "space-between" : "flex-start",
          flex: 1,
          alignItems: "center",
          flexWrap: "wrap",
          marginLeft: -10,
        }}
      >
        {isParser && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ToothFairyButton
              {...commonBtnStyle}
              isDisabled={isMainParsingFnDisabled || isPassageNil}
              onPress={handleParse}
              title="Create"
            />

            {isLargeScreen() && Constants.isShowRequestEnabled && (
              <ToothFairyButton
                {...commonBtnStyle}
                isDisabled={
                  isMainParsingFnDisabled
                  // || !data || data?.length === 0
                }
                onPress={() => {
                  setIsModalOpen(true);
                }}
                title="Show Request"
              />
            )}
            {isVeryLargeScreen() &&
              !parseInProgress &&
              isUserContentAdmin() && (
                <ToothFairyButton
                  title={"Save output"}
                  onPress={() => {
                    setIsSaveOutputModalOpen(true);
                  }}
                  isDisabled={
                    (eValue[0]?.children?.length === 1 &&
                      eValue[0]?.children[0]?.text?.trim() === "") ||
                    isMainParsingFnDisabled
                  }
                  {...commonBtnStyle}
                />
              )}
            {isVeryLargeScreen() &&
              !parseInProgress &&
              isUserContentAdmin() && (
                <ToothFairyButton
                  title={"Save prompt"}
                  onPress={() => {
                    handleGenerationOfCustomPrompt(value, [agentId]);
                  }}
                  isDisabled={
                    (value[0]?.children?.length === 1 &&
                      value[0]?.children[0]?.text?.trim() === "") ||
                    isMainParsingFnDisabled
                  }
                  {...commonBtnStyle}
                />
              )}
            {isVeryLargeScreen() && parseInProgress && (
              <ToothFairyButton
                title={"Cancel"}
                onPress={() => {
                  handleCancellation();
                }}
                {...commonBtnStyle}
              />
            )}
          </View>
        )}
        {!isVeryLargeScreen() && (
          <ToothFairyButton
            isDisabled={
              isMainParsingFnDisabled
              // || !data || data?.length === 0
            }
            title="Options"
            {...commonBtnStyle}
            onPress={() => {
              setOptionsModalOpen(true);
            }}
          />
        )}
      </View>
    );
  }, [
    isMainParsingFnDisabled,
    isPassageNil,
    handleParse,
    data,
    eValue,
    isParser,
    setIsModalOpen,
    commonBtnStyle,
  ]);
  return (
    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
      {_coreActionsButtons}
    </View>
  );
};

export default GeneratorActionButtons;
