import { AppText } from "@toothfairy/shared-ui";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { View, useWindowDimensions } from "react-native";
import { isLargeScreen } from "../../../MainViewContainer";

const ToothFairyCustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <AppTextInput value={value} onClick={onClick} ref={ref} />
));

const DateFilter = ({
  startDate,
  endDate,
  onUpdateStartDate,
  onUpdateEndDate,
}) => {
  const { width } = useWindowDimensions();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 5,
        marginLeft: 5,
        zIndex: 1,
        flexWrap: "wrap",
        maxWidth: !isLargeScreen() && 340,
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <AppText wrapperStyle={{ marginRight: 10 }}>Start Date</AppText>
        <DatePicker
          customInput={<ToothFairyCustomInput />}
          selected={startDate}
          onChange={onUpdateStartDate}
          dateFormat="dd/MM/yyyy"
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginLeft: isLargeScreen() && 20,
        }}
      >
        <AppText wrapperStyle={{ marginRight: 10 }}>End Date</AppText>
        <DatePicker
          customInput={<ToothFairyCustomInput />}
          selected={endDate}
          onChange={onUpdateEndDate}
          dateFormat="dd/MM/yyyy"
        />
      </View>
    </View>
  );
};

export default DateFilter;
