import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Prompts from "../../../../API/Prompts";
import { isLargeScreen } from "../../../MainViewContainer";
import GenerationSettingsAIView from "../SubComponents/GenerationSettingsAIView";

const GenerationSettingsAI = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onMappingCreated,
      selectedId,
      handleGenerationItemSave,
      selectedIndex,
      setShowPromptGenerationModal,
      customPrompt,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();

    const { state, dispatch } = AppStateManager.useAppStateManager();

    const validationSchema = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      label: AppForms.yup.string().required().label(),
      interpolationString: AppForms.yup.string().required().label(),
      // add promptLength which must be a number between 128 to 512
      promptLength: AppForms.yup.number().required().min(128).max(2048).label(),
    });

    const handleDeletion = async (item, configType) => {
      try {
        const _deletedPrompt = await Prompts.deletePrompt(item.id);
        dispatch(
          "SET_CUSTOM_PROMPTS",
          state?.customPrompts?.filter((prompt) => prompt.id !== item.id)
        );

        setShowPromptGenerationModal(false);
        // navigateBack();
        AppToasty.show(t("promptDeletionSuccessCaption"), {
          type: "success",
        });
      } catch (error) {
        console.error("", error);
        AppToasty.show(t("promptDeletionFailedCaption"), {
          type: "danger",
        });
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            height: 500,
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Prompt : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            style={{
              display: "block",
            }}
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={(values) => {
              handleGenerationItemSave(values);
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <GenerationSettingsAIView
              customPrompt={customPrompt}
              selectedId={selectedId}
              handleDeletion={handleDeletion}
              handleForking={(values, selectedId) => {
                handleGenerationItemSave(values, selectedId);
              }}
              isEdit={isEdit}
              settingType={settingType}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [
        state.activeWorkspaceID,
        initialMapValues,
        state.generationConfigData,
        isEdit,
        selectedId,
      ]
    );
    return _view;
  }
);

export default GenerationSettingsAI;
