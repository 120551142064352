import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import React, { useCallback, useEffect } from "react";
import useSections from "../../../../Hooks/useSections";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";

const DictionarySettingsAIView = ({ isEdit, settingType, handleDeletion }) => {
  const { currentTheme } = AppTheme.useTheme();
  const entityName = "Dictionary mapping";

  const { state } = AppStateManager.useAppStateManager();
  const { getWorkspaceBaseModel } = useWorkspaces();
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  const { setFieldValue, values } = AppForms.useFormikContext();
  useEffect(() => {
    if (values?.sourceLanguage !== "en" && values?.targetLanguage !== "en")
      setFieldValue("targetLanguage", "en");
  }, [values?.sourceLanguage]);
  useEffect(() => {
    if (values?.sourceLanguage !== "en" && values?.targetLanguage !== "en")
      setFieldValue("sourceLanguage", "en");
  }, [values?.targetLanguage]);

  return (
    <>
      <>
        <View>
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Source language"
            isMandatory
            name="sourceLanguage"
            items={state?.languagesConfig ? [...state?.languagesConfig] : []}
            propertyLabel="name"
            propertyValue="id"
          />
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Source text`}
            placeholder={`Source text`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="sourceText"
          />
        </View>

        <View>
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Target language"
            isMandatory
            name="targetLanguage"
            items={state?.languagesConfig ? [...state?.languagesConfig] : []}
            propertyLabel="name"
            propertyValue="id"
          />
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Target text`}
            placeholder={`Target text`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="targetText"
          />
        </View>

        <AppForms.AppFormField
          showTextInputLabel
          wrapperInputStyle={{
            paddingTop: 10,
          }}
          label={`Description`}
          placeholder={`Description`}
          keyboardtype="default"
          textContextType="label"
          autoCorrect={false}
          name="description"
        />
      </>
      <View style={{ alignItems: "center" }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default DictionarySettingsAIView;
