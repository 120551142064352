import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import envConfig from "../../../envConfig";

const parseText = ({
  text,
  timezone,
  isSplit,
  isMyself,
  isMedNERenabled,
  workspaceID,
  token,
  forcedLanguage,
}) => {
  let headers;
  if (token) headers = { "x-api-key": token };
  const url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/parser?text=${text}&timezone=${timezone}&workspaceid=${workspaceID}&self=${isMyself}&med=${isMedNERenabled}&split=${isSplit}&forcedLanguage=${forcedLanguage}`;
  return secureRequest(client, "GET", url, null, null, false, headers);
};

const generatePayload = ({
  sentences,
  timezone,
  isSplit,
  isMyself,
  isMedNERenabled,
  workspaceID,
  forcedLanguage,
}) => {
  for (let i = 0; i < sentences.length; i++) {
    sentences[i].timezone = timezone;
    sentences[i].split = isSplit ? "true" : "false";
    sentences[i].self = isMyself ? "true" : "false";
    sentences[i].med = isMedNERenabled ? "true" : "false";
    sentences[i].forcedLanguage = forcedLanguage || "def";
  }
  return {
    sentences,
    workspaceid: workspaceID,
  };
};

const bulkParseText = ({
  sentences,
  timezone,
  isSplit,
  isMyself,
  isMedNERenabled,
  workspaceID,
  token,
  forcedLanguage,
}) => {
  const url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/parser`;
  let headers;
  if (token) headers = { "x-api-key": token };
  const body = generatePayload({
    sentences,
    timezone,
    isSplit,
    isMyself,
    isMedNERenabled,
    workspaceID,
    forcedLanguage,
  });
  return secureRequest(client, "POST", url, null, body, false, headers);
};

export default {
  parseText,
  bulkParseText,
  generatePayload,
};
