import React, { useEffect, useState } from "react";
import useWorkspaces from "../Hooks/useWorkspaces";
import { Redirect, Route } from "./index";
import { routes } from "./routes";
import { useHistory } from "../Router";
const TFRoute = (props) => {
  const { isUserAdmin, isUserLoadedInWorkspace, getActiveWorkspace } =
    useWorkspaces();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        isUserAdmin() != null &&
        isUserLoadedInWorkspace() &&
        getActiveWorkspace()
      ) {
        history.push(
          `/workspaces/${getActiveWorkspace()?.id}/${
            !isUserAdmin() ? "chats" : "dashboard/model"
          }`
        );
      }
    }, 500); // change 2000 to the number of milliseconds you want to wait

    return () => clearTimeout(timer); // this will clear Timeout if component unmounts for any reason
  }, [isUserAdmin(), isUserLoadedInWorkspace(), getActiveWorkspace()]);
  return shouldRedirect ? (
    <Redirect
      to={{
        pathname: routes.chats,
      }}
    />
  ) : (
    <Route {...props} />
  );
};

export default TFRoute;
