import useApi from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "react-reactive-var";
import { showSideMenu, userType } from "../../../App";
import envConfig from "../../../envConfig";
import usersAPI from "../../API/Users/usersAPI";
import workspacesHelper from "../../API/Workspaces/workspacesHelper";
import usePath from "../../Hooks/usePath";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { lastMembersChange } from "../../Screens/MainViewContainer";
import { UserType } from "../../models";
import UsersInvite from "./UsersInvite";
import workspacesAPI from "../../API/Workspaces/workspacesAPI";

dayjs.extend(relativeTime);
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
const UsersInviteContainer = () => {
  const showMenu = useReactiveVar(showSideMenu);
  const [showModal, setShowModal] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState("User");
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [changeInProgress, setChangeInProgress] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [newUsers, setNewUsers] = useState([]);
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [usersAccess, setUsersAccess] = useState([]);
  const [showChoice, setShowChoice] = useState(false);
  const [forcedValidationError, setForcedValidationError] = useState(null);
  const { getActiveWorkspace, isUserAdmin, isUserRoot } = useWorkspaces();
  const { user } = AppUser.useUser();
  const [shareMembers, setShareMembers] = useState([]);
  const AppToasty = AppToast.useToast();
  const [shareMembersRef, setShareMembersRef] = useState([]);
  const { updatePath } = usePath();
  const { t } = useTranslation();
  const [shareInputValue, setShareInputValue] = useState("");
  const [usersCount, setUsersCount] = useState(1);
  const {
    data: usersUpdateResult,
    error: usersUpdateError,
    loading: usersUpdateInProgress,
    apiRequest: usersUpdateRequest,
    response: usersUpdateResponse,
  } = useApi(workspacesHelper.updateWorkspaceUsers, envConfig);

  const {
    data: userEmailRequestResult,
    error: userEmailRequestError,
    loading: userEmailRequestInProgress,
    apiRequest: userEmailRequestFetchRequest,
    response: userEmailRequestResponse,
  } = useApi(usersAPI.getUserByEmail, envConfig);

  const loadMembers = async () => {
    if (getActiveWorkspace()?.users) {
      const result = await workspacesAPI.getWorkspaceUsers(
        getActiveWorkspace()?.id
      );
      const data = result?.data?.getUsersWorkspace;
      const sharedWorkspaceUsers =
        data?.map((item) => ({
          email: item.user_email,
          username: item.username,
          type: item.id !== getActiveWorkspace().userID ? "Member" : "Admin",
          id: item.id,
        })) || [];
      setUsersCount(sharedWorkspaceUsers?.length || 1);
      setShareMembers([...sharedWorkspaceUsers]);
      setShareMembersRef([...sharedWorkspaceUsers]);
    }
  };

  const loadMembersAccess = async () => {
    if (getActiveWorkspace()?.id) {
      setUsersLoading(true);
      const result = await usersAPI.getWorkspaceUsersByWorkspaceID(
        getActiveWorkspace().id
      );
      setUsersAccess(
        result?.data?.userToWorkspaceMapping?.items?.filter(
          (u) => u?._deleted !== true
        ) || []
      );
      setUsersLoading(false);
    }
  };

  const handleUserDeletion = async () => {
    try {
      const members = shareMembers.filter((u) => u.id !== selectedItem?.id);
      setShareMembers([...members]);
      const mappingUserWorkspace = usersAccess.find(
        (u) => u.userID === selectedItem?.id
      );
      const deletionResult = await usersAPI.deleteWorkspaceUser(
        mappingUserWorkspace?.id,
        mappingUserWorkspace?._version
      );
      const result = await usersUpdateRequest({
        workspaceid: getActiveWorkspace().id,
        users: members
          .filter((member) => member?.id !== selectedItem?.id)
          ?.map((member) => member?.id),
      });
      setShowModal(false);
      setShowChoice(false);
      loadMembers();
      loadMembersAccess();
      AppToasty.show(t("WorkspaceMembersDeleteCaption"), {
        type: "success",
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSearch = (nameText = "", typeText = "") => {
    const filteredUsers = shareMembersRef.filter((user) => {
      const nameMatch = user.username
        .toLowerCase()
        .includes(nameText.toLowerCase());

      const typeMatch = user.type
        .toLowerCase()
        .includes(typeText.toLowerCase());
      return nameMatch && typeMatch;
    });
    setShareMembers([...filteredUsers]);
  };
  const handleMemberAddition = async (payload) => {
    try {
      setEmailValidated(true);
      const enhancedPayload = {
        ...payload,
        email: shareInputValue,
      };
      setNewUsers([...newUsers, enhancedPayload]);
      setSelectedUserName(payload?.label);
      setSelectedUserID(payload?.value);
    } catch (error) {
      setEmailValidated(false);
      setForcedValidationError("User not found");
    }
  };
  const handleUserSelection = (value) => {
    setSelectedUserType(value?.item?.type);
    setSelectedItem(value);
    setEmailValidated(true);
    setSelectedUserName(value?.item?.label);
    setShowModal(true);
  };
  const handleMembersInvite = async () => {
    try {
      setChangeInProgress(true);
      if (isEditMode) {
        const userMapping = usersAccess.find(
          (u) => u.userID === selectedItem?.id
        );
        const updateResult = await usersAPI.updateWorkspaceUser(
          userMapping?.id,
          userMapping?._version,
          selectedUserType
        );
        const result = await usersAPI.getWorkspaceUsersByWorkspaceID(
          getActiveWorkspace().id
        );
        const indexOf = shareMembers.findIndex(
          (u) => u.id === selectedItem?.id
        );
        let newMembers = [...shareMembers];
        newMembers[indexOf].type = selectedUserType;
        setShareMembers([...newMembers]);

        setUsersAccess(result?.data?.userToWorkspaceMapping?.items || []);
        setShowModal(false);
      } else {
        const newUsersStack = [
          ...shareMembers,
          ...newUsers?.map((user) => ({
            id: user?.value,
            email: user?.email,
            username: user?.label,
            type: selectedUserType,
            status: "To invite",
          })),
        ];

        // Detect shareMembersRef and shareMembers differences and create 3 arrays.
        // 1. New members to be invited
        // 2. Members to be removed
        // 3. Members to be updated
        const newMembers = newUsersStack.filter(
          (member) => !shareMembersRef.find((ref) => ref.id === member.id)
        );
        // for each new member, send an invite
        for (const member of newMembers) {
          const resultCreation = await usersAPI.createWorkspaceUser(
            getActiveWorkspace().id,
            member.id,
            member.type
          );
        }
        const result = await usersUpdateRequest({
          workspaceid: getActiveWorkspace().id,
          users: newUsersStack.map((member) => member?.id),
        });
        lastMembersChange(dayjs().toDate().getTime());
        if (result?.emailsNotFound?.length > 0) {
          AppToasty.show(t("WorkspaceMembersNotFoundUpdateCaption"), {
            type: "warning",
          });
        } else {
          loadMembers();
          loadMembersAccess();
          AppToasty.show(t("WorkspaceMembersUpdateCaption"), {
            type: "success",
          });
        }
        setShowModal(false);
        setShareMembers(newUsersStack);
      }
    } catch (error) {
      setShowModal(false);
      AppToasty.show(t("WorkspaceMembersUpdateFailedCaption"), {
        type: "danger",
      });
    } finally {
      setChangeInProgress(false);
    }
  };
  useEffect(() => {
    if (getActiveWorkspace()?.id) {
      loadMembers();
      loadMembersAccess();
    }
  }, [getActiveWorkspace()?.id, JSON.stringify(getActiveWorkspace()?.users)]);
  useEffect(() => {
    setForcedValidationError(null);
  }, [shareInputValue]);

  const validateSelection = (user) => {
    if (user?.type == UserType.ROOT) {
      AppToasty.show("Root account cannot be edited or deleted", {
        placement: "bottom",
        type: "danger",
      });
      return false;
    } else if (user?.type == UserType.SUPER_ADMIN && !isUserRoot()) {
      AppToasty.show(
        "A SuperAdmin can be edited or deleted only by the Root account",
        {
          placement: "bottom",
          type: "danger",
        }
      );
      return false;
    } else if (!isUserRoot() && !isUserAdmin()) {
      AppToasty.show("Only Root or Admin can edit or delete a user", {
        placement: "bottom",
        type: "danger",
      });
      return false;
    } else {
      return true;
    }
  };
  return (
    <UsersInvite
      dispatch={dispatch}
      showMenu={showMenu}
      showModal={showModal}
      setShowModal={setShowModal}
      updatePath={updatePath}
      shareMembersRef={shareMembersRef}
      shareMembers={shareMembers}
      shareInputValue={shareInputValue}
      newUsers={newUsers}
      setShareInputValue={setShareInputValue}
      handleMembersInvite={handleMembersInvite}
      handleMemberAddition={handleMemberAddition}
      handleMemberDelete={handleUserDeletion}
      isAdmin={isUserAdmin()}
      forcedValidationError={forcedValidationError}
      userEmailRequestInProgress={userEmailRequestInProgress}
      setShowChoice={setShowChoice}
      showChoice={showChoice}
      usersAccess={usersAccess}
      selectedUserType={selectedUserType}
      setSelectedUserType={setSelectedUserType}
      handleCreation={() => {
        setEmailValidated(false);
        setShareInputValue("");
        setSelectedUserType("User");
        setSelectedUserName(null);
        setSelectedUserID(null);
        setShowModal(true);
        setIsEditMode(false);
      }}
      changeInProgress={changeInProgress}
      setSelectedUserName={setSelectedUserName}
      selectedUserName={selectedUserName}
      emailValidated={emailValidated}
      usersLoading={usersLoading}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      validateSelection={validateSelection}
      handleUserSelection={handleUserSelection}
      handleSearch={handleSearch}
      userEmailRequestFetchRequest={userEmailRequestFetchRequest}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      usersCount={usersCount}
    />
  );
};

export default UsersInviteContainer;
