import { AppIcon } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppModal from "@toothfairy/shared-ui/AppModal";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useState } from "react";
import { Platform, StyleSheet } from "react-native";
import usePath from "../../Hooks/usePath";
import useSupport from "../../Hooks/useSupport";
import LoadingPlaceholder from "../LoadingPlaceholder";
import ToothFairyHTMLViewer from "./ToothFairyHTMLViewer";

const ToothFairySupportButton = ({
  _pageId,
  _url,
  forceUrlRedirection,
  show = false,
  props,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { section } = usePath();
  const { url, getRawContent, loading } = useSupport(section);
  const AppToasty = AppToast.useToast();
  const _styles = styleGenerator({
    currentTheme,
  });
  const _modalBackdrop = true;
  const _modalBackdropStatic = false;
  const _modalStyles = _styles.modalInputHelp;

  const _modalTransparency = true;

  const [title, setTitle] = useState("");
  const [content, setContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handlePress = async () => {
    try {
      if (!forceUrlRedirection) {
        setIsModalOpen(true);
        const response = await getRawContent(section, _pageId);
        if (response?.data?.data?.pages?.single) {
          setContent(response.data.data.pages.single?.content);
          setTitle(response.data.data.pages.single?.title);
        }
      } else window.open(_url || url, "_blank");
    } catch (error) {
      setIsModalOpen(false);
      if (_url || url) {
        window.open(_url || url, "_blank");
      } else
        AppToasty.show("errorFetchingSupport", {
          type: "danger",
        });
    }
  };

  return show ? (
    <>
      <AppIcon
        {...props}
        style={{ marginHorizontal: 10 }}
        activeColor={currentTheme?.theme?.primary}
        color={currentTheme?.theme?.mediumGrey}
        icon={AppAssets?.icons?.question_icon}
        size={24}
        isClickable
        isHoverable
        onPress={handlePress}
      />
      <AppModal
        modalScrollContainer={{
          flex: 1,
        }}
        modalInnerContentStyle={{
          flex: 1,
        }}
        modalBackdrop={_modalBackdrop}
        modalBackdropStatic={_modalBackdropStatic}
        modalStyle={_modalStyles}
        modalTitle={title}
        isCloseButtonVisible
        closeModal={() => setIsModalOpen(false)}
        isModalVisible={isModalOpen}
        transparent={_modalTransparency}
      >
        {loading ? (
          <LoadingPlaceholder caption="loadingSupportContent" visible={true} />
        ) : (
          <ToothFairyHTMLViewer content={content} />
        )}
      </AppModal>
    </>
  ) : (
    <></>
  );
};
const styleGenerator = ({ currentTheme }) => {
  return StyleSheet.create({
    modalInputHelp: {
      height: "95%",
      marginHorizontal: Platform.OS === "web" ? "20%" : "5%",
      marginVertical: "auto",
      width: Platform.OS === "web" ? "60%" : "90%",
    },
  });
};

export default ToothFairySupportButton;
