import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { Platform, StyleSheet } from "react-native";
import useSections from "../../Hooks/useSections";
import useWorkspaces from "../../Hooks/useWorkspaces";
import DictionariesList from "./Components/Dictionary/DictionariesList";
import EntitiesList from "./Components/EntitiesList";
import FunctionsList from "./Components/Functions/FunctionsList";
import ConnectionsList from "./Components/Connections/ConnectionsList";
import AuthorisationsList from "./Components/Authorisations/AuthorisationsList";
import GeneratorConfigList from "./Components/Generation/GeneratorConfigList";
import ParsingSettings from "./Components/ParsingSettings";
import QaConfigList from "./Components/QA/QaConfigList";
import SettingsAI from "./Components/SettingsAI";
import TrainingSettings from "./Components/TrainingSettings";
import SitesList from "./Components/Sites/SitesList";
import { isMobileScreen } from "../MainViewContainer";
import HostingsList from "./Components/Hostings/HostingList";
import ExecEnvsList from "./Components/Execution Environments/ExecEnvsList";
import ChannelsList from "./Components/Channels/ChannelsList";

const SettingsScreen = ({ workspace, handleWorkspaceUpdate }) => {
  const { currentTheme } = AppTheme.useTheme();
  const { setDisplayedView, displayedOpt, viewRenderer } = useSections();
  const styles = styleGenerator(currentTheme, isMobileScreen());
  const { getWorkspaceBaseModel } = useWorkspaces();
  const { state } = AppStateManager.useAppStateManager();

  const mapDisplayedViewWithComponent = (uid) => {
    const Intents =
      state?.aiEntitiesConfig?.filter((i) => i.type === "intent") || [];
    const NERs = state?.aiEntitiesConfig?.filter((i) => i.type === "ner") || [];
    const Topics =
      state?.aiEntitiesConfig?.filter((i) => i.type === "topic") || [];
    Intents.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );
    NERs.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );
    Topics.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );
    switch (uid) {
      case "INTENT":
        return (
          <EntitiesList
            showEmoji={false}
            entities={Intents}
            buttonTitle={"Create intent"}
            isCreateEnabled={getWorkspaceBaseModel() === "none"}
            createEntitityUID={"INTENT_NEW"}
            editEntityUID={"INTENT_EDIT"}
            settingType="intent"
          />
        );
      case "INTENT_NEW":
        return (
          <SettingsAI
            backTo="INTENT"
            settingType="intent"
            isEdit={false}
            setDisplayedView={setDisplayedView}
            opt={displayedOpt}
            sectionTitle="New"
          />
        );
      case "INTENT_EDIT":
        return (
          <SettingsAI
            backTo="INTENT"
            isEdit
            sectionTitle={displayedOpt?.label}
            settingType="intent"
            opt={displayedOpt}
            initialValues={{ ...displayedOpt }}
            setDisplayedView={setDisplayedView}
          />
        );
      case "NER":
        return (
          <EntitiesList
            showEmoji={true}
            entities={NERs}
            buttonTitle={"Create NER"}
            createEntitityUID={"NER_NEW"}
            editEntityUID={"NER_EDIT"}
            settingType="ner"
          />
        );
      case "NER_NEW":
        return (
          <SettingsAI
            backTo="NER"
            settingType="ner"
            isEdit={false}
            setDisplayedView={setDisplayedView}
            opt={displayedOpt}
            sectionTitle="New"
          />
        );
      case "NER_EDIT":
        return (
          <SettingsAI
            backTo="NER"
            settingType="ner"
            isEdit
            sectionTitle={displayedOpt?.label}
            opt={displayedOpt}
            initialValues={{ ...displayedOpt }}
            setDisplayedView={setDisplayedView}
          />
        );

      case "TOPIC":
        return (
          <EntitiesList
            showEmoji={true}
            entities={Topics}
            buttonTitle={"Create Topic"}
            createEntitityUID={"TOPIC_NEW"}
            editEntityUID={"TOPIC_EDIT"}
          />
        );
      case "TOPIC_NEW":
        return (
          <SettingsAI
            backTo="TOPIC"
            settingType="topic"
            isEdit={false}
            setDisplayedView={setDisplayedView}
            opt={displayedOpt}
            sectionTitle="New TOPIC"
          />
        );
      case "TOPIC_EDIT":
        return (
          <SettingsAI
            backTo="TOPIC"
            settingType="topic"
            isEdit
            sectionTitle={displayedOpt?.label}
            opt={displayedOpt}
            initialValues={{ ...displayedOpt }}
            setDisplayedView={setDisplayedView}
          />
        );
      case "DICTIONARY":
        return (
          <DictionariesList
            buttonTitle={"Create Dictionary"}
            createEntitityUID={"DICTIONARY_NEW"}
            editEntityUID={"DICTIONARY_EDIT"}
            dictionaries={state?.aiDictionariesConfig}
          />
        );
      case "FUNCTIONS":
        return (
          <FunctionsList
            buttonTitle={"Create Function"}
            createEntitityUID={"FUNCTION_NEW"}
            editEntityUID={"FUNCTION_EDIT"}
            dictionaries={state?.agentsFunctions}
          />
        );
      case "DB_CONNECTIONS":
        return (
          <ConnectionsList
            buttonTitle={"Create DB Connection"}
            createEntitityUID={"DB_CONNECTION_NEW"}
            editEntityUID={"DB_CONNECTION_EDIT"}
            dictionaries={state?.dbConnections}
            setDisplayedView={setDisplayedView}
          />
        );
      case "AUTHORISATIONS":
        return (
          <AuthorisationsList
            buttonTitle={"Create Authorisation"}
            createEntitityUID={"AUTH_NEW"}
            editEntityUID={"AUTH_EDIT"}
            dictionaries={state?.authorisations}
            setDisplayedView={setDisplayedView}
          />
        );
      case "HOSTINGS":
        return (
          <HostingsList
            buttonTitle={"Create Provider"}
            createEntitityUID={"HOST_NEW"}
            editEntityUID={"HOST_EDIT"}
            dictionaries={state?.hostings}
            setDisplayedView={setDisplayedView}
          />
        );
      case "CHANNELS":
        return (
          <ChannelsList
            buttonTitle={"Create Channel"}
            createEntitityUID={"CHANNEL_NEW"}
            editEntityUID={"CHANNEL_EDIT"}
            dictionaries={state?.channels}
            setDisplayedView={setDisplayedView}
          />
        );
      case "SITES":
        return (
          <SitesList
            buttonTitle={"Create Site"}
            createEntitityUID={"SITE_NEW"}
            editEntityUID={"SITE_EDIT"}
            sites={state?.sites}
          />
        );
      case "EXEC_ENVS":
        return (
          <ExecEnvsList
            buttonTitle={"Create Execution environment"}
            createEntitityUID={"EXEC_ENV_NEW"}
            editEntityUID={"EXEC_ENV_EDIT"}
            execEnvs={state?.execEnvs}
          />
        );
      case "GENERATOR_SETTINGS":
        return (
          <GeneratorConfigList
            buttonTitle={""}
            createEntitityUID={"GENERATOR_SETTINGS_NEW"}
            editEntityUID={"GENERATOR_SETTINGS_EDIT"}
          />
        );
      case "ROLES_SETTINGS":
        return (
          <QaConfigList
            buttonTitle={""}
            createEntitityUID={"ROLES_SETTINGS_NEW"}
            editEntityUID={"ROLES_SETTINGS_EDIT"}
          />
        );
      case "TRAINING":
        return (
          <TrainingSettings
            workspace={workspace}
            handleWorkspaceUpdate={handleWorkspaceUpdate}
          />
        );
      case "PARSING":
        return (
          <ParsingSettings handleWorkspaceUpdate={handleWorkspaceUpdate} />
        );
      default:
        return <></>;
    }
  };

  return viewRenderer(mapDisplayedViewWithComponent, styles);
};
const styleGenerator = (currentTheme, isMobileScreen) => {
  return StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 10,
      paddingHorizontal: 20,
    },
    icon: {
      borderRadius: 5,
      width: "auto",
      height: "auto",
    },
    modal: {
      borderRadius: 30,
      height: Platform.OS === "web" && !isMobileScreen ? 500 : "95%",
      left: "auto",
      marginHorizontal: Platform.OS === "web" ? "auto" : "5%",
      marginVertical: "auto",
      top: "auto",
      width: Platform.OS === "web" && !isMobileScreen ? 500 : "99%",
    },
    modalInnerContent: {
      paddingHorizontal: 20,
      paddingTop: "auto",
    },
    modalRightIcon: {
      position: "relative",
      top: "auto",
      right: "auto",
    },
    modalWrapper: {
      height: "auto",
    },
    title: {
      fontSize: currentTheme?.theme?.largest_font_size,
      fontWeight: "bold",
    },
  });
};

export default SettingsScreen;
