import { DataStore } from "@aws-amplify/datastore";
import useAPI from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import envConfig from "../../../envConfig";
import Batch from "../../API/Batch";
import batchLogHelper from "../../API/Batch/batchLogHelper";
import Files from "../../API/Files";
import Constants from "../../Constants";
import usePath from "../../Hooks/usePath";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { BatchJobLog } from "../../models";
import colorsMappings from "../Common/colorsMappings";
import downloader from "../Common/downloader";
import BatchScreen from "./BatchScreen";
dayjs.extend(isBetween);
const routes = [
  {
    key: "generativeTraining",
    title: "Content creator",
    routeId: "gn",
    shortTitle: "GN",
  },
  {
    key: "readComprehensionTraining",
    title: "Business analyst",
    routeId: "rc",
    shortTitle: "BA",
  },
];
const BatchScreenContainer = ({ navigation }) => {
  const { t } = useTranslation();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { params } = usePath();
  const {
    getActiveWorkspace,
    handleTabSelection,
    getRawTabSelection,
    getPlanConfig,
  } = useWorkspaces();
  useEffect(() => {
    if (
      getActiveWorkspace()?.isSentimentReviewerEnabled &&
      routes?.find((u) => u.routeId === "nlp") == null
    )
      routes.push({
        key: "nlpTraining",
        title: "Sentiment reviewer",
        routeId: "nlp",
        shortTitle: "SR",
      });
  }, [getActiveWorkspace()?.isSentimentReviewerEnabled]);

  // const routes = [];
  // if (getPlanConfig()?.isSentimentReviewerEnabled)
  //   routes.push({
  //     key: "nlpTraining",
  //     title: "Natural language",
  //     routeId: "nlp",
  //     shortTitle: "NLP",
  //   });
  // if (getPlanConfig()?.isBusinessAnalystEnabled)
  //   routes.push({
  //     key: "readComprehensionTraining",
  //     title: "Reading comprehension",
  //     routeId: "rc",
  //     shortTitle: "RC",
  //   });
  // if (getPlanConfig()?.isContentCreatorEnabled) {
  //   routes.push({
  //     key: "generativeTraining",
  //     title: "Content generation",
  //     routeId: "gn",
  //     shortTitle: "GN",
  //   });
  // }

  const { user } = AppUser.useUser();
  const AppToasty = AppToast.useToast();

  const {
    data: uploadedBatchData,
    loading: batchDataUploadInProgress,
    apiRequest: uploadBatchFile,
    response: uploadBatchFileResponse,
  } = useAPI(Batch.uploadBatchFile, envConfig);

  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);

  const [selectedTab, setSelectedTab] = useState(
    getRawTabSelection(routes, "training")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(dayjs().startOf("month").toDate());
  const [end, setEnd] = useState(dayjs().endOf("month").toDate());
  const [fileSelector, SetFileSelector] = useState(null);
  const [statusFilter, setStatusFilter] = useState("noFilter");
  const [displayedLogs, setDisplayedLogs] = useState([]);
  const [batchConfig, setBatchConfig] = useState({
    temperature:
      getActiveWorkspace()?.parsingConfig?.temperature || Constants.temperature,
    topP: getActiveWorkspace()?.parsingConfig?.topP || Constants.topP,
    maxTokens:
      getActiveWorkspace()?.parsingConfig?.maxTokens || Constants.maxTokens,
    frequencyPenalty:
      getActiveWorkspace()?.parsingConfig?.frequencyPenalty ||
      Constants.frequencyPenalty,
    presencePenalty:
      getActiveWorkspace()?.parsingConfig?.presencePenalty ||
      Constants.presencePenalty,
    fileUploadRequired: true,
    isQuestionGeneration: false,
  });
  useEffect(() => {
    if (getActiveWorkspace()?.parsingConfig && !batchConfig)
      setBatchConfig({
        ...getActiveWorkspace()?.parsingConfig,
        fileUploadRequired: true,
        isQuestionGeneration: false,
        name: batchConfig?.name || "",
        description: batchConfig?.description || "",
      });
  }, [
    JSON.stringify(getActiveWorkspace()?.parsingConfig),
    selectedTab?.routeId,
  ]);
  const handleFilterChange = (v) => {
    setStatusFilter(v);
  };
  const handleFieldUpdate = async (id, property, value) => {
    try {
      const result = await batchLogHelper.updateBatchJobLog(id, [
        {
          property,
          value,
        },
      ]);
      const objectIndex = state?.batchLogs.findIndex((log) => log.id === id);
      if (objectIndex >= 0) {
        state.batchLogs[objectIndex] = result;
        dispatch("SET_BATCH_LOGS", state.batchLogs);
      }
    } catch (error) {
      throw error;
    }
  };
  const handleUpdateStartDate = (v) => {
    setStart(v);
  };
  const handleUpdateEndDate = (v) => {
    setEnd(v);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleNoFileUpload = async () => {
    await uploadBatchFile({
      workspaceid: state?.activeWorkspaceID,
      type: "rc",
      userid: user?.id,
      token: getActiveWorkspace().workspaceToken,
      config: batchConfig,
    });
  };
  useEffect(() => {
    if (fileSelector) {
      handleFileSelect();
      SetFileSelector(null);
    }
  }, [fileSelector, handleFileSelect, handleNoFileUpload]);
  const handleBatchInitiation = async () => {
    try {
      SetFileSelector(
        buildFileSelector(selectedTab?.routeId, user?.id, batchConfig)
      );
    } catch (error) {
      console.error("Error on batch initiation", error);
      AppToasty.show(t("batchParsingCreationFailedCaption"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };

  const handleCancelBatch = async (id) => {
    try {
      await DataStore.delete(BatchJobLog, (ent) => ent.id("eq", id));
      AppToasty.show(t("BatchDeletionCaption"), {
        placement: "top",
        type: "success",
      });
      const batchLogs = state?.batchLogs.filter((u) => u.id !== id);
      dispatch("SET_BATCH_LOGS", batchLogs);
    } catch (error) {
      AppToasty.show(t("BatchDeletionFailedCaption"), {
        placement: "top",
        type: "danger",
      });
    }
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    handleTabSelection(tab, routes, "batch");
  };
  const handleSearch = () => {
    setDisplayedLogs(
      state?.batchLogs?.filter(
        (u) =>
          // u.createdBy === user.id &&
          u.type === selectedTab?.routeId &&
          (u.status === statusFilter || statusFilter === "noFilter") &&
          dayjs(new Date(u._lastChangedAt)).isBetween(start, end, null, "[]")
      )
    );
  };
  useEffect(() => {
    let validLogs = state?.batchLogs?.filter(
      (u) =>
        // u.createdBy === user.id &&
        u.type === selectedTab?.routeId
    );
    // find index of the active training log
    setDisplayedLogs(validLogs);
  }, [selectedTab?.routeId, JSON.stringify(state?.batchLogs)]);
  function buildFileSelector(type, userid, batchConfig) {
    const handleImport = async (file, type, userid) => {
      try {
        AppToasty.show(t("DocumentImportUploadStartedCaption"), {
          type: "success",
        });
        await uploadBatchFile({
          workspaceid: state?.activeWorkspaceID,
          filename: "imported_file.csv",
          type: type,
          userid: userid,
          token: getActiveWorkspace().workspaceToken,
          file,
          config: batchConfig,
        });
        AppToasty.show(t("DocumentImportUploadSuccessCaption"), {
          type: "success",
        });
        setBatchConfig({
          name: "",
          description: "",
          agentId: null,
        });
      } catch (error) {
        AppToasty.show(t("DocumentImportUploadFailedCaption"), {
          type: "danger",
        });
        console.error("Upload result", error);
      }
    };

    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("single", "single");
    fileSelector.addEventListener("change", function (e) {
      handleImport(e?.target, type, userid);
    });
    return fileSelector;
  }
  const handleFileSelect = () => {
    if (Platform.OS === "web") {
      // e.preventDefault();
      fileSelector.value = null;
      fileSelector.click();
    } else {
      AppToasty.show(`Bulk parsing not available on ${Platform.OS}`, {
        type: "warning",
      });
    }
  };
  const handleBatchOutputDataDownload = async ({
    workspaceid,
    logId,
    type,
    selection,
  }) => {
    try {
      const result = await Promise.all(
        selection?.map((u) =>
          S3downloadUrlRequest({
            workspaceid,
            logId: logId,
            type,
            filename: Constants.BATCH_DOWNLOAD_OPTIONS.find(
              (item) => item.id === u
            ).filename,
            context: "batch",
          })
        )
      );

      downloader.download_files(
        result?.map((u, i) => {
          return {
            download: u.url,
            filename: "BT-" + logId + "-" + selection[i],
          };
        })
      );
    } catch (error) {
      console.error("Batch data download error", error);
    }
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BatchScreen
        handleFieldUpdate={handleFieldUpdate}
        onUpdateStartDate={handleUpdateStartDate}
        onUpdateEndDate={handleUpdateEndDate}
        onFilterChange={handleFilterChange}
        statusFilter={statusFilter}
        startDate={start}
        endDate={end}
        handleSearch={handleSearch}
        handleTabChange={handleTabChange}
        selectedTab={selectedTab}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleBatchInitiation={handleBatchInitiation}
        handleFileSelect={handleFileSelect}
        batchLogs={displayedLogs}
        logStatusColors={colorsMappings.logStatusColors}
        handleCancelBatch={handleCancelBatch}
        batchParsingInProgress={
          state?.batchLogs?.filter(
            (u) => u.status === "inProgress" || u.status === "dispatched"
          )?.length > 0
        }
        routes={routes}
        initialTabFromRouting={params.sectionId}
        batchConfig={batchConfig}
        setBatchConfig={setBatchConfig}
        handleBatchOutputDataDownload={handleBatchOutputDataDownload}
      />
    </Suspense>
  );
};

export default BatchScreenContainer;
