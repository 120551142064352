import React from "react";
import useWorkspaces from "../Hooks/useWorkspaces";
import { Redirect, Route } from "./index";
import { routes } from "./routes";

const ProRoute = (props) => {
  const { isUserAIEngineer, getPlanConfig } = useWorkspaces();

  return (getPlanConfig() && !getPlanConfig()?.isBatchJobsEnabled) ||
    !isUserAIEngineer() ? (
    <Redirect
      to={{
        pathname: routes.accessDenied,
      }}
    />
  ) : (
    <Route {...props} />
  );
};

export default ProRoute;
