import React from "react";
import { View } from "react-native";
import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const ChatMediaLeaf = ({
  onUploaded,
  url,
  type = "image",
  fileExtension = null,
  fileName = null,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const matchFileTypeWithAsset = () => {
    // const fileType = url.split(".").pop();
    switch (fileExtension) {
      case "pdf":
        return require("../../../assets/pdf.png");
      case "doc":
      case "docx":
        return require("../../../assets/doc.png");
      case "xls":
      case "xlsx":
      case "csv":
        return require("../../../assets/xls.png");
      // case "ppt":
      // case "pptx":
      //   return require("../../../assets/ppt.png");
      case "txt":
      case "md":
      case "html":
        return require("../../../assets/txt.png");
      default:
        return require("../../../assets/file.png");
    }
  };
  return (
    <View
      style={{
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <AppIcon
        icon={AppAssets?.icons?.close}
        size={16}
        color={currentTheme?.theme?.red}
        wrapperStyle={{
          position: "relative",
          zIndex: 10,
          backgroundColor: currentTheme?.theme?.lightGrey,
          borderRadius: 10,
        }}
        isClickable
        onPress={() => onUploaded(null)}
      />
      <View
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={
            type == "image"
              ? url
              : type == "video"
              ? require("../../../assets/video.png")
              : type == "audio"
              ? require("../../../assets/audio.png")
              : type == "file"
              ? matchFileTypeWithAsset()
              : null
          }
          style={{
            width: 48,
            height: 48,
            borderRadius: 10,
            position: "relative",
            bottom: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(url, "_blank");
          }}
        />
        {fileName && (
          <AppText
            wrapperStyle={{
              position: "absolute",
              bottom: 0,
            }}
            style={{
              fontSize: 8,
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 50,
              textWrap: "nowrap",
              direction: "rtl",
            }}
          >
            {fileName}
          </AppText>
        )}
      </View>
    </View>
  );
};

export default ChatMediaLeaf;
