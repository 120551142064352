import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { generateRowId } from "../../../../Utility/index";
import { isLargeScreen } from "../../../MainViewContainer";
import GenerationSettingsAI from "./GenerationSettingsAI";
import GeneratorConfigToolbar from "../SubComponents/GeneratorConfigToolbar";
import Prompts from "../../../../API/Prompts";
import { showSideMenu } from "../../../../../App";

const GeneratorConfigList = ({ isDictionariesLoading }) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { getActiveWorkspace, getPlanConfig } = useWorkspaces();
  const [showPromptGenerationModal, setShowPromptGenerationModal] =
    React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedPrompts, setDisplayedPrompts] = React.useState([]);
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { height, width } = useWindowDimensions();
  function camelize(str) {
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }
  const [initialMapValues, setInitialMapValues] = useState({
    // type: "",
    label: "",
    promptLength: 1024,
    // promptPlaceholder: "",
    interpolationString: "",
    actions: "",
  });
  const columns = [
    {
      title: "Key",
      width: 0.3,
    },
    {
      title: "Name",
      width: 0.2,
    },
    // {
    //   title: "Prompt length",
    // },

    {
      title: "Value",
    },
    {
      title: "Prompt length",
      width: 0.1,
    },
    {
      title: "Actions",
      isAction: true,
    },
  ];

  const handleGenerationItemSave = async (values, selectedId) => {
    try {
      const availableToAgents = values?.availableToAgents?.map((item) => {
        return item.id;
      });
      if (isEditMode && !selectedId) {
        let editedPrompt = { ...state.customPrompts[selectedIndex] };
        if (editedPrompt) {
          editedPrompt.label = values.label;
          editedPrompt.interpolationString = values.interpolationString;
          editedPrompt.promptLength = parseInt(values.promptLength);
        }

        const _editedRole = await Prompts.updatePrompt(editedPrompt.id, [
          {
            property: "label",
            value: values.label,
          },
          {
            property: "interpolationString",
            value: values.interpolationString,
          },
          {
            property: "availableToAgents",
            value: availableToAgents,
          },
        ]);
        let bufferPrompts = [...state.customPrompts];
        bufferPrompts[selectedIndex] = _editedRole;
        dispatch("SET_CUSTOM_PROMPTS", bufferPrompts);
      } else {
        const _type = generateRowId()?.toString();
        const _promptCode = `${camelize(values.label)}${
          selectedId ? `-${_type}` : ""
        }`;
        let newPrompt = {
          type: _type,
          label: values.label,
          promptLength: parseInt(values.promptLength),
          scope: _promptCode,
          type: _promptCode,
          availableToAgents: availableToAgents,
          // promptPlaceholder: values.promptPlaceholder,
          interpolationString: values.interpolationString,
          workspaceID: getActiveWorkspace()?.id,
        };
        if (state.customPrompts?.find((item) => item.type === _promptCode)) {
          return AppToasty.show(
            t("promptCreationErrorCaptionBecauseDuplicated"),
            {
              type: "danger",
            }
          );
        }
        const _newRole = await Prompts.createPrompt(newPrompt);
        dispatch("SET_CUSTOM_PROMPTS", [...state.customPrompts, _newRole]);
      }
      AppToasty.show(t("promptCreationSuccessCaption"), {
        type: "success",
      });
      setShowPromptGenerationModal(false);
    } catch (error) {
      AppToasty.show(t("promptCreationErrorCaption"), {
        type: "danger",
      });
      console.log("error", error);
    }
  };
  const customPromptConfigView = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          maxHeight: 900,
          height: "95%",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showPromptGenerationModal}
        setIsModalOpen={setShowPromptGenerationModal}
        modalTitle={isEditMode ? "promptEditTitle" : "promptCreationTitle"}
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowPromptGenerationModal(false);
          setSelectedIndex(null);
        }}
      >
        <GenerationSettingsAI
          ref={formRef}
          initialMapValues={initialMapValues}
          handleGenerationItemSave={handleGenerationItemSave}
          isEdit={isEditMode}
          selectedId={displayedPrompts[selectedIndex]?.id}
          selectedIndex={selectedIndex}
          customPrompt={state?.customPrompts?.find(
            (u) => u?.id === displayedPrompts[selectedIndex]?.id
          )}
          setShowPromptGenerationModal={setShowPromptGenerationModal}
        />
      </ToothFairyModal>
    );
  }, [
    showPromptGenerationModal,
    setShowPromptGenerationModal,
    initialMapValues,
    isEditMode,
    selectedIndex,
    displayedPrompts,
    JSON.stringify(state?.configData),
    isLargeScreen(),
  ]);
  useEffect(() => {
    if (state?.customPrompts) {
      setDisplayedPrompts(state?.customPrompts);
    }
  }, [JSON.stringify(state?.customPrompts)]);

  const handlePromptItemSelection = ({ item, index }) => {
    setSelectedIndex(index);
    setIsEditMode(true);
    item = { ...displayedPrompts[index] };
    if (item?.availableToAgents) {
      const result = item.availableToAgents.map((agent) => {
        return state?.agents.find((u) => u.id === agent);
      });
      item.availableToAgents = result?.map((u) => {
        return {
          id: u.id,
          label: u.label,
          value: u.id,
        };
      });
    }
    setInitialMapValues(item);
    setShowPromptGenerationModal(true);
  };
  const handlePromptItemDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        const itemToDelete = displayedPrompts[selectedIndex];
        const _deletedPrompt = await Prompts.deletePrompt(itemToDelete.id);
        dispatch(
          "SET_CUSTOM_PROMPTS",
          state?.customPrompts?.filter(
            (item) => item.id !== _deletedPrompt[0].id
          )
        );

        AppToasty.show(t("promptDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      AppToasty.show(t("promptDeletionFailedCaption"), {
        type: "danger",
      });
    }
  };
  const handleCreation = () => {
    setIsEditMode(false);
    setShowPromptGenerationModal(true);
    setInitialMapValues({
      label: "",
      interpolationString: "",
      promptLength: 256,
    });
  };
  const handleSearch = async (type, label, interpolationString) => {
    // find items in the list which cointain either the sourceText or targetText
    const filteredItems = state?.customPrompts?.filter((item) => {
      return (
        item.type.toLowerCase().includes(type.toLowerCase()) &&
        item.label.toLowerCase().includes(label.toLowerCase()) &&
        item.interpolationString
          .toLowerCase()
          .includes(interpolationString.toLowerCase())
      );
    });
    setDisplayedPrompts(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"promptItemCancelConfirmation"}
        caption="promptItemCancelCaption"
        subCaption="promptItemCancelSubCaption"
        confirmCaption={"promptItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={() => handlePromptItemDeletion()}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [setShowChoice, showChoice, selectedItem, setSelectedItem, selectedIndex]);

  return !getPlanConfig()?.isContentCreatorEnabled ? (
    <AppPlaceholderView
      wrapperStyle={{
        width: "100%",
        height: "100%",
        marginVertical: 20,
        marginHorizontal: "auto",
        flex: 1,
      }}
      size={80}
      icon={AppAssets.icons.orbit}
      mainCaption="EmptyPlaceholder"
      subCaption="SettingsSubCaption"
    />
  ) : (
    <View
      style={{
        paddingLeft: 10,
        minWidth: "100%",
        flex: 1,
        width: width - (showSideMenu() ? 400 : 220),
      }}
    >
      <GeneratorConfigToolbar
        handleSearch={handleSearch}
        handleCreation={handleCreation}
      />
      {isDictionariesLoading ? (
        <AppActivityIndicator
          backgroundColor={currentTheme?.theme?.softBackgroundColor}
          visible={true}
          containerStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
            minHeight: 250,
          }}
        />
      ) : (
        <>
          {displayedPrompts.length > 0 ? (
            <AppTable
              actionButtons
              handleDocumentSelection={handlePromptItemSelection}
              handleDocumentDeletion={(item, index) => {
                setSelectedItem(item);
                setSelectedIndex(index);
                setShowChoice(true);
              }}
              style={{
                zIndex: -1,
                maxHeight: height - 200,
                overflowY: "auto",
                overflowX: "hidden",
              }}
              headers={columns}
              items={displayedPrompts
                ?.slice(
                  (currentPage - 1) * pageSize,
                  (currentPage - 1) * pageSize + pageSize
                )
                ?.map((u) => {
                  return {
                    type: u.type,
                    label: u.label,
                    interpolationString: u.interpolationString,
                    promptLength: u.promptLength.toString(),
                    // promptPlaceholder: u.promptPlaceholder,

                    actions: " ",
                  };
                })}
            />
          ) : (
            <AppPlaceholderView
              wrapperStyle={{
                width: "100%",
                height: "100%",
                marginVertical: 20,
                marginHorizontal: "auto",
                flex: 1,
              }}
              size={80}
              icon={AppAssets.icons.orbit}
              mainCaption="EmptyPlaceholder"
              subCaption="PromptSubCaption"
            />
          )}
          {displayedPrompts?.length > pageSize && (
            <AppPagination
              onPageChange={handlePageChange}
              totalCount={displayedPrompts?.length}
              siblingCount={1}
              currentPage={currentPage}
              pageSize={pageSize}
              wrapperStyle={{
                marginTop: 10,
                alignSelf: "flex-end",
              }}
            />
          )}
        </>
      )}
      {customPromptConfigView}
      {_coreChoice}
    </View>
  );
};

export default GeneratorConfigList;
