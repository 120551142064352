import API from "@aws-amplify/api";
import {
  createCommunicationService,
  deleteCommunicationService,
  updateCommunicationService,
} from "../../graphql/mutations";
import {
  communicationServicesByWorkspace,
  getCommunicationService,
} from "../../graphql/queries";
const createChannel_ = async (object) => {
  return API.graphql({
    query: createCommunicationService,
    variables: { input: object },
  });
};
const getChannel_ = async (id) => {
  return API.graphql({
    query: getCommunicationService,
    variables: { id },
  });
};
const updateChannel_ = async (id, _properties = {}) => {
  const original = await getChannel_(id);
  return API.graphql({
    query: updateCommunicationService,
    variables: {
      input: {
        id,
        _version: original?.data?.getCommunicationService?._version,
        ..._properties,
      },
    },
  });
};

const deleteChannel_ = async (id) => {
  const original = await getChannel_(id);
  return API.graphql({
    query: deleteCommunicationService,
    variables: {
      input: {
        id,
        _version: original?.data?.getCommunicationService?._version,
      },
    },
  });
};

const getChannels_ = async (workspaceID, nextToken) => {
  return API.graphql({
    query: communicationServicesByWorkspace,
    variables: {
      workspaceID,
      nextToken,
    },
  });
};

export default {
  createChannel: createChannel_,
  updateChannel: updateChannel_,
  deleteChannel: deleteChannel_,
  getChannel: getChannel_,
  getChannels: getChannels_,
};
