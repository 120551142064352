import React from "react";
import { AppIcon } from "@toothfairy/shared-ui";
const TreeIcon = ({ ...props }) => {
  return (
    <AppIcon
      // style adjusted to make sure the treeview looks okay from a positioning of icons perspective
      wrapperStyle={{
        marginBottom:
          (props?.icon?.iconName == "calendar-day" ||
            props?.icon?.iconName == "user-secret") &&
          2,
      }}
      isClickable
      size={14}
      {...props}
    />
  );
};

export default TreeIcon;
