import API, { graphqlOperation } from "@aws-amplify/api";
import { Amplify, Hub } from "@aws-amplify/core";
import { DataStore } from "@aws-amplify/datastore";
import { useFonts } from "@expo-google-fonts/open-sans";
import useAPI from "@toothfairy/shared-api/useApi";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import "highlight.js/styles/github.css";
import "jsoneditor/dist/jsoneditor.css";
import "rc-slider/assets/index.css";
import React, { Suspense, useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { makeVar } from "react-reactive-var";
import envConfig from "./envConfig";
import "./i18n.config";
import API_Consumption from "./src/API/API_Consumption";
import config from "./src/Auth/aws-exports";
import ToothFairyLoadingPlaceholder from "./src/Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";
import { default as AppConfig, default as Constants } from "./src/Constants";
import appReducer from "./src/Redux/appReducer";
import initialState from "./src/Redux/initialState";
import Routes from "./src/Router/routes";
import AuthScreen from "./src/Screens/Auth/AuthScreen";
import MainViewContainer from "./src/Screens/MainViewContainer";
import {
  listSubscriptionBaseConfigs,
  listSubscriptionPricingPlans,
  usersByOwner,
  workspacesByUser,
} from "./src/graphql/queries";

Amplify.configure(config);
// AppAuth.Auth.configure(config);
API.configure(config);
DataStore.configure(config);

export const AppRootContext = React.createContext({});
API.Auth.configure(config);
console.log("HUB--", Hub);
export const showSideMenu = makeVar(
  Dimensions.get("window").width > Constants.screenResponsiveWidth
);
export const forcedClosed = makeVar(false);
export const pinnedSideMenu = makeVar(false);
export const currentUserId = makeVar(null);
export const lastPathInWorkspace = makeVar({
  workspaceid: null,
  path: null,
});
export const isUserDelinquent = makeVar(false);
export const isUserOnTrial = makeVar(false);
export const isFirstTrialStarted = makeVar(false);
export const isUserWithNoPaymentMethod = makeVar(false);
export const isUserEnabledToOperate = makeVar(false);
export const isBillingAddressSet = makeVar(false);
export const isConnectedToCloud = makeVar(false);
export const displayedToasties = makeVar(null);
export const reservedConfigs = makeVar(null);
export const userType = makeVar(null);
export const isSSO = makeVar(false);
//

const App = () => {
  // Amplify.Logger.LOG_LEVEL = "DEBUG";
  const [isLocalDBReady, setLocalDBReady] = useState(false);
  const [serverWorkspaces, setServerWorkspaces] = useState([]);
  const [authUserSub, setAuthUserSub] = useState(null);
  const [dataSyncReady, setDataSyncReady] = useState(false);
  const [subscriptionsEstablished, setSubscriptionsEstablished] =
    useState(false);
  const [isFederatedSignInProgress, setFederatedSignInProgress] =
    useState(false);
  const {
    data: stripeCustomerData,

    loading: stripeCustomerAPIInProgress,
    apiRequest: fetchStripeCustomer,
  } = useAPI(API_Consumption.getStripeCustomer);

  const onCognitoUserAuthenticated = async () => {
    try {
      const [authUserResult, resultFromStripe] = await Promise.all([
        getCurrentAuthenticatedUser(),
        fetchStripeCustomer(),
      ]);
      if (
        authUserResult?.username?.startsWith("google_") ||
        authUserResult?.username?.startsWith("azure")
      ) {
        setAuthUserSub(
          authUserResult?.signInUserSession?.idToken?.payload?.sub
        );
        await fetchDataForSelectiveSync(
          authUserResult?.signInUserSession?.idToken?.payload?.sub
        );
        isSSO(authUserResult?.username?.startsWith("azure"));
        // create the user in dynamodb using name and surname
        // associate to a workspace based on the domain name
        // give them access based on the role tf_user
        // then they can use the app
      } else {
        setAuthUserSub(authUserResult.username);
        await fetchDataForSelectiveSync(authUserResult.username);
      }
      if (resultFromStripe) {
        isUserDelinquent(resultFromStripe.delinquent);
        isFirstTrialStarted(resultFromStripe.trialEndDate != null);
        isBillingAddressSet(resultFromStripe.address !== null);
        isUserOnTrial(
          resultFromStripe.trialEndDate !== null &&
            resultFromStripe.trialEndDate * 1000 > Date.now()
        );
        isUserWithNoPaymentMethod(
          resultFromStripe.invoice_settings.default_payment_method === null
        );
        isUserEnabledToOperate(
          !resultFromStripe.delinquent &&
            resultFromStripe.invoice_settings.default_payment_method !== null
        );
      }
      fetchDataForConfig();
      fetchDataForWorkspaceBaseConfig();
    } catch (error) {
      throw error;
    }
  };
  const {
    currentView,
    setCurrentView,
    getCurrentAuthenticatedUser,
    attemptSignInWithGoogle,
  } = AppAuth.useAuth(config, onCognitoUserAuthenticated);
  let [fontsLoaded] = useFonts({
    normalFont: require("./src/Fonts/Gilroy-Regular.woff2"),
    boldFont: require("./src/Fonts/Gilroy-SemiBold.woff2"),
  });
  const _isInitialisationInProgress =
    currentView === "initializing" ||
    stripeCustomerAPIInProgress ||
    (!isLocalDBReady && currentView !== "auth");
  useEffect(() => {
    Hub.listen("datastore", async (hubData) => {
      const { event, data } = hubData.payload;
      console.log("__", event);
      if (event === "subscriptionsEstablished") {
        setSubscriptionsEstablished(true);
      }
      if (event === "networkStatus") {
        isConnectedToCloud(data.active); //@todo: use makeVar
      }
      if (event === "ready") {
        console.log("Datastore is ready... ");
        setLocalDBReady(true);
      }
    });
  }, []);
  useEffect(() => {
    Hub.listen("auth", async (hubData) => {
      const { event, data } = hubData.payload;
      if (event === "signIn" && data?.username?.startsWith("google_")) {
        window.location.reload();
      } else if (
        event === "signIn_failure" &&
        data?.message === "invalid_grant"
      ) {
        setFederatedSignInProgress(true);
        console.log("__auth__attempt_init");
        const result = await attemptSignInWithGoogle();
      }
    });
  }, []);
  const fetchDataForSelectiveSync = async (ownerId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const userData = await API.graphql(
          graphqlOperation(usersByOwner, {
            owner: ownerId,
          })
        );
        const userID = userData?.data?.usersByOwner?.items?.[0]?.id;
        const workspaceData = await API.graphql(
          graphqlOperation(workspacesByUser, {
            userID,
          })
        );
        const workspaces = workspaceData?.data?.workspacesByUser?.items
          ?.filter((u) => u?._deleted != true)
          ?.map((u) => u.workspaceID);
        currentUserId(userID);
        setServerWorkspaces(workspaces);
        setDataSyncReady(true);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const fetchDataForConfig = async () => {
    const result = await API.graphql(
      graphqlOperation(listSubscriptionPricingPlans, {})
    );
    const config = result?.data?.listSubscriptionPricingPlans;
    reservedConfigs({ ...reservedConfigs(), plans: config?.items });
  };

  const fetchDataForWorkspaceBaseConfig = async () => {
    const result = await API.graphql(
      graphqlOperation(listSubscriptionBaseConfigs, {})
    );
    const config = result?.data?.listSubscriptionBaseConfigs;
    reservedConfigs({
      ...reservedConfigs(),
      workspaceBaseConfigs: config?.items,
    });
  };

  //

  useEffect(() => {
    if (currentView === "auth") setLocalDBReady(false);
  }, [currentView]);

  return (
    <AppRootContext.Provider
      value={{
        setCurrentView,
      }}
    >
      <AppTheme.ThemeManager>
        <AppToast.ToastProvider
          {...AppConfig.notificationConfig}
          // offset for both top and bottom toasts..
        >
          <AppStateManager.AppProvider
            initialState={initialState}
            appReducer={appReducer}
            envConfig={envConfig}
          >
            {/* This theme provider is required for emotion. It conflicts with the AppTheme. */}
            {/*<ThemeProvider theme={generateLightTheme.theme}>*/}
            <AppUser.UserProvider>
              {_isInitialisationInProgress && <ToothFairyLoadingPlaceholder />}
              {currentView === "auth" && (
                <Suspense fallback={<div>Loading...</div>}>
                  <AuthScreen
                    isFederatedSignInProgress={isFederatedSignInProgress}
                    setCurrentView={setCurrentView}
                    onCognitoUserAuthenticated={onCognitoUserAuthenticated}
                    setFederatedSignInProgress={setFederatedSignInProgress}
                  />
                </Suspense>
              )}
              {currentView === "mainNav" &&
                authUserSub &&
                serverWorkspaces &&
                dataSyncReady &&
                stripeCustomerData && (
                  <MainViewContainer
                    _isInitialisationInProgress={_isInitialisationInProgress}
                    stripeCustomerData={stripeCustomerData}
                    isLocalDBReady={isLocalDBReady}
                    authUserSub={authUserSub}
                    isConnectedToCloud={isConnectedToCloud()}
                    serverWorkspaces={serverWorkspaces}
                    currentView={currentView}
                  >
                    <Routes
                      setCurrentView={setCurrentView}
                      currentView={currentView}
                    />
                  </MainViewContainer>
                )}
            </AppUser.UserProvider>
            {/*</ThemeProvider>*/}
          </AppStateManager.AppProvider>
        </AppToast.ToastProvider>
      </AppTheme.ThemeManager>
    </AppRootContext.Provider>
  );
};

//export default withAuthenticator(App);
export default App;
