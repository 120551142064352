import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppSelection from "@toothfairy/shared-ui/AppSelection";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import entitiesHelper from "../../../API/EntitiesAI/entitiesHelper";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../Constants";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import AdminInfoLeaf from "../../Admin/Components/SubComponents/AdminInfoLeaf";
import AppToast from "@toothfairy/shared-ui/AppToast";
import { useTranslation } from "react-i18next";
import Utility from "../../../Utility";
import { isLargeScreen } from "../../MainViewContainer";
import Agents from "../../../API/Agents";
import sitesHelper from "../../../API/Sites/sitesHelper";
import { Agent, Site } from "../../../models";
import { DataStore } from "@aws-amplify/datastore";
const SettingsAI = ({
  sectionTitle,
  initialValues = { label: "" },
  settingType,
  isEdit,
  opt,
  setDisplayedView,
  backTo,
  onEntityCreated,
}) => {
  const generateName = () => {
    switch (settingType) {
      case "intent":
        return "Intent";
      case "ner":
        return "NER";
      case "topic":
        return "Topic";
      default:
        return "";
    }
  };
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const { currentTheme } = AppTheme.useTheme();
  const numberOfColumns = 6;
  const entityName = generateName();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { getWorkspaceBaseModel, getPlanConfig, getActiveWorkspace } =
    useWorkspaces();
  const validationSchema = AppForms.yup.object().shape({
    label: AppForms.yup.string().required().label(`${entityName} name`),
    backgroundColor: AppForms.yup.string().nullable().required(),
    emoji: AppForms.yup.string().nullable(),
    parentEntity: AppForms.yup.string().nullable(),
  });
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  const handleDeletion = async () => {
    try {
      const result = await entitiesHelper.deleteEntity(opt.id);
      for (let i = 0; i < state?.agents?.length; i++) {
        if (
          state?.agents[i]?.allowedTopics?.map((u) => u?.id)?.includes(opt.id)
        ) {
          const newTopics = state?.agents[i]?.allowedTopics?.filter(
            (u) => u?.id !== opt.id
          );
          const newAgent = await Agents.updateAgent(state?.agents[i]?.id, [
            {
              property: "allowedTopics",
              value: newTopics,
            },
          ]);
          setTimeout(() => {}, 500);
        }
      }
      const resultAgents = await DataStore.query(Agent, (e) =>
        e.workspaceID("eq", getActiveWorkspace()?.id)
      );
      dispatch("SET_AGENTS", resultAgents);
      for (let i = 0; i < state?.sites?.length; i++) {
        if (state?.sites[i]?.topics?.includes(opt.id)) {
          const newTopics = state?.sites[i]?.topics?.filter(
            (u) => u !== opt.id
          );
          const newSite = await sitesHelper.updateSite(state?.sites[i]?.id, [
            {
              property: "topics",
              value: newTopics,
            },
          ]);
          setTimeout(() => {}, 500);
        }
      }
      const resultSites = await DataStore.query(Site, (e) =>
        e.workspaceID("eq", getActiveWorkspace()?.id)
      );
      dispatch("SET_SITES", resultSites);
      state.aiEntitiesConfig.splice(identifyOptByID(opt.id), 1);

      dispatch("SET_AI_ENTITIES_CONFIG", state.aiEntitiesConfig);
      navigateBack();
      AppToasty.show(
        `${Utility.upperCaseFirstLetter(opt?.type)} ${t("deleted")}`,
        {
          type: "success",
        }
      );
    } catch (error) {
      console.error("entityDeleteErrorCaption", error);
      AppToasty.show(
        `${t("entityDeleteErrorCaption") + " "} ${Utility.upperCaseFirstLetter(
          opt?.type
        )}`,
        {
          type: "danger",
        }
      );
    }
  };
  const navigateBack = () => {
    setDisplayedView(backTo);
  };
  const identifyOptByID = (id) => {
    return state.aiEntitiesConfig.map((u) => u.id).indexOf(id);
  };
  const handleEntitySave = async (v) => {
    try {
      let result;
      const payload = [
        {
          property: "label",
          value: v.label,
        },
        {
          property: "emoji",
          value: v.emoji || "",
        },
        {
          property: "backgroundColor",
          value: v.backgroundColor || "#fff",
        },
        {
          property: "parentEntity",
          value: v.parentEntity,
        },
        {
          property: "description",
          value: v.description || "",
        },
      ];
      if (isEdit) {
        result = await entitiesHelper.updateEntity(opt.id, payload);
        state.aiEntitiesConfig[identifyOptByID(result.id)] = result;
      } else {
        result = await entitiesHelper.createEntity({
          label: v.label,
          emoji: v?.emoji,
          backgroundColor: v?.backgroundColor,
          description: v?.description,
          parentEntity: v.parentEntity,
          type: settingType,
          workspaceID: state.activeWorkspaceID,
        });
        state.aiEntitiesConfig.push(result);
      }
      dispatch("SET_AI_ENTITIES_CONFIG", state.aiEntitiesConfig);
      backTo && navigateBack();
      onEntityCreated && onEntityCreated("success");
    } catch (error) {
      console.error(error);
      onEntityCreated && onEntityCreated("error");
    }
  };

  const _view = useMemo(
    () => (
      <AppFadeView
        style={{
          horizontalMargin: 10,
          paddingHorizontal: 20,
          minWidth: isLargeScreen() ? 450 : 300,
          paddingBottom: 10,
          // height: "90%",
          overflow: "auto",
        }}
      >
        {backTo && (
          <AppMenu.DefaultBackOptionComponent
            navigateBack={navigateBack}
            sectionTitle={generateName()?.toUpperCase() + ": " + sectionTitle}
            isClickable
            isBackIconDisplayed
          />
        )}
        {isEdit && (
          <AdminInfoLeaf
            textContainer={{
              marginLeft: 5,
              marginTop: 10,
              marginBottom: 0,
            }}
            wrapperStyle={{
              marginBottom: 10,
            }}
            titleStyle={{
              marginTop: 0,
              marginLeft: 20,
            }}
            title={"ID"}
            value={opt?.id}
            width={420}
          />
        )}

        <AppForms.AppForm
          initialValues={initialValues}
          onSubmit={handleEntitySave}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {
            <>
              <AppForms.AppFormField
                showTextInputLabel
                isMandatory
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                label={`Name`}
                placeholder={`Name`}
                autoCapitalise
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="label"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                label={`Description`}
                placeholder={`Description`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="description"
              />
              <AppForms.AppFormPicker
                showTextInputLabel
                isMandatory
                wrapperInputStyle={{
                  paddingVertical: 10,
                }}
                label={`Colour`}
                placeholder={`Colour`}
                autoCapitalize="none"
                keyboardtype="default"
                textContextType="backgroundColor"
                autoCorrect={false}
                name="backgroundColor"
                modalBackdrop={currentTheme?.theme?.softBackgroundColor}
                PickerItemComponent={AppSelection.AppColoursSelectionComponent}
                options={AppUtilities.availableColours}
                numberOfColumns={numberOfColumns}
                optionLabel={null}
                style={{ fontSize: currentTheme?.theme?.small_font_size }}
                title={"Choose a colour"}
              />
              {settingType === "intent" &&
                state.workspaces.filter(
                  (w) => w.id === state.activeWorkspaceID
                )[0].type === "hierarchical" && (
                  <AppForms.AppFormPicker
                    showTextInputLabel
                    label="Set parent intent"
                    name={"parentEntity"}
                    numberOfColumns={1}
                    optionKey="id"
                    optionLabel="name"
                    options={{ id: "dd", name: "My parent intent" }}
                    placeholder={"No parent set"}
                    textStyle={{
                      fontSize: currentTheme?.theme?.small_font_size,
                    }}
                    title={"Choose a parent intent"}
                    modalBackdrop={currentTheme?.theme?.softBackgroundColor}
                  />
                )}
              {(settingType === "ner" || settingType === "topic") && (
                <AppForms.AppFormPicker
                  showTextInputLabel
                  label={`Icon`}
                  placeholder={`Icon`}
                  pickerType="emoji"
                  options={AppUtilities.availableEmojis}
                  itemFontSize={32}
                  numberOfColumns={numberOfColumns}
                  autoCapitalize="none"
                  keyboardtype="default"
                  textContextType="emoji"
                  autoCorrect={false}
                  name="emoji"
                  title={"Choose an icon"}
                  modalBackdrop={currentTheme?.theme?.softBackgroundColor}
                />
              )}
            </>
          }
          <View style={{ alignItems: "center" }}>
            <AppForms.AppSubmitButton
              title={_title()}
              awesomeIconColor={currentTheme?.theme?.white}
              backgroundColor={currentTheme?.theme?.primary}
              btnContentStyle={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
              style={[
                {
                  height: Constants.appButtonHeight,
                  width: "auto",
                  shadowColor: currentTheme?.theme?.transparent,
                  marginHorizontal: 10,
                },
              ]}
              btnLabelStyle={{ fontSize: 16 }}
              wrapperStyle={[
                {
                  width: "fit-content",
                  minWidth: 150,
                  marginTop: 20,
                  height: Constants.appButtonHeight,
                },
              ]}
              // backgroundColor={currentTheme?.theme?.green}

              // awesomeIcon={AppAssets.icons.add_icon}
              // awesomeIconColor={currentTheme?.theme?.white}
            />
            {isEdit && (
              <ToothFairyButton
                title={"Delete"}
                onPress={handleDeletion}
                isDeletion
                isDisabled={
                  settingType === "intent" && getWorkspaceBaseModel() !== "none"
                }
                wrapperStyle={{
                  marginTop: 30,
                  minWidth: 150,
                  height: Constants.appButtonHeight,
                }}
              />
            )}
          </View>
        </AppForms.AppForm>
      </AppFadeView>
    ),
    [
      state.activeWorkspaceID,
      initialValues,
      state?.sites?.length,
      state?.agents?.length,
    ]
  );

  if (
    (settingType === "ner" || settingType === "intent") &&
    !getPlanConfig()?.isSentimentReviewerEnabled
  )
    return (
      <AppPlaceholderView
        wrapperStyle={{
          width: "100%",
          height: "100%",
          marginVertical: 20,
          marginHorizontal: "auto",
          flex: 1,
        }}
        size={80}
        icon={AppAssets.icons.orbit}
        mainCaption="EmptyPlaceholder"
        subCaption="SettingsSubCaption"
      />
    );
  else return _view;
};

export default SettingsAI;
