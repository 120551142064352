/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateDocumentEmbeddingStatus = /* GraphQL */ `
  mutation UpdateDocumentEmbeddingStatus(
    $id: String!
    $embedding_status: String!
    $completion_percentage: Float
  ) {
    updateDocumentEmbeddingStatus(
      id: $id
      embedding_status: $embedding_status
      completion_percentage: $completion_percentage
    ) {
      id
      embedding_status
      completion_percentage
    }
  }
`;
export const createAppConfig = /* GraphQL */ `
  mutation CreateAppConfig(
    $input: CreateAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    createAppConfig(input: $input, condition: $condition) {
      id
      saveTimer
      screenResponsiveWidth
      mobileResponsiveWidth
      veryLargeScreenResponsiveWidth
      chartMaxHeight
      inactiveTimeout
      sideMenuWidth
      availableLanguages
      baseIntents
      baseEntities
      baseTopics
      supportDomain
      metadata
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAppConfig = /* GraphQL */ `
  mutation UpdateAppConfig(
    $input: UpdateAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    updateAppConfig(input: $input, condition: $condition) {
      id
      saveTimer
      screenResponsiveWidth
      mobileResponsiveWidth
      veryLargeScreenResponsiveWidth
      chartMaxHeight
      inactiveTimeout
      sideMenuWidth
      availableLanguages
      baseIntents
      baseEntities
      baseTopics
      supportDomain
      metadata
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAppConfig = /* GraphQL */ `
  mutation DeleteAppConfig(
    $input: DeleteAppConfigInput!
    $condition: ModelAppConfigConditionInput
  ) {
    deleteAppConfig(input: $input, condition: $condition) {
      id
      saveTimer
      screenResponsiveWidth
      mobileResponsiveWidth
      veryLargeScreenResponsiveWidth
      chartMaxHeight
      inactiveTimeout
      sideMenuWidth
      availableLanguages
      baseIntents
      baseEntities
      baseTopics
      supportDomain
      metadata
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      username
      user_email
      user_email_verified
      user_phone_number
      timezone
      language
      isSentenceSplitEnabled
      isImplicitSelfEnabled
      replyLanguage
      theme
      user_phone_number_verified
      userInstructions
      userMemories
      isOnboarded
      userHelperPreferences
      type
      workspaces {
        items {
          id
          owner
          title
          visibility
          userID
          isMed
          type
          usagePlanID
          workspaceTokenID
          workspaceToken
          subscriptionType
          subscriptionId
          subscriptionStatus
          enableExperimentalFeatures
          utilityEndpoint
          utilityUrl
          utilityUrlStatus
          utilityUrlType
          utilityBaseModel
          utilityProvider
          codingEndpoint
          codingUrl
          codingUrlStatus
          codingUrlType
          codingBaseModel
          codingProvider
          generalPurposeEndpoint
          generalPurposeUrl
          generalPurposeUrlStatus
          generalPurposeUrlType
          generalPurposeBaseModel
          generalPurposeProvider
          qaEnpoint
          qaUrl
          qaUrlStatus
          qaUrlType
          qaBaseModel
          qaProvider
          cnEndpoint
          cnUrl
          cnUrlStatus
          cnUrlType
          cnBaseModel
          cnProvider
          gnEnpoint
          gnUrl
          gnUrlStatus
          gnUrlType
          gnBaseModel
          gnProvider
          nlpEnpoint
          nlpUrl
          nlpUrlStatus
          nlpUrlType
          nlpBaseModel
          nlpProvider
          embeddingEndpoint
          embeddingUrl
          embeddingUrlStatus
          embeddingUrlType
          embeddingBaseModel
          embeddingProvider
          chartingEndpoint
          chartingUrl
          chartingUrlStatus
          chartingUrlType
          chartingBaseModel
          chartingProvider
          imagingEndpoint
          imagingUrl
          imagingUrlStatus
          imagingUrlType
          imagingBaseModel
          imagingProvider
          reasoningEndpoint
          reasoningUrl
          reasoningUrlStatus
          reasoningUrlType
          reasoningBaseModel
          reasoningProvider
          billingStatus
          lastBillingDate
          lastConsumptionNotificationDate
          activeUntil
          paymentFailed
          bulkImportAllowed
          lastPaymentAttempt
          targetSubscriptionType
          lastChangeSubscriptionDate
          maxUsers
          baseModel
          domain
          customImageRecognitionModel
          customVectorDB
          disabled
          users
          workspaceCreationDate
          workspaceModelReady
          enableDeleteFunctionInAgents
          enableUpdateFunctionInAgents
          enableCustomFunctionInAgents
          defaultChatAgent
          defaultBusinessAnalystAgent
          defaultContentCreatorAgent
          isSentimentReviewerEnabled
          isExtendedContentCreatorEnabled
          isExtendedBusinessAnalystReportEnabled
          customIndexedDBendpoint
          createdBy
          updatedBy
          federationMetadataUrl
          federationMetadataUrlStatus
          federationMetadataUrlType
          ssoType
          ssoDomain
          ssoProviderName
          isMultiLanguageEnabled
          customChartingConfig
          twilioConfig
          emailConfig
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cognitoUsername
      stripeCustomerId
      stripePaymentIntentId
      maximumNumberOfWorkspaces
      workspacesAccessLevel {
        items {
          id
          workspaceID
          userID
          userType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      username
      user_email
      user_email_verified
      user_phone_number
      timezone
      language
      isSentenceSplitEnabled
      isImplicitSelfEnabled
      replyLanguage
      theme
      user_phone_number_verified
      userInstructions
      userMemories
      isOnboarded
      userHelperPreferences
      type
      workspaces {
        items {
          id
          owner
          title
          visibility
          userID
          isMed
          type
          usagePlanID
          workspaceTokenID
          workspaceToken
          subscriptionType
          subscriptionId
          subscriptionStatus
          enableExperimentalFeatures
          utilityEndpoint
          utilityUrl
          utilityUrlStatus
          utilityUrlType
          utilityBaseModel
          utilityProvider
          codingEndpoint
          codingUrl
          codingUrlStatus
          codingUrlType
          codingBaseModel
          codingProvider
          generalPurposeEndpoint
          generalPurposeUrl
          generalPurposeUrlStatus
          generalPurposeUrlType
          generalPurposeBaseModel
          generalPurposeProvider
          qaEnpoint
          qaUrl
          qaUrlStatus
          qaUrlType
          qaBaseModel
          qaProvider
          cnEndpoint
          cnUrl
          cnUrlStatus
          cnUrlType
          cnBaseModel
          cnProvider
          gnEnpoint
          gnUrl
          gnUrlStatus
          gnUrlType
          gnBaseModel
          gnProvider
          nlpEnpoint
          nlpUrl
          nlpUrlStatus
          nlpUrlType
          nlpBaseModel
          nlpProvider
          embeddingEndpoint
          embeddingUrl
          embeddingUrlStatus
          embeddingUrlType
          embeddingBaseModel
          embeddingProvider
          chartingEndpoint
          chartingUrl
          chartingUrlStatus
          chartingUrlType
          chartingBaseModel
          chartingProvider
          imagingEndpoint
          imagingUrl
          imagingUrlStatus
          imagingUrlType
          imagingBaseModel
          imagingProvider
          reasoningEndpoint
          reasoningUrl
          reasoningUrlStatus
          reasoningUrlType
          reasoningBaseModel
          reasoningProvider
          billingStatus
          lastBillingDate
          lastConsumptionNotificationDate
          activeUntil
          paymentFailed
          bulkImportAllowed
          lastPaymentAttempt
          targetSubscriptionType
          lastChangeSubscriptionDate
          maxUsers
          baseModel
          domain
          customImageRecognitionModel
          customVectorDB
          disabled
          users
          workspaceCreationDate
          workspaceModelReady
          enableDeleteFunctionInAgents
          enableUpdateFunctionInAgents
          enableCustomFunctionInAgents
          defaultChatAgent
          defaultBusinessAnalystAgent
          defaultContentCreatorAgent
          isSentimentReviewerEnabled
          isExtendedContentCreatorEnabled
          isExtendedBusinessAnalystReportEnabled
          customIndexedDBendpoint
          createdBy
          updatedBy
          federationMetadataUrl
          federationMetadataUrlStatus
          federationMetadataUrlType
          ssoType
          ssoDomain
          ssoProviderName
          isMultiLanguageEnabled
          customChartingConfig
          twilioConfig
          emailConfig
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cognitoUsername
      stripeCustomerId
      stripePaymentIntentId
      maximumNumberOfWorkspaces
      workspacesAccessLevel {
        items {
          id
          workspaceID
          userID
          userType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      username
      user_email
      user_email_verified
      user_phone_number
      timezone
      language
      isSentenceSplitEnabled
      isImplicitSelfEnabled
      replyLanguage
      theme
      user_phone_number_verified
      userInstructions
      userMemories
      isOnboarded
      userHelperPreferences
      type
      workspaces {
        items {
          id
          owner
          title
          visibility
          userID
          isMed
          type
          usagePlanID
          workspaceTokenID
          workspaceToken
          subscriptionType
          subscriptionId
          subscriptionStatus
          enableExperimentalFeatures
          utilityEndpoint
          utilityUrl
          utilityUrlStatus
          utilityUrlType
          utilityBaseModel
          utilityProvider
          codingEndpoint
          codingUrl
          codingUrlStatus
          codingUrlType
          codingBaseModel
          codingProvider
          generalPurposeEndpoint
          generalPurposeUrl
          generalPurposeUrlStatus
          generalPurposeUrlType
          generalPurposeBaseModel
          generalPurposeProvider
          qaEnpoint
          qaUrl
          qaUrlStatus
          qaUrlType
          qaBaseModel
          qaProvider
          cnEndpoint
          cnUrl
          cnUrlStatus
          cnUrlType
          cnBaseModel
          cnProvider
          gnEnpoint
          gnUrl
          gnUrlStatus
          gnUrlType
          gnBaseModel
          gnProvider
          nlpEnpoint
          nlpUrl
          nlpUrlStatus
          nlpUrlType
          nlpBaseModel
          nlpProvider
          embeddingEndpoint
          embeddingUrl
          embeddingUrlStatus
          embeddingUrlType
          embeddingBaseModel
          embeddingProvider
          chartingEndpoint
          chartingUrl
          chartingUrlStatus
          chartingUrlType
          chartingBaseModel
          chartingProvider
          imagingEndpoint
          imagingUrl
          imagingUrlStatus
          imagingUrlType
          imagingBaseModel
          imagingProvider
          reasoningEndpoint
          reasoningUrl
          reasoningUrlStatus
          reasoningUrlType
          reasoningBaseModel
          reasoningProvider
          billingStatus
          lastBillingDate
          lastConsumptionNotificationDate
          activeUntil
          paymentFailed
          bulkImportAllowed
          lastPaymentAttempt
          targetSubscriptionType
          lastChangeSubscriptionDate
          maxUsers
          baseModel
          domain
          customImageRecognitionModel
          customVectorDB
          disabled
          users
          workspaceCreationDate
          workspaceModelReady
          enableDeleteFunctionInAgents
          enableUpdateFunctionInAgents
          enableCustomFunctionInAgents
          defaultChatAgent
          defaultBusinessAnalystAgent
          defaultContentCreatorAgent
          isSentimentReviewerEnabled
          isExtendedContentCreatorEnabled
          isExtendedBusinessAnalystReportEnabled
          customIndexedDBendpoint
          createdBy
          updatedBy
          federationMetadataUrl
          federationMetadataUrlStatus
          federationMetadataUrlType
          ssoType
          ssoDomain
          ssoProviderName
          isMultiLanguageEnabled
          customChartingConfig
          twilioConfig
          emailConfig
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cognitoUsername
      stripeCustomerId
      stripePaymentIntentId
      maximumNumberOfWorkspaces
      workspacesAccessLevel {
        items {
          id
          workspaceID
          userID
          userType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $input: CreateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    createWorkspace(input: $input, condition: $condition) {
      id
      owner
      title
      visibility
      userID
      isMed
      type
      usagePlanID
      workspaceTokenID
      workspaceToken
      subscriptionType
      subscriptionId
      subscriptionStatus
      enableExperimentalFeatures
      utilityEndpoint
      utilityUrl
      utilityUrlStatus
      utilityUrlType
      utilityBaseModel
      utilityProvider
      codingEndpoint
      codingUrl
      codingUrlStatus
      codingUrlType
      codingBaseModel
      codingProvider
      generalPurposeEndpoint
      generalPurposeUrl
      generalPurposeUrlStatus
      generalPurposeUrlType
      generalPurposeBaseModel
      generalPurposeProvider
      qaEnpoint
      qaUrl
      qaUrlStatus
      qaUrlType
      qaBaseModel
      qaProvider
      cnEndpoint
      cnUrl
      cnUrlStatus
      cnUrlType
      cnBaseModel
      cnProvider
      gnEnpoint
      gnUrl
      gnUrlStatus
      gnUrlType
      gnBaseModel
      gnProvider
      nlpEnpoint
      nlpUrl
      nlpUrlStatus
      nlpUrlType
      nlpBaseModel
      nlpProvider
      embeddingEndpoint
      embeddingUrl
      embeddingUrlStatus
      embeddingUrlType
      embeddingBaseModel
      embeddingProvider
      chartingEndpoint
      chartingUrl
      chartingUrlStatus
      chartingUrlType
      chartingBaseModel
      chartingProvider
      imagingEndpoint
      imagingUrl
      imagingUrlStatus
      imagingUrlType
      imagingBaseModel
      imagingProvider
      reasoningEndpoint
      reasoningUrl
      reasoningUrlStatus
      reasoningUrlType
      reasoningBaseModel
      reasoningProvider
      billingStatus
      lastBillingDate
      lastConsumptionNotificationDate
      activeUntil
      paymentFailed
      bulkImportAllowed
      lastPaymentAttempt
      targetSubscriptionType
      lastChangeSubscriptionDate
      maxUsers
      baseModel
      domain
      trainingConfig {
        trainingIterations
        trainingIterationsNLP
        trainingIterationsRC
        trainingEpochsNLP
        trainingEpochsRC
        trainingEpochsGN
        maxStepsNLP
        patienceNLP
        learningRateNLP
        learningRateRC
        testSizeNLP
        testSizeRC
        batchSizeNLP
        batchSizeRC
        datasetTestSizeThresholdNLP
        datasetTestSizeThresholdRC
        learningRateGN
        testSizeGN
        batchSizeGN
        datasetTestSizeThresholdGN
        enableManagedSpotTraining
        maxRuntimeWaitTime
        maxRuntime
        includeReadComprehensionDataInTraining
        generateDockerImageForRCengine
        generateDockerImageForNLPengine
        generateDockerImageForGNengine
        emailsListForTrainingConfirmation
        maxQueueWaitTime
      }
      parsingConfig {
        questionsLimit
        answerAsNER
        answerByParagraph
        uniqueAnswer
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        temperature
        maxTokens
        topP
        topK
        qaDocTopK
        frequencyPenalty
        presencePenalty
        qaTopP
        qaTopK
        qaMaxTokens
        qaTemperature
        qaFrequencyPenalty
        qaPresencePenalty
        qaReplyOutsidePassage
        qaAiKnowledge
        qaInhibitionPassage
        qaEnhancementPassage
        qaRole
        qaDefaultAnswer
        qaStopSequence
        stopSequence
        stopPrompt
        qaExamples {
          question
          answer
        }
        qaBlender
        agcEnabled
      }
      notificationConfig {
        emailsList
        emailOnTrainingCompleted
        emailOnBatchCompleted
        emailOnBatchFailed
        emailOnBatchStart
        emailOnTrainingFailed
        emailOnTrainingStart
        notifyAllUsersInWorkspace
        notifyOnThreasholdConsumption
        notificationThresholdConsumption
      }
      embeddingConfig {
        maxChunkWords
        maxOverlapSentences
        chunkingStrategy
        imageExtractionStrategy
        minImageWidth
        minImageHeight
        aspectRatioMin
        aspectRatioMax
      }
      customGenerationConfig {
        styles
        domains
        scopes
        prompts {
          type
          label
          promptLength
          promptPlaceholder
          interpolationString
        }
        hasTopicsContext
        topics
        docTopK
        topK
        blender
        mode
        aiKnowledge
        compressor
      }
      customImageRecognitionModel
      customVectorDB
      workspaceAgents {
        items {
          id
          type
          label
          description
          interpolationString
          emoji
          color
          darkColor
          icon
          largeLogo
          icoUrl
          logoBackground
          darkLogoBackground
          messageOnLaunch
          messagesOnLaunch
          rotateMessagesOnLaunch
          rotateMessagesOnLaunchInterval
          placeholderMessageOnLoading
          customGreetingInstructions
          customConclusionInstructions
          customChartingInstructions
          customSummarisationInstructions
          customImagesInstructions
          customToolingInstructions
          placeholderInputMessage
          openByDefault
          disclaimer
          showDocumentsReferences
          isDefault
          hasMemory
          hasImages
          promptTopKeywords
          keyWordsForKnowledgeBase
          hasTopicsContext
          hasFunctions
          advancedLanguageDetection
          allowedTopics
          defaultAnswer
          noKnowledgeDefaultAnswer
          quickQuestions
          recencyImportance
          maxRecencyIncrease
          recencyTopKIncreaseFactor
          stopSequence
          enhancementPassage
          inhibitionPassage
          pertinencePassage
          goals
          qaUrl
          topK
          mode
          charting
          summarisation
          compressor
          docTopK
          minRetrievalScore
          timeSignificance
          hasModeration
          customModerations
          maxHistory
          maxTokens
          topicEnhancer
          maxTopics
          blender
          temperature
          version
          subject
          workspaceID
          createdBy
          updatedBy
          agentFunctions
          isGlobal
          plainTextOutput
          chainOfThoughts
          treeOfThoughts
          extendedOutput
          agentType
          agentsPool
          maxPlanningSteps
          planningInstructions
          plannerExecutionAttempts
          plannerRequiresApprovalForPlanExecution
          reviewInstructions
          showReferencesToInternalDocs
          preventWidgetUsage
          allowFeedback
          generateExtendedReport
          showTimeForResponse
          showDetectedLanguage
          isMultiLanguageEnabled
          hasMath
          hasCode
          hasNER
          restrictedAccess
          restrictedAccessUsers
          splashTitle
          showSplashWhenEmpty
          llmEndpoint
          llmUrl
          llmUrlStatus
          llmUrlType
          llmBaseModel
          llmProvider
          functionCallingProvider
          functionCallingModel
          showAgentName
          allowImagesUpload
          allowVideosUpload
          allowAudiosUpload
          allowDocsUpload
          allowImagesGeneration
          baseImageGenerationModel
          maxImagesGenerated
          advancedImageGeneration
          allowVideosGeneration
          allowAudiosGeneration
          voiceFileKey
          allowInternetSearch
          bannedDomains
          maxUrlsForInternetSearch
          allowDeepInternetSearch
          internetSearchModes
          internetSearchLocation
          autoVoiceMode
          keywordsWeightInRetrieval
          customCodeExecutionEnvironments
          phoneNumberAsSender
          whatsappNumberAsSender
          emailAsSender
          communicationServices
          channelCallbackDelay
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      workspaceCustomPrompts {
        items {
          id
          type
          scope
          style
          domain
          label
          promptLength
          promptPlaceholder
          interpolationString
          availableToAgents
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customQaConfig {
        roles {
          type
          label
          description
          interpolationString
          emoji
          color
          isDefault
          hasMemory
          hasTopicsContext
          allowedTopics
          defaultAnswer
          stopSequence
          enhancementPassage
          inhibitionPassage
          pertinencePassage
          goals
          qaUrl
          topK
          mode
          charting
          summarisation
          compressor
          docTopK
          maxHistory
          maxTokens
          topicEnhancer
          maxTopics
          blender
          temperature
          version
          subject
        }
      }
      aiConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      disabled
      subscriptionPricingPlan {
        items {
          id
          type
          subscriptionStatus
          isPredictionOnDemandGlobal
          isTrainingOnDemandGlobal
          predictionRequestsGlobal
          batchRequestsGlobal
          trainingMinutesGlobal
          baseModelGN
          baseModelQA
          baseModelNLP
          maxUsers
          agcEnabled
          isDownloadModelEnabled
          isDownloadDataEnabled
          isAPIAccessEnabled
          isBatchJobsEnabled
          isSentimentReviewerEnabled
          isBusinessAnalystEnabled
          isContentCreatorEnabled
          isPriorityTrainingEnabled
          isPredictionGPUAccelerationEnabledGlobal
          isPredictionGPUAccelerationEnabledRC
          isPredictionGPUAccelerationEnabledNLP
          maxMrcDocs
          accuracyModelEnabled
          isTrainingNLPEnabled
          isTrainingQAEnabled
          isTrainingGNEnabled
          isLongTermMemoryEnabled
          isFunctionsEnabled
          isDBFunctionsEnabled
          isHtmlFunctionsEnabled
          isSitesBindingEnabled
          isAgentsSecurityEnabled
          isWidgetChatsHistoryEnabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      folders {
        items {
          id
          owner
          name
          description
          emoji
          status
          parent
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      trainingLogs {
        items {
          id
          owner
          name
          description
          status
          trainingIterations
          lastActivation
          duration
          trainingMeta
          trainingConfig
          workspaceID
          type
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      entities {
        items {
          id
          owner
          label
          type
          description
          emoji
          parentEntity
          backgroundColor
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      appLogs {
        items {
          id
          owner
          type
          filename
          event
          status
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchJobs {
        items {
          id
          owner
          name
          description
          status
          duration
          batchMeta
          batchConfig
          batchOutput
          workspaceID
          type
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dictionaryItems {
        items {
          id
          owner
          sourceLanguage
          targetLanguage
          sourceText
          targetText
          workspaceID
          pos
          description
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      requestsLogs {
        items {
          id
          srtKey
          type
          status
          words
          tokens
          sentences
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      communicationServices {
        items {
          id
          workspaceID
          name
          description
          channel
          provider
          senderid
          fromNumber
          tokenSecret
          isActive
          config
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sites {
        items {
          id
          name
          description
          url
          workspaceID
          topics
          status
          lastValidationDate
          lastValidationRequested
          validationToken
          allowedPaths
          siteTree
          lastScraped
          skipOwnerValidation
          useProxy
          completion_percentage
          extractImages
          freeRoaming
          scrapingCycle
          imgRetrievalPrompt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      usersAccessLevel {
        items {
          id
          workspaceID
          userID
          userType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      workspaceCreationDate
      workspaceModelReady
      enableDeleteFunctionInAgents
      enableUpdateFunctionInAgents
      enableCustomFunctionInAgents
      defaultChatAgent
      defaultBusinessAnalystAgent
      defaultContentCreatorAgent
      isSentimentReviewerEnabled
      isExtendedContentCreatorEnabled
      isExtendedBusinessAnalystReportEnabled
      customIndexedDBendpoint
      createdBy
      updatedBy
      federationMetadataUrl
      federationMetadataUrlStatus
      federationMetadataUrlType
      ssoType
      ssoDomain
      ssoProviderName
      isMultiLanguageEnabled
      customChartingConfig
      twilioConfig
      emailConfig
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWorkspace = /* GraphQL */ `
  mutation UpdateWorkspace(
    $input: UpdateWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    updateWorkspace(input: $input, condition: $condition) {
      id
      owner
      title
      visibility
      userID
      isMed
      type
      usagePlanID
      workspaceTokenID
      workspaceToken
      subscriptionType
      subscriptionId
      subscriptionStatus
      enableExperimentalFeatures
      utilityEndpoint
      utilityUrl
      utilityUrlStatus
      utilityUrlType
      utilityBaseModel
      utilityProvider
      codingEndpoint
      codingUrl
      codingUrlStatus
      codingUrlType
      codingBaseModel
      codingProvider
      generalPurposeEndpoint
      generalPurposeUrl
      generalPurposeUrlStatus
      generalPurposeUrlType
      generalPurposeBaseModel
      generalPurposeProvider
      qaEnpoint
      qaUrl
      qaUrlStatus
      qaUrlType
      qaBaseModel
      qaProvider
      cnEndpoint
      cnUrl
      cnUrlStatus
      cnUrlType
      cnBaseModel
      cnProvider
      gnEnpoint
      gnUrl
      gnUrlStatus
      gnUrlType
      gnBaseModel
      gnProvider
      nlpEnpoint
      nlpUrl
      nlpUrlStatus
      nlpUrlType
      nlpBaseModel
      nlpProvider
      embeddingEndpoint
      embeddingUrl
      embeddingUrlStatus
      embeddingUrlType
      embeddingBaseModel
      embeddingProvider
      chartingEndpoint
      chartingUrl
      chartingUrlStatus
      chartingUrlType
      chartingBaseModel
      chartingProvider
      imagingEndpoint
      imagingUrl
      imagingUrlStatus
      imagingUrlType
      imagingBaseModel
      imagingProvider
      reasoningEndpoint
      reasoningUrl
      reasoningUrlStatus
      reasoningUrlType
      reasoningBaseModel
      reasoningProvider
      billingStatus
      lastBillingDate
      lastConsumptionNotificationDate
      activeUntil
      paymentFailed
      bulkImportAllowed
      lastPaymentAttempt
      targetSubscriptionType
      lastChangeSubscriptionDate
      maxUsers
      baseModel
      domain
      trainingConfig {
        trainingIterations
        trainingIterationsNLP
        trainingIterationsRC
        trainingEpochsNLP
        trainingEpochsRC
        trainingEpochsGN
        maxStepsNLP
        patienceNLP
        learningRateNLP
        learningRateRC
        testSizeNLP
        testSizeRC
        batchSizeNLP
        batchSizeRC
        datasetTestSizeThresholdNLP
        datasetTestSizeThresholdRC
        learningRateGN
        testSizeGN
        batchSizeGN
        datasetTestSizeThresholdGN
        enableManagedSpotTraining
        maxRuntimeWaitTime
        maxRuntime
        includeReadComprehensionDataInTraining
        generateDockerImageForRCengine
        generateDockerImageForNLPengine
        generateDockerImageForGNengine
        emailsListForTrainingConfirmation
        maxQueueWaitTime
      }
      parsingConfig {
        questionsLimit
        answerAsNER
        answerByParagraph
        uniqueAnswer
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        temperature
        maxTokens
        topP
        topK
        qaDocTopK
        frequencyPenalty
        presencePenalty
        qaTopP
        qaTopK
        qaMaxTokens
        qaTemperature
        qaFrequencyPenalty
        qaPresencePenalty
        qaReplyOutsidePassage
        qaAiKnowledge
        qaInhibitionPassage
        qaEnhancementPassage
        qaRole
        qaDefaultAnswer
        qaStopSequence
        stopSequence
        stopPrompt
        qaExamples {
          question
          answer
        }
        qaBlender
        agcEnabled
      }
      notificationConfig {
        emailsList
        emailOnTrainingCompleted
        emailOnBatchCompleted
        emailOnBatchFailed
        emailOnBatchStart
        emailOnTrainingFailed
        emailOnTrainingStart
        notifyAllUsersInWorkspace
        notifyOnThreasholdConsumption
        notificationThresholdConsumption
      }
      embeddingConfig {
        maxChunkWords
        maxOverlapSentences
        chunkingStrategy
        imageExtractionStrategy
        minImageWidth
        minImageHeight
        aspectRatioMin
        aspectRatioMax
      }
      customGenerationConfig {
        styles
        domains
        scopes
        prompts {
          type
          label
          promptLength
          promptPlaceholder
          interpolationString
        }
        hasTopicsContext
        topics
        docTopK
        topK
        blender
        mode
        aiKnowledge
        compressor
      }
      customImageRecognitionModel
      customVectorDB
      workspaceAgents {
        items {
          id
          type
          label
          description
          interpolationString
          emoji
          color
          darkColor
          icon
          largeLogo
          icoUrl
          logoBackground
          darkLogoBackground
          messageOnLaunch
          messagesOnLaunch
          rotateMessagesOnLaunch
          rotateMessagesOnLaunchInterval
          placeholderMessageOnLoading
          customGreetingInstructions
          customConclusionInstructions
          customChartingInstructions
          customSummarisationInstructions
          customImagesInstructions
          customToolingInstructions
          placeholderInputMessage
          openByDefault
          disclaimer
          showDocumentsReferences
          isDefault
          hasMemory
          hasImages
          promptTopKeywords
          keyWordsForKnowledgeBase
          hasTopicsContext
          hasFunctions
          advancedLanguageDetection
          allowedTopics
          defaultAnswer
          noKnowledgeDefaultAnswer
          quickQuestions
          recencyImportance
          maxRecencyIncrease
          recencyTopKIncreaseFactor
          stopSequence
          enhancementPassage
          inhibitionPassage
          pertinencePassage
          goals
          qaUrl
          topK
          mode
          charting
          summarisation
          compressor
          docTopK
          minRetrievalScore
          timeSignificance
          hasModeration
          customModerations
          maxHistory
          maxTokens
          topicEnhancer
          maxTopics
          blender
          temperature
          version
          subject
          workspaceID
          createdBy
          updatedBy
          agentFunctions
          isGlobal
          plainTextOutput
          chainOfThoughts
          treeOfThoughts
          extendedOutput
          agentType
          agentsPool
          maxPlanningSteps
          planningInstructions
          plannerExecutionAttempts
          plannerRequiresApprovalForPlanExecution
          reviewInstructions
          showReferencesToInternalDocs
          preventWidgetUsage
          allowFeedback
          generateExtendedReport
          showTimeForResponse
          showDetectedLanguage
          isMultiLanguageEnabled
          hasMath
          hasCode
          hasNER
          restrictedAccess
          restrictedAccessUsers
          splashTitle
          showSplashWhenEmpty
          llmEndpoint
          llmUrl
          llmUrlStatus
          llmUrlType
          llmBaseModel
          llmProvider
          functionCallingProvider
          functionCallingModel
          showAgentName
          allowImagesUpload
          allowVideosUpload
          allowAudiosUpload
          allowDocsUpload
          allowImagesGeneration
          baseImageGenerationModel
          maxImagesGenerated
          advancedImageGeneration
          allowVideosGeneration
          allowAudiosGeneration
          voiceFileKey
          allowInternetSearch
          bannedDomains
          maxUrlsForInternetSearch
          allowDeepInternetSearch
          internetSearchModes
          internetSearchLocation
          autoVoiceMode
          keywordsWeightInRetrieval
          customCodeExecutionEnvironments
          phoneNumberAsSender
          whatsappNumberAsSender
          emailAsSender
          communicationServices
          channelCallbackDelay
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      workspaceCustomPrompts {
        items {
          id
          type
          scope
          style
          domain
          label
          promptLength
          promptPlaceholder
          interpolationString
          availableToAgents
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customQaConfig {
        roles {
          type
          label
          description
          interpolationString
          emoji
          color
          isDefault
          hasMemory
          hasTopicsContext
          allowedTopics
          defaultAnswer
          stopSequence
          enhancementPassage
          inhibitionPassage
          pertinencePassage
          goals
          qaUrl
          topK
          mode
          charting
          summarisation
          compressor
          docTopK
          maxHistory
          maxTokens
          topicEnhancer
          maxTopics
          blender
          temperature
          version
          subject
        }
      }
      aiConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      disabled
      subscriptionPricingPlan {
        items {
          id
          type
          subscriptionStatus
          isPredictionOnDemandGlobal
          isTrainingOnDemandGlobal
          predictionRequestsGlobal
          batchRequestsGlobal
          trainingMinutesGlobal
          baseModelGN
          baseModelQA
          baseModelNLP
          maxUsers
          agcEnabled
          isDownloadModelEnabled
          isDownloadDataEnabled
          isAPIAccessEnabled
          isBatchJobsEnabled
          isSentimentReviewerEnabled
          isBusinessAnalystEnabled
          isContentCreatorEnabled
          isPriorityTrainingEnabled
          isPredictionGPUAccelerationEnabledGlobal
          isPredictionGPUAccelerationEnabledRC
          isPredictionGPUAccelerationEnabledNLP
          maxMrcDocs
          accuracyModelEnabled
          isTrainingNLPEnabled
          isTrainingQAEnabled
          isTrainingGNEnabled
          isLongTermMemoryEnabled
          isFunctionsEnabled
          isDBFunctionsEnabled
          isHtmlFunctionsEnabled
          isSitesBindingEnabled
          isAgentsSecurityEnabled
          isWidgetChatsHistoryEnabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      folders {
        items {
          id
          owner
          name
          description
          emoji
          status
          parent
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      trainingLogs {
        items {
          id
          owner
          name
          description
          status
          trainingIterations
          lastActivation
          duration
          trainingMeta
          trainingConfig
          workspaceID
          type
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      entities {
        items {
          id
          owner
          label
          type
          description
          emoji
          parentEntity
          backgroundColor
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      appLogs {
        items {
          id
          owner
          type
          filename
          event
          status
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchJobs {
        items {
          id
          owner
          name
          description
          status
          duration
          batchMeta
          batchConfig
          batchOutput
          workspaceID
          type
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dictionaryItems {
        items {
          id
          owner
          sourceLanguage
          targetLanguage
          sourceText
          targetText
          workspaceID
          pos
          description
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      requestsLogs {
        items {
          id
          srtKey
          type
          status
          words
          tokens
          sentences
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      communicationServices {
        items {
          id
          workspaceID
          name
          description
          channel
          provider
          senderid
          fromNumber
          tokenSecret
          isActive
          config
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sites {
        items {
          id
          name
          description
          url
          workspaceID
          topics
          status
          lastValidationDate
          lastValidationRequested
          validationToken
          allowedPaths
          siteTree
          lastScraped
          skipOwnerValidation
          useProxy
          completion_percentage
          extractImages
          freeRoaming
          scrapingCycle
          imgRetrievalPrompt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      usersAccessLevel {
        items {
          id
          workspaceID
          userID
          userType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      workspaceCreationDate
      workspaceModelReady
      enableDeleteFunctionInAgents
      enableUpdateFunctionInAgents
      enableCustomFunctionInAgents
      defaultChatAgent
      defaultBusinessAnalystAgent
      defaultContentCreatorAgent
      isSentimentReviewerEnabled
      isExtendedContentCreatorEnabled
      isExtendedBusinessAnalystReportEnabled
      customIndexedDBendpoint
      createdBy
      updatedBy
      federationMetadataUrl
      federationMetadataUrlStatus
      federationMetadataUrlType
      ssoType
      ssoDomain
      ssoProviderName
      isMultiLanguageEnabled
      customChartingConfig
      twilioConfig
      emailConfig
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWorkspace = /* GraphQL */ `
  mutation DeleteWorkspace(
    $input: DeleteWorkspaceInput!
    $condition: ModelWorkspaceConditionInput
  ) {
    deleteWorkspace(input: $input, condition: $condition) {
      id
      owner
      title
      visibility
      userID
      isMed
      type
      usagePlanID
      workspaceTokenID
      workspaceToken
      subscriptionType
      subscriptionId
      subscriptionStatus
      enableExperimentalFeatures
      utilityEndpoint
      utilityUrl
      utilityUrlStatus
      utilityUrlType
      utilityBaseModel
      utilityProvider
      codingEndpoint
      codingUrl
      codingUrlStatus
      codingUrlType
      codingBaseModel
      codingProvider
      generalPurposeEndpoint
      generalPurposeUrl
      generalPurposeUrlStatus
      generalPurposeUrlType
      generalPurposeBaseModel
      generalPurposeProvider
      qaEnpoint
      qaUrl
      qaUrlStatus
      qaUrlType
      qaBaseModel
      qaProvider
      cnEndpoint
      cnUrl
      cnUrlStatus
      cnUrlType
      cnBaseModel
      cnProvider
      gnEnpoint
      gnUrl
      gnUrlStatus
      gnUrlType
      gnBaseModel
      gnProvider
      nlpEnpoint
      nlpUrl
      nlpUrlStatus
      nlpUrlType
      nlpBaseModel
      nlpProvider
      embeddingEndpoint
      embeddingUrl
      embeddingUrlStatus
      embeddingUrlType
      embeddingBaseModel
      embeddingProvider
      chartingEndpoint
      chartingUrl
      chartingUrlStatus
      chartingUrlType
      chartingBaseModel
      chartingProvider
      imagingEndpoint
      imagingUrl
      imagingUrlStatus
      imagingUrlType
      imagingBaseModel
      imagingProvider
      reasoningEndpoint
      reasoningUrl
      reasoningUrlStatus
      reasoningUrlType
      reasoningBaseModel
      reasoningProvider
      billingStatus
      lastBillingDate
      lastConsumptionNotificationDate
      activeUntil
      paymentFailed
      bulkImportAllowed
      lastPaymentAttempt
      targetSubscriptionType
      lastChangeSubscriptionDate
      maxUsers
      baseModel
      domain
      trainingConfig {
        trainingIterations
        trainingIterationsNLP
        trainingIterationsRC
        trainingEpochsNLP
        trainingEpochsRC
        trainingEpochsGN
        maxStepsNLP
        patienceNLP
        learningRateNLP
        learningRateRC
        testSizeNLP
        testSizeRC
        batchSizeNLP
        batchSizeRC
        datasetTestSizeThresholdNLP
        datasetTestSizeThresholdRC
        learningRateGN
        testSizeGN
        batchSizeGN
        datasetTestSizeThresholdGN
        enableManagedSpotTraining
        maxRuntimeWaitTime
        maxRuntime
        includeReadComprehensionDataInTraining
        generateDockerImageForRCengine
        generateDockerImageForNLPengine
        generateDockerImageForGNengine
        emailsListForTrainingConfirmation
        maxQueueWaitTime
      }
      parsingConfig {
        questionsLimit
        answerAsNER
        answerByParagraph
        uniqueAnswer
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        temperature
        maxTokens
        topP
        topK
        qaDocTopK
        frequencyPenalty
        presencePenalty
        qaTopP
        qaTopK
        qaMaxTokens
        qaTemperature
        qaFrequencyPenalty
        qaPresencePenalty
        qaReplyOutsidePassage
        qaAiKnowledge
        qaInhibitionPassage
        qaEnhancementPassage
        qaRole
        qaDefaultAnswer
        qaStopSequence
        stopSequence
        stopPrompt
        qaExamples {
          question
          answer
        }
        qaBlender
        agcEnabled
      }
      notificationConfig {
        emailsList
        emailOnTrainingCompleted
        emailOnBatchCompleted
        emailOnBatchFailed
        emailOnBatchStart
        emailOnTrainingFailed
        emailOnTrainingStart
        notifyAllUsersInWorkspace
        notifyOnThreasholdConsumption
        notificationThresholdConsumption
      }
      embeddingConfig {
        maxChunkWords
        maxOverlapSentences
        chunkingStrategy
        imageExtractionStrategy
        minImageWidth
        minImageHeight
        aspectRatioMin
        aspectRatioMax
      }
      customGenerationConfig {
        styles
        domains
        scopes
        prompts {
          type
          label
          promptLength
          promptPlaceholder
          interpolationString
        }
        hasTopicsContext
        topics
        docTopK
        topK
        blender
        mode
        aiKnowledge
        compressor
      }
      customImageRecognitionModel
      customVectorDB
      workspaceAgents {
        items {
          id
          type
          label
          description
          interpolationString
          emoji
          color
          darkColor
          icon
          largeLogo
          icoUrl
          logoBackground
          darkLogoBackground
          messageOnLaunch
          messagesOnLaunch
          rotateMessagesOnLaunch
          rotateMessagesOnLaunchInterval
          placeholderMessageOnLoading
          customGreetingInstructions
          customConclusionInstructions
          customChartingInstructions
          customSummarisationInstructions
          customImagesInstructions
          customToolingInstructions
          placeholderInputMessage
          openByDefault
          disclaimer
          showDocumentsReferences
          isDefault
          hasMemory
          hasImages
          promptTopKeywords
          keyWordsForKnowledgeBase
          hasTopicsContext
          hasFunctions
          advancedLanguageDetection
          allowedTopics
          defaultAnswer
          noKnowledgeDefaultAnswer
          quickQuestions
          recencyImportance
          maxRecencyIncrease
          recencyTopKIncreaseFactor
          stopSequence
          enhancementPassage
          inhibitionPassage
          pertinencePassage
          goals
          qaUrl
          topK
          mode
          charting
          summarisation
          compressor
          docTopK
          minRetrievalScore
          timeSignificance
          hasModeration
          customModerations
          maxHistory
          maxTokens
          topicEnhancer
          maxTopics
          blender
          temperature
          version
          subject
          workspaceID
          createdBy
          updatedBy
          agentFunctions
          isGlobal
          plainTextOutput
          chainOfThoughts
          treeOfThoughts
          extendedOutput
          agentType
          agentsPool
          maxPlanningSteps
          planningInstructions
          plannerExecutionAttempts
          plannerRequiresApprovalForPlanExecution
          reviewInstructions
          showReferencesToInternalDocs
          preventWidgetUsage
          allowFeedback
          generateExtendedReport
          showTimeForResponse
          showDetectedLanguage
          isMultiLanguageEnabled
          hasMath
          hasCode
          hasNER
          restrictedAccess
          restrictedAccessUsers
          splashTitle
          showSplashWhenEmpty
          llmEndpoint
          llmUrl
          llmUrlStatus
          llmUrlType
          llmBaseModel
          llmProvider
          functionCallingProvider
          functionCallingModel
          showAgentName
          allowImagesUpload
          allowVideosUpload
          allowAudiosUpload
          allowDocsUpload
          allowImagesGeneration
          baseImageGenerationModel
          maxImagesGenerated
          advancedImageGeneration
          allowVideosGeneration
          allowAudiosGeneration
          voiceFileKey
          allowInternetSearch
          bannedDomains
          maxUrlsForInternetSearch
          allowDeepInternetSearch
          internetSearchModes
          internetSearchLocation
          autoVoiceMode
          keywordsWeightInRetrieval
          customCodeExecutionEnvironments
          phoneNumberAsSender
          whatsappNumberAsSender
          emailAsSender
          communicationServices
          channelCallbackDelay
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      workspaceCustomPrompts {
        items {
          id
          type
          scope
          style
          domain
          label
          promptLength
          promptPlaceholder
          interpolationString
          availableToAgents
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customQaConfig {
        roles {
          type
          label
          description
          interpolationString
          emoji
          color
          isDefault
          hasMemory
          hasTopicsContext
          allowedTopics
          defaultAnswer
          stopSequence
          enhancementPassage
          inhibitionPassage
          pertinencePassage
          goals
          qaUrl
          topK
          mode
          charting
          summarisation
          compressor
          docTopK
          maxHistory
          maxTokens
          topicEnhancer
          maxTopics
          blender
          temperature
          version
          subject
        }
      }
      aiConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      disabled
      subscriptionPricingPlan {
        items {
          id
          type
          subscriptionStatus
          isPredictionOnDemandGlobal
          isTrainingOnDemandGlobal
          predictionRequestsGlobal
          batchRequestsGlobal
          trainingMinutesGlobal
          baseModelGN
          baseModelQA
          baseModelNLP
          maxUsers
          agcEnabled
          isDownloadModelEnabled
          isDownloadDataEnabled
          isAPIAccessEnabled
          isBatchJobsEnabled
          isSentimentReviewerEnabled
          isBusinessAnalystEnabled
          isContentCreatorEnabled
          isPriorityTrainingEnabled
          isPredictionGPUAccelerationEnabledGlobal
          isPredictionGPUAccelerationEnabledRC
          isPredictionGPUAccelerationEnabledNLP
          maxMrcDocs
          accuracyModelEnabled
          isTrainingNLPEnabled
          isTrainingQAEnabled
          isTrainingGNEnabled
          isLongTermMemoryEnabled
          isFunctionsEnabled
          isDBFunctionsEnabled
          isHtmlFunctionsEnabled
          isSitesBindingEnabled
          isAgentsSecurityEnabled
          isWidgetChatsHistoryEnabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      folders {
        items {
          id
          owner
          name
          description
          emoji
          status
          parent
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      trainingLogs {
        items {
          id
          owner
          name
          description
          status
          trainingIterations
          lastActivation
          duration
          trainingMeta
          trainingConfig
          workspaceID
          type
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      entities {
        items {
          id
          owner
          label
          type
          description
          emoji
          parentEntity
          backgroundColor
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      appLogs {
        items {
          id
          owner
          type
          filename
          event
          status
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchJobs {
        items {
          id
          owner
          name
          description
          status
          duration
          batchMeta
          batchConfig
          batchOutput
          workspaceID
          type
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dictionaryItems {
        items {
          id
          owner
          sourceLanguage
          targetLanguage
          sourceText
          targetText
          workspaceID
          pos
          description
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      requestsLogs {
        items {
          id
          srtKey
          type
          status
          words
          tokens
          sentences
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      communicationServices {
        items {
          id
          workspaceID
          name
          description
          channel
          provider
          senderid
          fromNumber
          tokenSecret
          isActive
          config
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sites {
        items {
          id
          name
          description
          url
          workspaceID
          topics
          status
          lastValidationDate
          lastValidationRequested
          validationToken
          allowedPaths
          siteTree
          lastScraped
          skipOwnerValidation
          useProxy
          completion_percentage
          extractImages
          freeRoaming
          scrapingCycle
          imgRetrievalPrompt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      usersAccessLevel {
        items {
          id
          workspaceID
          userID
          userType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      workspaceCreationDate
      workspaceModelReady
      enableDeleteFunctionInAgents
      enableUpdateFunctionInAgents
      enableCustomFunctionInAgents
      defaultChatAgent
      defaultBusinessAnalystAgent
      defaultContentCreatorAgent
      isSentimentReviewerEnabled
      isExtendedContentCreatorEnabled
      isExtendedBusinessAnalystReportEnabled
      customIndexedDBendpoint
      createdBy
      updatedBy
      federationMetadataUrl
      federationMetadataUrlStatus
      federationMetadataUrlType
      ssoType
      ssoDomain
      ssoProviderName
      isMultiLanguageEnabled
      customChartingConfig
      twilioConfig
      emailConfig
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserWorkspaceType = /* GraphQL */ `
  mutation CreateUserWorkspaceType(
    $input: CreateUserWorkspaceTypeInput!
    $condition: ModelUserWorkspaceTypeConditionInput
  ) {
    createUserWorkspaceType(input: $input, condition: $condition) {
      id
      workspaceID
      userID
      userType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserWorkspaceType = /* GraphQL */ `
  mutation UpdateUserWorkspaceType(
    $input: UpdateUserWorkspaceTypeInput!
    $condition: ModelUserWorkspaceTypeConditionInput
  ) {
    updateUserWorkspaceType(input: $input, condition: $condition) {
      id
      workspaceID
      userID
      userType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserWorkspaceType = /* GraphQL */ `
  mutation DeleteUserWorkspaceType(
    $input: DeleteUserWorkspaceTypeInput!
    $condition: ModelUserWorkspaceTypeConditionInput
  ) {
    deleteUserWorkspaceType(input: $input, condition: $condition) {
      id
      workspaceID
      userID
      userType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAgent = /* GraphQL */ `
  mutation CreateAgent(
    $input: CreateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    createAgent(input: $input, condition: $condition) {
      id
      type
      label
      description
      interpolationString
      emoji
      color
      darkColor
      icon
      largeLogo
      icoUrl
      logoBackground
      darkLogoBackground
      messageOnLaunch
      messagesOnLaunch
      rotateMessagesOnLaunch
      rotateMessagesOnLaunchInterval
      placeholderMessageOnLoading
      customGreetingInstructions
      customConclusionInstructions
      customChartingInstructions
      customSummarisationInstructions
      customImagesInstructions
      customToolingInstructions
      placeholderInputMessage
      openByDefault
      disclaimer
      showDocumentsReferences
      isDefault
      hasMemory
      hasImages
      promptTopKeywords
      keyWordsForKnowledgeBase
      hasTopicsContext
      hasFunctions
      advancedLanguageDetection
      allowedTopics
      defaultAnswer
      noKnowledgeDefaultAnswer
      quickQuestions
      recencyImportance
      maxRecencyIncrease
      recencyTopKIncreaseFactor
      stopSequence
      examples {
        question
        answer
      }
      enhancementPassage
      inhibitionPassage
      pertinencePassage
      goals
      qaUrl
      topK
      mode
      charting
      summarisation
      compressor
      docTopK
      minRetrievalScore
      timeSignificance
      hasModeration
      customModerations
      maxHistory
      maxTokens
      topicEnhancer
      maxTopics
      blender
      temperature
      version
      subject
      workspaceID
      createdBy
      updatedBy
      agentFunctions
      isGlobal
      plainTextOutput
      chainOfThoughts
      treeOfThoughts
      extendedOutput
      agentType
      agentsPool
      maxPlanningSteps
      planningInstructions
      plannerExecutionAttempts
      plannerRequiresApprovalForPlanExecution
      reviewInstructions
      showReferencesToInternalDocs
      preventWidgetUsage
      allowFeedback
      generateExtendedReport
      showTimeForResponse
      showDetectedLanguage
      isMultiLanguageEnabled
      hasMath
      hasCode
      hasNER
      restrictedAccess
      restrictedAccessUsers
      splashTitle
      showSplashWhenEmpty
      llmEndpoint
      llmUrl
      llmUrlStatus
      llmUrlType
      llmBaseModel
      llmProvider
      functionCallingProvider
      functionCallingModel
      showAgentName
      allowImagesUpload
      allowVideosUpload
      allowAudiosUpload
      allowDocsUpload
      allowImagesGeneration
      baseImageGenerationModel
      maxImagesGenerated
      advancedImageGeneration
      allowVideosGeneration
      allowAudiosGeneration
      voiceFileKey
      allowInternetSearch
      bannedDomains
      maxUrlsForInternetSearch
      allowDeepInternetSearch
      internetSearchModes
      internetSearchLocation
      autoVoiceMode
      keywordsWeightInRetrieval
      customCodeExecutionEnvironments
      phoneNumberAsSender
      whatsappNumberAsSender
      emailAsSender
      communicationServices
      channelCallbackDelay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent(
    $input: UpdateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    updateAgent(input: $input, condition: $condition) {
      id
      type
      label
      description
      interpolationString
      emoji
      color
      darkColor
      icon
      largeLogo
      icoUrl
      logoBackground
      darkLogoBackground
      messageOnLaunch
      messagesOnLaunch
      rotateMessagesOnLaunch
      rotateMessagesOnLaunchInterval
      placeholderMessageOnLoading
      customGreetingInstructions
      customConclusionInstructions
      customChartingInstructions
      customSummarisationInstructions
      customImagesInstructions
      customToolingInstructions
      placeholderInputMessage
      openByDefault
      disclaimer
      showDocumentsReferences
      isDefault
      hasMemory
      hasImages
      promptTopKeywords
      keyWordsForKnowledgeBase
      hasTopicsContext
      hasFunctions
      advancedLanguageDetection
      allowedTopics
      defaultAnswer
      noKnowledgeDefaultAnswer
      quickQuestions
      recencyImportance
      maxRecencyIncrease
      recencyTopKIncreaseFactor
      stopSequence
      examples {
        question
        answer
      }
      enhancementPassage
      inhibitionPassage
      pertinencePassage
      goals
      qaUrl
      topK
      mode
      charting
      summarisation
      compressor
      docTopK
      minRetrievalScore
      timeSignificance
      hasModeration
      customModerations
      maxHistory
      maxTokens
      topicEnhancer
      maxTopics
      blender
      temperature
      version
      subject
      workspaceID
      createdBy
      updatedBy
      agentFunctions
      isGlobal
      plainTextOutput
      chainOfThoughts
      treeOfThoughts
      extendedOutput
      agentType
      agentsPool
      maxPlanningSteps
      planningInstructions
      plannerExecutionAttempts
      plannerRequiresApprovalForPlanExecution
      reviewInstructions
      showReferencesToInternalDocs
      preventWidgetUsage
      allowFeedback
      generateExtendedReport
      showTimeForResponse
      showDetectedLanguage
      isMultiLanguageEnabled
      hasMath
      hasCode
      hasNER
      restrictedAccess
      restrictedAccessUsers
      splashTitle
      showSplashWhenEmpty
      llmEndpoint
      llmUrl
      llmUrlStatus
      llmUrlType
      llmBaseModel
      llmProvider
      functionCallingProvider
      functionCallingModel
      showAgentName
      allowImagesUpload
      allowVideosUpload
      allowAudiosUpload
      allowDocsUpload
      allowImagesGeneration
      baseImageGenerationModel
      maxImagesGenerated
      advancedImageGeneration
      allowVideosGeneration
      allowAudiosGeneration
      voiceFileKey
      allowInternetSearch
      bannedDomains
      maxUrlsForInternetSearch
      allowDeepInternetSearch
      internetSearchModes
      internetSearchLocation
      autoVoiceMode
      keywordsWeightInRetrieval
      customCodeExecutionEnvironments
      phoneNumberAsSender
      whatsappNumberAsSender
      emailAsSender
      communicationServices
      channelCallbackDelay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  mutation DeleteAgent(
    $input: DeleteAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    deleteAgent(input: $input, condition: $condition) {
      id
      type
      label
      description
      interpolationString
      emoji
      color
      darkColor
      icon
      largeLogo
      icoUrl
      logoBackground
      darkLogoBackground
      messageOnLaunch
      messagesOnLaunch
      rotateMessagesOnLaunch
      rotateMessagesOnLaunchInterval
      placeholderMessageOnLoading
      customGreetingInstructions
      customConclusionInstructions
      customChartingInstructions
      customSummarisationInstructions
      customImagesInstructions
      customToolingInstructions
      placeholderInputMessage
      openByDefault
      disclaimer
      showDocumentsReferences
      isDefault
      hasMemory
      hasImages
      promptTopKeywords
      keyWordsForKnowledgeBase
      hasTopicsContext
      hasFunctions
      advancedLanguageDetection
      allowedTopics
      defaultAnswer
      noKnowledgeDefaultAnswer
      quickQuestions
      recencyImportance
      maxRecencyIncrease
      recencyTopKIncreaseFactor
      stopSequence
      examples {
        question
        answer
      }
      enhancementPassage
      inhibitionPassage
      pertinencePassage
      goals
      qaUrl
      topK
      mode
      charting
      summarisation
      compressor
      docTopK
      minRetrievalScore
      timeSignificance
      hasModeration
      customModerations
      maxHistory
      maxTokens
      topicEnhancer
      maxTopics
      blender
      temperature
      version
      subject
      workspaceID
      createdBy
      updatedBy
      agentFunctions
      isGlobal
      plainTextOutput
      chainOfThoughts
      treeOfThoughts
      extendedOutput
      agentType
      agentsPool
      maxPlanningSteps
      planningInstructions
      plannerExecutionAttempts
      plannerRequiresApprovalForPlanExecution
      reviewInstructions
      showReferencesToInternalDocs
      preventWidgetUsage
      allowFeedback
      generateExtendedReport
      showTimeForResponse
      showDetectedLanguage
      isMultiLanguageEnabled
      hasMath
      hasCode
      hasNER
      restrictedAccess
      restrictedAccessUsers
      splashTitle
      showSplashWhenEmpty
      llmEndpoint
      llmUrl
      llmUrlStatus
      llmUrlType
      llmBaseModel
      llmProvider
      functionCallingProvider
      functionCallingModel
      showAgentName
      allowImagesUpload
      allowVideosUpload
      allowAudiosUpload
      allowDocsUpload
      allowImagesGeneration
      baseImageGenerationModel
      maxImagesGenerated
      advancedImageGeneration
      allowVideosGeneration
      allowAudiosGeneration
      voiceFileKey
      allowInternetSearch
      bannedDomains
      maxUrlsForInternetSearch
      allowDeepInternetSearch
      internetSearchModes
      internetSearchLocation
      autoVoiceMode
      keywordsWeightInRetrieval
      customCodeExecutionEnvironments
      phoneNumberAsSender
      whatsappNumberAsSender
      emailAsSender
      communicationServices
      channelCallbackDelay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAgentFunction = /* GraphQL */ `
  mutation CreateAgentFunction(
    $input: CreateAgentFunctionInput!
    $condition: ModelAgentFunctionConditionInput
  ) {
    createAgentFunction(input: $input, condition: $condition) {
      id
      name
      description
      parameters
      model
      url
      requestType
      authorisationType
      authorisationKey
      authorisationID
      paramsAsPath
      scope
      jsonPathExtractor
      headers
      staticArgs
      customExecutionCode
      isCallbackFn
      isDatabaseScript
      isHtmlFn
      isChatFn
      isHandOffFn
      onFnCall
      handOffAgent
      handOffToOriginalAgentOnCompletion
      handOffToOriginalAgentOnFailure
      handOffAgentOnCompletion
      handOffAgentOnFailure
      chatScript
      chatAction
      htmlUrl
      htmlScript
      isHtmlRedirectFn
      htmlFormOnSuccessfulSubmitMessage
      htmlFormOnFailedSubmitMessage
      dbSchemaMetadata
      preExecutionScript
      postExecutionScript
      dbScript
      dbConnectionID
      preExecutionFnID
      preExecutionMapping
      OAuthConnectionID
      workspaceID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAgentFunction = /* GraphQL */ `
  mutation UpdateAgentFunction(
    $input: UpdateAgentFunctionInput!
    $condition: ModelAgentFunctionConditionInput
  ) {
    updateAgentFunction(input: $input, condition: $condition) {
      id
      name
      description
      parameters
      model
      url
      requestType
      authorisationType
      authorisationKey
      authorisationID
      paramsAsPath
      scope
      jsonPathExtractor
      headers
      staticArgs
      customExecutionCode
      isCallbackFn
      isDatabaseScript
      isHtmlFn
      isChatFn
      isHandOffFn
      onFnCall
      handOffAgent
      handOffToOriginalAgentOnCompletion
      handOffToOriginalAgentOnFailure
      handOffAgentOnCompletion
      handOffAgentOnFailure
      chatScript
      chatAction
      htmlUrl
      htmlScript
      isHtmlRedirectFn
      htmlFormOnSuccessfulSubmitMessage
      htmlFormOnFailedSubmitMessage
      dbSchemaMetadata
      preExecutionScript
      postExecutionScript
      dbScript
      dbConnectionID
      preExecutionFnID
      preExecutionMapping
      OAuthConnectionID
      workspaceID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAgentFunction = /* GraphQL */ `
  mutation DeleteAgentFunction(
    $input: DeleteAgentFunctionInput!
    $condition: ModelAgentFunctionConditionInput
  ) {
    deleteAgentFunction(input: $input, condition: $condition) {
      id
      name
      description
      parameters
      model
      url
      requestType
      authorisationType
      authorisationKey
      authorisationID
      paramsAsPath
      scope
      jsonPathExtractor
      headers
      staticArgs
      customExecutionCode
      isCallbackFn
      isDatabaseScript
      isHtmlFn
      isChatFn
      isHandOffFn
      onFnCall
      handOffAgent
      handOffToOriginalAgentOnCompletion
      handOffToOriginalAgentOnFailure
      handOffAgentOnCompletion
      handOffAgentOnFailure
      chatScript
      chatAction
      htmlUrl
      htmlScript
      isHtmlRedirectFn
      htmlFormOnSuccessfulSubmitMessage
      htmlFormOnFailedSubmitMessage
      dbSchemaMetadata
      preExecutionScript
      postExecutionScript
      dbScript
      dbConnectionID
      preExecutionFnID
      preExecutionMapping
      OAuthConnectionID
      workspaceID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAuthorisation = /* GraphQL */ `
  mutation CreateAuthorisation(
    $input: CreateAuthorisationInput!
    $condition: ModelAuthorisationConditionInput
  ) {
    createAuthorisation(input: $input, condition: $condition) {
      id
      name
      tokenSecret
      description
      scope
      grantType
      clientId
      clientSecret
      authorizationBaseUrl
      staticArgs
      tokenBaseUrl
      type
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAuthorisation = /* GraphQL */ `
  mutation UpdateAuthorisation(
    $input: UpdateAuthorisationInput!
    $condition: ModelAuthorisationConditionInput
  ) {
    updateAuthorisation(input: $input, condition: $condition) {
      id
      name
      tokenSecret
      description
      scope
      grantType
      clientId
      clientSecret
      authorizationBaseUrl
      staticArgs
      tokenBaseUrl
      type
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAuthorisation = /* GraphQL */ `
  mutation DeleteAuthorisation(
    $input: DeleteAuthorisationInput!
    $condition: ModelAuthorisationConditionInput
  ) {
    deleteAuthorisation(input: $input, condition: $condition) {
      id
      name
      tokenSecret
      description
      scope
      grantType
      clientId
      clientSecret
      authorizationBaseUrl
      staticArgs
      tokenBaseUrl
      type
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createHosting = /* GraphQL */ `
  mutation CreateHosting(
    $input: CreateHostingInput!
    $condition: ModelHostingConditionInput
  ) {
    createHosting(input: $input, condition: $condition) {
      id
      name
      description
      tokenSecret
      endpoint
      modelName
      baseModel
      customModel
      apiVersion
      type
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateHosting = /* GraphQL */ `
  mutation UpdateHosting(
    $input: UpdateHostingInput!
    $condition: ModelHostingConditionInput
  ) {
    updateHosting(input: $input, condition: $condition) {
      id
      name
      description
      tokenSecret
      endpoint
      modelName
      baseModel
      customModel
      apiVersion
      type
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteHosting = /* GraphQL */ `
  mutation DeleteHosting(
    $input: DeleteHostingInput!
    $condition: ModelHostingConditionInput
  ) {
    deleteHosting(input: $input, condition: $condition) {
      id
      name
      description
      tokenSecret
      endpoint
      modelName
      baseModel
      customModel
      apiVersion
      type
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDatabaseConnection = /* GraphQL */ `
  mutation CreateDatabaseConnection(
    $input: CreateDatabaseConnectionInput!
    $condition: ModelDatabaseConnectionConditionInput
  ) {
    createDatabaseConnection(input: $input, condition: $condition) {
      id
      name
      description
      type
      host
      port
      username
      passwordSecret
      database
      schema
      ssl
      ssh
      sshHost
      sshPort
      sshUsername
      sshPasswordSecret
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDatabaseConnection = /* GraphQL */ `
  mutation UpdateDatabaseConnection(
    $input: UpdateDatabaseConnectionInput!
    $condition: ModelDatabaseConnectionConditionInput
  ) {
    updateDatabaseConnection(input: $input, condition: $condition) {
      id
      name
      description
      type
      host
      port
      username
      passwordSecret
      database
      schema
      ssl
      ssh
      sshHost
      sshPort
      sshUsername
      sshPasswordSecret
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDatabaseConnection = /* GraphQL */ `
  mutation DeleteDatabaseConnection(
    $input: DeleteDatabaseConnectionInput!
    $condition: ModelDatabaseConnectionConditionInput
  ) {
    deleteDatabaseConnection(input: $input, condition: $condition) {
      id
      name
      description
      type
      host
      port
      username
      passwordSecret
      database
      schema
      ssl
      ssh
      sshHost
      sshPort
      sshUsername
      sshPasswordSecret
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      name
      description
      url
      workspaceID
      topics
      status
      lastValidationDate
      lastValidationRequested
      validationToken
      allowedPaths
      siteTree
      lastScraped
      skipOwnerValidation
      useProxy
      completion_percentage
      extractImages
      freeRoaming
      scrapingCycle
      imgRetrievalPrompt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      name
      description
      url
      workspaceID
      topics
      status
      lastValidationDate
      lastValidationRequested
      validationToken
      allowedPaths
      siteTree
      lastScraped
      skipOwnerValidation
      useProxy
      completion_percentage
      extractImages
      freeRoaming
      scrapingCycle
      imgRetrievalPrompt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      name
      description
      url
      workspaceID
      topics
      status
      lastValidationDate
      lastValidationRequested
      validationToken
      allowedPaths
      siteTree
      lastScraped
      skipOwnerValidation
      useProxy
      completion_percentage
      extractImages
      freeRoaming
      scrapingCycle
      imgRetrievalPrompt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEntityAI = /* GraphQL */ `
  mutation CreateEntityAI(
    $input: CreateEntityAIInput!
    $condition: ModelEntityAIConditionInput
  ) {
    createEntityAI(input: $input, condition: $condition) {
      id
      owner
      label
      type
      description
      emoji
      parentEntity
      backgroundColor
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEntityAI = /* GraphQL */ `
  mutation UpdateEntityAI(
    $input: UpdateEntityAIInput!
    $condition: ModelEntityAIConditionInput
  ) {
    updateEntityAI(input: $input, condition: $condition) {
      id
      owner
      label
      type
      description
      emoji
      parentEntity
      backgroundColor
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEntityAI = /* GraphQL */ `
  mutation DeleteEntityAI(
    $input: DeleteEntityAIInput!
    $condition: ModelEntityAIConditionInput
  ) {
    deleteEntityAI(input: $input, condition: $condition) {
      id
      owner
      label
      type
      description
      emoji
      parentEntity
      backgroundColor
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTrainingLog = /* GraphQL */ `
  mutation CreateTrainingLog(
    $input: CreateTrainingLogInput!
    $condition: ModelTrainingLogConditionInput
  ) {
    createTrainingLog(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status
      trainingIterations
      lastActivation
      duration
      trainingMeta
      trainingConfig
      workspaceID
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTrainingLog = /* GraphQL */ `
  mutation UpdateTrainingLog(
    $input: UpdateTrainingLogInput!
    $condition: ModelTrainingLogConditionInput
  ) {
    updateTrainingLog(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status
      trainingIterations
      lastActivation
      duration
      trainingMeta
      trainingConfig
      workspaceID
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTrainingLog = /* GraphQL */ `
  mutation DeleteTrainingLog(
    $input: DeleteTrainingLogInput!
    $condition: ModelTrainingLogConditionInput
  ) {
    deleteTrainingLog(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status
      trainingIterations
      lastActivation
      duration
      trainingMeta
      trainingConfig
      workspaceID
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBatchJobLog = /* GraphQL */ `
  mutation CreateBatchJobLog(
    $input: CreateBatchJobLogInput!
    $condition: ModelBatchJobLogConditionInput
  ) {
    createBatchJobLog(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status
      duration
      batchMeta
      batchConfig
      batchOutput
      workspaceID
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBatchJobLog = /* GraphQL */ `
  mutation UpdateBatchJobLog(
    $input: UpdateBatchJobLogInput!
    $condition: ModelBatchJobLogConditionInput
  ) {
    updateBatchJobLog(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status
      duration
      batchMeta
      batchConfig
      batchOutput
      workspaceID
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBatchJobLog = /* GraphQL */ `
  mutation DeleteBatchJobLog(
    $input: DeleteBatchJobLogInput!
    $condition: ModelBatchJobLogConditionInput
  ) {
    deleteBatchJobLog(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status
      duration
      batchMeta
      batchConfig
      batchOutput
      workspaceID
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAppLog = /* GraphQL */ `
  mutation CreateAppLog(
    $input: CreateAppLogInput!
    $condition: ModelAppLogConditionInput
  ) {
    createAppLog(input: $input, condition: $condition) {
      id
      owner
      type
      filename
      event
      status
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAppLog = /* GraphQL */ `
  mutation UpdateAppLog(
    $input: UpdateAppLogInput!
    $condition: ModelAppLogConditionInput
  ) {
    updateAppLog(input: $input, condition: $condition) {
      id
      owner
      type
      filename
      event
      status
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAppLog = /* GraphQL */ `
  mutation DeleteAppLog(
    $input: DeleteAppLogInput!
    $condition: ModelAppLogConditionInput
  ) {
    deleteAppLog(input: $input, condition: $condition) {
      id
      owner
      type
      filename
      event
      status
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRequestLog = /* GraphQL */ `
  mutation CreateRequestLog(
    $input: CreateRequestLogInput!
    $condition: ModelRequestLogConditionInput
  ) {
    createRequestLog(input: $input, condition: $condition) {
      id
      srtKey
      type
      status
      words
      tokens
      sentences
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRequestLog = /* GraphQL */ `
  mutation UpdateRequestLog(
    $input: UpdateRequestLogInput!
    $condition: ModelRequestLogConditionInput
  ) {
    updateRequestLog(input: $input, condition: $condition) {
      id
      srtKey
      type
      status
      words
      tokens
      sentences
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRequestLog = /* GraphQL */ `
  mutation DeleteRequestLog(
    $input: DeleteRequestLogInput!
    $condition: ModelRequestLogConditionInput
  ) {
    deleteRequestLog(input: $input, condition: $condition) {
      id
      srtKey
      type
      status
      words
      tokens
      sentences
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOutputStream = /* GraphQL */ `
  mutation CreateOutputStream(
    $input: CreateOutputStreamInput!
    $condition: ModelOutputStreamConditionInput
  ) {
    createOutputStream(input: $input, condition: $condition) {
      id
      content
      t_content
      prompt
      t_prompt
      workspaceID
      type
      status
      humanFeedback
      humanFeedbackComment
      humanFeedbackType
      humanFeedbackSubType
      humanFeedbackProvidedBy
      adminResolution
      adminChecked
      autoTune
      scoring
      metadata
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOutputStream = /* GraphQL */ `
  mutation UpdateOutputStream(
    $input: UpdateOutputStreamInput!
    $condition: ModelOutputStreamConditionInput
  ) {
    updateOutputStream(input: $input, condition: $condition) {
      id
      content
      t_content
      prompt
      t_prompt
      workspaceID
      type
      status
      humanFeedback
      humanFeedbackComment
      humanFeedbackType
      humanFeedbackSubType
      humanFeedbackProvidedBy
      adminResolution
      adminChecked
      autoTune
      scoring
      metadata
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOutputStream = /* GraphQL */ `
  mutation DeleteOutputStream(
    $input: DeleteOutputStreamInput!
    $condition: ModelOutputStreamConditionInput
  ) {
    deleteOutputStream(input: $input, condition: $condition) {
      id
      content
      t_content
      prompt
      t_prompt
      workspaceID
      type
      status
      humanFeedback
      humanFeedbackComment
      humanFeedbackType
      humanFeedbackSubType
      humanFeedbackProvidedBy
      adminResolution
      adminChecked
      autoTune
      scoring
      metadata
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFolders = /* GraphQL */ `
  mutation CreateFolders(
    $input: CreateFoldersInput!
    $condition: ModelFoldersConditionInput
  ) {
    createFolders(input: $input, condition: $condition) {
      id
      owner
      name
      description
      emoji
      status
      parent
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFolders = /* GraphQL */ `
  mutation UpdateFolders(
    $input: UpdateFoldersInput!
    $condition: ModelFoldersConditionInput
  ) {
    updateFolders(input: $input, condition: $condition) {
      id
      owner
      name
      description
      emoji
      status
      parent
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFolders = /* GraphQL */ `
  mutation DeleteFolders(
    $input: DeleteFoldersInput!
    $condition: ModelFoldersConditionInput
  ) {
    deleteFolders(input: $input, condition: $condition) {
      id
      owner
      name
      description
      emoji
      status
      parent
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDictionaryItem = /* GraphQL */ `
  mutation CreateDictionaryItem(
    $input: CreateDictionaryItemInput!
    $condition: ModelDictionaryItemConditionInput
  ) {
    createDictionaryItem(input: $input, condition: $condition) {
      id
      owner
      sourceLanguage
      targetLanguage
      sourceText
      targetText
      workspaceID
      pos
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDictionaryItem = /* GraphQL */ `
  mutation UpdateDictionaryItem(
    $input: UpdateDictionaryItemInput!
    $condition: ModelDictionaryItemConditionInput
  ) {
    updateDictionaryItem(input: $input, condition: $condition) {
      id
      owner
      sourceLanguage
      targetLanguage
      sourceText
      targetText
      workspaceID
      pos
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDictionaryItem = /* GraphQL */ `
  mutation DeleteDictionaryItem(
    $input: DeleteDictionaryItemInput!
    $condition: ModelDictionaryItemConditionInput
  ) {
    deleteDictionaryItem(input: $input, condition: $condition) {
      id
      owner
      sourceLanguage
      targetLanguage
      sourceText
      targetText
      workspaceID
      pos
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status {
        status
        lastUpdated
      }
      workspaceID
      creationTime
      createdBy
      updatedBy
      visibility
      isAIReplying
      activeMessageForAI
      messages {
        items {
          id
          role
          chatID
          text
          t_text
          t_text_summary
          createdAt
          creationTime
          updatedAt
          scoring
          embeddingId
          humanFeedback
          humanFeedbackComment
          humanFeedbackType
          humanFeedbackSubType
          humanFeedbackProvidedBy
          adminResolution
          prevMessage
          prevMessagePosEq
          prevMessageNegEq
          correctContext
          correctAnswer
          adminChecked
          autoTune
          userID
          agentID
          images
          audios
          videos
          files
          imagesText
          audiosText
          videosText
          filesText
          isAI
          messageType
          status
          metadata
          callbackMetadata
          workspaceID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      chatHistory
      topics
      documentId
      primaryRole
      primaryAgent
      plannerAgent
      forcePlannerStop
      allowMultipleRoles
      roles
      language
      summary
      caseId
      customerId
      caseInfo
      customerInfo
      metadata
      channelSettings
      externalParticipantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status {
        status
        lastUpdated
      }
      workspaceID
      creationTime
      createdBy
      updatedBy
      visibility
      isAIReplying
      activeMessageForAI
      messages {
        items {
          id
          role
          chatID
          text
          t_text
          t_text_summary
          createdAt
          creationTime
          updatedAt
          scoring
          embeddingId
          humanFeedback
          humanFeedbackComment
          humanFeedbackType
          humanFeedbackSubType
          humanFeedbackProvidedBy
          adminResolution
          prevMessage
          prevMessagePosEq
          prevMessageNegEq
          correctContext
          correctAnswer
          adminChecked
          autoTune
          userID
          agentID
          images
          audios
          videos
          files
          imagesText
          audiosText
          videosText
          filesText
          isAI
          messageType
          status
          metadata
          callbackMetadata
          workspaceID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      chatHistory
      topics
      documentId
      primaryRole
      primaryAgent
      plannerAgent
      forcePlannerStop
      allowMultipleRoles
      roles
      language
      summary
      caseId
      customerId
      caseInfo
      customerInfo
      metadata
      channelSettings
      externalParticipantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      owner
      name
      description
      status {
        status
        lastUpdated
      }
      workspaceID
      creationTime
      createdBy
      updatedBy
      visibility
      isAIReplying
      activeMessageForAI
      messages {
        items {
          id
          role
          chatID
          text
          t_text
          t_text_summary
          createdAt
          creationTime
          updatedAt
          scoring
          embeddingId
          humanFeedback
          humanFeedbackComment
          humanFeedbackType
          humanFeedbackSubType
          humanFeedbackProvidedBy
          adminResolution
          prevMessage
          prevMessagePosEq
          prevMessageNegEq
          correctContext
          correctAnswer
          adminChecked
          autoTune
          userID
          agentID
          images
          audios
          videos
          files
          imagesText
          audiosText
          videosText
          filesText
          isAI
          messageType
          status
          metadata
          callbackMetadata
          workspaceID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      chatHistory
      topics
      documentId
      primaryRole
      primaryAgent
      plannerAgent
      forcePlannerStop
      allowMultipleRoles
      roles
      language
      summary
      caseId
      customerId
      caseInfo
      customerInfo
      metadata
      channelSettings
      externalParticipantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomPrompt = /* GraphQL */ `
  mutation CreateCustomPrompt(
    $input: CreateCustomPromptInput!
    $condition: ModelCustomPromptConditionInput
  ) {
    createCustomPrompt(input: $input, condition: $condition) {
      id
      type
      scope
      style
      domain
      label
      promptLength
      promptPlaceholder
      interpolationString
      availableToAgents
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomPrompt = /* GraphQL */ `
  mutation UpdateCustomPrompt(
    $input: UpdateCustomPromptInput!
    $condition: ModelCustomPromptConditionInput
  ) {
    updateCustomPrompt(input: $input, condition: $condition) {
      id
      type
      scope
      style
      domain
      label
      promptLength
      promptPlaceholder
      interpolationString
      availableToAgents
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomPrompt = /* GraphQL */ `
  mutation DeleteCustomPrompt(
    $input: DeleteCustomPromptInput!
    $condition: ModelCustomPromptConditionInput
  ) {
    deleteCustomPrompt(input: $input, condition: $condition) {
      id
      type
      scope
      style
      domain
      label
      promptLength
      promptPlaceholder
      interpolationString
      availableToAgents
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomCodeExecutionEnvironment = /* GraphQL */ `
  mutation CreateCustomCodeExecutionEnvironment(
    $input: CreateCustomCodeExecutionEnvironmentInput!
    $condition: ModelCustomCodeExecutionEnvironmentConditionInput
  ) {
    createCustomCodeExecutionEnvironment(input: $input, condition: $condition) {
      id
      name
      description
      functionName
      availableLibraries
      customExecutionInstructions
      customExecutionCode
      allowExternalAPI
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomCodeExecutionEnvironment = /* GraphQL */ `
  mutation UpdateCustomCodeExecutionEnvironment(
    $input: UpdateCustomCodeExecutionEnvironmentInput!
    $condition: ModelCustomCodeExecutionEnvironmentConditionInput
  ) {
    updateCustomCodeExecutionEnvironment(input: $input, condition: $condition) {
      id
      name
      description
      functionName
      availableLibraries
      customExecutionInstructions
      customExecutionCode
      allowExternalAPI
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomCodeExecutionEnvironment = /* GraphQL */ `
  mutation DeleteCustomCodeExecutionEnvironment(
    $input: DeleteCustomCodeExecutionEnvironmentInput!
    $condition: ModelCustomCodeExecutionEnvironmentConditionInput
  ) {
    deleteCustomCodeExecutionEnvironment(input: $input, condition: $condition) {
      id
      name
      description
      functionName
      availableLibraries
      customExecutionInstructions
      customExecutionCode
      allowExternalAPI
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage(
    $input: CreateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    createChatMessage(input: $input, condition: $condition) {
      id
      role
      chatID
      text
      t_text
      t_text_summary
      createdAt
      creationTime
      updatedAt
      scoring
      embeddingId
      humanFeedback
      humanFeedbackComment
      humanFeedbackType
      humanFeedbackSubType
      humanFeedbackProvidedBy
      adminResolution
      prevMessage
      prevMessagePosEq
      prevMessageNegEq
      correctContext
      correctAnswer
      adminChecked
      autoTune
      userID
      agentID
      images
      audios
      videos
      files
      imagesText
      audiosText
      videosText
      filesText
      isAI
      messageType
      status
      metadata
      callbackMetadata
      workspaceID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage(
    $input: UpdateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      role
      chatID
      text
      t_text
      t_text_summary
      createdAt
      creationTime
      updatedAt
      scoring
      embeddingId
      humanFeedback
      humanFeedbackComment
      humanFeedbackType
      humanFeedbackSubType
      humanFeedbackProvidedBy
      adminResolution
      prevMessage
      prevMessagePosEq
      prevMessageNegEq
      correctContext
      correctAnswer
      adminChecked
      autoTune
      userID
      agentID
      images
      audios
      videos
      files
      imagesText
      audiosText
      videosText
      filesText
      isAI
      messageType
      status
      metadata
      callbackMetadata
      workspaceID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage(
    $input: DeleteChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      role
      chatID
      text
      t_text
      t_text_summary
      createdAt
      creationTime
      updatedAt
      scoring
      embeddingId
      humanFeedback
      humanFeedbackComment
      humanFeedbackType
      humanFeedbackSubType
      humanFeedbackProvidedBy
      adminResolution
      prevMessage
      prevMessagePosEq
      prevMessageNegEq
      correctContext
      correctAnswer
      adminChecked
      autoTune
      userID
      agentID
      images
      audios
      videos
      files
      imagesText
      audiosText
      videosText
      filesText
      isAI
      messageType
      status
      metadata
      callbackMetadata
      workspaceID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCommunicationService = /* GraphQL */ `
  mutation CreateCommunicationService(
    $input: CreateCommunicationServiceInput!
    $condition: ModelCommunicationServiceConditionInput
  ) {
    createCommunicationService(input: $input, condition: $condition) {
      id
      workspaceID
      name
      description
      channel
      provider
      senderid
      fromNumber
      tokenSecret
      isActive
      config
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCommunicationService = /* GraphQL */ `
  mutation UpdateCommunicationService(
    $input: UpdateCommunicationServiceInput!
    $condition: ModelCommunicationServiceConditionInput
  ) {
    updateCommunicationService(input: $input, condition: $condition) {
      id
      workspaceID
      name
      description
      channel
      provider
      senderid
      fromNumber
      tokenSecret
      isActive
      config
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCommunicationService = /* GraphQL */ `
  mutation DeleteCommunicationService(
    $input: DeleteCommunicationServiceInput!
    $condition: ModelCommunicationServiceConditionInput
  ) {
    deleteCommunicationService(input: $input, condition: $condition) {
      id
      workspaceID
      name
      description
      channel
      provider
      senderid
      fromNumber
      tokenSecret
      isActive
      config
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSubscriptionPricingPlan = /* GraphQL */ `
  mutation CreateSubscriptionPricingPlan(
    $input: CreateSubscriptionPricingPlanInput!
    $condition: ModelSubscriptionPricingPlanConditionInput
  ) {
    createSubscriptionPricingPlan(input: $input, condition: $condition) {
      id
      type
      subscriptionStatus
      isPredictionOnDemandGlobal
      isTrainingOnDemandGlobal
      predictionRequestsGlobal
      batchRequestsGlobal
      trainingMinutesGlobal
      baseModelGN
      baseModelQA
      baseModelNLP
      maxUsers
      agcEnabled
      isDownloadModelEnabled
      isDownloadDataEnabled
      isAPIAccessEnabled
      isBatchJobsEnabled
      isSentimentReviewerEnabled
      isBusinessAnalystEnabled
      isContentCreatorEnabled
      isPriorityTrainingEnabled
      isPredictionGPUAccelerationEnabledGlobal
      isPredictionGPUAccelerationEnabledRC
      isPredictionGPUAccelerationEnabledNLP
      aiMaxConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      maxMrcDocs
      accuracyModelEnabled
      isTrainingNLPEnabled
      isTrainingQAEnabled
      isTrainingGNEnabled
      isLongTermMemoryEnabled
      isFunctionsEnabled
      isDBFunctionsEnabled
      isHtmlFunctionsEnabled
      isSitesBindingEnabled
      isAgentsSecurityEnabled
      isWidgetChatsHistoryEnabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSubscriptionPricingPlan = /* GraphQL */ `
  mutation UpdateSubscriptionPricingPlan(
    $input: UpdateSubscriptionPricingPlanInput!
    $condition: ModelSubscriptionPricingPlanConditionInput
  ) {
    updateSubscriptionPricingPlan(input: $input, condition: $condition) {
      id
      type
      subscriptionStatus
      isPredictionOnDemandGlobal
      isTrainingOnDemandGlobal
      predictionRequestsGlobal
      batchRequestsGlobal
      trainingMinutesGlobal
      baseModelGN
      baseModelQA
      baseModelNLP
      maxUsers
      agcEnabled
      isDownloadModelEnabled
      isDownloadDataEnabled
      isAPIAccessEnabled
      isBatchJobsEnabled
      isSentimentReviewerEnabled
      isBusinessAnalystEnabled
      isContentCreatorEnabled
      isPriorityTrainingEnabled
      isPredictionGPUAccelerationEnabledGlobal
      isPredictionGPUAccelerationEnabledRC
      isPredictionGPUAccelerationEnabledNLP
      aiMaxConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      maxMrcDocs
      accuracyModelEnabled
      isTrainingNLPEnabled
      isTrainingQAEnabled
      isTrainingGNEnabled
      isLongTermMemoryEnabled
      isFunctionsEnabled
      isDBFunctionsEnabled
      isHtmlFunctionsEnabled
      isSitesBindingEnabled
      isAgentsSecurityEnabled
      isWidgetChatsHistoryEnabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSubscriptionPricingPlan = /* GraphQL */ `
  mutation DeleteSubscriptionPricingPlan(
    $input: DeleteSubscriptionPricingPlanInput!
    $condition: ModelSubscriptionPricingPlanConditionInput
  ) {
    deleteSubscriptionPricingPlan(input: $input, condition: $condition) {
      id
      type
      subscriptionStatus
      isPredictionOnDemandGlobal
      isTrainingOnDemandGlobal
      predictionRequestsGlobal
      batchRequestsGlobal
      trainingMinutesGlobal
      baseModelGN
      baseModelQA
      baseModelNLP
      maxUsers
      agcEnabled
      isDownloadModelEnabled
      isDownloadDataEnabled
      isAPIAccessEnabled
      isBatchJobsEnabled
      isSentimentReviewerEnabled
      isBusinessAnalystEnabled
      isContentCreatorEnabled
      isPriorityTrainingEnabled
      isPredictionGPUAccelerationEnabledGlobal
      isPredictionGPUAccelerationEnabledRC
      isPredictionGPUAccelerationEnabledNLP
      aiMaxConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      maxMrcDocs
      accuracyModelEnabled
      isTrainingNLPEnabled
      isTrainingQAEnabled
      isTrainingGNEnabled
      isLongTermMemoryEnabled
      isFunctionsEnabled
      isDBFunctionsEnabled
      isHtmlFunctionsEnabled
      isSitesBindingEnabled
      isAgentsSecurityEnabled
      isWidgetChatsHistoryEnabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSubscriptionBaseConfig = /* GraphQL */ `
  mutation CreateSubscriptionBaseConfig(
    $input: CreateSubscriptionBaseConfigInput!
    $condition: ModelSubscriptionBaseConfigConditionInput
  ) {
    createSubscriptionBaseConfig(input: $input, condition: $condition) {
      id
      type
      planConfig {
        domain
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSubscriptionBaseConfig = /* GraphQL */ `
  mutation UpdateSubscriptionBaseConfig(
    $input: UpdateSubscriptionBaseConfigInput!
    $condition: ModelSubscriptionBaseConfigConditionInput
  ) {
    updateSubscriptionBaseConfig(input: $input, condition: $condition) {
      id
      type
      planConfig {
        domain
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSubscriptionBaseConfig = /* GraphQL */ `
  mutation DeleteSubscriptionBaseConfig(
    $input: DeleteSubscriptionBaseConfigInput!
    $condition: ModelSubscriptionBaseConfigConditionInput
  ) {
    deleteSubscriptionBaseConfig(input: $input, condition: $condition) {
      id
      type
      planConfig {
        domain
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGenerationConfig = /* GraphQL */ `
  mutation CreateGenerationConfig(
    $input: CreateGenerationConfigInput!
    $condition: ModelGenerationConfigConditionInput
  ) {
    createGenerationConfig(input: $input, condition: $condition) {
      id
      styles
      domains
      scopes
      prompts {
        type
        label
        promptLength
        promptPlaceholder
        interpolationString
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGenerationConfig = /* GraphQL */ `
  mutation UpdateGenerationConfig(
    $input: UpdateGenerationConfigInput!
    $condition: ModelGenerationConfigConditionInput
  ) {
    updateGenerationConfig(input: $input, condition: $condition) {
      id
      styles
      domains
      scopes
      prompts {
        type
        label
        promptLength
        promptPlaceholder
        interpolationString
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGenerationConfig = /* GraphQL */ `
  mutation DeleteGenerationConfig(
    $input: DeleteGenerationConfigInput!
    $condition: ModelGenerationConfigConditionInput
  ) {
    deleteGenerationConfig(input: $input, condition: $condition) {
      id
      styles
      domains
      scopes
      prompts {
        type
        label
        promptLength
        promptPlaceholder
        interpolationString
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createArenaBattle = /* GraphQL */ `
  mutation CreateArenaBattle(
    $input: CreateArenaBattleInput!
    $condition: ModelArenaBattleConditionInput
  ) {
    createArenaBattle(input: $input, condition: $condition) {
      id
      firstAgent
      secondAgent
      firstAgentChatID
      secondAgentChatID
      winnerID
      createdAt
      creationTime
      updatedAt
      workspaceID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateArenaBattle = /* GraphQL */ `
  mutation UpdateArenaBattle(
    $input: UpdateArenaBattleInput!
    $condition: ModelArenaBattleConditionInput
  ) {
    updateArenaBattle(input: $input, condition: $condition) {
      id
      firstAgent
      secondAgent
      firstAgentChatID
      secondAgentChatID
      winnerID
      createdAt
      creationTime
      updatedAt
      workspaceID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteArenaBattle = /* GraphQL */ `
  mutation DeleteArenaBattle(
    $input: DeleteArenaBattleInput!
    $condition: ModelArenaBattleConditionInput
  ) {
    deleteArenaBattle(input: $input, condition: $condition) {
      id
      firstAgent
      secondAgent
      firstAgentChatID
      secondAgentChatID
      winnerID
      createdAt
      creationTime
      updatedAt
      workspaceID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
