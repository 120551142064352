import React from "react";
import { View } from "react-native";
import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import ToothFairyJsonViewer from "../../../Components/ToothFairyComponents/ToothFairyJsonViewer";

const FunctionLeaf = ({ id, fn }) => {
  const { currentTheme } = AppTheme.useTheme();

  return (
    <View
      style={{
        flexDirection: "column",
        borderBottomWidth: 1,
        borderBottomColor: currentTheme?.theme?.darkGrey,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <AppText
          style={{
            fontWeight: "bold",
            textOverflow: "ellipsis",
            maxWidth: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          color={currentTheme?.theme?.primary}
          wrapperStyle={{
            marginVertical: 10,
            maxWidth: "80%",
            flex: 1,
          }}
        >
          {fn?.name}
        </AppText>
        {/* {chunk_id && scoring > 0 && (
              <AppText
                style={{
                  fontSize: currentTheme?.theme?.extra_small_font_size,
                }}
                fontWeight="bold"
              >
                {`Accuracy - ${(scoring * 100)?.toFixed(2)}%`}
              </AppText>
            )} */}
      </View>

      <ToothFairyJsonViewer data={fn?.response} />
    </View>
  );
};

export default FunctionLeaf;
