import Constants from ".";

const commonBtnStyleGenerator = (currentTheme) => {
  return {
    awesomeIconColor: currentTheme?.theme?.white,
    backgroundColor: currentTheme?.theme?.primary,
    btnContentStyle: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    style: {
      height: Constants.appButtonHeight,
      width: "auto",
      shadowColor: currentTheme?.theme?.transparent,
      marginHorizontal: 10,
      marginTop: 5,
    },
    btnLabelStyle: {
      fontSize: 16,
    },
    wrapperStyle: { width: "fit-content" },
  };
};

export default {
  commonBtnStyleGenerator,
};
