import React, { useEffect, useState } from "react";
import DashboardScreen from "./DashboardScreen";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import battleHelper from "../../../API/Battle/battleHelper";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
const DashboardContainer = () => {
  const [battlesData, setBattlesData] = useState([]);
  const { state } = AppStateManager.useAppStateManager();
  const [dashboardData, setDashboardData] = useState([]);
  const { getActiveWorkspace } = useWorkspaces();
  console.log("DashboardContainer state", state);
  useEffect(() => {
    const fetchData = async (workspaceId) => {
      // Fetch data from API
      const data = await battleHelper.getBattlesByWorkspace(workspaceId);
      console.log("Dashboard data", data);
      // Set data to state variable
      setBattlesData(data?.data?.ArenaBattlesByWorkspace?.items);
    };
    if (getActiveWorkspace()?.id) fetchData(getActiveWorkspace()?.id);
  }, [getActiveWorkspace()?.id]);
  useEffect(() => {
    if (battlesData?.length > 0) {
      tf_wins = 0;
      tf_draws = 0;
      let _leaderBoardAgents = state?.agents
        ?.filter((agent) => agent?.mode == state?.selectedArenaMode)
        ?.map((u) => {
          return {
            id: u?.id,
            label: u?.label,
            wins: 0,
            losses: 0,
            draws: 0,
            total_battles: 0,
            total_points: 0,
          };
        });

      let total_battles = battlesData?.length;
      battlesData?.map((battle) => {
        let i_f_agent = _leaderBoardAgents
          ?.map((u) => u?.id)
          ?.indexOf(battle?.firstAgent);
        let f_agent = _leaderBoardAgents[i_f_agent];
        f_agent = {
          ...f_agent,
          total_battles: f_agent?.total_battles + 1,
        };
        _leaderBoardAgents[i_f_agent] = f_agent;

        let i_s_agent = _leaderBoardAgents
          ?.map((u) => u?.id)
          ?.indexOf(battle?.secondAgent);
        let s_agent = _leaderBoardAgents[i_s_agent];
        s_agent = {
          ...s_agent,
          total_battles: s_agent?.total_battles + 1,
        };
        _leaderBoardAgents[i_s_agent] = s_agent;

        if (battle?.winnerID) {
          if (
            _leaderBoardAgents?.find(
              (agent) => agent?.id == battle?.winnerID
            ) == null
          ) {
            return;
          }
          console.log("Winner", battle?.winnerID);
          let i_w_agent = _leaderBoardAgents
            ?.map((u) => u?.id)
            ?.indexOf(battle?.winnerID);
          let w_agent = _leaderBoardAgents[i_w_agent];
          w_agent = {
            ...w_agent,
            wins: w_agent?.wins + 1,
            total_points: w_agent?.total_points + 1,
          };
          _leaderBoardAgents[i_w_agent] = w_agent;
          tf_wins = tf_wins + 1;
        } else {
          tf_draws = tf_draws + 1;
        }
        total_battles = total_battles + 1;
      });
      setDashboardData({
        total_battles: total_battles || 0,
        tf_wins: tf_wins || 0,
        tf_losses: 0,
        tf_draws: tf_draws || 0,
        _leaderBoardAgents: _leaderBoardAgents || [],
      });
    } else {
      setDashboardData({
        total_battles: 0,
        tf_wins: 0,
        tf_losses: 0,
        tf_draws: 0,
        _leaderBoardAgents: [],
      });
    }
  }, [JSON.stringify(battlesData), state?.agents, state?.selectedArenaMode]);
  return <DashboardScreen dashboardData={dashboardData} />;
};

export default DashboardContainer;
