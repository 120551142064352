import io from "socket.io-client";
import envConfig from "../../envConfig";
let socket;
const joinWorkspace = (workspaceID, username) => {
  socket = io(`${envConfig.REACT_APP_WORKSPACE_COLLAB}`);
  console.log(`Connecting socket...`);
  if (socket && workspaceID)
    socket.emit("join", {
      room: workspaceID,
      username,
    });
};
const leaveWorkspace = (workspaceID, username) => {
  console.log("Disconnecting socket...");
  if (socket)
    socket.disconnect({
      room: workspaceID,
      username,
    });
};
const listenToNotifications = (cb) => {
  if (!socket) return true;
  socket.on("init", (msg) => {
    return cb(null, {
      event: "init",
      msg,
    });
  });
  socket.on("user-joined", (msg) => {
    return cb(null, {
      event: "user-joined",
      msg,
    });
  });
  socket.on("user-left", (msg) => {
    return cb(null, {
      event: "user-left",
      msg,
    });
  });
  // handle connection error
  socket.on("connect_error", (err) => {
    return cb(null, {
      event: "connect_error",
      msg: "Connection error" + err,
    });
  });
};

export default {
  joinWorkspace,
  leaveWorkspace,
  listenToNotifications,
};
