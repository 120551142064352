import { DataStore } from "@aws-amplify/datastore";
import { Site } from "../../models";

const createSite = async (object) => {
  return DataStore.save(new Site(object));
};

const updateSite = async (id, args) => {
  const original = await DataStore.query(Site, id);
  return DataStore.save(
    Site.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteSite = async (id) => {
  return DataStore.delete(Site, (ent) => ent.id("eq", id));
};

export default {
  createSite,
  updateSite,
  deleteSite,
};
