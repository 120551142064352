import { AppText } from "@toothfairy/shared-ui";
import AppDot from "@toothfairy/shared-ui/AppDot";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";

const Notification = ({ label, color, dotValue, isProgress = true, style }) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View
      style={[
        {
          flexDirection: "row",
          alignItems: "center",
          // alignSelf: "flex-end",
          marginRight: 5,
        },
        style,
      ]}
    >
      {isProgress && (
        <AppDot
          forcedValue={dotValue}
          dotSize={8}
          dotStyle={{
            marginRight: 10,
            backgroundColor: currentTheme?.theme[color],
          }}
          isProgress={isProgress}
        />
      )}
      <AppText
        style={{
          fontWeight: "bold",
        }}
        color={currentTheme?.theme[color]}
      >
        {label}
      </AppText>
    </View>
  );
};

export default Notification;
