import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import React from "react";
import ToothFairyToast from "../Components/ToothFairyComponents/ToothFairyToast";
import { GenerationDomains, BaseModelType } from "../models";
import countries from "./countries.json";

const date = new Date();
const timezoneOffset = date.getTimezoneOffset() * -1;
const config = {
  testAnExample: {
    isCategoriesAndToolbarVisibile: false,
  },
};
const defaultDocumentID = "newDocID";
const notificationConfig = {
  successColor: "green",
  dangerColor: "red",
  warningColor: "orange",
  normalColor: "gray",
  placement: "bottom",
  duration: 3000,
  animationType: "slide-in",
  animationDuration: 100,
  offset: 10,
  icon: <AppIcon color={"white"} icon={AppAssets.icons.ai} />,
  successIcon: <AppIcon icon={AppAssets.icons.check} />,
  dangerIcon: <AppIcon icon={AppAssets.icons.issue} />,
  warningIcon: <AppIcon icon={AppAssets.icons.risk} />,
  textStyle: { fontSize: 16 },
  renderToast: (toastOptions) => {
    return <ToothFairyToast {...toastOptions} />;
  }, // offset for both top and bottom toasts
};
const baseTopics = [
  // {
  //   label: "WEBSITE",
  //   backgroundColor: "#00A6A6",
  //   type: "topic",
  // },
  // {
  //   label: "PRIVATE",
  //   backgroundColor: "#EFCA08",
  //   type: "topic",
  // },
  {
    label: "DEFAULT",
    backgroundColor: "#FF934F",
    type: "topic",
  },
];

const defaultProductivityIntents = [
  {
    label: "TASK",
    backgroundColor: "#00A6A6",
  },
  {
    label: "REQUIREMENT",
    backgroundColor: "#EFCA08",
  },
  {
    label: "ISSUE",
    backgroundColor: "#FF934F",
  },
  {
    label: "RISK",
    backgroundColor: "#e63f08",
  },
  {
    label: "INSIGHT",
    backgroundColor: "#C2E812",
  },
  {
    label: "DECISION",
    backgroundColor: "#AA3E98",
  },
  {
    label: "MEETING",
    backgroundColor: "#FB9F89",
  },
  {
    label: "OPPORTUNITY",
    backgroundColor: "#77b58d",
  },
  {
    label: "REMINDER",
    backgroundColor: "#9297C4",
  },
  {
    label: "UNDEFINED",
    backgroundColor: "#BFCBC2",
  },
];

const defaultSentimentIntents = [
  {
    label: "POSITIVE",
    backgroundColor: "#7ccf00",
  },
  {
    label: "NEUTRAL",
    backgroundColor: "#c5cf00",
  },
  {
    label: "NEGATIVE",
    backgroundColor: "#cf9400",
  },
];

export const defaultConsumptionObject = {
  generator: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  questioner: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  translator: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  answerer: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  parser: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  tokenizer: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  embedder: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  detector: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  chatter: {
    requests: 0,
    words: 0,
    tokens: 0,
    sentences: 0,
  },
  lastUpdate: null,
};

const generateBaseIntentsFromBaseModel = (model) => {
  switch (model) {
    case "none":
      return [];
    case "sentiment":
      return defaultSentimentIntents;
    case "productivity":
      return defaultProductivityIntents;
    default:
      break;
  }
};
const sideMenuWidth = 250;
const availableLanguages = [
  { id: "en", name: "English" },
  { id: "it", name: "Italian" },
  { id: "nl", name: "Dutch" },
];
const availableRCLanguages = [
  ...availableLanguages,
  { id: "def", name: "Default to question language" },
];

const mapKeysToNames = (key, isBatch) => {
  switch (key) {
    case "nlp":
      return "Sentiment reviewer";
    case "nlpTraining":
      return "Sentiment reviewer";
    case "rc":
      return isBatch ? "Business analyst" : "Reasoning";
    case "readComprehensionTraining":
      return isBatch ? "Business analyst" : "Reasoning";
    case "gn":
      return isBatch ? "Content creator" : "Generative AI";
    case "generativeTraining":
      return isBatch ? "Content creator" : "Generative AI";
    case "conversationalTraining":
      return isBatch ? "Chat agent" : "Conversational AI";
    case "cn":
      return isBatch ? "Chat agent" : "Conversational AI";
    default:
      return "";
  }
};
const onboardingData = {
  name: {
    icon: AppAssets.icons.account,
    fieldInitialValue: { name: "" },
    fieldName: "name",
    fieldPlaceholder: "Your full name",
    fieldValidation: AppForms.yup.object().shape({
      name: AppForms.yup.string().required().label("Your full name"),
    }),
    title: "What's your full name?",
  },
  timezone: {
    icon: AppAssets.icons.clock,
    fieldInitialValue: {
      timezone: AppUtilities.timezoneData().find(
        (u) => u.timezoneOffset === timezoneOffset
      ).id,
    },
    fieldName: "timezone",
    fieldOptions: AppUtilities.timezoneData(),
    fieldPlaceholder: "Select timezone",
    fieldValidation: AppForms.yup.object().shape({
      timezone: AppForms.yup.string().required().label("Your timezone"),
    }),
    title: "Let's align the clocks",
  },
  theme: {
    icon: AppAssets.icons.paintRoller,
    fieldInitialValue: { id: "light", theme: "light", title: "Light" },
    fieldName: "theme",
    fieldOptions: [
      { id: "light", title: "Light" },
      { id: "dark", title: "Dark" },
      // { id: "blue", title: "ToothFairyAI" },
    ],
    fieldPlaceholder: "Select theme",
    fieldValidation: AppForms.yup.object().shape({
      theme: AppForms.yup.string().required().label("Your theme"),
    }),
    title: "Choose your theme",
  },
  workspaceName: {
    icon: AppAssets.icons.enableAI,
    fieldInitialValue: { workspaceName: "" },
    fieldName: "workspaceName",
    fieldPlaceholder: "AI workspace name",
    fieldValidation: AppForms.yup.object().shape({
      workspaceName: AppForms.yup
        .string()
        .required()
        .label("Your AI workspace"),
    }),
    title: "Name of your AI workspace",
  },
};

const workspaceCreationData = {
  workspaceType: {
    icon: AppAssets.icons.enableAI,
    fieldInitialValue: { workspaceType: "flat" },
    fieldName: "workspaceType",
    fieldOptions: [
      { id: "hierarchical", title: "Hierarchical" },
      { id: "flat", title: "Flat" },
    ],
    fieldPlaceholder: "Select intent structure",
    fieldValidation: AppForms.yup.object().shape({
      workspaceType: AppForms.yup
        .string()
        .required()
        .label("Workspace intent structure"),
    }),
    title: "Choose intent structure",
  },
  workspaceMed: {
    icon: AppAssets.icons.paintRoller,
    fieldInitialValue: { workspaceMed: false },
    fieldName: "workspaceMed",
    fieldOptions: [
      { id: "false", title: "False" },
      { id: "true", title: "True" },
    ],
    fieldPlaceholder: "The workspace needs medical NERs",
    fieldValidation: AppForms.yup.object().shape({
      workspaceType: AppForms.yup
        .string()
        .required()
        .label("Workspace medical settings"),
    }),
    title: "Choose workspace medical settings",
  },
  workspaceBaseModel: {
    fieldName: "baseModel",
    fieldInitialValue: { baseModel: "NONE" },
    fieldOptions: [
      ...Object.keys(BaseModelType).map((key) => ({
        id: key,
        title: key,
      })),
    ],
    title: "Choose a base model",
  },
  workspaceDomain: {
    fieldName: "domain",
    fieldInitialValue: { domain: "DEFAULT" },
    fieldOptions: [
      ...Object.keys(GenerationDomains).map((key) => ({
        id: key,
        title: key,
      })),
    ],
    title: "Choose your domain",
  },
  subscriptionType: {
    fieldName: "subscriptionType",
    fieldInitialValue: { subscriptionType: "base" },
    fieldOptions: [
      { id: "base", title: "Base" },
      { id: "pro", title: "Pro" },
      { id: "enterprise", title: "Enterprise" },
    ],
    title: "Choose a subscription",
  },
};
const defaultModerations = {
  illegal:
    "Illegal activity, including content that promotes or facilitates the sale or use of illegal or regulated substances",
  "child abuse":
    "child sexual abuse material or any content that exploits or harms children.",
  "hate violence harassment":
    "Generation of hateful, harassing, or violent content: content that expresses, incites, or promotes hate based on identity, content that intends to harass, threaten, or bully an individual, content that promotes or glorifies violence or celebrates the suffering or humiliation of others.",
  malware:
    "Generation of malware: content that attempts to generate code that is designed to disrupt, damage, or gain unauthorized access to a computer system.",
  "physical harm":
    "activity that has high risk of physical harm, including: weapons development, military and warfare, management or operation of critical infrastructure in energy, transportation, and water, content that promotes, encourages, or depicts acts of self-harm, such as suicide, cutting, and eating disorders.",
  "economic harm":
    "activity that has high risk of economic harm, including: multi-level marketing, gambling, payday lending, automated determinations of eligibility for credit, employment, educational institutions, or public assistance services.",
  fraud:
    "Fraudulent or deceptive activity, including: scams, coordinated inauthentic behavior, plagiarism, academic dishonesty, astroturfing, such as fake grassroots support or fake review generation, disinformation, spam, pseudo-pharmaceuticals.",
  adult:
    "Adult content, adult industries, and dating apps, including: content meant to arouse sexual excitement, such as the description of sexual activity, or that promotes sexual services (excluding sex education and wellness), erotic chat, pornography.",
  political:
    "Political campaigning or lobbying, by: generating high volumes of campaign materials, generating campaign materials personalized to or targeted at specific demographics, building conversational or interactive systems such as chatbots that provide information about campaigns or engage in political advocacy or lobbying, building products for political campaigning or lobbying purposes.",
  privacy:
    "Activity that violates people's privacy, including: tracking or monitoring an individual without their consent, facial recognition of private individuals, classifying individuals based on protected characteristics, using biometrics for identification or assessment, unlawful collection or disclosure of personal identifiable information or educational, financial, or other protected records.",
  "unqualified law":
    "Engaging in the unauthorized practice of law, or offering tailored legal advice without a qualified person reviewing the information.",
  "unqualified financial":
    "Offering tailored financial advice without a qualified person reviewing the information.",
  "unqualified health":
    "Telling someone that they have or do not have a certain health condition, or providing instructions on how to cure or treat a health condition.",
  "unqualified education":
    "Offering tailored educational advice without a qualified person reviewing the information.",
  "prompt injection":
    "Attempts to manipulate or bypass the system's programming or operational guidelines, including: instructions asking the agent to ignore or discolse its own safety, security, ethical guidelines, or designed operational parameters, crafting prompts to produce output that would otherwise be restricted or filtered, exploiting any system vulnerabilities to alter the agent's functions, or soliciting information on how to modify the agent's behavior against protocol.",
};
const appButtonHeight = 35;
const saveTimer = 2000;
const screenResponsiveWidth = 888;
const chartMaxHeight = 400;
const inactiveTimeout = 1000 * 60 * 30;
const initialWorkspaceValues = {
  workspaceName: "",
  workspaceType: "flat",
  workspaceMed: false,
  subscriptionType: "base",
};
const NLP_OPTIONS = [
  {
    id: "IMP_SELF",
    label: "SelfNLPCaption",
    logicalPropertyName: "isImplicitSelfEnabled",
  },
  {
    id: "SENT_SPLIT",
    label: "SplitNLPCaption",
    logicalPropertyName: "isSentenceSplitEnabled",
  },
];

const MRC_OPTIONS = [
  // {
  //   id: "QA_AS_NER",
  //   label: "qaAsNER",
  //   logicalPropertyName: "answerAsNER",
  // },
  // {
  //   id: "QA_BY_PAR",
  //   label: "qaByPar",
  //   logicalPropertyName: "answerByParagraph",
  // },
  {
    id: "UNI_QA",
    label: "uniQA",
    logicalPropertyName: "uniqueAnswer",
  },
  {
    id: "BLEND_QA",
    label: "blenderQA",
    logicalPropertyName: "qaBlender",
  },
  {
    id: "COMMON_KB",
    label: "knReply",
    logicalPropertyName: "qaAiKnowledge",
  },
];

const DOWNLOAD_OPTIONS = [
  {
    id: "NER_DATA",
    label: "NER Dataset",
    filename: "ner_dataset.json",
    type: "NLP",
  },
  {
    id: "INTENT_DATA",
    label: "Intent Dataset",
    filename: "intent_dataset.json",
    type: "NLP",
  },
  {
    id: "TEST_NER_DATA",
    label: "Validation NER Dataset",
    filename: "ner_test_dataset.json",
    type: "NLP",
  },
  {
    id: "TEST_INTENT_DATA",
    label: "Validation Intent Dataset",
    filename: "intent_test_dataset.json",
    type: "NLP",
  },
  {
    id: "MODEL",
    label: "Model",
    type: "NLP",
    filename: "model.tar.gz",
  },
  {
    id: "META_DATA",
    label: "Metadata",
    filename: "meta.json",
    type: "NLP",
  },
  {
    id: "QA_DATA",
    label: "Training Dataset",
    type: "QA",
    filename: "openai_dataset.jsonl",
  },
  {
    id: "QA_TEST_DATA",
    label: "Validation Dataset",
    type: "QA",
    filename: "test_openai_dataset.jsonl",
  },
  {
    id: "META_DATA",
    label: "Metadata",
    type: "QA",
    filename: "metrics.json",
  },
  {
    id: "GN_DATA",
    label: "Training Dataset",
    type: "GN",
    filename: "genai_dataset.jsonl",
  },
  {
    id: "GN_TEST_DATA",
    label: "Validation Dataset",
    type: "GN",
    filename: "test_genai_dataset.jsonl",
  },
  {
    id: "GN_META_DATA",
    label: "Metadata",
    type: "GN",
    filename: "metrics.json",
  },
  {
    id: "CN_DATA",
    label: "Training Dataset",
    type: "CN",
    filename: "convai_dataset.jsonl",
  },
  {
    id: "CN_TEST_DATA",
    label: "Validation Dataset",
    type: "CN",
    filename: "test_convai_dataset.jsonl",
  },
  {
    id: "CN_META_DATA",
    label: "Metadata",
    type: "CN",
    filename: "metrics.json",
  },
];

const BATCH_DOWNLOAD_OPTIONS = [
  {
    id: "OUTPUT_DATA",
    label: "Output",
    filename: "output.csv",
    type: "ALL",
  },
  {
    id: "META_DATA",
    label: "Metadata",
    filename: "metadata.json",
    type: "ALL",
  },
  {
    id: "INPUT_DATA",
    label: "Input",
    filename: "input.csv",
    type: "ALL",
  },
];

const NOTIFICATION_OPTIONS = [
  {
    id: "NOTIFICATION_ON_TR_COMPLETED",
    label: "Email on completed",
    logicalPropertyName: "emailOnTrainingCompleted",
    type: "TR",
  },
  {
    id: "NOTIFICATION_ON_TR_FAILED",
    label: "Email on failed",
    logicalPropertyName: "emailOnTrainingFailed",
    type: "TR",
  },
  {
    id: "NOTIFICATION_ON_TR_START",
    label: "Email on start",
    logicalPropertyName: "emailOnTrainingStart",
    type: "TR",
  },
  {
    id: "NOTIFICATION_ON_BT_COMPLETED",
    label: "Email on completed",
    logicalPropertyName: "emailOnBatchCompleted",
    type: "BT",
  },
  {
    id: "NOTIFICATION_ON_BT_FAILED",
    label: "Email on failed",
    logicalPropertyName: "emailOnBatchFailed",
    type: "BT",
  },
  {
    id: "NOTIFICATION_ON_BT_START",
    label: "Email on start",
    logicalPropertyName: "emailOnBatchStart",
    type: "BT",
  },
  {
    id: "NOTIFICATION_TO_ALL_USERS",
    label: "Notify all users in workspace",
    logicalPropertyName: "notifyAllUsersInWorkspace",
    type: "TR",
  },
  {
    id: "NOTIFICATION_ON_API_CONSUMPTION",
    label: "Notify consumption",
    logicalPropertyName: "notifyOnThreasholdConsumption",
    type: "PR",
    disabledIfBase: false,
    showSlider: true,
    sliderPropertyName: "notificationThresholdConsumption",
  },
];

const supportDomain = "https://support.toothfairyai.com";
const supportUrl = `https://toothfairyai.github.io/tooth-fairy-mono-repo/docs/intro`;
const initialEditorValue = [
  {
    children: [
      {
        text: "",
      },
    ],
    type: "paragraph",
  },
];
const baseModels = [
  {
    id: "Mistral-7B-Instruct-v0.1",
    type: "conversational",
    label: "Mistral/Mixtral",
  },
  {
    id: "meta-llama/Llama-3-70b-chat-hf",
    type: "conversational",
    label: "Llama 3",
  },
  {
    id: "Qwen/Qwen2-72B-Instruct",
    type: "conversational",
    label: "Qwen 2",
  },
  {
    id: "llama-2-70b-chat",
    type: "conversational",
    label: "Llama 2",
  },
];
questionsLimit = 10;
learningRatGN = 0.001;
baseThreasholdConsumption = 90;
maxQueueWaitTime = 180;
maxStepsNLP = 40000;
patienceNLP = 1600;
baseTrainingNLPEpochs = 200;
baseTrainingRCEpochs = 20;
baseTrainingGNEpochs = 4;
batchSizeNLP = 100;
batchSizeRC = 32;
batchSizeGN = 32;
learningRateNLP = 0.01;
learningRateRC = 0.01;
learningRatGN = 0.001;
testSizeNLP = 0.1;
testSizeRC = 0.1;
testSizeGN = 0.1;
datasetTestSizeThresholdNLP = 200;
datasetTestSizeThresholdRC = 200;
datasetTestSizeThresholdGN = 200;
temperature = 0.3;
topP = 1;
maxTokens = 800;
presencePenalty = 0.5;
frequencyPenalty = 0.5;
docTopK = 4;
qaDocTopK = 4;
isAIQuestionsEnabled = false;
isShowRequestEnabled = false;
isAnswerByNEREnabled = false;
showProgrammaticChats = false;
showCost = false;
export default {
  temperature,
  topP,
  maxTokens,
  datasetTestSizeThresholdGN,
  mapKeysToNames,
  testSizeGN,
  onboardingData,
  config,
  learningRatGN,
  notificationConfig,
  defaultDocumentID,
  workspaceCreationData,
  initialWorkspaceValues,
  appButtonHeight,
  saveTimer,
  NLP_OPTIONS,
  countries,
  availableLanguages,
  sideMenuWidth,
  qaDocTopK,
  supportUrl,
  generateBaseIntentsFromBaseModel,
  screenResponsiveWidth,
  chartMaxHeight,
  availableRCLanguages,
  supportDomain,
  inactiveTimeout,
  initialEditorValue,
  MRC_OPTIONS,
  questionsLimit,
  NOTIFICATION_OPTIONS,
  baseThreasholdConsumption,
  DOWNLOAD_OPTIONS,
  maxQueueWaitTime,
  maxStepsNLP,
  patienceNLP,
  baseTrainingNLPEpochs,
  baseTrainingRCEpochs,
  baseTrainingGNEpochs,
  learningRateNLP,
  testSizeNLP,
  learningRateRC,
  testSizeRC,
  batchSizeGN,
  datasetTestSizeThresholdNLP,
  datasetTestSizeThresholdRC,
  BATCH_DOWNLOAD_OPTIONS,
  frequencyPenalty,
  presencePenalty,
  isAIQuestionsEnabled,
  isShowRequestEnabled,
  isAnswerByNEREnabled,
  showProgrammaticChats,
  showCost,
  baseTopics,
  baseModels,
  defaultModerations,
};
