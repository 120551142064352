import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import PageTitle from "../../../Components/PageTitle";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairySupportButton from "../../../Components/ToothFairyComponents/ToothFairySupportButton";
import TrainSwitcher from "../../../Components/TrainSwitcher";
import Constants from "../../../Constants";
import btnStyleGenerator from "../../../Constants/btnStyleGenerator";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { useHistory } from "../../../Router";
import { isLargeScreen } from "../../MainViewContainer";
import InputSwitcher from "./InputSwitcher";
import { initialEditorValue } from "../../TestExample/commonUtils";

const AnswerScreenHeader = ({
  isParser,
  questionInProgress,
  answerInProgress,
  storeInError,
  storeInProgress,
  isSaved,
  handleNewDocument,
  routeToParsing,
  setIsParser,
  documentId,
  setIsTrainerNoDocumentId,
  setIsTopicModeOn,
  isTopicModeOn,
  isAutoQuestionOn,
  setIsAutoQuestionModeOn,
  isTopicTrainingScope,
  setisTopicTrainingScopeAsTopic,
  isTrainerNoDocumentId,
  isDirty,
  handleShowTrData,
  setIsValidationData,
  isValidationData,
  setAgent,
  setPrompt,
  agent,
  setQValue,
  forceEditorWithValue,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const history = useHistory();
  const { getActiveWorkspace, getPlanConfig } = useWorkspaces();
  const commonBtnStyle = useMemo(
    () => btnStyleGenerator.commonBtnStyleGenerator(currentTheme),
    [currentTheme]
  );
  const _styles = styleGenerator({
    currentTheme,
  });

  return (
    <View style={_styles.titleContainer}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <PageTitle marginRight={10}>Reading comprehension</PageTitle>
        <ToothFairySupportButton />
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
          width: "100%",
        }}
      >
        <TrainSwitcher
          parseInProgress={questionInProgress || answerInProgress}
          storeInError={storeInError}
          storeInProgress={storeInProgress}
          isSaved={isSaved}
          isParser={isParser}
          setIsValidationData={setIsValidationData}
          isValidationData={isValidationData}
          handleNewDocument={handleNewDocument}
          routeBackToParser={() => {
            routeToParsing();
          }}
          isTrainingEnabled={getPlanConfig()?.isTrainingQAEnabled}
          setIsParser={setIsParser}
          documentId={documentId}
          setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
          childrenComponentRelativePosition="left"
          isTrainerNoDocumentId={isTrainerNoDocumentId}
        >
          <View
            style={{
              paddingTop: 5,
              flexDirection: "column",
              width: "auto",
              justifyContent: "space-between",
            }}
          >
            {!isParser && isLargeScreen() && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {Constants.isShowRequestEnabled && (
                  <ToothFairyButton
                    {...commonBtnStyle}
                    onPress={handleShowTrData}
                    title="Show Request"
                  />
                )}

                <ToothFairyButton
                  title={isTrainerNoDocumentId ? "Save" : "New"}
                  onPress={() =>
                    handleNewDocument(
                      !isTrainerNoDocumentId,
                      initialEditorValue
                    )
                  }
                  isDisabled={!isDirty}
                  {...commonBtnStyle}
                />
              </View>
            )}
            <View style={{ flexDirection: "row" }}>
              {isLargeScreen() && isParser && (
                <InputSwitcher
                  parseInProgress={questionInProgress || answerInProgress}
                  setIsTopicModeOn={setIsTopicModeOn}
                  isTopicModeOn={isTopicModeOn}
                  isAutoQuestionOn={isAutoQuestionOn}
                  setIsAutoQuestionModeOn={setIsAutoQuestionModeOn}
                  isParser={isParser}
                  isTopicTrainingScope={isTopicTrainingScope}
                  setisTopicTrainingScopeAsTopic={
                    setisTopicTrainingScopeAsTopic
                  }
                  setAgent={setAgent}
                  setPrompt={setPrompt}
                  agent={agent}
                  setQValue={setQValue}
                  forceEditorWithValue={forceEditorWithValue}
                />
              )}
            </View>
          </View>
        </TrainSwitcher>
      </View>
    </View>
  );
};

const styleGenerator = ({ currentTheme }) => {
  return StyleSheet.create({
    titleContainer: {
      alignItems: "flex-start",
      width: "100%",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
  });
};

export default AnswerScreenHeader;
