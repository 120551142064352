import { Hub } from "@aws-amplify/core";
import useAPI from "@toothfairy/shared-api/useApi";
import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppQuantumEditor from "@toothfairy/shared-ui/AppQuantumEditor";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { createEditor, Editor } from "slate";
import "../../../../shared/src/styles/Editor/styles.css";
import envConfig from "../../../envConfig";
import useWorkspaces from "../../Hooks/useWorkspaces";
// import HocuspocusProvider from "@hocuspocus/provider";
import { withCursors, withYHistory, withYjs, YjsEditor } from "@slate-yjs/core";
import alpha from "color-alpha";
import randomColor from "randomcolor";
import * as Y from "yjs";
import LoadingPlaceholder from "../LoadingPlaceholder";
import PageTitle from "../PageTitle";
import ToothFairyButton from "../ToothFairyComponents/ToothFairyButton";
import trainingAPI from "../../API/Training/trainingAPI";
import Files from "../../API/Files";

const HocuspocusProvider = require("@hocuspocus/provider");

const EditorWrapper = React.forwardRef(
  (
    {
      wrapperStyle,
      editMode,
      isInactive,
      documentId = null,
      editorState,
      onNERCreation,
      handleOnChange,
      handleAnnotation,
      nlpConfig,
      extractEntitiesFromEditorState,
      isRefreshRequired,
      value,
      setValue,
      setEditor,
      trainMode,
      parseMode,
      onSocketConnectionChange = () => {},
      onSocketSyncChange,
      title,
      forceTitle = true,
      children,
      isToolBarVisible = false,
      allowForHidingToolbar = false,
      showLabel = "Show Editor",
      hideLabel = "Hide Editor",
      wrapperTitleStyle,
      isPDF = false,
      contextualButtons,
      contextButtonLabel,
      onContextualButtonPress = () => {},
      allowImages = false,
      workspaceid,
      ...props
    },
    ref
  ) => {
    const { getActiveWorkspace } = useWorkspaces();
    const {
      data: S3uploadUrlData,
      loading: urlUploadGenerationInProgress,
      apiRequest: S3uploadUrlRequest,
      response: S3uploadResponse,
    } = useAPI(trainingAPI.bulkImportUrlGeneration, envConfig);
    const {
      data: S3downloadUrlData,
      loading: urldownloadGenerationInProgress,
      apiRequest: S3downloadUrlRequest,
      response: S3downloadResponse,
    } = useAPI(Files.downloadUrlGeneration, envConfig);
    const delay = 200;
    const [isReconnecting, setReconnecting] = useState(false);
    const { currentTheme } = AppTheme.useTheme();
    const [idToken, setToken] = useState(null);
    const [isEditorExpanded, setEditorExpanded] = useState(true);
    const [isConnectedToSocket, setConnectedToSocket] = useState(true);
    const [isSynced, setSynced] = useState(false);
    const [isConnectedToCloud, setConnectedToCloud] = useState(true);
    const [renewRTC, setRenewRTC] = useState(null);
    const { user } = AppUser.useUser();
    const handleSocketConnectionChange = (status) => {
      if (status === "disconnected") {
        console.log("disconnected --- attempting to reconnect");
        setReconnecting(true);
        setConnectedToSocket(false);
        setSynced(false);
        setTimeout(() => {
          handleReconnection();
        }, delay);
        setReconnecting(false);
      }
      onSocketConnectionChange(status);
    };
    const handleReconnection = () => {
      rtcProvider.disconnect();
      setTimeout(() => {
        setRenewRTC(Date.now());
      }, delay);

      setTimeout(() => {
        setConnectedToSocket(true);
      }, delay);
    };
    const getToken = () => {
      AppAuth.Auth.currentSession()
        .then((session) => {
          console.log("Token refresh", Date.now());
          setToken(session?.idToken?.jwtToken);
        })
        .catch((err) => console.log(err)); //Is there a beter way of doing this with Cognito?
    };
    Hub.listen("datastore", async (hubData) => {
      const { event, data } = hubData.payload;
      if (event === "networkStatus") {
        setConnectedToCloud(data.active);
      }
    });
    // Setup listener for Cognito events
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "tokenExpired": {
          getToken();
          break;
        }
        default: {
          console.log(`Unhandled event: ${event}`);
        }
      }
    });
    useEffect(() => {
      getToken();
    }, [user?.id]);

    const [sharedType, rtcProvider] = useMemo(() => {
      if (
        editMode &&
        idToken &&
        documentId &&
        !isInactive &&
        user?.id &&
        getActiveWorkspace()?.id
      ) {
        const rtcProvider = new HocuspocusProvider.HocuspocusProvider({
          url: envConfig.REACT_APP_RTC_API,
          name: documentId,
          connect: true,
          token: idToken,
          parameters: {
            documentId: documentId,
            workspaceId: getActiveWorkspace()?.id,
            userId: user?.id,
            token: idToken,
          },
          document: new Y.Doc(),
          onSynced: () => {
            setSynced(true);
          },
          onStatus: (status) => {
            onSocketConnectionChange(status?.status);
          },
          awareness: rtcProvider?.awareness,
        });
        const sharedType = rtcProvider.document.get("content", Y.XmlText);
        return [sharedType, rtcProvider];
      }
      return [null, null];
    }, [
      documentId,
      editMode,
      idToken,
      renewRTC,
      isInactive,
      user?.id,
      getActiveWorkspace()?.id,
    ]);

    const rtcEditor = useMemo(() => {
      if (!rtcProvider) return;
      if (rtcProvider?.status == "disconnected") {
        setConnectedToSocket(false);
      }

      const sharedType = rtcProvider.document.get("content", Y.XmlText);
      const color = randomColor();
      const e = withYHistory(
        withCursors(
          withYjs(createEditor(), sharedType, { autoConnect: false }),
          rtcProvider.awareness,
          {
            data: {
              alphaColor: alpha(color, 0.5),
              name: user?.username,
            },
          }
        )
      );

      // Ensure editor always has at least 1 valid child
      const { normalizeNode } = e;
      e.normalizeNode = (entry) => {
        const [node] = entry;
        if (!Editor.isEditor(node) || node.children.length > 0) {
          return normalizeNode(entry);
        }
      };

      return e;
    }, [rtcProvider?.document, user?.username]);

    useEffect(() => {
      if ((!documentId && idToken) || !isConnectedToCloud) {
        setConnectedToSocket(false);
        rtcProvider?.disconnect();
      }
      return () => {
        rtcProvider?.disconnect();
      };
    }, [documentId, idToken, isConnectedToCloud]);
    useEffect(() => {
      if (!idToken || !rtcProvider) return;
      rtcProvider.connect();
      return () => rtcProvider.disconnect();
    }, [rtcProvider, idToken]);
    useEffect(() => {
      if (!idToken || !rtcProvider) return;
      YjsEditor.connect(rtcEditor);
      return () => YjsEditor.disconnect(rtcEditor);
    }, [rtcEditor, idToken]);

    if (idToken && !isPDF) {
      return (
        <View
          style={{
            flex: isEditorExpanded ? 1 : 0,
            flexBasis: isEditorExpanded ? "100%" : 30,
          }}
        >
          {!isConnectedToSocket && allowForHidingToolbar && (
            <AppText
              clickableViewWrapperStyle={{
                alignSelf: "flex-end",
              }}
              isHoverable={false}
              rightIcon={
                !isEditorExpanded
                  ? AppAssets.icons.arrow_right
                  : AppAssets.icons.arrow_down
              }
              fontWeight="bold"
              color={currentTheme?.theme?.primary}
              isClickable
              onPress={() => {
                setEditorExpanded(!isEditorExpanded);
              }}
              wrapperStyle={{
                alignSelf: "flex-end",
                marginLeft: 10,
              }}
            >
              {isEditorExpanded ? hideLabel : showLabel}
            </AppText>
          )}

          {(isEditorExpanded || isConnectedToSocket) && (
            <View style={wrapperStyle}>
              {title && (
                <PageTitle
                  fontSize={currentTheme?.theme?.base_font_size}
                  marginRight={0}
                  style={[
                    {
                      color: currentTheme?.theme?.font_color,
                      width: "100%",
                      padding: 10,
                      backgroundColor: currentTheme?.theme?.grey,
                    },
                    wrapperTitleStyle,
                  ]}
                >
                  {title}
                </PageTitle>
              )}
              {contextualButtons && (
                <View style={{ position: "absolute", right: 20, top: 20 }}>
                  <AppText
                    isClickable
                    // isHoverable={false}
                    onPress={onContextualButtonPress}
                    leftIcon={AppAssets.icons.clear}
                    style={{
                      marginLeft: 10,
                    }}
                    color={currentTheme?.theme?.primary}
                  >
                    {contextButtonLabel}
                  </AppText>
                </View>
              )}

              {/* <ToothFairyButton
                isClickable
                onPress={() => {
                  rtcProvider?.disconnect();
                }}
                title="Reconnect"
              /> */}
              {children}

              {documentId && (isReconnecting || !isConnectedToSocket) ? (
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      margin: "auto",
                      alignItems: "center",
                    }}
                  >
                    {isReconnecting ? (
                      <>
                        <LoadingPlaceholder
                          visible={true}
                          caption="Reconnecting"
                        />
                      </>
                    ) : (
                      !isConnectedToSocket && (
                        <>
                          <AppPlaceholderView
                            accessibilityLabel="json-placeholder"
                            wrapperStyle={{
                              maxWidth: 400,
                              height: 200,
                              margin: "auto",
                            }}
                            size={60}
                            icon={AppAssets.icons.orbit}
                            mainCaption="NotConnectedCaption"
                            subCaption="NotConnectedSubCaption"
                          />
                          <ToothFairyButton
                            wrapperStyle={{ marginTop: 20 }}
                            isDisabled={!isConnectedToCloud}
                            title="Reconnect"
                            onPress={() => {
                              handleReconnection();
                            }}
                          />
                        </>
                      )
                    )}
                  </View>
                </View>
              ) : (isSynced && documentId) || !documentId ? (
                <AppQuantumEditor
                  {...props}
                  isToolBarVisible={isToolBarVisible}
                  editMode={editMode}
                  documentId={documentId}
                  timezone={user.timezone}
                  value={documentId ? rtcEditor.children : value}
                  trainMode={trainMode}
                  parseMode={parseMode}
                  setValue={setValue}
                  setEditor={setEditor}
                  editorState={editorState}
                  rtcEditor={rtcEditor}
                  forceTitle={forceTitle}
                  nlpConfig={nlpConfig}
                  allowImages={allowImages}
                  // annotationOptions={annotationOptions}
                  onNERCreation={onNERCreation}
                  onChange={handleOnChange}
                  handleAnnotation={handleAnnotation}
                  onSocketConnectionChange={handleSocketConnectionChange}
                  extractEntitiesFromEditorState={
                    extractEntitiesFromEditorState
                  }
                  workspaceid={getActiveWorkspace()?.id}
                  S3uploadUrlRequest={allowImages && S3uploadUrlRequest}
                  S3downloadUrlRequest={allowImages && S3downloadUrlRequest}
                  isRefreshRequired={isRefreshRequired}
                  ref={ref}
                  idToken={idToken}
                  username={user.username}
                  websocketEndpoint={envConfig.REACT_APP_RTC_API}
                  sharedType={sharedType}
                  rtcProvider={rtcProvider}
                />
              ) : (
                <LoadingPlaceholder
                  visible={true}
                  caption="Setting up real time collab"
                />
              )}
            </View>
          )}
        </View>
      );
    }
    return <></>;
  }
);

export default EditorWrapper;
