import { loadStripe } from "@stripe/stripe-js";
import useAPI from "@toothfairy/shared-api/useApi";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import envConfig from "../../../../envConfig";
import API_Consumption from "../../../API/API_Consumption";
import BillingDetailsView from "./BillingDetailsView";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppUser from "@toothfairy/shared-ui/AppUser";
import {
  isBillingAddressSet,
  isFirstTrialStarted,
  isUserDelinquent,
  isUserEnabledToOperate,
  isUserOnTrial,
  isUserWithNoPaymentMethod,
  isSSO,
} from "../../../../App";

const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_PK);

const BillingDetailsContainer = ({ isWizard }) => {
  const { t } = useTranslation();
  const { user } = AppUser.useUser();
  const [invoices, setInvoices] = useState([]);
  const [isPaymentMethodEditMode, setPaymentMethodEditMode] = useState(false);
  const AppToasty = AppToast.useToast();
  const {
    data: stripeCustomerData,
    error: stripeCustomerAPIError,
    loading: stripeCustomerAPIInProgress,
    apiRequest: fetchStripeCustomer,
  } = useAPI(API_Consumption.getStripeCustomer);

  const {
    data: stripeClientSecretData,
    error: stripeClientSecretAPIError,
    loading: stripeClientSecretAPIInProgress,
    apiRequest: fetchStripeClientSecret,
  } = useAPI(API_Consumption.getStripeClientSecret);

  const {
    data: stripeClientInvoicesData,
    error: stripeClientInvoicesAPIError,
    loading: stripeClientInvoicesAPIInProgress,
    apiRequest: fetchStripeClientInvoices,
  } = useAPI(API_Consumption.getStripeClientInvoices);

  const {
    loading: clientPaymentIntentAPIInProgress,
    apiRequest: createClientPaymentIntent,
  } = useAPI(API_Consumption.createClientPaymentIntent);

  const {
    loading: paymentMethodAPIInProgress,
    apiRequest: deletePaymentMethod,
  } = useAPI(API_Consumption.deleteClientPaymentMethod);

  const { apiRequest: fetchStripeCheckout } = useAPI(
    API_Consumption.getStripeCheckout
  );
  const { apiRequest: saveStripeCustomer, loading: saveInProgress } = useAPI(
    API_Consumption.saveStripeCustomer
  );
  const {
    data: stripePortalData,
    error: stripePortalAPIError,
    loading: stripePortalAPIInProgress,
    apiRequest: fetchStripePortal,
  } = useAPI(API_Consumption.getStripePortal);

  const {
    data: paymentMethodsData,
    error: paymentMethodsAPIError,
    loading: paymentMethodsAPIInProgress,
    apiRequest: fetchPaymentMethods,
  } = useAPI(API_Consumption.getClientPaymentMethods);
  const setupCustomStripeDetails = (resultFromStripe) => {
    if (resultFromStripe) {
      isUserDelinquent(resultFromStripe.delinquent);
      isFirstTrialStarted(resultFromStripe.trialEndDate != null);
      isBillingAddressSet(resultFromStripe.address !== null);
      isUserOnTrial(
        resultFromStripe.trialEndDate !== null &&
          resultFromStripe.trialEndDate * 1000 > Date.now()
      );
      isUserWithNoPaymentMethod(
        resultFromStripe.invoice_settings.default_payment_method === null
      );
      isUserEnabledToOperate(
        !resultFromStripe.delinquent &&
          resultFromStripe.invoice_settings.default_payment_method !== null
      );
    }
  };
  useEffect(() => {
    if (stripeCustomerAPIError)
      AppToasty.show(t("StripeCustomerFetchFailed"), { type: "danger" });
    if (stripeClientSecretAPIError)
      AppToasty.show(t("StripeClientSecretFetchFailed"), { type: "danger" });
    if (stripeClientInvoicesAPIError)
      AppToasty.show(t("StripeClientInvoicesFetchFailed"), { type: "danger" });
    if (stripePortalAPIError)
      AppToasty.show(t("StripePortalFetchFailed"), { type: "danger" });
    if (paymentMethodsAPIError)
      AppToasty.show(t("PaymentMethodsFetchFailed"), { type: "danger" });
  }, [
    stripeCustomerAPIError,
    stripeClientSecretAPIError,
    stripeClientInvoicesAPIError,
    stripePortalAPIError,
  ]);
  const onPaymentMethodSave = async (pm_id) => {
    await saveStripeCustomer({
      invoice_settings: {
        default_payment_method: pm_id,
      },
    });
    await fetchPaymentMethods();
    const resultFromStripe = await fetchStripeCustomer();
    if (resultFromStripe) {
      setupCustomStripeDetails(resultFromStripe);
    }
  };
  const loadMoreInvoices = async () => {
    // get last item from stripeClientInvoicesData
    const lastInvoiceId =
      stripeClientInvoicesData?.invoices?.data[
        stripeClientInvoicesData?.invoices?.data.length - 1
      ]?.id;
    await fetchStripeClientInvoices(lastInvoiceId);
  };
  const fetchCustomerAddress = async () => {
    try {
      await Promise.all([
        fetchStripeCustomer(),
        fetchStripeClientSecret(),
        fetchStripeClientInvoices(),
        fetchPaymentMethods(),
      ]);
    } catch (err) {
      console.error(err);
      AppToasty.show(t("StripeCustomerFetchFailed"), { type: "danger" });
    }
  };

  useEffect(() => {
    if (!stripeClientInvoicesData || stripeClientInvoicesData?.length === 0) {
      setInvoices(stripeClientInvoicesData?.invoices?.data || []);
    } else {
      setInvoices([...invoices, ...stripeClientInvoicesData?.invoices?.data]);
    }
  }, [stripeClientInvoicesData]);

  const handlePaymentPortalOpening = async () => {
    await createClientPaymentIntent({
      userID: user.id,
      stripeCustomerId: user.stripeCustomerId,
    });
    await fetchStripeClientSecret();
    setPaymentMethodEditMode(true);
  };

  const handlePaymentMethodSave = async () => {
    return Promise.all(
      paymentMethodsData?.paymentMethods?.data?.map((u) =>
        deletePaymentMethod({
          paymentMethodId: u.id,
        })
      )
    );
  };

  const fetchCheckoutSession = async () => {
    try {
      const sessionResponse = fetchStripeCheckout();
      const { sessionId } = await sessionResponse;

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId,
      });
    } catch (err) {
      console.error(err);
      AppToasty.show(t("StripeCheckoutFetchFailed"), { type: "danger" });
    }
  };

  const saveCustomerDetails = async (values) => {
    try {
      await saveStripeCustomer({
        name: values.name,
        address: {
          ...values,
          name: undefined,
        },
      });
      const resultFromStripe = await fetchStripeCustomer();
      if (resultFromStripe) {
        setupCustomStripeDetails(resultFromStripe);
      }
      AppToasty.show(t("StripeCustomerSaveSuccess"), { type: "success" });
      isWizard && fetchCheckoutSession();
    } catch (err) {
      console.error("Error occured while saving the customer", err);
      AppToasty.show(t("StripeCustomerSaveFailed"), { type: "danger" });
    }
  };

  const goToPortal = async () => {
    try {
      fetchStripePortal();
    } catch (error) {
      console.error(error);
      AppToasty.show(t("StripeCheckoutFetchFailed"), { type: "danger" });
    }
  };

  useEffect(() => {
    fetchCustomerAddress();
  }, []);

  useEffect(() => {
    if (stripePortalData) {
      const { portalUrl } = stripePortalData;
      if (portalUrl) {
        window.open(portalUrl, "_blank");
      }
    }
  }, [stripePortalData]);

  return isSSO() ? (
    <AppPlaceholderView
      wrapperStyle={{
        width: 335,
        height: "auto",
        minHeight: 200,
        marginVertical: "auto",
        marginHorizontal: "auto",
      }}
      mainCaption={"SSO users cannot register payment methods"}
      subCaption={"Contact your administrator for more information"}
      size={80}
      icon={AppAssets.icons.orbit}
    />
  ) : (
    <BillingDetailsView
      isPaymentMethodEditMode={isPaymentMethodEditMode}
      setPaymentMethodEditMode={setPaymentMethodEditMode}
      stripeCustomerAPIInProgress={stripeCustomerAPIInProgress}
      stripePortalAPIInProgress={stripePortalAPIInProgress}
      paymentMethodAPIInProgress={paymentMethodAPIInProgress}
      stripeClientInvoicesAPIInProgress={stripeClientInvoicesAPIInProgress}
      stripeClientSecretAPIInProgress={stripeClientSecretAPIInProgress}
      clientPaymentIntentAPIInProgress={clientPaymentIntentAPIInProgress}
      paymentMethodsAPIInProgress={paymentMethodsAPIInProgress}
      stripeCustomerData={stripeCustomerData}
      stripeClientSecretData={stripeClientSecretData}
      stripeClientInvoicesData={stripeClientInvoicesData}
      paymentMethodsData={paymentMethodsData}
      stripePromise={stripePromise}
      isWizard={isWizard}
      saveCustomerDetails={saveCustomerDetails}
      saveInProgress={saveInProgress}
      goToPortal={goToPortal}
      handlePaymentPortalOpening={handlePaymentPortalOpening}
      handlePaymentMethodSave={handlePaymentMethodSave}
      onPaymentMethodSave={onPaymentMethodSave}
      loadMoreInvoices={loadMoreInvoices}
      invoices={invoices}
    />
  );
};

export default BillingDetailsContainer;
