import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import React from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";

const AuthorisationsSettingsToolbar = ({
  searchBtnLabel = "Search",
  searchBtnAccLabel = "searchButton",
  handleSearch = () => {},
  handleCreation = () => {},
}) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [type, setType] = React.useState("");
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: -10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={name}
          onChangeText={setName}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Name"
        />
        <AppTextInput
          value={description}
          onChangeText={setDescription}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Description"
        />
      </View>

      <ToothFairyButton
        wrapperStyle={{ marginTop: 10 }}
        title={searchBtnLabel}
        accessibilityLabel={searchBtnAccLabel}
        onPress={() => handleSearch(name, description, type)}
      />
      <ToothFairyButton
        wrapperStyle={{
          alignSelf: "center",
          marginTop: 10,
        }}
        title="Create"
        onPress={handleCreation}
      />
    </View>
  );
};

export default AuthorisationsSettingsToolbar;
