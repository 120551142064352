import Clipboard from "@react-native-clipboard/clipboard";
import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import { isLargeScreen, isMobileScreen } from "../../../MainViewContainer";

const AdminInfoLeaf = ({
  handleClick,
  value,
  caption,
  subcaption,
  title,
  accessibilityLabel,
  width = 475,
  titleStyle,
  wrapperStyle,
  textContainer,
  textStyle,
  marginLeft = 100,
  label = "WorkspaceAPIkey",
  showSimpleText = false,
  subCaptionColor,
  showCopyIconOnSimpleText = true,
  simpleTextWrapperStyle,
  simpleTextStyle,
  titleColor,
  hideShowIcon = false,
}) => {
  const { width: windowWidth } = useWindowDimensions();
  const AppToasty = AppToast.useToast();

  const { t } = useTranslation();
  const { currentTheme } = AppTheme.useTheme();
  const handleDefaultClick = () => {
    Clipboard.setString(value);
    AppToasty.show(t("VcopyCaption"), {
      type: "success",
    });
  };
  return (
    <View
      style={[
        {
          flexDirection: "column",
          alignItems: "center",
        },
        wrapperStyle,
      ]}
    >
      {title && (
        <AppText
          wrapperStyle={[
            { alignSelf: "flex-start", marginTop: 20 },
            titleStyle,
          ]}
          fontWeight="bold"
          color={titleColor || currentTheme?.theme?.font_color}
        >
          {title}
        </AppText>
      )}
      {caption && (
        <AppText
          wrapperStyle={{
            alignSelf: "flex-start",
            marginTop: 10,
            maxWidth: windowWidth - 75,
          }}
          numberOfLines={3}
        >
          {caption}
        </AppText>
      )}
      {showSimpleText ? (
        <AppText
          wrapperStyle={[
            {
              alignSelf: "flex-start",
              maxWidth: windowWidth - 75,
            },
            simpleTextWrapperStyle,
          ]}
          style={[
            {
              marginRight: 10,
            },
            simpleTextStyle,
          ]}
          numberOfLines={3}
          onRightIconPress={handleClick || handleDefaultClick}
          isClickable
          rightIcon={showCopyIconOnSimpleText && AppAssets.icons.copy}
          fontWeight="bold"
        >
          {!isMobileScreen() ? value : ""}
        </AppText>
      ) : (
        <AppTextInput
          editable={false}
          selectTextOnFocus={false}
          hideShowIcon={hideShowIcon}
          accessibilityLabel={accessibilityLabel}
          onRightIconClick={handleClick || handleDefaultClick}
          value={value || ""}
          label={label}
          rightIcon={AppAssets.icons.copy}
          textContainer={[
            {
              width: Math.min(windowWidth - 75, 500),
              backgroundColor: currentTheme?.theme?.lightGrey,
              marginLeft: isLargeScreen() ? marginLeft : 0,
              marginVertical: 20,
            },
            textContainer,
          ]}
          style={[
            {
              backgroundColor: currentTheme?.theme?.lightGrey,
            },
            textStyle,
          ]}
        />
      )}

      {subcaption && (
        <AppText
          color={subCaptionColor || currentTheme?.theme?.darkGrey}
          numberOfLines={3}
        >
          {subcaption}
        </AppText>
      )}
    </View>
  );
};

export default AdminInfoLeaf;
