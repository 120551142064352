import AppButton from "@toothfairy/shared-ui/AppButton";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { StyleSheet, View } from "react-native";
import ToothFairySearch from "../../../Components/ToothFairyComponents/ToothFairySearch";
import Constants from "../../../Constants";
import DateFilter from "./Leafs/DateFilter";
import OperationsFilter from "./Leafs/OperationsFilter";

const LogsFilter = ({
  startDate,
  endDate,
  onUpdateEndDate,
  onUpdateStartDate,
  triggerFetchLogs,
  logTypes,
  fetchingLogTypes,
  selectedServiceType,
  selectedFilterLimit,
  setSelectedFilterLimit,
  setSelectedServiceType,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const styles = styleGenerator(currentTheme);
  return (
    <ToothFairySearch handleSearch={triggerFetchLogs}>
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        onUpdateEndDate={onUpdateEndDate}
        onUpdateStartDate={onUpdateStartDate}
      />
      <OperationsFilter
        items={logTypes?.logTypes}
        selectedServiceType={selectedServiceType}
        setSelectedServiceType={setSelectedServiceType}
        selectedFilterLimit={selectedFilterLimit}
        setSelectedFilterLimit={setSelectedFilterLimit}
      />
    </ToothFairySearch>
  );
};

const styleGenerator = (currentTheme) => {
  return StyleSheet.create({
    button: {
      marginBottom: 5,
      height: Constants.appButtonHeight,
      width: 200,
      marginLeft: "auto",
      marginRight: "auto",
    },
    buttonLabel: {
      fontSize: currentTheme?.theme?.small_font_size,
    },
  });
};

export default LogsFilter;
