import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppJSONViewer from "@toothfairy/shared-ui/AppJSONViewer";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import React from "react";
import { View } from "react-native";
import Common from "../../API/Common";
import { isMobileScreen } from "../../Screens/MainViewContainer";

const ToothFairyJsonViewer = ({
  isEmpty,
  data,
  mainCaption = "EmptyPlaceholder",
  subCaption,
  ...props
}) => {
  return (
    <View style={{ maxWidth: !isEmpty && data && "80%" }}>
      {!isEmpty && data ? (
        <AppJSONViewer
          //  fontColor={currentTheme?.theme?.white}
          json={Common.objectCleaner(data)}
          {...props}
        />
      ) : (
        <AppPlaceholderView
          accessibilityLabel="json-placeholder"
          wrapperStyle={{
            width: isMobileScreen() ? 200 : 300,
            height: 200,
            marginVertical: "auto",
            marginHorizontal: "auto",
          }}
          size={60}
          icon={AppAssets.icons.orbit}
          mainCaption={mainCaption}
          subCaption={subCaption}
        />
      )}
    </View>
  );
};

export default ToothFairyJsonViewer;
