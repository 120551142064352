import React from "react";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const ToothFairyHTMLViewer = ({ content }) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <div
      style={{
        color: currentTheme?.theme?.font_color,
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default ToothFairyHTMLViewer;
