import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";
import ToothFairyButton from "./ToothFairyButton";

const ToothFairySearch = ({ children, handleSearch }) => {
  const { currentTheme } = AppTheme.useTheme();

  return (
    <View
      style={{
        backgroundColor: currentTheme?.theme?.softBackgroundColor,
        padding: 10,
      }}
    >
      <View style={{ maxWidth: 800 }}>{children}</View>

      <ToothFairyButton
        wrapperStyle={{
          marginVertical: 10,
          marginLeft: 5,
          marginRight: 10,
        }}
        style={{
          zIndex: -1,
          marginLeft: 0,
        }}
        title="Search"
        accessibilityLabel="searchButton"
        onPress={handleSearch}
      />
    </View>
  );
};

export default ToothFairySearch;
