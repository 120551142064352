import { DataStore } from "@aws-amplify/datastore";
import { DictionaryItem } from "../../models";

const createDictionaryItem = async (object) => {
  return DataStore.save(new DictionaryItem(object));
};

const updateDictionaryItem = async (id, args) => {
  const original = await DataStore.query(DictionaryItem, id);
  return DataStore.save(
    DictionaryItem.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteDictionaryItem = async (id) => {
  return DataStore.delete(DictionaryItem, (ent) => ent.id("eq", id));
};

export default {
  createDictionaryItem,
  updateDictionaryItem,
  deleteDictionaryItem,
};
