import React from "react";
import { StyleSheet, View } from "react-native";

import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { AppIcon } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const SettingsToolbar = ({
  categoriesSwitch,
  formatSwitch,
  handleModalOpen,
  setCategoriesSwitch,
  setFormatSwitch,
}) => {
  const { currentTheme } = AppTheme.useTheme();

  return (
    <View style={styles.container}>
      <AppIcon
        color={currentTheme?.theme?.font_color}
        icon={AppAssets.icons.slidersV}
        isActive={formatSwitch}
        isClickable
        label="Format"
        onPress={() => {
          setFormatSwitch(!formatSwitch);
        }}
        style={styles.icon}
      />
      <AppIcon
        color={currentTheme?.theme?.font_color}
        icon={AppAssets.icons.layerGroup}
        isActive={categoriesSwitch}
        isClickable
        label="Categories"
        onPress={() => {
          setCategoriesSwitch(!categoriesSwitch);
        }}
        style={styles.icon}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  icon: {
    borderRadius: 5,
    width: "auto",
    height: "auto",
  },
});

export default SettingsToolbar;
