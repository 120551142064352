import { DataStore } from "@aws-amplify/datastore";
import useAPI from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import envConfig from "../../../envConfig";
import Files from "../../API/Files";
import TrainingLogHelper from "../../API/TrainingLog/trainingLogHelper";
import workspacesHelper from "../../API/Workspaces/workspacesHelper";
import Constants from "../../Constants";
import usePath from "../../Hooks/usePath";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory } from "../../Router";
import { TrainingLog } from "../../models";
import TrainingScreen from "./TrainingScreen";
import trainingLogHelper from "../../API/TrainingLog/trainingLogHelper";
dayjs.extend(isBetween);

const TrainingScreenContainer = ({ navigation }) => {
  const routes = [];
  const history = useHistory();
  const {
    getActiveWorkspace,
    handleTabSelection,
    getRawTabSelection,
    isPriorityTrainingEnabled,
    updateWorkspaceInContext,
    getPlanConfig,
    isTrainingEnabled,
    getTrainingLogInfo,
  } = useWorkspaces();
  routes.push({
    key: "generativeTraining",
    title: "Generative AI",
    logicalRoute: "generator",
    routeId: "gn",
    shortTitle: "GN",
  });
  routes.push({
    key: "conversationalTraining",
    title: "Conversational AI",
    logicalRoute: "conversational",
    routeId: "cn",
    shortTitle: "CN",
  });
  routes.push({
    key: "nlpTraining",
    title: "Sentiment reviewer",
    logicalRoute: "nlp",
    routeId: "nlp",
    shortTitle: "SR",
  });

  const { t } = useTranslation();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { params } = usePath();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const { user } = AppUser.useUser();
  const AppToasty = AppToast.useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTrainingInitiationModalOpen, setIsTrainingInitiationModalOpen] =
    useState(false);
  const [isTrainingFineTuningModalOpen, setIsTrainingFineTuningModalOpen] =
    useState(false);
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [isBaseModalOpen, setIsBaseModalOpen] = useState(false);
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const [selectedTab, setSelectedTab] = useState(
    getRawTabSelection(routes, "training")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState(
    dayjs().subtract(3, "months").startOf("month").toDate()
  );
  const [end, setEnd] = useState(dayjs().endOf("month").toDate());
  const [users, setUsers] = useState(null);
  const [trainingConfig, setTrainingConfig] = useState({
    datasetOnly: !isTrainingEnabled,
    baseModel: "none",
  });
  const [statusFilter, setStatusFilter] = useState("noFilter");
  const [displayedLogs, setDisplayedLogs] = useState([]);
  const handleFilterChange = (v) => {
    setStatusFilter(v);
  };
  useEffect(() => {
    setSelectedTab(getRawTabSelection(routes, "training"));
  }, [routes?.length]);
  const logStatusColors = {
    dispatched: "darkGrey",
    swapModelRequested: "darkGrey",
    inProgress: "orangeBtnColor",
    completed: "green",
    inError: "red",
    cancellationRequested: "orangeBtnColor",
    cancelled: "red",
    swapping: "orangeBtnColor",
    swapModelFailed: "red",
  };
  const handleUpdateStartDate = (v) => {
    setStart(v);
  };
  const handleUpdateEndDate = (v) => {
    setEnd(v);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleFineTuningSetupOpening = (trainingLogId) => {
    setTrainingConfig({
      ...getActiveWorkspace()?.trainingConfig,
      // baseModel:
      //   selectedTab?.key === "conversationalTraining"
      //     ? getActiveWorkspace()?.cnBaseModel || "llama-2-70b-chat"
      //     : getActiveWorkspace()?.gnBaseModel || "llama-2-70b-chat",
    });
    setIsModalOpen(false);
    setIsTrainingFineTuningModalOpen(true);
  };
  const handleFineTuningStart = (trainingLogId, trainingConfig) => {
    if (getPlanConfig()?.subscriptionType !== "enterprise") {
      return AppToasty.show(t("TrainingCreationFailedCaptionEnterprise"), {
        placement: "top",
        type: "danger",
      });
    }
    try {
      const trainingLog = getTrainingLogInfo(trainingLogId);
      const resultTrainingLog = trainingLogHelper.updateTrainingLog(
        trainingLogId,
        [
          {
            property: "status",
            value: "fineTuningRequested",
          },
          {
            property: "trainingConfig",
            value: {
              ...trainingLog?.trainingConfig,
              ...trainingConfig,
            },
          },
        ]
      );
      // fidn index of the training log
      const objectIndex = state?.trainingLogs.findIndex(
        (log) => log.id === trainingLogId
      );
      if (objectIndex >= 0) {
        state.trainingLogs[objectIndex] = resultTrainingLog;
        dispatch("SET_TRAINING_LOGS", state.trainingLogs);
      }
      AppToasty.show(t("TrainingUpdateCaptionStart"), {
        placement: "top",
        type: "success",
      });
    } catch (error) {
      console.error("Error while creating training log", error);
      AppToasty.show(t("TrainingUpdateFailedCaption"), {
        placement: "top",
        type: "danger",
      });
    }
  };
  useEffect(() => {
    if (
      state.activeWorkspaceID &&
      !routes?.find((r) => r.key === "nlpTraining")
    )
      history.push(`/workspaces/${state.activeWorkspaceID}/training/rc`);
  }, [routes?.length, state.activeWorkspaceID]);
  const handleTrainingInitiation = async () => {
    try {
      const trainingConfigSnapshot = JSON.parse(
        JSON.stringify(getActiveWorkspace()?.trainingConfig)
      );

      console.log("trainingConfig", trainingConfig);
      // if (
      //   selectedTab?.key === "nlpTraining" &&
      //   getPlanConfig()?.isTrainingNLPEnabled === false
      // )
      //   return AppToasty.show(t("TrainingCreationFailedCaptionNLP"), {
      //     placement: "top",
      //     type: "danger",
      //   });
      // else if (
      //   selectedTab?.key === "readComprehensionTraining" &&
      //   getPlanConfig()?.isTrainingQAEnabled === false
      // )
      //   return AppToasty.show(t("TrainingCreationFailedCaptionQA"), {
      //     placement: "top",
      //     type: "danger",
      //   });
      // else if (
      //   selectedTab?.key === "generativeTraining" &&
      //   getPlanConfig()?.isTrainingGNEnabled === false
      // )
      //   return AppToasty.show(t("TrainingCreationFailedCaptionGN"), {
      //     placement: "top",
      //     type: "danger",
      //   });

      const resultTrainingLog = await DataStore.save(
        new TrainingLog({
          createdBy: user.id,
          status: "dispatched",
          type: selectedTab?.key,
          workspaceID: state.activeWorkspaceID,
          name: trainingConfig?.name,
          description: trainingConfig?.description,
          trainingConfig: {
            ...trainingConfigSnapshot,
            datasetOnly: trainingConfig?.datasetOnly,
            baseModel: trainingConfig?.baseModel,
            topics: selectedTopics?.map((u) => u.id),
            enableManagedSpotTraining: !isPriorityTrainingEnabled()
              ? true
              : getActiveWorkspace()?.trainingConfig?.enableManagedSpotTraining,
          },
        })
      );
      const updatedLogs = [...state?.trainingLogs];
      updatedLogs.unshift(resultTrainingLog);
      dispatch("SET_TRAINING_LOGS", updatedLogs);
      AppToasty.show(t("TrainingCreationCaption"), {
        placement: "top",
        type: "success",
      });
    } catch (error) {
      console.error("Error while creating training log", error);
      AppToasty.show(t("TrainingCreationFailedCaption"), {
        placement: "top",
        type: "danger",
      });
    }
  };
  const isBaseModel = () => {
    if (selectedTab?.key === "nlpTraining")
      return (
        getActiveWorkspace()?.nlpUrl == null ||
        state?.workspacePlan?.baseModelNLP === getActiveWorkspace().nlpUrl
      );
    else if (selectedTab?.key === "readComprehensionTraining")
      return (
        getActiveWorkspace()?.qaUrl == null ||
        state?.workspacePlan?.baseModelQA === getActiveWorkspace().qaUrl
      );
    else if (selectedTab?.key === "generativeTraining")
      return (
        getActiveWorkspace()?.gnUrl == null ||
        state?.workspacePlan?.baseModelGN === getActiveWorkspace().gnUrl
      );
    else if (selectedTab?.key === "conversationalTraining")
      return (
        getActiveWorkspace()?.cnUrl == null ||
        state?.workspacePlan?.baseModelCN === getActiveWorkspace().cnUrl
      );
  };
  const handleBaseModelInitiation = async () => {
    let endpointPr = "";
    let endpointValue = "";
    switch (selectedTab?.key) {
      case "nlpTraining":
        endpointPr = "nlpUrl";
        endpointValue = state?.workspacePlan?.baseModelNLP;
        break;
      case "readComprehensionTraining":
        endpointPr = "qaUrl";
        endpointValue = state?.workspacePlan?.baseModelQA;
        break;
      case "generativeTraining":
        endpointPr = "gnUrl";
        endpointValue = state?.workspacePlan?.baseModelGN;
        break;
      case "conversationalTraining":
        endpointPr = "cnUrl";
        endpointValue = state?.workspacePlan?.baseModelCN;
      default:
        break;
    }
    try {
      const result = await workspacesHelper.updateWorkspace(
        state?.activeWorkspaceID,
        [{ property: endpointPr, value: endpointValue }]
      );
      updateWorkspaceInContext(result);
      AppToasty.show(t("WorkspaceUpdateCaption"), {
        type: "success",
      });
    } catch (error) {
      AppToasty.show(t("WorkspaceUpdateFailedCaption"), {
        type: "danger",
      });
      console.error("An error occured while updating the workspace", error);
    }
  };
  const markTrainingLogAsActive = async (id) => {
    try {
      const result = await TrainingLogHelper.updateTrainingLog(id, [
        {
          property: "lastActivation",
          value: Date.now(),
        },
        {
          property: "status",
          value: "swapModelRequested",
        },
      ]);
      const objectIndex = state?.trainingLogs.findIndex((log) => log.id === id);
      if (objectIndex >= 0) {
        state.trainingLogs[objectIndex] = result;
        dispatch("SET_TRAINING_LOGS", state.trainingLogs);
      }
    } catch (error) {
      throw error;
    }
  };
  const handleFieldUpdate = async (id, property, value) => {
    try {
      const result = await TrainingLogHelper.updateTrainingLog(id, [
        {
          property,
          value,
        },
      ]);
      const objectIndex = state?.trainingLogs.findIndex((log) => log.id === id);
      if (objectIndex >= 0) {
        state.trainingLogs[objectIndex] = result;
        dispatch("SET_TRAINING_LOGS", state.trainingLogs);
      }
    } catch (error) {
      throw error;
    }
  };
  const handleTrainingCancellation = async (id) => {
    try {
      const result = await TrainingLogHelper.updateTrainingLog(id, [
        { property: "status", value: "cancellationRequested" },
      ]);
      AppToasty.show(t("TrainingCancellationRequestedCaption"), {
        placement: "top",
        type: "success",
      });
      const objectIndex = state?.trainingLogs.findIndex((log) => log.id === id);
      if (objectIndex >= 0) {
        state.trainingLogs[objectIndex] = result;
        dispatch("SET_TRAINING_LOGS", state.trainingLogs);
      }
    } catch (error) {
      AppToasty.show(t("TrainingCancellationFailedCaption"), {
        placement: "top",
        type: "danger",
      });
    }
  };
  const handleDeleteTrainingLog = async (id) => {
    try {
      await DataStore.delete(TrainingLog, (ent) => ent.id("eq", id));
      AppToasty.show(t("TrainingDeletionCaption"), {
        placement: "top",
        type: "success",
      });
      const trainingLogs = state?.trainingLogs.filter((u) => u.id !== id);
      dispatch("SET_TRAINING_LOGS", trainingLogs);
    } catch (error) {
      AppToasty.show(t("TrainingDeletionFailedCaption"), {
        placement: "top",
        type: "danger",
      });
    }
  };
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    handleTabSelection(tab, routes, "training");
  };
  useEffect(() => {
    if (params.sectionId && routes?.length > 0)
      handleTabChange(routes.find((u) => u?.routeId == params?.sectionId));
  }, [params?.sectionId, routes?.length]);
  const isTrainingLogIdActive = (id) => {
    return (
      getActiveWorkspace()?.nlpUrl?.includes(id) ||
      getActiveWorkspace()?.qaUrl?.includes(id)
    );
  };
  useEffect(() => {
    let validLogs = state?.trainingLogs?.filter(
      (u) =>
        // u.createdBy === user.id &&
        u.type === selectedTab?.key
    );
    // find index of the active training log
    const activeTrainingLogIndex = validLogs.findIndex((log) =>
      isTrainingLogIdActive(log.id)
    );
    // if active training log is found, move it to the top of the list
    if (activeTrainingLogIndex >= 0) {
      let activeTrainingLog = validLogs[activeTrainingLogIndex];
      activeTrainingLog.isActive = true;
      validLogs.splice(activeTrainingLogIndex, 1);
      validLogs.unshift(activeTrainingLog);
    }
    setDisplayedLogs(validLogs);
  }, [selectedTab?.key, JSON.stringify(state?.trainingLogs)]);
  const handleSearch = () => {
    setDisplayedLogs(
      state?.trainingLogs?.filter(
        (u) =>
          // u.createdBy === user.id &&
          u.type === selectedTab?.key &&
          (u.status === statusFilter || statusFilter === "noFilter") &&
          dayjs(new Date(u._lastChangedAt)).isBetween(start, end, null, "[]")
      )
    );
  };
  function download_files(files) {
    function download_next(i) {
      if (i >= files.length) {
        return;
      }
      var a = document.createElement("a");
      a.href = files[i].download;
      a.target = "_parent";
      // Use a.download if available, it prevents plugins from opening.
      if ("download" in a) {
        a.download = files[i].filename;
      }
      // Add a to the doc for click to work.
      (document.body || document.documentElement).appendChild(a);
      if (a.click) {
        a.click(); // The click method is supported by most browsers.
      } else {
        $(a).click(); // Backup using jquery
      }
      // Delete the temporary link.
      a.parentNode.removeChild(a);
      // Download the next file with a small timeout. The timeout is necessary
      // for IE, which will otherwise only download the first file.
      setTimeout(function () {
        download_next(i + 1);
      }, 2000);
    }
    // Initiate the first download.
    download_next(0);
  }
  const handleTrainingDataDownload = async ({
    workspaceid,
    logId,
    type,
    selection,
  }) => {
    try {
      AppToasty.show(t("TrainingDataDownloadCaption"), {
        placement: "bottom",
        type: "success",
      });
      const result = await Promise.all(
        selection?.map((u) =>
          S3downloadUrlRequest({
            workspaceid,
            logId,
            type,
            filename: Constants.DOWNLOAD_OPTIONS.find(
              (item) => item.type === type.toUpperCase() && item.id === u
            ).filename,
            context: "training",
          })
        )
      );

      download_files(
        result?.map((u, i) => {
          return {
            download: u.url,
            filename: "TR-" + logId + "-" + selection[i],
          };
        })
      );
    } catch (error) {
      console.error("Training data download error", error);
      AppToasty.show(t("TrainingDataDownloadFailedCaption"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TrainingScreen
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isTrainingInitiationModalOpen={isTrainingInitiationModalOpen}
        setIsTrainingInitiationModalOpen={setIsTrainingInitiationModalOpen}
        isTrainingFineTuningModalOpen={isTrainingFineTuningModalOpen}
        setIsTrainingFineTuningModalOpen={setIsTrainingFineTuningModalOpen}
        isModalOptionsOpen={isModalOptionsOpen}
        setIsModalOptionsOpen={setIsModalOptionsOpen}
        isBaseModalOpen={isBaseModalOpen}
        setIsBaseModalOpen={setIsBaseModalOpen}
        setSelectedTopics={setSelectedTopics}
        selectedTopics={selectedTopics}
        handleBaseModelInitiation={handleBaseModelInitiation}
        handleFieldUpdate={handleFieldUpdate}
        trainingConfig={trainingConfig}
        setTrainingConfig={setTrainingConfig}
        onUpdateStartDate={handleUpdateStartDate}
        onUpdateEndDate={handleUpdateEndDate}
        onFilterChange={handleFilterChange}
        isBaseModel={isBaseModel}
        statusFilter={statusFilter}
        startDate={start}
        endDate={end}
        handleSearch={handleSearch}
        handleTabChange={handleTabChange}
        selectedTab={selectedTab}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleTrainingInitiation={handleTrainingInitiation}
        handleFineTuningStart={handleFineTuningStart}
        handleFineTuningSetupOpening={handleFineTuningSetupOpening}
        handleTrainingCancellation={handleTrainingCancellation}
        handleDeleteTrainingLog={handleDeleteTrainingLog}
        markTrainingLogAsActive={markTrainingLogAsActive}
        trainingLogs={displayedLogs}
        logStatusColors={logStatusColors}
        trainingInProgress={
          state?.trainingLogs?.filter(
            (u) =>
              u.status === "inProgress" ||
              u.status === "dispatched" ||
              u.status === "cancellationRequested"
          )?.length > 0
        }
        isSwapRequested={
          state?.trainingLogs?.filter((u) => u.status === "swapModelRequested")
            ?.length > 0
        }
        isSwapingInProgress={
          getActiveWorkspace()?.nlpUrlStatus === "swapping" ||
          getActiveWorkspace()?.qaUrlStatus === "swapping"
        }
        users={users}
        routes={routes}
        initialTabFromRouting={params.sectionId}
        handleDataDownload={handleTrainingDataDownload}
      />
    </Suspense>
  );
};

export default TrainingScreenContainer;
