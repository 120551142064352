import { DataStore } from "@aws-amplify/datastore";
import { User, Workspace } from "../../models";

const updateUser = async (id, args) => {
  const original = await DataStore.query(User, id);
  return DataStore.save(
    User.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const updateWorkspace = async (id, args) => {
  const original = await DataStore.query(Workspace, id);
  return DataStore.save(
    Workspace.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteUser = async (id) => {
  return DataStore.delete(User, (ent) => ent.id("eq", id));
};

export default {
  updateUser,
  updateWorkspace,
  deleteUser,
};
