import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import AdminInfoLeaf from "../../../Admin/Components/SubComponents/AdminInfoLeaf";

const AuthorisationSettingsAIView = ({
  isEdit,
  settingType,
  handleDeletion,
  isSaveInProgress,
}) => {
  const { currentTheme, mode } = AppTheme.useTheme();
  const entityName = "Authorisation mapping";
  const { setFieldValue, values, validationSchema } =
    AppForms.useFormikContext();

  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  // const { setFieldValue, values } = AppForms.useFormikContext();
  // useEffect(() => {
  //   if (values?.sourceLanguage !== "en") setFieldValue("targetLanguage", "en");
  //   if (values?.targetLanguage !== "en") setFieldValue("sourceLanguage", "en");
  // }, [values]);
  return (
    <>
      <>
        <View>
          {isEdit && (
            <AdminInfoLeaf
              wrapperStyle={{
                alignSelf: "flex-start",
                marginBottom: 10,
              }}
              textContainer={{
                marginLeft: 5,
                marginTop: 10,
                marginBottom: 0,
              }}
              titleStyle={{
                marginTop: 0,
                marginLeft: 20,
              }}
              textStyle={{
                marginLeft: 0,
              }}
              title={"ID"}
              value={values?.id}
              width={420}
            />
          )}
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Authorisation name`}
            placeholder={`Authorisation name`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="name"
          />
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            label={`Description`}
            placeholder={`Description of what the connection is used for`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="description"
          />
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Type"
            isMandatory
            name="type"
            items={[
              {
                id: "apikey",
                name: "API Key",
              },
              {
                id: "bearer",
                name: "Bearer",
              },
              {
                id: "oauth",
                name: "OAuth2",
              },
            ]}
            propertyLabel="name"
            propertyValue="id"
          />
          {values?.type !== "none" && values?.type != "oauth" && (
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              label={`Token`}
              placeholder={
                isEdit
                  ? "API keys cannot be changed after creation"
                  : `The API key to use to authorise the request`
              }
              disabled={isEdit}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name={isEdit ? "$xc" : "tokenSecret"}
            />
          )}

          {values?.type == "oauth" && (
            <View
              style={{
                zIndex: -1,
              }}
            >
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                isMandatory
                label={`Client ID`}
                placeholder={`Client ID`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="clientId"
              />

              <AppForms.AppFormField
                showTextInputLabel
                isMandatory
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                label={`Client secret`}
                placeholder={
                  isEdit
                    ? "Secrets cannot be changed after creation"
                    : `The secret to use to retrieve the bearer token`
                }
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name={isEdit ? "$xc" : "clientSecret"}
                disabled={isEdit}
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                isMandatory
                label={`Authorisation URL`}
                placeholder={`Authorisation URL`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="authorizationBaseUrl"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                isMandatory
                label={`Grant type`}
                placeholder={`The grant to retrieve`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="grantType"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                label={`Scope`}
                placeholder={`Scope`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="scope"
              />
              <AppForms.AppFormFieldJSONV2
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                mode="code"
                formFieldContainerStyle={{
                  zIndex: -1,
                }}
                darkMode={mode === "dark"}
                label={`Static arguments`}
                placeholder={`{"param1": "myStaticParam1", "param2": "myStaticParam2"}`}
                name="staticArgs"
              />
            </View>
          )}
        </View>
      </>
      <View style={{ alignItems: "center" }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          isDisabled={isSaveInProgress}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default AuthorisationSettingsAIView;
