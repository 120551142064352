import { AppText } from "@toothfairy/shared-ui";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useContext, useEffect, useState } from "react";
import { Dimensions, useWindowDimensions, View } from "react-native";
import ToothFairySupportButton from "../Components/ToothFairyComponents/ToothFairySupportButton";
import Constants from "../Constants";
import useWorkspaces from "./useWorkspaces";

const initialValues = {
  displayedView: "MAIN",
};

export const SectionsContext = React.createContext(initialValues);

export const SectionsProvider = ({
  children,
  optionsDataGenerator,
  onOptionPressed,
  defaultViewUID,
  defaultOptionUID,
  sectionsMainTitle,
  sectionsWidth,
  onDisplayedViewChanged = (v) => {},
}) => {
  const { getWorkspaceEntities } = useWorkspaces();
  const dimensions = useWindowDimensions();
  const [isLargeScreen, setLargeScreen] = useState(
    dimensions?.width >= Constants.screenResponsiveWidth
  );
  const [marginLeftUnderAppExpander, setMarginLeftUnderAppExpander] = useState(
    dimensions?.width >= Constants.screenResponsiveWidth ? 42 : 0
  );
  const [displayedView, setDisplayedView] = useState("MAIN");

  const [displayedOpt, setDisplayedOption] = useState(
    typeof defaultOptionUID === "string"
      ? getWorkspaceEntities().find((e) => e.id === defaultOptionUID) ||
          defaultOptionUID
      : defaultOptionUID
  );
  useEffect(() => {
    setDisplayedOption(
      typeof defaultOptionUID === "string"
        ? getWorkspaceEntities().find((e) => e.id === defaultOptionUID) ||
            defaultOptionUID
        : defaultOptionUID
    );
  }, [defaultOptionUID, getWorkspaceEntities()?.length]);

  const [isMainMenuBackVisible, setIsMainMenuBackVisible] = useState(true);
  const { currentTheme, mode } = AppTheme.useTheme();
  const [isLoaded, setIsLoaded] = useState(false);
  const borderStyle = {
    borderRightWidth: 1,
    borderRightColor: currentTheme?.theme?.app_outline_color,
    height: "100%",
    zIndex: 1,
  };
  useEffect(() => {
    if (isLoaded) onDisplayedViewChanged(displayedView, displayedOpt);
    else setIsLoaded(true);
  }, [displayedView]);

  const initialSelectionRenderer = (isSecondary) =>
    ((isSecondary && isLargeScreen) || !isSecondary) && (
      <View
        style={[
          {
            maxWidth: isLargeScreen && 250,
            minWidth: 125,
            backgroundColor: currentTheme?.theme?.backgroundColor,
            maxHeight: dimensions?.height - 58,
            overflow: "auto",
            marginLeft: 2,
          },
          isLargeScreen && { ...borderStyle },
        ]}
      >
        {optionsDataGenerator()?.map(
          (opt) =>
            opt.isHidden !== true && (
              <AppMenu.AppDefaultOption
                item={opt}
                wrapperStyle={{
                  width: "auto",
                  maxWidth: 250,
                  paddingVertical: 10,
                  paddingHorizontal: 5,
                  backgroundColor:
                    (opt.uid === displayedView ||
                      displayedView?.startsWith(opt.uid)) &&
                    currentTheme?.theme?.primary,
                }}
                isHoverable={opt.uid !== displayedView}
                color={
                  (opt.uid === displayedView ||
                    displayedView?.startsWith(opt.uid)) &&
                  currentTheme?.theme?.black
                }
                accessibilityLabel={`${opt.uid}-section`}
                isNavigation={!isLargeScreen && opt?.isNavigation}
                navigationIcon={opt?.navigationIcon}
                onPress={() => {
                  if (opt.subOptions) {
                    setDisplayedView(opt.uid);
                  }
                  opt?.onPress && opt?.onPress(opt);
                  onOptionPressed && onOptionPressed(opt.uid, opt);
                }}
                key={opt.uid}
              >
                {opt.label}
              </AppMenu.AppDefaultOption>
            )
        )}
      </View>
    );

  useEffect(() => {
    const _largeScreen = dimensions?.width >= Constants.screenResponsiveWidth;
    setLargeScreen(_largeScreen);
    setMarginLeftUnderAppExpander(_largeScreen ? 42 : 5);
    if (_largeScreen && displayedView === "MAIN")
      setDisplayedView(defaultViewUID);
  }, [dimensions?.width]);

  const viewRenderer = (mapDisplayedViewWithComponent, styles) => {
    return (
      <View style={{ paddingRight: 20, paddingTop: 10, flex: 1 }}>
        {/* <AppText
          accessibilityLabel="SectionsMainTitle"
          wrapperStyle={{ marginBottom: 20, marginTop: 0 }}
          style={{
            fontSize: currentTheme?.theme?.largest_font_size,
            fontWeight: "bold",
          }}
          // size={currentTheme?.theme?.medium_font_size}
        >
          {sectionsMainTitle}
        </AppText> */}
        <AppMenu.AppMenuOptions
          isMainMenuBackVisible={isMainMenuBackVisible}
          setIsMainMenuBackVisible={setIsMainMenuBackVisible}
          onOptionPressed={(id, opt) => {}}
          onMainOpened={() => {}}
          onChange={(value) => {}}
          modalBackdrop={true}
          modalBarTitleStyle={{ paddingLeft: 10 }}
          modalInnerContentStyle={styles.modalInnerContent}
          modalRightIconSize={24}
          modalStyle={styles.modal}
          modalWrapperStyle={styles.modalWrapper}
          rightIconModalStyle={styles.modalRightIcon}
        >
          {displayedView === "MAIN" ? (
            initialSelectionRenderer()
          ) : (
            <View
              style={{
                flexDirection: "row",
                width: !isLargeScreen && "100%",
                height: "100%",
                padding: 0,
                // backgroundColor: currentTheme?.theme?.softBackgroundColor,
              }}
            >
              {initialSelectionRenderer(true)}
              <View
                style={{
                  // backgroundColor:
                  //   isLargeScreen && currentTheme?.theme?.softBackgroundColor,
                  height: Dimensions.get("window").height - 70,
                  flex: 1,
                  overflowX: "auto",
                }}
              >
                <View
                  style={{
                    marginHorizontal: isLargeScreen && "auto",
                    minWidth:
                      isLargeScreen &&
                      (optionsDataGenerator().find(
                        (u) => u?.uid === defaultViewUID
                      )?.fullWidth
                        ? "100%"
                        : sectionsWidth || 450),
                    width: !isLargeScreen && "100%",
                    paddingBottom: 10,
                    overflow: "auto",
                    paddingHorizontal: !sectionsWidth && 0,
                    margin: "0, auto",
                    flex: 1,
                  }}
                >
                  {optionsDataGenerator()
                    .map((u) => u.uid)
                    .indexOf(displayedView) > -1 && (
                    <View
                      style={{
                        flexDirection: "row",
                        margin: "0 auto",
                        justifyContent: isLargeScreen ? "center" : "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <View>
                        <AppMenu.DefaultBackOptionComponent
                          isBackIconDisplayed={!isLargeScreen}
                          navigateBack={() => {
                            setDisplayedView("MAIN");
                          }}
                          isClickable={!isLargeScreen}
                          sectionTitle={
                            isLargeScreen
                              ? optionsDataGenerator().filter(
                                  (u) => u.uid === displayedView
                                )[0]?.label
                              : "Back"
                          }
                          wrapperStyle={{
                            flexDirection: isLargeScreen ? "center" : "row",

                            paddingHorizontal: 0,
                          }}
                        />
                      </View>
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "flex-start",
                          alignSelf: "center",
                        }}
                      >
                        {optionsDataGenerator()?.find(
                          (u) => u.uid === displayedView
                        )?.supportVisible && <ToothFairySupportButton />}
                      </View>
                    </View>
                  )}
                  {mapDisplayedViewWithComponent(displayedView)}
                </View>
              </View>
            </View>
          )}
        </AppMenu.AppMenuOptions>
      </View>
    );
  };

  return (
    <SectionsContext.Provider
      value={{
        displayedView,
        setDisplayedView,
        isLargeScreen,
        displayedOpt,
        setDisplayedOption,
        isMainMenuBackVisible,
        setIsMainMenuBackVisible,
        initialSelectionRenderer,
        viewRenderer,
        marginLeftUnderAppExpander,
      }}
    >
      {children}
    </SectionsContext.Provider>
  );
};

export const useSections = () => {
  const {
    displayedView,
    displayedOpt,
    setDisplayedView,
    setDisplayedOption,
    isLargeScreen,
    isMainMenuBackVisible,
    setIsMainMenuBackVisible,
    initialSelectionRenderer,
    viewRenderer,
    marginLeftUnderAppExpander,
  } = useContext(SectionsContext);
  return {
    displayedOpt,
    displayedView,
    setDisplayedView,
    setDisplayedOption,
    isLargeScreen,
    isMainMenuBackVisible,
    setIsMainMenuBackVisible,
    initialSelectionRenderer,
    viewRenderer,
    marginLeftUnderAppExpander,
  };
};

export default useSections;
