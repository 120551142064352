import React from "react";
import DataLeaf from "./DataLeaf";
import { View } from "react-native";
import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const BattlesOverview = ({ dashboardData, ...props }) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View {...props}>
      <AppText
        color={currentTheme?.theme?.primary}
        leftIcon={AppAssets?.icons?.dashboard}
        style={{
          paddingLeft: 20,
          fontWeight: "bold",
        }}
        wrapperStyle={{
          paddingBottom: 20,
        }}
      >
        Battles Overview
      </AppText>
      <DataLeaf
        label="Number of battles:"
        data={dashboardData?.total_battles}
      />
      <DataLeaf label="#Wins:" data={dashboardData?.tf_wins} />

      <DataLeaf label="#Draws:" data={dashboardData?.tf_draws} />
    </View>
  );
};

export default BattlesOverview;
