import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppButtonMenu from "@toothfairy/shared-ui/AppButtonMenu";
import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMultipleSelectInput from "@toothfairy/shared-ui/AppMultipleSelectInput";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Platform, View } from "react-native";
import DelayedRender from "../../Components/DelayRenderer";
import EditorWrapper from "../../Components/EditorWrapper";
import GenericViewer from "../../Components/GenericViewer";
import AppJoyRide from "../../Components/Joyride";
import LoadingPlaceholder from "../../Components/LoadingPlaceholder";
import PDFViewer from "../../Components/PDFViewer";
import PageTitle from "../../Components/PageTitle";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairyClickableOption from "../../Components/ToothFairyComponents/ToothFairyClickableOption";
import ToothFairyLoadingPlaceholder from "../../Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";
import ToothFairyModal from "../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairyResponsiveOptionsModal from "../../Components/ToothFairyComponents/ToothFairyResponsiveOptionsModal";
import ToothFairySupportButton from "../../Components/ToothFairyComponents/ToothFairySupportButton";
import ToothFairySwitch from "../../Components/ToothFairyComponents/ToothFairySwitch";
import SavingNotification from "../../Components/TrainSwitcher/SavingNotification";
import AppTreeView from "../../Components/TreeView";
import Constants from "../../Constants";
import useUserHelperPreferences from "../../Hooks/useUserHelperPreferences";
import useWorkspaces from "../../Hooks/useWorkspaces";
import {
  isLargeScreen,
  isMobileScreen,
  minAppWidth,
} from "../MainViewContainer";
import TopicsSelectionComponents from "../MrcDocuments/Components/TopicsSelectionComponent";
import SettingsAI from "../Settings/Components/SettingsAI";
import NerViewComponent from "./Components/NerViewComponent";
import TopicsSelectionComponent from "./Components/TopicsSelectionComponent";
import ImagesExtraction from "./Components/ImagesExtraction";

const steps_first_interaction = [
  {
    target: ".upload_pdf",
    content:
      "You can click on the upload menu button to upload documents, collaborate with live docs and create folders",
    placement: "left",
    disableBeacon: true,
  },
  {
    target: ".document_section",
    content:
      "On the right side of this module, you can see the document you have uploaded making changes to the document in real-time",
    placement: "right",
    disableBeacon: true,
  },
  {
    target: ".documents_tree",
    content:
      "Finally on the left side, you can see the tree view of the documents and folders you have uploaded",
    placement: "right",
    disableBeacon: true,
  },
];

const steps_second_interaction = [
  {
    disableBeacon: true,
    target: ".learning_notification",
    content:
      "Here you can see the learning status, once the learning is completed, you can start using the document with any agent",
    placement: "right",
  },
];

const MrcDocumentsView = ({
  isInactive,
  isReadyForRTC,
  isDocumentValid,
  onSocketConnectionChange,
  handleEditorError,
  handleClickOnNode,
  setSelectedNodeForTreeView,
  value,
  setValue,
  documentId,
  createDocument,
  SaveFolder,
  selectedNode,
  deleteNode,
  showModal,
  setShowModal,
  setDialogActionOnTree,
  treeViewActionContext,
  isTreeDataLoading,
  isLoading,
  storeInError,
  storeInProgress,
  isSaved,
  selectedTopics,
  onSelectedTopicsChange,
  topics,
  handleEntityCreation,
  onDrop,
  initialOpen,
  _coreTreeViewData,
  selectedNodeToDelete,
  editDocumentTitle,
  creationofNewDocumentInProgress,
  wordsCount,
  charactersCount,
  agcEnabled,
  fakeCheckInProgress,
  fakeCheckData,
  isCollapsed,
  setIsCollapsed,
  onMove,
  handleOnRecorderClick,
  setEditor,
  documentStatus,
  onDocumentStatusChange = () => {},
  handleFileSelectPdf = () => {},
  handleFileSelectGeneric = () => {},
  handleDocumentSelection = () => {},
  onTopicCreated = () => {},
  isTopicUpdateModeEnabled,
  setIsTopicUpdateModeEnabled,
  filePath,
  filePathGeneric,
  importFileFromHook = () => {},
  onPDFDocumentLoad = () => {},
  isPDF,
  isPDFLoaded,
  isPDFUploadInProgress,
  folders,
  embeddingStatus,
  embeddingProgress,
  fileUploadProgress,
  setTreeViewActionContext,
  selectedNodeForTreeview,
  handleImports = () => {},
  handleImportsGeneric = () => {},
  batchUploadStatus,
  setPublishOnUpload,
  publishOnUpload,
  setSelectedTopicsForPdfUpload,
  selectedTopicsForPdfUpload,
  onSelectedTopicsChangeForPdfUpload = () => {},
  counter,
  extractImages,
  setExtractImages,
  isExtractImagesAllowed,
  searchText,
  setSearchText,
  promiseOptions,
  setLastCancellation,
  embeddingsByDocumentResult,
  embeddingsByDocumentInProgress,
  fetchEmbeddingsByDocument = () => {},
  setIsSaveRequested,
  savingInProgress,
  documentImgRetrievalInstructions,
  handleImagesExtraction = () => {},
}) => {
  const maxNerByChunk = 10;
  const AppToasty = AppToast.useToast();
  const formValidationSchema = AppForms.yup.object().shape({
    name:
      treeViewActionContext === "UPLOAD_PDF"
        ? AppForms.yup.string().nullable().label("Name")
        : AppForms.yup.string().required().nullable().label("Name"),
    path: AppForms.yup.string().nullable().label("Path"),
  });
  let filesArray = [];
  if (filePath && treeViewActionContext === "UPLOAD_PDF")
    filesArray = Array.from(filePath);
  else if (filePathGeneric && treeViewActionContext === "IMPORT_FILE")
    filesArray = Array.from(filePathGeneric);
  const { currentTheme } = AppTheme.useTheme();
  const formRef = useRef();
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const [isSearchInputOpen, setShowSearchInput] = useState(false);
  const [showSourcesModal, setShowSourcesModal] = useState(false);
  const { user } = AppUser.useUser();
  const { updateUserHelperPreferences } = useUserHelperPreferences();
  const { getActiveWorkspace, getWorkspaceEntities } = useWorkspaces();
  const [isInstructionsWindowOpened, setIsIstructionsWindowOpened] =
    useState(false);
  function formatPercentage(num, decimalPlaces = 2) {
    return num.toFixed(decimalPlaces) + "%";
  }
  useEffect(() => {
    if (!showModal && formRef?.current) formRef?.current?.resetForm();
  }, [showModal, formRef?.current]);
  const _editorStyles = useMemo(() => {
    return {
      wrapperStyle: {
        // backgroundColor: currentTheme?.theme?.softBackgroundColor,
        marginBottom: 10,
        flex: 1,
        padding: 10,
        paddingTop: 15,
        marginLeft: 20,
        borderWidth: 1,

        borderRadius: 10,
        borderColor: currentTheme?.theme?.border_color,
      },
      style: {
        overflowY: Platform.OS === "web" ? "auto" : "scroll",
        flex: 1,
        zIndex: -1,
        fontFamily: "normalFont",
      },
      titleStyle: {
        fontSize: currentTheme?.theme?.base_font_size,
      },
    };
  }, [currentTheme]);
  const _selectedNodeType = selectedNode?.data?.droppable ? "node" : "document";
  const [lastModalOpen, setLastModalOpen] = useState("");
  const mapEmbeddingStatusToText = (embeddingStatus) => {
    switch (embeddingStatus) {
      case "draft":
        return "Learning not started";
      case "pending":
        return "Learning pending";
      case "failed":
        return "Learning failed";
      case "ready":
        return "Learning completed";
      case "not_ready":
        return "Learning in progress";
      default:
        return "Learning not started";
    }
  };
  const isHTML =
    selectedNode?.data?.external_path?.split(".")?.pop() === "html";
  const isRawText =
    selectedNode?.data?.external_path?.split(".")?.pop() === "txt" ||
    selectedNode?.data?.external_path?.split(".")?.pop() === "md";
  const _coreModalView = useMemo(() => {
    const handleFormSubmit = (values) => {
      if (treeViewActionContext === "NEW_FOLDER")
        SaveFolder({}, values.name, false);
      else if (treeViewActionContext === "EDIT_FOLDER")
        SaveFolder(selectedNode, values.name, true);
      else if (treeViewActionContext === "NEW_DOCUMENT")
        createDocument(values.name, values?.topics, values?.status);
      else if (
        treeViewActionContext === "EDIT_DOCUMENT" ||
        treeViewActionContext === "EDIT_PDF" ||
        treeViewActionContext === "EDIT_GENERIC"
      )
        editDocumentTitle(values.name);
    };

    const generateModalTitle = () => {
      if (treeViewActionContext === "NEW_FOLDER") return "New folder";
      else if (treeViewActionContext === "EDIT_FOLDER") return "Edit folder";
      else if (treeViewActionContext === "NEW_DOCUMENT")
        return "New live document";
      else if (treeViewActionContext === "UPLOAD_PDF") return "Upload PDFs";
      else if (treeViewActionContext === "IMPORT_FILE") return "Import file";
      else if (treeViewActionContext === "EDIT_DOCUMENT")
        return "Edit document title";
      else if (treeViewActionContext === "EDIT_PDF")
        return "Edit PDF document title";
    };
    const entityName = () => {
      if (
        treeViewActionContext === "NEW_FOLDER" ||
        treeViewActionContext === "EDIT_FOLDER"
      )
        return "Folder";
      else if (
        treeViewActionContext === "NEW_DOCUMENT" ||
        treeViewActionContext === "EDIT_DOCUMENT"
      )
        return "Document";
      else if (treeViewActionContext === "UPLOAD_PDF") return "PDF Document";
      else if (treeViewActionContext === "IMPORT_FILE")
        return "Generic Document";
    };

    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                maxWidth: 600,
                height: "auto",
                marginHorizontal: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={showModal}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible={!isPDFUploadInProgress}
        modalTitle={generateModalTitle()}
        modalInnerContentStyle={{
          paddingHorizontal: 50,
          paddingVertical: 20,
        }}
        closeModal={() => {
          setLastModalOpen(Date.now());
          setShowModal(false);
          setTreeViewActionContext("");
          setSelectedTopicsForPdfUpload(null);
          setPublishOnUpload(true);
          formRef?.current?.resetForm();
        }}
      >
        {treeViewActionContext === "DELETE_NODE" ? (
          <AppChoice
            wrapperStyle={{
              alignSelf: "center",
              maxWidth: 600,
              marginTop: 50,
            }}
            cancelCaption={`${_selectedNodeType}DeleteCancelCaption`}
            confirmCaption={`${_selectedNodeType}DeleteConfirmCaption`}
            onConfirmButtonPress={() => {
              deleteNode(selectedNodeToDelete);
            }}
            onCancelButtonPress={() => {
              setShowModal(false);
            }}
            AppChoiceButton={ToothFairyButton}
            caption={`${_selectedNodeType}DeleteCaption`}
            subCaption={`${_selectedNodeType}DeleteSubCaption`}
          />
        ) : treeViewActionContext !== "UPLOAD_PDF" &&
          treeViewActionContext !== "IMPORT_FILE" ? (
          <AppForms.AppForm
            ref={formRef}
            enableReinitialize
            key={lastModalOpen}
            style={{
              minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 300,
            }}
            initialValues={{
              name: treeViewActionContext?.includes("EDIT")
                ? selectedNodeForTreeview?.data?.name
                : null,
              path: null,
            }}
            onSubmit={(e) => {
              if (
                treeViewActionContext !== "UPLOAD_PDF" &&
                !treeViewActionContext?.includes("EDIT") &&
                treeViewActionContext !== "NEW_FOLDER" &&
                treeViewActionContext !== "EDIT_FOLDER" &&
                treeViewActionContext !== "EDIT_DOCUMENT" &&
                treeViewActionContext !== "EDIT_GENERIC"
              ) {
                if (
                  !selectedTopicsForPdfUpload ||
                  selectedTopicsForPdfUpload?.length === 0
                )
                  return AppToasty.show("Please select at least one topic", {
                    type: "danger",
                  });
                handleFormSubmit({
                  ...e,
                  topics: selectedTopicsForPdfUpload,
                  status: publishOnUpload ? "published" : "draft",
                });
              } else if (
                treeViewActionContext === "EDIT_FOLDER" ||
                treeViewActionContext === "NEW_FOLDER"
              ) {
                SaveFolder(
                  selectedNodeForTreeview,
                  e.name,
                  treeViewActionContext === "EDIT_FOLDER"
                );
              } else if (treeViewActionContext?.includes("EDIT")) {
                editDocumentTitle(e?.name);
              } else {
                importFileFromHook(
                  filePath,
                  e.name,
                  selectedNodeForTreeview?.data?.droppable
                    ? selectedNodeForTreeview?.data?.id
                    : selectedNodeForTreeview?.data?.parent
                );
              }
              setLastModalOpen(Date.now());
              setShowModal(false);
              setTreeViewActionContext("");
              setSelectedTopicsForPdfUpload(null);
              setPublishOnUpload(true);
              formRef?.current?.resetForm();
            }}
            validationSchema={formValidationSchema}
          >
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              name="name"
              label={`${entityName()} name`}
              placeholder={`${entityName()} name`}
            />
            {treeViewActionContext === "NEW_DOCUMENT" && (
              <>
                <TopicsSelectionComponents
                  showAddNew
                  onTopicCreated={onTopicCreated}
                  showEditIcon={false}
                  isTopicUpdateModeEnabled={true}
                  selectedTopics={selectedTopicsForPdfUpload}
                  onSelectedTopicsChange={onSelectedTopicsChangeForPdfUpload}
                  topics={topics}
                  wrapperContainerStyle={{
                    flex: 1,
                    marginHorizontal: 5,
                  }}
                />
                <ToothFairySwitch
                  wrapperStyle={{
                    flex: 1,
                    backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                    marginTop: 20,
                    zIndex: -1,
                    marginVertical: 30,
                    marginHorizontal: 5,
                  }}
                  label={"Publish on creation"}
                  onChange={(v) => {
                    setPublishOnUpload(v);
                  }}
                  isTicked={publishOnUpload}
                />
              </>
            )}

            <AppForms.AppSubmitButton
              title={"Save"}
              // change here
              isDisabled={
                creationofNewDocumentInProgress ||
                isPDFUploadInProgress ||
                (treeViewActionContext === "UPLOAD_PDF" &&
                  (filePath?.name == null || filePath?.name == ""))
              }
              awesomeIconColor={currentTheme?.theme?.white}
              backgroundColor={currentTheme?.theme?.primary}
              btnContentStyle={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
              style={[
                {
                  height: Constants.appButtonHeight,
                  width: "auto",
                  shadowColor: currentTheme?.theme?.transparent,
                  marginHorizontal: 10,
                },
              ]}
              btnLabelStyle={{ fontSize: 16 }}
              wrapperStyle={[
                {
                  width: "fit-content",
                  minWidth: 150,
                  marginTop: 20,
                  alignSelf: "center",
                  height: Constants.appButtonHeight,
                },
              ]}
            />
          </AppForms.AppForm>
        ) : (
          <>
            <View
              style={{
                padding: 20,
                overflowY: "auto",
              }}
            >
              <AppText
                wrapperStyle={{
                  paddingBottom: 10,
                }}
                color={currentTheme?.theme?.primary}
              >
                Selected files:
              </AppText>
              {filesArray?.map(
                (file) =>
                  file?.name && (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 10,
                        maxWidth: 450,
                      }}
                    >
                      <AppText
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: 400,
                        }}
                        fontWeight="bold"
                      >
                        {file?.name || "No file selected"}
                      </AppText>
                      {batchUploadStatus[file?.name] && (
                        <AppText
                          style={{
                            marginLeft: 10,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                          fontWeight="bold"
                          color={
                            batchUploadStatus[file?.name] == 100
                              ? currentTheme?.theme?.green
                              : currentTheme?.theme?.orange
                          }
                        >
                          {` ${Math.floor(batchUploadStatus[file?.name])}%`}
                        </AppText>
                      )}
                    </View>
                  )
              )}
              <TopicsSelectionComponents
                showAddNew
                onTopicCreated={onTopicCreated}
                showEditIcon={false}
                isTopicUpdateModeEnabled={true}
                selectedTopics={selectedTopicsForPdfUpload}
                onSelectedTopicsChange={onSelectedTopicsChangeForPdfUpload}
                topics={topics}
                wrapperContainerStyle={{
                  flex: 1,
                }}
              />
              <ToothFairySwitch
                wrapperStyle={{
                  flex: 1,
                  backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                  marginTop: 20,
                  zIndex: -1,
                  marginVertical: 30,
                }}
                label={"Publish on upload"}
                onChange={(v) => {
                  setPublishOnUpload(v);
                }}
                isTicked={publishOnUpload}
              />
              {/* {isPDFUploadInProgress && (
                <AppText
                  wrapperStyle={{
                    alignSelf: "flex-start",
                    paddingBottom: 20,
                    maxWidth: 400,
                  }}
                  color={currentTheme?.theme?.orange}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  fontWeight="bold"
                >
                  {`Upload in progress`}
                </AppText>
              )} */}
              <ToothFairyButton
                isDisabled={isPDFUploadInProgress}
                title={isPDFUploadInProgress ? "In progress" : "Upload"}
                onPress={() => {
                  if (
                    (!selectedTopicsForPdfUpload ||
                      selectedTopicsForPdfUpload?.length === 0) &&
                    treeViewActionContext !== "NEW_FOLDER" &&
                    treeViewActionContext !== "EDIT_FOLDER" &&
                    treeViewActionContext !== "EDIT_DOCUMENT" &&
                    treeViewActionContext !== "EDIT_GENERIC"
                  )
                    return AppToasty.show("Please select at least one topic", {
                      type: "danger",
                    });
                  treeViewActionContext === "IMPORT_FILE"
                    ? handleImportsGeneric()
                    : handleImports();
                }}
                wrapperStyle={{
                  alignSelf: "center",
                }}
              />
            </View>
          </>
        )}
      </ToothFairyModal>
    );
  }, [
    setShowModal,
    SaveFolder,
    lastModalOpen,
    currentTheme,
    showModal,
    isLargeScreen(),
    selectedNode?.id,
    treeViewActionContext,
    creationofNewDocumentInProgress,
    filePath,
    isPDFUploadInProgress,
    fileUploadProgress,
    publishOnUpload,
    JSON.stringify(selectedTopicsForPdfUpload),
    publishOnUpload,
    JSON.stringify(batchUploadStatus),
    topics?.length,
  ]);
  const _saveStatus = useMemo(() => {
    if (!documentId)
      return {
        label: "No document selected",
        color: "primary",
        dotValue: 0,
        isProgress: false,
        icon: AppAssets.icons.save,
      };
    else if (!documentId)
      return {
        label: "Parse mode",
        color: "mediumGrey",
        dotValue: 0,
        isProgress: true,
      };
    else {
      if (!storeInError && !storeInProgress && isSaved)
        return {
          label: "Saved",
          color: "green",
          dotValue: 1,
          isProgress: false,
          icon: AppAssets.icons.save,
        };
      else if (storeInProgress)
        return {
          label: "Saving...",
          color: "orange",
          dotValue: 1,
          isProgress: true,
          icon: AppAssets.icons.clock,
        };
      else if (!isSaved)
        return {
          label: "Not saved",
          color: "red",
          dotValue: 1,
          isProgress: true,
          icon: AppAssets.icons.risk,
        };
      else if (storeInError)
        return {
          label: "Error while saving",
          color: "red",
          dotValue: 1,
          isProgress: true,
          icon: AppAssets.icons.risk,
        };
      else
        return {
          label: "",
          dotValue: 0,
        };
    }
  }, [documentId, isSaved, storeInError, storeInProgress]);
  const _coreSourcesModelView = useMemo(() => {
    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                width: "95%",
                maxWidth: 600,
                maxHeight: "99%",
                minHeight: 300,
                height: "auto",
                marginHorizontal: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={showSourcesModal}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle={"Document chunks"}
        modalInnerContentStyle={{
          paddingHorizontal: 20,
          paddingVertical: 20,
        }}
        closeModal={() => {
          setShowSourcesModal(false);
        }}
      >
        {embeddingsByDocumentInProgress ? (
          <ToothFairyLoadingPlaceholder
            style={{
              maxHeight: 200,
            }}
            caption="Loading..."
          />
        ) : (
          <View>
            {embeddingsByDocumentResult?.data?.length > 0 ? (
              embeddingsByDocumentResult?.data?.map((e) => {
                return (
                  <View
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: currentTheme?.theme?.border_color,
                      paddingBottom: 5,
                    }}
                  >
                    <AppText
                      style={{
                        fontWeight: "bold",
                        paddingVertical: 5,
                      }}
                      color={currentTheme?.theme?.primary}
                      preventTranslation
                    >
                      Chunk id: <strong>{e?.chunk_id}</strong>
                    </AppText>
                    <AppText
                      style={{
                        fontSize: currentTheme?.theme?.extra_small_font_size,
                        paddingVertical: 10,
                        width: "-webkit-fill-available",
                      }}
                      wrapperStyle={{
                        marginTop: 10,
                      }}
                    >
                      {e?.raw_text}
                    </AppText>
                    <NerViewComponent
                      ners={e?.ners}
                      chunk_id={e?.chunk_id}
                      showHover={false}
                    />
                  </View>
                );
              })
            ) : (
              <AppText
                style={{
                  fontSize: currentTheme?.theme?.extra_small_font_size,
                }}
              >
                No data sources available
              </AppText>
            )}
          </View>
        )}
      </ToothFairyModal>
    );
  }, [
    JSON.stringify(embeddingsByDocumentResult),
    showSourcesModal,
    embeddingsByDocumentInProgress,
  ]);
  const _savingNotification = useMemo(() => {
    return (
      <SavingNotification
        showEditBtn={documentId}
        color={_saveStatus?.color}
        dotValue={_saveStatus?.dotValue}
        icon={_saveStatus?.icon}
        isProgress={_saveStatus?.isProgress}
        label={_saveStatus?.label}
        isEditEnabled={isTopicUpdateModeEnabled}
        setIsEditEnabled={setIsTopicUpdateModeEnabled}
        setLastCancellation={setLastCancellation}
        setIsSaveRequested={setIsSaveRequested}
      />
    );
  }, [
    _saveStatus,
    isTopicUpdateModeEnabled,
    setIsTopicUpdateModeEnabled,
    documentId,
    setLastCancellation,
    setIsSaveRequested,
  ]);
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.border_color,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
      borderRadius: 20,
      color: currentTheme?.theme?.font_color,
      minWidth: isLargeScreen() ? 500 : 250,
      maxWidth: isLargeScreen() ? 500 : 250,
    }),
    input: (base) => ({
      ...base,
      color: currentTheme?.theme?.font_color, // This would change the color of the text inside the input
    }),
    option: (styles, { data }) => {
      let color = currentTheme?.theme?.font_color;
      return {
        ...styles,
        backgroundColor: currentTheme?.theme?.transparent,
        color: currentTheme?.theme?.font_color,
        cursor: "pointer",

        ":active": {
          ...styles[":active"],
          backgroundColor: currentTheme?.theme?.primary,
        },
        ":hover": {
          ...styles[":hover"],
          backgroundColor: currentTheme?.theme?.primary,
        },
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      color: currentTheme?.theme?.font_color,
    }),
    multiValue: (styles, { data }) => {
      let color = currentTheme?.theme?.font_color;
      return {
        ...styles,
        backgroundColor: currentTheme?.theme?.primary,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: currentTheme?.theme?.font_color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: currentTheme?.theme?.font_color,
      ":hover": {
        backgroundColor: currentTheme?.theme?.primary,
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.lightGray,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
    }),
  };
  const _statusAvailable = [
    { id: "suspended", name: "Suspended" },
    { id: "published", name: "Published" },
    { id: "archived", name: "Archived" },
  ];
  if (documentStatus === "draft") {
    _statusAvailable.push({ id: "draft", name: "Draft" });
  }
  const statusSelectionComponent = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: "column",
          alignItems: "flex-end",
          marginLeft: 20,
          zIndex: 1,
          minWidth: 100,
          marginTop: -10,
        }}
      >
        <AppDropDown
          showTextInputLabel
          label="Status"
          disabled={!isTopicUpdateModeEnabled}
          inputLabelStyle={{
            marginLeft: 0,
            marginRight: 10,
            marginBottom: 0,
          }}
          dropDownStyle={{
            minWidth: 100,
            borderColor: currentTheme?.theme?.border_color,
            maxWidth: !isLargeScreen() && 250,
          }}
          isElevated={false}
          dropdownIconColor="transparent"
          items={_statusAvailable}
          wrapperContainer={{
            flexDirection: "row",
            alignItems: "center",
          }}
          propertyLabel="name"
          propertyValue="id"
          selectedValue={documentStatus}
          onValueChange={(itemValue, itemIndex) => {
            onDocumentStatusChange(itemValue);
          }}
        />
        {
          <>
            <AppText
              wrapperStyle={{
                marginTop: 5,
              }}
              isClickable={embeddingStatus == "ready"}
              onPress={() => {
                setShowSourcesModal(true);
                fetchEmbeddingsByDocument();
              }}
              color={
                embeddingStatus == "ready"
                  ? currentTheme?.theme?.green
                  : currentTheme?.theme?.orange
              }
              leftIcon={
                embeddingStatus == "ready"
                  ? AppAssets.icons.check
                  : AppAssets.icons.clock
              }
              iconSize={16}
              style={{
                marginLeft: 10,
                fontWeight: "bold",
                textDecorationColor:
                  embeddingStatus == "ready"
                    ? currentTheme?.theme?.green
                    : currentTheme?.theme?.orange,
              }}
            >
              {`${mapEmbeddingStatusToText(embeddingStatus)}${
                embeddingStatus == "not_ready"
                  ? ` - ${formatPercentage(embeddingProgress)}`
                  : ""
              }`}
            </AppText>
            <div className="learning_notification"></div>
          </>
        }
      </View>
    );
  });
  const topicSelectionComponent = useMemo(() => {
    return (
      <View
        style={{
          flex: 1,
          marginLeft: 5,
        }}
      >
        <TopicsSelectionComponent
          showAddNew={isTopicUpdateModeEnabled}
          wrapperStyle={{
            paddingBottom: (!isPDF || isHTML || isRawText) && 0,
            paddingTop: (isPDF || isHTML || isRawText) && 0,
          }}
          isTopicUpdateModeEnabled={isTopicUpdateModeEnabled}
          showEditIcon={false}
          selectedTopics={selectedTopics}
          setIsTopicUpdateModeEnabled={setIsTopicUpdateModeEnabled}
          onSelectedTopicsChange={onSelectedTopicsChange}
          topics={topics}
          placeholder="Select a topic to make the document accessible to the agents"
          value={selectedTopics
            ?.filter((y) => topics?.find((t) => t?.id === y?.id))
            ?.map((u) => {
              return {
                ...u,
                backgroundColor:
                  topics?.find((t) => t?.id === u?.id)?.backgroundColor ||
                  currentTheme?.theme?.grey,
                emoji: topics?.find((t) => t?.id === u?.id)?.emoji || "📁",
                value: u?.id,
                label: u?.name || "Undefined",
              };
            })}
        />
        {(isPDF &&
          isPDFLoaded &&
          selectedNode?.data?.external_path?.split(".")?.pop() === "pdf") ||
        selectedNode?.data?.external_path?.split(".")?.pop() === "html" ? (
          <>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                minWidth: 200,
              }}
            >
              <ToothFairySwitch
                disabled={
                  !isTopicUpdateModeEnabled ||
                  (!isExtractImagesAllowed &&
                    selectedNode?.data?.external_path?.split(".")?.pop() !=
                      "html")
                }
                wrapperStyle={{
                  flex: 1,
                  backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                  marginTop: 5,
                  zIndex: -1,
                  maxWidth: 250,
                }}
                label={"Extract images"}
                onChange={(v) => {
                  setExtractImages(v);
                }}
                isTicked={extractImages}
              />
              {extractImages && (
                <AppIcon
                  icon={AppAssets.icons.cogWheel}
                  size={20}
                  wrapperStyle={{
                    marginLeft: 10,
                    marginTop: 5,
                  }}
                  isClickable
                  onPress={() => {
                    setIsIstructionsWindowOpened(true);
                  }}
                />
              )}
            </View>
            {!isExtractImagesAllowed &&
              selectedNode?.data?.external_path?.split(".")?.pop() !=
                "html" && (
                <AppText
                  style={{
                    fontSize: 10,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  wrapperStyle={{
                    marginLeft: 12,
                    position: "absolute",
                    top: 80,
                  }}
                  color={currentTheme?.theme?.darkGrey}
                >
                  Images can be extracted only from files that are less than 50
                  pages
                </AppText>
              )}
          </>
        ) : (
          <View
            style={{
              height: 35,
            }}
          ></View>
        )}
      </View>
    );
  });
  const topicCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: 500,
          width: 450,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={isTopicModalOpen}
        setIsModalOpen={setIsTopicModalOpen}
        modalTitle="Create topic"
      >
        <SettingsAI
          onEntityCreated={handleEntityCreation}
          settingType="topic"
        />
      </ToothFairyModal>
    );
  }, [isTopicModalOpen, setIsTopicModalOpen]);
  const imagesExtractionComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: 500,
          width: 450,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={isInstructionsWindowOpened}
        setIsModalOpen={setIsIstructionsWindowOpened}
        modalTitle="Images extraction instructions"
      >
        <ImagesExtraction
          initialValues={{
            documentImgRetrievalInstructions,
          }}
          savingInProgress={savingInProgress}
          handleFormSubmit={(v) => {
            console.log("submit", v);
            handleImagesExtraction(v);
          }}
        />
      </ToothFairyModal>
    );
  }, [
    isInstructionsWindowOpened,
    setIsIstructionsWindowOpened,
    documentImgRetrievalInstructions,
    savingInProgress,
  ]);
  const _treeView = useMemo(() => {
    return (isTreeDataLoading || _coreTreeViewData === null) && !isCollapsed ? (
      <LoadingPlaceholder
        style={{
          padding: 10,
          maxWidth: 200,
        }}
        visible
        caption="Loading..."
      />
    ) : (
      <div className="documents_tree">
        <AppTreeView
          showCollapseTree={true}
          title="Documents "
          heightOffset={180}
          foldersData={_coreTreeViewData}
          selectedNode={selectedNode}
          handleClickOnNode={handleClickOnNode}
          setSelectedNodeForTreeView={setSelectedNodeForTreeView}
          deleteNode={deleteNode}
          setDialogActionOnTree={setDialogActionOnTree}
          isEmpty={_coreTreeViewData?.length === 0}
          onDrop={onDrop}
          initialOpen={initialOpen}
          minWidth={!isLargeScreen() && minAppWidth() - 50}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          showIconsOnSelection={true}
          onMove={onMove}
          fixedNodeIds={["mrcRoot", "qaRoot"]}
        />
      </div>
    );
  }, [
    JSON.stringify(_coreTreeViewData),
    isTreeDataLoading,
    initialOpen,
    selectedNode?.id,
    isLargeScreen(),
    minAppWidth(),
    isCollapsed,
    getActiveWorkspace()?.id,
    folders?.length,
    setSelectedNodeForTreeView,
  ]);
  const _actionsChoice = () => {
    return (
      <ToothFairyResponsiveOptionsModal
        isModalOpen={isModalOptionsOpen}
        setIsModalOpen={setIsModalOptionsOpen}
        modalTitle={"Actions"}
        height={300}
      >
        <ToothFairyClickableOption
          leftIcon={AppAssets.icons.copy}
          onPress={() => {
            setIsModalOptionsOpen(false);
            setLastModalOpen(Date.now());
            setDialogActionOnTree("NEW_FOLDER");
          }}
        >
          Folder
        </ToothFairyClickableOption>
        <ToothFairyClickableOption
          leftIcon={AppAssets.icons.copy}
          onPress={() => {
            setIsModalOptionsOpen(false);
            setLastModalOpen(Date.now());
            setDialogActionOnTree("NEW_DOCUMENT");
          }}
        >
          Live document
        </ToothFairyClickableOption>
        <ToothFairyClickableOption
          leftIcon={AppAssets.icons.pdf}
          onPress={() => {
            setIsModalOptionsOpen(false);
            setLastModalOpen(Date.now());
            handleFileSelectPdf();
          }}
        >
          Upload PDF
        </ToothFairyClickableOption>
      </ToothFairyResponsiveOptionsModal>
    );
  };

  const _coreHeaderComponent = useMemo(() => {
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <PageTitle>Knowledge hub</PageTitle>
        <ToothFairySupportButton />

        <div
          className="upload_pdf"
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            gap: 10,
            minHeight: 38,
            marginBottom: 2,
          }}
        >
          <AppButtonMenu
            mainActionLabel={"Upload PDF"}
            onClickMainAction={() => {
              handleFileSelectPdf();
            }}
            wrapperStyle={{
              backgroundColor: currentTheme?.theme?.primary,
              paddingVertical: 5,
              paddingHorizontal: 10,
              borderRadius: 20,
            }}
            menuItemIconSize={18}
            labelStyle={{
              fontWeight: "bold",
            }}
            style={{
              top: 35,
              fontFamily: "boldFont",
              minWidth: 110,
            }}
            menuItemStyle={{
              marginLeft: 10,
              fontSize: currentTheme?.theme?.small_font_size,
            }}
            color={currentTheme?.theme?.black}
            rightIconColor={currentTheme?.theme?.black}
            onClickMenuItem={(id) => {
              if (id === "NEW_FOLDER") {
                setDialogActionOnTree("NEW_FOLDER");
              } else if (id === "NEW_DOCUMENT") {
                setDialogActionOnTree("NEW_DOCUMENT");
              } else if (id === "NEW_PDF") {
                handleFileSelectPdf();
              } else if (id === "IMPORT_FILE") {
                handleFileSelectGeneric();
              }
            }}
            options={[
              {
                label: "Upload PDF",
                id: "NEW_PDF",
                icon: AppAssets.icons.pdf,
              },
              {
                label: "Upload Files",
                id: "IMPORT_FILE",
                icon: AppAssets.icons.import,
              },
              {
                label: "Live Document",
                id: "NEW_DOCUMENT",
                icon: AppAssets.icons.file,
              },
              {
                label: "Folder",
                id: "NEW_FOLDER",
                icon: AppAssets.icons.folder,
              },
            ]}
          />

          {isSearchInputOpen && (
            <AppMultipleSelectInput
              accessibilityLabel="docs_search"
              onSelectionChange={(v) => {
                handleDocumentSelection(v);
              }}
              onInputChange={(v) => {
                setSearchText(v);
              }}
              autoFocus={true}
              inputValue={searchText}
              defaultInputValue={searchText}
              selectedItems={[]}
              labelOption={"title"}
              placeholder="Find documents"
              isAsync={true}
              promiseOptions={promiseOptions}
              uniqueKey="chunk_id"
              showDropDowns={true}
              readOnlyHeadings={true}
              styles={colourStyles}
            />
          )}
          <AppIcon
            icon={
              isSearchInputOpen
                ? AppAssets?.icons?.close
                : AppAssets?.icons?.search
            }
            size={20}
            onPress={() => {
              setShowSearchInput(!isSearchInputOpen);
            }}
            isClickable
          />
        </div>
      </View>
    );
  }, [
    setDialogActionOnTree,
    isLargeScreen(),
    handleFileSelectPdf,
    searchText,
    setSearchText,
    isSearchInputOpen,
    setShowSearchInput,
  ]);
  return (
    <AppBaseScreen
      isScrollable={true}
      contentContainerStyle={{
        overflow: "auto",
        flex: 1,
      }}
    >
      <AppJoyRide
        handleCallback={(e) => {
          if (
            e?.index == 2 &&
            e?.action == "next" &&
            e?.lifecycle == "complete"
          ) {
            updateUserHelperPreferences(user, {
              knowledgeHubTourCompleted: true,
            });
          }
        }}
        run={user?.userHelperPreferences?.knowledgeHubTourCompleted != true}
        steps={steps_first_interaction}
        spotlightClicks={true}
      />
      <View
        style={{
          paddingHorizontal: 20,
          paddingVertical: 10,
          minWidth: minAppWidth(),
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            maxWidth: isMobileScreen() && 300,
          }}
        >
          {_coreHeaderComponent}
          {(isPDF && isPDFLoaded) || (!isPDF && !isLoading) ? (
            _savingNotification
          ) : (
            <></>
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            zIndex: -1,
          }}
        >
          {_treeView}
          {isLoading || isPDFUploadInProgress ? (
            <LoadingPlaceholder
              style={{
                padding: 10,
              }}
              visible
              caption="Loading document"
            />
          ) : isDocumentValid ? (
            <View
              style={{
                flex: 1,
                minWidth: minAppWidth() - 40,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                  alignItems: "center",
                  flexWrap: "wrap",
                  zIndex: 2,
                }}
              >
                {(isPDF && isPDFLoaded) || (!isPDF && !isLoading) ? (
                  <>
                    {topicSelectionComponent}
                    {statusSelectionComponent}
                  </>
                ) : (
                  <View
                    style={{
                      height: 68,
                    }}
                  ></View>
                )}
              </View>
              {selectedNode?.data?.external_path ? (
                selectedNode?.data?.external_path?.split(".")?.pop() ===
                "pdf" ? (
                  <PDFViewer
                    path={selectedNode?.data?.external_path}
                    onPDFDocumentLoad={onPDFDocumentLoad}
                  />
                ) : (
                  <GenericViewer
                    path={selectedNode?.data?.external_path}
                    onDownloadClick={(path) => {
                      console.log("download", path);
                    }}
                    isPDFLoaded={isPDFLoaded}
                    onPDFDocumentLoad={onPDFDocumentLoad}
                    documentId={documentId}
                  />
                )
              ) : (
                <EditorWrapper
                  {..._editorStyles}
                  isToolBarVisible
                  allowImages={true}
                  workspaceid={getActiveWorkspace()?.id}
                  editMode={true}
                  isPDF={selectedNode?.data?.external_path != null}
                  isPlaceHolderEnabled
                  placeholder="Enter text here..."
                  onSocketConnectionChange={onSocketConnectionChange}
                  onError={handleEditorError}
                  value={value}
                  setValue={setValue}
                  documentId={documentId}
                  isInactive={isInactive}
                  words={wordsCount}
                  characters={charactersCount}
                  agcEnabled={agcEnabled}
                  fakeCheckInProgress={fakeCheckInProgress}
                  fakeCheckData={fakeCheckData}
                  onRecordClick={handleOnRecorderClick}
                  allowTranscription={true}
                  setEditor={setEditor}
                />
              )}
            </View>
          ) : (
            <DelayedRender delay={3000}>
              <AppPlaceholderView
                accessibilityLabel="doc-placeholder"
                wrapperStyle={{
                  width: 300,
                  height: 200,
                  marginVertical: "auto",
                  marginHorizontal: "auto",
                }}
                size={60}
                icon={AppAssets.icons.orbit}
                mainCaption={
                  documentId
                    ? "DocumentDoesNotExistCaption"
                    : "SelectDocumentCaption"
                }
                subCaption={
                  documentId
                    ? "DocumentDoesNotExistSubCaption"
                    : "SelectDocumentSubCaption"
                }
              />
              <div
                style={{
                  width: 0,
                }}
                className="document_section"
              ></div>
            </DelayedRender>
          )}
        </View>
      </View>
      {_coreModalView}
      {topicCreationComponent}
      {_coreSourcesModelView}
      {imagesExtractionComponent}
      {_actionsChoice()}
    </AppBaseScreen>
  );
};

export default MrcDocumentsView;
