import React from "react";
import AppTab from "@toothfairy/shared-ui/AppTab";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const ToothFairyTab = (props) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <AppTab
      selectionBackgroundColor={currentTheme?.theme?.transparent}
      nonAnimatedTabViewStyle={{ paddingBottom: 10 }}
      {...props}
    />
  );
};

export default ToothFairyTab;
