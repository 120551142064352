import React, { useRef } from "react";
import envConfig from "../../../envConfig";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const ChatWidget = ({ isOpen, ...props }) => {
  const tfWidgetRef = useRef(null);
  const { currentTheme } = AppTheme.useTheme();
  function tfToggleChat(open) {
    const tfChatWidget = document.getElementById("tfChatWidget");
    const tfWidget = tfWidgetRef.current;
    const openChatBtn = document.getElementById("tfOpenChat");
    const startNewBtn = document.getElementById("tfStartNew");
    const closeChatBtn = document.getElementById("tfCloseChat");

    tfWidget.style.display = open ? "block" : "none";
    openChatBtn.style.display = open ? "none" : "block";
    startNewBtn.style.display = open ? "block" : "none";
    closeChatBtn.style.display = open ? "block" : "none";

    tfChatWidget.style.border = open ? "0.5px solid grey" : "none";
    tfChatWidget.style.backgroundColor = open ? "white" : "transparent";
  }

  function tfStartNewChat() {
    const tfWidget = tfWidgetRef.current;
    tfWidget.src += "";
    tfToggleChat(true);
  }

  return (
    <div
      id="tfChatWidget"
      style={{
        position: "fixed",
        bottom: "10px",
        right: "10px",
        zIndex: 10,
        border: "none",
        backgroundColor: "transparent",
        height: "95%",
        minHeight: 400,
      }}
    >
      <style>
        {`.tfChatBtn {
          border: none;
          border-radius: 5px;
          padding: 10px 15px;
          margin: 5px;
          cursor: pointer;
          transition: background 0.3s;
        }`}
      </style>
      <iframe
        id="tfWidget"
        ref={tfWidgetRef}
        src={`https://agent.${
          envConfig?.REACT_APP_ENV == "dev"
            ? "toothfairylab.link"
            : "toothfairyai.com"
        }/${envConfig?.REACT_APP_SUPPORT_WORKSPACE}/${
          envConfig?.REACT_APP_SUPPORT_AGENT
        }`}
        style={{
          display: "block",
          width: "360px",
          height: "400px",
          border: `0.5px solid ${currentTheme?.theme?.border_color}`,
          borderRadius: "5px",
          height: "95%",
          marginTop: 30,
        }} // Adjust iframe size if needed
      />
    </div>
  );
};

export default ChatWidget;
