import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import { showSideMenu } from "../../../../../App";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import { isLargeScreen } from "../../../MainViewContainer";
import ExecEnvsSettings from "./ExecEnvsSettings";
import ExecEnvsSettingsToolbar from "./ExecEnvsSettingsToolbar";
import execEnvsHelper from "../../../../API/ExecutionEnvironments/execEnvsHelper";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import envConfig from "../../../../../envConfig";

const ExecEnvsList = ({ isExecsLoading, setDisplayedView }) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const { getActiveWorkspace } = useWorkspaces();
  const { width } = useWindowDimensions();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [showexecEnvModal, setShowexecEnvModal] = React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedExecEnvs, setDisplayedexecEnvs] = React.useState([]);
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { height } = useWindowDimensions();
  const [initialMapValues, setInitialMapValues] = useState({
    name: "",
    description: "",
    functionName: "",
    availableLibraries: "",
    customExecutionInstructions: "",
    customExecutionCode: "",
  });
  const columns = [
    {
      title: "ID",
      width: 0.2,
    },
    {
      title: "Name",
      width: 0.25,
    },
    {
      title: "Description",
      width: 0.35,
    },
    {
      title: "Remote environment",
      width: 0.15,
    },
    {
      title: "Actions",
      isAction: true,
      width: 0.1,
    },
  ];
  const onExecEnvDeleted = useCallback(() => {
    setShowexecEnvModal(false);
  });
  const onExecEnvCreated = useCallback(
    (event) => {
      if (event === "success") {
        AppToasty.show(t("execEnvCreationSuccessCaption"), {
          type: "success",
        });
        loadExecEnvironments(state?.activeWorkspaceID);
        setShowexecEnvModal(false);
      } else
        AppToasty.show(t("execEnvgCreationFailedCaption"), {
          type: "danger",
        });
      formRef?.current?.resetForm();
      setShowexecEnvModal(false);
      setSelectedIndex(null);
    },
    [AppToasty, t, loadExecEnvironments, state?.activeWorkspaceID]
  );
  const loadExecEnvironments = async (workspaceID) => {
    const execEnvsData =
      await execEnvsHelper.getCustomCodeExecutionEnvironments(
        workspaceID,
        null
      );
    let execEnvs =
      execEnvsData?.data?.customCodeExecutionEnvironmentsByWorkspace?.items;
    execEnvs =
      execEnvs
        ?.map((u) => {
          if (u?._deleted !== true)
            return {
              ...u,
            };
        })
        ?.filter((u) => u != null) || [];
    dispatch("SET_EXEC_ENVS", execEnvs || []);

    setDisplayedexecEnvs(execEnvs);
  };
  useEffect(() => {
    loadExecEnvironments(state?.activeWorkspaceID);
  }, [state?.activeWorkspaceID]);
  useEffect(() => {
    setDisplayedexecEnvs([...state?.execEnvs]);
  }, [JSON.stringify([...state?.execEnvs])]);

  const execEnvItemCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        key={Date.now().toString()}
        modalStyle={{
          maxHeight: "95%",
          height: "auto",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showexecEnvModal}
        setIsModalOpen={setShowexecEnvModal}
        modalTitle={isEditMode ? "execEnvEditTitle" : "execEnvCreationTitle"}
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowexecEnvModal(false);
          setSelectedIndex(null);
        }}
      >
        <ExecEnvsSettings
          ref={formRef}
          initialMapValues={initialMapValues}
          onExecEnvCreated={onExecEnvCreated}
          isEdit={isEditMode}
          selectedId={displayedExecEnvs[selectedIndex]?.id}
          opt={displayedExecEnvs[selectedIndex]}
          setDisplayedView={setDisplayedView}
          onExecEnvDeleted={onExecEnvDeleted}
        />
      </ToothFairyModal>
    );
  }, [
    showexecEnvModal,
    setShowexecEnvModal,
    initialMapValues,
    isEditMode,
    selectedIndex,
    displayedExecEnvs,
    isLargeScreen(),
    onExecEnvDeleted,
  ]);
  const identifyOptByID = (id) => {
    return state.execEnvs.find((u) => u.id === id);
  };
  const handleExecEnvItemSelection = ({ item, index }) => {
    setSelectedIndex(index);
    setIsEditMode(true);
    let extendedItem = identifyOptByID(item.id);
    setInitialMapValues({
      ...extendedItem,
    });

    setShowexecEnvModal(true);
  };
  const handleExecEnvItemDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        const functionsData =
          await execEnvsHelper.getCustomCodeExecutionEnvironments(
            state?.activeWorkspaceID
          );
        let functions =
          functionsData?.data?.CustomCodeExecutionEnvironmentsByWorkspace
            ?.items;
        functions =
          functions
            ?.map((u) => {
              if (u?.llmProvider === displayedExecEnvs[selectedIndex]?.id)
                return {
                  ...u,
                };
            })
            ?.filter((u) => u != null) || [];
        if (functions.length > 0) {
          AppToasty.show(t("execEnvDeletionErrorCaptionDueToConflict"), {
            type: "danger",
          });
          return;
        }
        const _id = displayedExecEnvs[selectedIndex]?.id;
        const result =
          await execEnvsHelper.deleteCustomCodeExecutionEnvironment(_id);
        dispatch("SET_EXEC_ENVS", [
          ...state?.execEnvs.filter((u) => u.id !== _id),
        ]);
        AppToasty.show(t("execEnvDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      console.log(error);
      AppToasty.show(t("execEnvsItemDeleteFailedCaption"), {
        type: "danger",
      });
    }
  };
  const handleSearch = async (name, description, host, type) => {
    // find items in the list which cointain either the sourceText or targetText
    const filteredItems = state?.execEnvs.filter((item) => {
      return (
        item?.name?.toLowerCase()?.includes(name?.toLowerCase()) &&
        item?.description?.toLowerCase()?.includes(description?.toLowerCase())
      );
    });
    setDisplayedexecEnvs(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleCreation = () => {
    setInitialMapValues({
      name: "",
      description: "",
      functionName: "",
      availableLibraries: "",
      customExecutionInstructions: "",
      customExecutionCode: "",
      allowExternalAPI: false,
    });
    setIsEditMode(false);
    setShowexecEnvModal(true);
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"execEnvsItemCancelConfirmation"}
        caption="execEnvsItemCancelCaption"
        subCaption="execEnvsItemCancelSubCaption"
        confirmCaption={"execEnvsItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={() => handleExecEnvItemDeletion()}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [setShowChoice, showChoice, selectedItem, setSelectedItem]);

  return getActiveWorkspace()?.subscriptionType === "enterprise" ||
    envConfig.REACT_APP_ENV == "dev" ? (
    <View
      style={{
        paddingLeft: 10,
        flex: 1,
        width: showSideMenu() ? width - (showSideMenu() ? 410 : 220) : "100%",
        minWidth: "100%",
      }}
    >
      <>
        <ExecEnvsSettingsToolbar
          handleSearch={handleSearch}
          handleCreation={handleCreation}
        />
        {isExecsLoading ? (
          <AppActivityIndicator
            backgroundColor={currentTheme?.theme?.softBackgroundColor}
            visible={true}
            containerStyle={{
              backgroundColor: currentTheme?.theme?.transparent,
              minHeight: 250,
            }}
          />
        ) : (
          <>
            {displayedExecEnvs.length > 0 ? (
              <AppTable
                actionButtons
                handleDocumentSelection={handleExecEnvItemSelection}
                handleDocumentDeletion={(item, index) => {
                  setSelectedItem(item);
                  setSelectedIndex(index);
                  setShowChoice(true);
                }}
                style={{
                  zIndex: -1,
                  maxHeight: height - 180,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                headers={columns}
                items={displayedExecEnvs
                  ?.slice(
                    (currentPage - 1) * pageSize,
                    (currentPage - 1) * pageSize + pageSize
                  )
                  ?.map((u) => {
                    return {
                      id: u?.id || " ",
                      name: u?.name || " ",
                      description: u?.description || " ",
                      functionName: u?.functionName || " ",
                      actions: " ",
                    };
                  })}
              />
            ) : (
              <AppPlaceholderView
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  marginVertical: 60,
                  marginHorizontal: "auto",
                  flex: 1,
                  zIndex: -1,
                }}
                size={80}
                icon={AppAssets.icons.orbit}
                mainCaption="EmptyPlaceholder"
                subCaption="execEnvsSubCaption"
              />
            )}
            {displayedExecEnvs?.length > pageSize && (
              <AppPagination
                onPageChange={handlePageChange}
                totalCount={displayedExecEnvs?.length}
                siblingCount={1}
                currentPage={currentPage}
                pageSize={pageSize}
                wrapperStyle={{
                  marginTop: 10,
                  alignSelf: "flex-end",
                }}
              />
            )}
          </>
        )}
      </>

      {execEnvItemCreationComponent}
      {_coreChoice}
    </View>
  ) : (
    <AppPlaceholderView
      accessibilityLabel="code-execution-environment-availability"
      wrapperStyle={{
        maxWidth: 400,
        height: 200,
        marginHorizontal: "auto",
        marginTop: 50,
      }}
      size={60}
      icon={AppAssets.icons.accessDenied}
      mainCaption="Code execution environments are not available for your plan"
      subCaption="Upgrade to enterprise to enable this setting"
    />
  );
};

export default ExecEnvsList;
