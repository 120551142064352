import React from "react";
import ToothFairySlider from "../../../../Components/ToothFairyComponents/ToothFairySlider";
import useWorkspaces from "../../../../Hooks/useWorkspaces";

const PreferencesGPT = ({
  handleUpdate,
  isWorkspaceSetting,
  isQA,
  temperatureProperty,
  topPProperty,
  frequencyPenaltyProperty,
  presencePenaltyProperty,
  maxTokensProperty,
  TopKProperty,
  step = 50,
}) => {
  const { getActiveWorkspace, getContentMultiplier } = useWorkspaces();
  return (
    <>
      <ToothFairySlider
        minimumValue={0.1}
        maximumValue={1}
        step={0.1}
        label="Temperature"
        onSubmit={(v) => {
          handleUpdate("parsingConfig", {
            ...getActiveWorkspace().parsingConfig,
            [temperatureProperty]: v,
          });
        }}
        initialValue={getActiveWorkspace()?.parsingConfig[temperatureProperty]}
      />
      <ToothFairySlider
        label="TopP"
        minimumValue={0.1}
        maximumValue={1}
        step={0.1}
        onSubmit={(v) =>
          handleUpdate("parsingConfig", {
            ...getActiveWorkspace().parsingConfig,
            [topPProperty]: v,
          })
        }
        initialValue={getActiveWorkspace()?.parsingConfig[topPProperty]}
      />
      <ToothFairySlider
        label="Frequency penalty"
        minimumValue={0.1}
        maximumValue={1}
        step={0.1}
        onSubmit={(v) =>
          handleUpdate("parsingConfig", {
            ...getActiveWorkspace().parsingConfig,
            [frequencyPenaltyProperty]: v,
          })
        }
        initialValue={
          getActiveWorkspace()?.parsingConfig[frequencyPenaltyProperty]
        }
      />
      <ToothFairySlider
        label="Presence penalty"
        minimumValue={0.1}
        maximumValue={1}
        step={0.1}
        onSubmit={(v) =>
          handleUpdate("parsingConfig", {
            ...getActiveWorkspace().parsingConfig,
            [presencePenaltyProperty]: v,
          })
        }
        initialValue={
          getActiveWorkspace()?.parsingConfig[presencePenaltyProperty]
        }
      />
      <ToothFairySlider
        label="Max tokens"
        minimumValue={isQA ? 50 : 300}
        maximumValue={(isQA ? 200 : 2000) * getContentMultiplier()}
        step={step}
        onSubmit={(v) =>
          handleUpdate("parsingConfig", {
            ...getActiveWorkspace().parsingConfig,
            [maxTokensProperty]: v,
          })
        }
        initialValue={getActiveWorkspace()?.parsingConfig[maxTokensProperty]}
      />
      {isQA && (
        <ToothFairySlider
          label="Top K"
          minimumValue={1}
          maximumValue={20}
          step={1}
          onSubmit={(v) =>
            handleUpdate("parsingConfig", {
              ...getActiveWorkspace().parsingConfig,
              [TopKProperty]: v,
            })
          }
          initialValue={getActiveWorkspace()?.parsingConfig[TopKProperty]}
        />
      )}
    </>
  );
};

export default PreferencesGPT;
