import AppButton from "@toothfairy/shared-ui/AppButton";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback } from "react";
import Constants from "../../Constants";

const ToothFairyButton = ({
  style,
  wrapperStyle,
  isDeletion,
  isSecondary,
  paddingLeft = 0,
  paddingRight = 0,
  icon,
  isDisabled,
  ...props
}) => {
  const { currentTheme, mode } = AppTheme.useTheme();
  const generateBtnFontColor = useCallback(() => {
    if (isDisabled) {
      return currentTheme?.theme?.white;
    } else if (isDeletion) {
      return currentTheme?.theme?.red;
    } else {
      return currentTheme?.theme?.black;
    }
  }, [isDisabled, isDeletion, mode]);
  return (
    <AppButton
      awesomeIcon={icon}
      fontColor={generateBtnFontColor()}
      awesomeIconColor={currentTheme?.theme?.white}
      backgroundColor={
        isDeletion
          ? currentTheme?.theme?.transparent
          : isSecondary
          ? currentTheme?.theme?.lightGrey
          : currentTheme?.theme?.primary
      }
      isDisabled={isDisabled}
      btnContentStyle={{
        paddingLeft,
        paddingRight: icon ? 20 : paddingRight,
      }}
      style={[
        {
          height: Constants.appButtonHeight,
          width: "auto",
          shadowColor: currentTheme?.theme?.transparent,
          marginHorizontal: 10,
        },
        style,
      ]}
      btnLabelStyle={{ fontSize: 16 }}
      wrapperStyle={[{ width: "fit-content", minWidth: 150 }, wrapperStyle]}
      {...props}
    />
  );
};

export default ToothFairyButton;
