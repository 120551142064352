import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  matchPath,
  Switch,
} from "react-router-dom";

// This is here to fix the imports on web
const DeepLinking = {};

export {
  DeepLinking,
  Link,
  Router,
  Route,
  Redirect,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  matchPath,
  Switch,
};
