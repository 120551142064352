import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppToast from "@toothfairy/shared-ui/AppToast";
import { useTranslation } from "react-i18next";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairySwitch from "../../../Components/ToothFairyComponents/ToothFairySwitch";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import TopicsSelectionComponent from "../../MrcDocuments/Components/TopicsSelectionComponent";

const TrainingConfigSelection = ({
  selectedTab,
  trainingConfig,
  setTrainingConfig,
  setIsTrainingInitiationModalOpen,
  handleTrainingInitiation,
  setSelectedTopics,
  selectedTopics,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { isTrainingEnabled, getWorkspaceEntities, getActiveWorkspace } =
    useWorkspaces();
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic?.label;
    topic.category = topic.type;
    return topic;
  });
  return (
    <AppChoice
      wrapperStyle={{ alignSelf: "center", maxWidth: 600 }}
      caption={`${Constants.mapKeysToNames(selectedTab?.key)} fine-tuning`}
      subCaption="Please provide a name and a description for the fine-tuning experiment"
      cancelCaption="Click 'Cancel' to abort"
      confirmCaption="Click 'Confirm' to start the process"
      onConfirmButtonPress={() => {
        if (!trainingConfig?.name || trainingConfig?.name == "") {
          {
            return AppToasty.show(t("TrainingCreationFailedCaptionName"), {
              placement: "bottom",
              type: "danger",
            });
          }
        }
        if (
          (!trainingConfig?.baseModel || trainingConfig?.baseModel == "none") &&
          selectedTab?.key !== "nlpTraining"
        ) {
          return AppToasty.show(t("TrainingCreationFailedCaptionBaseModel"), {
            placement: "bottom",
            type: "danger",
          });
        }
        handleTrainingInitiation();
        setIsTrainingInitiationModalOpen(false);
      }}
      onCancelButtonPress={() => {
        setIsTrainingInitiationModalOpen(false);
      }}
      AppChoiceButton={ToothFairyButton}
    >
      {/*  */}
      {!trainingConfig?.datasetOnly && (
        <AppText
          color={currentTheme?.theme?.orange}
          leftIcon={AppAssets?.icons?.risk}
          style={{
            marginLeft: 10,
          }}
          wrapperStyle={{
            marginTop: 10,
          }}
        >
          It is highly recommended to generate at least 100 examples to
          fine-tune
        </AppText>
      )}

      <AppTextInput
        placeholder="trainNamePlaceholder"
        style={{
          marginTop: 2,
        }}
        wrapperInputStyle={{
          maxWidth: 470,
          marginBottom: 20,
          marginTop: 20,
        }}
        textContainer={{
          margin: 0,
        }}
        type="text"
        value={trainingConfig?.name}
        onChangeText={(text) => {
          setTrainingConfig({ ...trainingConfig, name: text });
        }}
      />
      <AppTextInput
        multiline={true}
        numberOfLines={4}
        placeholder="trainDescrPlaceholder"
        style={{
          marginTop: 2,
        }}
        wrapperInputStyle={{
          maxWidth: 470,
          marginBottom: 20,
        }}
        textContainer={{
          margin: 0,
          padding: 10,
        }}
        type="text"
        value={trainingConfig?.description}
        onChangeText={(text) => {
          setTrainingConfig({ ...trainingConfig, description: text });
        }}
      />
      {selectedTab?.key !== "nlpTraining" && (
        <>
          <TopicsSelectionComponent
            onSelectedTopicsChange={(v) => setSelectedTopics(v)}
            selectedTopics={selectedTopics}
            isTopicUpdateModeEnabled
            showAddNew={false}
            showEditIcon={false}
            topics={topics}
            placeholder="Filter topics"
            wrapperStyle={{
              paddingTop: 0,
              paddingBottom: 20,
              marginRight: 20,
            }}
            wrapperContainerStyle={{
              flex: 1,
            }}
          />
          <AppDropDown
            dropDownStyle={{
              width: 300,
              marginBottom: 20,
              borderColor: currentTheme?.theme?.border_color,
            }}
            isElevated={false}
            dropdownIconColor="transparent"
            items={[
              {
                label: "Select a base model",
                id: "none",
              },
              ...Constants.baseModels,
            ]}
            propertyLabel="label"
            propertyValue="id"
            selectedValue={trainingConfig?.baseModel}
            onValueChange={(itemValue, itemIndex) => {
              setTrainingConfig({
                ...trainingConfig,
                baseModel: itemValue,
              });
            }}
          />
        </>
      )}

      <ToothFairySwitch
        wrapperStyle={{
          flex: 1,
          marginRight: 20,
          backgroundColor: currentTheme?.theme?.inputBackgroundColor,
          marginTop: 0,
          zIndex: -1,
        }}
        disabled={!isTrainingEnabled}
        label={"Dataset only"}
        onChange={(v) => {
          setTrainingConfig({
            ...trainingConfig,
            datasetOnly: v,
          });
        }}
        isTicked={trainingConfig?.datasetOnly}
      />
      {!isTrainingEnabled &&
      getActiveWorkspace()?.subscriptionType !== "enterprise" ? (
        <AppText
          leftIcon={AppAssets?.icons?.question_icon}
          iconSize={18}
          color={currentTheme?.theme?.orange}
          isClickable
          style={{
            marginLeft: 10,
            textDecorationColor: currentTheme?.theme?.orange,
          }}
          wrapperStyle={{
            padding: 15,
          }}
          onPress={() => {
            // open a new tab with the https://www.toothfairyai.com/contact-us
            window.open("https://www.toothfairyai.com/contact", "_blank");
          }}
        >
          Upgrade to enterprise to use fine-tuned models in ToothFairyAI
        </AppText>
      ) : (
        <AppText></AppText>
      )}
    </AppChoice>
  );
};

export default TrainingConfigSelection;
