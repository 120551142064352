import { AppText } from "@toothfairy/shared-ui";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import Color from "../../../../Utility/Color";
import { isLargeScreen } from "../../../MainViewContainer";
import SiteTokenComponent from "../Sites/Components/SiteTokenComponent";
import AdminInfoLeaf from "../../../Admin/Components/SubComponents/AdminInfoLeaf";

const SiteSettingsView = ({
  isEdit,
  settingType,
  handleDeletion,
  setDisplayTokenSection,
  displayTokenSection,
  requestValidation = () => {},
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const entityName = "Site mapping";
  const { setFieldValue, values, validationSchema, isValid, dirty } =
    AppForms.useFormikContext();
  const { state } = AppStateManager.useAppStateManager();
  const _site_ = state?.sites?.find((u) => u?.id === values?.id);
  const AppToasty = AppToast.useToast();
  const { getWorkspaceBaseModel, getWorkspaceEntities } = useWorkspaces();
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  const _topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic["label"];
    topic.category = topic["type"];
    return { ...topic };
  });
  const createMapFile = () => {
    // using getSiteObject()?.siteTree generate a txt file and allow the user to download it
    const element = document.createElement("a");
    const file = new Blob(
      [JSON.stringify(getSiteObject()?.siteTree, undefined, 4)],
      {
        type: "text/plain;charset=utf-8",
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = `${getSiteObject()
      ?.url?.trim()
      ?.replace("https://", "")
      ?.replace("http://", "")}-sitemap.txt`;
    document.body.appendChild(element);
    element.click();
  };
  const getSiteObject = () => {
    return state?.sites?.find((u) => u?.id === values?.id);
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.border_color,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
      borderRadius: 20,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let color = new Color(data?.backgroundColor || currentTheme?.theme?.grey);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? currentTheme?.theme?.white
            ? "white"
            : "black"
          : data?.backgroundColor,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data?.backgroundColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      let color = new Color(data?.backgroundColor || currentTheme?.theme?.grey);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor,
      ":hover": {
        backgroundColor: data?.backgroundColor,
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.lightGray,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
    }),
  };
  if (displayTokenSection)
    return (
      <View>
        <AppText
          color={currentTheme?.theme?.primary}
          style={{
            fontWeight: "bold",
          }}
          wrapperStyle={{
            marginTop: 20,
          }}
        >
          Please add this DNS record to your domain
        </AppText>
        <SiteTokenComponent values={values} />
        <ToothFairyButton
          title="Validate"
          wrapperStyle={{
            marginTop: 30,
            alignSelf: "center",
          }}
          onPress={() =>
            requestValidation(
              state?.sites?.find(
                (u) =>
                  u?.url?.trim() === values?.url?.trim() ||
                  u?.url?.trim()?.replace("https://", "") ===
                    values?.url?.trim()
              )?.id
            )
          }
        />
      </View>
    );
  else
    return (
      <>
        <>
          <View>
            {isEdit && (
              <AdminInfoLeaf
                wrapperStyle={{
                  alignSelf: "flex-start",
                  marginBottom: 10,
                }}
                textContainer={{
                  marginLeft: 5,
                  marginTop: 10,
                  marginBottom: 0,
                }}
                titleStyle={{
                  marginTop: 0,
                  marginLeft: 20,
                }}
                textStyle={{
                  marginLeft: 0,
                }}
                title={"ID"}
                value={getSiteObject()?.id}
                width={420}
              />
            )}
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              editable={!isEdit}
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              label={`Domain`}
              placeholder={`https://domain.com/path`}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name="url"
            />
            {isEdit && (
              <AdminInfoLeaf
                wrapperStyle={{
                  alignSelf: "flex-start",
                  marginBottom: 10,
                }}
                textContainer={{
                  marginLeft: 5,
                  marginTop: 10,
                  marginBottom: 0,
                  marginHorizontal: 0,
                }}
                textStyle={{
                  marginLeft: 0,
                }}
                titleStyle={{
                  marginTop: 0,
                  marginLeft: 20,
                }}
                title={"Synced path"}
                value={getSiteObject()?.allowedPaths || ["*"]}
                width={420}
              />
            )}
            <AppForms.AppFormField
              showTextInputLabel
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              label={`Description`}
              placeholder={`Description of what the site is about`}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name="description"
            />

            <View style={{ margin: 5, zIndex: 20 }}>
              <AppForms.AppFormMultiSelect
                name="topics"
                placeholder="Assign topics"
                hidePlaceholder={true}
                items={_topics}
                uniqueKey="id"
                showDropDowns={true}
                readOnlyHeadings={true}
                labelOption="label"
                groupByProperty="type"
                style={{ maxWidth: 800 }}
                wrapperContainerStyle={{
                  flex: !isLargeScreen() ? 1 : 0.5,
                  zIndex: 2000,
                  paddingTop: 10,
                  marginBottom: 10,
                }}
                styles={colourStyles}
                options={_topics.map((u) => {
                  return {
                    ...u,
                    value: u?.id,
                  };
                })}
              />
            </View>

            <AppForms.AppFormSwitch
              // disabled={isEdit}
              // disabled={true}
              wrapperStyle={[
                {
                  backgroundColor: isEdit
                    ? currentTheme?.theme?.grey
                    : currentTheme?.theme?.lightGrey,
                  maxHeight: 30,
                  paddingHorizontal: 10,
                  borderRadius: 20,
                  paddingVertical: 18,
                  margin: 5,
                },
              ]}
              labelTextStyle={{
                fontSize: currentTheme?.theme?.small_font_size,
                color: currentTheme?.theme?.darkGrey,
              }}
              showTextInputLabel={true}
              label={"Extract images"}
              displayIsTicked={true}
              name="extractImages"
              value={values?.extractImages}
            />
            {values?.extractImages && (
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                label={`Images retrieval`}
                placeholder={`Filter images to extract`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="imgRetrievalPrompt"
                textContainer={{
                  minHeight: 120,
                  paddingVertical: 10,
                }}
                multiline={true}
              />
            )}

            <View
              style={{
                marginLeft: 0,
              }}
            >
              <AppForms.AppFormSwitch
                wrapperStyle={[
                  {
                    backgroundColor: isEdit
                      ? currentTheme?.theme?.grey
                      : currentTheme?.theme?.lightGrey,
                    maxHeight: 30,
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    paddingVertical: 18,
                    margin: 5,
                    marginBottom: 10,
                  },
                ]}
                labelTextStyle={{
                  fontSize: currentTheme?.theme?.small_font_size,
                  color: currentTheme?.theme?.darkGrey,
                }}
                showTextInputLabel={true}
                label={"Use proxy"}
                displayIsTicked={true}
                name="useProxy"
                value={values?.useProxy}
              />
            </View>
            <AppForms.AppFormDropDown
              isElevated={false}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: !isLargeScreen() && 250,
              }}
              showTextInputLabel
              label="Sync cycle"
              isMandatory
              name="scrapingCycle"
              items={[
                {
                  id: 0,
                  name: "Manual",
                },
                {
                  id: 24,
                  name: "Every 24 hours",
                },
                {
                  id: 72,
                  name: "Every 72 hours",
                },
                {
                  id: 168,
                  name: "Every week",
                },
              ]}
              propertyLabel="name"
              propertyValue="id"
            />
            {isEdit && (
              <>
                <SiteTokenComponent
                  wrapperStyle={{
                    padding: 4,
                    marginTop: -25,
                  }}
                  values={{
                    ...values,
                  }}
                />
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    marginTop: 20,
                    zIndex: -1,
                  }}
                >
                  <AdminInfoLeaf
                    showCopyIconOnSimpleText={false}
                    wrapperStyle={{
                      alignSelf: "flex-start",
                      marginBottom: 10,
                    }}
                    textContainer={{
                      marginLeft: 5,
                      marginTop: 10,
                      marginBottom: 0,
                      marginHorizontal: 0,
                    }}
                    simpleTextWrapperStyle={{
                      marginLeft: 20,
                      marginTop: 10,
                    }}
                    textStyle={{
                      marginLeft: 0,
                    }}
                    titleStyle={{
                      marginTop: 0,
                      marginLeft: 20,
                    }}
                    title={"Last synced"}
                    showSimpleText
                    value={getSiteObject()?.lastScraped || "Never"}
                    width={420}
                  />
                  <AppText
                    isClickable={getSiteObject()?.lastScraped != null}
                    color={
                      getSiteObject()?.lastScraped != null
                        ? currentTheme?.theme?.primary
                        : currentTheme?.theme?.darkGrey
                    }
                    style={{
                      fontWeight: "bold",
                    }}
                    onPress={() => {
                      createMapFile();
                    }}
                  >
                    Get site map
                  </AppText>
                </View>
              </>
            )}

            {/* <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 245,
              paddingVertical: 10,
            }}
            label={`Parameters`}
            placeholder={`{"type":"object","properties":{"foo":{"type":"string","description":"foo desc"},"poe":{"type":"string","enum":["enum1","enum2"],"description":"poe desc"}},"required":["foo"]}`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="parameters"
          /> */}
          </View>
        </>
        <View style={{ alignItems: "center", zIndex: -1 }}>
          <AppForms.AppSubmitButton
            title={_title()}
            awesomeIconColor={currentTheme?.theme?.white}
            backgroundColor={currentTheme?.theme?.primary}
            btnContentStyle={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
            style={[
              {
                height: Constants.appButtonHeight,
                width: "auto",
                shadowColor: currentTheme?.theme?.transparent,
                marginHorizontal: 10,
              },
            ]}
            btnLabelStyle={{ fontSize: 16 }}
            wrapperStyle={[
              {
                width: "fit-content",
                minWidth: 150,
                marginTop: 20,
                height: Constants.appButtonHeight,
              },
            ]}
          />
          {isEdit && _site_?.status == "active" && (
            <ToothFairyButton
              title="Sync"
              isDisabled={isValid && dirty}
              wrapperStyle={{
                marginTop: 30,
                alignSelf: "center",
              }}
              onPress={() => {
                requestValidation(_site_?.id, "active");
              }}
            />
          )}
          {isEdit && (
            <ToothFairyButton
              title={"Delete"}
              onPress={() => handleDeletion(getSiteObject()?.id)}
              isDeletion
              wrapperStyle={{
                marginTop: 30,
                minWidth: 150,
                height: Constants.appButtonHeight,
              }}
            />
          )}
        </View>
      </>
    );
};

export default SiteSettingsView;
