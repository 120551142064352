import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import { showSideMenu } from "../../../../../App";
import AgentFunctions from "../../../../API/AgentFunctions";
import authorisationsHelper from "../../../../API/Authorisations/authorisationsHelper";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import { isLargeScreen } from "../../../MainViewContainer";
import AuthorisationsSettingsAI from "./AuthorisationsSettingsAI";
import ConnectionsSettingsToolbar from "./AuthorisationsSettingsToolbar";

const AuthorisationsList = ({ isFunctionsLoading, setDisplayedView }) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const { width } = useWindowDimensions();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [showAuthorisationModal, setShowAuthorisationModal] =
    React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedAuthorisations, setDisplayedAuthorisations] = React.useState(
    []
  );
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { height } = useWindowDimensions();
  const [initialMapValues, setInitialMapValues] = useState({
    name: "",
    description: "",
    scope: "",
    grantType: "",
    clientId: "",
    clientSecret: "",
    authorizationBaseUrl: "",
    tokenBaseUrl: "",
    type: "apikey",
  });
  const columns = [
    {
      title: "ID",
      width: 0.2,
    },
    {
      title: "Name",
      width: 0.25,
    },
    {
      title: "Description",
      width: 0.25,
    },
    {
      title: "Type",
      width: 0.25,
    },
    {
      title: "Actions",
      isAction: true,
      width: 0.1,
    },
  ];
  const onAuthCreated = useCallback(
    (event) => {
      if (event === "success") {
        AppToasty.show(t("authorisationCreationSuccessCaption"), {
          type: "success",
        });
        loadAuthorisations(state?.activeWorkspaceID);
        setShowAuthorisationModal(false);
      } else
        AppToasty.show(t("authorisationCreationFailedCaption"), {
          type: "danger",
        });
      formRef?.current?.resetForm();
      setShowAuthorisationModal(false);
      setSelectedIndex(null);
    },
    [
      AppToasty,
      t,
      setShowAuthorisationModal.formRef?.current,
      loadAuthorisations,
      state?.activeWorkspaceID,
    ]
  );
  const loadAuthorisations = async (workspaceID) => {
    const authorisationsData = await authorisationsHelper.getAuthorisations(
      workspaceID,
      null
    );

    let authorisations =
      authorisationsData?.data?.authorisationsByWorkspace?.items;
    authorisations =
      authorisations
        ?.map((u) => {
          if (u?._deleted !== true)
            return {
              ...u,
              staticArgs: JSON.parse(u?.staticArgs),
            };
        })
        ?.filter((u) => u != null) || [];
    dispatch("SET_AUTHORISATIONS", authorisations || []);

    setDisplayedAuthorisations(authorisations);
  };
  useEffect(() => {
    loadAuthorisations(state?.activeWorkspaceID);
  }, [state?.activeWorkspaceID]);
  useEffect(() => {
    setDisplayedAuthorisations([...state?.authorisations]);
  }, [JSON.stringify([...state?.authorisations])]);

  const authorisationItemCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        key={Date.now().toString()}
        modalStyle={{
          maxHeight: "95%",
          height: "auto",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showAuthorisationModal}
        setIsModalOpen={setShowAuthorisationModal}
        modalTitle={
          isEditMode ? "authorisationEditTitle" : "authorisationCreationTitle"
        }
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowAuthorisationModal(false);
          setSelectedIndex(null);
        }}
      >
        <AuthorisationsSettingsAI
          ref={formRef}
          initialMapValues={initialMapValues}
          onAuthCreated={onAuthCreated}
          isEdit={isEditMode}
          selectedId={displayedAuthorisations[selectedIndex]?.id}
          opt={displayedAuthorisations[selectedIndex]}
          setDisplayedView={setDisplayedView}
        />
      </ToothFairyModal>
    );
  }, [
    showAuthorisationModal,
    setShowAuthorisationModal,
    initialMapValues,
    isEditMode,
    selectedIndex,
    displayedAuthorisations,
    isLargeScreen(),
  ]);
  const identifyOptByID = (id) => {
    return state.authorisations.find((u) => u.id === id);
  };
  const handleAuthorisationItemSelection = ({ item, index }) => {
    setSelectedIndex(index);
    setIsEditMode(true);
    let extendedItem = identifyOptByID(item.id);
    setInitialMapValues({
      ...extendedItem,
    });

    setShowAuthorisationModal(true);
  };
  const handleAuthorisationItemDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        const functionsData =
          await AgentFunctions.getAgentFunctionsByWorkspaceID({
            workspaceID: state?.activeWorkspaceID,
          });
        let functions = functionsData?.data?.functionsByWorkspace?.items;
        functions =
          functions
            ?.map((u) => {
              if (
                u?.authorisationID ===
                displayedAuthorisations[selectedIndex]?.id
              )
                return {
                  ...u,
                };
            })
            ?.filter((u) => u != null) || [];
        if (functions.length > 0) {
          AppToasty.show(t("authorisationDeletionErrorCaptionDueToConflict"), {
            type: "danger",
          });
          return;
        }
        const _id = displayedAuthorisations[selectedIndex]?.id;
        const result = await authorisationsHelper.deleteAuthorisation(_id);
        dispatch("SET_AUTHORISATIONS", [
          ...state?.authorisations.filter((u) => u.id !== _id),
        ]);
        AppToasty.show(t("authorisationDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      console.log(error);
      AppToasty.show(t("authorisationDeletionErrorCaption"), {
        type: "danger",
      });
    }
  };
  const handleSearch = async (name, description, host, type) => {
    // find items in the list which cointain either the sourceText or targetText
    const filteredItems = state?.authorisations.filter((item) => {
      return (
        item?.name?.toLowerCase()?.includes(name?.toLowerCase()) &&
        item?.description?.toLowerCase()?.includes(description?.toLowerCase())
      );
    });
    setDisplayedAuthorisations(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleCreation = () => {
    setInitialMapValues({
      name: "",
      description: "",
      scope: "",
      grantType: "",
      clientId: "",
      clientSecret: "",
      authorizationBaseUrl: "",
      tokenBaseUrl: "",
      type: "apikey",
    });
    setIsEditMode(false);
    setShowAuthorisationModal(true);
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"authorisationsItemCancelConfirmation"}
        caption="authorisationsItemCancelCaption"
        subCaption="authorisationsItemCancelSubCaption"
        confirmCaption={"authorisationsItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={() => handleAuthorisationItemDeletion()}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [setShowChoice, showChoice, selectedItem, setSelectedItem]);

  return (
    <View
      style={{
        paddingLeft: 10,
        flex: 1,
        width: showSideMenu() ? width - (showSideMenu() ? 410 : 220) : "100%",
        minWidth: "100%",
      }}
    >
      <>
        <ConnectionsSettingsToolbar
          handleSearch={handleSearch}
          handleCreation={handleCreation}
        />
        {isFunctionsLoading ? (
          <AppActivityIndicator
            backgroundColor={currentTheme?.theme?.softBackgroundColor}
            visible={true}
            containerStyle={{
              backgroundColor: currentTheme?.theme?.transparent,
              minHeight: 250,
            }}
          />
        ) : (
          <>
            {displayedAuthorisations.length > 0 ? (
              <AppTable
                actionButtons
                handleDocumentSelection={handleAuthorisationItemSelection}
                handleDocumentDeletion={(item, index) => {
                  setSelectedItem(item);
                  setSelectedIndex(index);
                  setShowChoice(true);
                }}
                style={{
                  zIndex: -1,
                  maxHeight: height - 180,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                headers={columns}
                items={displayedAuthorisations
                  ?.slice(
                    (currentPage - 1) * pageSize,
                    (currentPage - 1) * pageSize + pageSize
                  )
                  ?.map((u) => {
                    return {
                      id: u?.id,
                      name: u?.name || " ",
                      description: u?.description || " ",
                      type: u?.type?.toUpperCase() || " ",
                      actions: " ",
                    };
                  })}
              />
            ) : (
              <AppPlaceholderView
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  marginVertical: 60,
                  marginHorizontal: "auto",
                  flex: 1,
                  zIndex: -1,
                }}
                size={80}
                icon={AppAssets.icons.orbit}
                mainCaption="EmptyPlaceholder"
                subCaption="AuthorisationsSubCaption"
              />
            )}
            {displayedAuthorisations?.length > pageSize && (
              <AppPagination
                onPageChange={handlePageChange}
                totalCount={displayedAuthorisations?.length}
                siblingCount={1}
                currentPage={currentPage}
                pageSize={pageSize}
                wrapperStyle={{
                  marginTop: 10,
                  alignSelf: "flex-end",
                }}
              />
            )}
          </>
        )}
      </>

      {authorisationItemCreationComponent}
      {_coreChoice}
    </View>
  );
};

export default AuthorisationsList;
