import { AppText, AppIcon } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useRef, useState } from "react";
import { View } from "react-native";

const AudioPlaceholder = ({
  onFileSelection = () => {},
  audioPath,
  isFileUploadInProgress,
  fileUploadProgress,
  isVoiceRecordingInProgress,
  style,
  children,
}) => {
  const fileInputRef = useRef(null);
  const AppToasty = AppToast.useToast();
  const { currentTheme } = AppTheme.useTheme();
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const handlePlaceholderClick = () => {
    fileInputRef.current.click();
  };
  const [errorMessgae, setErrorMessage] = React.useState(null);
  const isValidFileSize = (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 1MB
    return file.size <= maxSizeInBytes;
  };
  const handleAudioUpload = (event) => {
    const file = event.target.files[0];
    if (!isValidFileSize(file)) {
      setErrorMessage(
        "File size exceeds 10MB. Please upload a smaller audio file."
      );
      return AppToasty.show(
        "File size exceeds 10MB. Please upload a smaller audio file.",
        {
          type: "danger",
        }
      );
    }
    onFileSelection(file);
  };

  return (
    <View
      style={[
        {
          cursor: "pointer",
          border: `1px solid ${
            currentTheme?.theme[
              audioPath || isFileUploadInProgress ? "orange" : "primary"
            ]
          }`,
          //   padding: !audioPath && 5,
          textAlign: "center",
          width: "width",
          alignSelf: "center",
          borderRadius: 20,
          width: "100%",
          justifyContent: "space-between",
          padding: 18,
          marginTop: 20,
        },
        style,
      ]}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleAudioUpload}
        // limit the input to only accept audio files of type mp3 and wav
        accept="audio/*"
      />
      {isFileUploadInProgress && fileUploadProgress !== 100 ? (
        <AppText
          style={{
            fontWeight: "bold",
          }}
          color={currentTheme?.theme?.orange}
        >{`Upload progress: ${fileUploadProgress}%`}</AppText>
      ) : (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          {isVoiceRecordingInProgress ? (
            <AppText
              style={{
                fontWeight: "bold",
              }}
              color={currentTheme?.theme?.orange}
            >{`Recording voice...`}</AppText>
          ) : (
            <AppText
              isClickable={true}
              onPress={() => fileInputRef.current.click()}
              style={{
                fontWeight: "bold",
                textDecorationColor: audioPath
                  ? currentTheme?.theme?.orange
                  : currentTheme?.theme?.primary,
              }}
              wrapperStyle={{
                flex: 1,
                display: "flex",
              }}
              color={
                audioPath
                  ? currentTheme?.theme?.orange
                  : currentTheme?.theme?.font_color
              }
            >
              {audioPath
                ? `Voice file: ${audioPath?.split("/")[2]}`
                : errorMessgae
                ? errorMessgae
                : "No voice file selected"}
            </AppText>
          )}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            {children}
            <AppIcon
              icon={AppAssets?.icons?.audio}
              isClickable={true}
              onPress={() => fileInputRef.current.click()}
              color={
                audioPath
                  ? currentTheme?.theme?.orange
                  : currentTheme?.theme?.font_color
              }
            />
            {audioPath && (
              <AppIcon
                isClickable={true}
                color={currentTheme?.theme?.red}
                icon={AppAssets?.icons?.close}
                onPress={() => {
                  setErrorMessage(null);
                  onFileSelection(null);
                }}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default AudioPlaceholder;
