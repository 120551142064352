import React from "react";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import btnStyleGenerator from "../../Constants/btnStyleGenerator";
import Constants from "../../Constants";

const ToothFairySubmitButton = ({
  style,
  wrapperStyle,
  isDeletion,
  isSecondary,
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const commonBtnStyle = btnStyleGenerator.commonBtnStyleGenerator(
    currentTheme
  );
  return (
    <AppForms.AppSubmitButton
      awesomeIconColor={currentTheme?.theme?.white}
      backgroundColor={
        isDeletion || isSecondary
          ? currentTheme?.theme?.secondary_button_background_colour
          : currentTheme?.theme?.primary
      }
      btnContentStyle={{
        paddingLeft: 0,
        paddingRight: props?.awesomeIcon ? 20 : 0,
      }}
      style={[
        {
          height: Constants.appButtonHeight,
          width: "auto",
          shadowColor: currentTheme?.theme?.transparent,
          marginHorizontal: 10,
        },
        style,
      ]}
      btnLabelStyle={{ fontSize: 16 }}
      wrapperStyle={[
        { width: "fit-content", minWidth: 150, alignSelf: "center" },
        wrapperStyle,
      ]}
      {...props}
    />
  );
};

export default ToothFairySubmitButton;
