import { A as AuthScreen } from '../../dist/AuthScreen-05bc278e.esm.js';
import { u as useAuth } from '../../dist/useAuth-994a3ce1.esm.js';
import Auth from '@aws-amplify/auth';
import 'react';
import 'react-native';
import '../../dist/AppFormCodeInput-c6e62233.esm.js';
import '../../dist/extends-a2cb9f0c.esm.js';
import '../../dist/AppFormField-e4ad0fb5.esm.js';
import 'formik';
import '../../dist/index-fbfbaf35.esm.js';
import '../../dist/index-1e7daec1.esm.js';
import 'react-i18next';
import '../../dist/useTheme-ee8bbfc3.esm.js';
import '../../dist/index-3be41b05.esm.js';
import '@fortawesome/react-native-fontawesome';
import 'react-native-popable';
import 'markdown-it';
import 'mermaid';
import '../../dist/index-d65ffcb9.esm.js';
import '@fortawesome/pro-light-svg-icons/faAdjust';
import '@fortawesome/pro-light-svg-icons/faAlignCenter';
import '@fortawesome/pro-light-svg-icons/faAlignLeft';
import '@fortawesome/pro-light-svg-icons/faAlignRight';
import '@fortawesome/pro-light-svg-icons/faArrowCircleRight';
import '@fortawesome/pro-light-svg-icons/faArrowSquareLeft';
import '@fortawesome/pro-light-svg-icons/faArrowToRight';
import '@fortawesome/pro-light-svg-icons/faBan';
import '@fortawesome/pro-light-svg-icons/faBars';
import '@fortawesome/pro-light-svg-icons/faBell';
import '@fortawesome/pro-light-svg-icons/faBezierCurve';
import '@fortawesome/pro-light-svg-icons/faBold';
import '@fortawesome/pro-light-svg-icons/faBookOpen';
import '@fortawesome/pro-light-svg-icons/faBookReader';
import '@fortawesome/pro-light-svg-icons/faBookmark';
import '@fortawesome/pro-light-svg-icons/faBooks';
import '@fortawesome/pro-light-svg-icons/faBracketsCurly';
import '@fortawesome/pro-light-svg-icons/faBrain';
import '@fortawesome/pro-light-svg-icons/faHeadSideBrain';
import '@fortawesome/pro-light-svg-icons/faBrowser';
import '@fortawesome/pro-light-svg-icons/faCalendarDay';
import '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import '@fortawesome/pro-light-svg-icons/faFileChartLine';
import '@fortawesome/pro-light-svg-icons/faChartNetwork';
import '@fortawesome/pro-light-svg-icons/faCheckCircle';
import '@fortawesome/pro-light-svg-icons/faChevronDoubleUp';
import '@fortawesome/pro-light-svg-icons/faChevronDown';
import '@fortawesome/pro-light-svg-icons/faChevronLeft';
import '@fortawesome/pro-light-svg-icons/faScannerTouchscreen';
import '@fortawesome/pro-light-svg-icons/faChevronRight';
import '@fortawesome/pro-light-svg-icons/faChevronUp';
import '@fortawesome/pro-light-svg-icons/faCircle';
import '@fortawesome/pro-light-svg-icons/faClipboard';
import '@fortawesome/pro-light-svg-icons/faClipboardListCheck';
import '@fortawesome/pro-light-svg-icons/faSparkles';
import '@fortawesome/pro-light-svg-icons/faClock';
import '@fortawesome/pro-light-svg-icons/faClone';
import '@fortawesome/pro-light-svg-icons/faCloudDownload';
import '@fortawesome/pro-light-svg-icons/faCloudUpload';
import '@fortawesome/pro-light-svg-icons/faCode';
import '@fortawesome/pro-light-svg-icons/faCodeMerge';
import '@fortawesome/pro-light-svg-icons/faCog';
import '@fortawesome/pro-light-svg-icons/faCommentAltLines';
import '@fortawesome/pro-light-svg-icons/faCommentLines';
import '@fortawesome/pro-light-svg-icons/faCommentsAlt';
import '@fortawesome/pro-light-svg-icons/faCompressAlt';
import '@fortawesome/pro-light-svg-icons/faCopy';
import '@fortawesome/pro-light-svg-icons/faCreditCard';
import '@fortawesome/pro-light-svg-icons/faDatabase';
import '@fortawesome/pro-light-svg-icons/faDollarSign';
import '@fortawesome/pro-light-svg-icons/faEdit';
import '@fortawesome/pro-light-svg-icons/faEllipsisH';
import '@fortawesome/pro-light-svg-icons/faEllipsisV';
import '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import '@fortawesome/pro-light-svg-icons/faEraser';
import '@fortawesome/pro-light-svg-icons/faExchangeAlt';
import '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import '@fortawesome/pro-light-svg-icons/faExpandAlt';
import '@fortawesome/pro-light-svg-icons/faExpandWide';
import '@fortawesome/pro-light-svg-icons/faEye';
import '@fortawesome/pro-light-svg-icons/faEyeSlash';
import '@fortawesome/pro-light-svg-icons/faFile';
import '@fortawesome/pro-light-svg-icons/faFileAlt';
import '@fortawesome/pro-light-svg-icons/faFileCode';
import '@fortawesome/pro-light-svg-icons/faFileCsv';
import '@fortawesome/pro-light-svg-icons/faFileExcel';
import '@fortawesome/pro-light-svg-icons/faFileExport';
import '@fortawesome/pro-light-svg-icons/faFileImport';
import '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
import '@fortawesome/pro-light-svg-icons/faFilePdf';
import '@fortawesome/pro-light-svg-icons/faFilePowerpoint';
import '@fortawesome/pro-light-svg-icons/faFileSignature';
import '@fortawesome/pro-light-svg-icons/faFileUpload';
import '@fortawesome/pro-light-svg-icons/faFileWord';
import '@fortawesome/pro-light-svg-icons/faFolder';
import '@fortawesome/pro-light-svg-icons/faFont';
import '@fortawesome/pro-light-svg-icons/faGameBoardAlt';
import '@fortawesome/pro-light-svg-icons/faGlobeEurope';
import '@fortawesome/pro-light-svg-icons/faGripLinesVertical';
import '@fortawesome/pro-light-svg-icons/faGripVertical';
import '@fortawesome/pro-light-svg-icons/faH1';
import '@fortawesome/pro-light-svg-icons/faH2';
import '@fortawesome/pro-light-svg-icons/faHashtag';
import '@fortawesome/pro-light-svg-icons/faHighlighter';
import '@fortawesome/pro-light-svg-icons/faHistory';
import '@fortawesome/pro-light-svg-icons/faHomeAlt';
import '@fortawesome/pro-light-svg-icons/faImage';
import '@fortawesome/pro-light-svg-icons/faInbox';
import '@fortawesome/pro-light-svg-icons/faIndent';
import '@fortawesome/pro-light-svg-icons/faInfoCircle';
import '@fortawesome/pro-light-svg-icons/faItalic';
import '@fortawesome/pro-light-svg-icons/faKeyboard';
import '@fortawesome/pro-light-svg-icons/faLanguage';
import '@fortawesome/pro-light-svg-icons/faLaptopCode';
import '@fortawesome/pro-light-svg-icons/faLayerGroup';
import '@fortawesome/pro-light-svg-icons/faLightbulbExclamation';
import '@fortawesome/pro-light-svg-icons/faLink';
import '@fortawesome/pro-light-svg-icons/faListOl';
import '@fortawesome/pro-light-svg-icons/faListUl';
import '@fortawesome/pro-light-svg-icons/faLock';
import '@fortawesome/pro-light-svg-icons/faLockOpen';
import '@fortawesome/pro-light-svg-icons/faMeteor';
import '@fortawesome/pro-light-svg-icons/faMicrophone';
import '@fortawesome/pro-light-svg-icons/faMoneyCheckAlt';
import '@fortawesome/pro-light-svg-icons/faNetworkWired';
import '@fortawesome/pro-light-svg-icons/faPaintRoller';
import '@fortawesome/pro-light-svg-icons/faPaperPlaneTop';
import '@fortawesome/pro-light-svg-icons/faPen';
import '@fortawesome/pro-light-svg-icons/faPlanetRinged';
import '@fortawesome/pro-light-svg-icons/faPlus';
import '@fortawesome/pro-light-svg-icons/faPlusCircle';
import '@fortawesome/pro-light-svg-icons/faPowerOff';
import '@fortawesome/pro-light-svg-icons/faQuestion';
import '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import '@fortawesome/pro-light-svg-icons/faQuoteLeft';
import '@fortawesome/pro-light-svg-icons/faRedo';
import '@fortawesome/pro-light-svg-icons/faReply';
import '@fortawesome/pro-light-svg-icons/faRocketLaunch';
import '@fortawesome/pro-light-svg-icons/faSatelliteDish';
import '@fortawesome/pro-light-svg-icons/faScrubber';
import '@fortawesome/pro-light-svg-icons/faSearch';
import '@fortawesome/pro-light-svg-icons/faSearchMinus';
import '@fortawesome/pro-light-svg-icons/faSearchPlus';
import '@fortawesome/pro-light-svg-icons/faShareAlt';
import '@fortawesome/pro-light-svg-icons/faSignOut';
import '@fortawesome/pro-light-svg-icons/faSlidersV';
import '@fortawesome/pro-light-svg-icons/faSlidersVSquare';
import '@fortawesome/pro-light-svg-icons/faSpinner';
import '@fortawesome/pro-light-svg-icons/faStopCircle';
import '@fortawesome/pro-light-svg-icons/faStream';
import '@fortawesome/pro-light-svg-icons/faStrikethrough';
import '@fortawesome/pro-light-svg-icons/faSync';
import '@fortawesome/pro-light-svg-icons/faTag';
import '@fortawesome/pro-light-svg-icons/faTerminal';
import '@fortawesome/pro-light-svg-icons/faThumbsDown';
import '@fortawesome/pro-light-svg-icons/faThumbsUp';
import '@fortawesome/pro-light-svg-icons/faThumbtack';
import '@fortawesome/pro-light-svg-icons/faTimes';
import '@fortawesome/pro-light-svg-icons/faTimesCircle';
import '@fortawesome/pro-light-svg-icons/faTrash';
import '@fortawesome/pro-light-svg-icons/faTypewriter';
import '@fortawesome/pro-light-svg-icons/faUnderline';
import '@fortawesome/pro-light-svg-icons/faUndo';
import '@fortawesome/pro-light-svg-icons/faUnlink';
import '@fortawesome/pro-light-svg-icons/faUser';
import '@fortawesome/pro-light-svg-icons/faUserCircle';
import '@fortawesome/pro-light-svg-icons/faUserLock';
import '@fortawesome/pro-light-svg-icons/faUserRobot';
import '@fortawesome/pro-light-svg-icons/faUserSecret';
import '@fortawesome/pro-light-svg-icons/faUserShield';
import '@fortawesome/pro-light-svg-icons/faUsers';
import '@fortawesome/pro-light-svg-icons/faUsersCog';
import '@fortawesome/pro-light-svg-icons/faVial';
import '@fortawesome/pro-light-svg-icons/faWaveSquare';
import '@fortawesome/pro-light-svg-icons/faWifiSlash';
import '@fortawesome/pro-light-svg-icons/faWindowMinimize';
import '@fortawesome/pro-light-svg-icons/faChartBar';
import '@fortawesome/pro-light-svg-icons/faSlidersH';
import '@fortawesome/pro-light-svg-icons/faPageBreak';
import '@fortawesome/pro-light-svg-icons/faServer';
import '@fortawesome/pro-light-svg-icons/faTools';
import '@fortawesome/pro-light-svg-icons/faAnalytics';
import '@fortawesome/pro-light-svg-icons/faTrophy';
import '@fortawesome/pro-light-svg-icons/faDice';
import '@fortawesome/pro-light-svg-icons/faSwords';
import '@fortawesome/pro-light-svg-icons/faBoxingGlove';
import '@fortawesome/pro-light-svg-icons/faRandom';
import '@fortawesome/pro-light-svg-icons/faVideoPlus';
import '@fortawesome/pro-light-svg-icons/faFileAudio';
import '@fortawesome/pro-light-svg-icons/faMagic';
import '@fortawesome/pro-light-svg-icons/faSitemap';
import '@fortawesome/pro-light-svg-icons/faFiles';
import '@fortawesome/pro-light-svg-icons/faUserShakespeare';
import '@fortawesome/pro-light-svg-icons/faUserPilot';
import '@fortawesome/pro-light-svg-icons/faUserPilotTie';
import '@fortawesome/pro-light-svg-icons/faUserRobotXmarks';
import '@fortawesome/pro-light-svg-icons/faUserVisor';
import '@fortawesome/pro-light-svg-icons/faUserNinja';
import '@fortawesome/pro-light-svg-icons/faUserGear';
import '@fortawesome/pro-light-svg-icons/faArrowTurnDownRight';
import '@fortawesome/pro-light-svg-icons/faTriangleExclamation';
import '@fortawesome/pro-light-svg-icons/faMessageBot';
import '@fortawesome/pro-light-svg-icons/faVolume';
import '@fortawesome/pro-light-svg-icons/faVolumeSlash';
import '@fortawesome/pro-light-svg-icons/faImages';
import '@fortawesome/pro-light-svg-icons/faSwordLaser';
import '@fortawesome/pro-light-svg-icons/faSwordsLaser';
import '@fortawesome/pro-light-svg-icons/faHelmetBattle';
import '@fortawesome/pro-light-svg-icons/faUpRightAndDownLeftFromCenter';
import '@fortawesome/pro-light-svg-icons/faDownLeftAndUpRightToCenter';
import 'url';
import 'highlight.js';
import 'react-native-markdown-display';
import 'prop-types';
import '../../dist/index-1f1ff596.esm.js';
import '../../dist/index-15c42e19.esm.js';
import '../../dist/index-10e395c6.esm.js';
import '../../dist/index-c075b3dd.esm.js';
import 'react-native-confirmation-code-field';
import '../../dist/AppSubmitButton-8551e265.esm.js';
import '../../dist/index-b141c75b.esm.js';
import '../../dist/AppButton-0c2b9501.esm.js';
import '../../dist/index-a9a6637c.esm.js';
import 'expo-linear-gradient';
import 'react-native-toast-notifications';
import 'yup';

var AppAuthScreen = {
  AppAuthScreen: AuthScreen,
  useAuth,
  Auth
};

export { AppAuthScreen as default };
