import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React from "react";
import { isLargeScreen } from "../../Screens/MainViewContainer";

const ToothFairyLanguageSelectionDropdown = ({
  defaultLanguageLabel = "Default Language",
  label = "Language",
  onValueChange = () => {},
  value,
  showTextInputLabel,
}) => {
  const { currentTheme } = AppTheme.useTheme();

  const { user } = AppUser.useUser();
  const { state } = AppStateManager.useAppStateManager();

  return state?.languagesConfig?.length > 0 ? (
    <AppDropDown
      showTextInputLabel={showTextInputLabel}
      label={defaultLanguageLabel}
      inputLabelStyle={{
        marginLeft: 0,
      }}
      dropDownStyle={{
        minWidth: 100,
        borderColor: currentTheme?.theme?.border_color,
        maxWidth: !isLargeScreen() && 250,
      }}
      isElevated={false}
      dropdownIconColor="transparent"
      items={[{ id: "", name: label }, ...state?.languagesConfig]}
      propertyLabel="name"
      propertyValue="id"
      selectedValue={value}
      onValueChange={(itemValue, itemIndex) => {
        onValueChange(itemValue, itemIndex);
      }}
    />
  ) : (
    <></>
  );
};

export default ToothFairyLanguageSelectionDropdown;
