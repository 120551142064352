// @ts-check
import { initSchema } from "@aws-amplify/datastore";
import { schema } from "./schema";

const UserType = {
  ROOT: "Root",
  SUPER_ADMIN: "SuperAdmin",
  CONTENT_ADMIN: "ContentAdmin",
  BILLING_ADMIN: "BillingAdmin",
  AI_ENGINEER: "AIEngineer",
  USER: "User",
};

const AuthorisationType = {
  BEARER: "bearer",
  APIKEY: "apikey",
  OAUTH: "oauth",
  NONE: "none",
};

const HostingType = {
  AZURE_OPENAI: "azure_openai",
  AWS: "aws",
  LOCAL: "local",
  GOOGLE: "google",
  ANTHROPIC: "anthropic",
  TOGETHER: "together",
  TF: "tf",
  REPLICATE: "replicate",
  AZURE: "azure",
  OPENAI: "openai",
  GROQ: "groq",
};

const FunctionRequestType = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  CUSTOM: "custom",
};

const RequestType = {
  TRANSLATOR: "translator",
  ANSWERER: "answerer",
  PARSER: "parser",
  TOKENIZER: "tokenizer",
  EMBEDDER: "embedder",
  DETECTOR: "detector",
  QUESTIONER: "questioner",
  GENERATOR: "generator",
  CHATTER: "chatter",
};

const RequestStatus = {
  SUCCESS: "success",
  FAILED: "failed",
  TIMEOUT: "timeout",
  FULFILLED: "fulfilled",
  PENDING: "pending",
  IN_PROGRESS: "inProgress",
  CANCELLED: "cancelled",
};

const AgentType = {
  CHATBOT: "Chatbot",
  BUSINESS_ANALYST: "BusinessAnalyst",
  CONTENT_CREATOR: "ContentCreator",
  DEFAULT: "Default",
};

const SiteStatus = {
  NO_DOMAIN: "noDomain",
  NO_ANSWER: "noAnswer",
  ACTIVE: "active",
  SYNCING: "syncing",
  INACTIVE: "inactive",
  INVALID_TOKEN: "invalidToken",
  FAILED: "failed",
  PENDING: "pending",
  IN_PROGRESS: "inProgress",
  VALIDATION_TOKEN_CREATION_ERROR: "validationTokenCreationError",
  READY_FOR_VALIDATION: "readyForValidation",
  TOKEN_REQUESTED: "tokenRequested",
};

const FunctionScope = {
  CUSTOMER_AUTHENTICATION: "customerAuthentication",
  CUSTOMER: "customer",
  CASE_AUTHENTICATION: "caseAuthentication",
  CASE: "case",
};

const ChatVisibility = {
  PUBLIC: "public",
  PRIVATE: "private",
  WIDGET: "widget",
};

const HumanFeedbackType = {
  MULTILANGUAGE: "multilanguage",
  DOCUMENTATION: "documentation",
  REASONING: "reasoning",
  IMAGES: "images",
  CHARTING: "charting",
  API: "api",
  CODE: "code",
  MATH: "math",
  MODERATION: "moderation",
};

const MultiLanguageHumanFeedbackSubType = {
  TRANSLATION: "translation",
  LANGUAGE_DETECTION: "languageDetection",
  LANGUAGE_DETECTION_AND_TRANSLATION: "languageDetectionAndTranslation",
};

const DocumentationHumanFeedbackSubType = {
  OUTDATED_DOCUMENTATION: "OutdatedDocumentation",
  MISSING_DOCUMENTATION: "MissingDocumentation",
  INCORRECT_DOCUMENTATION: "IncorrectDocumentation",
};

const ReasoningHumanFeedbackSubType = {
  INCORRECT_ANSWER: "IncorrectAnswer",
  OUT_OF_CONTEXT_ANSWER: "OutOfContextAnswer",
};

const ImagesHumanFeedbackSubType = {
  INCORRECT_IMAGE: "IncorrectImage",
  MISSING_IMAGE: "MissingImage",
};

const ChartingHumanFeedbackSubType = {
  INCORRECT_CHART: "IncorrectChart",
  MISSING_CHART: "MissingChart",
};

const ModerationHumanFeedbackSubType = {
  INCORRECT_MODERATION: "IncorrectModeration",
  MISSING_MODERATION: "MissingModeration",
};

const AgentFunctionChatAction = {
  SUGGESTION: "suggestion",
  NEXT_QUESTION: "nextQuestion",
  HUMAN_SUPPORT: "humanSupport",
  REDIRECTION: "redirection",
  SCRIPT: "script",
};

const CommunicationChannel = {
  EMAIL: "email",
  SMS: "sms",
  WHATSAPP: "whatsapp",
  LINKEDIN: "linkedin",
};

const CommunicationProvider = {
  SENDGRID: "sendgrid",
  AMAZON_SES: "amazon_ses",
  MAILGUN: "mailgun",
  POSTMARK: "postmark",
  MAILJET: "mailjet",
  TWILIO: "twilio",
  WHATSAPP: "whatsapp",
  SMS_MAGIC: "sms_magic",
  SMS_MAGIC_SF: "sms_magic_sf",
  LINKEDIN: "linkedin",
};

const InternetSearchMode = {
  SEARCH: "search",
  NEWS: "news",
  IMAGES: "images",
  VIDEOS: "videos",
  MAPS: "maps",
  PLACES: "places",
  SHOPPING: "shopping",
};

const ChunkingStrategy = {
  KEYWORDS: "keywords",
  SUMMARY: "summary",
};

const ExtractionStrategy = {
  SAFE: "safe",
  BRUTE: "brute",
};

const GenerationScopes = {
  GENERATION: "generation",
  TWEET: "tweet",
  POST: "post",
  TITLE: "title",
  EMAIL: "email",
  PARAPHRASE: "paraphrase",
  PARAPHRASE_LITERAL: "paraphraseLiteral",
  SUMMARY: "summary",
  SIMPLIFY: "simplify",
  CUSTOM: "custom",
};

const GenerationStyles = {
  DEFAULT: "default",
  SHORT: "short",
  LONG: "long",
  FUNNY: "funny",
  INFORMAL: "informal",
  FORMAL: "formal",
  INTERVIEW: "interview",
  ACADEMIC: "academic",
  BUSINESS: "business",
  CREATIVE: "creative",
  SCIENTIFIC: "scientific",
  POETRY: "poetry",
  NEWS: "news",
};

const ChatMode = {
  SPEED: "speed",
  ACCURACY: "accuracy",
  CUSTOM: "custom",
  RETRIEVER: "retriever",
  CODER: "coder",
  MATHEMATICIAN: "mathematician",
  CHATTER: "chatter",
  PLANNER: "planner",
};

const GenerationMode = {
  SPEED: "speed",
  ACCURACY: "accuracy",
  CUSTOM: "custom",
};

const HumanFeedback = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
  NEUTRAL: "neutral",
};

const MessageType = {
  SYSTEM: "system",
  HANDOFF: "handoff",
  NOTIFICATION: "notification",
};

const GenerationDomains = {
  EDITORIAL: "editorial",
  CUSTOMER_SERVICE: "customerService",
  SALES: "sales",
  MARKETING: "marketing",
  HR: "hr",
  FINANCE: "finance",
  LEGAL: "legal",
  MEDICAL: "medical",
  SCIENCE: "science",
  TECHNOLOGY: "technology",
  DEFAULT: "default",
};

const Visibility = {
  PRIVATE: "private",
  PUBLIC: "public",
  SHARED: "shared",
};

const BaseModelType = {
  PRODUCTIVITY: "productivity",
  SENTIMENT: "sentiment",
  NONE: "none",
};

const EntityAiType = {
  NER: "ner",
  INTENT: "intent",
  TOPIC: "topic",
};

const FolderStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

const WorkspaceAiType = {
  HIERARCHICAL: "hierarchical",
  FLAT: "flat",
};

const BatchLogType = {
  NLP: "nlp",
  RC: "rc",
  GN: "gn",
};

const SubscriptionStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

const TrainingStatus = {
  DISPATCHED: "dispatched",
  IN_PROGRESS: "inProgress",
  COMPLETED: "completed",
  IN_ERROR: "inError",
  CANCELLED: "cancelled",
  CANCELLATION_REQUESTED: "cancellationRequested",
  TRAINING: "training",
  SWAPPING: "swapping",
  SWAP_MODEL_FAILED: "swapModelFailed",
  SWAP_MODEL_REQUESTED: "swapModelRequested",
  DATASET_READY: "datasetReady",
  MODEL_READY: "modelReady",
  FINE_TUNING_REQUESTED: "fineTuningRequested",
};

const BatchJobStatus = {
  DISPATCHED: "dispatched",
  IN_PROGRESS: "inProgress",
  COMPLETED: "completed",
  IN_ERROR: "inError",
  CANCELLED: "cancelled",
  CANCELLATION_REQUESTED: "cancellationRequested",
};

const QaUrlStatus = {
  READY: "ready",
  NOT_READY: "notReady",
  DISMISSED: "dismissed",
  UNKNOWN: "unknown",
  SWAPPING: "swapping",
};

const PlanType = {
  BASE: "base",
  PRO: "pro",
  ENTERPRISE: "enterprise",
};

const LogType = {
  IMPORT: "import",
  EXPORT: "export",
  ENTITY_DELETION: "entityDeletion",
  DOCUMENT_IMPORT: "documentImport",
};

const LogStatus = {
  DISPATCHED: "dispatched",
  IN_PROGRESS: "inProgress",
  COMPLETED: "completed",
  IN_ERROR: "inError",
};

const TrainingLogType = {
  NLP_TRAINING: "nlpTraining",
  READ_COMPREHENSION_TRAINING: "readComprehensionTraining",
  GENERATIVE_TRAINING: "generativeTraining",
  CONVERSATIONAL_TRAINING: "conversationalTraining",
};

const QaUrlType = {
  SERVERLESS: "serverless",
  PROVISIONED: "provisioned",
  ELASTIC: "elastic",
};

const {
  AppConfig,
  User,
  Workspace,
  UserWorkspaceType,
  Agent,
  AgentFunction,
  Authorisation,
  Hosting,
  DatabaseConnection,
  Site,
  EntityAI,
  TrainingLog,
  BatchJobLog,
  AppLog,
  RequestLog,
  OutputStream,
  Folders,
  DictionaryItem,
  Chat,
  CustomPrompt,
  CustomCodeExecutionEnvironment,
  ChatMessage,
  CommunicationService,
  SubscriptionPricingPlan,
  SubscriptionBaseConfig,
  GenerationConfig,
  ArenaBattle,
  ChatStatus,
  ChannelSettings,
  ChannelConfig,
  CustomGenerationConfig,
  QaRole,
  CustomQaConfig,
  AiWorkspaceConsumption,
  ApiMethodConsumption,
  Prompt,
  ConfigMapByPlan,
  UserWorkspace,
  TrainingConfig,
  ParsingConfig,
  NotificationConfig,
  EmbeddingConfig,
  ModelDocumentFilterInput,
  QA,
  Document,
} = initSchema(schema);

export {
  AppConfig,
  User,
  Workspace,
  UserWorkspaceType,
  Agent,
  AgentFunction,
  Authorisation,
  Hosting,
  DatabaseConnection,
  Site,
  EntityAI,
  TrainingLog,
  BatchJobLog,
  AppLog,
  RequestLog,
  OutputStream,
  Folders,
  DictionaryItem,
  Chat,
  CustomPrompt,
  CustomCodeExecutionEnvironment,
  ChatMessage,
  CommunicationService,
  SubscriptionPricingPlan,
  SubscriptionBaseConfig,
  GenerationConfig,
  ArenaBattle,
  UserType,
  AuthorisationType,
  HostingType,
  FunctionRequestType,
  RequestType,
  RequestStatus,
  AgentType,
  SiteStatus,
  FunctionScope,
  ChatVisibility,
  HumanFeedbackType,
  MultiLanguageHumanFeedbackSubType,
  DocumentationHumanFeedbackSubType,
  ReasoningHumanFeedbackSubType,
  ImagesHumanFeedbackSubType,
  ChartingHumanFeedbackSubType,
  ModerationHumanFeedbackSubType,
  AgentFunctionChatAction,
  CommunicationChannel,
  CommunicationProvider,
  InternetSearchMode,
  ChunkingStrategy,
  ExtractionStrategy,
  GenerationScopes,
  GenerationStyles,
  ChatMode,
  GenerationMode,
  HumanFeedback,
  MessageType,
  GenerationDomains,
  Visibility,
  BaseModelType,
  EntityAiType,
  FolderStatus,
  WorkspaceAiType,
  BatchLogType,
  SubscriptionStatus,
  TrainingStatus,
  BatchJobStatus,
  QaUrlStatus,
  PlanType,
  LogType,
  LogStatus,
  TrainingLogType,
  QaUrlType,
  ChatStatus,
  ChannelSettings,
  ChannelConfig,
  CustomGenerationConfig,
  QaRole,
  CustomQaConfig,
  AiWorkspaceConsumption,
  ApiMethodConsumption,
  Prompt,
  ConfigMapByPlan,
  UserWorkspace,
  TrainingConfig,
  ParsingConfig,
  NotificationConfig,
  EmbeddingConfig,
  ModelDocumentFilterInput,
  QA,
  Document,
};
