/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsersWorkspace = /* GraphQL */ `
  query GetUsersWorkspace($id: ID) {
    getUsersWorkspace(id: $id) {
      id
      username
      user_email
    }
  }
`;
export const getPlanWorkspace = /* GraphQL */ `
  query GetPlanWorkspace($id: ID) {
    getPlanWorkspace(id: $id) {
      id
      type
      subscriptionStatus
      isPredictionOnDemandGlobal
      isTrainingOnDemandGlobal
      predictionRequestsGlobal
      batchRequestsGlobal
      trainingMinutesGlobal
      baseModelGN
      baseModelQA
      baseModelNLP
      maxUsers
      agcEnabled
      isDownloadModelEnabled
      isDownloadDataEnabled
      isAPIAccessEnabled
      isBatchJobsEnabled
      isSentimentReviewerEnabled
      isBusinessAnalystEnabled
      isContentCreatorEnabled
      isPriorityTrainingEnabled
      isPredictionGPUAccelerationEnabledGlobal
      isPredictionGPUAccelerationEnabledRC
      isPredictionGPUAccelerationEnabledNLP
      aiMaxConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      maxMrcDocs
      accuracyModelEnabled
      isTrainingNLPEnabled
      isTrainingQAEnabled
      isTrainingGNEnabled
      isLongTermMemoryEnabled
      isFunctionsEnabled
      isDBFunctionsEnabled
      isHtmlFunctionsEnabled
      isSitesBindingEnabled
      isAgentsSecurityEnabled
      isWidgetChatsHistoryEnabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getUserIDByEmail = /* GraphQL */ `
  query GetUserIDByEmail($email: String!) {
    getUserIDByEmail(email: $email) {
      id
      username
      user_email
    }
  }
`;
export const getDocumentsWorkspace = /* GraphQL */ `
  query GetDocumentsWorkspace($id: ID!, $limit: Int, $nextToken: String) {
    getDocumentsWorkspace(id: $id, limit: $limit, nextToken: $nextToken) {
      id
      embedding_status
      completion_percentage
    }
  }
`;
export const getTokenByAgent = /* GraphQL */ `
  query GetTokenByAgent($id: ID!) {
    getTokenByAgent(id: $id)
  }
`;
export const getSSOproviderByDomain = /* GraphQL */ `
  query GetSSOproviderByDomain($domain: String!) {
    getSSOproviderByDomain(domain: $domain)
  }
`;
export const getAppConfig = /* GraphQL */ `
  query GetAppConfig($id: ID!) {
    getAppConfig(id: $id) {
      id
      saveTimer
      screenResponsiveWidth
      mobileResponsiveWidth
      veryLargeScreenResponsiveWidth
      chartMaxHeight
      inactiveTimeout
      sideMenuWidth
      availableLanguages
      baseIntents
      baseEntities
      baseTopics
      supportDomain
      metadata
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAppConfigs = /* GraphQL */ `
  query ListAppConfigs(
    $filter: ModelAppConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        saveTimer
        screenResponsiveWidth
        mobileResponsiveWidth
        veryLargeScreenResponsiveWidth
        chartMaxHeight
        inactiveTimeout
        sideMenuWidth
        availableLanguages
        baseIntents
        baseEntities
        baseTopics
        supportDomain
        metadata
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppConfigs = /* GraphQL */ `
  query SyncAppConfigs(
    $filter: ModelAppConfigFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        saveTimer
        screenResponsiveWidth
        mobileResponsiveWidth
        veryLargeScreenResponsiveWidth
        chartMaxHeight
        inactiveTimeout
        sideMenuWidth
        availableLanguages
        baseIntents
        baseEntities
        baseTopics
        supportDomain
        metadata
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      username
      user_email
      user_email_verified
      user_phone_number
      timezone
      language
      isSentenceSplitEnabled
      isImplicitSelfEnabled
      replyLanguage
      theme
      user_phone_number_verified
      userInstructions
      userMemories
      isOnboarded
      userHelperPreferences
      type
      workspaces {
        items {
          id
          owner
          title
          visibility
          userID
          isMed
          type
          usagePlanID
          workspaceTokenID
          workspaceToken
          subscriptionType
          subscriptionId
          subscriptionStatus
          enableExperimentalFeatures
          utilityEndpoint
          utilityUrl
          utilityUrlStatus
          utilityUrlType
          utilityBaseModel
          utilityProvider
          codingEndpoint
          codingUrl
          codingUrlStatus
          codingUrlType
          codingBaseModel
          codingProvider
          generalPurposeEndpoint
          generalPurposeUrl
          generalPurposeUrlStatus
          generalPurposeUrlType
          generalPurposeBaseModel
          generalPurposeProvider
          qaEnpoint
          qaUrl
          qaUrlStatus
          qaUrlType
          qaBaseModel
          qaProvider
          cnEndpoint
          cnUrl
          cnUrlStatus
          cnUrlType
          cnBaseModel
          cnProvider
          gnEnpoint
          gnUrl
          gnUrlStatus
          gnUrlType
          gnBaseModel
          gnProvider
          nlpEnpoint
          nlpUrl
          nlpUrlStatus
          nlpUrlType
          nlpBaseModel
          nlpProvider
          embeddingEndpoint
          embeddingUrl
          embeddingUrlStatus
          embeddingUrlType
          embeddingBaseModel
          embeddingProvider
          chartingEndpoint
          chartingUrl
          chartingUrlStatus
          chartingUrlType
          chartingBaseModel
          chartingProvider
          imagingEndpoint
          imagingUrl
          imagingUrlStatus
          imagingUrlType
          imagingBaseModel
          imagingProvider
          reasoningEndpoint
          reasoningUrl
          reasoningUrlStatus
          reasoningUrlType
          reasoningBaseModel
          reasoningProvider
          billingStatus
          lastBillingDate
          lastConsumptionNotificationDate
          activeUntil
          paymentFailed
          bulkImportAllowed
          lastPaymentAttempt
          targetSubscriptionType
          lastChangeSubscriptionDate
          maxUsers
          baseModel
          domain
          customImageRecognitionModel
          customVectorDB
          disabled
          users
          workspaceCreationDate
          workspaceModelReady
          enableDeleteFunctionInAgents
          enableUpdateFunctionInAgents
          enableCustomFunctionInAgents
          defaultChatAgent
          defaultBusinessAnalystAgent
          defaultContentCreatorAgent
          isSentimentReviewerEnabled
          isExtendedContentCreatorEnabled
          isExtendedBusinessAnalystReportEnabled
          customIndexedDBendpoint
          createdBy
          updatedBy
          federationMetadataUrl
          federationMetadataUrlStatus
          federationMetadataUrlType
          ssoType
          ssoDomain
          ssoProviderName
          isMultiLanguageEnabled
          customChartingConfig
          twilioConfig
          emailConfig
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      cognitoUsername
      stripeCustomerId
      stripePaymentIntentId
      maximumNumberOfWorkspaces
      workspacesAccessLevel {
        items {
          id
          workspaceID
          userID
          userType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        username
        user_email
        user_email_verified
        user_phone_number
        timezone
        language
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        replyLanguage
        theme
        user_phone_number_verified
        userInstructions
        userMemories
        isOnboarded
        userHelperPreferences
        type
        workspaces {
          nextToken
          startedAt
        }
        cognitoUsername
        stripeCustomerId
        stripePaymentIntentId
        maximumNumberOfWorkspaces
        workspacesAccessLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        username
        user_email
        user_email_verified
        user_phone_number
        timezone
        language
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        replyLanguage
        theme
        user_phone_number_verified
        userInstructions
        userMemories
        isOnboarded
        userHelperPreferences
        type
        workspaces {
          nextToken
          startedAt
        }
        cognitoUsername
        stripeCustomerId
        stripePaymentIntentId
        maximumNumberOfWorkspaces
        workspacesAccessLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const usersByOwner = /* GraphQL */ `
  query UsersByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        username
        user_email
        user_email_verified
        user_phone_number
        timezone
        language
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        replyLanguage
        theme
        user_phone_number_verified
        userInstructions
        userMemories
        isOnboarded
        userHelperPreferences
        type
        workspaces {
          nextToken
          startedAt
        }
        cognitoUsername
        stripeCustomerId
        stripePaymentIntentId
        maximumNumberOfWorkspaces
        workspacesAccessLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $user_email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      user_email: $user_email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        username
        user_email
        user_email_verified
        user_phone_number
        timezone
        language
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        replyLanguage
        theme
        user_phone_number_verified
        userInstructions
        userMemories
        isOnboarded
        userHelperPreferences
        type
        workspaces {
          nextToken
          startedAt
        }
        cognitoUsername
        stripeCustomerId
        stripePaymentIntentId
        maximumNumberOfWorkspaces
        workspacesAccessLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const usersByStripeCustomerID = /* GraphQL */ `
  query UsersByStripeCustomerID(
    $stripeCustomerId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByStripeCustomerID(
      stripeCustomerId: $stripeCustomerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        username
        user_email
        user_email_verified
        user_phone_number
        timezone
        language
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        replyLanguage
        theme
        user_phone_number_verified
        userInstructions
        userMemories
        isOnboarded
        userHelperPreferences
        type
        workspaces {
          nextToken
          startedAt
        }
        cognitoUsername
        stripeCustomerId
        stripePaymentIntentId
        maximumNumberOfWorkspaces
        workspacesAccessLevel {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkspace = /* GraphQL */ `
  query GetWorkspace($id: ID!) {
    getWorkspace(id: $id) {
      id
      owner
      title
      visibility
      userID
      isMed
      type
      usagePlanID
      workspaceTokenID
      workspaceToken
      subscriptionType
      subscriptionId
      subscriptionStatus
      enableExperimentalFeatures
      utilityEndpoint
      utilityUrl
      utilityUrlStatus
      utilityUrlType
      utilityBaseModel
      utilityProvider
      codingEndpoint
      codingUrl
      codingUrlStatus
      codingUrlType
      codingBaseModel
      codingProvider
      generalPurposeEndpoint
      generalPurposeUrl
      generalPurposeUrlStatus
      generalPurposeUrlType
      generalPurposeBaseModel
      generalPurposeProvider
      qaEnpoint
      qaUrl
      qaUrlStatus
      qaUrlType
      qaBaseModel
      qaProvider
      cnEndpoint
      cnUrl
      cnUrlStatus
      cnUrlType
      cnBaseModel
      cnProvider
      gnEnpoint
      gnUrl
      gnUrlStatus
      gnUrlType
      gnBaseModel
      gnProvider
      nlpEnpoint
      nlpUrl
      nlpUrlStatus
      nlpUrlType
      nlpBaseModel
      nlpProvider
      embeddingEndpoint
      embeddingUrl
      embeddingUrlStatus
      embeddingUrlType
      embeddingBaseModel
      embeddingProvider
      chartingEndpoint
      chartingUrl
      chartingUrlStatus
      chartingUrlType
      chartingBaseModel
      chartingProvider
      imagingEndpoint
      imagingUrl
      imagingUrlStatus
      imagingUrlType
      imagingBaseModel
      imagingProvider
      reasoningEndpoint
      reasoningUrl
      reasoningUrlStatus
      reasoningUrlType
      reasoningBaseModel
      reasoningProvider
      billingStatus
      lastBillingDate
      lastConsumptionNotificationDate
      activeUntil
      paymentFailed
      bulkImportAllowed
      lastPaymentAttempt
      targetSubscriptionType
      lastChangeSubscriptionDate
      maxUsers
      baseModel
      domain
      trainingConfig {
        trainingIterations
        trainingIterationsNLP
        trainingIterationsRC
        trainingEpochsNLP
        trainingEpochsRC
        trainingEpochsGN
        maxStepsNLP
        patienceNLP
        learningRateNLP
        learningRateRC
        testSizeNLP
        testSizeRC
        batchSizeNLP
        batchSizeRC
        datasetTestSizeThresholdNLP
        datasetTestSizeThresholdRC
        learningRateGN
        testSizeGN
        batchSizeGN
        datasetTestSizeThresholdGN
        enableManagedSpotTraining
        maxRuntimeWaitTime
        maxRuntime
        includeReadComprehensionDataInTraining
        generateDockerImageForRCengine
        generateDockerImageForNLPengine
        generateDockerImageForGNengine
        emailsListForTrainingConfirmation
        maxQueueWaitTime
      }
      parsingConfig {
        questionsLimit
        answerAsNER
        answerByParagraph
        uniqueAnswer
        isSentenceSplitEnabled
        isImplicitSelfEnabled
        temperature
        maxTokens
        topP
        topK
        qaDocTopK
        frequencyPenalty
        presencePenalty
        qaTopP
        qaTopK
        qaMaxTokens
        qaTemperature
        qaFrequencyPenalty
        qaPresencePenalty
        qaReplyOutsidePassage
        qaAiKnowledge
        qaInhibitionPassage
        qaEnhancementPassage
        qaRole
        qaDefaultAnswer
        qaStopSequence
        stopSequence
        stopPrompt
        qaExamples {
          question
          answer
        }
        qaBlender
        agcEnabled
      }
      notificationConfig {
        emailsList
        emailOnTrainingCompleted
        emailOnBatchCompleted
        emailOnBatchFailed
        emailOnBatchStart
        emailOnTrainingFailed
        emailOnTrainingStart
        notifyAllUsersInWorkspace
        notifyOnThreasholdConsumption
        notificationThresholdConsumption
      }
      embeddingConfig {
        maxChunkWords
        maxOverlapSentences
        chunkingStrategy
        imageExtractionStrategy
        minImageWidth
        minImageHeight
        aspectRatioMin
        aspectRatioMax
      }
      customGenerationConfig {
        styles
        domains
        scopes
        prompts {
          type
          label
          promptLength
          promptPlaceholder
          interpolationString
        }
        hasTopicsContext
        topics
        docTopK
        topK
        blender
        mode
        aiKnowledge
        compressor
      }
      customImageRecognitionModel
      customVectorDB
      workspaceAgents {
        items {
          id
          type
          label
          description
          interpolationString
          emoji
          color
          darkColor
          icon
          largeLogo
          icoUrl
          logoBackground
          darkLogoBackground
          messageOnLaunch
          messagesOnLaunch
          rotateMessagesOnLaunch
          rotateMessagesOnLaunchInterval
          placeholderMessageOnLoading
          customGreetingInstructions
          customConclusionInstructions
          customChartingInstructions
          customSummarisationInstructions
          customImagesInstructions
          customToolingInstructions
          placeholderInputMessage
          openByDefault
          disclaimer
          showDocumentsReferences
          isDefault
          hasMemory
          hasImages
          promptTopKeywords
          keyWordsForKnowledgeBase
          hasTopicsContext
          hasFunctions
          advancedLanguageDetection
          allowedTopics
          defaultAnswer
          noKnowledgeDefaultAnswer
          quickQuestions
          recencyImportance
          maxRecencyIncrease
          recencyTopKIncreaseFactor
          stopSequence
          enhancementPassage
          inhibitionPassage
          pertinencePassage
          goals
          qaUrl
          topK
          mode
          charting
          summarisation
          compressor
          docTopK
          minRetrievalScore
          timeSignificance
          hasModeration
          customModerations
          maxHistory
          maxTokens
          topicEnhancer
          maxTopics
          blender
          temperature
          version
          subject
          workspaceID
          createdBy
          updatedBy
          agentFunctions
          isGlobal
          plainTextOutput
          chainOfThoughts
          treeOfThoughts
          extendedOutput
          agentType
          agentsPool
          maxPlanningSteps
          planningInstructions
          plannerExecutionAttempts
          plannerRequiresApprovalForPlanExecution
          reviewInstructions
          showReferencesToInternalDocs
          preventWidgetUsage
          allowFeedback
          generateExtendedReport
          showTimeForResponse
          showDetectedLanguage
          isMultiLanguageEnabled
          hasMath
          hasCode
          hasNER
          restrictedAccess
          restrictedAccessUsers
          splashTitle
          showSplashWhenEmpty
          llmEndpoint
          llmUrl
          llmUrlStatus
          llmUrlType
          llmBaseModel
          llmProvider
          functionCallingProvider
          functionCallingModel
          showAgentName
          allowImagesUpload
          allowVideosUpload
          allowAudiosUpload
          allowDocsUpload
          allowImagesGeneration
          baseImageGenerationModel
          maxImagesGenerated
          advancedImageGeneration
          allowVideosGeneration
          allowAudiosGeneration
          voiceFileKey
          allowInternetSearch
          bannedDomains
          maxUrlsForInternetSearch
          allowDeepInternetSearch
          internetSearchModes
          internetSearchLocation
          autoVoiceMode
          keywordsWeightInRetrieval
          customCodeExecutionEnvironments
          phoneNumberAsSender
          whatsappNumberAsSender
          emailAsSender
          communicationServices
          channelCallbackDelay
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      workspaceCustomPrompts {
        items {
          id
          type
          scope
          style
          domain
          label
          promptLength
          promptPlaceholder
          interpolationString
          availableToAgents
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customQaConfig {
        roles {
          type
          label
          description
          interpolationString
          emoji
          color
          isDefault
          hasMemory
          hasTopicsContext
          allowedTopics
          defaultAnswer
          stopSequence
          enhancementPassage
          inhibitionPassage
          pertinencePassage
          goals
          qaUrl
          topK
          mode
          charting
          summarisation
          compressor
          docTopK
          maxHistory
          maxTokens
          topicEnhancer
          maxTopics
          blender
          temperature
          version
          subject
        }
      }
      aiConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      disabled
      subscriptionPricingPlan {
        items {
          id
          type
          subscriptionStatus
          isPredictionOnDemandGlobal
          isTrainingOnDemandGlobal
          predictionRequestsGlobal
          batchRequestsGlobal
          trainingMinutesGlobal
          baseModelGN
          baseModelQA
          baseModelNLP
          maxUsers
          agcEnabled
          isDownloadModelEnabled
          isDownloadDataEnabled
          isAPIAccessEnabled
          isBatchJobsEnabled
          isSentimentReviewerEnabled
          isBusinessAnalystEnabled
          isContentCreatorEnabled
          isPriorityTrainingEnabled
          isPredictionGPUAccelerationEnabledGlobal
          isPredictionGPUAccelerationEnabledRC
          isPredictionGPUAccelerationEnabledNLP
          maxMrcDocs
          accuracyModelEnabled
          isTrainingNLPEnabled
          isTrainingQAEnabled
          isTrainingGNEnabled
          isLongTermMemoryEnabled
          isFunctionsEnabled
          isDBFunctionsEnabled
          isHtmlFunctionsEnabled
          isSitesBindingEnabled
          isAgentsSecurityEnabled
          isWidgetChatsHistoryEnabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      folders {
        items {
          id
          owner
          name
          description
          emoji
          status
          parent
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      trainingLogs {
        items {
          id
          owner
          name
          description
          status
          trainingIterations
          lastActivation
          duration
          trainingMeta
          trainingConfig
          workspaceID
          type
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      entities {
        items {
          id
          owner
          label
          type
          description
          emoji
          parentEntity
          backgroundColor
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      appLogs {
        items {
          id
          owner
          type
          filename
          event
          status
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchJobs {
        items {
          id
          owner
          name
          description
          status
          duration
          batchMeta
          batchConfig
          batchOutput
          workspaceID
          type
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      dictionaryItems {
        items {
          id
          owner
          sourceLanguage
          targetLanguage
          sourceText
          targetText
          workspaceID
          pos
          description
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      requestsLogs {
        items {
          id
          srtKey
          type
          status
          words
          tokens
          sentences
          workspaceID
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      communicationServices {
        items {
          id
          workspaceID
          name
          description
          channel
          provider
          senderid
          fromNumber
          tokenSecret
          isActive
          config
          createdBy
          updatedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      sites {
        items {
          id
          name
          description
          url
          workspaceID
          topics
          status
          lastValidationDate
          lastValidationRequested
          validationToken
          allowedPaths
          siteTree
          lastScraped
          skipOwnerValidation
          useProxy
          completion_percentage
          extractImages
          freeRoaming
          scrapingCycle
          imgRetrievalPrompt
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users
      usersAccessLevel {
        items {
          id
          workspaceID
          userID
          userType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      workspaceCreationDate
      workspaceModelReady
      enableDeleteFunctionInAgents
      enableUpdateFunctionInAgents
      enableCustomFunctionInAgents
      defaultChatAgent
      defaultBusinessAnalystAgent
      defaultContentCreatorAgent
      isSentimentReviewerEnabled
      isExtendedContentCreatorEnabled
      isExtendedBusinessAnalystReportEnabled
      customIndexedDBendpoint
      createdBy
      updatedBy
      federationMetadataUrl
      federationMetadataUrlStatus
      federationMetadataUrlType
      ssoType
      ssoDomain
      ssoProviderName
      isMultiLanguageEnabled
      customChartingConfig
      twilioConfig
      emailConfig
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWorkspaces = /* GraphQL */ `
  query ListWorkspaces(
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        title
        visibility
        userID
        isMed
        type
        usagePlanID
        workspaceTokenID
        workspaceToken
        subscriptionType
        subscriptionId
        subscriptionStatus
        enableExperimentalFeatures
        utilityEndpoint
        utilityUrl
        utilityUrlStatus
        utilityUrlType
        utilityBaseModel
        utilityProvider
        codingEndpoint
        codingUrl
        codingUrlStatus
        codingUrlType
        codingBaseModel
        codingProvider
        generalPurposeEndpoint
        generalPurposeUrl
        generalPurposeUrlStatus
        generalPurposeUrlType
        generalPurposeBaseModel
        generalPurposeProvider
        qaEnpoint
        qaUrl
        qaUrlStatus
        qaUrlType
        qaBaseModel
        qaProvider
        cnEndpoint
        cnUrl
        cnUrlStatus
        cnUrlType
        cnBaseModel
        cnProvider
        gnEnpoint
        gnUrl
        gnUrlStatus
        gnUrlType
        gnBaseModel
        gnProvider
        nlpEnpoint
        nlpUrl
        nlpUrlStatus
        nlpUrlType
        nlpBaseModel
        nlpProvider
        embeddingEndpoint
        embeddingUrl
        embeddingUrlStatus
        embeddingUrlType
        embeddingBaseModel
        embeddingProvider
        chartingEndpoint
        chartingUrl
        chartingUrlStatus
        chartingUrlType
        chartingBaseModel
        chartingProvider
        imagingEndpoint
        imagingUrl
        imagingUrlStatus
        imagingUrlType
        imagingBaseModel
        imagingProvider
        reasoningEndpoint
        reasoningUrl
        reasoningUrlStatus
        reasoningUrlType
        reasoningBaseModel
        reasoningProvider
        billingStatus
        lastBillingDate
        lastConsumptionNotificationDate
        activeUntil
        paymentFailed
        bulkImportAllowed
        lastPaymentAttempt
        targetSubscriptionType
        lastChangeSubscriptionDate
        maxUsers
        baseModel
        domain
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        embeddingConfig {
          maxChunkWords
          maxOverlapSentences
          chunkingStrategy
          imageExtractionStrategy
          minImageWidth
          minImageHeight
          aspectRatioMin
          aspectRatioMax
        }
        customGenerationConfig {
          styles
          domains
          scopes
          hasTopicsContext
          topics
          docTopK
          topK
          blender
          mode
          aiKnowledge
          compressor
        }
        customImageRecognitionModel
        customVectorDB
        workspaceAgents {
          nextToken
          startedAt
        }
        workspaceCustomPrompts {
          nextToken
          startedAt
        }
        aiConsumption {
          lastUpdate
        }
        disabled
        subscriptionPricingPlan {
          nextToken
          startedAt
        }
        folders {
          nextToken
          startedAt
        }
        trainingLogs {
          nextToken
          startedAt
        }
        entities {
          nextToken
          startedAt
        }
        appLogs {
          nextToken
          startedAt
        }
        batchJobs {
          nextToken
          startedAt
        }
        dictionaryItems {
          nextToken
          startedAt
        }
        requestsLogs {
          nextToken
          startedAt
        }
        communicationServices {
          nextToken
          startedAt
        }
        sites {
          nextToken
          startedAt
        }
        users
        usersAccessLevel {
          nextToken
          startedAt
        }
        workspaceCreationDate
        workspaceModelReady
        enableDeleteFunctionInAgents
        enableUpdateFunctionInAgents
        enableCustomFunctionInAgents
        defaultChatAgent
        defaultBusinessAnalystAgent
        defaultContentCreatorAgent
        isSentimentReviewerEnabled
        isExtendedContentCreatorEnabled
        isExtendedBusinessAnalystReportEnabled
        customIndexedDBendpoint
        createdBy
        updatedBy
        federationMetadataUrl
        federationMetadataUrlStatus
        federationMetadataUrlType
        ssoType
        ssoDomain
        ssoProviderName
        isMultiLanguageEnabled
        customChartingConfig
        twilioConfig
        emailConfig
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkspaces = /* GraphQL */ `
  query SyncWorkspaces(
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkspaces(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        title
        visibility
        userID
        isMed
        type
        usagePlanID
        workspaceTokenID
        workspaceToken
        subscriptionType
        subscriptionId
        subscriptionStatus
        enableExperimentalFeatures
        utilityEndpoint
        utilityUrl
        utilityUrlStatus
        utilityUrlType
        utilityBaseModel
        utilityProvider
        codingEndpoint
        codingUrl
        codingUrlStatus
        codingUrlType
        codingBaseModel
        codingProvider
        generalPurposeEndpoint
        generalPurposeUrl
        generalPurposeUrlStatus
        generalPurposeUrlType
        generalPurposeBaseModel
        generalPurposeProvider
        qaEnpoint
        qaUrl
        qaUrlStatus
        qaUrlType
        qaBaseModel
        qaProvider
        cnEndpoint
        cnUrl
        cnUrlStatus
        cnUrlType
        cnBaseModel
        cnProvider
        gnEnpoint
        gnUrl
        gnUrlStatus
        gnUrlType
        gnBaseModel
        gnProvider
        nlpEnpoint
        nlpUrl
        nlpUrlStatus
        nlpUrlType
        nlpBaseModel
        nlpProvider
        embeddingEndpoint
        embeddingUrl
        embeddingUrlStatus
        embeddingUrlType
        embeddingBaseModel
        embeddingProvider
        chartingEndpoint
        chartingUrl
        chartingUrlStatus
        chartingUrlType
        chartingBaseModel
        chartingProvider
        imagingEndpoint
        imagingUrl
        imagingUrlStatus
        imagingUrlType
        imagingBaseModel
        imagingProvider
        reasoningEndpoint
        reasoningUrl
        reasoningUrlStatus
        reasoningUrlType
        reasoningBaseModel
        reasoningProvider
        billingStatus
        lastBillingDate
        lastConsumptionNotificationDate
        activeUntil
        paymentFailed
        bulkImportAllowed
        lastPaymentAttempt
        targetSubscriptionType
        lastChangeSubscriptionDate
        maxUsers
        baseModel
        domain
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        embeddingConfig {
          maxChunkWords
          maxOverlapSentences
          chunkingStrategy
          imageExtractionStrategy
          minImageWidth
          minImageHeight
          aspectRatioMin
          aspectRatioMax
        }
        customGenerationConfig {
          styles
          domains
          scopes
          hasTopicsContext
          topics
          docTopK
          topK
          blender
          mode
          aiKnowledge
          compressor
        }
        customImageRecognitionModel
        customVectorDB
        workspaceAgents {
          nextToken
          startedAt
        }
        workspaceCustomPrompts {
          nextToken
          startedAt
        }
        aiConsumption {
          lastUpdate
        }
        disabled
        subscriptionPricingPlan {
          nextToken
          startedAt
        }
        folders {
          nextToken
          startedAt
        }
        trainingLogs {
          nextToken
          startedAt
        }
        entities {
          nextToken
          startedAt
        }
        appLogs {
          nextToken
          startedAt
        }
        batchJobs {
          nextToken
          startedAt
        }
        dictionaryItems {
          nextToken
          startedAt
        }
        requestsLogs {
          nextToken
          startedAt
        }
        communicationServices {
          nextToken
          startedAt
        }
        sites {
          nextToken
          startedAt
        }
        users
        usersAccessLevel {
          nextToken
          startedAt
        }
        workspaceCreationDate
        workspaceModelReady
        enableDeleteFunctionInAgents
        enableUpdateFunctionInAgents
        enableCustomFunctionInAgents
        defaultChatAgent
        defaultBusinessAnalystAgent
        defaultContentCreatorAgent
        isSentimentReviewerEnabled
        isExtendedContentCreatorEnabled
        isExtendedBusinessAnalystReportEnabled
        customIndexedDBendpoint
        createdBy
        updatedBy
        federationMetadataUrl
        federationMetadataUrlStatus
        federationMetadataUrlType
        ssoType
        ssoDomain
        ssoProviderName
        isMultiLanguageEnabled
        customChartingConfig
        twilioConfig
        emailConfig
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userWorkspaces = /* GraphQL */ `
  query UserWorkspaces(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserWorkspaces(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        visibility
        userID
        isMed
        type
        usagePlanID
        workspaceTokenID
        workspaceToken
        subscriptionType
        subscriptionId
        subscriptionStatus
        enableExperimentalFeatures
        utilityEndpoint
        utilityUrl
        utilityUrlStatus
        utilityUrlType
        utilityBaseModel
        utilityProvider
        codingEndpoint
        codingUrl
        codingUrlStatus
        codingUrlType
        codingBaseModel
        codingProvider
        generalPurposeEndpoint
        generalPurposeUrl
        generalPurposeUrlStatus
        generalPurposeUrlType
        generalPurposeBaseModel
        generalPurposeProvider
        qaEnpoint
        qaUrl
        qaUrlStatus
        qaUrlType
        qaBaseModel
        qaProvider
        cnEndpoint
        cnUrl
        cnUrlStatus
        cnUrlType
        cnBaseModel
        cnProvider
        gnEnpoint
        gnUrl
        gnUrlStatus
        gnUrlType
        gnBaseModel
        gnProvider
        nlpEnpoint
        nlpUrl
        nlpUrlStatus
        nlpUrlType
        nlpBaseModel
        nlpProvider
        embeddingEndpoint
        embeddingUrl
        embeddingUrlStatus
        embeddingUrlType
        embeddingBaseModel
        embeddingProvider
        chartingEndpoint
        chartingUrl
        chartingUrlStatus
        chartingUrlType
        chartingBaseModel
        chartingProvider
        imagingEndpoint
        imagingUrl
        imagingUrlStatus
        imagingUrlType
        imagingBaseModel
        imagingProvider
        reasoningEndpoint
        reasoningUrl
        reasoningUrlStatus
        reasoningUrlType
        reasoningBaseModel
        reasoningProvider
        billingStatus
        lastBillingDate
        lastConsumptionNotificationDate
        activeUntil
        paymentFailed
        bulkImportAllowed
        lastPaymentAttempt
        targetSubscriptionType
        lastChangeSubscriptionDate
        maxUsers
        baseModel
        domain
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        embeddingConfig {
          maxChunkWords
          maxOverlapSentences
          chunkingStrategy
          imageExtractionStrategy
          minImageWidth
          minImageHeight
          aspectRatioMin
          aspectRatioMax
        }
        customGenerationConfig {
          styles
          domains
          scopes
          hasTopicsContext
          topics
          docTopK
          topK
          blender
          mode
          aiKnowledge
          compressor
        }
        customImageRecognitionModel
        customVectorDB
        workspaceAgents {
          nextToken
          startedAt
        }
        workspaceCustomPrompts {
          nextToken
          startedAt
        }
        aiConsumption {
          lastUpdate
        }
        disabled
        subscriptionPricingPlan {
          nextToken
          startedAt
        }
        folders {
          nextToken
          startedAt
        }
        trainingLogs {
          nextToken
          startedAt
        }
        entities {
          nextToken
          startedAt
        }
        appLogs {
          nextToken
          startedAt
        }
        batchJobs {
          nextToken
          startedAt
        }
        dictionaryItems {
          nextToken
          startedAt
        }
        requestsLogs {
          nextToken
          startedAt
        }
        communicationServices {
          nextToken
          startedAt
        }
        sites {
          nextToken
          startedAt
        }
        users
        usersAccessLevel {
          nextToken
          startedAt
        }
        workspaceCreationDate
        workspaceModelReady
        enableDeleteFunctionInAgents
        enableUpdateFunctionInAgents
        enableCustomFunctionInAgents
        defaultChatAgent
        defaultBusinessAnalystAgent
        defaultContentCreatorAgent
        isSentimentReviewerEnabled
        isExtendedContentCreatorEnabled
        isExtendedBusinessAnalystReportEnabled
        customIndexedDBendpoint
        createdBy
        updatedBy
        federationMetadataUrl
        federationMetadataUrlStatus
        federationMetadataUrlType
        ssoType
        ssoDomain
        ssoProviderName
        isMultiLanguageEnabled
        customChartingConfig
        twilioConfig
        emailConfig
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const workspacesByToken = /* GraphQL */ `
  query WorkspacesByToken(
    $workspaceToken: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesByToken(
      workspaceToken: $workspaceToken
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        visibility
        userID
        isMed
        type
        usagePlanID
        workspaceTokenID
        workspaceToken
        subscriptionType
        subscriptionId
        subscriptionStatus
        enableExperimentalFeatures
        utilityEndpoint
        utilityUrl
        utilityUrlStatus
        utilityUrlType
        utilityBaseModel
        utilityProvider
        codingEndpoint
        codingUrl
        codingUrlStatus
        codingUrlType
        codingBaseModel
        codingProvider
        generalPurposeEndpoint
        generalPurposeUrl
        generalPurposeUrlStatus
        generalPurposeUrlType
        generalPurposeBaseModel
        generalPurposeProvider
        qaEnpoint
        qaUrl
        qaUrlStatus
        qaUrlType
        qaBaseModel
        qaProvider
        cnEndpoint
        cnUrl
        cnUrlStatus
        cnUrlType
        cnBaseModel
        cnProvider
        gnEnpoint
        gnUrl
        gnUrlStatus
        gnUrlType
        gnBaseModel
        gnProvider
        nlpEnpoint
        nlpUrl
        nlpUrlStatus
        nlpUrlType
        nlpBaseModel
        nlpProvider
        embeddingEndpoint
        embeddingUrl
        embeddingUrlStatus
        embeddingUrlType
        embeddingBaseModel
        embeddingProvider
        chartingEndpoint
        chartingUrl
        chartingUrlStatus
        chartingUrlType
        chartingBaseModel
        chartingProvider
        imagingEndpoint
        imagingUrl
        imagingUrlStatus
        imagingUrlType
        imagingBaseModel
        imagingProvider
        reasoningEndpoint
        reasoningUrl
        reasoningUrlStatus
        reasoningUrlType
        reasoningBaseModel
        reasoningProvider
        billingStatus
        lastBillingDate
        lastConsumptionNotificationDate
        activeUntil
        paymentFailed
        bulkImportAllowed
        lastPaymentAttempt
        targetSubscriptionType
        lastChangeSubscriptionDate
        maxUsers
        baseModel
        domain
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        embeddingConfig {
          maxChunkWords
          maxOverlapSentences
          chunkingStrategy
          imageExtractionStrategy
          minImageWidth
          minImageHeight
          aspectRatioMin
          aspectRatioMax
        }
        customGenerationConfig {
          styles
          domains
          scopes
          hasTopicsContext
          topics
          docTopK
          topK
          blender
          mode
          aiKnowledge
          compressor
        }
        customImageRecognitionModel
        customVectorDB
        workspaceAgents {
          nextToken
          startedAt
        }
        workspaceCustomPrompts {
          nextToken
          startedAt
        }
        aiConsumption {
          lastUpdate
        }
        disabled
        subscriptionPricingPlan {
          nextToken
          startedAt
        }
        folders {
          nextToken
          startedAt
        }
        trainingLogs {
          nextToken
          startedAt
        }
        entities {
          nextToken
          startedAt
        }
        appLogs {
          nextToken
          startedAt
        }
        batchJobs {
          nextToken
          startedAt
        }
        dictionaryItems {
          nextToken
          startedAt
        }
        requestsLogs {
          nextToken
          startedAt
        }
        communicationServices {
          nextToken
          startedAt
        }
        sites {
          nextToken
          startedAt
        }
        users
        usersAccessLevel {
          nextToken
          startedAt
        }
        workspaceCreationDate
        workspaceModelReady
        enableDeleteFunctionInAgents
        enableUpdateFunctionInAgents
        enableCustomFunctionInAgents
        defaultChatAgent
        defaultBusinessAnalystAgent
        defaultContentCreatorAgent
        isSentimentReviewerEnabled
        isExtendedContentCreatorEnabled
        isExtendedBusinessAnalystReportEnabled
        customIndexedDBendpoint
        createdBy
        updatedBy
        federationMetadataUrl
        federationMetadataUrlStatus
        federationMetadataUrlType
        ssoType
        ssoDomain
        ssoProviderName
        isMultiLanguageEnabled
        customChartingConfig
        twilioConfig
        emailConfig
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const workspacesBySSOdomain = /* GraphQL */ `
  query WorkspacesBySSOdomain(
    $ssoDomain: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesBySSOdomain(
      ssoDomain: $ssoDomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        visibility
        userID
        isMed
        type
        usagePlanID
        workspaceTokenID
        workspaceToken
        subscriptionType
        subscriptionId
        subscriptionStatus
        enableExperimentalFeatures
        utilityEndpoint
        utilityUrl
        utilityUrlStatus
        utilityUrlType
        utilityBaseModel
        utilityProvider
        codingEndpoint
        codingUrl
        codingUrlStatus
        codingUrlType
        codingBaseModel
        codingProvider
        generalPurposeEndpoint
        generalPurposeUrl
        generalPurposeUrlStatus
        generalPurposeUrlType
        generalPurposeBaseModel
        generalPurposeProvider
        qaEnpoint
        qaUrl
        qaUrlStatus
        qaUrlType
        qaBaseModel
        qaProvider
        cnEndpoint
        cnUrl
        cnUrlStatus
        cnUrlType
        cnBaseModel
        cnProvider
        gnEnpoint
        gnUrl
        gnUrlStatus
        gnUrlType
        gnBaseModel
        gnProvider
        nlpEnpoint
        nlpUrl
        nlpUrlStatus
        nlpUrlType
        nlpBaseModel
        nlpProvider
        embeddingEndpoint
        embeddingUrl
        embeddingUrlStatus
        embeddingUrlType
        embeddingBaseModel
        embeddingProvider
        chartingEndpoint
        chartingUrl
        chartingUrlStatus
        chartingUrlType
        chartingBaseModel
        chartingProvider
        imagingEndpoint
        imagingUrl
        imagingUrlStatus
        imagingUrlType
        imagingBaseModel
        imagingProvider
        reasoningEndpoint
        reasoningUrl
        reasoningUrlStatus
        reasoningUrlType
        reasoningBaseModel
        reasoningProvider
        billingStatus
        lastBillingDate
        lastConsumptionNotificationDate
        activeUntil
        paymentFailed
        bulkImportAllowed
        lastPaymentAttempt
        targetSubscriptionType
        lastChangeSubscriptionDate
        maxUsers
        baseModel
        domain
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        embeddingConfig {
          maxChunkWords
          maxOverlapSentences
          chunkingStrategy
          imageExtractionStrategy
          minImageWidth
          minImageHeight
          aspectRatioMin
          aspectRatioMax
        }
        customGenerationConfig {
          styles
          domains
          scopes
          hasTopicsContext
          topics
          docTopK
          topK
          blender
          mode
          aiKnowledge
          compressor
        }
        customImageRecognitionModel
        customVectorDB
        workspaceAgents {
          nextToken
          startedAt
        }
        workspaceCustomPrompts {
          nextToken
          startedAt
        }
        aiConsumption {
          lastUpdate
        }
        disabled
        subscriptionPricingPlan {
          nextToken
          startedAt
        }
        folders {
          nextToken
          startedAt
        }
        trainingLogs {
          nextToken
          startedAt
        }
        entities {
          nextToken
          startedAt
        }
        appLogs {
          nextToken
          startedAt
        }
        batchJobs {
          nextToken
          startedAt
        }
        dictionaryItems {
          nextToken
          startedAt
        }
        requestsLogs {
          nextToken
          startedAt
        }
        communicationServices {
          nextToken
          startedAt
        }
        sites {
          nextToken
          startedAt
        }
        users
        usersAccessLevel {
          nextToken
          startedAt
        }
        workspaceCreationDate
        workspaceModelReady
        enableDeleteFunctionInAgents
        enableUpdateFunctionInAgents
        enableCustomFunctionInAgents
        defaultChatAgent
        defaultBusinessAnalystAgent
        defaultContentCreatorAgent
        isSentimentReviewerEnabled
        isExtendedContentCreatorEnabled
        isExtendedBusinessAnalystReportEnabled
        customIndexedDBendpoint
        createdBy
        updatedBy
        federationMetadataUrl
        federationMetadataUrlStatus
        federationMetadataUrlType
        ssoType
        ssoDomain
        ssoProviderName
        isMultiLanguageEnabled
        customChartingConfig
        twilioConfig
        emailConfig
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserWorkspaceType = /* GraphQL */ `
  query GetUserWorkspaceType($id: ID!) {
    getUserWorkspaceType(id: $id) {
      id
      workspaceID
      userID
      userType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserWorkspaceTypes = /* GraphQL */ `
  query ListUserWorkspaceTypes(
    $filter: ModelUserWorkspaceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserWorkspaceTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        userID
        userType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserWorkspaceTypes = /* GraphQL */ `
  query SyncUserWorkspaceTypes(
    $filter: ModelUserWorkspaceTypeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserWorkspaceTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        workspaceID
        userID
        userType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userToWorkspaceMapping = /* GraphQL */ `
  query UserToWorkspaceMapping(
    $workspaceID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserWorkspaceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userToWorkspaceMapping(
      workspaceID: $workspaceID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        userID
        userType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const workspacesByUser = /* GraphQL */ `
  query WorkspacesByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserWorkspaceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspacesByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        userID
        userType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      id
      type
      label
      description
      interpolationString
      emoji
      color
      darkColor
      icon
      largeLogo
      icoUrl
      logoBackground
      darkLogoBackground
      messageOnLaunch
      messagesOnLaunch
      rotateMessagesOnLaunch
      rotateMessagesOnLaunchInterval
      placeholderMessageOnLoading
      customGreetingInstructions
      customConclusionInstructions
      customChartingInstructions
      customSummarisationInstructions
      customImagesInstructions
      customToolingInstructions
      placeholderInputMessage
      openByDefault
      disclaimer
      showDocumentsReferences
      isDefault
      hasMemory
      hasImages
      promptTopKeywords
      keyWordsForKnowledgeBase
      hasTopicsContext
      hasFunctions
      advancedLanguageDetection
      allowedTopics
      defaultAnswer
      noKnowledgeDefaultAnswer
      quickQuestions
      recencyImportance
      maxRecencyIncrease
      recencyTopKIncreaseFactor
      stopSequence
      examples {
        question
        answer
      }
      enhancementPassage
      inhibitionPassage
      pertinencePassage
      goals
      qaUrl
      topK
      mode
      charting
      summarisation
      compressor
      docTopK
      minRetrievalScore
      timeSignificance
      hasModeration
      customModerations
      maxHistory
      maxTokens
      topicEnhancer
      maxTopics
      blender
      temperature
      version
      subject
      workspaceID
      createdBy
      updatedBy
      agentFunctions
      isGlobal
      plainTextOutput
      chainOfThoughts
      treeOfThoughts
      extendedOutput
      agentType
      agentsPool
      maxPlanningSteps
      planningInstructions
      plannerExecutionAttempts
      plannerRequiresApprovalForPlanExecution
      reviewInstructions
      showReferencesToInternalDocs
      preventWidgetUsage
      allowFeedback
      generateExtendedReport
      showTimeForResponse
      showDetectedLanguage
      isMultiLanguageEnabled
      hasMath
      hasCode
      hasNER
      restrictedAccess
      restrictedAccessUsers
      splashTitle
      showSplashWhenEmpty
      llmEndpoint
      llmUrl
      llmUrlStatus
      llmUrlType
      llmBaseModel
      llmProvider
      functionCallingProvider
      functionCallingModel
      showAgentName
      allowImagesUpload
      allowVideosUpload
      allowAudiosUpload
      allowDocsUpload
      allowImagesGeneration
      baseImageGenerationModel
      maxImagesGenerated
      advancedImageGeneration
      allowVideosGeneration
      allowAudiosGeneration
      voiceFileKey
      allowInternetSearch
      bannedDomains
      maxUrlsForInternetSearch
      allowDeepInternetSearch
      internetSearchModes
      internetSearchLocation
      autoVoiceMode
      keywordsWeightInRetrieval
      customCodeExecutionEnvironments
      phoneNumberAsSender
      whatsappNumberAsSender
      emailAsSender
      communicationServices
      channelCallbackDelay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        label
        description
        interpolationString
        emoji
        color
        darkColor
        icon
        largeLogo
        icoUrl
        logoBackground
        darkLogoBackground
        messageOnLaunch
        messagesOnLaunch
        rotateMessagesOnLaunch
        rotateMessagesOnLaunchInterval
        placeholderMessageOnLoading
        customGreetingInstructions
        customConclusionInstructions
        customChartingInstructions
        customSummarisationInstructions
        customImagesInstructions
        customToolingInstructions
        placeholderInputMessage
        openByDefault
        disclaimer
        showDocumentsReferences
        isDefault
        hasMemory
        hasImages
        promptTopKeywords
        keyWordsForKnowledgeBase
        hasTopicsContext
        hasFunctions
        advancedLanguageDetection
        allowedTopics
        defaultAnswer
        noKnowledgeDefaultAnswer
        quickQuestions
        recencyImportance
        maxRecencyIncrease
        recencyTopKIncreaseFactor
        stopSequence
        examples {
          question
          answer
        }
        enhancementPassage
        inhibitionPassage
        pertinencePassage
        goals
        qaUrl
        topK
        mode
        charting
        summarisation
        compressor
        docTopK
        minRetrievalScore
        timeSignificance
        hasModeration
        customModerations
        maxHistory
        maxTokens
        topicEnhancer
        maxTopics
        blender
        temperature
        version
        subject
        workspaceID
        createdBy
        updatedBy
        agentFunctions
        isGlobal
        plainTextOutput
        chainOfThoughts
        treeOfThoughts
        extendedOutput
        agentType
        agentsPool
        maxPlanningSteps
        planningInstructions
        plannerExecutionAttempts
        plannerRequiresApprovalForPlanExecution
        reviewInstructions
        showReferencesToInternalDocs
        preventWidgetUsage
        allowFeedback
        generateExtendedReport
        showTimeForResponse
        showDetectedLanguage
        isMultiLanguageEnabled
        hasMath
        hasCode
        hasNER
        restrictedAccess
        restrictedAccessUsers
        splashTitle
        showSplashWhenEmpty
        llmEndpoint
        llmUrl
        llmUrlStatus
        llmUrlType
        llmBaseModel
        llmProvider
        functionCallingProvider
        functionCallingModel
        showAgentName
        allowImagesUpload
        allowVideosUpload
        allowAudiosUpload
        allowDocsUpload
        allowImagesGeneration
        baseImageGenerationModel
        maxImagesGenerated
        advancedImageGeneration
        allowVideosGeneration
        allowAudiosGeneration
        voiceFileKey
        allowInternetSearch
        bannedDomains
        maxUrlsForInternetSearch
        allowDeepInternetSearch
        internetSearchModes
        internetSearchLocation
        autoVoiceMode
        keywordsWeightInRetrieval
        customCodeExecutionEnvironments
        phoneNumberAsSender
        whatsappNumberAsSender
        emailAsSender
        communicationServices
        channelCallbackDelay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAgents = /* GraphQL */ `
  query SyncAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAgents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        label
        description
        interpolationString
        emoji
        color
        darkColor
        icon
        largeLogo
        icoUrl
        logoBackground
        darkLogoBackground
        messageOnLaunch
        messagesOnLaunch
        rotateMessagesOnLaunch
        rotateMessagesOnLaunchInterval
        placeholderMessageOnLoading
        customGreetingInstructions
        customConclusionInstructions
        customChartingInstructions
        customSummarisationInstructions
        customImagesInstructions
        customToolingInstructions
        placeholderInputMessage
        openByDefault
        disclaimer
        showDocumentsReferences
        isDefault
        hasMemory
        hasImages
        promptTopKeywords
        keyWordsForKnowledgeBase
        hasTopicsContext
        hasFunctions
        advancedLanguageDetection
        allowedTopics
        defaultAnswer
        noKnowledgeDefaultAnswer
        quickQuestions
        recencyImportance
        maxRecencyIncrease
        recencyTopKIncreaseFactor
        stopSequence
        examples {
          question
          answer
        }
        enhancementPassage
        inhibitionPassage
        pertinencePassage
        goals
        qaUrl
        topK
        mode
        charting
        summarisation
        compressor
        docTopK
        minRetrievalScore
        timeSignificance
        hasModeration
        customModerations
        maxHistory
        maxTokens
        topicEnhancer
        maxTopics
        blender
        temperature
        version
        subject
        workspaceID
        createdBy
        updatedBy
        agentFunctions
        isGlobal
        plainTextOutput
        chainOfThoughts
        treeOfThoughts
        extendedOutput
        agentType
        agentsPool
        maxPlanningSteps
        planningInstructions
        plannerExecutionAttempts
        plannerRequiresApprovalForPlanExecution
        reviewInstructions
        showReferencesToInternalDocs
        preventWidgetUsage
        allowFeedback
        generateExtendedReport
        showTimeForResponse
        showDetectedLanguage
        isMultiLanguageEnabled
        hasMath
        hasCode
        hasNER
        restrictedAccess
        restrictedAccessUsers
        splashTitle
        showSplashWhenEmpty
        llmEndpoint
        llmUrl
        llmUrlStatus
        llmUrlType
        llmBaseModel
        llmProvider
        functionCallingProvider
        functionCallingModel
        showAgentName
        allowImagesUpload
        allowVideosUpload
        allowAudiosUpload
        allowDocsUpload
        allowImagesGeneration
        baseImageGenerationModel
        maxImagesGenerated
        advancedImageGeneration
        allowVideosGeneration
        allowAudiosGeneration
        voiceFileKey
        allowInternetSearch
        bannedDomains
        maxUrlsForInternetSearch
        allowDeepInternetSearch
        internetSearchModes
        internetSearchLocation
        autoVoiceMode
        keywordsWeightInRetrieval
        customCodeExecutionEnvironments
        phoneNumberAsSender
        whatsappNumberAsSender
        emailAsSender
        communicationServices
        channelCallbackDelay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const agentsByWorkspace = /* GraphQL */ `
  query AgentsByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentsByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        label
        description
        interpolationString
        emoji
        color
        darkColor
        icon
        largeLogo
        icoUrl
        logoBackground
        darkLogoBackground
        messageOnLaunch
        messagesOnLaunch
        rotateMessagesOnLaunch
        rotateMessagesOnLaunchInterval
        placeholderMessageOnLoading
        customGreetingInstructions
        customConclusionInstructions
        customChartingInstructions
        customSummarisationInstructions
        customImagesInstructions
        customToolingInstructions
        placeholderInputMessage
        openByDefault
        disclaimer
        showDocumentsReferences
        isDefault
        hasMemory
        hasImages
        promptTopKeywords
        keyWordsForKnowledgeBase
        hasTopicsContext
        hasFunctions
        advancedLanguageDetection
        allowedTopics
        defaultAnswer
        noKnowledgeDefaultAnswer
        quickQuestions
        recencyImportance
        maxRecencyIncrease
        recencyTopKIncreaseFactor
        stopSequence
        examples {
          question
          answer
        }
        enhancementPassage
        inhibitionPassage
        pertinencePassage
        goals
        qaUrl
        topK
        mode
        charting
        summarisation
        compressor
        docTopK
        minRetrievalScore
        timeSignificance
        hasModeration
        customModerations
        maxHistory
        maxTokens
        topicEnhancer
        maxTopics
        blender
        temperature
        version
        subject
        workspaceID
        createdBy
        updatedBy
        agentFunctions
        isGlobal
        plainTextOutput
        chainOfThoughts
        treeOfThoughts
        extendedOutput
        agentType
        agentsPool
        maxPlanningSteps
        planningInstructions
        plannerExecutionAttempts
        plannerRequiresApprovalForPlanExecution
        reviewInstructions
        showReferencesToInternalDocs
        preventWidgetUsage
        allowFeedback
        generateExtendedReport
        showTimeForResponse
        showDetectedLanguage
        isMultiLanguageEnabled
        hasMath
        hasCode
        hasNER
        restrictedAccess
        restrictedAccessUsers
        splashTitle
        showSplashWhenEmpty
        llmEndpoint
        llmUrl
        llmUrlStatus
        llmUrlType
        llmBaseModel
        llmProvider
        functionCallingProvider
        functionCallingModel
        showAgentName
        allowImagesUpload
        allowVideosUpload
        allowAudiosUpload
        allowDocsUpload
        allowImagesGeneration
        baseImageGenerationModel
        maxImagesGenerated
        advancedImageGeneration
        allowVideosGeneration
        allowAudiosGeneration
        voiceFileKey
        allowInternetSearch
        bannedDomains
        maxUrlsForInternetSearch
        allowDeepInternetSearch
        internetSearchModes
        internetSearchLocation
        autoVoiceMode
        keywordsWeightInRetrieval
        customCodeExecutionEnvironments
        phoneNumberAsSender
        whatsappNumberAsSender
        emailAsSender
        communicationServices
        channelCallbackDelay
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAgentFunction = /* GraphQL */ `
  query GetAgentFunction($id: ID!) {
    getAgentFunction(id: $id) {
      id
      name
      description
      parameters
      model
      url
      requestType
      authorisationType
      authorisationKey
      authorisationID
      paramsAsPath
      scope
      jsonPathExtractor
      headers
      staticArgs
      customExecutionCode
      isCallbackFn
      isDatabaseScript
      isHtmlFn
      isChatFn
      isHandOffFn
      onFnCall
      handOffAgent
      handOffToOriginalAgentOnCompletion
      handOffToOriginalAgentOnFailure
      handOffAgentOnCompletion
      handOffAgentOnFailure
      chatScript
      chatAction
      htmlUrl
      htmlScript
      isHtmlRedirectFn
      htmlFormOnSuccessfulSubmitMessage
      htmlFormOnFailedSubmitMessage
      dbSchemaMetadata
      preExecutionScript
      postExecutionScript
      dbScript
      dbConnectionID
      preExecutionFnID
      preExecutionMapping
      OAuthConnectionID
      workspaceID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAgentFunctions = /* GraphQL */ `
  query ListAgentFunctions(
    $filter: ModelAgentFunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentFunctions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        parameters
        model
        url
        requestType
        authorisationType
        authorisationKey
        authorisationID
        paramsAsPath
        scope
        jsonPathExtractor
        headers
        staticArgs
        customExecutionCode
        isCallbackFn
        isDatabaseScript
        isHtmlFn
        isChatFn
        isHandOffFn
        onFnCall
        handOffAgent
        handOffToOriginalAgentOnCompletion
        handOffToOriginalAgentOnFailure
        handOffAgentOnCompletion
        handOffAgentOnFailure
        chatScript
        chatAction
        htmlUrl
        htmlScript
        isHtmlRedirectFn
        htmlFormOnSuccessfulSubmitMessage
        htmlFormOnFailedSubmitMessage
        dbSchemaMetadata
        preExecutionScript
        postExecutionScript
        dbScript
        dbConnectionID
        preExecutionFnID
        preExecutionMapping
        OAuthConnectionID
        workspaceID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAgentFunctions = /* GraphQL */ `
  query SyncAgentFunctions(
    $filter: ModelAgentFunctionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAgentFunctions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        parameters
        model
        url
        requestType
        authorisationType
        authorisationKey
        authorisationID
        paramsAsPath
        scope
        jsonPathExtractor
        headers
        staticArgs
        customExecutionCode
        isCallbackFn
        isDatabaseScript
        isHtmlFn
        isChatFn
        isHandOffFn
        onFnCall
        handOffAgent
        handOffToOriginalAgentOnCompletion
        handOffToOriginalAgentOnFailure
        handOffAgentOnCompletion
        handOffAgentOnFailure
        chatScript
        chatAction
        htmlUrl
        htmlScript
        isHtmlRedirectFn
        htmlFormOnSuccessfulSubmitMessage
        htmlFormOnFailedSubmitMessage
        dbSchemaMetadata
        preExecutionScript
        postExecutionScript
        dbScript
        dbConnectionID
        preExecutionFnID
        preExecutionMapping
        OAuthConnectionID
        workspaceID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const functionsByWorkspace = /* GraphQL */ `
  query FunctionsByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    functionsByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        parameters
        model
        url
        requestType
        authorisationType
        authorisationKey
        authorisationID
        paramsAsPath
        scope
        jsonPathExtractor
        headers
        staticArgs
        customExecutionCode
        isCallbackFn
        isDatabaseScript
        isHtmlFn
        isChatFn
        isHandOffFn
        onFnCall
        handOffAgent
        handOffToOriginalAgentOnCompletion
        handOffToOriginalAgentOnFailure
        handOffAgentOnCompletion
        handOffAgentOnFailure
        chatScript
        chatAction
        htmlUrl
        htmlScript
        isHtmlRedirectFn
        htmlFormOnSuccessfulSubmitMessage
        htmlFormOnFailedSubmitMessage
        dbSchemaMetadata
        preExecutionScript
        postExecutionScript
        dbScript
        dbConnectionID
        preExecutionFnID
        preExecutionMapping
        OAuthConnectionID
        workspaceID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAuthorisation = /* GraphQL */ `
  query GetAuthorisation($id: ID!) {
    getAuthorisation(id: $id) {
      id
      name
      tokenSecret
      description
      scope
      grantType
      clientId
      clientSecret
      authorizationBaseUrl
      staticArgs
      tokenBaseUrl
      type
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAuthorisations = /* GraphQL */ `
  query ListAuthorisations(
    $filter: ModelAuthorisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthorisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tokenSecret
        description
        scope
        grantType
        clientId
        clientSecret
        authorizationBaseUrl
        staticArgs
        tokenBaseUrl
        type
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAuthorisations = /* GraphQL */ `
  query SyncAuthorisations(
    $filter: ModelAuthorisationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuthorisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        tokenSecret
        description
        scope
        grantType
        clientId
        clientSecret
        authorizationBaseUrl
        staticArgs
        tokenBaseUrl
        type
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const authorisationsByWorkspace = /* GraphQL */ `
  query AuthorisationsByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAuthorisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    authorisationsByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tokenSecret
        description
        scope
        grantType
        clientId
        clientSecret
        authorizationBaseUrl
        staticArgs
        tokenBaseUrl
        type
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHosting = /* GraphQL */ `
  query GetHosting($id: ID!) {
    getHosting(id: $id) {
      id
      name
      description
      tokenSecret
      endpoint
      modelName
      baseModel
      customModel
      apiVersion
      type
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHostings = /* GraphQL */ `
  query ListHostings(
    $filter: ModelHostingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHostings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        tokenSecret
        endpoint
        modelName
        baseModel
        customModel
        apiVersion
        type
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHostings = /* GraphQL */ `
  query SyncHostings(
    $filter: ModelHostingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHostings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        tokenSecret
        endpoint
        modelName
        baseModel
        customModel
        apiVersion
        type
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hostingsByWorkspace = /* GraphQL */ `
  query HostingsByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHostingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    HostingsByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        tokenSecret
        endpoint
        modelName
        baseModel
        customModel
        apiVersion
        type
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDatabaseConnection = /* GraphQL */ `
  query GetDatabaseConnection($id: ID!) {
    getDatabaseConnection(id: $id) {
      id
      name
      description
      type
      host
      port
      username
      passwordSecret
      database
      schema
      ssl
      ssh
      sshHost
      sshPort
      sshUsername
      sshPasswordSecret
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDatabaseConnections = /* GraphQL */ `
  query ListDatabaseConnections(
    $filter: ModelDatabaseConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatabaseConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        host
        port
        username
        passwordSecret
        database
        schema
        ssl
        ssh
        sshHost
        sshPort
        sshUsername
        sshPasswordSecret
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDatabaseConnections = /* GraphQL */ `
  query SyncDatabaseConnections(
    $filter: ModelDatabaseConnectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDatabaseConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        type
        host
        port
        username
        passwordSecret
        database
        schema
        ssl
        ssh
        sshHost
        sshPort
        sshUsername
        sshPasswordSecret
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const connectionsByWorkspace = /* GraphQL */ `
  query ConnectionsByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDatabaseConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    connectionsByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        host
        port
        username
        passwordSecret
        database
        schema
        ssl
        ssh
        sshHost
        sshPort
        sshUsername
        sshPasswordSecret
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      name
      description
      url
      workspaceID
      topics
      status
      lastValidationDate
      lastValidationRequested
      validationToken
      allowedPaths
      siteTree
      lastScraped
      skipOwnerValidation
      useProxy
      completion_percentage
      extractImages
      freeRoaming
      scrapingCycle
      imgRetrievalPrompt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        url
        workspaceID
        topics
        status
        lastValidationDate
        lastValidationRequested
        validationToken
        allowedPaths
        siteTree
        lastScraped
        skipOwnerValidation
        useProxy
        completion_percentage
        extractImages
        freeRoaming
        scrapingCycle
        imgRetrievalPrompt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSites = /* GraphQL */ `
  query SyncSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        url
        workspaceID
        topics
        status
        lastValidationDate
        lastValidationRequested
        validationToken
        allowedPaths
        siteTree
        lastScraped
        skipOwnerValidation
        useProxy
        completion_percentage
        extractImages
        freeRoaming
        scrapingCycle
        imgRetrievalPrompt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const sitesByWorkspace = /* GraphQL */ `
  query SitesByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sitesByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        url
        workspaceID
        topics
        status
        lastValidationDate
        lastValidationRequested
        validationToken
        allowedPaths
        siteTree
        lastScraped
        skipOwnerValidation
        useProxy
        completion_percentage
        extractImages
        freeRoaming
        scrapingCycle
        imgRetrievalPrompt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const siteByValidationToken = /* GraphQL */ `
  query SiteByValidationToken(
    $validationToken: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteByValidationToken(
      validationToken: $validationToken
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        url
        workspaceID
        topics
        status
        lastValidationDate
        lastValidationRequested
        validationToken
        allowedPaths
        siteTree
        lastScraped
        skipOwnerValidation
        useProxy
        completion_percentage
        extractImages
        freeRoaming
        scrapingCycle
        imgRetrievalPrompt
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEntityAI = /* GraphQL */ `
  query GetEntityAI($id: ID!) {
    getEntityAI(id: $id) {
      id
      owner
      label
      type
      description
      emoji
      parentEntity
      backgroundColor
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEntityAIS = /* GraphQL */ `
  query ListEntityAIS(
    $filter: ModelEntityAIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntityAIS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        label
        type
        description
        emoji
        parentEntity
        backgroundColor
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEntityAIS = /* GraphQL */ `
  query SyncEntityAIS(
    $filter: ModelEntityAIFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEntityAIS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        label
        type
        description
        emoji
        parentEntity
        backgroundColor
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const entitiesByWorkspace = /* GraphQL */ `
  query EntitiesByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEntityAIFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        label
        type
        description
        emoji
        parentEntity
        backgroundColor
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTrainingLog = /* GraphQL */ `
  query GetTrainingLog($id: ID!) {
    getTrainingLog(id: $id) {
      id
      owner
      name
      description
      status
      trainingIterations
      lastActivation
      duration
      trainingMeta
      trainingConfig
      workspaceID
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTrainingLogs = /* GraphQL */ `
  query ListTrainingLogs(
    $filter: ModelTrainingLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        status
        trainingIterations
        lastActivation
        duration
        trainingMeta
        trainingConfig
        workspaceID
        type
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTrainingLogs = /* GraphQL */ `
  query SyncTrainingLogs(
    $filter: ModelTrainingLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTrainingLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        status
        trainingIterations
        lastActivation
        duration
        trainingMeta
        trainingConfig
        workspaceID
        type
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBatchJobLog = /* GraphQL */ `
  query GetBatchJobLog($id: ID!) {
    getBatchJobLog(id: $id) {
      id
      owner
      name
      description
      status
      duration
      batchMeta
      batchConfig
      batchOutput
      workspaceID
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBatchJobLogs = /* GraphQL */ `
  query ListBatchJobLogs(
    $filter: ModelBatchJobLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchJobLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        status
        duration
        batchMeta
        batchConfig
        batchOutput
        workspaceID
        type
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBatchJobLogs = /* GraphQL */ `
  query SyncBatchJobLogs(
    $filter: ModelBatchJobLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBatchJobLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        status
        duration
        batchMeta
        batchConfig
        batchOutput
        workspaceID
        type
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAppLog = /* GraphQL */ `
  query GetAppLog($id: ID!) {
    getAppLog(id: $id) {
      id
      owner
      type
      filename
      event
      status
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAppLogs = /* GraphQL */ `
  query ListAppLogs(
    $filter: ModelAppLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        type
        filename
        event
        status
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppLogs = /* GraphQL */ `
  query SyncAppLogs(
    $filter: ModelAppLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        type
        filename
        event
        status
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRequestLog = /* GraphQL */ `
  query GetRequestLog($id: ID!) {
    getRequestLog(id: $id) {
      id
      srtKey
      type
      status
      words
      tokens
      sentences
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRequestLogs = /* GraphQL */ `
  query ListRequestLogs(
    $filter: ModelRequestLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        srtKey
        type
        status
        words
        tokens
        sentences
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRequestLogs = /* GraphQL */ `
  query SyncRequestLogs(
    $filter: ModelRequestLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequestLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        srtKey
        type
        status
        words
        tokens
        sentences
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const requestsByMonthAndWorkspace = /* GraphQL */ `
  query RequestsByMonthAndWorkspace(
    $srtKey: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRequestLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requestsByMonthAndWorkspace(
      srtKey: $srtKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        srtKey
        type
        status
        words
        tokens
        sentences
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOutputStream = /* GraphQL */ `
  query GetOutputStream($id: ID!) {
    getOutputStream(id: $id) {
      id
      content
      t_content
      prompt
      t_prompt
      workspaceID
      type
      status
      humanFeedback
      humanFeedbackComment
      humanFeedbackType
      humanFeedbackSubType
      humanFeedbackProvidedBy
      adminResolution
      adminChecked
      autoTune
      scoring
      metadata
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOutputStreams = /* GraphQL */ `
  query ListOutputStreams(
    $filter: ModelOutputStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOutputStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        t_content
        prompt
        t_prompt
        workspaceID
        type
        status
        humanFeedback
        humanFeedbackComment
        humanFeedbackType
        humanFeedbackSubType
        humanFeedbackProvidedBy
        adminResolution
        adminChecked
        autoTune
        scoring
        metadata
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOutputStreams = /* GraphQL */ `
  query SyncOutputStreams(
    $filter: ModelOutputStreamFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOutputStreams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        content
        t_content
        prompt
        t_prompt
        workspaceID
        type
        status
        humanFeedback
        humanFeedbackComment
        humanFeedbackType
        humanFeedbackSubType
        humanFeedbackProvidedBy
        adminResolution
        adminChecked
        autoTune
        scoring
        metadata
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const streamsByWorkspace = /* GraphQL */ `
  query StreamsByWorkspace(
    $workspaceID: ID!
    $humanFeedback: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOutputStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    streamsByWorkspace(
      workspaceID: $workspaceID
      humanFeedback: $humanFeedback
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        t_content
        prompt
        t_prompt
        workspaceID
        type
        status
        humanFeedback
        humanFeedbackComment
        humanFeedbackType
        humanFeedbackSubType
        humanFeedbackProvidedBy
        adminResolution
        adminChecked
        autoTune
        scoring
        metadata
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFolders = /* GraphQL */ `
  query GetFolders($id: ID!) {
    getFolders(id: $id) {
      id
      owner
      name
      description
      emoji
      status
      parent
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFoldersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        emoji
        status
        parent
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFolders = /* GraphQL */ `
  query SyncFolders(
    $filter: ModelFoldersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFolders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        emoji
        status
        parent
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDictionaryItem = /* GraphQL */ `
  query GetDictionaryItem($id: ID!) {
    getDictionaryItem(id: $id) {
      id
      owner
      sourceLanguage
      targetLanguage
      sourceText
      targetText
      workspaceID
      pos
      description
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDictionaryItems = /* GraphQL */ `
  query ListDictionaryItems(
    $filter: ModelDictionaryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDictionaryItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        sourceLanguage
        targetLanguage
        sourceText
        targetText
        workspaceID
        pos
        description
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDictionaryItems = /* GraphQL */ `
  query SyncDictionaryItems(
    $filter: ModelDictionaryItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDictionaryItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        sourceLanguage
        targetLanguage
        sourceText
        targetText
        workspaceID
        pos
        description
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      owner
      name
      description
      status {
        status
        lastUpdated
      }
      workspaceID
      creationTime
      createdBy
      updatedBy
      visibility
      isAIReplying
      activeMessageForAI
      messages {
        items {
          id
          role
          chatID
          text
          t_text
          t_text_summary
          createdAt
          creationTime
          updatedAt
          scoring
          embeddingId
          humanFeedback
          humanFeedbackComment
          humanFeedbackType
          humanFeedbackSubType
          humanFeedbackProvidedBy
          adminResolution
          prevMessage
          prevMessagePosEq
          prevMessageNegEq
          correctContext
          correctAnswer
          adminChecked
          autoTune
          userID
          agentID
          images
          audios
          videos
          files
          imagesText
          audiosText
          videosText
          filesText
          isAI
          messageType
          status
          metadata
          callbackMetadata
          workspaceID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      chatHistory
      topics
      documentId
      primaryRole
      primaryAgent
      plannerAgent
      forcePlannerStop
      allowMultipleRoles
      roles
      language
      summary
      caseId
      customerId
      caseInfo
      customerInfo
      metadata
      channelSettings
      externalParticipantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        status {
          status
          lastUpdated
        }
        workspaceID
        creationTime
        createdBy
        updatedBy
        visibility
        isAIReplying
        activeMessageForAI
        messages {
          nextToken
          startedAt
        }
        chatHistory
        topics
        documentId
        primaryRole
        primaryAgent
        plannerAgent
        forcePlannerStop
        allowMultipleRoles
        roles
        language
        summary
        caseId
        customerId
        caseInfo
        customerInfo
        metadata
        channelSettings
        externalParticipantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChats = /* GraphQL */ `
  query SyncChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        status {
          status
          lastUpdated
        }
        workspaceID
        creationTime
        createdBy
        updatedBy
        visibility
        isAIReplying
        activeMessageForAI
        messages {
          nextToken
          startedAt
        }
        chatHistory
        topics
        documentId
        primaryRole
        primaryAgent
        plannerAgent
        forcePlannerStop
        allowMultipleRoles
        roles
        language
        summary
        caseId
        customerId
        caseInfo
        customerInfo
        metadata
        channelSettings
        externalParticipantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatsByWorkspace = /* GraphQL */ `
  query ChatsByWorkspace(
    $workspaceID: ID!
    $creationTime: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatsByWorkspace(
      workspaceID: $workspaceID
      creationTime: $creationTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        status {
          status
          lastUpdated
        }
        workspaceID
        creationTime
        createdBy
        updatedBy
        visibility
        isAIReplying
        activeMessageForAI
        messages {
          nextToken
          startedAt
        }
        chatHistory
        topics
        documentId
        primaryRole
        primaryAgent
        plannerAgent
        forcePlannerStop
        allowMultipleRoles
        roles
        language
        summary
        caseId
        customerId
        caseInfo
        customerInfo
        metadata
        channelSettings
        externalParticipantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatByExternalParticipantId = /* GraphQL */ `
  query ChatByExternalParticipantId(
    $externalParticipantId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatByExternalParticipantId(
      externalParticipantId: $externalParticipantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        status {
          status
          lastUpdated
        }
        workspaceID
        creationTime
        createdBy
        updatedBy
        visibility
        isAIReplying
        activeMessageForAI
        messages {
          nextToken
          startedAt
        }
        chatHistory
        topics
        documentId
        primaryRole
        primaryAgent
        plannerAgent
        forcePlannerStop
        allowMultipleRoles
        roles
        language
        summary
        caseId
        customerId
        caseInfo
        customerInfo
        metadata
        channelSettings
        externalParticipantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomPrompt = /* GraphQL */ `
  query GetCustomPrompt($id: ID!) {
    getCustomPrompt(id: $id) {
      id
      type
      scope
      style
      domain
      label
      promptLength
      promptPlaceholder
      interpolationString
      availableToAgents
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomPrompts = /* GraphQL */ `
  query ListCustomPrompts(
    $filter: ModelCustomPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        scope
        style
        domain
        label
        promptLength
        promptPlaceholder
        interpolationString
        availableToAgents
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomPrompts = /* GraphQL */ `
  query SyncCustomPrompts(
    $filter: ModelCustomPromptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomPrompts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        scope
        style
        domain
        label
        promptLength
        promptPlaceholder
        interpolationString
        availableToAgents
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customPromptsByWorkspace = /* GraphQL */ `
  query CustomPromptsByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customPromptsByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        scope
        style
        domain
        label
        promptLength
        promptPlaceholder
        interpolationString
        availableToAgents
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomCodeExecutionEnvironment = /* GraphQL */ `
  query GetCustomCodeExecutionEnvironment($id: ID!) {
    getCustomCodeExecutionEnvironment(id: $id) {
      id
      name
      description
      functionName
      availableLibraries
      customExecutionInstructions
      customExecutionCode
      allowExternalAPI
      workspaceID
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomCodeExecutionEnvironments = /* GraphQL */ `
  query ListCustomCodeExecutionEnvironments(
    $filter: ModelCustomCodeExecutionEnvironmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomCodeExecutionEnvironments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        functionName
        availableLibraries
        customExecutionInstructions
        customExecutionCode
        allowExternalAPI
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomCodeExecutionEnvironments = /* GraphQL */ `
  query SyncCustomCodeExecutionEnvironments(
    $filter: ModelCustomCodeExecutionEnvironmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomCodeExecutionEnvironments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        functionName
        availableLibraries
        customExecutionInstructions
        customExecutionCode
        allowExternalAPI
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customCodeExecutionEnvironmentsByWorkspace = /* GraphQL */ `
  query CustomCodeExecutionEnvironmentsByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomCodeExecutionEnvironmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customCodeExecutionEnvironmentsByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        functionName
        availableLibraries
        customExecutionInstructions
        customExecutionCode
        allowExternalAPI
        workspaceID
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      role
      chatID
      text
      t_text
      t_text_summary
      createdAt
      creationTime
      updatedAt
      scoring
      embeddingId
      humanFeedback
      humanFeedbackComment
      humanFeedbackType
      humanFeedbackSubType
      humanFeedbackProvidedBy
      adminResolution
      prevMessage
      prevMessagePosEq
      prevMessageNegEq
      correctContext
      correctAnswer
      adminChecked
      autoTune
      userID
      agentID
      images
      audios
      videos
      files
      imagesText
      audiosText
      videosText
      filesText
      isAI
      messageType
      status
      metadata
      callbackMetadata
      workspaceID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        chatID
        text
        t_text
        t_text_summary
        createdAt
        creationTime
        updatedAt
        scoring
        embeddingId
        humanFeedback
        humanFeedbackComment
        humanFeedbackType
        humanFeedbackSubType
        humanFeedbackProvidedBy
        adminResolution
        prevMessage
        prevMessagePosEq
        prevMessageNegEq
        correctContext
        correctAnswer
        adminChecked
        autoTune
        userID
        agentID
        images
        audios
        videos
        files
        imagesText
        audiosText
        videosText
        filesText
        isAI
        messageType
        status
        metadata
        callbackMetadata
        workspaceID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChatMessages = /* GraphQL */ `
  query SyncChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        role
        chatID
        text
        t_text
        t_text_summary
        createdAt
        creationTime
        updatedAt
        scoring
        embeddingId
        humanFeedback
        humanFeedbackComment
        humanFeedbackType
        humanFeedbackSubType
        humanFeedbackProvidedBy
        adminResolution
        prevMessage
        prevMessagePosEq
        prevMessageNegEq
        correctContext
        correctAnswer
        adminChecked
        autoTune
        userID
        agentID
        images
        audios
        videos
        files
        imagesText
        audiosText
        videosText
        filesText
        isAI
        messageType
        status
        metadata
        callbackMetadata
        workspaceID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const messagesByChat = /* GraphQL */ `
  query MessagesByChat(
    $chatID: ID!
    $creationTime: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChat(
      chatID: $chatID
      creationTime: $creationTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        chatID
        text
        t_text
        t_text_summary
        createdAt
        creationTime
        updatedAt
        scoring
        embeddingId
        humanFeedback
        humanFeedbackComment
        humanFeedbackType
        humanFeedbackSubType
        humanFeedbackProvidedBy
        adminResolution
        prevMessage
        prevMessagePosEq
        prevMessageNegEq
        correctContext
        correctAnswer
        adminChecked
        autoTune
        userID
        agentID
        images
        audios
        videos
        files
        imagesText
        audiosText
        videosText
        filesText
        isAI
        messageType
        status
        metadata
        callbackMetadata
        workspaceID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const messagesByWorkspace = /* GraphQL */ `
  query MessagesByWorkspace(
    $workspaceID: ID!
    $humanFeedback: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByWorkspace(
      workspaceID: $workspaceID
      humanFeedback: $humanFeedback
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        chatID
        text
        t_text
        t_text_summary
        createdAt
        creationTime
        updatedAt
        scoring
        embeddingId
        humanFeedback
        humanFeedbackComment
        humanFeedbackType
        humanFeedbackSubType
        humanFeedbackProvidedBy
        adminResolution
        prevMessage
        prevMessagePosEq
        prevMessageNegEq
        correctContext
        correctAnswer
        adminChecked
        autoTune
        userID
        agentID
        images
        audios
        videos
        files
        imagesText
        audiosText
        videosText
        filesText
        isAI
        messageType
        status
        metadata
        callbackMetadata
        workspaceID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCommunicationService = /* GraphQL */ `
  query GetCommunicationService($id: ID!) {
    getCommunicationService(id: $id) {
      id
      workspaceID
      name
      description
      channel
      provider
      senderid
      fromNumber
      tokenSecret
      isActive
      config
      createdBy
      updatedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCommunicationServices = /* GraphQL */ `
  query ListCommunicationServices(
    $filter: ModelCommunicationServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunicationServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        name
        description
        channel
        provider
        senderid
        fromNumber
        tokenSecret
        isActive
        config
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCommunicationServices = /* GraphQL */ `
  query SyncCommunicationServices(
    $filter: ModelCommunicationServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCommunicationServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        workspaceID
        name
        description
        channel
        provider
        senderid
        fromNumber
        tokenSecret
        isActive
        config
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const communicationServicesByWorkspace = /* GraphQL */ `
  query CommunicationServicesByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommunicationServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CommunicationServicesByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspaceID
        name
        description
        channel
        provider
        senderid
        fromNumber
        tokenSecret
        isActive
        config
        createdBy
        updatedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriptionPricingPlan = /* GraphQL */ `
  query GetSubscriptionPricingPlan($id: ID!) {
    getSubscriptionPricingPlan(id: $id) {
      id
      type
      subscriptionStatus
      isPredictionOnDemandGlobal
      isTrainingOnDemandGlobal
      predictionRequestsGlobal
      batchRequestsGlobal
      trainingMinutesGlobal
      baseModelGN
      baseModelQA
      baseModelNLP
      maxUsers
      agcEnabled
      isDownloadModelEnabled
      isDownloadDataEnabled
      isAPIAccessEnabled
      isBatchJobsEnabled
      isSentimentReviewerEnabled
      isBusinessAnalystEnabled
      isContentCreatorEnabled
      isPriorityTrainingEnabled
      isPredictionGPUAccelerationEnabledGlobal
      isPredictionGPUAccelerationEnabledRC
      isPredictionGPUAccelerationEnabledNLP
      aiMaxConsumption {
        generator {
          requests
          words
          tokens
          sentences
        }
        questioner {
          requests
          words
          tokens
          sentences
        }
        translator {
          requests
          words
          tokens
          sentences
        }
        answerer {
          requests
          words
          tokens
          sentences
        }
        parser {
          requests
          words
          tokens
          sentences
        }
        tokenizer {
          requests
          words
          tokens
          sentences
        }
        embedder {
          requests
          words
          tokens
          sentences
        }
        detector {
          requests
          words
          tokens
          sentences
        }
        chatter {
          requests
          words
          tokens
          sentences
        }
        lastUpdate
      }
      maxMrcDocs
      accuracyModelEnabled
      isTrainingNLPEnabled
      isTrainingQAEnabled
      isTrainingGNEnabled
      isLongTermMemoryEnabled
      isFunctionsEnabled
      isDBFunctionsEnabled
      isHtmlFunctionsEnabled
      isSitesBindingEnabled
      isAgentsSecurityEnabled
      isWidgetChatsHistoryEnabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubscriptionPricingPlans = /* GraphQL */ `
  query ListSubscriptionPricingPlans(
    $filter: ModelSubscriptionPricingPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionPricingPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        subscriptionStatus
        isPredictionOnDemandGlobal
        isTrainingOnDemandGlobal
        predictionRequestsGlobal
        batchRequestsGlobal
        trainingMinutesGlobal
        baseModelGN
        baseModelQA
        baseModelNLP
        maxUsers
        agcEnabled
        isDownloadModelEnabled
        isDownloadDataEnabled
        isAPIAccessEnabled
        isBatchJobsEnabled
        isSentimentReviewerEnabled
        isBusinessAnalystEnabled
        isContentCreatorEnabled
        isPriorityTrainingEnabled
        isPredictionGPUAccelerationEnabledGlobal
        isPredictionGPUAccelerationEnabledRC
        isPredictionGPUAccelerationEnabledNLP
        aiMaxConsumption {
          lastUpdate
        }
        maxMrcDocs
        accuracyModelEnabled
        isTrainingNLPEnabled
        isTrainingQAEnabled
        isTrainingGNEnabled
        isLongTermMemoryEnabled
        isFunctionsEnabled
        isDBFunctionsEnabled
        isHtmlFunctionsEnabled
        isSitesBindingEnabled
        isAgentsSecurityEnabled
        isWidgetChatsHistoryEnabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubscriptionPricingPlans = /* GraphQL */ `
  query SyncSubscriptionPricingPlans(
    $filter: ModelSubscriptionPricingPlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubscriptionPricingPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        subscriptionStatus
        isPredictionOnDemandGlobal
        isTrainingOnDemandGlobal
        predictionRequestsGlobal
        batchRequestsGlobal
        trainingMinutesGlobal
        baseModelGN
        baseModelQA
        baseModelNLP
        maxUsers
        agcEnabled
        isDownloadModelEnabled
        isDownloadDataEnabled
        isAPIAccessEnabled
        isBatchJobsEnabled
        isSentimentReviewerEnabled
        isBusinessAnalystEnabled
        isContentCreatorEnabled
        isPriorityTrainingEnabled
        isPredictionGPUAccelerationEnabledGlobal
        isPredictionGPUAccelerationEnabledRC
        isPredictionGPUAccelerationEnabledNLP
        aiMaxConsumption {
          lastUpdate
        }
        maxMrcDocs
        accuracyModelEnabled
        isTrainingNLPEnabled
        isTrainingQAEnabled
        isTrainingGNEnabled
        isLongTermMemoryEnabled
        isFunctionsEnabled
        isDBFunctionsEnabled
        isHtmlFunctionsEnabled
        isSitesBindingEnabled
        isAgentsSecurityEnabled
        isWidgetChatsHistoryEnabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriptionBaseConfig = /* GraphQL */ `
  query GetSubscriptionBaseConfig($id: ID!) {
    getSubscriptionBaseConfig(id: $id) {
      id
      type
      planConfig {
        domain
        parsingConfig {
          questionsLimit
          answerAsNER
          answerByParagraph
          uniqueAnswer
          isSentenceSplitEnabled
          isImplicitSelfEnabled
          temperature
          maxTokens
          topP
          topK
          qaDocTopK
          frequencyPenalty
          presencePenalty
          qaTopP
          qaTopK
          qaMaxTokens
          qaTemperature
          qaFrequencyPenalty
          qaPresencePenalty
          qaReplyOutsidePassage
          qaAiKnowledge
          qaInhibitionPassage
          qaEnhancementPassage
          qaRole
          qaDefaultAnswer
          qaStopSequence
          stopSequence
          stopPrompt
          qaBlender
          agcEnabled
        }
        notificationConfig {
          emailsList
          emailOnTrainingCompleted
          emailOnBatchCompleted
          emailOnBatchFailed
          emailOnBatchStart
          emailOnTrainingFailed
          emailOnTrainingStart
          notifyAllUsersInWorkspace
          notifyOnThreasholdConsumption
          notificationThresholdConsumption
        }
        trainingConfig {
          trainingIterations
          trainingIterationsNLP
          trainingIterationsRC
          trainingEpochsNLP
          trainingEpochsRC
          trainingEpochsGN
          maxStepsNLP
          patienceNLP
          learningRateNLP
          learningRateRC
          testSizeNLP
          testSizeRC
          batchSizeNLP
          batchSizeRC
          datasetTestSizeThresholdNLP
          datasetTestSizeThresholdRC
          learningRateGN
          testSizeGN
          batchSizeGN
          datasetTestSizeThresholdGN
          enableManagedSpotTraining
          maxRuntimeWaitTime
          maxRuntime
          includeReadComprehensionDataInTraining
          generateDockerImageForRCengine
          generateDockerImageForNLPengine
          generateDockerImageForGNengine
          emailsListForTrainingConfirmation
          maxQueueWaitTime
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubscriptionBaseConfigs = /* GraphQL */ `
  query ListSubscriptionBaseConfigs(
    $filter: ModelSubscriptionBaseConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionBaseConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        planConfig {
          domain
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubscriptionBaseConfigs = /* GraphQL */ `
  query SyncSubscriptionBaseConfigs(
    $filter: ModelSubscriptionBaseConfigFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubscriptionBaseConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        planConfig {
          domain
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGenerationConfig = /* GraphQL */ `
  query GetGenerationConfig($id: ID!) {
    getGenerationConfig(id: $id) {
      id
      styles
      domains
      scopes
      prompts {
        type
        label
        promptLength
        promptPlaceholder
        interpolationString
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGenerationConfigs = /* GraphQL */ `
  query ListGenerationConfigs(
    $filter: ModelGenerationConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenerationConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        styles
        domains
        scopes
        prompts {
          type
          label
          promptLength
          promptPlaceholder
          interpolationString
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGenerationConfigs = /* GraphQL */ `
  query SyncGenerationConfigs(
    $filter: ModelGenerationConfigFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGenerationConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        styles
        domains
        scopes
        prompts {
          type
          label
          promptLength
          promptPlaceholder
          interpolationString
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getArenaBattle = /* GraphQL */ `
  query GetArenaBattle($id: ID!) {
    getArenaBattle(id: $id) {
      id
      firstAgent
      secondAgent
      firstAgentChatID
      secondAgentChatID
      winnerID
      createdAt
      creationTime
      updatedAt
      workspaceID
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listArenaBattles = /* GraphQL */ `
  query ListArenaBattles(
    $filter: ModelArenaBattleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArenaBattles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstAgent
        secondAgent
        firstAgentChatID
        secondAgentChatID
        winnerID
        createdAt
        creationTime
        updatedAt
        workspaceID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncArenaBattles = /* GraphQL */ `
  query SyncArenaBattles(
    $filter: ModelArenaBattleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncArenaBattles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstAgent
        secondAgent
        firstAgentChatID
        secondAgentChatID
        winnerID
        createdAt
        creationTime
        updatedAt
        workspaceID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const arenaBattlesByWorkspace = /* GraphQL */ `
  query ArenaBattlesByWorkspace(
    $workspaceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelArenaBattleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ArenaBattlesByWorkspace(
      workspaceID: $workspaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstAgent
        secondAgent
        firstAgentChatID
        secondAgentChatID
        winnerID
        createdAt
        creationTime
        updatedAt
        workspaceID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
