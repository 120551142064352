import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useEffect, useState } from "react";
import workspacesHelper from "../../../API/Workspaces/workspacesHelper";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import EmbeddingsSettingsView from "../Components/EmbeddingsSettingsView";

const EmbeddingsSettingsContainer = () => {
  const { getActiveWorkspace, updateWorkspaceInContext } = useWorkspaces();

  const AppToasty = AppToast.useToast();
  const [initialValues, setInitialValues] = useState({});
  const [saveInProgress, setSaveInProgress] = useState(false);
  useEffect(() => {
    setInitialValues({
      maxChunkWords:
        getActiveWorkspace()?.embeddingConfig?.maxChunkWords || 400,
      maxOverlapSentences:
        getActiveWorkspace()?.embeddingConfig?.maxOverlapSentences || 2,
      chunkingStrategy:
        getActiveWorkspace()?.embeddingConfig?.chunkingStrategy || "keywords",
      imageExtractionStrategy:
        getActiveWorkspace()?.embeddingConfig?.imageExtractionStrategy ||
        "safe",
      minImageWidth:
        getActiveWorkspace()?.embeddingConfig?.minImageWidth || 300,
      minImageHeight:
        getActiveWorkspace()?.embeddingConfig?.minImageHeight || 300,
      aspectRatioMin:
        getActiveWorkspace()?.embeddingConfig?.aspectRatioMin || 0.5,
      aspectRatioMax:
        getActiveWorkspace()?.embeddingConfig?.aspectRatioMax || 6,
    });
  }, [JSON.stringify(getActiveWorkspace()?.embeddingConfig)]);
  const handleSubmit = async (v) => {
    setSaveInProgress(true);
    try {
      result = await workspacesHelper.updateWorkspace(getActiveWorkspace().id, [
        {
          property: "embeddingConfig",
          value: v,
        },
      ]);
      AppToasty.show("Embeddings configuration updated", {
        type: "success",
      });
    } catch (e) {
      console.log("e", e);
      AppToasty.show("Embeddings configuration could not be updated", {
        type: "danger",
      });
    } finally {
      setSaveInProgress(false);
    }
  };

  return (
    <EmbeddingsSettingsView
      initialValues={initialValues}
      onSubmit={handleSubmit}
      saveInProgress={saveInProgress}
    />
  );
};

export default EmbeddingsSettingsContainer;
