import React, { useState, useEffect } from "react";
import LoadingPlaceholder from "../LoadingPlaceholder";

const DelayedRender = ({ delay = 2000, onRender = () => {}, children }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true);
      onRender();
    }, delay);

    return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before the delay
  }, [delay]);

  return shouldRender ? (
    children
  ) : (
    <LoadingPlaceholder visible={true} caption="" />
  );
};

export default DelayedRender;
