const appReducer = (state, action) => {
  switch (action.type) {
    // case "SET_EXISTING_AI_NERS": {
    //   return { ...state, aiNerConfig: action.payload };
    // }
    // case "SET_EXISTING_AI_INTENTS": {
    //   return { ...state, aiIntentConfig: action.payload };
    // }
    case "SET_PAGE_NOT_FOUND": {
      return { ...state, pageNotFound: action.payload };
    }
    case "SET_AI_ENTITIES_CONFIG": {
      return { ...state, aiEntitiesConfig: action.payload };
    }
    case "SET_AI_DICTIONARIES_CONFIG": {
      return { ...state, aiDictionariesConfig: action.payload };
    }
    case "SET_AGENTS_FUNCTIONS": {
      return { ...state, agentsFunctions: action.payload };
    }
    case "SET_DB_CONNECTIONS": {
      return { ...state, dbConnections: action.payload };
    }
    case "SET_LANGUAGES_CONFIG": {
      return { ...state, languagesConfig: action.payload };
    }
    case "SET_CHATS_LOADED": {
      return { ...state, chatsLoaded: action.payload };
    }
    case "SET_SELECTED_ARENA_MODE": {
      return { ...state, selectedArenaMode: action.payload };
    }
    case "SET_WORKSPACES": {
      return { ...state, workspaces: action.payload };
    }
    case "SET_HOSTINGS": {
      return { ...state, hostings: action.payload };
    }
    case "SET_AUTHORISATIONS": {
      return { ...state, authorisations: action.payload };
    }
    case "SET_SITES": {
      return { ...state, sites: action.payload };
    }
    case "SET_CHANNELS": {
      return { ...state, channels: action.payload };
    }
    case "SET_CHATS": {
      return { ...state, chats: action.payload };
    }
    case "SET_WORKSPACE_PLAN": {
      return { ...state, workspacePlan: action.payload };
    }
    case "SET_GENERATION_CONFIG_DATA": {
      return { ...state, generationConfigData: action.payload };
    }
    case "SET_AGENTS": {
      return { ...state, agents: action.payload };
    }
    case "SET_EXEC_ENVS": {
      return { ...state, execEnvs: action.payload };
    }
    case "SET_CUSTOM_PROMPTS": {
      return { ...state, customPrompts: action.payload };
    }
    case "SET_ACTIVE_WORKSPACE_ID": {
      return {
        ...state,
        activeWorkspaceID: action.payload,
      };
    }
    case "SET_GENERATOR_USER_SELECTION": {
      return {
        ...state,
        generatorUserSelection: action.payload,
      };
    }
    case "SET_BATCH_LOGS": {
      return {
        ...state,
        batchLogs: action.payload,
      };
    }
    case "SET_WORKSPACE_COLLAB_READY": {
      return { ...state, isWorkspaceCollabReady: action.payload };
    }
    case "SET_WORKSPACE_READY": {
      return { ...state, isWorkspaceReady: action.payload };
    }
    case "SET_TRAINING_DATA": {
      return { ...state, trainingData: action.payload };
    }
    case "SET_TRAINING_LOGS": {
      return {
        ...state,
        trainingLogs: action.payload,
      };
    }
    case "SET_WORKSPACE_ACTIVE_USERS": {
      return { ...state, activeWorkspaceUsers: action.payload };
    }
    case "SET_WORKSPACE_USERS": {
      return { ...state, workspaceUsers: action.payload };
    }
    case "SET_ACCESS_CREDENTIALS": {
      return { ...state, accessCredentials: action.payload };
    }
    case "SET_TRAINING_LOGS_PAGE": {
      return { ...state, trainingLogPage: action.payload };
    }
    case "SET_CHANGES_NOT_SAVED": {
      return { ...state, changesNotSaved: action.payload };
    }
    case "SET_APP_LOGS": {
      return { ...state, appLogs: action.payload };
    }
    case "SET_APP_LOGS_READY": {
      return { ...state, appLogsReady: action.payload };
    }
    case "SET_TAB_SELECTION": {
      return { ...state, appTabSelection: action.payload };
    }
    case "SET_INITIAL_NLP_VALUE": {
      return { ...state, initialNLPValue: action.payload };
    }
    case "SET_INITIAL_RC_VALUE": {
      return { ...state, initialRCValue: action.payload };
    }
    case "SET_INITIAL_GN_VALUE": {
      return { ...state, initialGNValue: action.payload };
    }
    case "SET_INITIAL_CN_VALUE": {
      return { ...state, initialCNValue: action.payload };
    }
    case "SET_INITIAL_MRC_VALUE": {
      return { ...state, initialMRCValue: action.payload };
    }
    case "SET_REDIRECT_TO_DOC": {
      return { ...state, redirectToDoc: action.payload };
    }
    case "SET_MRC_TREEVIEW_DATA": {
      return { ...state, treeViewData: action.payload };
    }
    case "SET_FOLDERS_LOADED": {
      return { ...state, foldersLoaded: action.payload };
    }
    case "SET_MRC_DOCUMENTS": {
      return { ...state, mrcDocuments: action.payload };
    }
    case "SET_MRC_FOLDERS": {
      return { ...state, mrcFolders: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
