import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppAvatarList from "@toothfairy/shared-ui/AppAvatarList";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppLabelCountIcon from "@toothfairy/shared-ui/AppLabelCountIcon";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";
import { Popable } from "react-native-popable";
import { useReactiveVar } from "react-reactive-var";
import { forcedClosed, pinnedSideMenu, showSideMenu } from "../../../App";
import Constants from "../../Constants";
import usePath from "../../Hooks/usePath";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory } from "../../Router";
import { isLargeScreen } from "../../Screens/MainViewContainer";
import ToothFairySupportButton from "../ToothFairyComponents/ToothFairySupportButton";
import AppAccountAvatar from "./Components/AppAccountAvatar";
import ChatWidget from "../ChatWidget";
import envConfig from "../../../envConfig";

dayjs.extend(relativeTime);

const TopBar = ({ isInWorkspace, handleSignOut }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { supportUrl } = Constants;
  const showMenu = useReactiveVar(showSideMenu);
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [showSupportWidget, setShowSupportWidget] = useState(false);
  const [lastWidgetOpened, setLastWidgetOpened] = useState(Date.now());
  const { user } = AppUser.useUser();
  const { currentTheme, mode } = AppTheme.useTheme();
  const styles = generateStyles({ currentTheme, mode });
  const { updatePath, params } = usePath();
  const [showModal, setShowModal] = useState(false);
  const {
    getActiveWorkspace,
    returnCleanedWorkspaces,
    getWorkspaceUsers,
    isUserAdmin,
  } = useWorkspaces();

  const supportUrls = (section) => {
    switch (section) {
      case "DASHBOARD_MODEL":
        return `${supportUrl}/i/32`;
      case "NLP_PARSER":
        return `${supportUrl}/i/6`;
      case "PROFILE_ANALYSIS":
        return `${supportUrl}/i/34`;
      default:
        return supportUrl;
    }
  };
  const _workspaceDropDown = useMemo(() => {
    return (
      isLargeScreen() &&
      isInWorkspace && (
        <AppDropDown
          dropDownStyle={{
            width: isLargeScreen() ? 300 : 250,
            marginLeft: 20,
            borderColor: currentTheme?.theme?.border_color,
          }}
          isElevated={false}
          dropdownIconColor="transparent"
          items={returnCleanedWorkspaces(state?.workspaces)}
          propertyLabel="title"
          propertyValue="id"
          selectedValue={state?.activeWorkspaceID}
          onValueChange={(itemValue, itemIndex) => {
            dispatch("SET_ACTIVE_WORKSPACE_ID", itemValue);
            updatePath({
              workspaceId: itemValue,
              sectionId: params?.sectionId,
            });
            window.location.reload();
          }}
        />
      )
    );
  }, [
    isLargeScreen(),
    isInWorkspace,
    currentTheme,
    JSON.stringify(state?.workspaces),
    updatePath,
    returnCleanedWorkspaces,
    params,
  ]);
  const _trialEndingCaption = useMemo(() => {
    return (
      isInWorkspace &&
      user?.stripeCustomerData?.subscriptionIdOnTrial ===
        getActiveWorkspace()?.subscriptionId && (
        <AppText
          wrapperStyle={{ marginRight: 10, marginTop: 5 }}
          color={currentTheme?.theme?.orange}
          style={{ fontSize: currentTheme?.theme?.extra_small_font_size }}
          fontWeight="bold"
        >{`Trial ending on ${AppUtilities.dateConverter.ConvertTimeStampToDateString(
          user?.stripeCustomerData?.trialEndDate * 1000,
          "en",
          t,
          false,
          "D MMM"
        )}`}</AppText>
      )
    );
  }, [
    isInWorkspace,
    user?.id,
    JSON.stringify(currentTheme),
    user?.stripeCustomerData?.subscriptionIdOnTrial,
    getActiveWorkspace()?.subscriptionId,
  ]);

  const _activeBalanceCaption = useMemo(() => {
    return (
      user?.stripeCustomerData?.balance < 0 && (
        <AppText
          wrapperStyle={{ marginRight: 10, marginTop: 5 }}
          color={currentTheme?.theme?.green}
          style={{ fontSize: currentTheme?.theme?.extra_small_font_size }}
          fontWeight="bold"
        >{`Available credit $${
          user?.stripeCustomerData?.balance / -100
        }`}</AppText>
      )
    );
  }, [isInWorkspace, user?.id, user?.stripeCustomerData?.balance]);
  const _menuIcon = useMemo(() => {
    return (
      <AppIcon
        size={30}
        isClickable
        icon={AppAssets.icons.menu}
        onPress={() => {
          showSideMenu(!showMenu);
          forcedClosed(!forcedClosed);
          pinnedSideMenu(false);
        }}
      />
    );
  }, [showMenu]);

  const _usersInWorkspace = useMemo(() => {
    return (
      isInWorkspace && (
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 2 }}
        >
          <Popable
            numberOfLines={2}
            backgroundColor={currentTheme?.theme?.tooltipBackgroundColor}
            style={[
              {
                top: 30,
                zIndex: 20,
                maxWidth: 300,
              },
            ]}
            content={"Workspace Users"}
            action="hover"
            position="bottom"
          >
            <AppLabelCountIcon
              shouldShowOne={false}
              count={getWorkspaceUsers()?.length}
              labelCountIconViewStyle={{
                marginTop: -7,
                marginRight: 0,
              }}
              marginRight={14}
              isDescriptionVisible={false}
              label={"wk_users"}
              icon={AppAssets.icons.usersSettings}
              color={currentTheme?.theme?.primary}
              wrapperCountLabelStyle={{
                right: 6,
                top: -4,
              }}
              countColor={currentTheme?.theme?.primary}
              activeColor={currentTheme?.theme?.primary}
              countLabelStyle={{
                fontWeight: "bold",
                fontSize: currentTheme?.theme?.extra_small_font_size,
              }}
              size={24}
              onPress={() => {
                if (isUserAdmin())
                  history.push(
                    `/workspaces/${getActiveWorkspace()?.id}/admin/users`
                  );
              }}
            />
          </Popable>
        </View>
      )
    );
  }, [
    isInWorkspace,
    currentTheme,
    getWorkspaceUsers(),
    getActiveWorkspace()?.userID,
    user?.id,
  ]);
  const _activeUsersLists = useMemo(() => {
    return (
      isInWorkspace && (
        <Popable
          numberOfLines={2}
          backgroundColor={currentTheme?.theme?.tooltipBackgroundColor}
          style={[
            {
              top: 30,
              zIndex: 20,
              maxWidth: 300,
            },
          ]}
          content={t("usersActiveWorkspaceCaption")}
          action="hover"
          position="bottom"
        >
          <AppAvatarList
            avatarSize={20}
            avatarRatio={0.5}
            labelProperty="username"
            namesList={state?.activeWorkspaceUsers}
            wrapperStyle={{
              top: 2,
              marginRight: 10,
            }}
          />
        </Popable>
      )
    );
  }, [
    isInWorkspace,
    JSON.stringify(
      state?.activeWorkspaceUsers,
      currentTheme?.theme?.tooltipBackgroundColor
    ),
  ]);
  const _topBarView = useMemo(() => {
    return (
      <>
        <View style={styles.container}>
          <View style={{ flexDirection: "row" }}>
            {_menuIcon}
            {_workspaceDropDown}
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {isUserAdmin() && _activeUsersLists}
            {isUserAdmin() && _trialEndingCaption}
            {isUserAdmin() && _activeBalanceCaption}
            {state?.isWorkspaceCollabReady === false && (
              <AppIcon
                size={22}
                icon={AppAssets.icons.networkError}
                color={currentTheme?.theme?.orange}
                wrapperStyle={{ marginLeft: 5, marginRight: 10 }}
              />
            )}

            {
              <Popable
                numberOfLines={2}
                backgroundColor={currentTheme?.theme?.tooltipBackgroundColor}
                style={[
                  {
                    top: 30,
                    zIndex: 20,
                    maxWidth: 300,
                  },
                ]}
                content={"Ask ToothFairyAI"}
                action="hover"
                position="bottom"
              >
                <AppIcon
                  isClickable
                  onPress={() => {
                    setShowSupportWidget(!showSupportWidget);
                    setLastWidgetOpened(Date.now());
                  }}
                  // imageSource={require("../../assets/ai.png")}
                  icon={AppAssets.icons.question_icon}
                  width={"100%"}
                  size={18}
                  wrapperStyle={{
                    height: 24,
                    width: 24,
                    marginTop: 5,
                    marginRight: 8,
                    borderBottomColor: showSupportWidget
                      ? currentTheme?.theme?.primary
                      : currentTheme?.theme?.transparent,
                    borderBottomWidth: 1,
                  }}
                  style={{
                    height: 24,
                    width: 24,
                  }}
                />
              </Popable>
            }

            {isUserAdmin() && _usersInWorkspace}
            <AppAccountAvatar handleSignOut={handleSignOut} user={user} />
          </View>
        </View>
      </>
    );
  }, [
    _menuIcon,
    showSupportWidget,
    _workspaceDropDown,
    _trialEndingCaption,
    getWorkspaceUsers(),
    isUserAdmin(),
    mode,
    state?.isWorkspaceCollabReady,
  ]);
  return (
    <>
      {_topBarView}
      {showSupportWidget ? <ChatWidget isOpen={showSupportWidget} /> : <></>}
    </>
  );
};

const generateStyles = ({ currentTheme, mode }) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingVertical: 5,
      paddingHorizontal: 15,
      height: 45,
      zIndex: 10,
      backgroundColor: currentTheme?.theme?.backgroundColor,
      borderBottomColor: currentTheme?.theme?.app_outline_color,
      borderBottomWidth: 1,
      opacity: 0.9,
    },
    modal: {
      height: "95%",
      marginHorizontal: Platform.OS === "web" ? "auto" : "5%",
      marginVertical: "auto",
      width: Platform.OS === "web" ? "60%" : "90%",
      maxWidth: 600,
    },
    listContentContainer: {
      padding: 0,
    },
    listHeader: {
      backgroundColor: currentTheme?.theme?.lightGrey,
    },
    listItemContainer: {
      flexDirection: "row",
      padding: 10,
    },
  });

export default TopBar;
