import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React from "react";
import { View } from "react-native";
import AppToast from "@toothfairy/shared-ui/AppToast";
import { useTranslation } from "react-i18next";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";

const BillingCheckoutForm = ({
  setPaymentMethodEditMode,
  handlePaymentMethodSave,
  onPaymentMethodSave,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    await handlePaymentMethodSave();
    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: window.location.href,
      },
    });
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.error(result.error.message);
      AppToasty.show(
        t("errorWhileSavingPaymentMethod"),

        { type: "danger" }
      );
    } else {
      AppToasty.show(
        t("paymentMethodSavedSuccessfully"),

        { type: "success" }
      );
      onPaymentMethodSave(result.setupIntent.payment_method);
      setPaymentMethodEditMode(false);

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <View>
      <PaymentElement />
      <ToothFairyButton
        onPress={handleSubmit}
        paddingLeft={10}
        paddingRight={10}
        wrapperStyle={{
          margin: "auto",
          marginTop: 20,
        }}
        disabled={!stripe}
        title="Save payment method"
      />
      <ToothFairyButton
        onPress={() => {
          setPaymentMethodEditMode(false);
        }}
        isDeletion
        paddingLeft={10}
        paddingRight={10}
        wrapperStyle={{
          margin: "auto",
          marginTop: 20,
        }}
        title="Cancel"
      />
    </View>
  );
};

export default BillingCheckoutForm;
