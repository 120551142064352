import API from "@aws-amplify/api";
import {
  createCustomCodeExecutionEnvironment,
  deleteCustomCodeExecutionEnvironment,
  updateCustomCodeExecutionEnvironment,
} from "../../graphql/mutations";
import {
  customCodeExecutionEnvironmentsByWorkspace,
  getCustomCodeExecutionEnvironment,
} from "../../graphql/queries";
const createCustomCodeExecutionEnvironment_ = async (object) => {
  return API.graphql({
    query: createCustomCodeExecutionEnvironment,
    variables: { input: object },
  });
};
const getCustomCodeExecutionEnvironment_ = async (id) => {
  return API.graphql({
    query: getCustomCodeExecutionEnvironment,
    variables: { id },
  });
};
const updateCustomCodeExecutionEnvironment_ = async (id, _properties = {}) => {
  const original = await getCustomCodeExecutionEnvironment_(id);
  return API.graphql({
    query: updateCustomCodeExecutionEnvironment,
    variables: {
      input: {
        id,
        _version: original?.data?.getCustomCodeExecutionEnvironment?._version,
        ..._properties,
      },
    },
  });
};

const deleteCustomCodeExecutionEnvironment_ = async (id) => {
  const original = await getCustomCodeExecutionEnvironment_(id);
  return API.graphql({
    query: deleteCustomCodeExecutionEnvironment,
    variables: {
      input: {
        id,
        _version: original?.data?.getCustomCodeExecutionEnvironment?._version,
      },
    },
  });
};

const getCustomCodeExecutionEnvironments_ = async (workspaceID, nextToken) => {
  return API.graphql({
    query: customCodeExecutionEnvironmentsByWorkspace,
    variables: {
      workspaceID,
      nextToken,
    },
  });
};

export default {
  createCustomCodeExecutionEnvironment: createCustomCodeExecutionEnvironment_,
  updateCustomCodeExecutionEnvironment: updateCustomCodeExecutionEnvironment_,
  deleteCustomCodeExecutionEnvironment: deleteCustomCodeExecutionEnvironment_,
  getCustomCodeExecutionEnvironment: getCustomCodeExecutionEnvironment_,
  getCustomCodeExecutionEnvironments: getCustomCodeExecutionEnvironments_,
};
