import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { forwardRef, useEffect, useState, useRef } from "react";
import { Platform, View } from "react-native";
import Constants from "../../../Constants";
import { isLargeScreen } from "../../MainViewContainer";
import { AppText } from "@toothfairy/shared-ui";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { useHistory } from "../../../Router";
import AgentFunctions from "../../../API/AgentFunctions";
import channelsHelper from "../../../API/Channels/channelsHelper";

const formValidationSchema = AppForms.yup.object().shape({
  name: AppForms.yup
    .string()
    .required("Chat name cannot be empty")
    .label("Chat name"),
  description: AppForms.yup.string().label("Chat description").nullable(),
  primaryRole: AppForms.yup
    .string()
    .required("You must select an agent")
    .label("Agent")
    .notOneOf(["[object Object]"], "Agent is required")
    .nullable(),
  isPublic: AppForms.yup.boolean().label("Public chat").nullable(),
  documentId: AppForms.yup.string().label("Document").nullable(),
  language: AppForms.yup.string().label("Language").nullable(),
  topics: AppForms.yup.array().label("Topics"),
  toPhoneNumber: AppForms.yup
    .string()
    .matches(
      /^\+\d{1,3}\s?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/,
      "Phone number must include country code (e.g., +61)"
    )
    .label("Phone Number")
    .nullable(),
  toEmail: AppForms.yup
    .string()
    .email("Invalid email address")
    .label("Email Address")
    .nullable(),
});

const ChatCreationForm = forwardRef(
  (
    {
      creationofNewDocumentInProgress,
      treeViewActionContext,
      selectedNode,
      handleFormSubmit,
      documents,
    },
    ref
  ) => {
    const observerRef = useRef(null);
    const fakeRef = useRef(null);
    const [formResetted, setFormResetted] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState("none");
    const history = useHistory();
    const [isPublicDisabled, setIsPublicDisabled] = React.useState(false);
    const { currentTheme } = AppTheme.useTheme();
    const { state } = AppStateManager.useAppStateManager();
    const [allowedDocuments, setAllowedDocuments] = React.useState([]);
    const [hasCustomerFunction, setHasCustomerFunction] = React.useState(false);
    const [hasCaseFunction, setHasCaseFunction] = React.useState(false);
    const { getActiveWorkspace } = useWorkspaces();
    const [allowLanguageSelection, setAllowLanguageSelection] =
      React.useState(true);
    const [hasAccessToDocuments, setHasAccessToDocuments] =
      React.useState(false); //state?.user?.hasAccessToDocuments
    const [providers, setProviders] = React.useState([]);
    const [existingChannelSettings, setExistingChannelSettings] =
      useState(null);
    const [hasWhatsApp, setHasWhatsApp] = React.useState(false);
    const [hasEmail, setHasEmail] = React.useState(false);
    const [hasSMS, setHasSMS] = React.useState(false);
    const [availableChannels, setAvailableChannels] = useState([]);
    const [allowedTopics, setAllowedTopics] = React.useState([]);
    // run a use effect to change the state of isPublicDisabled after 1 second to avoid the form to be submitted before the state is changed
    React.useEffect(() => {
      if (treeViewActionContext)
        setTimeout(() => {
          setIsPublicDisabled(
            treeViewActionContext === "EDIT_CHAT" &&
              selectedNode?.data?.visibility === "public"
          );
        }, 100);
    }, [
      treeViewActionContext,
      setIsPublicDisabled,
      selectedNode?.data?.visibility,
    ]);
    const forceRemovalOfState = () => {
      setSelectedChannel("none");
      setIsPublicDisabled(false);
      setAllowLanguageSelection(true);
      setHasCustomerFunction(false);
      setHasCaseFunction(false);
      setHasAccessToDocuments(false);
      setAllowedDocuments([]);
      setAllowedTopics([]);
      setHasSMS(false);
      setHasEmail(false);
      setHasWhatsApp(false);
      setAvailableChannels([]);
      setProviders([]);
      setExistingChannelSettings(null);
    };

    function extractEnabledChannelInfo(channelSettings) {
      const channels = ["email", "sms", "whatsapp", "linkedin"];
      // convert channel settings to a json if is a string
      if (typeof channelSettings === "string") {
        channelSettings = JSON.parse(channelSettings);
      }
      for (const channel of channels) {
        if (channelSettings[channel] && channelSettings[channel]?.isEnabled) {
          setSelectedChannel(channel);
          return {
            channel: channel,
            providerID: channelSettings[channel].providerID,
          };
        }
      }

      // If no channel is enabled, return null or throw an error
      return null;
    }

    useEffect(() => {
      const _channels = [
        {
          id: "none",
          title: "None",
        },
      ];
      if (hasSMS)
        _channels.push({
          id: "sms",
          title: "SMS",
        });
      if (hasWhatsApp)
        _channels.push({
          id: "whatsapp",
          title: "WhatsApp",
        });
      if (hasEmail)
        _channels.push({
          id: "email",
          title: "Email",
        });
      setAvailableChannels(_channels);
      if (
        selectedNode?.data?.channelSettings &&
        treeViewActionContext === "EDIT_CHAT"
      ) {
        setExistingChannelSettings(
          extractEnabledChannelInfo(selectedNode?.data?.channelSettings)
        );
      }
    }, [hasEmail, hasSMS, hasWhatsApp, treeViewActionContext]);
    const onValueChange = async (values) => {
      if (values != "noSelection") {
        const agentRole = state?.agents?.find((role) => role.id === values);
        const _allowedTopics = agentRole?.allowedTopics;
        const _allowedTopicsId = _allowedTopics?.map((topic) => topic?.id);
        setAllowLanguageSelection(agentRole?.isMultiLanguageEnabled != false);
        if (
          agentRole?.mode == "retriever" ||
          agentRole?.mode == "speed" ||
          agentRole?.mode == "accuracy"
        ) {
          filteredObjects = documents;
          if (_allowedTopicsId?.length > 0) {
            let filteredObjects = documents.filter((obj) => {
              return obj?.topics?.some((id) => _allowedTopicsId.includes(id));
            });
            setAllowedDocuments(filteredObjects);
            setHasAccessToDocuments(true);
          } else {
            setAllowedDocuments(documents);
            setHasAccessToDocuments(true);
          }
        } else if (
          _allowedTopicsId?.length === 0 ||
          !agentRole?.hasTopicsContext
        ) {
          setHasAccessToDocuments(false);
        }
        setIsPublicDisabled(agentRole?.restrictedAccess == true);
        const functionsData =
          await AgentFunctions.getAgentFunctionsByWorkspaceID({
            workspaceID: getActiveWorkspace()?.id,
          });

        const channelsData = await channelsHelper.getChannels(
          getActiveWorkspace()?.id
        );

        let functions = functionsData?.data?.functionsByWorkspace?.items;
        functions = functions?.filter((func) =>
          agentRole?.agentFunctions?.some((roleFunc) => roleFunc == func.id)
        );
        let channels =
          channelsData?.data?.CommunicationServicesByWorkspace?.items;
        const agentChannels = channels?.filter((u) =>
          agentRole?.communicationServices?.includes(u?.id)
        );

        setHasWhatsApp(
          agentChannels?.find((channel) => channel?.channel == "whatsapp")
        );
        setHasEmail(
          agentChannels?.find((channel) => channel?.channel == "email")
        );
        setHasSMS(agentChannels?.find((channel) => channel?.channel == "sms"));
        setProviders(
          agentChannels?.map((channel) => {
            return {
              channel: channel?.channel,
              provider: channel?.provider,
              id: channel?.id,
            };
          })
        );
        setHasCustomerFunction(
          functions?.some((func) => func?.scope == "customer")
        );
        setHasCaseFunction(functions?.some((func) => func?.scope == "case"));
      } else {
        setHasEmail(false);
        setHasSMS(false);
        setHasWhatsApp(false);
        setProviders([]);
      }
    };
    const entityName = () => {
      if (
        treeViewActionContext === "NEW_CHAT" ||
        treeViewActionContext === "EDIT_CHAT"
      )
        return "Chat";
    };
    const roles =
      state?.agents?.filter(
        (u) =>
          u?.agentType !== "ContentCreator" &&
          u?.agentType !== "BusinessAnalyst"
      ) || [];
    let primaryDefaultRole;
    if (roles?.length > 0) {
      if (getActiveWorkspace()?.defaultChatAgent)
        primaryDefaultRole = roles?.find(
          (role) => role.id === getActiveWorkspace()?.defaultChatAgent
        )?.id;
      else {
        primaryDefaultRole = roles?.find((role) => role.mode === "chatter")?.id;
        if (!primaryDefaultRole) primaryDefaultRole = roles[0]?.id;
      }
    }
    const _items = [...roles].sort((a, b) => {
      if (a.id === primaryDefaultRole) return -1;
      if (b.id === primaryDefaultRole) return 1;
      return 0;
    });
    _items.unshift({
      id: "noSelection",
      label: "None",
    });
    useEffect(() => {
      const agentRole = state?.agents?.find(
        (role) => role.id === primaryDefaultRole
      );
      const _allowedTopics = agentRole?.allowedTopics;
      const _allowedTopicsId = _allowedTopics?.map((topic) => topic?.id);
      setAllowLanguageSelection(agentRole?.isMultiLanguageEnabled != false);
      if (agentRole?.mode == "retriever") {
        filteredObjects = documents;
        if (_allowedTopicsId?.length > 0) {
          let filteredObjects = documents.filter((obj) => {
            return obj?.topics?.some((id) => _allowedTopicsId.includes(id));
          });
          setAllowedDocuments(filteredObjects);
          setHasAccessToDocuments(true);
        } else {
          setAllowedDocuments(documents);
          setHasAccessToDocuments(true);
        }
      } else if (
        _allowedTopicsId?.length === 0 ||
        !agentRole?.hasTopicsContext
      ) {
        setHasAccessToDocuments(false);
      }
    }, [primaryDefaultRole, documents?.length, treeViewActionContext]);
    useEffect(() => {
      if (
        selectedNode?.data?.primaryRole &&
        treeViewActionContext?.includes("EDIT")
      )
        onValueChange(selectedNode?.data?.primaryRole);
    }, [
      selectedNode?.data?.primaryRole,
      treeViewActionContext?.includes("EDIT"),
    ]);
    useEffect(() => {
      if (!fakeRef.current) return;

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0, // Changed to 0 to detect when the form is completely out of view
      };

      const handleIntersection = (entries) => {
        const [entry] = entries;
        if (!entry.isIntersecting) {
          // The form is completely out of view, reinitialize it
          if (
            ref?.current &&
            ref?.current?.resetForm &&
            formResetted == false
          ) {
            setFormResetted(true);
            ref.current.resetForm();
            forceRemovalOfState();
          }
        } else {
          setFormResetted(false);
        }
      };

      observerRef.current = new IntersectionObserver(
        handleIntersection,
        options
      );
      if (fakeRef?.current) {
        observerRef.current.observe(fakeRef.current);
      }

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, [ref.current]);

    return (
      <>
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1, // Ensure it's behind other content
          }}
          ref={fakeRef}
        ></View>
        <AppForms.AppForm
          ref={ref}
          enableReinitialize
          style={{
            minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 300,
          }}
          initialValues={{
            name: treeViewActionContext?.includes("EDIT")
              ? selectedNode?.data?.name
              : `New ${entityName()}`,
            description: treeViewActionContext?.includes("EDIT")
              ? selectedNode?.data?.description
              : "",
            primaryRole: treeViewActionContext?.includes("EDIT")
              ? selectedNode?.data?.primaryRole
              : primaryDefaultRole,
            isPublic: treeViewActionContext?.includes("EDIT")
              ? selectedNode?.data?.visibility === "public"
              : false,
            documentId: treeViewActionContext?.includes("EDIT")
              ? selectedNode?.data?.documentId
              : null,
            language: treeViewActionContext?.includes("EDIT")
              ? selectedNode?.data?.language
              : "def",
            caseId: treeViewActionContext?.includes("EDIT")
              ? selectedNode?.data?.caseId
              : null,
            customerId: treeViewActionContext?.includes("EDIT")
              ? selectedNode?.data?.customerId
              : null,
            toPhoneNumber:
              treeViewActionContext?.includes("EDIT") &&
              (selectedChannel == "sms" || selectedChannel == "whatsapp")
                ? selectedNode?.data?.externalParticipantId
                : null,
            toEmail:
              treeViewActionContext?.includes("EDIT") &&
              selectedChannel == "email"
                ? selectedNode?.data?.externalParticipantId
                : null,
            channelType: treeViewActionContext?.includes("EDIT")
              ? existingChannelSettings?.channel
              : null,
            providerId: treeViewActionContext?.includes("EDIT")
              ? existingChannelSettings?.providerID
              : null,
          }}
          onSubmit={(e) => {
            if (!hasCaseFunction && e.caseId) e.caseId = null;
            if (!hasCustomerFunction && e.customerId) e.customerId = null;
            if (!hasSMS && !hasWhatsApp) e.toPhoneNumber = null;
            if (!hasEmail) e.toEmail = null;
            if (
              !e?.providerId &&
              selectedChannel != null &&
              providers?.filter((u) => u?.channel === selectedChannel)?.length >
                0
            )
              e.providerId = providers?.filter(
                (u) => u?.channel === selectedChannel
              )[0]?.id;
            handleFormSubmit(e);
          }}
          validationSchema={formValidationSchema}
        >
          {treeViewActionContext === "NEW_CHAT" && (
            <AppForms.AppFormDropDown
              isElevated={false}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: !isLargeScreen() && 250,
              }}
              showTextInputLabel
              label="Agent"
              isMandatory
              name="primaryRole"
              items={_items}
              propertyLabel="label"
              propertyValue="id"
              _onValueChange={onValueChange}
            />
          )}
          <AppForms.AppFormField
            showTextInputLabel
            name="name"
            label={`${entityName()} name`}
            wrapperInputStyle={{
              marginTop: 5,
            }}
          />
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
              marginHorizontal: 5,
            }}
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            multiline={true}
            label={`Description`}
            placeholder=""
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="description"
          />

          <AppForms.AppFormSwitch
            disabled={isPublicDisabled}
            key={isPublicDisabled}
            // disabled={true}
            wrapperStyle={[
              {
                backgroundColor: currentTheme?.theme?.lightGrey,
                maxHeight: 30,
                paddingHorizontal: 10,
                borderRadius: 20,
                paddingVertical: 18,
                marginHorizontal: 5,
                marginTop: 10,
                marginBottom: 5,
              },
            ]}
            labelTextStyle={{
              fontSize: currentTheme?.theme?.small_font_size,
              color: currentTheme?.theme?.darkGrey,
            }}
            showTextInputLabel={true}
            label={"Public chat"}
            displayIsTicked={true}
            name="isPublic"
          />
          {state?.languagesConfig?.length > 0 && allowLanguageSelection && (
            <AppForms.AppFormDropDown
              isElevated={false}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: !isLargeScreen() && 250,
              }}
              disabled={treeViewActionContext !== "NEW_CHAT"}
              showTextInputLabel
              label="Language"
              name="language"
              items={[
                { id: "def", name: "Auto-detect" },
                ...state?.languagesConfig,
              ]}
              propertyLabel="name"
              propertyValue="id"
            />
          )}

          {treeViewActionContext === "NEW_CHAT" &&
            hasAccessToDocuments &&
            (allowedDocuments?.length > 0 ? (
              <AppForms.AppFormDropDown
                isElevated={false}
                dropDownStyle={{
                  borderColor: currentTheme?.theme?.border_color,
                  maxWidth: !isLargeScreen() && 250,
                }}
                showTextInputLabel
                label="Knowledge"
                name="documentId"
                items={[
                  {
                    id: "noSelection",
                    title: "All documents (default)",
                  },
                  ...allowedDocuments,
                ]}
                propertyLabel="title"
                propertyValue="id"
              />
            ) : (
              <AppText
                isClickable
                isHoverable
                onPress={() => {
                  history.push(
                    `/workspaces/${getActiveWorkspace()?.id}/mrc_contents`
                  );
                }}
                color={currentTheme?.theme?.orange}
                style={{
                  fontWeight: "bold",
                  paddingHorizontal: 20,
                  paddingVertical: 5,
                }}
              >
                No documents available yet. Upload your documents and check the
                learning status in Knowledge hub.
              </AppText>
            ))}

          <>
            {hasCustomerFunction && (
              <AppForms.AppFormField
                showTextInputLabel
                name="customerId"
                disabled={treeViewActionContext !== "NEW_CHAT"}
                placeholder="The id of the customer to be linked to the chat"
                label={`Customer id`}
                wrapperInputStyle={{
                  marginTop: 5,
                }}
              />
            )}
            {hasCaseFunction && (
              <AppForms.AppFormField
                showTextInputLabel
                name="caseId"
                disabled={treeViewActionContext !== "NEW_CHAT"}
                placeholder="The id of the case to be linked to the chat"
                label={`Case id`}
                wrapperInputStyle={{
                  marginTop: 5,
                }}
              />
            )}
            {(hasWhatsApp || hasSMS || hasEmail) && (
              <>
                <AppForms.AppFormDropDown
                  isElevated={false}
                  dropDownStyle={{
                    borderColor: currentTheme?.theme?.border_color,
                    maxWidth: !isLargeScreen() && 250,
                  }}
                  showTextInputLabel
                  label="Select channel"
                  name="channelType"
                  items={availableChannels}
                  propertyLabel="title"
                  propertyValue="id"
                  _onValueChange={(v) => {
                    setSelectedChannel(v);
                  }}
                  // disabled={treeViewActionContext !== "NEW_CHAT"}
                />
              </>
            )}
            {providers?.filter((u) => u?.channel === selectedChannel)?.length >
              0 && (
              <AppForms.AppFormDropDown
                isElevated={false}
                dropDownStyle={{
                  borderColor: currentTheme?.theme?.border_color,
                  maxWidth: !isLargeScreen() && 250,
                }}
                showTextInputLabel
                label="Select provider"
                name="providerId"
                items={providers?.filter((u) => u?.channel === selectedChannel)}
                propertyLabel="provider"
                propertyValue="id"
                // disabled={treeViewActionContext !== "NEW_CHAT"}
              />
            )}
            {(hasSMS || hasWhatsApp) &&
              (selectedChannel == "sms" || selectedChannel == "whatsapp") && (
                <AppForms.AppFormField
                  showTextInputLabel
                  name="toPhoneNumber"
                  disabled={treeViewActionContext !== "NEW_CHAT"}
                  placeholder="The phone number to send messages to (e.g. +61 412 345 678)"
                  label={`Recipient phone number`}
                  wrapperInputStyle={{
                    marginTop: 5,
                  }}
                />
              )}
            {hasEmail && selectedChannel == "email" && (
              <AppForms.AppFormField
                showTextInputLabel
                name="toEmail"
                disabled={treeViewActionContext !== "NEW_CHAT"}
                placeholder="The email address to send messages to"
                label={`Recipient email address`}
                wrapperInputStyle={{
                  marginTop: 5,
                }}
              />
            )}
          </>
          <AppForms.AppSubmitButton
            title={"Save"}
            isEnabledOnFilled
            isDisabled={creationofNewDocumentInProgress}
            awesomeIconColor={currentTheme?.theme?.white}
            backgroundColor={currentTheme?.theme?.primary}
            btnContentStyle={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
            style={[
              {
                height: Constants.appButtonHeight,
                width: "auto",
                shadowColor: currentTheme?.theme?.transparent,
                marginHorizontal: 10,
              },
            ]}
            btnLabelStyle={{ fontSize: 16 }}
            wrapperStyle={[
              {
                width: "fit-content",
                minWidth: 150,
                marginTop: 20,
                alignSelf: "center",
                height: Constants.appButtonHeight,
              },
            ]}
          />
        </AppForms.AppForm>
      </>
    );
  }
);

export default ChatCreationForm;
