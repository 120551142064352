import { DataStore } from "@aws-amplify/datastore";
import { EntityAI } from "../../models";

const createEntity = async (object) => {
  return DataStore.save(new EntityAI(object));
};

const updateEntity = async (id, args) => {
  const original = await DataStore.query(EntityAI, id);
  return DataStore.save(
    EntityAI.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteEntity = async (id) => {
  return DataStore.delete(EntityAI, (ent) => ent.id("eq", id));
};

export default {
  createEntity,
  updateEntity,
  deleteEntity,
};
