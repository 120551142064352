import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { forwardRef, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
} from "react-native";
import EditorWrapper from "../../Components/EditorWrapper";
import LoadingPlaceholder from "../../Components/LoadingPlaceholder";
import ToothFairyOutputModal from "../../Components/ToothFairyComponents/ToothFairyOutputModal";
import Constants from "../../Constants";
import SettingsMain from "./Components/SettingsMain";
import SettingsToolbar from "./Components/SettingsToolbar";

const TestAnExampleScreen = forwardRef(
  (
    {
      isInactive,
      user,
      handleParse,
      onNERCreation,
      handleOnChange,
      handleNewDocument,
      handleAnnotation,
      editorState,
      nlpConfig,
      setNlpConfig,
      envConfig,
      initialState,
      editorReducer,
      handleTraining,
      nlpData,
      parseInProgress,
      storeInProgress,
      isRefreshRequired,
      value,
      setValue,
      setEditor,
      triggerNERTransformation,
      onAnalysisCompleted,
      NERStyles,
      IntentStyles,
      createAPIInProgress,
      onConfirmationForTraining,
      documentId,
      onSocketConnectionChange,
      onSocketSyncChange,
      storeInError,
      isEditorLoaded,
      isSaved,
      isParser,
      setIsParser,
      routeToParsing,
      isTrainerNoDocumentId,
      setIsTrainerNoDocumentId,
      isDirty,
      handleEditorError,
      isOutputUpToDate,
      wordsCount,
      charactersCount,
      payload,
      trainingData,
      workspaceid,
      setIsValidationData,
      isValidationData,
    },
    ref
  ) => {
    const dimensions = useWindowDimensions();
    const maxDocHeight = dimensions.height - 100;
    const { state } = AppStateManager.useAppStateManager();
    const [isOutputExpanded, setIsOutputExpanded] = useState(true);
    const [isTrDataModalOpen, setIsTrDataModalOpen] = useState(false);
    const { currentTheme } = AppTheme.useTheme();
    const _styles = styleGenerator({
      currentTheme,
      flexDirection: "column",
      isOutputExpanded,
      maxDocHeight,
    });
    const [formatSwitch, setFormatSwitch] = useState(false);
    const [categoriesSwitch, setCategoriesSwitch] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const _editorStyles = useMemo(() => {
      return {
        wrapperStyle: {
          // backgroundColor: currentTheme?.theme?.softBackgroundColor,
          marginBottom: 10,
          flex: 1,
          padding: 10,
          borderWidth: 1,
          borderRadius: 10,
          borderColor: currentTheme?.theme?.border_color,
        },
        style: {
          overflowY: Platform.OS === "web" ? "auto" : "scroll",
          flex: 1,
          zIndex: -1,
          marginTop: -20,
          fontFamily: "normalFont",
        },
        titleStyle: {
          fontSize: currentTheme?.theme?.base_font_size,
        },
      };
    }, [currentTheme]);
    const { t } = useTranslation();
    const handleShowOutput = () => {
      setIsModalOpen(true);
    };
    const handleShowTrData = () => {
      setIsTrDataModalOpen(true);
    };
    const handleOutputResize = () => {
      setIsOutputExpanded(!isOutputExpanded);
    };
    const isMainParsingFnDisabled =
      parseInProgress ||
      !state?.isWorkspaceReady ||
      (!isParser && !documentId && !isTrainerNoDocumentId);
    const passageComponent = (
      <View style={{ flex: 1 }}>
        {parseInProgress && (
          <LoadingPlaceholder
            style={{
              alignSelf: "center",
              position: "absolute",
              top: "50%",
              zIndex: 1,
            }}
            visible={true}
            caption="Parsing..."
          />
        )}
        <EditorWrapper
          forceTitle={false}
          words={wordsCount}
          characters={charactersCount}
          maxWords={200}
          maxCharacters={1000}
          onError={handleEditorError}
          isPlaceHolderEnabled
          placeholder={t("DocumentName")}
          editMode
          {..._editorStyles}
          onSocketConnectionChange={onSocketConnectionChange}
          handleParse={handleParse}
          initialState={initialState}
          editorReducer={editorReducer}
          editorState={editorState}
          handleOnChange={handleOnChange}
          isRefreshRequired={isRefreshRequired}
          isToolBarVisible={formatSwitch}
          isHTMLPastingAllowed
          setEditor={setEditor}
          value={value}
          setValue={setValue}
          nlpConfig={nlpConfig}
          envConfig={envConfig}
          onAnalysisCompleted={onAnalysisCompleted}
          documentId={documentId}
          NERStyles={NERStyles}
          IntentStyles={IntentStyles}
          isNERToolBarDisabled={isParser}
          ref={ref}
          parseMode={isParser}
          readOnly={isMainParsingFnDisabled}
          autoFocus
        />
      </View>
    );

    const trainerComponent = !createAPIInProgress ? (
      <EditorWrapper
        autoFocus
        forceTitle={false}
        words={wordsCount}
        isInactive={isInactive}
        characters={charactersCount}
        maxWords={200}
        maxCharacters={1000}
        placeholder={t("DocumentNameTrain")}
        editMode
        {..._editorStyles}
        wrapperStyle={{ flex: 1 }}
        ref={ref}
        handleParse={handleParse}
        initialState={initialState}
        editorReducer={editorReducer}
        editorState={editorState}
        onError={handleEditorError}
        handleOnChange={handleOnChange}
        handleAnnotation={handleAnnotation}
        isRefreshRequired={isRefreshRequired}
        isToolBarVisible={formatSwitch}
        isHTMLPastingAllowed
        isNERToolBarDisabled={isParser}
        value={value}
        setValue={setValue}
        NERStyles={NERStyles}
        IntentStyles={IntentStyles}
        setEditor={setEditor}
        parseMode={isParser}
        trainMode={categoriesSwitch}
        nlpConfig={nlpConfig}
        envConfig={envConfig}
        triggerNERTransformation={triggerNERTransformation}
        onAnalysisCompleted={onAnalysisCompleted}
        documentId={documentId}
        onSocketConnectionChange={onSocketConnectionChange}
        onSocketSyncChange={onSocketSyncChange}
        readOnly={isMainParsingFnDisabled}
      />
    ) : (
      <LoadingPlaceholder
        style={{
          alignSelf: "center",
          position: "absolute",
          top: "50%",
          zIndex: 1,
        }}
        visible={true}
        caption="Creating document..."
      />
    );
    return (
      <AppBaseScreen
        contentContainerStyle={{ height: "100%", minHeight: 500 }}
        isScrollable={true}
      >
        <SettingsMain
          handleParse={handleParse}
          handleShowOutput={handleShowOutput}
          handleShowTrData={handleShowTrData}
          setIsValidationData={setIsValidationData}
          isValidationData={isValidationData}
          routeToParsing={routeToParsing}
          setIsParser={setIsParser}
          handleTraining={handleTraining}
          handleNewDocument={handleNewDocument}
          parseInProgress={parseInProgress}
          storeInError={storeInError}
          storeInProgress={storeInProgress}
          documentId={documentId}
          isSaved={isSaved}
          isParser={isParser}
          setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
          isTrainerNoDocumentId={isTrainerNoDocumentId}
          isDirty={isDirty}
          nlpData={nlpData}
          isMainParsingFnDisabled={isMainParsingFnDisabled}
        />
        {documentId || isParser || isTrainerNoDocumentId ? (
          <>
            <View style={_styles.container}>
              <KeyboardAvoidingView
                behavior="padding"
                enabled={Platform.OS === "android"}
                contentContainerStyle={{ flex: 1 }}
                keyboardVerticalOffset={Platform.select({
                  ios: -50,
                  android: 20,
                })}
                style={_styles.inputContainer}
              >
                {Constants.config.testAnExample
                  .isCategoriesAndToolbarVisibile && (
                  <SettingsToolbar
                    categoriesSwitch={categoriesSwitch}
                    formatSwitch={formatSwitch}
                    setCategoriesSwitch={setCategoriesSwitch}
                    setFormatSwitch={setFormatSwitch}
                  />
                )}
                {isParser && passageComponent}

                {!isParser && trainerComponent}
              </KeyboardAvoidingView>
              {/* {isParser && (
                <ToothFairyOutput
                  handleOutputResize={handleOutputResize}
                  isOutputExpanded={isOutputExpanded}
                  parsingInProgress={parseInProgress}
                  data={nlpData}
                  subCaption={"TrainerSubCaption"}
                />
              )} */}

              <ToothFairyOutputModal
                path={"parse"}
                checkLength={false}
                isOutputUpToDate={isOutputUpToDate}
                handleOutputResize={handleOutputResize}
                isOutputExpanded={isOutputExpanded}
                parsingInProgress={parseInProgress}
                data={nlpData}
                requestPayload={payload}
                subCaption={"TrainerSubCaption"}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
              <ToothFairyOutputModal
                checkLength={false}
                method="GET"
                path={"trainingdata"}
                isOutputUpToDate={true}
                handleOutputResize={() => {}}
                isOutputExpanded={true}
                parsingInProgress={false}
                data={trainingData}
                requestPayload={{
                  pathParams: {
                    id: documentId || "Undefined - document must be saved",
                    workspaceId: workspaceid,
                    trainingData: trainingData,
                  },
                }}
                subCaption={"noTrainingDataInDocumentCaption"}
                isModalOpen={isTrDataModalOpen}
                setIsModalOpen={setIsTrDataModalOpen}
              />
            </View>
          </>
        ) : isEditorLoaded ? (
          <AppPlaceholderView
            wrapperStyle={{
              width: 300,
              height: 200,
              marginVertical: "auto",
              marginHorizontal: "auto",
            }}
            mainCaptionStyle={{
              textAlign: "center",
            }}
            size={60}
            icon={AppAssets.icons.decision}
            mainCaption="TrainerPlaceholder"
          />
        ) : (
          <LoadingPlaceholder
            style={{
              alignSelf: "center",
              position: "absolute",
              top: "50%",
              zIndex: 1,
            }}
            visible={true}
            caption="Loading..."
          />
        )}
      </AppBaseScreen>
    );
  }
);

const styleGenerator = ({
  currentTheme,
  flexDirection,
  isOutputExpanded,
  maxDocHeight,
}) => {
  return StyleSheet.create({
    title: {
      fontSize: currentTheme?.theme?.base_font_size,
      fontWeight: "bold",
      color: currentTheme?.theme?.white,
    },
    container: {
      flexDirection: flexDirection,
      flex: 1,
      overflow: Platform.OS === "web" ? "auto" : "scroll",
      justifyContent: "space-between",
      paddingHorizontal: 5,
      paddingTop: 10,
      borderWidth: 1,
      borderRadius: 10,
      marginHorizontal: 20,
      marginTop: 10,
      borderColor: currentTheme?.theme?.transparent,
    },
    inputContainer: {
      flex: flexDirection === "row" ? 0.5 : 1,
      paddingHorizontal: flexDirection === "row" ? 20 : 10,
      marginBottom: 20,
      minWidth: "100%",

      backgroundColor: currentTheme?.theme?.transparent,
    },
    outputContainer: {
      backgroundColor: currentTheme?.theme?.primary,

      paddingHorizontal: flexDirection === "row" ? 20 : 10,
      marginVertical: flexDirection === "row" ? 0 : 20,
      overflow: "auto",
      flex: 0.3,
      flexBasis: 200,
      minWidth: "100%",
    },
  });
};

export default TestAnExampleScreen;
