import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import envConfig from "../../../envConfig";

const sendEmail = async ({ userEmail, workspaceId, subject, message }) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/emails/sendEmail`;
  const requestBody = {
    type: "enquiry-enterprise-upgrade",
    to:
      envConfig.REACT_APP_ENV === "dev"
        ? "noreply@toothfairy.link"
        : "noreply@toothfairyai.com",
    parameters: {
      workspaceId,
      from: userEmail,
      message,
      subject,
    },
    subject,
    from: userEmail,
  };
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    requestBody,
    true,
    null,
    payload
  );
};

export default {
  sendEmail,
};
