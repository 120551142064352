import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";
import LoadingPlaceholder from "../../../Components/LoadingPlaceholder";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import {
  isLargerScreen,
  isLargeScreen,
  isVeryLargeScreen,
} from "../../MainViewContainer";
import useWorkspaces from "../../../Hooks/useWorkspaces";

const GeneratorConfig = ({
  scope,
  styles,
  agent,
  setScope,
  setStyles,
  setPrompt,
  setAgent,
  prompt,
  isInModal = false,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { state } = AppStateManager.useAppStateManager();
  const { getPromptsByAgent } = useWorkspaces();
  const configData = state?.generationConfigData;
  const contentCreators = state?.agents?.filter(
    (u) => u?.agentType === "ContentCreator"
  );

  const coreComponent = (
    <>
      {(isLargerScreen() || isInModal) && (
        <>
          {contentCreators && (
            <AppDropDown
              showTextInputLabel
              label="Agent"
              inputLabelStyle={{
                marginLeft: 0,
                marginRight: 10,
                marginTop: 10,
              }}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: 250,
                minWidth: 170,
              }}
              wrapperContainer={{
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 0,
                marginRight: 15,
              }}
              isElevated={false}
              dropdownIconColor="transparent"
              // uppercase only first letter
              // label={}
              items={contentCreators || []}
              propertyLabel="label"
              propertyValue="id"
              selectedValue={agent}
              onValueChange={(itemValue, itemIndex) => {
                setAgent(itemValue);
              }}
            />
          )}

          <AppDropDown
            showTextInputLabel
            label="Prompt  "
            inputLabelStyle={{
              marginLeft: 0,
              marginRight: 10,
              marginTop: 10,
            }}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: 250,
              minWidth: 170,
            }}
            wrapperContainer={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: 0,
            }}
            isElevated={false}
            dropdownIconColor="transparent"
            // uppercase only first letter
            // label={}
            // change
            items={getPromptsByAgent(agent)}
            propertyLabel="name"
            propertyValue="id"
            selectedValue={scope}
            onValueChange={(itemValue, itemIndex) => {
              setScope(itemValue);
              setPrompt(itemValue);
            }}
          />
        </>
      )}
    </>
  );
  return configData?.styles?.length > 0 ? (
    isInModal ? (
      coreComponent
    ) : (
      <View
        style={{
          flexDirection: "row",
        }}
      >
        {coreComponent}
      </View>
    )
  ) : (
    <LoadingPlaceholder
      style={{
        height: 43,
      }}
      size={20}
      backgroundColor={currentTheme?.theme?.transparent}
      visible={true}
    />
  );
};

export default GeneratorConfig;
