import { DataStore } from "@aws-amplify/datastore";
import { OutputStream } from "../../models";

const createOutputStream = async (object) => {
  return DataStore.save(new OutputStream(object));
};

const updateOutputStream = async (id, args) => {
  const original = await DataStore.query(OutputStream, id);
  return DataStore.save(
    OutputStream.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deleteOutputStream = async (id) => {
  return DataStore.delete(OutputStream, (ent) => ent.id("eq", id));
};

export default {
  createOutputStream,
  updateOutputStream,
  deleteOutputStream,
};
