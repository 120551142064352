import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppCard from "@toothfairy/shared-ui/AppCard";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  useWindowDimensions,
} from "react-native";
import {
  isFirstTrialStarted,
  isUserEnabledToOperate,
  isUserWithNoPaymentMethod,
} from "../../../App";
import AppJoyRide from "../../Components/Joyride";
import PageTitle from "../../Components/PageTitle";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairyModal from "../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairySwitch from "../../Components/ToothFairyComponents/ToothFairySwitch";
import Constants from "../../Constants";
import useUserHelperPreferences from "../../Hooks/useUserHelperPreferences";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory } from "../../Router";
import { isLargeScreen } from "../MainViewContainer";
import WorkspaceChip from "./Components/WorkspaceChip";
import WorkspaceModalContent from "./Components/WorkspaceModalContent";

const steps = [
  {
    target: ".create_workspace",
    content: "To get started, click here to create a new workspace.",
    placement: "left",
    disableBeacon: true,
  },
  {
    target: ".select_workspace",
    disableBeacon: true,
    content:
      "Once you create a workspace, you can select it here. All the workspaces you will be invited to will be displayed here as well!",
  },
];

const WorkspacesScreen = ({
  workspaces,
  handleWorkSpaceCreation,
  handleWorkspaceEdit,
  handleWorkspaceDeletion,
  handleWorkspaceSelection,
  handleFormSubmit,
  initialValues,
  formValidationSchema,
  setIsModalOpen,
  setInitialValues,
  isModalOpen,
  modalContentKey,
  setModalContentKey,
  selectedWorkspace,
  setSelectedWorkspace,
  setShareInputValue,
  shareInputValue,
  handleMembersChange,
  loadMembers,
  shareMembers,
  cancelPlanChange,
  targetPlan,
  setTargetPlan,
  workspaceMutationInProgress,
  onPlanSelection,
  onValuesChange,
  onSubmit,
  forceEnabled,
  ...props
}) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const dimensions = useWindowDimensions();
  const [isMouseOverNewWorkspace, setIsMouseOverNewWorkspace] = useState(false);
  const isNewWorkspaceDisabled =
    isFirstTrialStarted() && !isUserEnabledToOperate();
  const { user } = AppUser.useUser();
  const { t } = useTranslation();
  const [showShareInput, setShareInput] = useState(false);
  const coreViewHeight = dimensions.height - 200;
  const _styles = styleGenerator(
    currentTheme,
    coreViewHeight,
    dimensions,
    isLargeScreen()
  );
  const showBillingRequired = false;
  // (isUserWithNoPaymentMethod() || !isBillingAddressSet()) && !isSSO();
  const history = useHistory();
  const [includeDisabled, setIncludeDisabled] = useState(false);
  const {
    returnCleanedWorkspaces,
    getDisabledWorkspaces,
    isWorkspaceDisabled,
    isWorkspaceInCreation,
  } = useWorkspaces();
  const { updateUserHelperPreferences, isUserHelperToBeDisplayed } =
    useUserHelperPreferences();
  const disabledWorkspacesCount = getDisabledWorkspaces(workspaces)?.length;
  // make only the first letter upper case from a string
  const capitalizeFirstLetter = (string) => {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <AppBaseScreen isScrollable={false}>
      <AppJoyRide
        handleCallback={(e) => {
          if (e?.status == "finished" || e?.status == "skipped") {
            updateUserHelperPreferences(user, { workspaceTourCompleted: true });
          }
        }}
        run={isUserHelperToBeDisplayed("workspaceTourCompleted")}
        steps={steps}
      />

      <View
        style={{ paddingHorizontal: 20, paddingVertical: 10, height: "100%" }}
      >
        <View>
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: -5,
            }}
          >
            <PageTitle
              style={{ marginTop: 10 }}
              accessibilityLabel="workspaces-title"
            >
              Workspaces
            </PageTitle>
            <div
              className="create_workspace"
              onClick={handleWorkSpaceCreation}
              style={{
                cursor: "pointer",
              }}
            >
              <ToothFairyButton
                paddingLeft={10}
                paddingRight={10}
                style={{ marginTop: 10 }}
                isDisabled={isNewWorkspaceDisabled}
                title="New workspace"
                onPress={handleWorkSpaceCreation}
              />
            </div>

            {disabledWorkspacesCount > 0 && (
              <ToothFairySwitch
                wrapperStyle={{ marginTop: 10 }}
                accessibilityLabel="inactiveWorkspacesSwitcher"
                label={`${t("Include disabled")} (${disabledWorkspacesCount})`}
                isTicked={includeDisabled}
                displayIsTicked={true}
                onChange={() => {
                  setIncludeDisabled(!includeDisabled);
                }}
              />
            )}
          </View>
          {showBillingRequired && (
            <AppText
              numberOfLines={4}
              color={currentTheme?.theme?.orange}
              fontWeight="bold"
              clickableViewWrapperStyle={{
                marginTop: 20,
              }}
              isClickable
              onPress={() => {
                history.push("/profile/billing");
              }}
            >
              {isFirstTrialStarted()
                ? "BillingSetupRequiredWhileOnTrial"
                : isUserWithNoPaymentMethod()
                ? "GenericBillingSetupRequired"
                : "GenericBillingAddressSetupRequired"}
            </AppText>
          )}
        </View>
        <View style={_styles.list}>
          <View
            style={{
              flexWrap: "wrap",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
            items={workspaces}
            contentContainerStyle={{
              padding: 10,
            }}
          >
            {workspaces?.length > 0 ? (
              returnCleanedWorkspaces(workspaces, includeDisabled)?.map(
                (item) => (
                  <div className="select_workspace">
                    <AppCard
                      isHoverable
                      accessibilityLabel={`workspace-card-${item.id}`}
                      key={item.id}
                      headerContainerStyle={{
                        alignItems: "center",
                      }}
                      style={{
                        marginHorizontal: 20,
                        borderColor: currentTheme?.theme?.app_outline_color,
                      }}
                      onCardPress={() => {
                        if (isWorkspaceDisabled(item))
                          history.push("/profile/billing");
                        else handleWorkspaceSelection(item?.id);
                      }}
                      disabled={isWorkspaceInCreation(item)}
                      cardWidth={300}
                      cardHeight={260}
                      heading={item?.title}
                      shadowColor={currentTheme?.theme?.transparent}
                      footerHeadingStyle={{
                        fontSize: currentTheme.theme.small_font_size,
                        fontWeight: "normal",
                        paddingBottom: 10,
                        maxWidth: 170,
                      }}
                      // rightIconOnPress={() => {
                      //   user.id === item?.userID && handleWorkspaceEdit(item);
                      // }}
                      headingStyle={{
                        maxWidth: 240,
                        fontSize: 18,
                      }}
                      subFooterHeadingStyle={{
                        maxWidth: 170,
                      }}
                      rightIconSize={20}
                      rightIconDisplayed
                      isRightIconClickable={
                        item?.disabled !== true &&
                        new Date(item?.activeUntil) > Date.now()
                      }
                      // rightIconName={
                      //   user.id === item?.userID && AppAssets.icons.info
                      // }
                      childrenViewStyle={{
                        justifyContent: "flex-end",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          flexWrap: "wrap",
                          height: 110,
                        }}
                      >
                        {/* <View style={{ flexDirection: "row" }}> */}
                        {item?.userID === user.id && (
                          <WorkspaceChip
                            label={"Root"}
                            leftIcon={AppAssets.icons.admin}
                            backgroundColor={currentTheme?.theme?.primary}
                            fontColor={currentTheme?.theme?.white}
                          />
                        )}

                        {item?.users?.length > 1 && (
                          <WorkspaceChip
                            label={`${item?.users?.length} members`}
                            leftIcon={AppAssets.icons.users}
                          />
                        )}
                        <WorkspaceChip
                          label={
                            capitalizeFirstLetter(item?.domain) || "Default"
                          }
                          leftIcon={AppAssets.icons.writer}
                        />
                        {/* </View> */}

                        <View style={{ flexDirection: "row" }}>
                          <WorkspaceChip
                            label={capitalizeFirstLetter(
                              item?.subscriptionType
                            )}
                            leftIcon={AppAssets.icons.cost}
                          />
                        </View>
                        {user?.stripeCustomerData?.subscriptionIdOnTrial !=
                          null &&
                          user?.stripeCustomerData?.subscriptionIdOnTrial ===
                            item?.subscriptionId && (
                            <WorkspaceChip
                              label={"Trial"}
                              leftIcon={AppAssets.icons.trial}
                              backgroundColor={currentTheme?.theme?.orange}
                              fontColor={currentTheme?.theme?.white}
                            />
                          )}
                        <WorkspaceChip
                          label={capitalizeFirstLetter(item?.baseModel)}
                          leftIcon={AppAssets.icons.enableAI}
                        />

                        {item?.activeUntil &&
                          (item?.disabled === true ||
                            new Date(item?.activeUntil) < Date.now()) && (
                            <WorkspaceChip
                              isClickable
                              isHoverable={false}
                              onPress={() => {
                                history.push("/profile/billing");
                              }}
                              backgroundColor={currentTheme?.theme?.red}
                              fontColor={currentTheme?.theme?.white}
                              label={`Payment ${
                                item?.paymentFailed ? "failed" : "required"
                              }`}
                              leftIcon={AppAssets.icons.lock}
                            />
                          )}
                        {!item?.activeUntil && (
                          <WorkspaceChip
                            showProgressIndicator
                            label={"Setting up"}
                            backgroundColor={currentTheme?.theme?.purple}
                            fontColor={currentTheme?.theme?.white}
                            leftIcon={AppAssets.icons.progress}
                          />
                        )}
                      </View>
                    </AppCard>
                  </div>
                )
              )
            ) : (
              <div
                className="select_workspace"
                style={{
                  margin: "auto",
                }}
              >
                <TouchableWithoutFeedback
                  disabled={isNewWorkspaceDisabled || showBillingRequired}
                  onPress={
                    !showBillingRequired
                      ? handleWorkSpaceCreation
                      : () => {
                          history.push("/profile/billing");
                        }
                  }
                >
                  <AppPlaceholderView
                    onMouseEnter={() => setIsMouseOverNewWorkspace(true)}
                    onMouseLeave={() => setIsMouseOverNewWorkspace(false)}
                    accessibilityLabel="workspaces-placeholder"
                    fontColor={
                      !isNewWorkspaceDisabled && currentTheme?.theme?.primary
                    }
                    wrapperStyle={{
                      marginHorizontal: "auto",
                      marginTop: 50,
                      borderColor:
                        isMouseOverNewWorkspace && !isNewWorkspaceDisabled
                          ? currentTheme?.theme?.primary
                          : currentTheme?.theme?.border_color,
                      borderWidth: 1,
                      borderRadius: 10,
                      padding: 50,
                      maxWidth: 400,
                      maxHeight: 260,
                      cursor: !isNewWorkspaceDisabled ? "pointer" : "default",
                    }}
                    size={60}
                    // fontColor={currentTheme?.theme?.white}
                    // color={currentTheme?.theme?.white}
                    icon={AppAssets.icons.new}
                    mainCaption={
                      showBillingRequired
                        ? "WorkspacesBillingEmptyPlaceholder"
                        : "WorkspacesEmptyPlaceholder"
                    }
                    subCaption={
                      showBillingRequired
                        ? "WorkspacesBillingRequiredPlaceholderSub"
                        : "WorkspacesEmptyPlaceholderSub"
                    }
                  />
                </TouchableWithoutFeedback>
              </div>
            )}
          </View>
        </View>
        {isModalOpen && (
          <ToothFairyModal
            headerContainerStyle={{
              paddingTop: 10,
              paddingHorizontal: 20,
            }}
            scrollViewStyle={{
              margin: 20,
              width: "95%",
            }}
            modalBackdrop={true}
            modalBackdropStatic={true}
            modalStyle={_styles.modalInput}
            modalTitle={
              modalContentKey === "NEW"
                ? "Create your workspace"
                : modalContentKey === "UPDATE" || modalContentKey === "DELETE"
                ? `Edit workspace: ${selectedWorkspace?.title}`
                : modalContentKey === "BILLING"
                ? "Billing"
                : ""
            }
            isCloseButtonVisible
            closeModal={() => {
              formRef?.current?.resetForm();
              setIsModalOpen(false);
              setInitialValues(Constants.initialWorkspaceValues);
            }}
            isModalVisible={isModalOpen}
            transparent={true}
          >
            <WorkspaceModalContent
              ref={formRef}
              showShareInput={showShareInput}
              setShareInput={setShareInput}
              modalContentKey={modalContentKey}
              handleFormSubmit={handleFormSubmit}
              formValidationSchema={formValidationSchema}
              initialValues={initialValues}
              shareInputValue={shareInputValue}
              setShareInputValue={setShareInputValue}
              handleMembersChange={handleMembersChange}
              setModalContentKey={setModalContentKey}
              setIsModalOpen={setIsModalOpen}
              handleWorkspaceDeletion={handleWorkspaceDeletion}
              loadMembers={loadMembers}
              shareMembers={shareMembers}
              cancelPlanChange={cancelPlanChange}
              targetPlan={targetPlan}
              setTargetPlan={setTargetPlan}
              selectedWorkspace={selectedWorkspace}
              workspaceMutationInProgress={workspaceMutationInProgress}
              onPlanSelection={onPlanSelection}
              onValuesChange={onValuesChange}
              onSubmit={onSubmit}
              forceEnabled
            />
          </ToothFairyModal>
        )}
      </View>
    </AppBaseScreen>
  );
};
const styleGenerator = (props, coreViewHeight, dimensions, isLargeScreen) => {
  return StyleSheet.create({
    title: { paddingRight: 10, flex: 1 },
    submitBotton: {
      marginTop: 20,
      alignSelf: "center",
      minWidth: 200,
    },
    list: {
      zIndex: -1,
      flex: 1,
      marginRight: -5,
      marginLeft: -10,
      overflow: "auto",
      // justifyContent: "space-around",
    },
    workspaceItem: {
      alignSelf: "flex-start",
      flexDirection: "row",
      flex: 1,
      paddingTop: 10,
    },
    modalInput: {
      maxHeight: Math.min(dimensions.height - 40, 680),
      height: "auto",
      marginHorizontal: Platform.OS === "web" && isLargeScreen ? "20%" : "auto",
      marginVertical: "auto",
      width: Platform.OS === "web" && isLargeScreen ? "60%" : "95%",
    },
  });
};

export default WorkspacesScreen;
