import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import { showSideMenu } from "../../../../../App";
import AgentFunctions from "../../../../API/AgentFunctions";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import FunctionSettingAI from "./FunctionSettingsAI";
import FunctionSettingsToolbar from "./FunctionsSettingsToolbar";

const FunctionsList = ({ isFunctionsLoading }) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const { width } = useWindowDimensions();
  const { getPlanConfig } = useWorkspaces();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [showFunctionModal, setShowFunctionModal] = React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedFunctions, setDisplayedFunctions] = React.useState([]);
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { height } = useWindowDimensions();
  const [initialMapValues, setInitialMapValues] = useState({
    name: "",
    description: "",
    requestType: "",
    authorisationType: "",
    url: "",
    dbScript: "",
    isCallbackFn: false,
    isDatabaseScript: false,
    scope: null,
    paramsAsPath: false,
  });
  const columns = [
    {
      title: "ID",
      width: 0.00001,
    },
    {
      title: "Name",
      width: 0.25,
    },
    {
      title: "Description",
      width: 0.25,
    },
    {
      title: "Type",
      width: 0.1,
    },
    // {
    //   title: "Authorisation Type",
    // },
    {
      title: "Endpoint",
      width: 0.3,
    },
    {
      title: "Actions",
      isAction: true,
      width: 0.1,
    },
  ];
  const onFunctionCreated = useCallback(
    (event) => {
      if (event === "success") {
        AppToasty.show(t("functionCreationSuccessCaption"), {
          type: "success",
        });
        loadAgentsFunctions(state?.activeWorkspaceID);
        setShowFunctionModal(false);
      } else
        AppToasty.show(t("functionCreationFailedCaption"), {
          type: "danger",
        });
      formRef?.current?.resetForm();
      setShowFunctionModal(false);
      setSelectedIndex(null);
    },
    [
      AppToasty,
      t,
      setShowFunctionModal.formRef?.current,
      loadAgentsFunctions,
      state?.activeWorkspaceID,
    ]
  );
  const loadAgentsFunctions = async (workspaceID) => {
    const functionsData = await AgentFunctions.getAgentFunctionsByWorkspaceID({
      workspaceID,
    });
    let functions = functionsData?.data?.functionsByWorkspace?.items;
    functions =
      functions
        ?.map((u) => {
          if (u?._deleted !== true)
            return {
              ...u,
              parameters: JSON.parse(u.parameters),
              headers: u?.headers && JSON.parse(u.headers),
              staticArgs: u?.staticArgs && JSON.parse(u.staticArgs),
              model: u?.model === "gpt-3.5-turbo-0613" ? "speed" : "accuracy",
              // authorisationType: u?.authorisationType?.toUpperCase(),
              requestType: u?.requestType?.toUpperCase(),
            };
        })
        ?.filter((u) => u != null) || [];
    dispatch("SET_AGENTS_FUNCTIONS", functions || []);

    setDisplayedFunctions(functions);
  };
  useEffect(() => {
    loadAgentsFunctions(state?.activeWorkspaceID);
  }, [state?.activeWorkspaceID]);
  useEffect(() => {
    setDisplayedFunctions([...state?.agentsFunctions]);
  }, [JSON.stringify([...state?.agentsFunctions])]);

  const functionItemCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        key={Date.now().toString()}
        modalStyle={{
          maxHeight: isLargeScreen()
            ? initialMapValues?.isChatFn
              ? 750
              : initialMapValues?.isHtmlFn
              ? 580
              : 900
            : 500,
          height: "95%",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showFunctionModal}
        setIsModalOpen={setShowFunctionModal}
        modalTitle={isEditMode ? "functionEditTitle" : "functionCreationTitle"}
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowFunctionModal(false);
          setSelectedIndex(null);
        }}
      >
        <FunctionSettingAI
          ref={formRef}
          initialMapValues={initialMapValues}
          onFunctionCreated={onFunctionCreated}
          isEdit={isEditMode}
          selectedId={displayedFunctions[selectedIndex]?.id}
        />
      </ToothFairyModal>
    );
  }, [
    showFunctionModal,
    setShowFunctionModal,
    initialMapValues,
    isEditMode,
    selectedIndex,
    displayedFunctions,
  ]);
  const identifyOptByID = (id) => {
    return state.agentsFunctions.find((u) => u.id === id);
  };
  const handleFunctionItemSelection = ({ item, index }) => {
    setSelectedIndex(index);
    setIsEditMode(true);
    let extendedItem = identifyOptByID(item.id);
    setInitialMapValues({
      ...extendedItem,
      parameters: extendedItem?.parameters || {},
      authorisationType: extendedItem?.authorisationType?.toUpperCase(),
      headers: extendedItem.headers || {},
      staticArgs: extendedItem.staticArgs || {},
    });

    setShowFunctionModal(true);
  };
  const handleFunctionItemDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        const _id = displayedFunctions[selectedIndex]?.id;

        const _version = displayedFunctions[selectedIndex]?._version;
        const result = await AgentFunctions.deleteAgentFunction(_id, _version);
        dispatch("SET_AGENTS_FUNCTIONS", [
          ...state?.agentsFunctions.filter((u) => u.id !== _id),
        ]);
        AppToasty.show(t("functionDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      console.log(error);
      AppToasty.show(t("functionDeletionErrorCaption"), {
        type: "danger",
      });
    }
  };
  const handleSearch = async (name, description, url, requestType) => {
    // find items in the list which cointain either the sourceText or targetText
    const filteredItems = state?.agentsFunctions.filter((item) => {
      return (
        (!name ||
          (item?.name &&
            item.name.toLowerCase().includes(name.toLowerCase()))) &&
        (!url ||
          (item?.url && item.url.toLowerCase().includes(url.toLowerCase())) ||
          (item?.htmlUrl &&
            item.htmlUrl.toLowerCase().includes(url.toLowerCase())))
      );
    });
    setDisplayedFunctions(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleCreation = (id) => {
    if (id === "NEW_API_FUNCTION")
      setInitialMapValues({
        name: "",
        description: "",
        requestType: "GET",
        authorisationType: "NONE",
        url: "",
        model: "speed",
        headers: {},
        parameters: {
          type: "object",
          properties: {
            location: {
              type: "string",
              description: "The location, e.g. Melbourne, VIC",
            },
            unit: {
              type: "string",
              enum: ["celsius"],
            },
          },
          required: ["location"],
        },
        staticArgs: {},
        isDatabaseScript: false,
        scope: null,
        paramsAsPath: false,
      });
    else if (id === "NEW_DB_FUNCTION")
      setInitialMapValues({
        name: "",
        description: "",
        dbScript: "",
        parameters: {
          type: "object",
          properties: {
            location: {
              type: "string",
              description: "The location, e.g. Melbourne, VIC",
            },
            unit: {
              type: "string",
              enum: ["celsius"],
            },
          },
          required: ["location"],
        },
        isDatabaseScript: true,
        scope: null,
      });
    else if (id === "NEW_HTML_FUNCTION")
      setInitialMapValues({
        name: "",
        description: "",
        htmlScript: "",
        htmlUrl: "",
        htmlFormOnSuccessfulSubmitMessage: "",
        htmlFormOnFailedSubmitMessage: "",
        isHtmlFn: true,
        isHtmlRedirectFn: true,
        scope: null,
        paramsAsPath: false,
      });
    else if (id === "NEW_CHAT_FUNCTION")
      setInitialMapValues({
        name: "",
        description: "",
        htmlScript: "",
        htmlUrl: "",
        htmlFormOnSuccessfulSubmitMessage: "",
        htmlFormOnFailedSubmitMessage: "",
        isChatFn: true,
        isDatabaseScript: false,
        scope: null,
        paramsAsPath: false,
        chatScript: "",
        chatAction: "suggestion",
      });

    setIsEditMode(false);
    setShowFunctionModal(true);
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"functionItemCancelConfirmation"}
        caption="functionItemCancelCaption"
        subCaption="functionItemCancelSubCaption"
        confirmCaption={"functionItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={() => handleFunctionItemDeletion()}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [setShowChoice, showChoice, selectedItem, setSelectedItem]);

  return (
    <View
      style={{
        paddingLeft: 10,
        flex: 1,
        width: showSideMenu() ? width - (showSideMenu() ? 410 : 220) : "100%",
        minWidth: "100%",
      }}
    >
      {getPlanConfig()?.isFunctionsEnabled ? (
        <>
          <View>
            <FunctionSettingsToolbar
              handleSearch={handleSearch}
              handleCreation={handleCreation}
              isDBFunctionsEnabled={getPlanConfig()?.isDBFunctionsEnabled}
              isHtmlFunctionsEnabled={getPlanConfig()?.isHtmlFunctionsEnabled}
            />
          </View>
          {isFunctionsLoading ? (
            <AppActivityIndicator
              backgroundColor={currentTheme?.theme?.softBackgroundColor}
              visible={true}
              containerStyle={{
                backgroundColor: currentTheme?.theme?.transparent,
                minHeight: 250,
              }}
            />
          ) : (
            <>
              {displayedFunctions.length > 0 ? (
                <AppTable
                  actionButtons
                  handleDocumentSelection={handleFunctionItemSelection}
                  handleDocumentDeletion={(item, index) => {
                    setSelectedItem(item);
                    setSelectedIndex(index);
                    setShowChoice(true);
                  }}
                  style={{
                    zIndex: -1,
                    height: height - 180,
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                  headers={columns}
                  items={displayedFunctions
                    ?.slice(
                      (currentPage - 1) * pageSize,
                      (currentPage - 1) * pageSize + pageSize
                    )
                    ?.map((u) => {
                      return {
                        id: u?.id,
                        name: u?.name || " ",
                        description: u?.description || " ",
                        requestType: u?.isDatabaseScript
                          ? "DB"
                          : u?.isHtmlFn
                          ? "HTML"
                          : u?.isChatFn
                          ? "CHAT"
                          : u?.requestType?.toUpperCase() || " ",
                        url: u?.isDatabaseScript
                          ? u?.dbScript
                          : u?.isHtmlFn
                          ? u?.htmlUrl
                          : u?.url || " ",
                        actions: " ",
                      };
                    })}
                />
              ) : (
                <AppPlaceholderView
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    marginVertical: 60,
                    marginHorizontal: "auto",
                    zIndex: -1,
                    flex: 1,
                  }}
                  size={80}
                  icon={AppAssets.icons.orbit}
                  mainCaption="EmptyPlaceholder"
                  subCaption="FunctionSubCaption"
                />
              )}
              {displayedFunctions?.length > pageSize && (
                <AppPagination
                  onPageChange={handlePageChange}
                  totalCount={displayedFunctions?.length}
                  siblingCount={1}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  wrapperStyle={{
                    marginTop: 10,
                    alignSelf: "flex-end",
                  }}
                />
              )}
            </>
          )}
        </>
      ) : (
        <AppPlaceholderView
          wrapperStyle={{
            width: "100%",
            height: "100%",
            marginVertical: 60,
            marginHorizontal: "auto",
            flex: 1,
          }}
          size={80}
          icon={AppAssets.icons.orbit}
          mainCaption="EmptyPlaceholder"
          subCaption="SettingsSubCaption"
        />
      )}
      {functionItemCreationComponent}
      {_coreChoice}
    </View>
  );
};

export default FunctionsList;
