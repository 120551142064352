import AppToast from "@toothfairy/shared-ui/AppToast";
import React from "react";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import ChartingSettingsView from "../Components/ChartingSettingsView";
import workspacesHelper from "../../../API/Workspaces/workspacesHelper";

const ChartingSettingsContainer = () => {
  const { getActiveWorkspace } = useWorkspaces();
  const AppToasty = AppToast.useToast();
  const handleSubmit = async (v) => {
    console.log("v", v);
    try {
      result = await workspacesHelper.updateWorkspace(getActiveWorkspace().id, [
        {
          property: "customChartingConfig",
          value: JSON.stringify(v),
        },
      ]);
      AppToasty.show("Charting palette updated", {
        type: "success",
      });
    } catch (e) {
      console.log("e", e);
      AppToasty.show("Charting palette could not be updated", {
        type: "danger",
      });
    }
  };

  return <ChartingSettingsView onSubmit={handleSubmit} />;
};

export default ChartingSettingsContainer;
