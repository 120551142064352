import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import hostingsHelper from "../../../../API/Hostings/hostingsHelper";
import secretGenerator from "../../../../API/SecretGenerator";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import HostingSettingsAIView from "../SubComponents/HostingSettingsAIView";

const HostingsSettingsAI = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onHostingCreated,
      selectedId,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();

    const { state, dispatch } = AppStateManager.useAppStateManager();
    const { getActiveWorkspace } = useWorkspaces();
    const [isSaveInProgress, setIsSaveInProgress] = React.useState(false);
    const validationSchema = AppForms.yup.object().shape({
      type: AppForms.yup.string().required().label("Provider Type"),
      name: AppForms.yup
        .string()
        .required()
        .label("Name")
        .matches(
          /^[a-zA-Z0-9_]*$/,
          "Only alphanumeric characters and underscores are allowed without spaces."
        )
        .max(64, "Too long, maximum of 64 characters are allowed."),
      description: AppForms.yup.string().label("Description").max(255),
      tokenSecret: AppForms.yup.string().when("type", {
        is: (type) => type === "together" || type === "replicate",
        then: AppForms.yup.string().required().label("API Key"),
        otherwise: AppForms.yup.string().notRequired().nullable(),
      }),
      clientId: AppForms.yup.string().when("type", {
        is: (type) => type === "oauth",
        then: AppForms.yup.string().required().label("Client ID"),
        otherwise: AppForms.yup.string().notRequired(),
      }),
      clientSecret: AppForms.yup.string().when("type", {
        is: (type) => type === "oauth",
        then: AppForms.yup.string().required().label("Client Secret"),
        otherwise: AppForms.yup.string().nullable().notRequired(),
      }),
      scope: AppForms.yup.string().notRequired(),
      grantType: AppForms.yup.string().when("type", {
        is: (type) => type === "oauth",
        then: AppForms.yup.string().required().label("Grant Type"),
        otherwise: AppForms.yup.string().notRequired(),
      }),
      authorizationBaseUrl: AppForms.yup.string().when("type", {
        is: (type) => type === "oauth",
        then: AppForms.yup.string().required().label("Authorization URL"),
        otherwise: AppForms.yup.string().notRequired(),
      }),
    });

    const handleDeletion = async () => {
      try {
        console.log("result of deletion", opt);
        const result = await hostingsHelper.deleteHosting(opt.id);
        dispatch("SET_HOSTINGS", [
          ...state?.hostings?.filter((u) => u?.id !== opt.id),
        ]);
        navigateBack();
        AppToasty.show(`Provider deleted`, {
          type: "success",
        });
      } catch (error) {
        console.log("Error deleting auth", error);
        AppToasty.show(`Provider could not be deleted`, {
          type: "danger",
        });
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };
    const identifyOptByID = (id) => {
      return state?.hostings.find((u) => u?.id === id);
    };
    const handleHostingItemSave = async (v) => {
      setIsSaveInProgress(true);
      v.workspaceID = getActiveWorkspace()?.id;
      v.type = v.type.toLowerCase();
      if (v?.staticArgs) v.staticArgs = JSON.stringify(v.staticArgs, null, 2);
      delete v?._lastChangedAt;
      delete v?.createdAt;
      delete v?.updatedAt;
      delete v?._deleted;
      delete v?.__typename;
      const psw = v?.type == "oauth" ? v?.clientSecret : v?.tokenSecret;
      delete v?.clientSecret;
      delete v?.tokenSecret;
      let newAuthorisations = [...state?.hostings];
      try {
        let result;
        if (isEdit) {
          result = await hostingsHelper.updateHosting(opt.id, v);
          console.log("Edit result", result);
          const _updatedObject = identifyOptByID(
            result.data?.updateHosting?.id
          );
          const updateIndex = newAuthorisations.findIndex(
            (u) => u?.id === _updatedObject?.id
          );
          newAuthorisations[updateIndex] = result?.data?.updateHosting;
        } else {
          if (state?.hostings?.find((u) => u?.name === v?.name)) {
            AppToasty.show("Provider with this name already exists", {
              type: "danger",
            });
            setIsSaveInProgress(false);
            return;
          }
          result = await hostingsHelper.createHosting({
            ...v,
          });
          const _id = result?.data?.createHosting?.id;
          if (v?.type != "local" && v?.type != "aws") {
            const secretResult = await secretGenerator.storeSecret({
              workspaceid: v.workspaceID,
              id: _id,
              passwordSecretValue: psw,
            });
            if (secretResult?.data?.status === "Success") {
              const payload =
                v?.type == "oauth"
                  ? {
                      clientSecret: secretResult?.data?.name,
                    }
                  : {
                      tokenSecret: secretResult?.data?.name,
                    };
              const _updatedObject = await hostingsHelper.updateHosting(
                _id,
                payload
              );
            } else {
              throw new Error("Secret could not be created");
            }
          }
          newAuthorisations.push(result?.data?.updateHosting);
        }

        dispatch("SET_HOSTINGS", [...newAuthorisations]);
        backTo && navigateBack();
        onHostingCreated && onHostingCreated("success");
      } catch (error) {
        console.error(error);
        onHostingCreated && onHostingCreated("error");
      } finally {
        setIsSaveInProgress(false);
      }
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            height: "auto",
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Provider : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={handleHostingItemSave}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <HostingSettingsAIView
              handleDeletion={handleDeletion}
              isEdit={isEdit}
              settingType={settingType}
              isSaveInProgress={isSaveInProgress}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [
        state.activeWorkspaceID,
        initialMapValues,
        state?.hostings,
        isEdit,
        AppToasty,
        isSaveInProgress,
      ]
    );
    return _view;
  }
);

export default HostingsSettingsAI;
