import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

const getAgentFunctionsByWorkspaceID = ({ workspaceID }) =>
  API.graphql(graphqlOperation(queries.functionsByWorkspace, { workspaceID }));
const getAgentHostingsByWorkspaceID = ({ workspaceID }) =>
  API.graphql(graphqlOperation(queries.HostingsByWorkspace, { workspaceID }));
const createAgentFunction = (object) =>
  API.graphql(
    graphqlOperation(mutations.createAgentFunction, { input: object })
  );

const updateAgentFunction = (object, _version) => {
  return API.graphql(
    graphqlOperation(mutations.updateAgentFunction, {
      input: { ...object, _version },
    })
  );
};

const deleteAgentFunction = (id, _version) =>
  API.graphql(
    graphqlOperation(mutations.deleteAgentFunction, { input: { id, _version } })
  );

export default {
  getAgentFunctionsByWorkspaceID,
  createAgentFunction,
  updateAgentFunction,
  deleteAgentFunction,
  getAgentHostingsByWorkspaceID,
};
