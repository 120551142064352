import { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';

const useAuth = function (config, onCognitoUserAuthenticated) {
  let viewOnNotAuth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "auth";
  if (config) Auth.configure(config);
  const [currentView, setCurrentView] = useState("initializing");
  const checkAuth = async () => {
    try {
      const session = await Auth.currentSession();
      onCognitoUserAuthenticated && onCognitoUserAuthenticated(session);
      setCurrentView("mainNav");
    } catch (err) {
      console.error("user is not signed in " + err);
      setCurrentView(viewOnNotAuth);
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);
  const getCurrentAuthenticatedUser = () => {
    return Auth.currentAuthenticatedUser();
  };
  const getCurrentSession = () => {
    return Auth.currentSession();
  };
  const signOut = async () => {
    try {
      const result = await Auth.signOut();
      console.log("Auth - user successfully signed out");
      return result;
    } catch (error) {
      console.error("Auth - error signing out: ", error);
    }
  };
  const attemptSignInWithGoogle = () => {
    return Auth.federatedSignIn({
      provider: "Google"
    });
  };
  return {
    currentView,
    setCurrentView,
    getCurrentAuthenticatedUser,
    getCurrentSession,
    signOut,
    attemptSignInWithGoogle
  };
};

export { useAuth as u };
