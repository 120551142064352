import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";

import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useCallback } from "react";
import workspacesHelper from "../../API/Workspaces/workspacesHelper";
import { SectionsProvider } from "../../Hooks/useSections";
import useWorkspaces from "../../Hooks/useWorkspaces";
import AdminScreen from "./AdminScreen";
import { useTranslation } from "react-i18next";
import usePath from "../../Hooks/usePath";
import { useHistory } from "../../Router";
import AccessDeniedScreen from "../AccessDenied";

const AdminScreenContainer = () => {
  const {
    updateWorkspaceInContext,
    getLastTabSelectedByScreen,
    handleSectionSelection,
  } = useWorkspaces();
  const { params } = usePath();
  const history = useHistory();
  const AppToasty = AppToast.useToast();
  const { state } = AppStateManager.useAppStateManager();
  const { getActiveWorkspace, isUserAdmin } = useWorkspaces();

  const { t } = useTranslation();
  let _defaultView;
  if (params.sectionId && isUserAdmin()) {
    _defaultView =
      params.sectionId?.toUpperCase() ||
      getLastTabSelectedByScreen("admin", "WORKSPACE_SETTINGS");
  }

  const optionsDataGenerator = useCallback(() => {
    const _base_options = [
      {
        isNavigation: true,
        icon: AppAssets.icons.trainAI,
        isBackHidden: false,
        uid: "WORKSPACE_SETTINGS",
        label: "General settings",
        subOptions: [],
        supportVisible: true,
      },
      {
        isNavigation: true,
        icon: AppAssets.icons.usersSettings,
        isBackHidden: false,
        uid: "USERS",
        label: "User management",
        subOptions: [],
        supportVisible: false,
      },
      {
        isNavigation: true,
        icon: AppAssets.icons.userInfo,
        isBackHidden: false,
        uid: "HUMAN_FEEDBACK",
        label: "Human feedback",
        subOptions: [],
        supportVisible: false,
      },
      {
        isNavigation: true,
        icon: AppAssets.icons.trainAI,
        isBackHidden: false,
        uid: "API_INTEGRATION",
        label: "API integration",
        subOptions: [],
        supportVisible: false,
      },
      {
        isNavigation: true,
        icon: AppAssets.icons.charting,
        uid: "CHARTING",
        label: "Charting settings",
        subOptions: [],
        supportVisible: false,
      },
      {
        isNavigation: true,
        icon: AppAssets.icons.faPageBreak,
        uid: "EMBEDDING",
        label: "Embeddings settings",
        subOptions: [],
        supportVisible: false,
      },
      {
        isNavigation: true,
        icon: AppAssets.icons.notification,
        uid: "NOTIFICATIONS",
        label: "Notifications",
        subOptions: [],
        supportVisible: false,
      },
    ];
    if (getActiveWorkspace()?.subscriptionType === "enterprise") {
      _base_options.push(
        ...[
          {
            isNavigation: true,
            icon: AppAssets.icons.users,
            isBackHidden: false,
            uid: "SSO",
            label: "Single sign-on",
            subOptions: [],
            supportVisible: false,
          },
          {
            isNavigation: true,
            icon: AppAssets.icons.notes,
            uid: "LOGS",
            label: "Logs",
            subOptions: [],
            supportVisible: false,
          },
        ]
      );
    }
    return _base_options;
  }, [getActiveWorkspace()?.id]);
  const handleWorkspaceUpdate = async (property, value) => {
    try {
      const result = await workspacesHelper.updateWorkspace(
        state?.activeWorkspaceID,
        [{ property, value }]
      );
      updateWorkspaceInContext(result);
      AppToasty.show(t("WorkspaceUpdateCaption"), {
        type: "success",
      });
    } catch (error) {
      AppToasty.show(t("WorkspaceUpdateFailedCaption"), {
        type: "danger",
      });
      console.error("An error occured while updating the workspace", error);
    }
  };
  return _defaultView ? (
    <SectionsProvider
      sectionsWidth={"100%"}
      optionsDataGenerator={optionsDataGenerator}
      defaultViewUID={_defaultView}
      onDisplayedViewChanged={(section) => {
        handleSectionSelection(section, "admin");
        if (state.activeWorkspaceID && section)
          history.push(
            `/workspaces/${
              state.activeWorkspaceID
            }/admin/${section.toLowerCase()}`
          );
      }}
      sectionsMainTitle="Workspace admin"
    >
      <AdminScreen
        workspace={state?.workspaces?.find(
          (u) => u?.id === state?.activeWorkspaceID
        )}
        handleWorkspaceUpdate={handleWorkspaceUpdate}
      />
    </SectionsProvider>
  ) : (
    <></>
  );
};

export default AdminScreenContainer;
