import API from "@aws-amplify/api";
import { createChat, deleteChat, updateChat } from "../../graphql/mutations";
import { chatsByWorkspace, getChat } from "../../graphql/queries";
const createChat_ = async (object) => {
  return API.graphql({
    query: createChat,
    variables: { input: object },
  });
};
const getChat_ = async (id) => {
  return API.graphql({
    query: getChat,
    variables: { id },
  });
};
const updateChat_ = async (id, args) => {
  const original = await getChat_(id);
  let _properties = {};
  args.forEach((item) => {
    _properties[item.property] = item.value;
  });
  return API.graphql({
    query: updateChat,
    variables: {
      input: {
        id,
        _version: original?.data?.getChat?._version,
        ..._properties,
      },
    },
  });
};

const deleteChat_ = async (id) => {
  const original = await getChat_(id);
  return API.graphql({
    query: deleteChat,
    variables: {
      input: {
        id,
        _version: original?.data?.getChat?._version,
      },
    },
  });
};

const getChats = async (workspaceID, nextToken, isWidget) => {
  return API.graphql({
    query: chatsByWorkspace,
    variables: {
      workspaceID,
      nextToken,
      sortDirection: "DESC",
      limit: 500,
      filter: {
        visibility: {
          [isWidget ? "eq" : "ne"]: "widget",
        },
        name: {
          ne: isWidget ? "New Chat" : null,
        },
      },
    },
  });
};

export default {
  createChat: createChat_,
  updateChat: updateChat_,
  deleteChat: deleteChat_,
  getChats,
  getChat: getChat_,
};
