import { AppText } from "@toothfairy/shared-ui";
import React from "react";
import { View } from "react-native";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
const CurrentModelView = () => {
  const { getActiveTrainingLog, getPlanConfig, getActiveWorkspace } =
    useWorkspaces();
  const { currentTheme } = AppTheme.useTheme();
  const mapDefaultWithTFName = (modelType) => {
    if (getPlanConfig()?.type == "base") {
      switch (modelType) {
        case "gn":
          return "Default";
        case "qa":
          return "Default";
        case "nlp":
          return "Default";
      }
    } else if (getPlanConfig()?.type == "pro") {
      switch (modelType) {
        case "gn":
          return "Default";
        case "qa":
          return "Default";
        case "nlp":
          return "Default";
      }
    } else if (getPlanConfig()?.type == "enterprise") {
      switch (modelType) {
        case "gn":
          return "Default";
        case "qa":
          return "Default";
        case "nlp":
          return "Default";
      }
    }
  };
  const nlpModel = getActiveTrainingLog("nlp")?.id
    ? `${getActiveTrainingLog("nlp")?.name}`
    : mapDefaultWithTFName("nlp");
  const qaModel = getActiveTrainingLog("qa")?.id
    ? `${getActiveTrainingLog("qa")?.name}`
    : mapDefaultWithTFName("qa");
  const gnModel = getActiveTrainingLog("gn")?.id
    ? `${getActiveTrainingLog("gn")?.name}`
    : mapDefaultWithTFName("gn");

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: currentTheme?.theme?.lightGrey,
        padding: 20,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          marginTop: 10,
        }}
      >
        <AppText fontWeight="bold">Chat model - </AppText>
        <AppText>Default</AppText>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 10,
        }}
      >
        <AppText fontWeight="bold">Content creator model - </AppText>
        <AppText>{gnModel}</AppText>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 10,
        }}
      >
        <AppText fontWeight="bold">Business analyst model - </AppText>
        <AppText>{qaModel}</AppText>
      </View>
      {getPlanConfig()?.isSentimentReviewerEnabled &&
        getActiveWorkspace()?.isSentimentReviewerEnabled && (
          <View
            style={{
              flexDirection: "row",
              marginTop: 10,
            }}
          >
            <AppText fontWeight="bold">Sentiment reviewer model - </AppText>
            <AppText>{nlpModel}</AppText>
          </View>
        )}
    </View>
  );
};

export default CurrentModelView;
