import { AppText } from "@toothfairy/shared-ui";
import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";

const LoadingPlaceholder = ({ caption = "", style, ...props }) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View style={[{ flex: 1 }, style]}>
      <View style={{ margin: "auto" }}>
        <AppActivityIndicator {...props} />
        <AppText
          style={{ fontSize: currentTheme?.theme?.medium_font_size }}
          fontWeight="bold"
          wrapperStyle={{ marginTop: 20 }}
        >
          {caption}
        </AppText>
      </View>
    </View>
  );
};

export default LoadingPlaceholder;
