import { DataStore } from "@aws-amplify/datastore";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import {
  AppRootContext,
  isBillingAddressSet,
  isFirstTrialStarted,
  isUserDelinquent,
  isUserOnTrial,
  isUserWithNoPaymentMethod,
  userType,
} from "../../App";
import SideMenu from "../Components/SideMenu";
import TopBar from "../Components/TopBar";
import Constants from "../Constants";
import usePath from "../Hooks/usePath";
import useWorkspaces from "../Hooks/useWorkspaces";
import { useHistory } from "../Router";
import { isLargeScreen } from "./MainViewContainer";

const MainViewScreen = ({ children, height, sideMenuOpen, width }) => {
  const history = useHistory();
  const { isInWorkspace } = usePath();
  const { dispatch, state } = AppStateManager.useAppStateManager();
  const { width: screenWidth } = useWindowDimensions();
  const _styles = styles({
    height,
    sideMenuOpen,
    width,
    pageNotFound: state?.pageNotFound,
    isLargeScreen: isLargeScreen(),
  });
  const { t } = useTranslation();

  const { params } = usePath();
  const { setCurrentView } = useContext(AppRootContext);
  const { signOut } = AppAuth.useAuth();
  const AppToasty = AppToast.useToast();
  const { isWorkspaceEnabled, getActiveWorkspace } = useWorkspaces();
  const handleSignOut = async () => {
    const result = await DataStore.clear();
    console.log("__cleared_cache__", result);
    signOut()
      .then(async () => {
        AppToasty.show(t("LogoutMessage"), {
          placement: "top",
          type: "success",
        });
        history.push("/");
        setCurrentView("auth");
      })
      .catch((error) => {
        console.error("Auth - logout failed", error);
        AppToasty.show(t("LogoutFailure"), {
          placement: "top",
          type: "danger",
        });
      });
  };
  const sideMenu = useMemo(() => {
    return (
      !state?.pageNotFound && (
        <SideMenu handleSignOut={handleSignOut} isInWorkspace={isInWorkspace} />
      )
    );
  }, [isInWorkspace, state?.pageNotFound, handleSignOut, userType()]);
  const handleBillingSetupRequired = () => {
    if (
      (isUserDelinquent() || (isInWorkspace && !isWorkspaceEnabled)) &&
      !isUserOnTrial()
    ) {
      history.push("/profile/billing");
      AppToasty.show(t("BillingSetupRequiredDueToFailedPayment"), {
        type: "warning",
      });
    }
    if (
      !isUserOnTrial() &&
      isFirstTrialStarted()
      // &&
      // isUserWithNoPaymentMethod()
    ) {
      history.push("/profile/billing");
      AppToasty.show(t("BillingSetupRequiredDueToFinishedTrial"), {
        type: "warning",
      });
    }
    if (!isUserOnTrial() && isFirstTrialStarted() && !isBillingAddressSet()) {
      history.push("/profile/billing");
      AppToasty.show(t("BillingSetupRequiredDueToNoPaymentMethod"), {
        type: "warning",
      });
    }
  };

  useEffect(() => {
    if (params?.workspaceId) {
      dispatch("SET_ACTIVE_WORKSPACE_ID", params?.workspaceId);
    }
  }, [params?.workspaceId]);

  useEffect(() => {
    if (!isUserOnTrial()) handleBillingSetupRequired();
  }, [
    isUserDelinquent,
    isUserOnTrial(),
    isUserWithNoPaymentMethod,
    isWorkspaceEnabled,
  ]);

  return (
    <>
      {sideMenu}

      <View style={_styles.content}>
        {!state?.pageNotFound && (
          <TopBar handleSignOut={handleSignOut} isInWorkspace={isInWorkspace} />
        )}

        {children}
      </View>
    </>
  );
};

const styles = ({ height, sideMenuOpen, width, pageNotFound, isLargeScreen }) =>
  StyleSheet.create({
    container: {
      height: "100%",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflow: "hidden",
    },
    content: {
      zIndex: -1,
      height: height,
      maxHeight: !pageNotFound && height,
      width: "100%",
      maxWidth:
        !pageNotFound &&
        width - (sideMenuOpen && isLargeScreen && Constants.sideMenuWidth),
    },
  });

export default MainViewScreen;
