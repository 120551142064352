import AppMultipleSelectInput from "@toothfairy/shared-ui/AppMultipleSelectInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { isLargeScreen } from "../../MainViewContainer";
import Color from "../../../Utility/Color";
const { useWindowDimensions } = require("react-native");

const CommonEntityFilter = ({
  items,
  onDeleteSelectedItem,
  onItemPress,
  selectedItems,
  entityType,
  placeholder,
}) => {
  const { width } = useWindowDimensions();
  const { currentTheme } = AppTheme.useTheme();
  const removeDuplicates = (array) => {
    if (array) {
      let unique = array
        .map((e) => e["id"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => array[e])
        .map((e) => array[e]);
      return unique;
    } else return array;
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.border_color,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
      borderRadius: 20,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let color = new Color(data?.backgroundColor);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? currentTheme?.theme?.white
            ? "white"
            : "black"
          : data?.backgroundColor,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data?.backgroundColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      let color = new Color(data?.backgroundColor || currentTheme?.theme?.grey);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor,
      ":hover": {
        backgroundColor: data?.backgroundColor,
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.lightGray,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
    }),
  };

  return (
    <AppMultipleSelectInput
      onSelectionChange={onItemPress}
      selectedItems={removeDuplicates(selectedItems)}
      placeholder={placeholder}
      hidePlaceholder={true}
      disablePreSelectedValues={false}
      disable={false}
      items={removeDuplicates(items)}
      uniqueKey="id"
      showDropDowns={true}
      readOnlyHeadings={true}
      labelOption={"name"}
      groupByProperty="category"
      style={{ maxWidth: 800 }}
      wrapperContainerStyle={[
        { flex: !isLargeScreen() ? 1 : 0.5, marginLeft: 5, marginRight: 18 },
      ]}
      styles={colourStyles}
      options={removeDuplicates(
        items.map((u) => {
          return {
            ...u,
            value: u?.id,
          };
        })
      )}
    />
    // <AppAutoCompleteInput
    //   containerStyle={{
    //     minHeight: 78,
    //   }}
    //   labelOption="label"
    //   categoryLabel="type"
    //   placeholder={
    //     entityType === "NLP"
    //       ? "DocumentsNLPFilterPlaceholder"
    //       : "DocumentsRCFilterPlaceholder"
    //   }
    //   inputWidth={310}
    //   displayCategoriesSeparately={false}
    //   items={items}
    //   onDeleteSelectedItem={onDeleteSelectedItem}
    //   onItemPress={onItemPress}
    //   selectedItems={selectedItems}
    //   groupByProperty={"type"}
    //   displayedCategories={[
    //     { value: "ner", label: t("NER"), icon: AppAssets.icons.ner },
    //     {
    //       value: "intent",
    //       label: t("Intent"),
    //       icon: AppAssets.icons.trainAI,
    //     },
    //     {
    //       value: "topic",
    //       label: t("Topic"),
    //       icon: AppAssets.icons.bookmark,
    //     },
    //   ]}
    // />
  );
};

export default CommonEntityFilter;
