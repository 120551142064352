import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo } from "react";
import { Dimensions, Platform, StyleSheet, View } from "react-native";
import { AppIcon } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairySupportButton from "../../../Components/ToothFairyComponents/ToothFairySupportButton";
import TrainSwitcher from "../../../Components/TrainSwitcher";
import btnStyleGenerator from "../../../Constants/btnStyleGenerator";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { initialEditorValue } from "../commonUtils";
import { useHistory } from "../../../Router";

const SettingsMain = ({
  handleNewDocument,
  handleParse,
  handleShowOutput,
  storeInError,
  parseInProgress,
  storeInProgress,
  documentId,
  isSaved,
  isParser,
  setIsParser,
  routeToParsing,
  setIsTrainerNoDocumentId,
  isTrainerNoDocumentId,
  isDirty,
  nlpData,
  isMainParsingFnDisabled,
  handleShowTrData,
  setIsValidationData,
  isValidationData,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const styles = styleGenerator(currentTheme);
  const history = useHistory();
  const commonBtnStyle =
    btnStyleGenerator.commonBtnStyleGenerator(currentTheme);
  const { getPlanConfig, getActiveWorkspace } = useWorkspaces();
  const _commonActionsButtons = useMemo(() => {
    return (
      <>
        {!isParser && (
          <AppIcon
            wrapperStyle={{
              marginTop: 5,
            }}
            icon={AppAssets.icons?.arrow_left}
            isClickable
            onPress={() => {
              history.push(
                `/workspaces/${getActiveWorkspace()?.id}/documents/nlp`
              );
            }}
          />
        )}
        {isParser && (
          <ToothFairyButton
            title="Review"
            // awesomeIcon={AppAssets.icons.enableAI}
            onPress={handleParse}
            isDisabled={isMainParsingFnDisabled}
            {...commonBtnStyle}
          />
        )}

        {isParser && Constants.isShowRequestEnabled ? (
          <ToothFairyButton
            title="Show Request"
            onPress={handleShowOutput}
            isDisabled={isMainParsingFnDisabled}
            {...commonBtnStyle}
          />
        ) : (
          Constants.isShowRequestEnabled && (
            <ToothFairyButton
              title="Show Request"
              onPress={handleShowTrData}
              isDisabled={isMainParsingFnDisabled}
              {...commonBtnStyle}
            />
          )
        )}
        {!isParser && (
          <ToothFairyButton
            title={isTrainerNoDocumentId ? "Save" : "New"}
            onPress={() =>
              handleNewDocument(!isTrainerNoDocumentId, initialEditorValue)
            }
            isDisabled={parseInProgress}
            {...commonBtnStyle}
            {...commonBtnStyle}
            borderColor={"transparent"}
          />
        )}
      </>
    );
  }, [
    handleNewDocument,
    handleParse,
    handleShowOutput,
    isMainParsingFnDisabled,
    isDirty,
    isParser,
    isTrainerNoDocumentId,
    nlpData,
    parseInProgress,
    commonBtnStyle,
    getActiveWorkspace()?.isSentimentReviewerEnabled,
  ]);
  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <View style={{ flex: 1 }}>
          <View
            style={{
              // flexDirection: width > 1344 ? "row" : "column",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <ToothFairySupportButton />
            </View>

            <View style={{ flexDirection: "row", flex: 1 }}>
              <View
                style={{
                  flex: 1,
                }}
              >
                <TrainSwitcher
                  setIsValidationData={setIsValidationData}
                  isValidationData={isValidationData}
                  storeInError={storeInError}
                  storeInProgress={storeInProgress}
                  isSaved={isSaved}
                  isTrainingEnabled={getPlanConfig()?.isTrainingNLPEnabled}
                  isParser={isParser}
                  handleNewDocument={handleNewDocument}
                  setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
                  routeBackToParser={() => {
                    routeToParsing();
                  }}
                  handleShowTrData={handleShowTrData}
                  parseInProgress={parseInProgress}
                  title={""}
                  setIsParser={setIsParser}
                  documentId={documentId}
                  isTrainerNoDocumentId={isTrainerNoDocumentId}
                  childrenComponentRelativePosition={"left"}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      paddingVertical:
                        Dimensions.get("window").width < 540 && 10,
                      marginLeft: Dimensions.get("window").width < 540 && -10,
                    }}
                  >
                    {_commonActionsButtons}
                  </View>
                </TrainSwitcher>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styleGenerator = (currentTheme) => {
  return StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 5,
      paddingHorizontal: 20,
    },
    icon: {
      borderRadius: 5,
      width: "auto",
      height: "auto",
    },
    modal: {
      borderRadius: 30,
      height: Platform.OS === "web" ? 500 : "95%",
      left: "auto",
      marginHorizontal: Platform.OS === "web" ? "auto" : "5%",
      marginVertical: "auto",
      top: "auto",
      width: Platform.OS === "web" ? 500 : "90%",
    },
    modalInnerContent: {
      paddingHorizontal: 20,
      paddingTop: "auto",
    },
    modalRightIcon: {
      position: "relative",
      top: "auto",
      right: "auto",
    },
    modalWrapper: {
      height: "auto",
    },
    title: {
      fontSize: currentTheme?.theme?.largest_font_size,
      fontWeight: "bold",
    },
  });
};

export default SettingsMain;
