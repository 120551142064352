import { Elements } from "@stripe/react-stripe-js";
import { AppText } from "@toothfairy/shared-ui";
import LoadingPlaceholder from "../../../Components/LoadingPlaceholder";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppExpander from "@toothfairy/shared-ui/AppExpander";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import BillingAddress from "./BillingAddress";
import BillingCheckoutForm from "./BillingCheckoutForm";
import BillingInvoices from "./BillingInvoices";
import BillingPaymentMethod from "./BillingPaymentMethod";
import useSections from "../../../Hooks/useSections";
import { isLargeScreen } from "../../MainViewContainer";

const BillingDetailsView = ({
  stripeCustomerAPIInProgress,
  stripePortalAPIInProgress,
  paymentMethodAPIInProgress,
  stripeClientInvoicesAPIInProgress,
  stripeClientSecretAPIInProgress,
  clientPaymentIntentAPIInProgress,
  paymentMethodsAPIInProgress,
  stripeCustomerData,
  stripeClientSecretData,
  stripeClientInvoicesData,
  paymentMethodsData,
  stripePromise,
  isWizard,
  saveCustomerDetails,
  saveInProgress,
  goToPortal,
  isPaymentMethodEditMode,
  setPaymentMethodEditMode,
  handlePaymentPortalOpening,
  handlePaymentMethodSave,
  onPaymentMethodSave,
  loadMoreInvoices,
  invoices,
}) => {
  const { currentTheme, mode } = AppTheme.useTheme();
  const paymentMethod =
    paymentMethodsData?.paymentMethods?.data?.length > 0
      ? paymentMethodsData?.paymentMethods?.data[0].card
      : null;
  const isPaymentFetching =
    stripeClientSecretAPIInProgress ||
    paymentMethodAPIInProgress ||
    clientPaymentIntentAPIInProgress ||
    paymentMethodsAPIInProgress;
  const appearance = {
    theme: mode === "dark" ? "night" : "stripe",
    // labels: mode === "dark" && "floating",

    variables: {
      colorPrimary: currentTheme?.theme?.primary,
      colorBackground: currentTheme?.theme?.inputBackgroundColor,
      colorText: currentTheme?.theme?.font_color,
      colorDanger: currentTheme?.theme?.red,
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      fontSizeBase: 16,
      spacingUnit: "2px",
      borderRadius: "25px",

      // See all possible variables below
    },
  };
  const paymentPortal = useMemo(() => {
    return stripeClientSecretData?.setupIntent?.client_secret ? (
      <View
        style={{
          padding: 9,
        }}
      >
        {isPaymentMethodEditMode ? (
          <Elements
            stripe={stripePromise}
            options={{
              appearance,
              // passing the client secret obtained from the server
              clientSecret: stripeClientSecretData?.setupIntent?.client_secret,
            }}
          >
            <BillingCheckoutForm
              handlePaymentMethodSave={handlePaymentMethodSave}
              setPaymentMethodEditMode={setPaymentMethodEditMode}
              onPaymentMethodSave={onPaymentMethodSave}
            />
          </Elements>
        ) : (
          <BillingPaymentMethod
            stripeCustomerData={stripeCustomerData}
            handlePaymentPortalOpening={handlePaymentPortalOpening}
            paymentMethod={paymentMethod}
          />
        )}
      </View>
    ) : (
      <AppText>StripePortalFetchFailed</AppText>
    );
  }, [
    stripeClientSecretData,
    stripePromise,
    setPaymentMethodEditMode,
    isPaymentMethodEditMode,
    paymentMethod,
    stripeCustomerData,
    mode,
  ]);

  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        paddingHorizontal: 30,
      }}
    >
      {stripeCustomerAPIInProgress && (
        <LoadingPlaceholder
          caption="Loading billing info..."
          visible={true}
          containerStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
            minHeight: 250,
          }}
        />
      )}

      {!stripeCustomerAPIInProgress && stripeCustomerData && (
        <View
          style={{
            minWidth: isLargeScreen() ? 650 : 250,
          }}
        >
          {isWizard && (
            <AppText wrapperStyle={{ paddingBottom: 20 }}>
              billingDetailsWizardCaption
            </AppText>
          )}
          <AppExpander
            style={{ minWidth: isLargeScreen() ? 650 : 250 }}
            isExpandedOnLoad={true}
            title="Payment method"
            icon={AppAssets.icons.billing}
          >
            {stripeClientSecretAPIInProgress ? (
              <LoadingPlaceholder caption="Loading payments info..." visible />
            ) : (
              paymentPortal
            )}
          </AppExpander>
          <AppExpander
            title="Billing details"
            icon={AppAssets.icons.billingDetails}
            style={{ minWidth: isLargeScreen() ? 650 : 250 }}
            isExpandedOnLoad={true}
          >
            <BillingAddress
              stripeCustomerData={stripeCustomerData}
              saveCustomerDetails={saveCustomerDetails}
              isWizard={isWizard}
              saveInProgress={saveInProgress}
              goToPortal={goToPortal}
            />
          </AppExpander>
          <AppExpander
            title="Billing history"
            icon={AppAssets.icons.invoice}
            style={{ minWidth: isLargeScreen() ? 650 : 250 }}
          >
            {stripeClientInvoicesAPIInProgress ? (
              <LoadingPlaceholder
                caption="Loading billing history..."
                visible
              />
            ) : (
              <BillingInvoices
                invoices={invoices}
                stripeClientInvoicesData={stripeClientInvoicesData}
                loadMoreInvoices={loadMoreInvoices}
              />
            )}
          </AppExpander>
        </View>
      )}
    </View>
  );
};

const styleGenerator = (currentTheme) =>
  StyleSheet.create({
    cellWrapperStyle: {
      justifyContent: "center",
      width: "20%",
    },
    listContentContainer: {
      paddingBottom: 20,
    },
    listHeader: {
      backgroundColor: currentTheme?.theme?.lightGrey,
    },
    listItemContainer: {
      flexDirection: "row",
      padding: 10,
    },
  });

export default BillingDetailsView;
