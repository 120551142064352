import React, { useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { AppText, AppIcon } from "@toothfairy/shared-ui";
import { useHistory } from "../../../Router";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import usePath from "../../../Hooks/usePath";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";

const ArenaHeader = ({ ...props }) => {
  const { getActiveWorkspace } = useWorkspaces();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const path = usePath();

  is_in_leaderboard =
    path?.path?.path === "/workspaces/:workspaceId/arena_leaderboard";
  const [backRoute, setBackRoute] = useState({
    label: "View leaderboard",
    route: `/workspaces/${getActiveWorkspace()?.id}/arena_leaderboard`,
  });
  useEffect(() => {
    if (getActiveWorkspace()?.id)
      if (is_in_leaderboard) {
        setBackRoute({
          label: "Back to arena",
          route: `/workspaces/${getActiveWorkspace()?.id}/arena`,
        });
      } else {
        setBackRoute({
          label: "View leaderboard",
          route: `/workspaces/${getActiveWorkspace()?.id}/arena_leaderboard`,
        });
      }
  }, [is_in_leaderboard, getActiveWorkspace()?.id]);
  const currentRoute = {
    label: "Agents arena",
  };
  const { currentTheme } = AppTheme.useTheme();
  const history = useHistory();
  const _appLogo = useMemo(
    () => (
      <AppIcon
        style={{
          paddingVertical: 10,
          paddingRight: 5,
          width: 152,
          marginLeft: 5,
        }}
        imageSource={require("../../../assets/logoTitle.png")}
        width={"100%"}
        size={36}
        isClickable
        onPress={() => {
          window.open("https://toothfairyai.com", "_blank");
        }}
      />
    ),
    []
  );

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottomColor: currentTheme?.theme?.primary_medium,
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <AppText
          size={26}
          leftIcon={AppAssets?.icons?.arena}
          color={currentTheme?.theme?.primary}
          style={{
            fontSize: 26,
            fontWeight: "bold",
            paddingLeft: 10,
          }}
          wrapperStyle={{
            padding: 10,
          }}
        >
          {currentRoute?.label}
        </AppText>

        <AppDropDown
          dropDownStyle={{
            width: 200,
            marginLeft: 20,
            borderColor: currentTheme?.theme?.border_color,
          }}
          isElevated={false}
          dropdownIconColor="transparent"
          items={[
            {
              name: "Select agent mode",
              id: "none",
            },
            {
              name: "Casual",
              id: "chatter",
            },
            {
              name: "Coder",
              id: "coder",
            },
            {
              name: "Retriever",
              id: "retriever",
            },
          ]}
          placeholder="Select agent mode"
          propertyLabel="name"
          propertyValue="id"
          selectedValue={state?.selectedArenaMode}
          onValueChange={(itemValue, itemIndex) => {
            dispatch("SET_SELECTED_ARENA_MODE", itemValue);
          }}
        />
      </View>

      <AppText
        style={{
          fontSize: 16,
          fontWeight: "bold",
          color: currentTheme?.theme?.primary,
          padding: 10,
        }}
        isClickable
        onPress={() => {
          history.push(backRoute?.route);
        }}
      >
        {backRoute?.label}
      </AppText>
    </View>
  );
};

export default ArenaHeader;
