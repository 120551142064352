import React from "react";
import { Platform, View } from "react-native";
import AdminInfoLeaf from "../Components/SubComponents/AdminInfoLeaf";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import Clipboard from "@react-native-clipboard/clipboard";
import AppForms from "@toothfairy/shared-ui/AppForms";
import { isLargeScreen } from "../../MainViewContainer";
import Constants from "../../../Constants";

const SingleSignOnScreen = ({ onSubmit = () => {}, isSaveInProgress }) => {
  const { currentTheme } = AppTheme.useTheme();
  const { getActiveWorkspace } = useWorkspaces();
  const AppToasty = AppToast.useToast();
  const ssoValidationSchema = AppForms.yup.object().shape({
    ssoType: AppForms.yup.string().required().oneOf(["Azure"]),
    federationMetadataUrl: AppForms.yup.string().when("ssoType", {
      is: "Azure",
      then: AppForms.yup
        .string()
        .matches(
          /^https:\/\/login\.microsoftonline\.com\/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}\/federationmetadata\/2007-06\/federationmetadata\.xml$/,
          "Invalid Azure Federation Metadata URL"
        )
        .required("SSO provider is required"),
      otherwise: AppForms.yup.string().required(),
    }),
    ssoDomain: AppForms.yup
      .string()
      .required()
      .matches(
        "^(?!-)[A-Za-z0-9-]{1,63}(?<!-).([A-Za-z]{2,6}(.?[A-Za-z]{2,3})?)$",
        "Invalid domain"
      ),
  });

  return (
    <View
      style={{
        marginHorizontal: "auto",
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 340,
      }}
    >
      <AppForms.AppForm
        style={{
          minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 250,
          maxWidth: !isLargeScreen() && 250,
          alignSelf: !isLargeScreen() && "center",
        }}
        initialValues={{
          federationMetadataUrl:
            getActiveWorkspace()?.federationMetadataUrl || "",
          ssoType: getActiveWorkspace()?.ssoType || "",
          ssoDomain: getActiveWorkspace()?.ssoDomain || "",
        }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={ssoValidationSchema}
      >
        <AppForms.AppFormDropDown
          formFieldContainerStyle={{
            flex: 1,
          }}
          isElevated={false}
          dropDownStyle={{
            borderColor: currentTheme?.theme?.border_color,
            maxWidth: !isLargeScreen() && 250,
          }}
          showTextInputLabel
          label="SSO Provider"
          isMandatory
          name="ssoType"
          values={["None", "Azure"]}
          items={["None", "Azure"]}
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="App Federation Metadata Url"
          isMandatory
          name="federationMetadataUrl"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="SSO Domain mapping"
          isMandatory
          name="ssoDomain"
        />
        <View
          style={{
            marginHorizontal: 10,
          }}
        >
          <AdminInfoLeaf
            wrapperStyle={{
              alignSelf: "flex-start",
              width: "100%",
              alignItems: "flex-start",
            }}
            titleColor={currentTheme?.theme?.primary}
            showSimpleText
            subCaptionColor={currentTheme?.theme?.primary}
            title={"SSO Identifier (Entity ID)"}
            accessibilityLabel={"entity_id"}
            value={"urn:amazon:cognito:sp:ap-southeast-2_ZdZ1v3tsr"}
            handleClick={() => {
              Clipboard.setString(
                "urn:amazon:cognito:sp:ap-southeast-2_ZdZ1v3tsr"
              );
              AppToasty.show(t("VcopyCaption"), {
                type: "success",
              });
            }}
          />
          <AdminInfoLeaf
            wrapperStyle={{
              alignSelf: "flex-start",
              width: "100%",
              alignItems: "flex-start",
            }}
            titleColor={currentTheme?.theme?.primary}
            showSimpleText
            subCaptionColor={currentTheme?.theme?.primary}
            title={"SSO reply URL (Assertion Consumer Service)"}
            accessibilityLabel={"reply_url"}
            value={
              "https://toothfairyai808525a0-custom-dev.auth.ap-southeast-2.amazoncognito.com/saml2/idpresponse"
            }
            handleClick={() => {
              Clipboard.setString(
                "https://toothfairyai808525a0-custom-dev.auth.ap-southeast-2.amazoncognito.com/saml2/idpresponse"
              );
              AppToasty.show(t("VcopyCaption"), {
                type: "success",
              });
            }}
          />
        </View>

        <AppForms.AppSubmitButton
          title={"Save"}
          isDisabled={isSaveInProgress}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 30,
              alignSelf: "center",
              height: Constants.appButtonHeight,
            },
          ]}
        />
      </AppForms.AppForm>
    </View>
  );
};

export default SingleSignOnScreen;
