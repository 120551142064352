import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPopup from "@toothfairy/shared-ui/AppPopup";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo } from "react";
import { routes } from "../../../Router/routes";
import { useHistory } from "../../../Router";
import AppAvatar from "@toothfairy/shared-ui/AppAvatar";
import { Platform } from "react-native";

const AppAccountAvatar = ({ handleSignOut, user, showPopup = false }) => {
  const { currentTheme } = AppTheme.useTheme();
  const history = useHistory();
  const options = useMemo(
    () => [
      {
        label: "Profile",
        icon: AppAssets.icons.account,
        to: routes.profile,
      },
      {
        label: "Logout",
        icon: AppAssets.icons.logout,
        to: routes.workspaces,
        onPress: handleSignOut,
        color: currentTheme?.theme?.red,
      },
    ],
    [handleSignOut, currentTheme]
  );

  return showPopup ? (
    <AppPopup
      style={{ alignSelf: "flex-end" }}
      isAvatar
      isTriggerIcon={false}
      isEnabled
      avatarLabel={user?.username || "Not applicable"}
      avatarSize={36}
      avatarRatio={0.5}
      topOffset={-36}
      leftOffset={-36}
      isCloseButtonVisible={false}
      placement="left"
      avatarLabelColor={currentTheme?.theme?.white}
      avatarStyle={{
        backgroundColor: currentTheme?.theme?.primary,
      }}
    >
      {options.map((u) => {
        return (
          <AppText
            wrapperStyle={{
              paddingHorizontal: 8,
              paddingVertical: 10,
            }}
            iconSize={20}
            style={{
              marginLeft: 20,
            }}
            leftIcon={u.icon}
            isClickable
            isHoverable={false}
            color={u.color}
            onPress={() => {
              if (u.onPress) {
                u.onPress();
              } else if (u.to) {
                history.push(u?.to);
              }
            }}
          >
            {u?.label}
          </AppText>
        );
      })}
    </AppPopup>
  ) : (
    <AppAvatar
      label={user?.username || "Not applicable"}
      size={24}
      avatarLabelColor={currentTheme?.theme?.white}
      onPress={() => {
        history.push("/profile/profile_settings");
      }}
      style={{
        backgroundColor: currentTheme?.theme?.primary,
        cursor: Platform.OS === "web" && "pointer",
      }}
      ratio={0.5}
    />
  );
};

export default AppAccountAvatar;
