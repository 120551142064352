import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import ToothFairyModal from "../../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairyTab from "../../../Components/ToothFairyComponents/ToothFairyTab";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../MainViewContainer";
import HumanFeedbackFilter from "../SubComponents/HumanFeedbackFilter";
import FeedbackView from "./SubComponents/FeedbackView";
import { showSideMenu } from "../../../../App";

const HumanFeedbackView = ({
  getFeedbackInProgress,
  start,
  end,
  handleUpdateStartDate,
  handleUpdateEndDate,
  displayedFeedback,
  selectedFeedbackType,
  setSelectedFeedbackType,
  setDisplayedfeedback,
  setLastForwardToken,
  fetchLogs,
  setShowFeedbackModal,
  showFeedbackModal,
  setSelectedIndex,
  selectedIndex,
  rawFeedback,
  handleFeedbackUpdate,
  isFeedbackUpdateInProgress,
  setIsFeedbackUpdateInProgress,
  setIncludeResolved,
  includeResolved,
  members,
  currentPage = 1,
  handlePageChange = () => {},
  pageSize = 50,
  routes,
  handleTabChange = () => {},
  initialTabFromRouting,
  mainFeedbackType,
}) => {
  const { getLastTabSelectedByScreen } = useWorkspaces();
  const { currentTheme } = AppTheme.useTheme();
  const { width } = useWindowDimensions();
  const columns = [
    {
      title: "ID",
      width: 0.2,
    },
    {
      title: "Type",
      width: 0.2,
    },
    {
      title: "Message",
      width: 0.4,
    },
    {
      title: "Datetime",
      width: 0.2,
    },
    {
      title: "Resolved",
      width: 0.1,
    },
    {
      title: "Actions",
      isAction: true,
      width: 0.00001,
    },
  ];

  const HumanFeedbackFilter_ = () => {
    return (
      <HumanFeedbackFilter
        endDate={end}
        onUpdateEndDate={handleUpdateEndDate}
        onUpdateStartDate={handleUpdateStartDate}
        startDate={start}
        triggerFetchLogs={fetchLogs}
        selectedServiceType={selectedFeedbackType}
        setSelectedServiceType={setSelectedFeedbackType}
        showDates={false}
        showIncludeResolved={true}
        setIncludeResolved={setIncludeResolved}
        includeResolved={includeResolved}
      />
    );
  };

  return (
    <View
      style={{
        width: isLargeScreen()
          ? showSideMenu()
            ? width - (showSideMenu() ? 460 : 220)
            : "100%"
          : "100%",
        paddingHorizontal: 10,
      }}
    >
      <View
        style={{ flexDirection: "column", justifyContent: "space-between" }}
      >
        {routes?.length > 0 && (
          <ToothFairyTab
            key={mainFeedbackType === "generativeFeedback" ? 1 : 0}
            initialIndex={mainFeedbackType === "generativeFeedback" ? 1 : 0}
            isAnimated={false}
            onTabChange={handleTabChange}
            renderSceneObject={{
              conversationalFeedback: HumanFeedbackFilter_,
              generativeFeedback: HumanFeedbackFilter_,
            }}
            routes={routes}
          />
        )}
      </View>
      {getFeedbackInProgress ? (
        <AppActivityIndicator
          backgroundColor={currentTheme?.theme?.softBackgroundColor}
          visible={true}
          containerStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
            minHeight: 250,
          }}
        />
      ) : (
        <>
          {displayedFeedback?.length > 0 ? (
            <View>
              <AppTable
                style={{ zIndex: -1 }}
                headers={columns}
                items={displayedFeedback}
                itemsPerPage={50}
                actionButtons={true}
                isItemEditable={(item) => {
                  return true;
                }}
                isItemDeletable={(item) => {
                  return false;
                }}
                handleDocumentSelection={(result) => {
                  setSelectedIndex(result.index);
                  setShowFeedbackModal(true);
                }}
                handleDocumentDeletion={(document) => {}}
              />
              {displayedFeedback?.length > pageSize && (
                <AppPagination
                  onPageChange={handlePageChange}
                  totalCount={displayedFeedback?.length}
                  siblingCount={1}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  wrapperStyle={{
                    marginTop: 10,
                    alignSelf: "flex-end",
                  }}
                />
              )}
            </View>
          ) : (
            <AppPlaceholderView
              wrapperStyle={{
                height: "auto",
                paddingBottom: 20,
                width: 350,
                marginHorizontal: "auto",
                marginTop: 50,
              }}
              size={80}
              color={currentTheme?.theme?.primary}
              icon={AppAssets?.icons?.orbit}
              mainCaption="No feedback found, try changing the filters"
              mainCaptionStyle={
                {
                  // fontSize: currentTheme?.theme?.large_font_size,
                }
              }
            />
          )}
        </>
      )}
      <ToothFairyModal
        key={selectedIndex}
        modalStyle={{
          maxHeight: isLargeScreen() ? 630 : 500,
          height: "95%",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showFeedbackModal}
        setIsModalOpen={setShowFeedbackModal}
        modalTitle={"Feedback details"}
        closeModal={() => {
          setShowFeedbackModal(false);
          setSelectedIndex(null);
        }}
      >
        <FeedbackView
          isFeedbackUpdateInProgress={isFeedbackUpdateInProgress}
          setIsFeedbackUpdateInProgress={setIsFeedbackUpdateInProgress}
          handleFeedbackUpdate={handleFeedbackUpdate}
          members={members}
          feedback={rawFeedback?.find(
            (u) => u?.id === displayedFeedback[selectedIndex]?.ID
          )}
          isChat={mainFeedbackType === "conversationalFeedback"}
        />
      </ToothFairyModal>
    </View>
  );
};

export default HumanFeedbackView;
