import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useMemo, useState } from "react";
import { Platform, StyleSheet, useWindowDimensions, View } from "react-native";
import Notification from "../../Components/Notification";
import PageTitle from "../../Components/PageTitle";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairyModal from "../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairyOutputModal from "../../Components/ToothFairyComponents/ToothFairyOutputModal";
import ToothFairySearch from "../../Components/ToothFairyComponents/ToothFairySearch";
import ToothFairySupportButton from "../../Components/ToothFairyComponents/ToothFairySupportButton";
import AppToast from "@toothfairy/shared-ui/AppToast";
import ToothFairyTab from "../../Components/ToothFairyComponents/ToothFairyTab";
import useSupport from "../../Hooks/useSupport";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { TrainingStatus } from "../../models";
import DateFilter from "../Admin/SubComponents/Leafs/DateFilter";
import Styles from "../Styles/Styles";
import TrainingConfigSelection from "./Components/TrainingConfigSelection";
import {
  isLandscapeMobile,
  isLargeScreen,
  isMobileScreen,
  isVeryLargeScreen,
  minAppWidth,
} from "../MainViewContainer";
import TrainingBaseModelSelection from "./Components/TrainingBaseModelSelection";
import ToothFairyResponsiveOptionsModal from "../../Components/ToothFairyComponents/ToothFairyResponsiveOptionsModal";
import ToothFairyClickableOption from "../../Components/ToothFairyComponents/ToothFairyClickableOption";
import FineTuningConfigSelection from "./Components/FineTuningConfigSelection";

dayjs.extend(relativeTime);
const columns = [
  {
    title: "ID",
    width: 0.2,
  },
  {
    title: "Name",
    width: 0.2,
  },
  {
    title: "Created by",
    width: 0.2,
  },
  {
    title: "Last changed",
    width: 0.2,
  },
  {
    title: "Duration",
    width: 0.1,
  },
  {
    title: "Status",
    width: 0.1,
  },
  {
    title: "Actions",
    isAction: true,
    width: 0.00001,
  },
];
const TrainingScreen = ({
  logStatusColors,
  trainingInProgress,
  trainingLogs,
  handlePageChange = () => {},
  handleDeleteTrainingLog = () => {},
  handleTrainingCancellation = () => {},
  handleFieldUpdate = () => {},
  handleTabChange = () => {},
  onFilterChange = () => {},
  handleSearch = () => {},
  handleDataDownload = () => {},
  markTrainingLogAsActive = () => {},
  handleTrainingInitiation = () => {},
  handleFineTuningStart = () => {},
  handleFineTuningSetupOpening = () => {},
  isBaseModel = () => {},
  statusFilter,
  currentPage,
  selectedTab,
  onUpdateStartDate,
  onUpdateEndDate,
  startDate,
  endDate,
  routes,
  initialTabFromRouting,
  isSwapingInProgress,
  trainingConfig,
  setTrainingConfig,
  isSwapRequested,
  handleBaseModelInitiation,
  setSelectedTopics,
  selectedTopics,
  isModalOpen,
  setIsModalOpen,
  isTrainingInitiationModalOpen,
  setIsTrainingInitiationModalOpen,
  isTrainingFineTuningModalOpen,
  setIsTrainingFineTuningModalOpen,
  isModalOptionsOpen,
  setIsModalOptionsOpen,
  isBaseModalOpen,
  setIsBaseModalOpen,
  ...props
}) => {
  const pageSize = 25;
  const { url } = useSupport("NLP_TRAINING_PRF");
  const dimensions = useWindowDimensions();
  const AppToasty = AppToast.useToast();
  const { currentTheme } = AppTheme.useTheme();

  const [selectedItem, setSelectedItem] = useState(null);
  const styles = styleGenerator(currentTheme);
  const { state } = AppStateManager.useAppStateManager();
  const {
    isTrainingLogIdActive,
    isTrainingEnabled,
    getLastTabSelectedByScreen,
  } = useWorkspaces();
  const getUserName = (id) => {
    return state?.workspaceUsers
      ? state?.workspaceUsers[
          state?.workspaceUsers.map((e) => e?.id).indexOf(id)
        ]?.username
      : "";
  };
  const tabIndexFromRouting = routes
    ?.map((u) => u.routeId)
    ?.indexOf(initialTabFromRouting);

  const _initialIndex =
    tabIndexFromRouting > -1
      ? tabIndexFromRouting
      : getLastTabSelectedByScreen("training");
  const handleItemSelection = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };
  const generateColorForItem = (item) => {
    if (item?.status === "swapping") return currentTheme?.theme?.orange;
    else if (isTrainingLogIdActive(item?.id))
      return currentTheme?.theme?.primary;
    else if (item.status !== "dispatched" && item.status !== "inProgress")
      return currentTheme?.theme?.grey;
    else if (item?.trainingConfig?.datasetOnly)
      return currentTheme?.theme?.grey;
    else return currentTheme?.theme?.red;
  };
  const trainingInitiationModal = useMemo(() => {
    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                maxWidth: 600,
                maxHeight: selectedTab?.key === "nlpTraining" ? 650 : 770,
                margin: "auto",
                marginHorizontal: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={isTrainingInitiationModalOpen}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle=" "
        modalInnerContentStyle={{
          paddingHorizontal: 50,
          paddingVertical: 20,
        }}
        closeModal={() => setIsTrainingInitiationModalOpen(false)}
      >
        <TrainingConfigSelection
          handleTrainingInitiation={handleTrainingInitiation}
          handleFineTuningSetupOpening={handleFineTuningStart}
          selectedTab={selectedTab}
          setIsTrainingInitiationModalOpen={setIsTrainingInitiationModalOpen}
          setTrainingConfig={setTrainingConfig}
          trainingConfig={trainingConfig}
          setSelectedTopics={setSelectedTopics}
          selectedTopics={selectedTopics}
        />
      </ToothFairyModal>
    );
  }, [
    handleTrainingInitiation,
    selectedTab?.key,
    state.activeWorkspaceID,
    isTrainingInitiationModalOpen,
    setIsTrainingInitiationModalOpen,
    setSelectedTopics,
    JSON.stringify(selectedTopics),
    handleFineTuningSetupOpening,
  ]);

  const trainingFineTuningStart = useMemo(() => {
    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                maxWidth: 600,
                maxHeight: 450,
                margin: "auto",
                marginHorizontal: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={isTrainingFineTuningModalOpen}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle="Fine-tuning configuration"
        modalInnerContentStyle={{
          paddingHorizontal: 50,
          paddingVertical: 20,
        }}
        closeModal={() => setIsTrainingFineTuningModalOpen(false)}
      >
        <FineTuningConfigSelection
          setTrainingConfig={setTrainingConfig}
          trainingConfig={trainingConfig}
          handleFineTuningStart={handleFineTuningStart}
          trainingLogId={selectedItem?.id}
        />
      </ToothFairyModal>
    );
  }, [
    JSON.stringify(trainingConfig),
    setTrainingConfig,
    isTrainingFineTuningModalOpen,
    selectedItem?.id,
  ]);

  const baseModelInitiationModal = useMemo(() => {
    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                maxWidth: 600,
                maxHeight: "80%",
                height: "auto",
                margin: "auto",
                marginHorizontal: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={isBaseModalOpen}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle=" "
        modalInnerContentStyle={{
          paddingHorizontal: 50,
          paddingVertical: 20,
        }}
        closeModal={() => setIsBaseModalOpen(false)}
      >
        <TrainingBaseModelSelection
          handleBaseModelInitiation={handleBaseModelInitiation}
          selectedTab={selectedTab}
          setIsTrainingInitiationModalOpen={setIsBaseModalOpen}
        />
      </ToothFairyModal>
    );
  }, [
    handleBaseModelInitiation,
    selectedTab?.key,
    state.activeWorkspaceID,
    isTrainingInitiationModalOpen,
    setIsBaseModalOpen,
    isBaseModalOpen,
  ]);

  const _actionsChoice = () => {
    return (
      <ToothFairyResponsiveOptionsModal
        isModalOpen={isModalOptionsOpen}
        setIsModalOpen={setIsModalOptionsOpen}
        modalTitle={"Actions"}
        scrollViewStyle={{
          height: 250,
        }}
        height={"auto"}
      >
        <ToothFairyClickableOption
          leftIcon={AppAssets.icons.trainAI}
          onPress={() => {
            setIsTrainingInitiationModalOpen(true);
            setIsModalOptionsOpen(false);
          }}
          isClickable={
            !(
              trainingInProgress ||
              !state?.isWorkspaceReady ||
              isSwapingInProgress
            )
          }
        >
          Start tuning
        </ToothFairyClickableOption>
        {isTrainingEnabled && (
          <ToothFairyClickableOption
            isClickable={
              !(
                trainingInProgress ||
                !state?.isWorkspaceReady ||
                isSwapingInProgress ||
                isBaseModel()
              )
            }
            leftIcon={AppAssets.icons.undo}
            onPress={() => {
              setIsBaseModalOpen(true);
              setIsModalOptionsOpen(false);
            }}
          >
            Use base model
          </ToothFairyClickableOption>
        )}
      </ToothFairyResponsiveOptionsModal>
    );
  };

  const trainingLogsList = () => {
    return (
      <View
        style={{
          height: dimensions.height - 160,
          minHeight: 200,
          maxWidth: !isLargeScreen() && dimensions.width - 30,
        }}
      >
        <ToothFairySearch handleSearch={handleSearch}>
          <DateFilter
            startDate={startDate}
            endDate={endDate}
            onUpdateEndDate={onUpdateEndDate}
            onUpdateStartDate={onUpdateStartDate}
          />

          <AppDropDown
            translateLabels
            showTextInputLabel
            label="Status"
            inputLabelStyle={{
              marginBottom: 0,
              marginLeft: 5,
            }}
            dropDownStyle={{
              minWidth: 184,
              maxWidth: 184,
              textAlign: "center",
              marginLeft: 20,
              borderColor: currentTheme?.theme?.border_color,
              zIndex: -1,
            }}
            wrapperContainer={{
              flexDirection: "row",
            }}
            inputLabelFontWeight={"normal"}
            isElevated={false}
            dropdownIconColor="transparent"
            items={[...Object.values(TrainingStatus), "noFilter"]}
            selectedValue={statusFilter}
            onValueChange={(itemValue, itemIndex) => {
              onFilterChange(itemValue);
            }}
          />
        </ToothFairySearch>
        {trainingLogs?.length > 0 ? (
          <View
            style={{
              height: dimensions.height - 357,
              minHeight: 400,
              zIndex: -1,
            }}
          >
            <AppTable
              marginRightBeforeActions={30}
              actionButtons
              isItemDeletable={(item) => {
                return (
                  (item.status === "dispatched" ||
                    item.status === "inProgress") &&
                  item?.trainingConfig?.datasetOnly !== true
                );
              }}
              isItemEditable={(item) => {
                return (
                  item.status === "completed" ||
                  item.status === "inError" ||
                  item.status === "datasetReady"
                );
              }}
              handleDocumentSelection={(item, index) => {
                handleItemSelection(item?.item);
              }}
              handleDocumentDeletion={(item, index) => {
                if (item.status === "dispatched")
                  handleDeleteTrainingLog(item.id);
                else if (item.status === "inProgress")
                  handleTrainingCancellation(item.id);
              }}
              customDeleteIcon={AppAssets.icons.close}
              style={{
                zIndex: -1,
                maxHeight: dimensions?.height - 200,
                paddingRight: 10,
                overflowY: "auto",
                overflowX: "hidden",
              }}
              headers={columns}
              items={trainingLogs
                ?.sort((a, b) =>
                  a?._lastChangedAt < b?._lastChangedAt ? 1 : -1
                )
                ?.filter((u) => u?.type === selectedTab?.key)
                .slice(
                  (currentPage - 1) * pageSize,
                  (currentPage - 1) * pageSize + pageSize
                )
                ?.map((u) => {
                  return {
                    id: u?.id || "",
                    name: u?.name || "No name",
                    createdBy: getUserName(u?.createdBy) || " ",
                    lastChanged:
                      dayjs(u._lastChangedAt).format("DD-MM-YYYY HH:mm") || " ",
                    duration:
                      parseFloat((u?.trainingMeta?.trainingDuration || 0) / 60)
                        .toFixed(2)
                        .toString() || "0",
                    status: u?.status || " ",
                    // qaUrl: u.qaUrl || "",
                    // promptPlaceholder: u.promptPlaceholder,

                    actions: " ",
                  };
                })}
            />

            <AppPagination
              onPageChange={handlePageChange}
              totalCount={trainingLogs?.length}
              siblingCount={1}
              currentPage={currentPage}
              pageSize={pageSize}
              wrapperStyle={[styles.paginationWrapper]}
            />
          </View>
        ) : (
          <AppPlaceholderView
            wrapperStyle={{
              width: "100%",
              height: "100%",
              maxHeight: 300,
              marginVertical: "auto",
              marginHorizontal: "auto",
              justifyContent: "center",
            }}
            size={80}
            icon={AppAssets.icons.orbit}
            mainCaption="EmptyPlaceholder"
            subCaption="TrainingLogSubCaption"
          />
        )}
      </View>
    );
  };

  return (
    <AppBaseScreen
      isScrollable={true}
      contentContainerStyle={{
        overflow: "auto",
      }}
      style={{ minHeight: 700 }}
    >
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <PageTitle>Training</PageTitle>
            <ToothFairySupportButton />
            {isLargeScreen() ? (
              <>
                {" "}
                <ToothFairyButton
                  {...Styles.minifiedButtonStyle}
                  isDisabled={
                    trainingInProgress ||
                    !state?.isWorkspaceReady ||
                    isSwapingInProgress
                  }
                  onPress={() => {
                    setIsTrainingInitiationModalOpen(true);
                  }}
                  title="Start tuning"
                  accessibilityLabel="trainingButton"
                />
                {isTrainingEnabled && (
                  <ToothFairyButton
                    {...Styles.minifiedButtonStyle}
                    isDisabled={
                      trainingInProgress ||
                      !state?.isWorkspaceReady ||
                      isSwapingInProgress ||
                      isBaseModel() ||
                      !isTrainingEnabled
                    }
                    onPress={() => {
                      setIsBaseModalOpen(true);
                    }}
                    title="Use base model"
                    accessibilityLabel="baseModelButton"
                  />
                )}
              </>
            ) : (
              <ToothFairyButton
                {...Styles.minifiedButtonStyle}
                onPress={() => {
                  setIsModalOptionsOpen(true);
                }}
                title="Actions"
                accessibilityLabel="actionsButton"
              />
            )}
          </View>

          <View>
            {trainingInProgress && (
              <Notification
                style={{
                  right: 0,
                  // top: 10,
                }}
                color={"primary"}
                dotValue={1}
                label={!isVeryLargeScreen() ? " " : "Training in progress"}
                isProgress={true}
              />
            )}
            {isSwapingInProgress ? (
              <Notification
                style={{
                  right: 0,
                }}
                color={"primary"}
                dotValue={1}
                label={!isVeryLargeScreen() ? " " : "Swap in progress"}
                isProgress={true}
              />
            ) : (
              <></>
            )}
          </View>
        </View>
        {routes?.length > 0 && (
          <ToothFairyTab
            onTabChange={handleTabChange}
            isAnimated={false}
            renderSceneObject={{
              nlpTraining: trainingLogsList,
              readComprehensionTraining: trainingLogsList,
              generativeTraining: trainingLogsList,
              conversationalTraining: trainingLogsList,
            }}
            routes={routes}
            initialIndex={_initialIndex}
          />
        )}

        <ToothFairyOutputModal
          isEventDriven={true}
          path={"graph"}
          resultObjectTitle="Training output"
          responseCopyBtnTitle="Copy output"
          resultObjectIcon={AppAssets.icons.trainAI}
          createdBy={selectedItem?.createdBy}
          requestPayload={{
            input: {
              createdBy: selectedItem?.createdBy,
              owner: selectedItem?.owner,
              trainingConfig: selectedItem?.trainingConfig,
              status: "dispatched",
              workspaceID: selectedItem?.workspaceID,
              type: selectedItem?.type,
            },
          }}
          showSummary={true}
          handleFieldUpdate={(id, property, value) => {
            handleFieldUpdate(id, property, value);
            setSelectedItem({
              ...selectedItem,
              [property]: value,
            });
          }}
          name={selectedItem?.name}
          description={selectedItem?.description}
          includeConfig={true}
          configObjectTitle="Training config"
          configObjectIcon={AppAssets.icons.cogWheel}
          configObject={selectedItem?.trainingConfig}
          isOutputUpToDate={true}
          handleOutputResize={() => {}}
          isOutputExpanded={true}
          trainingInProgress={trainingInProgress}
          isSwapingInProgress={isSwapingInProgress}
          isSwapRequested={isSwapRequested}
          parsingInProgress={false}
          trainingLogId={selectedItem?.id}
          data={selectedItem?.trainingMeta}
          meta={selectedItem?.trainingMeta}
          subCaption={"TrainerMetaSubCaption"}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          checkLength={false}
          handleDataDownload={handleDataDownload}
          markTrainingLogAsActive={markTrainingLogAsActive}
          datasetOnly={selectedItem?.trainingConfig?.datasetOnly}
          trainingLogData={selectedItem}
          lastChangedAt={selectedItem?.lastChanged}
          handleTrainingInitiation={handleTrainingInitiation}
          handleFineTuningStart={handleFineTuningStart}
          handleFineTuningSetupOpening={handleFineTuningSetupOpening}
        />
      </View>
      {trainingInitiationModal}
      {baseModelInitiationModal}
      {trainingFineTuningStart}
      {_actionsChoice()}
    </AppBaseScreen>
  );
};

const styleGenerator = (currentTheme) => {
  return StyleSheet.create({
    container: {
      paddingHorizontal: 20,
      paddingVertical: 10,
      minWidth: minAppWidth(),
    },
    listContentContainer: {
      padding: 0,
    },
    listHeader: {
      justifyContent: "space-around",
      // backgroundColor: currentTheme?.theme?.lightGrey,
    },
    listItemContainer: {
      flexDirection: "row",
      padding: 10,
      justifyContent: "space-around",
    },
    paginationWrapper: {
      marginTop: 10,
      alignSelf: "flex-end",
    },
    title: {
      fontSize: currentTheme?.theme?.largest_font_size,
      fontWeight: currentTheme?.theme?.font_bold,
      marginRight: 20,
    },
    titleContainer: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });
};

export default TrainingScreen;
