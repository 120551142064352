// string to upper case first letter
const upperCaseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
var CUSTOMEPOCH = 1300000000000; // artificial epoch
export const generateRowId = (shardId = 2 /* range 0-64 for shard/slot */) => {
  var ts = new Date().getTime() - CUSTOMEPOCH; // limit to recent
  var randid = Math.floor(Math.random() * 512);
  ts = ts * 64; // bit-shift << 6
  ts = ts + shardId;
  return ts * 512 + (randid % 512);
};
export const createUniqueCode = (number) => {
  const base36Chars = "0123456789abcdefghijklmnopqrstuvwxyz";
  let code = "";

  while (number > 0 && code.length < 8) {
    const remainder = number % 36;
    code = base36Chars[remainder] + code;
    number = Math.floor(number / 36);
  }

  return code.slice(-8);
};
const mapAgentTypeWithIcon = (agent, AppAssets) => {
  if (agent?.agentType == "BusinessAnalyst")
    return AppAssets?.icons?.businessAnalyst;
  else if (agent?.agentType == "ContentCreator")
    return AppAssets?.icons?.contentCreator;
  else if (agent?.mode == "planner") return AppAssets?.icons?.planner;
  else if (agent?.mode == "chatter") return AppAssets?.icons?.casual;
  else if (agent?.mode == "coder") return AppAssets?.icons?.coder;
  else return AppAssets?.icons?.agent;
};
function camelCaseToSpaced(str) {
  // Use regular expression to match camel case words
  const regex = /([a-z0-9])([A-Z0-9])/g;
  // Replace camel case words with spaces, lowercase the first letter of the second word, and capitalize the very first letter
  return str
    .replace(regex, (match, $1, $2) => `${$1} ${$2.toLowerCase()}`)
    .replace(/^[a-z]/, (match) => match.toUpperCase());
}
function purifySlateObject(input) {
  return input.map((entry) => {
    if (entry.type === "ul_list") {
      return {
        ...entry,
        children: entry.children.map((listItem) => {
          if (
            listItem.type === "list_item" &&
            listItem.children[0].type === "paragraph"
          ) {
            const newParagraphChildren = listItem.children[0].children.map(
              (item) => {
                if (item.type === "link") {
                  // Transform the link structure
                  return {
                    type: "link",
                    url: item.link,
                    text: item.children[0].text,
                  };
                }
                return item; // Return other items unchanged
              }
            );
            return {
              ...listItem,
              children: [
                {
                  type: "paragraph",
                  children: newParagraphChildren,
                },
              ],
            };
          }
          return listItem;
        }),
      };
    }
    return entry;
  });
}
const mapFunctionTypeToColour = (fn, currentTheme) => {
  if (fn?.isChatFn) return currentTheme?.theme?.green;
  else if (fn?.isHtmlFn) return currentTheme?.theme?.purple;
  else if (fn?.isDatabaseScript) return currentTheme?.theme?.orange;
  else return currentTheme?.theme?.primary;
};

const mapFunctionTypeToLabel = (fn) => {
  if (fn?.isChatFn) return "chat";
  else if (fn?.isHtmlFn) return "html";
  else if (fn?.isDatabaseScript) return "db";
  else return "api";
};
const mapAgentTypeToColour = (agent, currentTheme) => {
  if (agent?.agentType == "Chatbot") return currentTheme?.primary;
  else if (agent?.agentType == "BusinessAnalyst") return currentTheme?.orange;
  else if (agent?.agentType == "ContentCreator") return currentTheme?.green;
  else return currentTheme?.primary;
};
export default {
  upperCaseFirstLetter,
  generateRowId,
  createUniqueCode,
  purifySlateObject,
  camelCaseToSpaced,
  mapFunctionTypeToColour,
  mapFunctionTypeToLabel,
  mapAgentTypeToColour,
  mapAgentTypeWithIcon,
};
