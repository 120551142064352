import API from "@aws-amplify/api";
import { DataStore } from "@aws-amplify/datastore";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import envConfig from "../../../envConfig";
import { deleteWorkspace } from "../../graphql/mutations";
import { Workspace } from "../../models";

const createWorkspace = async (object) => {
  return DataStore.save(new Workspace(object));
};

const updateWorkspace = async (id, args) => {
  const original = await DataStore.query(Workspace, id);
  return DataStore.save(
    Workspace.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const _deleteWorkspace = async (
  id,
  args = [
    {
      property: "_deleted",
      value: true,
    },
  ]
) => {
  const original = await DataStore.query(Workspace, id);
  return API.graphql({
    query: deleteWorkspace,
    variables: {
      input: {
        id,
        _version: original?._version,
      },
    },
  });
};

const updateWorkspaceUsers = async ({ users, workspaceid }) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/users/update-workspace-users`;
  const body = {
    users,
    workspaceid,
  };
  return secureRequest(client, "POST", url, null, body, true, null, payload);
};

export default {
  createWorkspace,
  updateWorkspace,
  deleteWorkspace: _deleteWorkspace,
  updateWorkspaceUsers,
};
