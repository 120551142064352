import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useMemo } from "react";
import { AppIcon } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { StyleSheet, View } from "react-native";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairySupportButton from "../../../Components/ToothFairyComponents/ToothFairySupportButton";
import TrainSwitcher from "../../../Components/TrainSwitcher";
import Constants from "../../../Constants";
import btnStyleGenerator from "../../../Constants/btnStyleGenerator";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../MainViewContainer";
import TopicsSelectionComponent from "../../MrcDocuments/Components/TopicsSelectionComponent";
import GeneratorConfig from "./GeneratorConfig";
import { initialEditorValue } from "../../TestExample/commonUtils";
import { useHistory } from "../../../Router";
const GeneratorScreenHeader = ({
  isParser,
  parseInProgress,
  storeInError,
  storeInProgress,
  isSaved,
  handleNewDocument,
  routeToParsing,
  setIsParser,
  documentId,
  setIsTrainerNoDocumentId,
  isTrainerNoDocumentId,
  isDirty,
  handleShowTrData,
  setIsValidationData,
  isValidationData,
  configData,
  scope,
  styles,
  setScope,
  setStyles,
  setPrompt,
  prompt,
  agent,
  setAgent,
  selectedTopics,
  setSelectedTopics,
  isTopicUpdateModeEnabled,
  setIsTopicUpdateModeEnabled,
  trainingContentType,
  setIsSaveRequested,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { getWorkspaceEntities, getPlanConfig, getActiveWorkspace } =
    useWorkspaces();
  const commonBtnStyle = useMemo(
    () => btnStyleGenerator.commonBtnStyleGenerator(currentTheme),
    [currentTheme]
  );
  const history = useHistory();
  const topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic?.label;
    topic.category = topic.type;
    return topic;
  });
  const _adaptedTopics = useMemo(() => {
    return selectedTopics?.map((topic) => {
      if (topic?.id && topic?.label) return topic;
      else return getWorkspaceEntities("topic")?.find((t) => t?.id === topic);
    });
  }, [JSON.stringify(selectedTopics), getWorkspaceEntities("topic")?.length]);
  const _styles = styleGenerator({
    currentTheme,
  });
  return (
    <View style={_styles.titleContainer}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <ToothFairySupportButton />
        {/* {getActiveWorkspace()?.customGenerationConfig?.aiKnowledge && (
          <AppText
            numberOfLines={4}
            color={currentTheme?.theme?.orange}
            fontWeight="bold"
            wrapperStyle={{
              maxWidth: isMobileScreen() ? 340 : isLargeScreen() && 450,
              marginTop: 10,
            }}
            isClickable
            onPress={() => {
              history.push(
                `/workspaces/${getActiveWorkspace()?.id}/settings/parsing/`
              );
            }}
          >
            qaAiKnowledgeActivated
          </AppText>
        )} */}
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
          width: "100%",
        }}
      >
        <TrainSwitcher
          isTrainingEnabled={getPlanConfig()?.isTrainingGNEnabled}
          parseInProgress={parseInProgress}
          storeInError={storeInError}
          storeInProgress={storeInProgress}
          isSaved={isSaved}
          isParser={isParser}
          handleNewDocument={handleNewDocument}
          routeBackToParser={() => {
            routeToParsing();
          }}
          handleShowTrData={handleShowTrData}
          setIsParser={setIsParser}
          documentId={documentId}
          setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
          childrenComponentRelativePosition="left"
          setIsValidationData={setIsValidationData}
          isValidationData={isValidationData}
          isTrainerNoDocumentId={isTrainerNoDocumentId}
          savingNotificationWrapperStyle={{
            marginTop: -5,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "auto",
              justifyContent: "space-between",
              flex: !isParser && 1,
            }}
          >
            {!isParser && isLargeScreen() && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  flex: !isParser && 1,
                }}
              >
                {Constants.isShowRequestEnabled && (
                  <ToothFairyButton
                    {...commonBtnStyle}
                    onPress={handleShowTrData}
                    title="Show Request"
                  />
                )}
                {!isParser && (
                  <AppIcon
                    wrapperStyle={{
                      marginTop: 5,
                    }}
                    icon={AppAssets.icons?.arrow_left}
                    isClickable
                    onPress={() => {
                      history.push(
                        `/workspaces/${
                          getActiveWorkspace()?.id
                        }/documents/${trainingContentType}`
                      );
                    }}
                  />
                )}
                <ToothFairyButton
                  title={isTrainerNoDocumentId ? "Save" : "New"}
                  onPress={() =>
                    handleNewDocument(
                      !isTrainerNoDocumentId,
                      initialEditorValue
                    )
                  }
                  isDisabled={!isDirty}
                  {...commonBtnStyle}
                />
                {!isParser && (
                  <TopicsSelectionComponent
                    showAddNew
                    onTopicCreated={() => {}}
                    showEditIcon={true}
                    setIsTopicUpdateModeEnabled={setIsTopicUpdateModeEnabled}
                    isTopicUpdateModeEnabled={isTopicUpdateModeEnabled}
                    selectedTopics={_adaptedTopics}
                    onSelectedTopicsChange={(topics) => {
                      setIsSaveRequested(Date.now()?.toString());
                      setSelectedTopics(topics);
                    }}
                    topics={topics}
                    wrapperContainerStyle={{
                      maxWidth: 400,
                      marginHorizontal: 5,
                      marginTop: 5,
                    }}
                  />
                )}
              </View>
            )}
            <View style={{ flexDirection: "row" }}>
              {isParser && (
                <GeneratorConfig
                  configData={configData}
                  scope={scope}
                  setScope={setScope}
                  styles={styles}
                  setStyles={setStyles}
                  setPrompt={setPrompt}
                  agent={agent}
                  setAgent={setAgent}
                  prompt={prompt}
                />
              )}
            </View>
          </View>
        </TrainSwitcher>
      </View>
    </View>
  );
};

const styleGenerator = ({ currentTheme }) => {
  return StyleSheet.create({
    titleContainer: {
      alignItems: "flex-start",
      width: "100%",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
  });
};

export default GeneratorScreenHeader;
