import useAPI from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import envConfig from "../../envConfig";
import appLogHelper from "../API/AppLog/appLogHelper";
import trainingAPI from "../API/Training/trainingAPI";
import useWorkspaces from "../Hooks/useWorkspaces";

const useFileSelector = ({
  _importType,
  _importFile,
  additionalFilenameParams,
  _appLogImportType,
  _appLogImportEvent,
  showSuccessOnUpload = false,
  flat = true,
  createDocument = false,
  contentType,
  uploadOnSelection = true,
  onFileUploaded = () => {},
  onDocumentCreated = () => {},
  allowMultiple = false,
  showPreview = false,
  onShowPreview = () => {},
  onDocumentsCreated = () => {},
  topics = [],
}) => {
  const { getActiveWorkspace } = useWorkspaces();
  const maxDocs = getActiveWorkspace()?.bulkImportAllowed ? 50 : 10;
  const [batchUploadStatus, setBatchUploadStatus] = useState({}); // This is just an example. Replace this with your actual state update logic.
  const {
    data: createAPIResult,
    error: createAPIError,
    loading: createAPIInProgress,
    apiRequest: createAPIRequest,
    lastUpdate: lastCreationTime,
  } = useAPI(trainingAPI.postTrainingData, envConfig);
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [isFileUploadInProgress, setIsFileUploadInProgress] = useState(false);
  const [fileUploadProgress, SetFileUploadProgress] = useState(0);
  const [importType, setImportType] = useState(_importType);
  const [importFile, setImportFile] = useState(_importFile);
  const [filePath, setFilePath] = useState(null);
  const [isImportInProgress, setIsImportInProgress] = useState(false);
  const [fileSelector, SetFileSelector] = useState(null);
  const { t } = useTranslation();
  const { user } = AppUser.useUser();
  const AppToasty = AppToast.useToast();
  const _appLogsInImport = useMemo(() => {
    return state?.appLogs?.filter(
      (u) =>
        u.type === _appLogImportType &&
        (u.status === "dispatched" || u.status === "inProgress")
    );
  }, [_appLogImportType, state.appLogs]);

  const {
    data: S3uploadUrlData,
    loading: urlUploadGenerationInProgress,
    apiRequest: S3uploadUrlRequest,
    response: S3uploadResponse,
  } = useAPI(trainingAPI.bulkImportUrlGeneration, envConfig);

  const importFileFromHook = (
    file,
    title,
    folderid,
    type,
    importFile = _importFile,
    contentType = contentType
  ) => {
    setIsFileUploadInProgress(true);
    handleImport({
      file,
      importType,
      importFile,
      additionalFilenameParams,
      _appLogImportType,
      _appLogImportEvent,
      showSuccessOnUpload,
      flat,
      createDocument,
      contentType,
      title,
      folderid,
      type,
    });
  };

  const handleImports = async (
    type,
    folderid,
    status = "draft",
    topics = null,
    contentType = contentType,
    importType = _importType,
    scope = null,
    source = null
  ) => {
    setIsFileUploadInProgress(true);
    let uploadPromises = [];
    if (!filePath) return;
    let files = [...filePath];
    let rejectedFiles = [];
    for (let file of files) {
      // check the actual file by reading it and making sure each line in the jsonl file is a valid json with only one property called text which must be a string
      if (importType === "gen_ai_training") {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const _rejFiles = [];
          const text = e.target.result;
          const lines = text.split("\n");
          lines
            ?.filter((u) => u && u?.trim() != "")
            .forEach((line, index) => {
              try {
                const json = JSON.parse(line);

                if (typeof json.text !== "string") {
                  console.error("Invalid json", error);
                  AppToasty.show(
                    `Error while importing file ${file?.name} on index ${index} \nEach line in the jsonl file must be a valid json with only one property called text which must be a string`,
                    {
                      type: "danger",
                    }
                  );
                  setIsFileUploadInProgress(false);
                  _rejFiles.push(file);
                  throw new Error("Invalid json");
                }
              } catch (error) {
                console.error("Error parsing json", error);
                AppToasty.show(
                  `Error while importing file ${file?.name} on index ${index}\nEach line in the jsonl file must be a valid json with only one property called text which must be a string`,
                  {
                    type: "danger",
                  }
                );
                _rejFiles.push(file);
                setIsFileUploadInProgress(false);
              } finally {
                return _rejFiles;
              }
            });
        };
        rejectedFiles.push(reader.readAsText(file));
      }
    }
    if (rejectedFiles?.length > 0)
      files = files.filter((u) => !rejectedFiles.includes(u));
    for (let file of files) {
      const uploadPromise = handleImportPromise(
        file,
        type,
        folderid,
        updateUploadStatus,
        contentType,
        importType
      );
      uploadPromises.push(uploadPromise);
    }
    if (folderid == "root") {
      folderid = "mrcRoot";
    }
    try {
      const results = await Promise.all(uploadPromises);
      const documentsPromises = [];
      for (let result of results) {
        if (result?.upload?.status == 200) {
          documentsPromises.push(
            createAPIRequest({
              data: [
                {
                  workspaceid: getActiveWorkspace()?.id,
                  userID: user?.id,
                  type,
                  title: result?.file?.name
                    ?.toLowerCase()
                    ?.replace(/\.[^/.]+$/, ""),
                  folderid: folderid || "mrcRoot",
                  external_path: `s3://files-to-import-aus-${envConfig.REACT_APP_ENV}/${importType}/${result?.filename}`,
                  status,
                  topics: topics?.length > 0 ? topics?.map((u) => u?.id) : null,
                  scope: scope,
                  source: source,
                },
              ],
            })
          );
        }
      }
      const documentsUpload = await Promise.all(documentsPromises);

      if (
        documentsUpload &&
        documentsUpload?.length > 0 &&
        typeof documentsUpload[0] === "string" &&
        documentsUpload[0]?.includes(
          "You have reached the maximum number of documents"
        )
      ) {
        AppToasty.show(documentsUpload[0], {
          type: "danger",
        });
        return;
      } else if (documentsUpload?.length === results?.length) {
        showSuccessOnUpload &&
          AppToasty.show(t("DocumentsImportUploadSuccessCaption"), {
            type: "success",
          });
        onDocumentsCreated(documentsUpload?.map((u) => u?.data[0]));
      } else {
        AppToasty.show(t("DocumentsImportUploadfailedCaption"), {
          type: "danger",
        });
      }
      // At this point, all uploads are complete. You can now create your documents.
      // createDocument(); // Note: This is a placeholder, replace with your actual function call.
    } catch (error) {
      // Handle any errors that occurred during upload here.
      console.error("An upload failed", error);
    } finally {
      setIsFileUploadInProgress(false);
    }
  };

  const updateUploadStatus = (filename, progress) => {
    // This is just an example. Replace this with your actual state update logic.
    setBatchUploadStatus((prevStatus) => ({
      ...prevStatus,
      [filename]: progress,
    }));
  };

  const handleImportPromise = async (
    file,
    type = "readComprehensionPdf",
    folderid = "mrcRoot",
    updateUploadStatus,
    contentType = contentType,
    importType = _importType
  ) => {
    try {
      AppToasty.show(t("DocumentImportUploadStartedCaption"), {
        type: "success",
      });
      const _appLog = await appLogHelper.createAppLog({
        status: "dispatched",
        workspaceID: state?.activeWorkspaceID,
        type: _appLogImportType,
        event: _appLogImportEvent,
        filename: file.name,
        createdBy: user?.id,
      });
      let _contentType = file.name.split(".").pop();
      const filename = `${state?.activeWorkspaceID}${flat ? "_" : "/"}${
        _appLog?.id
      }${additionalFilenameParams ? additionalFilenameParams : ""}.${
        importFile !== "generic" && importFile !== "gen_ai_training"
          ? importFile?.toLowerCase()
          : _contentType
      }`;
      if (contentType === "generic") {
        // infer the content type from the file extension
        if (_contentType === "txt") {
          contentType = "text/plain";
        } else if (_contentType === "md") {
          contentType = "text/markdown";
        } else if (_contentType === "html") {
          contentType = "text/html";
        }
      }
      if (contentType === "gen_ai_training") {
        contentType = "application/json";
      }

      const result = await S3uploadUrlRequest({
        filename,
        importType,
        contentType,
      });

      const upload = await axios.request({
        method: "put",
        url: JSON.parse(result?.body)?.uploadURL,
        data: file,
        headers: {
          "Content-Type": contentType,
        },
        onUploadProgress: (p) => {
          const progress = (p.loaded / p.total) * 100;
          SetFileUploadProgress(progress);
          // Update the upload status for this file.
          updateUploadStatus(file.name, progress);
        },
      });
      // Return the upload promise
      return { upload, file, filename, type, folderid };
    } catch (error) {
      AppToasty.show(t("DocumentImportUploadFailedCaption"), {
        type: "danger",
      });
      console.error("Upload result", error);
      setIsFileUploadInProgress(false);
    }
  };

  const handleImport = async ({
    file,
    importType,
    importFile,
    additionalFilenameParams,
    _appLogImportType,
    _appLogImportEvent,
    showSuccessOnUpload,
    flat,
    createDocument,
    contentType,
    title = "Imported PDF",
    folderid = "mrcRoot",
    type = "readComprehensionPdf",
  }) => {
    try {
      AppToasty.show(t("DocumentImportUploadStartedCaption"), {
        type: "success",
      });

      const _appLog = await appLogHelper.createAppLog({
        status: "dispatched",
        workspaceID: state?.activeWorkspaceID,
        type: _appLogImportType,
        event: _appLogImportEvent,
        filename: file.name,
        createdBy: user?.id,
      });
      const filename = `${state?.activeWorkspaceID}${flat ? "_" : "/"}${
        _appLog?.id
      }${
        additionalFilenameParams ? additionalFilenameParams : ""
      }.${importFile?.toLowerCase()}`;

      const result = await S3uploadUrlRequest({
        filename,
        importType,
        contentType,
      });
      const upload = await axios.request({
        method: "put",
        url: JSON.parse(result?.body)?.uploadURL,
        data: file,
        headers: {
          "Content-Type": "application/pdf",
        },

        onUploadProgress: (p) => {
          SetFileUploadProgress((p.loaded / p.total) * 100);
        },
      });
      if (JSON.parse(result?.body).uploadURL && createDocument) {
        const documentPostResult = await createAPIRequest({
          data: [
            {
              workspaceid: getActiveWorkspace()?.id,
              userID: user?.id,
              type,
              title,
              topics: [],
              folderid: folderid || "mrcRoot",
              external_path: `s3://files-to-import-aus-${envConfig.REACT_APP_ENV}/imported-pdf/${filename}`,
            },
          ],
        });
        if (documentPostResult?.data) {
          showSuccessOnUpload &&
            AppToasty.show(t("DocumentImportUploadSuccessCaption"), {
              type: "success",
            });
          onDocumentCreated(documentPostResult);
        } else {
          AppToasty.show(documentPostResult, {
            type: "danger",
          });
        }
      }
    } catch (error) {
      AppToasty.show(t("DocumentImportUploadFailedCaption"), {
        type: "danger",
      });
      console.error("Upload result", error);
    } finally {
      setIsFileUploadInProgress(false);
    }
  };

  function buildFileSelector({
    importType,
    importFile,
    additionalFilenameParams,
    _appLogImportType,
    _appLogImportEvent,
    showSuccessOnUpload,
    flat,
    createDocument,
    contentType,
    uploadOnSelection,
    allowMultiple,
  }) {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    if (allowMultiple) fileSelector.setAttribute("multiple", "");
    else fileSelector.setAttribute("single", "single");
    if (importFile === "pdf") fileSelector.setAttribute("accept", ".pdf");
    if (importFile === "generic")
      fileSelector.setAttribute(
        "accept",
        ".txt,.md,.html,.docx,.ppt,.pptx,.csv,.xlsx,.xlsm,.java,.py,.yaml,.yml,.xml,.sql,.sh,.php,.js,.ts,.tsx,.jsx,.csharp,.rb"
      );
    if (importFile === "gen_ai_training")
      fileSelector.setAttribute("accept", ".jsonl");
    if (uploadOnSelection && !showPreview) {
      fileSelector.addEventListener("change", function (e) {
        if (e.target.files.length > maxDocs) {
          AppToasty.show(
            `You cannot upload more than ${maxDocs} documents at one time`,
            {
              type: "danger",
            }
          );
          fileSelector.value = "";
          return; // clear the selected files
        } else {
          handleImport({
            file: allowMultiple ? e?.target?.files : e?.target?.files[0],
            importType,
            importFile,
            additionalFilenameParams,
            _appLogImportType,
            _appLogImportEvent,
            showSuccessOnUpload,
            flat,
            createDocument,
            contentType,
          });
        }
      });
    } else {
      fileSelector.addEventListener("change", function (e) {
        if (e.target.files.length > maxDocs) {
          AppToasty.show(
            `You cannot upload more than ${maxDocs} documents at one time`,
            {
              type: "danger",
            }
          );
          fileSelector.value = "";
          return; // clear the selected files
        } else {
          setFilePath(allowMultiple ? e?.target?.files : e?.target?.files[0]);
          onShowPreview(topics);
        }
      });
    }
    return fileSelector;
  }
  useEffect(() => {
    if (Platform.OS === "web" && state?.activeWorkspaceID && user?.id) {
      SetFileSelector(
        buildFileSelector({
          importType,
          importFile,
          additionalFilenameParams,
          _appLogImportType,
          _appLogImportEvent,
          showSuccessOnUpload,
          flat,
          createDocument,
          contentType,
          uploadOnSelection,
          allowMultiple,
        })
      );
    }
  }, [
    importType,
    importFile,
    state?.activeWorkspaceID,
    user?.id,
    state?.appLogsReady,
    additionalFilenameParams,
    _appLogImportType,
    _appLogImportEvent,
    showSuccessOnUpload,
    flat,
    createDocument,
    contentType,
    uploadOnSelection,
    allowMultiple,
    JSON.stringify(topics),
  ]);
  useEffect(() => {
    setIsImportInProgress(_appLogsInImport?.length > 0);
  }, [_appLogsInImport]);
  const handleFileSelect = () => {
    if (Platform.OS === "web") {
      setBatchUploadStatus({});
      // e.preventDefault();
      fileSelector.value = null;
      fileSelector.click();
    } else {
      AppToasty.show(`${t("bulkImportNotAvailable")} ${Platform.OS}`, {
        type: "warning",
      });
    }
  };
  return {
    handleFileSelect,
    isUploading: isImportInProgress || urlUploadGenerationInProgress,
    uploadRequestResponse: S3uploadResponse,
    isImportInProgress,
    filePath,
    importFileFromHook,
    isFileUploadInProgress,
    fileUploadProgress,
    handleImports,
    batchUploadStatus,
  };
};

export default useFileSelector;
