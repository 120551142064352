import React from "react";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import Joyride from "react-joyride";

const AppJoyRide = ({
  steps,
  run,
  handleCallback,
  styles = {
    beaconInner: {},
    beaconOuter: {},
  },
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <Joyride
      callback={handleCallback}
      continuous
      hideCloseButton
      run={run}
      scrollToFirstStep
      showProgress
      showSkipButton
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: currentTheme?.theme?.primary,
          backgroundColor: currentTheme?.theme?.backgroundColor,
          arrowColor: currentTheme?.theme?.backgroundColor,
        },
        beacon: {
          accentColor: currentTheme?.theme?.primary,
        },
        beaconInner: {
          marginTop: 2,
          marginRight: 2,
          ...styles?.beaconInner,
        },
        beaconOuter: {
          top: 2,
          right: 2,
          ...styles?.beaconOuter,
        },
        tooltipContent: {
          fontFamily: "normalFont",
          color: currentTheme?.theme?.font_color,
        },
        tooltipContainer: {
          backgroundColor: currentTheme?.theme?.backgroundColor,
        },
        buttonNext: {
          backgroundColor: currentTheme?.theme?.primary,
          fontFamily: "normalFont",
        },
        buttonBack: {
          fontFamily: "normalFont",
          color: currentTheme?.theme?.primary,
        },
        buttonSkip: {
          fontFamily: "normalFont",
          color: currentTheme?.theme?.font_color,
        },
      }}
      {...props}
    />
  );
};

export default AppJoyRide;
