import AppForms from "@toothfairy/shared-ui/AppForms";

const parametersVal = AppForms.yup
  .object()
  .required()
  .nullable()
  .shape({
    type: AppForms.yup
      .string()
      .nullable()
      .oneOf(["object"], 'Type must be "object"')
      .required("Type is required"),
    properties: AppForms.yup.object().required().nullable(),
    required: AppForms.yup
      .array()
      .nullable()
      .of(AppForms.yup.string().required("Required fields must be strings")),
  });

export default {
  parametersVal,
};
