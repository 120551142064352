import API, { graphqlOperation } from "@aws-amplify/api";
import { useWhisper } from "@chengsokdara/use-whisper";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import useAPI from "@toothfairy/shared-api/useApi";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import dayjs from "dayjs";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import envConfig from "../../../envConfig";
import chatsHelper from "../../API/Chats/chatsHelper";
import Chatter from "../../API/Chatter";
import Files from "../../API/Files";
import messagesHelper from "../../API/Messages/messagesHelper";
import trainingAPI from "../../API/Training/trainingAPI";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory, useParams } from "../../Router";
import {
  onCreateChatMessageByChatIDEvent,
  onUpdateChat,
  onUpdateChatMessageByChatIDEvent,
} from "../../graphql/subscriptions";
import { isMobileScreen } from "../MainViewContainer";
import ChatsScreenView from "./ChatsScreenView";
import Media from "../../API/Media";
import useRecording from "../../Hooks/useRecording";
import { useTextToSpeech } from "../../Hooks/useTextToSpeech";

const Chats = () => {
  const { chatId } = useParams();
  const history = useHistory();
  const { mode } = AppTheme?.useTheme();
  const cursor = mode === "light" ? "\u26AB" : "\u26AA";
  const [lastUpdateMessageTime, setLastUpdateMessageTime] = useState(null);
  const {
    getActiveWorkspace,
    getWorkspaceEntities,
    generateAdaptedTopics,
    loadChats,
    getAgent,
    getChat,
    isChatHistoryLoaded,
    setIsChatHistoryLoaded,
    getPlanConfig,
  } = useWorkspaces();
  const {
    updateText: updateTextForSpeech,
    currentText,
    processedText,
    isSpeechGettingProcessed,
    stopAudio,
    isPlaying,
    setIsPlaying,
    listenToAudio,
  } = useTextToSpeech();
  const [lastSubscribedChatId, setLastSubscribedChatId] = useState(null);
  const [errorWithSubscription, setErrorWithSubscription] = useState(false);
  const [subscriptionObj, setSubscriptionObject] = useState(null);
  const [chatSubscriptionObj, setChatSubscriptionObject] = useState(null);
  const [chatMetadata, setChatMetadata] = useState(null);
  const [allowStop, setAllowStop] = useState(false);
  const [selectedImageKey, setSelectedImageKey] = useState(null);
  const [selectedImageRawKey, setSelectedImageRawKey] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileKey, setFileKey] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioKey, setAudioKey] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoKey, setVideoKey] = useState(null);
  const [fileUrls, setFileUrls] = useState(null);
  const [fileKeys, setFileKeys] = useState(null);
  const [audiosText, setAudiosText] = useState("");
  const [videosText, setVideosText] = useState("");
  const [planningExecution, setPlanningExecution] = useState(null);
  const [audioPlayBackMessage, setAudioPlayBackMessage] = useState(null);
  const [activeStep, setActiveStep] = useState(null);
  const [totalSteps, setTotalSteps] = useState(0);
  const [streamingMessageID, setStreamingMessageID] = useState(null);
  const [loadedMessages, setLoadedMessages] = useState([20]);
  const [stateMessages, setStateMessages] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [showSourcesModal, setShowSourcesModal] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const [showWidgetChatsHistory, setShowWidgetChatsHistory] = useState(false);
  const [chatObject, setChatObject] = useState(null);
  const [primaryAgent, setPrimaryAgent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedChatId, setLoadedChatId] = useState(null);
  const [_coreTreeViewData, setCoreTreeViewData] = useState(null);
  const [inputText, setInputText] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [canInteractWithChat, setCanInteractWithChat] = useState(true);
  const [creationOfNewChatInProgress, setCreationOfNewChatInProgress] =
    React.useState(false);
  const [deletionInProgress, setDeletionInProgress] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(null);
  const [treeViewActionContext, setTreeViewActionContext] = useState("");
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const { user } = AppUser.useUser();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageKey, setImageKey] = useState(null);
  const chats = state?.chats?.filter((u) => {
    if (showWidgetChatsHistory) return u?.visibility === "widget";
    else return u?.visibility !== "widget";
  });
  const [messages, setMessages] = useState([]);
  const [isAIStreaming, setIsAIStreaming] = useState(false);
  const setChats = (chats) => {
    dispatch("SET_CHATS", chats);
  };
  const topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic?.label;
    topic.category = topic.type;
    return topic;
  });
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeToDelete, setSelectedNodeToDelete] = useState(null);
  useEffect(() => {
    setImageUrl(null);
    setImageKey(null);
    setAudioUrl(null);
    setFileKey(null);
    setFileUrl(null);
    setFileKeys(null);
    setFileUrls(null);
    setAudioKey(null);
    setVideoUrl(null);
    setVideoKey(null);
    setIsAIStreaming(false);
    setLastUpdateMessageTime(null);
  }, [chatId]);
  const { transcript, startRecording, stopRecording } = useRecording({
    onForcedStop: () => {},
  });
  const {
    loading: audioProcessingInProgress,
    apiRequest: audioProcessingRequest,
    wasInvoked: audioProcessingInvoked,
    data: audioProcessingResult,
  } = useAPI(Media.processAudio, null, () => {});
  const {
    loading: videoProcessingInProgress,
    apiRequest: videoProcessingRequest,
    wasInvoked: videoProcessingInvoked,
    data: videoProcessingResult,
  } = useAPI(Media.processVideo, null, () => {});
  const {
    loading: documentFetchInProgress,
    apiRequest: fetchMRCData,
    wasInvoked: fetchMRCDataInvoked,
  } = useAPI(trainingAPI.getDocumentsByWorkspace, null, () => {});
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  useEffect(() => {
    setLoadingMessageId(
      state?.chats.find((u) => u.id === chatId)?.activeMessageForAI
    );
    setDocumentId(state?.chats.find((u) => u.id === chatId)?.documentId);
  }, [JSON.stringify(state?.chats?.map((u) => u?.activeMessageForAI)), chatId]);
  const onImageUploaded = (url) => {
    setImageUrl(url);
    setImageKey(url);
  };
  const onFileUploaded = (url) => {
    setFileUrl(url);
    setFileKey(url);
  };
  const onFilesUploaded = (urls, presignedUrls, indexForRemoval = null) => {
    if (indexForRemoval !== null && fileUrls?.length > 0) {
      setFileUrls((prev) => {
        const newArray = [...prev];
        newArray.splice(indexForRemoval, 1);
        return newArray;
      });
      setFileKeys((prev) => {
        const newArray = [...prev];
        newArray.splice(indexForRemoval, 1);
        return newArray;
      });
      return;
    } else if (fileUrls?.length > 0) {
      setFileUrls((prev) => [...prev, ...presignedUrls]);
      setFileKeys((prev) => [...prev, ...urls]);
    } else {
      setFileUrls(presignedUrls);
      setFileKeys(urls);
    }
  };
  const onAudioUploaded = async (url) => {
    try {
      setAudioKey(url);
      setAudioUrl(url);
      const result = await audioProcessingRequest({
        token: getActiveWorkspace()?.workspaceToken,
        data: {
          audio_file: url,
          workspaceid: getActiveWorkspace()?.id,
        },
      });

      setAudiosText(result?.contents?.text);
      // AppToasty.show("Audio processed", {
      //   placement: "bottom",
      //   type: "success",
      // });
    } catch (error) {
      setAudiosText("");
      // AppToasty.show("Error processing audio", {
      //   placement: "bottom",
      //   type: "danger",
      // });
    }
  };
  const onImageSelected = (url, key) => {
    setSelectedImageKey(url);
    setSelectedImageRawKey(key);
  };
  const onVideoUploaded = async (url) => {
    try {
      setVideoKey(url);
      setVideoUrl(url);
      const result = await videoProcessingRequest({
        token: getActiveWorkspace()?.workspaceToken,
        data: {
          text_prompt: "Summarize the video in no more than 5 sentences",
          video_file: url,
          workspaceid: getActiveWorkspace()?.id,
        },
      });
      setVideosText(result?.contents);
      // AppToasty.show("Video processed", {
      //   placement: "bottom",
      //   type: "success",
      // });
    } catch (error) {
      setVideosText("");
      // AppToasty.show("Error processing video", {
      //   placement: "bottom",
      //   type: "danger",
      // });
    }
  };
  const handleCopy = () => {
    AppToasty.show("Value copied to clipboard", {
      placement: "bottom",
      type: "success",
    });
  };
  const handleFeedback = async (
    id,
    humanFeedback,
    humanFeedbackType,
    humanFeedbackComment,
    humanFeedbackSubType
  ) => {
    const payload = [
      {
        property: "humanFeedback",
        value: humanFeedback,
      },
      {
        property: "humanFeedbackProvidedBy",
        value: user?.id,
      },
      {
        property: "adminChecked",
        value: false,
      },
    ];
    if (humanFeedbackType) {
      payload.push({
        property: "humanFeedbackType",
        value: humanFeedbackType,
      });
    }
    if (humanFeedbackSubType) {
      payload.push({
        property: "humanFeedbackSubType",
        value: humanFeedbackSubType,
      });
    }
    if (humanFeedbackComment) {
      payload.push({
        property: "humanFeedbackComment",
        value: humanFeedbackComment,
      });
    }
    try {
      const result = await messagesHelper.updateChatMessage(id, payload);
      setMessages((prevMessages) => {
        let updatedMessages = [...prevMessages];
        const newMessageIndex = updatedMessages.map((u) => u._id).indexOf(id);
        if (newMessageIndex !== -1) {
          updatedMessages[newMessageIndex] = {
            ...updatedMessages[newMessageIndex],
            humanFeedback: humanFeedback,
            humanFeedbackType: humanFeedbackType,
            humanFeedbackComment: humanFeedbackComment,
            humanFeedbackSubType: humanFeedbackSubType,
          };
        }
        return [...updatedMessages];
      });
      AppToasty.show("Feedback saved", {
        placement: "bottom",
        type: "success",
      });
    } catch (error) {
      console.log(error);
      AppToasty.show("Error saving feedback", {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  const handleOnRecorderClick = (v) => {
    try {
      if (v) {
        startRecording();
        // show the toast message
        AppToasty.show(t("recordingStarted"), {
          placement: "bottom",
          type: "success",
        });
      } else {
        stopRecording();
        AppToasty.show(t("recordingStopped"), {
          placement: "bottom",
          type: "success",
        });
      }
    } catch (error) {
      AppToasty.show(t("recordingError"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  function removeSpecificUnicodeAtEnd(text) {
    const unicodeRegex = /(\u26AB|\u26AA)$/g;
    return text.replace(unicodeRegex, "");
  }
  const getDocuments = async () => {
    const workspaceID = getActiveWorkspace()?.id;

    const promise1 = fetchMRCData({
      workspaceID,
      type: "readComprehensionContent",
      fields: ["id", "title", "topics", "embedding_status"],
      page: -1,
      pageLimit: 500,
    });

    const promise2 = fetchMRCData({
      workspaceID,
      type: "questionAnswering",
      fields: ["id", "title", "topics", "embedding_status"],
      page: -1,
      pageLimit: 500,
    });

    const promise3 = fetchMRCData({
      workspaceID,
      type: "readComprehensionPdf",
      fields: ["id", "title", "topics", "embedding_status"],
      page: -1,
      pageLimit: 500,
    });
    const promise4 = fetchMRCData({
      workspaceID,
      type: "readComprehensionFile",
      fields: ["id", "title", "topics", "embedding_status"],
      page: -1,
      pageLimit: 500,
    });

    const [result, qaResult, pdfResult, fileResult] = await Promise.all([
      promise1,
      promise2,
      promise3,
      promise4,
    ]);
    const _docs = [
      ...result?.data,
      ...qaResult?.data,
      ...pdfResult?.data,
      ...fileResult?.data,
    ].filter((u) => u?.embedding_status === "ready");
    setDocuments(_docs);
  };
  useEffect(() => {
    if (getActiveWorkspace()?.id) getDocuments();
  }, [getActiveWorkspace()?.id]);
  const onCancelGeneration = async () => {
    subscriptionObj.unsubscribe();
    setIsAIStreaming(false);
    setLastUpdateMessageTime(null);
    await chatsHelper.updateChat(chatId, [
      {
        property: "isAIReplying",
        value: false,
      },
      {
        property: "activeMessageForAI",
        value: null,
      },
      {
        property: "forcePlannerStop",
        value: true,
      },
    ]);
    const result = await messagesHelper.updateChatMessage(streamingMessageID, [
      {
        property: "status",
        value: "cancelled",
      },
    ]);
    console.log("chat streaming stopped --- ", result);
  };
  const handleClickOnWidgetChatsHistory = () => {
    if (getPlanConfig()?.isWidgetChatsHistoryEnabled) {
      setSelectedNode(null);
      history.push(`/workspaces/${getActiveWorkspace()?.id}/chats`);
      loadChats(!showWidgetChatsHistory, true);
      setShowWidgetChatsHistory(!showWidgetChatsHistory);
    } else {
      AppToasty.show(t("widgetChatsHistoryNotEnabled"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  useEffect(() => {
    let subscriptionObj;
    let chatSubscriptionObj;
    let subscriptionObjCreate;
    if (chatId) {
      setAllowStop(false);
      subscriptionObjCreate = API.graphql(
        graphqlOperation(onCreateChatMessageByChatIDEvent, {
          chatID: chatId,
        })
      ).subscribe({
        next: async (data) => {
          const mainMessageObj =
            data?.value?.data?.onCreateChatMessageByChatIDEvent;
          const message = mainMessageObj?.text;
          const newMessage = {
            ...mainMessageObj,
            _id: mainMessageObj.id,
            createdAt: mainMessageObj.creationTime,
            user: {
              id: null,
              name: "assistant",
              agentName:
                primaryAgent?.id != mainMessageObj?.agentID
                  ? getAgent(mainMessageObj?.agentID)?.label
                  : primaryAgent?.label,
            },
            text: `${message}`,
            loading: false,
            humanFeedback: mainMessageObj?.humanFeedback,
            humanFeedbackType: mainMessageObj?.humanFeedbackType,
            humanFeedbackSubType: mainMessageObj?.humanFeedbackSubType,
            humanFeedbackComment: mainMessageObj?.humanFeedbackComment,
            metadata:
              typeof mainMessageObj.metadata === "string"
                ? JSON.parse(mainMessageObj.metadata)
                : mainMessageObj.metadata,
            callbackMetadata:
              typeof mainMessageObj.callbackMetadata === "string"
                ? JSON.parse(mainMessageObj.callbackMetadata)
                : mainMessageObj.callbackMetadata,
            agentName: getAgent(mainMessageObj?.agentID)?.label,
          };
          setTimeout(() => {
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              if (prevMessages?.find((u) => u?._id == newMessage?._id) == null)
                updatedMessages.push(newMessage);
              // sort messages by createdAt
              updatedMessages = updatedMessages.sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
              });
              return [...updatedMessages];
            });
          }, 1000);
        },
      });
      subscriptionObj = API.graphql(
        graphqlOperation(onUpdateChatMessageByChatIDEvent, {
          chatID: chatId,
        })
      ).subscribe({
        next: async (data) => {
          const mainMessageObj =
            data?.value?.data?.onUpdateChatMessageByChatIDEvent;
          const message = mainMessageObj?.text;
          if (mainMessageObj.id === streamingMessageID)
            setLastUpdateMessageTime(Date.now());
          if (
            mainMessageObj.status === "fulfilled" &&
            (mainMessageObj?.callbackMetadata?._type == "suggestion" ||
              mainMessageObj?.callbackMetadata?._type == "nextQuestion")
          ) {
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const callbackMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj?.id);
              if (callbackMessageIndex !== -1) {
                updatedMessages[callbackMessageIndex] = {
                  ...updatedMessages[callbackMessageIndex],
                  callbackMetadata: mainMessageObj.callbackMetadata,
                };
              }
              return [...updatedMessages];
            });
          }
          if (
            (mainMessageObj.status === "fulfilled" ||
              mainMessageObj?.role != "assistant") &&
            mainMessageObj?.metadata
          ) {
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const callbackMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj?.id);
              if (callbackMessageIndex !== -1) {
                updatedMessages[callbackMessageIndex] = {
                  ...updatedMessages[callbackMessageIndex],
                  metadata:
                    typeof mainMessageObj.metadata == "string"
                      ? JSON.parse(mainMessageObj.metadata)
                      : mainMessageObj.metadata,
                };
              }
              return [...updatedMessages];
            });
          }

          if (mainMessageObj.status === "fulfilled" && isAIStreaming) {
            if (mainMessageObj.id === streamingMessageID) {
              setIsAIStreaming(false);

              if (
                mainMessageObj.text !== "**...**" &&
                mainMessageObj.status === "fulfilled" &&
                mainMessageObj.text != processedText &&
                primaryAgent?.autoVoiceMode
              )
                updateTextForSpeech(
                  `${message}${
                    mainMessageObj.status === "fulfilled" ? "." : ""
                  }`,
                  mainMessageObj?.chatID,
                  mainMessageObj?.id
                );
              const _stResult = await chatsHelper.updateChat(selectedNode?.id, [
                {
                  property: "isAIReplying",
                  value: false,
                },
                {
                  property: "activeMessageForAI",
                  value: null,
                },
              ]);
              setStreamingMessageID(null);
            }
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const loadingMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(loadingMessageId);
              if (loadingMessageIndex !== -1) {
                updatedMessages[loadingMessageIndex] = {
                  ...updatedMessages[loadingMessageIndex],
                  text: removeSpecificUnicodeAtEnd(message),
                  loading: false,
                  metadata:
                    typeof mainMessageObj.metadata === "string"
                      ? JSON.parse(mainMessageObj.metadata)
                      : mainMessageObj.metadata,
                  callbackMetadata:
                    typeof mainMessageObj.callbackMetadata === "string"
                      ? JSON.parse(mainMessageObj.callbackMetadata)
                      : mainMessageObj.callbackMetadata,
                  agentName: getAgent(mainMessageObj?.agentID)?.label,
                };
              }
              return [...updatedMessages];
            });
          } else if (
            mainMessageObj.metadata &&
            mainMessageObj.text === "**...**" &&
            mainMessageObj.isAI === true
          ) {
            const _meta =
              typeof mainMessageObj.metadata === "string"
                ? JSON.parse(mainMessageObj.metadata)
                : mainMessageObj.metadata;
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const loadingMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj.id);
              if (loadingMessageIndex !== -1) {
                updatedMessages[loadingMessageIndex] = {
                  ...updatedMessages[loadingMessageIndex],
                  metadata:
                    typeof mainMessageObj.metadata === "string"
                      ? JSON.parse(mainMessageObj.metadata)
                      : mainMessageObj.metadata,
                  agentName: getAgent(mainMessageObj?.agentID)?.label,
                };
              }
              return [...updatedMessages];
            });
          } else if (
            (mainMessageObj.text !== "**...**" &&
              mainMessageObj.isAI === true) ||
            (loadingMessageId == null && isAIStreaming)
          ) {
            // update the last message in the chat with role assistant to the new message
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const loadingMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj.id);
              if (loadingMessageIndex !== -1 && message !== "**...**") {
                setAllowStop(loadingMessageId === mainMessageObj.id);
                updatedMessages[loadingMessageIndex] = {
                  ...updatedMessages[loadingMessageIndex],
                  text: `${message}${isAIStreaming ? " " + cursor : ""}`,
                  loading: false,
                  humanFeedback: mainMessageObj?.humanFeedback,
                  humanFeedbackType: mainMessageObj?.humanFeedbackType,
                  humanFeedbackSubType: mainMessageObj?.humanFeedbackSubType,
                  humanFeedbackComment: mainMessageObj?.humanFeedbackComment,
                  agentName: getAgent(mainMessageObj?.agentID)?.label,
                  metadata:
                    typeof mainMessageObj.metadata === "string"
                      ? JSON.parse(mainMessageObj.metadata)
                      : mainMessageObj.metadata,
                  callbackMetadata:
                    typeof mainMessageObj.callbackMetadata === "string"
                      ? JSON.parse(mainMessageObj.callbackMetadata)
                      : mainMessageObj.callbackMetadata,
                };
              }
              return [...updatedMessages];
            });
          }
        },
        error: (error) => {
          console.log(
            `Error with subscription: ${lastSubscribedChatId}`,
            error
          );
          setErrorWithSubscription(true);
          if (lastSubscribedChatId < 10)
            setLastSubscribedChatId((prev) => prev + 1);
        },
      });
      chatSubscriptionObj = API.graphql(
        graphqlOperation(onUpdateChat, {
          id: chatId,
        })
      ).subscribe({
        next: async (data) => {
          const chatObj = data?.value?.data?.onUpdateChat;
          // update chat title if it has changed
          if (chatObj?.id === chatId) {
            if (chatObj?.name !== chatObject?.name) {
              setChatObject(chatObj);
            }
            if (chatObj?.primaryRole !== chatObject?.primaryRole) {
              setPrimaryAgent(getAgent(chatObj?.primaryRole));
            }
            if (chatObj?.metadata) {
              // if metadata is a sting, parse it
              if (typeof chatObj?.metadata === "string") {
                chatObj.metadata = JSON.parse(chatObj?.metadata);
              }
              setPlanningExecution(chatObj?.metadata?.planning_status);
              //  possible planning_status values: planning_not_available, planning_requires_approval, planning_approved, plan_execution_in_progress, plan_execution_completed, plan_execution_failed,
              if (
                chatObj?.metadata?.planning_status ==
                "plan_execution_in_progress"
              ) {
                setIsAIStreaming(true);
                setActiveStep(chatObj?.metadata?.active_step);
                setTotalSteps(chatObj?.metadata?.plan?.length || 0);
              } else if (
                [
                  "plan_execution_completed",
                  "plan_execution_failed",
                  "planning_not_available",
                ].includes(chatObj?.metadata?.planning_status)
              ) {
                setIsAIStreaming(false);
              }
            }
          }
        },
        error: (error) => {
          console.log(`Error with chat subscription: ${chatId}`, error);
        },
      });
      setChatSubscriptionObject(chatSubscriptionObj);
      setSubscriptionObject(subscriptionObj);
    }

    return () => {
      if (subscriptionObj) subscriptionObj.unsubscribe();
      if (chatSubscriptionObj) chatSubscriptionObj.unsubscribe();
      if (subscriptionObjCreate) subscriptionObjCreate.unsubscribe();
    };
  }, [chatId, lastSubscribedChatId, loadingMessageId]);
  useEffect(() => {
    if (transcript?.text) {
      AppQuantumEditorUtils.Utilities.insertTextAtSelection(
        editor,
        transcript.text || ""
      );
      setValue(editor?.children);
    }
  }, [transcript]);
  const saveTopics = async (chatId, payload) => {
    try {
      await chatsHelper.updateChat(chatId, payload);
    } catch (error) {
      console.log(error);
      AppToasty.show(t("errorSavingTopics"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  useEffect(() => {
    if (chatId && selectedTopics && isLoading === false) {
      saveTopics(chatId, [
        {
          property: "topics",
          value: selectedTopics ? JSON.stringify(selectedTopics) : null,
        },
      ]);
    }
  }, [selectedTopics?.length]);
  useEffect(() => {
    if (isMobileScreen()) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isMobileScreen()]);
  const adaptMessages = (_messages) => {
    return _messages.map((message) => {
      return {
        _id: message.id,
        user: {
          id: message.userID,
          name: message.role,
          agentName:
            message?.agentID != primaryAgent?.id
              ? getAgent(message?.agentID)?.label
              : null,
        },
        text: message.text,
        createdAt: message.creationTime,
        metadata: message.metadata,
        callbackMetadata: message.callbackMetadata,
        humanFeedback: message.humanFeedback,
        humanFeedbackType: message.humanFeedbackType,
        humanFeedbackSubType: message.humanFeedbackSubType,
        humanFeedbackComment: message.humanFeedbackComment,
        images: message.images,
        audios: message.audios,
        videos: message.videos,
        files: message.files,
        agentName: getAgent(message?.agentID)?.label,
      };
    });
  };
  const loadMessages = async (chatId) => {
    setIsLoading(true);
    let _messages = await messagesHelper.getChatMessages(chatId);
    _messages = _messages?.data?.messagesByChat?.items?.filter(
      (u) => u?._deleted != true
    );
    _messages = _messages.map((message) => {
      if (typeof message.metadata === "string") {
        message.metadata = JSON.parse(message.metadata);
      }
      if (typeof message.callbackMetadata === "string") {
        message.callbackMetadata = JSON.parse(message.callbackMetadata);
      }
      return message;
    });
    const sortedMessage = _messages.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    setStateMessages(adaptMessages(sortedMessage));
    // sort messages by createdAt
    const lastSortedMessages = sortedMessage.slice(-loadedMessages);
    setMessages(adaptMessages(lastSortedMessages));
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };
  useEffect(() => {
    if (
      chatId &&
      getActiveWorkspace()?.id &&
      chatId !== loadedChatId &&
      state?.agents?.length
    ) {
      setLoadedMessages(20);
      setSelectedNode({ id: chatId });
      setLoadedChatId(chatId);
      loadMessages(chatId);
    }
  }, [chatId, getActiveWorkspace()?.id, loadedChatId, state?.agents]);
  const getChatObject = async (chatId) => {
    let chatObj = getChat(chatId);
    if (!chatObj && chatId)
      try {
        const result = await chatsHelper.getChat(chatId);
        chatObj = result?.data?.getChat;
        if (chatObj?.topics && typeof chatObj?.topics === "string")
          chatObj.topics = JSON.parse(chatObj.topics);
        dispatch("SET_CHATS", [...state?.chats, chatObj]);
      } catch (error) {
        console.log(error);
        AppToasty.show(t("errorLoadingChat"), {
          placement: "bottom",
          type: "danger",
        });
      }
    setChatObject(chatObj);
  };
  const lastMessageFromAssistant =
    messages?.filter((u) => u?.user?.name === "assistant")?.slice(-1)[0] || {};
  useEffect(() => {
    if (chatId != loadedChatId || (!chatObject && chatId)) {
      getChatObject(chatId);
    }
  }, [chatId, getActiveWorkspace()?.id, JSON.stringify(chats), isLoading]);
  useEffect(() => {
    if (chatObject?.primaryRole) {
      const agent = getAgent(chatObject?.primaryRole);
      setCanInteractWithChat(chatObject?.visibility !== "widget");
      setPrimaryAgent(agent);
      if (chatObject?.topics && typeof chatObject?.topics === "string") {
        setSelectedTopics(JSON.parse(chatObject?.topics) || []);
      } else {
        setSelectedTopics(chatObject?.topics || []);
      }
      if (chatObject?.metadata && typeof chatObject?.metadata === "string") {
        setChatMetadata(JSON.parse(chatObject?.metadata) || {});
      } else {
        setChatMetadata(chatObject?.metadata || {});
      }
    }
  }, [JSON.stringify(chatObject), JSON.stringify(state?.agents)]);
  useEffect(() => {
    setActiveStep(chatObject?.metadata?.active_step || 0);
    setPlanningExecution(chatObject?.metadata?.planning_execution);
    setTotalSteps(chatObject?.metadata?.plan?.length || 0);
  }, [JSON.stringify(chatObject?.metadata)]);
  const goToChat = (chatId) => {
    if (chatId) {
      history.push(`/workspaces/${getActiveWorkspace()?.id}/chats/${chatId}`);
    }
  };
  const goToChatsOverview = () => {
    history.push(`/workspaces/${getActiveWorkspace()?.id}/chats`);
  };
  const removeNode = async (node) => {
    setDeletionInProgress(true);
    setShowModal(false);
    setIsLoading(true);
    if (selectedNode?.id === selectedNodeToDelete.id) {
      try {
        result = await chatsHelper.deleteChat(selectedNode?.id);
        const indexOfDeletedNode =
          state?.treeViewData
            ?.filter((u) => !u.data?.droppable)
            .map((u) => u.id)
            ?.indexOf(selectedNodeToDelete.id) - 1;
        AppToasty.show(t("chatDeleted"), {
          placement: "bottom",
          type: "success",
        });
        setChats(chats.filter((u) => u.id?.toString() != node?.id));
      } catch (error) {
        console.error(error);
        AppToasty.show(t("errorDeletingChat"), {
          placement: "bottom",
          type: "danger",
        });
      } finally {
        goToChatsOverview();
        setDeletionInProgress(false);
        setIsLoading(false);
      }
    }
  };
  const updateNodeName = (node, name) => {
    let itemIndex;
    itemIndex = state?.chats.findIndex((u) => u.id == node.id);
    state.chats[itemIndex].name = name;
    dispatch("SET_CHATS", [...state.chats]);
  };

  const handleClickOnNode = (node, toDelete = false) => {
    if (toDelete) setSelectedNodeToDelete(node);
    else {
      setSelectedNode(node);
      if (node && node?.id !== selectedNode?.id && !node?.data?.droppable) {
        setIsLoading(true);
        goToChat(node?.id);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    }
  };

  const setDialogActionOnTree = useCallback(
    (action) => {
      setShowModal(true);
      setTreeViewActionContext(action);
    },
    [setShowModal, setTreeViewActionContext]
  );
  const onMove = (object) => {
    console.log("onMove", object);
  };
  const executePostChatCreationActions = () => {
    setIsLoading(false);
    setCreationOfNewChatInProgress(false);
    setSelectedTopics([]);
    loadChats(false, true);
    setShowWidgetChatsHistory(false);
  };
  const handleParentActionClick = async (agentid) => {
    result = await chatsHelper.createChat({
      workspaceID: getActiveWorkspace()?.id,
      allowMultipleRoles: false,
      createdBy: user?.id,
      visibility: "private",
      primaryRole: agentid,
      plannerAgent: agentid,
      name: "New Chat",
      creationTime: Math.floor(new Date().getTime() / 1000),
    });
    history.push(
      `/workspaces/${getActiveWorkspace()?.id}/chats/${
        result?.data?.createChat?.id
      }`
    );
    AppToasty.show(t("chatCreationCompleted"), {
      placement: "bottom",
      type: "success",
    });
    executePostChatCreationActions();
  };
  const createChat = async (selectedNode, values, isEdit) => {
    try {
      setIsLoading(true);
      setCreationOfNewChatInProgress(true);
      let channelSettings = null;
      let externalParticipantId = null;
      if (values?.toPhoneNumber || values?.toEmail) {
        if (values?.channelType == "email")
          externalParticipantId = values?.toEmail;
        else if (
          values?.channelType == "sms" ||
          values?.channelType == "whatsapp"
        )
          externalParticipantId = values?.toPhoneNumber;
        channelSettings = {
          sms: {
            isEnabled: values?.channelType == "sms",
            recipient: values?.toPhoneNumber,
            providerID:
              values?.channelType == "sms" ? values?.providerId : null,
          },
          whatsapp: {
            isEnabled: values?.channelType == "whatsapp",
            recipient: values?.toPhoneNumber,
            providerID:
              values?.channelType == "whatsapp" ? values?.providerId : null,
          },
          email: {
            isEnabled: values?.channelType == "email",
            recipient: values?.toEmail,
            providerID:
              values?.channelType == "email" ? values?.providerId : null,
          },
        };
      }
      delete values.toPhoneNumber;
      delete values.toEmail;
      delete values.channelType;
      delete values.providerId;
      // CREATE CHAT
      if (isEdit) {
        result = await chatsHelper.updateChat(selectedNode?.id, [
          { property: "name", value: values.name },
          { property: "description", value: values.description },
          { property: "primaryRole", value: values.primaryRole },
          { property: "plannerAgent", value: values.primaryRole },
          {
            property: "visibility",
            value: values.isPublic ? "public" : "private",
          },
          {
            property: "channelSettings",
            value: JSON.stringify(channelSettings),
          },
          {
            property: "externalParticipantId",
            value: externalParticipantId,
          },
        ]);

        AppToasty.show(t("chatUpdateCompleted"), {
          placement: "bottom",
          type: "success",
        });
      } else {
        const _visibility = values.isPublic ? "public" : "private";
        delete values.isPublic;
        result = await chatsHelper.createChat({
          ...values,
          plannerAgent: values.primaryRole,
          workspaceID: getActiveWorkspace()?.id,
          allowMultipleRoles: false,
          createdBy: user?.id,
          visibility: _visibility,
          creationTime: Math.floor(new Date().getTime() / 1000),
          channelSettings: JSON.stringify(channelSettings),
          externalParticipantId: externalParticipantId,
        });
        history.push(
          `/workspaces/${getActiveWorkspace()?.id}/chats/${
            result?.data?.createChat?.id
          }`
        );
        AppToasty.show(t("chatCreationCompleted"), {
          placement: "bottom",
          type: "success",
        });
      }
      setShowModal(false);
      setIsLoading(false);
      setCreationOfNewChatInProgress(false);
      setSelectedTopics([]);
      loadChats(false, true);
      setShowWidgetChatsHistory(false);
    } catch (error) {
      console.error("Error creating chat", error);
      AppToasty.show(t("chatCreationError"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  const onLoadEarlier = async () => {
    let newLoadedMessages = loadedMessages + 20;
    setMessages(stateMessages.slice(-newLoadedMessages));
    setLoadedMessages(newLoadedMessages);
  };
  function wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onSendEvent = async (message) => {
    try {
      if (message.trim().length === 0) return;
      if (isAIStreaming) {
        subscriptionObj.unsubscribe();
        await messagesHelper.updateChatMessage(streamingMessageID, [
          {
            property: "status",
            value: "cancelled",
          },
        ]);
        setIsAIStreaming(false);
        setLastUpdateMessageTime(null);
        await wait(1000);
      }
      setAllowStop(false);
      setIsAIStreaming(true);
      const promises = [
        messagesHelper.createChatMessage({
          chatID: selectedNode?.id,
          workspaceID: getActiveWorkspace()?.id,
          text: message,
          role: user?.username,
          userID: user?.id,
          isAI: false,
          creationTime: Math.floor(new Date().getTime() / 1000),
          images: imageKey ? [imageKey] : null,
          audios: audioKey ? [audioKey] : null,
          videos: videoKey ? [videoKey] : null,
          files: fileKeys ? fileKeys : null,
          // audiosText: audiosText,
          // videosText: videosText,
        }),
        messagesHelper.createChatMessage({
          chatID: selectedNode?.id,
          workspaceID: getActiveWorkspace()?.id,
          text: "**...**",
          role: "assistant",
          isAI: true,
          creationTime: Math.floor(new Date().getTime() / 1000) + 1,
          agentID: primaryAgent?.id,
        }),
      ];
      const results = await Promise.all(promises);
      let newMessage = results[0]?.data?.createChatMessage;
      let assistantPlaceholder = results[1]?.data?.createChatMessage;
      setMessages((previousMessages) => [
        ...previousMessages,
        {
          _id: newMessage?.id,
          text: message,
          createdAt: Math.floor(new Date().getTime() / 1000),
          user: { id: user?.id, name: user?.username, agentName: null },
          loading: false,
          images: imageKey ? [imageKey] : null,
          audios: audioKey ? [audioKey] : null,
          videos: videoKey ? [videoKey] : null,
          files: fileKeys ? fileKeys : null,
        },
        {
          _id: assistantPlaceholder?.id,
          text: "",
          createdAt: Math.floor(new Date().getTime() / 1000) + 1,
          user: {
            id: null,
            name: "assistant",
            parentMessageId: newMessage?.id,
            agentName: primaryAgent?.label,
          },
          loading: true,
        },
      ]);
      setStreamingMessageID(assistantPlaceholder?.id);
      await Promise.all([
        Chatter.getAnswerinChat({
          chatid: selectedNode?.id,
          messages: [
            {
              text: message,
              role: user?.username,
              userID: user?.id,
            },
          ],
          baseImage: selectedImageRawKey,
          agentid: primaryAgent?.id,
          token: getActiveWorkspace()?.workspaceToken,
          workspaceid: getActiveWorkspace()?.id,
          stream: "true",
          nextMessageId: assistantPlaceholder?.id,
          methodName: primaryAgent?.mode == "planner" ? "planner" : "chatter",
        }),
        chatsHelper.updateChat(selectedNode?.id, [
          {
            property: "isAIReplying",
            value: true,
          },
          {
            property: "activeMessageForAI",
            value: assistantPlaceholder?.id,
          },
          {
            property: "forcePlannerStop",
            value: false,
          },
        ]),
      ]);
      // Change this
    } catch (error) {
      console.error(error);
      try {
        setIsAIStreaming(false);
        await chatsHelper.updateChat(selectedNode?.id, [
          {
            property: "isAIReplying",
            value: false,
          },
          {
            property: "activeMessageForAI",
            value: null,
          },
          {
            property: "forcePlannerStop",
            value: false,
          },
        ]);
      } catch (error) {
        console.error(error);
      }

      AppToasty.show(t("chatMessageAdditionError"), {
        placement: "bottom",
        type: "danger",
      });
    } finally {
      // remove the loading message by checking the loading property
      // setMessages((previousMessages) =>
      //   previousMessages.filter((m) => !m.loading)
      // );
    }
  };
  const onSelectedTopicsChange = useCallback(
    (selectedItems) => {
      if (typeof selectedItems === "string") {
        selectedItems = JSON.parse(selectedItems);
      }
      if (!isLoading) setSelectedTopics(selectedItems);
    },
    [setSelectedTopics, isLoading]
  );
  useEffect(() => {
    if (getActiveWorkspace()?.id) {
      loadChats();
    }
  }, [getActiveWorkspace()?.id]);
  useEffect(() => {
    window.onmessage = (e) => {
      if (e?.data?.tf_event_type == "form_submit" && "data" in e.data) {
        alert(JSON.stringify(e.data));
      }
    };
  }, [chatId]);
  useEffect(() => {
    // make sure chats are unique by id
    let uniqueChats = [];
    if (chats) {
      uniqueChats = chats.filter(
        (u, index, self) => index === self.findIndex((t) => t.id === u.id)
      );
    }
    setCoreTreeViewData(groupChatsByProperty(uniqueChats, "createdAt"));
  }, [
    JSON.stringify(
      chats?.map((u) => {
        return {
          id: u.id,
          createdAt: u.createdAt,
          primaryRole: u.primaryRole,
          visibility: u.visibility,
          name: u.name,
        };
      })
    ),
    state?.agents?.length,
  ]);
  const onDrop = async ({
    newTreeData,
    dragSourceId,
    dropTargetId,
    rest,
  }) => {};
  const handleShowSourcesDetails = (sources) => {
    setSelectedSources(sources);
    setShowSourcesModal(true);
  };
  function groupChatsByProperty(chats, property = "primaryRole") {
    let groupedChats = {};
    state?.agents
      ?.filter((u) => u?.agentType == "Chatbot")
      ?.map((u) => {
        groupedChats[u.id] = {
          id: u.id,
          name: u.label,
          is_agent: true,
          agentType: u?.agentType,
          mode: u?.mode,
          droppable: true,
          children: [],
        };
      });

    chats.forEach((chat) => {
      const groupKey = chat[property];
      const agent = state?.agents?.find(
        (agent) => agent.id === chat["primaryRole"]
      );
      const date = dayjs(groupKey);
      const formattedDate = date.format("DD MMM YY");
      if (!agent) {
        if (!groupedChats["deleted_agents_id"])
          groupedChats["deleted_agents_id"] = {
            id: "deleted_agents_id",
            name: "Agent not available",
            is_agent: true,
            is_agent_available: false,
            droppable: true,
            children: [],
          };
        if (
          !groupedChats["deleted_agents_id"].children.find(
            (u) => u.id === "deleted_agents_id" + formattedDate
          )
        ) {
          groupedChats["deleted_agents_id"].children.push({
            id: "deleted_agents_id" + formattedDate,
            name: formattedDate, // Make sure the getAgent() function is defined
            droppable: true,
            children: [],
          });
        }

        groupedChats["deleted_agents_id"].children
          .find((u) => u.id === "deleted_agents_id" + formattedDate)
          .children.push(chat);
        return;
      }

      if (!groupedChats[agent?.id])
        groupedChats[agent?.id] = {
          id: agent?.id,
          name: agent?.label,
          is_agent: true,
          agentType: agent?.agentType,
          mode: agent?.mode,
          droppable: true,
          children: [],
        };
      if (
        !groupedChats[agent?.id].children.find(
          (u) => u.id === agent?.id + formattedDate
        )
      ) {
        groupedChats[agent?.id].children.push({
          id: agent?.id + formattedDate,
          name: formattedDate, // Make sure the getAgent() function is defined
          droppable: true,
          children: [],
        });
      }

      groupedChats[agent?.id].children
        .find((u) => u.id === agent?.id + formattedDate)
        .children.push(chat);
    });
    // Convert the grouped chats to an array and sort by date in descending order
    const sortedGroupedChats = Object.values(groupedChats).sort((a, b) => {
      return dayjs(b.id).valueOf() - dayjs(a.id).valueOf();
    });
    return sortedGroupedChats;
  }
  const isAIReplyingOtherUsers =
    state?.chats?.find((chat) => chat.id === chatId)?.isAIReplying &&
    !isAIStreaming;
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ChatsScreenView
        showWidgetChatsHistory={showWidgetChatsHistory}
        handleClickOnWidgetChatsHistory={handleClickOnWidgetChatsHistory}
        setShowWidgetChatsHistory={setShowWidgetChatsHistory}
        isMoreToLoad={!state?.chatsLoaded}
        errorWithSubscription={errorWithSubscription}
        handleClickOnNode={handleClickOnNode}
        SaveChat={createChat}
        selectedNode={selectedNode}
        deleteNode={removeNode}
        showModal={showModal}
        setShowModal={setShowModal}
        setDialogActionOnTree={setDialogActionOnTree}
        treeViewActionContext={treeViewActionContext}
        isTreeDataLoading={!isChatHistoryLoaded || deletionInProgress}
        selectedTopics={selectedTopics}
        onSelectedTopicsChange={onSelectedTopicsChange}
        topics={generateAdaptedTopics(topics)}
        onDrop={onDrop}
        _coreTreeViewData={_coreTreeViewData}
        selectedNodeToDelete={selectedNodeToDelete}
        creationOfNewChatInProgress={creationOfNewChatInProgress}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        setSelectedNode={setSelectedNode}
        onMove={onMove}
        chatId={chatId}
        isLoading={isLoading}
        primaryAgent={primaryAgent}
        messages={messages}
        setMessages={setMessages}
        onSendEvent={onSendEvent}
        chatObject={chatObject}
        isAIStreaming={isAIStreaming}
        isAIReplyingOtherUsers={isAIReplyingOtherUsers}
        onCancelGeneration={onCancelGeneration}
        inputText={inputText}
        setInputText={setInputText}
        onLoadEarlier={onLoadEarlier}
        allMessagesLoaded={stateMessages.length >= loadedMessages}
        subscriptionObj={subscriptionObj}
        streamingMessageID={streamingMessageID}
        documents={documents}
        documentId={documentId}
        setTreeViewActionContext={setTreeViewActionContext}
        S3downloadUrlRequest={S3downloadUrlRequest}
        handleFeedback={handleFeedback}
        workspaceid={getActiveWorkspace()?.id}
        onImageSelected={onImageSelected}
        selectedImageKey={selectedImageKey}
        handleCopy={handleCopy}
        handleShowSourcesDetails={handleShowSourcesDetails}
        showSourcesModal={showSourcesModal}
        setShowSourcesModal={setShowSourcesModal}
        selectedSources={selectedSources}
        allowStop={allowStop}
        setIsChatHistoryLoaded={setIsChatHistoryLoaded}
        isLastMessageEmpty={lastMessageFromAssistant?.text == ""}
        canInteractWithChat={canInteractWithChat && primaryAgent?.label != null}
        handleParentActionClick={handleParentActionClick}
        onImageUploaded={onImageUploaded}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        onAudioUploaded={onAudioUploaded}
        audioUrl={audioUrl}
        setAudioUrl={setAudioUrl}
        onVideoUploaded={onVideoUploaded}
        videoUrl={videoUrl}
        setVideoUrl={setVideoUrl}
        onFileUploaded={onFileUploaded}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        onFilesUploaded={onFilesUploaded}
        fileUrls={fileUrls}
        setFileUrls={setFileUrls}
        forceAgentsName={getAgent(chatObject?.plannerAgent)?.mode == "planner"}
        activeStep={activeStep}
        planningExecution={planningExecution}
        totalSteps={totalSteps}
        isSpeechGettingProcessed={isSpeechGettingProcessed}
        updateTextForSpeech={updateTextForSpeech}
        stopAudio={() => {
          stopAudio();
          setIsPlaying(false);
        }}
        isPlaying={isPlaying}
        listenToAudio={listenToAudio}
        lastUpdateMessageTime={lastUpdateMessageTime}
      />
    </Suspense>
  );
};

export default Chats;
