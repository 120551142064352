import AppExpander from "@toothfairy/shared-ui/AppExpander";
import React from "react";
import { View } from "react-native";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AnalysisPreferencesMRC from "./SubComponents/AnalysisPreferencesMRC";
import AnalysisPreferencesNLP from "./SubComponents/AnalysisPreferencesNLP";
import AnalysisPreferencesGN from "./SubComponents/AnalysisPreferencesGN";
import { isLargeScreen } from "../../MainViewContainer";
import useWorkspaces from "../../../Hooks/useWorkspaces";

const ParsingSettings = ({ handleWorkspaceUpdate }) => {
  const { getPlanConfig } = useWorkspaces();
  return (
    <View
      style={{
        zIndex: -1,
      }}
    >
      {/* {getPlanConfig()?.isContentCreatorEnabled && (
        <AppExpander
          style={{ minWidth: isLargeScreen() ? 530 : 250 }}
          isExpandedOnLoad={true}
          title="Content creator"
          icon={AppAssets.icons.writer}
        >
          <AnalysisPreferencesGN handleUpdate={handleWorkspaceUpdate} />
        </AppExpander>
      )}
      {getPlanConfig()?.isBusinessAnalystEnabled && (
        <AppExpander
          style={{ minWidth: isLargeScreen() ? 530 : 250 }}
          isExpandedOnLoad={true}
          title="Business analyst"
          icon={AppAssets.icons.answerer}
        >
          <AnalysisPreferencesMRC handleUpdate={handleWorkspaceUpdate} />
        </AppExpander>
      )} */}
      {getPlanConfig()?.isSentimentReviewerEnabled && (
        <AppExpander
          style={{ minWidth: isLargeScreen() ? 530 : 250 }}
          isExpandedOnLoad={true}
          title="Sentiment reviewer"
          icon={AppAssets.icons.trainingLog}
        >
          <AnalysisPreferencesNLP
            showReplyLanguage={false}
            handleUpdate={handleWorkspaceUpdate}
          />
        </AppExpander>
      )}
      {!getPlanConfig()?.isSentimentReviewerEnabled &&
        !getPlanConfig()?.isBusinessAnalystEnabled &&
        !getPlanConfig()?.isContentCreatorEnabled && (
          <AppPlaceholderView
            wrapperStyle={{
              width: "100%",
              height: "100%",
              marginVertical: 20,
              marginHorizontal: "auto",
              flex: 1,
            }}
            size={80}
            icon={AppAssets.icons.orbit}
            mainCaption="EmptyPlaceholder"
            subCaption="SettingsSubCaption"
          />
        )}
    </View>
  );
};

export default ParsingSettings;
