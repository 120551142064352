import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import envConfig from "../../../envConfig";

const getCosts = async ({ workspaceId, month, year }) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/monthCosts?workspaceId=${workspaceId}&month=${month}&year=${year}`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getStripeCustomer = async () => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/customer`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getStripeCheckout = async () => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/checkout`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const saveStripeCustomer = async (requestBody) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/customer`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    requestBody,
    true,
    null,
    payload
  );
};

const getStripePortal = async () => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/portal`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getStripeClientSecret = async () => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/clientSecret`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getStripeClientInvoices = async (starting_after) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/invoices`;
  if (starting_after) url = url + `?starting_after=${starting_after}`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getClientPaymentMethods = async () => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/clientPaymentMethods`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const deleteClientPaymentMethod = async (requestBody) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/deletePaymentMethod`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    requestBody,
    true,
    null,
    payload
  );
};

const createClientPaymentIntent = async (requestBody) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/createPaymentIntent`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    requestBody,
    true,
    null,
    payload
  );
};

const updateUsageForWorkspace = async (requestBody) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/billing/updateUsageRecordsForWorkspace`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    requestBody,
    true,
    null,
    payload
  );
};

export default {
  getCosts,
  getStripeCustomer,
  getStripeCheckout,
  saveStripeCustomer,
  getStripePortal,
  getStripeClientSecret,
  getStripeClientInvoices,
  getClientPaymentMethods,
  deleteClientPaymentMethod,
  createClientPaymentIntent,
  updateUsageForWorkspace,
};
