import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppMultipleSelectInput from "@toothfairy/shared-ui/AppMultipleSelectInput";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairySlider from "../../../Components/ToothFairyComponents/ToothFairySlider";
import ToothFairySwitch from "../../../Components/ToothFairyComponents/ToothFairySwitch";
import AnalysisPreferencesGN from "../../Settings/Components/SubComponents/AnalysisPreferencesGN";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../MainViewContainer";

const MetaSelection = ({
  selectedTab,
  setIsBatchInitiationModalOpen,
  batchConfig,
  setBatchConfig,
  topics,
  handleBatchInitiation,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();

  const { state } = AppStateManager.useAppStateManager();
  const { generateAdaptedTopics, getActiveWorkspace } = useWorkspaces();
  const _inputFields = useMemo(() => {
    return (
      <>
        {(selectedTab?.routeId === "rc" || selectedTab?.routeId === "gn") && (
          <AppDropDown
            withEmptyItem
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            wrapperContainer={{
              marginRight: 30,
              marginBottom: 20,
            }}
            items={[
              {
                type: "noSelection",
                label: "Select an agent",
              },
              ...state?.agents?.filter((u) => {
                if (selectedTab?.routeId === "rc") {
                  return u?.agentType === "BusinessAnalyst";
                } else if (selectedTab?.routeId === "gn") {
                  return u?.agentType === "ContentCreator";
                } else {
                  return true;
                }
              }),
            ]}
            propertyLabel="label"
            propertyValue="id"
            onValueChange={(value) => {
              console.log("value", value);
              setBatchConfig({
                ...batchConfig,
                agentId: value,
              });
            }}
          />
        )}

        <AppTextInput
          placeholder="batchNamePlaceholder"
          style={{
            marginTop: 2,
          }}
          wrapperInputStyle={{
            maxWidth: 470,
            marginBottom: 20,
          }}
          textContainer={{
            margin: 0,
          }}
          type="text"
          value={batchConfig?.name}
          onChangeText={(text) => {
            setBatchConfig({ ...batchConfig, name: text });
          }}
        />
        <AppTextInput
          multiline={true}
          numberOfLines={4}
          placeholder="batchDescrPlaceholder"
          style={{
            marginTop: 2,
          }}
          wrapperInputStyle={{
            maxWidth: 470,
            marginBottom: 20,
          }}
          textContainer={{
            margin: 0,
            padding: 10,
          }}
          type="text"
          value={batchConfig?.description}
          onChangeText={(text) => {
            setBatchConfig({ ...batchConfig, description: text });
          }}
        />
      </>
    );
  }, [
    batchConfig?.name,
    batchConfig?.description,
    setBatchConfig,
    JSON.stringify(batchConfig),
    JSON.stringify(state?.agents),
    selectedTab?.routeId,
  ]);
  const _rcInputFields = useMemo(() => {
    return (
      <>
        <AppMultipleSelectInput
          showTextInputLabel={true}
          isMandatory={true}
          onSelectionChange={(v) => {
            setBatchConfig({
              ...batchConfig,
              topics: v?.map((u) => u?.id),
            });
          }}
          inputLabelStyle={{
            marginLeft: 5,
            marginBottom: 5,
          }}
          label={"Topics"}
          selectedItems={batchConfig?.rawTopics}
          placeholder="Select topics"
          items={generateAdaptedTopics(topics)}
          uniqueKey="id"
          showDropDowns={true}
          readOnlyHeadings={true}
          labelOption={"name"}
          groupByProperty="category"
          style={{ maxWidth: 470 }}
        />
        <ToothFairySwitch
          wrapperStyle={{
            flex: 1,
            marginRight: 20,
            backgroundColor: currentTheme?.theme?.inputBackgroundColor,
            marginTop: 20,
            zIndex: -1,
          }}
          label={"Answer generation"}
          onChange={(v) => {
            setBatchConfig({
              ...batchConfig,
              fileUploadRequired: v,
              isQuestionGeneration: !v,
            });
          }}
          isTicked={batchConfig?.fileUploadRequired}
        />

        {batchConfig?.fileUploadRequired === true ? (
          <View
            style={{
              marginTop: 20,
              zIndex: -1,
            }}
          >
            <ToothFairySwitch
              wrapperStyle={{
                flex: 1,
                marginRight: 20,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                marginBottom: 20,
              }}
              label={"Unique answer"}
              onChange={(v) => {
                setBatchConfig({
                  ...batchConfig,
                  uniqueAnswer: v,
                });
              }}
              isTicked={batchConfig?.uniqueAnswer}
            />
            <ToothFairySwitch
              wrapperStyle={{
                flex: 1,
                marginRight: 20,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                marginBottom: 20,
              }}
              label={"Answer by paragraph"}
              onChange={(v) => {
                setBatchConfig({
                  ...batchConfig,
                  answerByParagraph: v,
                });
              }}
              isTicked={batchConfig?.answerByParagraph}
            />
            <ToothFairySwitch
              wrapperStyle={{
                flex: 1,
                marginRight: 20,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                marginBottom: 20,
              }}
              label={"Use RC training data as content source"}
              onChange={(v) => {
                setBatchConfig({
                  ...batchConfig,
                  includeReadComprehensionData: v,
                });
              }}
              isTicked={batchConfig?.includeReadComprehensionData}
            />
          </View>
        ) : (
          <View
            style={{
              marginTop: 20,
              zIndex: -1,
            }}
          >
            {state?.languagesConfig?.length > 0 && (
              <AppDropDown
                showTextInputLabel
                label="Language"
                inputLabelStyle={{
                  marginLeft: 0,
                  marginRight: 10,
                  marginTop: 10,
                }}
                dropDownStyle={{
                  borderColor: currentTheme?.theme?.border_color,
                  maxWidth: 250,
                }}
                wrapperContainer={{
                  flexDirection: "row",
                  alignItems: "baseline",
                  marginBottom: 20,
                }}
                isElevated={false}
                dropdownIconColor="transparent"
                items={[
                  { id: "def", name: "Auto-detect" },
                  ...state?.languagesConfig,
                ]}
                propertyLabel="name"
                propertyValue="id"
                selectedValue={batchConfig?.questionLanguage}
                onValueChange={(itemValue, itemIndex) => {
                  setBatchConfig({
                    ...batchConfig,
                    questionLanguage: itemValue,
                  });
                }}
              />
            )}

            <ToothFairySlider
              step={1}
              maximumValue={100}
              minimumValue={1}
              label="Questions limit"
              onSubmit={(v) =>
                setBatchConfig({
                  ...batchConfig,
                  limit: v,
                })
              }
              initialValue={batchConfig?.questionsLimit || 10}
            />
          </View>
        )}
      </>
    );
  }, [
    JSON.stringify(batchConfig?.topics),
    batchConfig?.fileUploadRequired,
    batchConfig?.uniqueAnswer,
    batchConfig?.answerByParagraph,
    batchConfig?.includeReadComprehensionData,
    batchConfig?.questionLanguage,
    batchConfig?.questionsLimit,
    JSON.stringify(topics),
    setBatchConfig,
    batchConfig?.name,
    batchConfig?.description,
  ]);
  const _gnInputFields = useMemo(() => {
    return (
      <AnalysisPreferencesGN
        handleUpdate={(property, value) => {
          setBatchConfig({
            ...batchConfig,
            [property]: value,
          });
        }}
        isWorkspaceSetting={false}
        config={batchConfig}
      />
    );
  }, [setBatchConfig, JSON.stringify(batchConfig)]);
  return (
    <AppChoice
      wrapperStyle={{ alignSelf: "center", maxWidth: 600 }}
      caption={`You are about to initiate a ${Constants.mapKeysToNames(
        selectedTab?.routeId,
        true
      )} batch`}
      subCaption="The process will generate a csv file that will be available for download in the batch logs section"
      cancelCaption="Click 'Cancel' to abort"
      confirmCaption="Click 'Confirm' to start the batch"
      onConfirmButtonPress={() => {
        if (
          (batchConfig?.agentId == null ||
            batchConfig?.agentId === "noSelection" ||
            batchConfig?.agentId == "[object Object]") &&
          (selectedTab?.routeId === "rc" || selectedTab?.routeId === "gn")
        ) {
          AppToasty.show("Please select an agent", {
            placement: "bottom",
            type: "danger",
          });
          return;
        }
        if (
          selectedTab?.routeId === "rc" ||
          selectedTab?.routeId === "nlp" ||
          selectedTab?.routeId === "gn"
        ) {
          handleBatchInitiation(
            selectedTab?.routeId === "gn" ||
              batchConfig?.fileUploadRequired === true
          );
          setIsBatchInitiationModalOpen(false);
        } else {
          AppToasty.show(t("batchParsingCreationFailedCaption"), {
            placement: "bottom",
            type: "danger",
          });
        }
      }}
      onCancelButtonPress={() => {
        setIsBatchInitiationModalOpen(false);
      }}
      AppChoiceButton={ToothFairyButton}
    >
      <View
        style={{
          flexDirection: "column",
          marginTop: 20,
        }}
      >
        {_inputFields}
      </View>
    </AppChoice>
  );
};

export default MetaSelection;
