import useAPI from "@toothfairy/shared-api/useApi";
import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { View, useWindowDimensions } from "react-native";
import React, { useEffect, useMemo, useState } from "react";
import { Document, Outline, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import envConfig from "../../../envConfig";
import Files from "../../API/Files";
import useWorkspaces from "../../Hooks/useWorkspaces";
import LoadingPlaceholder from "../LoadingPlaceholder";
import DelayedRender from "../DelayRenderer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ path, onPDFDocumentLoad = () => {} }) => {
  const maxZoom = 5;
  const minZoom = 1;
  const [zoomLevel, setZoomLevel] = useState(2);
  const [url, setUrl] = useState(null);
  const [pages, setPages] = useState(null);
  const { height } = useWindowDimensions();
  const [pageNumber, setPageNumber] = useState(1);
  const { currentTheme } = AppTheme.useTheme();
  const { getActiveWorkspace } = useWorkspaces();
  const [value, setValue] = useState("");

  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const getUrl = async () => {
    const _url = await S3downloadUrlRequest({
      workspaceid: getActiveWorkspace()?.id,
      logId: null,
      type: "pdf",
      filename: path.replace(
        `s3://files-to-import-aus-${envConfig.REACT_APP_ENV}/`,
        ""
      ),
      context: "pdf",
    });
    setUrl(_url);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setPages(numPages);
    if (numPages && pageNumber == null) setPageNumber(1);
    onPDFDocumentLoad(numPages);
  }
  useEffect(() => {
    getUrl();
    setPageNumber(1);
    setPages(null);
  }, [path]);
  // handle change in zoom level to make sure the max zoom level is 3 and min zoom level is 1. the function gets in input +1 or -1
  const handleZoomLevelChange = (zoomLevelChange) => {
    if (zoomLevelChange === 1 && zoomLevel < maxZoom) {
      setZoomLevel(zoomLevel + 1);
    } else if (zoomLevelChange === -1 && zoomLevel > minZoom) {
      setZoomLevel(zoomLevel - 1);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      let num = parseInt(value);
      // if value is not a number, reset the textInput value to an empty string
      if (isNaN(num)) {
        setValue("");
      } else {
        if (num > pages) {
          num = pages;
        } else if (num < 1) {
          num = 1;
        }
        setPageNumber(num);
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    setValue(value);
  };
  const corePdf = useMemo(() => {
    return (
      <Document
        error="Attempting to reload PDF"
        file={{ url: url?.url }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.log("Inside Error", error)}
        loading={
          <LoadingPlaceholder
            style={{
              backgroundColor: currentTheme?.theme?.transparent,
              minHeight: 250,
              position: "absolute",
            }}
            visible
            caption="Loading PDF"
          />
        }
      >
        <Outline />

        <Page
          loading={
            <AppActivityIndicator
              backgroundColor={currentTheme?.theme?.transparent}
              visible={true}
            />
          }
          height={height - 270}
          scale={zoomLevel}
          key={`page_${pageNumber}`}
          pageNumber={pageNumber || 1}
        />
      </Document>
    );
  }, [url, pages, pageNumber, height, zoomLevel]);

  return urldownloadGenerationInProgress || !url ? (
    <DelayedRender>
      <AppPlaceholderView
        accessibilityLabel="doc-placeholder"
        wrapperStyle={{
          width: 300,
          height: 200,
          marginVertical: "auto",
          marginHorizontal: "auto",
        }}
        size={60}
        icon={AppAssets.icons.orbit}
        mainCaption={"DocumentDoesNotExistCaption"}
        subCaption={"DocumentDoesNotExistSubCaption"}
      />
    </DelayedRender>
  ) : (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 10,
        }}
      >
        {pages > 0 && (
          <>
            <AppPagination
              onPageChange={(page) => {
                setPageNumber(page);
              }}
              totalCount={pages}
              siblingCount={1}
              currentPage={pageNumber || 1}
              pageSize={1}
              wrapperStyle={{
                position: "relative",
                backgroundColor: currentTheme?.theme?.transparent,
                bottom: 0,
              }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <AppIcon
                icon={AppAssets?.icons?.separator}
                color={currentTheme?.theme?.mediumGrey}
                isActive={false}
                size={18}
                wrapperStyle={{
                  marginHorizontal: 10,
                }}
              />
              <AppText>Go to page</AppText>
              <input
                type="text"
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                style={{
                  width: 50,
                  fontFamily: "normalFont",
                  fontSize: currentTheme?.theme?.small_font_size,
                  color: currentTheme?.theme?.font_color,
                  backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                  border: "unset",
                  marginLeft: 5,
                  border: `1px solid ${currentTheme?.theme?.border_color}`, // Change this to your desired color
                  outline: "none",
                  boxSizing: "border-box",
                  borderRadius: 5, // optional, for rounded corners
                }}
              />
              <AppIcon
                icon={AppAssets?.icons?.separator}
                color={currentTheme?.theme?.mediumGrey}
                isActive={false}
                size={18}
                wrapperStyle={{
                  marginHorizontal: 10,
                }}
              />
              <AppIcon
                icon={AppAssets?.icons?.zoomIn}
                isClickable={zoomLevel < maxZoom}
                onPress={() => handleZoomLevelChange(1)}
                wrapperStyle={{
                  marginRight: 10,
                }}
                isActive={zoomLevel < maxZoom}
                activeColor={
                  zoomLevel < maxZoom
                    ? currentTheme?.theme?.primary
                    : currentTheme?.theme?.disabled
                }
                color={currentTheme?.theme?.mediumGrey}
                size={18}
              />
              <AppIcon
                icon={AppAssets?.icons?.zoomOut}
                isClickable={zoomLevel > minZoom}
                onPress={() => handleZoomLevelChange(-1)}
                isActive={zoomLevel > minZoom}
                activeColor={
                  zoomLevel > minZoom
                    ? currentTheme?.theme?.primary
                    : currentTheme?.theme?.disabled
                }
                color={currentTheme?.theme?.mediumGrey}
                size={18}
              />
            </View>
          </>
        )}
      </View>

      {corePdf}
    </View>
  );
};

export default PDFViewer;
