import API, { graphqlOperation } from "@aws-amplify/api";
import useAPI from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { Suspense, useEffect, useState } from "react";
import envConfig from "../../../envConfig";
import { useHistory } from "../../Router";
import ArenaScreen from "./ArenaScreen";
import chatsHelper from "../../API/Chats/chatsHelper";
import useWorkspaces from "../../Hooks/useWorkspaces";
import messagesHelper from "../../API/Messages/messagesHelper";
import {
  onCreateChatMessageByChatIDEvent,
  onUpdateChatMessageByChatIDEvent,
} from "../../graphql/subscriptions";
import Files from "../../API/Files";
import AppUser from "@toothfairy/shared-ui/AppUser";
import Chatter from "../../API/Chatter";

const AgentScreenContainer = ({
  agentId,
  workspaceId,
  setWinner,
  isWinner,
  onChatCreated,
  _inputText,
  onStreamFinished,
  _agentName,
  setAgent,
  isFirstMessage,
  setIsFirstMessage,
  chatId_prop,
}) => {
  const defaultPlaceholderMessageOnLoading = "**...**";
  const [chatId, setChatId] = useState(null);
  const history = useHistory();
  const { getAgent, getActiveWorkspace } = useWorkspaces();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const AppToasty = AppToast.useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [requestedStop, setRequestedStop] = useState(false);
  const { user } = AppUser.useUser();
  const [workspace, setWorkspace] = useState(null);
  const [subscriptionObj, setSubscriptionObject] = useState(null);
  const [subscriptionObj2, setSubscriptionObject2] = useState(null);
  const [allowStop, setAllowStop] = useState(true);
  const [isAIStreaming, setIsAIStreaming] = useState(false);
  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [messageIndex, setMessageIndex] = useState(1);
  const [inputText, setInputText] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const [agentData, setAgentData] = useState(null);
  const [encryptedToken_, setEncryptedToken_] = useState(null);
  const [agentImage, setAgentImage] = useState(null);
  const [agentLogo, setAgentLogo] = useState(null);
  const { mode } = AppTheme.useTheme();
  const cursor = mode === "light" ? "\u26AB" : "\u26AA";
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const {
    data: S3downloadUrlDataForWidget,
    loading: urldownloadGenerationInProgressForWidget,
    apiRequest: S3downloadUrlRequestForWidget,
    response: S3downloadResponseForWidget,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const fetchAgentImage = async (path, isLargeLogo) => {
    const _url = await S3downloadUrlRequest({
      filename: `imported-image/${workspaceId}/${path}`,
      context: "pdf",
      workspaceid: workspaceId,
    });
    if (isLargeLogo) setAgentLogo(_url?.url);
    else setAgentImage(_url?.url);
  };

  // const updateFavicon = (icoUrl) => {
  //   const link =
  //     document.querySelector("link[rel*='shortcut icon']") ||
  //     document.createElement("link");
  //   link.type = "image/x-icon";
  //   link.rel = "shortcut icon";
  //   link.href = icoUrl;
  //   document.getElementsByTagName("head")[0].appendChild(link);
  // };

  useEffect(() => {
    if (agentData?.icon && workspaceId) {
      fetchAgentImage(agentData?.icon);
    } else {
      setAgentImage(null);
    }
  }, [agentData?.icon, workspaceId]);

  // useEffect(() => {
  //   if (agentData?.icoUrl && workspaceId) {
  //     updateFavicon(agentData?.icoUrl);
  //   } else {
  //     updateFavicon("https://toothfairyai.com/favicon.ico");
  //   }
  // }, [agentData?.icoUrl, workspaceId]);
  useEffect(() => {
    if (agentData?.largeLogo && workspaceId)
      fetchAgentImage(agentData?.largeLogo, true);
  }, [agentData?.largeLogo, workspaceId]);
  const createChat = async () => {
    if (isFirstMessage) {
      try {
        console.log("creating chat", agentId, workspaceId);

        const result = await chatsHelper.createChat({
          primaryRole: agentId,
          workspaceID: workspaceId,
          allowMultipleRoles: false,
          creationTime: Math.floor(new Date().getTime() / 1000),
          visibility: "widget",
          name: "New Chat",
        });
        const agent = getAgent(agentId);

        setAgentData(agent);
        onChatCreated(result?.data?.createChat?.id);
        setChatId(chatID);
        setIsFirstMessage(false);
      } catch (error) {
        history.push("/error");
        console.error(error);
      }
    }
  };
  function removeSpecificUnicodeAtEnd(text) {
    const unicodeRegex = /(\u26AB|\u26AA)$/g;
    return text.replace(unicodeRegex, "");
  }
  useEffect(() => {
    const updateMessage = () => {
      setMessages((prevMessages) => {
        let updatedMessages = [...prevMessages];
        updatedMessages[0]["text"] = agentData?.messagesOnLaunch[carouselIndex];
        return updatedMessages;
      });

      // Increment index or loop back to 0
      setCarouselIndex(
        (prevIndex) =>
          (prevIndex + 1) % (agentData?.messagesOnLaunch.length || 1)
      );
    };
    if (
      agentData?.messagesOnLaunch?.length > 0 &&
      agentData?.rotateMessagesOnLaunchInterval &&
      messages?.length == 1
    ) {
      const interval = setInterval(
        updateMessage,
        agentData?.rotateMessagesOnLaunchInterval || 5000
      );

      return () => {
        clearInterval(interval); // Cleanup interval on component unmount
      };
    }
  }, [agentData?.messagesOnLaunch?.length, carouselIndex, messages?.length]);
  const handleFeedback = async (id, humanFeedback) => {
    console.log(id, humanFeedback);
    try {
      const result = await messagesHelper.updateChatMessage(id, [
        {
          property: "humanFeedback",
          value: humanFeedback,
        },
      ]);
      setMessages((prevMessages) => {
        let updatedMessages = [...prevMessages];
        const newMessageIndex = updatedMessages.map((u) => u._id).indexOf(id);
        if (newMessageIndex !== -1) {
          updatedMessages[newMessageIndex] = {
            ...updatedMessages[newMessageIndex],
            humanFeedback: humanFeedback,
          };
        }
        return [...updatedMessages];
      });
      AppToasty.show("Feedback saved", {
        placement: "bottom",
        type: "success",
      });
    } catch (error) {
      console.log(error);
      AppToasty.show("Error saving feedback", {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  useEffect(() => {
    if (isFirstMessage && agentId && workspaceId) createChat();
  }, [isFirstMessage, agentId, workspaceId]);
  useEffect(() => {
    if (
      messages.length === 0 &&
      agentData?.id &&
      agentData?.showSplashWhenEmpty !== true
    ) {
      const timer = setTimeout(() => {
        setMessages((prevMessages) => [
          {
            _id: 2,
            text: agentData?.messageOnLaunch || "Hi, how can I help you?",
            createdAt: new Date().getTime() / 1000,
            user: {
              name: "assistant",
              _id: 2,
            },
          },
          ...prevMessages,
        ]);
        setMessageIndex((prevIndex) => prevIndex + 1);
      }, 50);

      return () => clearTimeout(timer);
    } else {
      setTimeout(() => {
        setIsMessagesLoaded(true);
      }, 10);
    }
  }, [
    agentData?.messageOnLaunch,
    agentData?.id,
    messageIndex,
    messages?.length,
  ]);
  useEffect(() => {
    let subscriptionObj;
    let subscriptionObj2;
    if (chatId_prop) {
      subscriptionObj?.unsubscribe();
      subscriptionObj2?.unsubscribe();
      envConfig.REACT_APP_ENV == "dev" && console.log("_", "subscribing");
      subscriptionObj = API.graphql(
        graphqlOperation(onCreateChatMessageByChatIDEvent, {
          chatID: chatId_prop,
        })
      ).subscribe({
        next: async (data) => {
          const newMessage = data.value.data.onCreateChatMessageByChatIDEvent;
        },
      });
      subscriptionObj2 = API.graphql(
        graphqlOperation(onUpdateChatMessageByChatIDEvent, {
          chatID: chatId_prop,
        })
      ).subscribe({
        next: async (data) => {
          const mainMessageObj =
            data?.value?.data?.onUpdateChatMessageByChatIDEvent;
          envConfig?.REACT_APP_ENV == "dev" &&
            console.log(
              "_",
              data?.value?.data?.onUpdateChatMessageByChatIDEvent,
              { isAIStreaming },
              { loadingMessageId }
            );

          const message =
            data?.value?.data?.onUpdateChatMessageByChatIDEvent?.text;
          if (
            mainMessageObj.status === "fulfilled" &&
            (mainMessageObj?.callbackMetadata?._type == "suggestion" ||
              mainMessageObj?.callbackMetadata?._type == "nextQuestion")
          ) {
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const callbackMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj?.id);
              if (callbackMessageIndex !== -1) {
                updatedMessages[callbackMessageIndex] = {
                  ...updatedMessages[callbackMessageIndex],
                  callbackMetadata: mainMessageObj.callbackMetadata,
                };
              }
              return [...updatedMessages];
            });
          }
          if (requestedStop) return;
          if (mainMessageObj.status === "fulfilled" && isAIStreaming) {
            envConfig.REACT_APP_ENV == "dev" && console.log("_", "fulfilled");
            if (loadingMessageId === mainMessageObj.id) {
              setIsAIStreaming(false);
              setLoadingMessageId(null);
              onStreamFinished();
            }
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const loadingMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj.id);
              if (loadingMessageIndex !== -1) {
                updatedMessages[loadingMessageIndex] = {
                  ...updatedMessages[loadingMessageIndex],
                  text: removeSpecificUnicodeAtEnd(message),
                  loading: false,
                  metadata:
                    typeof mainMessageObj.metadata === "string"
                      ? JSON.parse(mainMessageObj.metadata)
                      : mainMessageObj.metadata,
                  callbackMetadata:
                    typeof mainMessageObj.callbackMetadata === "string"
                      ? JSON.parse(mainMessageObj.callbackMetadata)
                      : mainMessageObj.callbackMetadata,
                };
              }
              return [...updatedMessages];
            });
          } else if (
            (mainMessageObj.text !== "**...**" &&
              mainMessageObj.text !== agentData?.placeholderMessageOnLoading &&
              mainMessageObj.isAI === true) ||
            (loadingMessageId == null && isAIStreaming)
          ) {
            envConfig.REACT_APP_ENV == "dev" &&
              console.log("loadingMessageId", mainMessageObj.id);
            // update the last message in the chat with role assistant to the new message
            setMessages((prevMessages) => {
              let updatedMessages = [...prevMessages];
              const loadingMessageIndex = updatedMessages
                .map((u) => u._id)
                .indexOf(mainMessageObj.id);
              if (loadingMessageIndex !== -1) {
                updatedMessages[loadingMessageIndex] = {
                  ...updatedMessages[loadingMessageIndex],
                  text: `${message}${isAIStreaming ? " " + cursor : ""}`,
                  loading: false,
                };
              }
              return [...updatedMessages];
            });
          }
        },
        error: (error) => {
          console.log(
            `Error with subscription: ${lastSubscribedChatId}`,
            error
          );
        },
      });
      setSubscriptionObject(subscriptionObj);
      setSubscriptionObject2(subscriptionObj2);
    }

    return () => {
      if (subscriptionObj) {
        envConfig.REACT_APP_ENV == "dev" && console.log("_", "unsubscribing");
        subscriptionObj.unsubscribe();
        subscriptionObj2.unsubscribe();
      }
    };
  }, [chatId, loadingMessageId, isAIStreaming, chatId_prop]);
  const onCancelGeneration = async () => {
    subscriptionObj?.unsubscribe();
    subscriptionObj2?.unsubscribe();
    setRequestedStop(true);
    setAllowStop(false);
    setIsAIStreaming(false);
    setIsLoading(false);

    await chatsHelper.updateChat(
      chatId,
      [
        {
          property: "isAIReplying",
          value: false,
        },
        {
          property: "activeMessageForAI",
          value: null,
        },
      ],
      Common.decrypt(encryptedToken_)
    );
    await chatsHelper.updateChatMessage(
      {
        id: loadingMessageId,
        status: "cancelled",
      },
      Common.decrypt(encryptedToken_)
    );
    setLoadingMessageId(null);
    setTimeout(() => {
      setAllowStop(true);
    }, [1000]);
  };
  let chatID;
  useEffect(() => {
    if (agentId && workspaceId && _inputText != null && _inputText != "") {
      fetchData(_inputText);
    }
  }, [agentId, workspaceId, _inputText]);
  const fetchData = async (message) => {
    try {
      const optimisticMessageId = Math.random().toString(36).substring(7);
      setMessages((previousMessages) => [
        ...previousMessages,
        {
          _id: optimisticMessageId,
          text: message,
          createdAt: new Date().getTime() / 1000,
          user: {
            name: "user",
          },
          loading: false,
        },
      ]);
      const promises = [
        messagesHelper.createChatMessage({
          chatID: chatId_prop,
          text: message,
          role: "user",
          userID: user._id,
          isAI: false,
          creationTime: Math.floor(new Date().getTime() / 1000),
          workspaceID: workspaceId,
        }),
        messagesHelper.createChatMessage({
          chatID: chatId_prop,
          text:
            agentData?.placeholderMessageOnLoading ||
            defaultPlaceholderMessageOnLoading,
          role: "assistant",
          isAI: true,
          creationTime: Math.floor(new Date().getTime() / 1000) + 1,
          workspaceID: workspaceId,
        }),
      ];
      const [result1, assistantPlaceholder] = await Promise.all(promises);

      // create a new Date one second in the future to ensure the message is at the bottom
      let f_date = new Date();
      f_date.setSeconds(f_date.getSeconds() + 1);
      setLoadingMessageId(assistantPlaceholder?.data?.createChatMessage?.id);
      setMessages((previousMessages) => [
        ...previousMessages,
        {
          _id: assistantPlaceholder?.data?.createChatMessage?.id,
          text: "",
          createdAt: f_date.getTime() / 1000,
          user: {
            _id: 2,
            name: "assistant",
          },
          loading: true,
        },
      ]);
      setRequestedStop(false);
      setIsAIStreaming(true);
      setAllowStop(true);
      await Promise.all([
        chatsHelper.updateChat(chatId_prop, [
          {
            property: "isAIReplying",
            value: true,
          },
          {
            property: "activeMessageForAI",
            value: assistantPlaceholder?.data?.createChatMessage?.id,
          },
        ]),
        Chatter.getAnswerinChat({
          chatid: chatId_prop,
          messages: [
            {
              text: message,
              role: user?.username,
              userID: user?.id,
            },
          ],
          token: getActiveWorkspace()?.workspaceToken,
          workspaceid: getActiveWorkspace()?.id,
          stream: "true",
          nextMessageId: assistantPlaceholder?.data?.createChatMessage?.id,
          agentid: agentId,
        }),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      // remove the loading message by checking the loading property
      // setMessages((previousMessages) =>
      //   previousMessages.filter((m) => !m.loading)
      // );
    }
  };
  // from messages get the last message from the assistant handling the case where there are no messages yet or no messages from the assistant
  const lastMessageFromAssistant =
    messages?.filter((u) => u?.user?.name === "assistant")?.slice(-1)[0] || {};

  useEffect(() => {
    if (
      messages?.filter((u) => u?.loading === true).length > 0 &&
      !isAIStreaming
    ) {
      // Update the last message's loading status and add the reply
      setMessages((previousMessages) => {
        const updatedMessages = previousMessages.slice();
        updatedMessages[0].loading = false;
        // updatedMessages[0].text = "Something went wrong, please try again";
        return updatedMessages;
      });
    }
  }, [messages?.length, isAIStreaming]);
  const handleSend = (message) => {
    if (message.trim().length === 0) return;
    setIsLoading(true);
    console.log("chatid", chatId);
    fetchData(message);
  };
  const onQuestionPress = (question) => {
    if (!isLoading) handleSend(question);
  };
  const onLanguageChange = (language) => {};

  // change the tab title to the agent title
  // useEffect(() => {
  //   if (agentData?.label) document.title = agentData?.label;
  // }, [agentData?.label]);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {agentId && urldownloadGenerationInProgress == false ? (
        <ArenaScreen
          setAgent={setAgent}
          agentId={agentId}
          setWinner={setWinner}
          isWinner={isWinner}
          S3downloadUrlRequest={S3downloadUrlRequestForWidget}
          onQuestionPress={onQuestionPress}
          onLanguageChange={onLanguageChange}
          messages={messages}
          setMessages={setMessages}
          user={user}
          handleSend={handleSend}
          isMessagesLoaded={isMessagesLoaded}
          languages={state.languages}
          selectedLanguage={state.selectedLanguage}
          isAIStreaming={isAIStreaming}
          onCancel={onCancelGeneration}
          inputText={inputText}
          setInputText={setInputText}
          loadingMessageId={loadingMessageId}
          isLastMessageEmpty={lastMessageFromAssistant?.text == ""}
          allowFeedback={agentData?.allowFeedback}
          handleFeedback={handleFeedback}
          isLoading={isLoading}
          placeholder={agentData?.placeholderInputMessage || "Ask me anything"}
          agentTitle={agentData?.label}
          textToDisplayWhileLoading={
            agentData?.placeholderMessageOnLoading ||
            defaultPlaceholderMessageOnLoading
          }
          showSplash={agentData?.showSplashWhenEmpty}
          splashTitle={agentData?.messageOnLaunch}
          coder={agentData?.mode === "coder"}
          isAgentFetched={agentData?.label != null}
          disclaimer={agentData?.disclaimer}
          agentImage={agentImage}
          agentLogo={agentLogo}
          agentColor={mode === "dark" ? agentData?.darkColor : agentData?.color}
          logoBackground={
            mode === "dark"
              ? agentData?.darkLogoBackground
              : agentData?.logoBackground
          }
          showTimeForResponse={agentData?.showTimeForResponse}
          showDetectedLanguage={agentData?.showDetectedLanguage}
          quickQuestions={agentData?.quickQuestions
            ?.split(";")
            ?.map((u) => u?.trim()?.replace(/\s+/g, " "))}
          allowStop={allowStop}
          workspaceId={workspaceId}
          workspace={workspace}
          _agentName={_agentName}
        />
      ) : (
        <div className="tf-loader"></div>
      )}
    </Suspense>
  );
};

export default AgentScreenContainer;
