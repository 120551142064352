function download_files(files, forceNewTab = false) {
  function download_next(i) {
    if (i >= files.length) {
      return;
    }
    var a = document.createElement("a");
    a.href = files[i].download;
    if (forceNewTab) {
      a.target = "_blank"; // Open the link in a new tab
      a.rel = "noopener noreferrer"; // Prevent the new tab from keeping the old tab open
    } else {
      a.target = "_parent";
    }
    // Use a.download if available, it prevents plugins from opening.
    if ("download" in a) {
      a.download = files[i].filename;
    }
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    } else {
      $(a).click(); // Backup using jquery
    }
    // Delete the temporary link.
    a.parentNode.removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function () {
      download_next(i + 1);
    }, 2000);
  }
  // Initiate the first download.
  download_next(0);
}

export default {
  download_files,
};
