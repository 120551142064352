import { DataStore } from "@aws-amplify/datastore";
import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AgentFunctions from "../../../../API/AgentFunctions";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import Utility from "../../../../Utility";
import { isLargeScreen } from "../../../MainViewContainer";
import FunctionSettingsAIView from "../SubComponents/FunctionSettingsAIView";
import validations from "./validations";

const FunctionSettingAI = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onFunctionCreated,
      selectedId,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();

    const { state, dispatch } = AppStateManager.useAppStateManager();
    const { getActiveWorkspace } = useWorkspaces();
    const propertySchema = AppForms.yup.object().shape({
      type: AppForms.yup
        .string()
        .oneOf(["string"], 'Type must be "string"')
        .required("Type is required"),
      enum: AppForms.yup.array().of(AppForms.yup.string()).notRequired(),
      description: AppForms.yup.string().required("Description is required"),
    });
    const validationSchema = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      name: AppForms.yup
        .string()
        .required()
        .label()
        .matches(
          /^[a-zA-Z0-9_]*$/,
          "Only alphanumeric characters and underscores are allowed without spaces."
        )
        .max(64, "Too long, maximum of 64 characters are allowed."),
      description: AppForms.yup.string().required().label().max(255),
      requestType: AppForms.yup.string().required().label().max(10),
      authorisationType: AppForms.yup.string().required().label().max(50),
      url: AppForms.yup
        .string()
        .url("Must be a valid URL")
        .required()
        .label()
        .max(255)
        .nullable(),
      parameters: validations.parametersVal,
      model: AppForms.yup
        .string()
        .required()
        .label()
        .max(255)
        .notOneOf(["None"]),
      // validate model is not None
      headers: AppForms.yup.object().nullable()?.notRequired(),
      staticArgs: AppForms.yup.object().nullable()?.notRequired(),
      isCallbackFn: AppForms.yup.boolean().nullable()?.notRequired(),
      paramsAsPath: AppForms.yup.boolean().nullable()?.notRequired(),
      isDatabaseScript: AppForms.yup.boolean().nullable()?.notRequired(),
      scope: AppForms.yup.string().nullable()?.notRequired(),
    });

    const validationSchemaDB = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      name: AppForms.yup
        .string()
        .required()
        .label()
        .matches(
          /^[a-zA-Z0-9_]*$/,
          "Only alphanumeric characters and underscores are allowed without spaces."
        )
        .max(64, "Too long, maximum of 64 characters are allowed."),
      description: AppForms.yup.string().required().label().max(255),
      dbScript: AppForms.yup
        .string()
        .required()
        .label("Stored procedure")
        .max(255)
        .nullable(),
      parameters: validations.parametersVal,
      isCallbackFn: AppForms.yup.boolean().nullable()?.notRequired(),
      isDatabaseScript: AppForms.yup.boolean().nullable()?.notRequired(),
      scope: AppForms.yup.string().nullable()?.notRequired(),
    });
    const validationSchemaHtml = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      name: AppForms.yup
        .string()
        .required()
        .label()
        .matches(
          /^[a-zA-Z0-9_]*$/,
          "Only alphanumeric characters and underscores are allowed without spaces."
        )
        .max(64, "Too long, maximum of 64 characters are allowed."),
      description: AppForms.yup.string().required().label().max(255),
      htmlUrl: AppForms.yup
        .string()
        // .url("Must be a valid URL")
        .required()
        .label("URL")
        .max(255)
        .nullable(),
      htmlFormOnSuccessfulSubmitMessage: AppForms.yup
        .string()
        .label("Success message")
        .max(255)
        .nullable(),
      htmlFormOnFailedSubmitMessage: AppForms.yup
        .string()

        .label("Failure message")
        .max(255)
        .nullable(),
      isHtmlRedirectFn: AppForms.yup.boolean().nullable()?.notRequired(),
      isCallbackFn: AppForms.yup.boolean().nullable()?.notRequired(),
      isHtmlFunctionsEnabled: AppForms.yup.boolean().nullable()?.notRequired(),
    });
    const validationSchemaChat = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      name: AppForms.yup
        .string()
        .required()
        .label()
        .matches(
          /^[a-zA-Z0-9_]*$/,
          "Only alphanumeric characters and underscores are allowed without spaces."
        )
        .max(64, "Too long, maximum of 64 characters are allowed."),
      description: AppForms.yup.string().required().label().max(255),
      chatScript: AppForms.yup
        .string()
        // .url("Must be a valid URL")
        .required()
        .label("Script")
        .max(2048)
        .nullable(),
      chatAction: AppForms.yup
        .string()
        // .url("Must be a valid URL")
        .required()
        .label("Action")
        .max(255)
        .nullable(),

      isCallbackFn: AppForms.yup.boolean().nullable()?.notRequired(),
    });

    const handleDeletion = async () => {
      try {
        for (let i = 0; i < state?.agents?.length; i++) {
          if (state?.agents[i]?.agentFunctions?.includes(opt.id)) {
            const newFunctions = state?.agents[i]?.agentFunctions?.filter(
              (u) => u !== opt.id
            );
            const newAgent = await Agents.updateAgent(state?.agents[i]?.id, [
              {
                property: "agentFunctions",
                value: newFunctions,
              },
            ]);
            setTimeout(() => {}, 500);
          }
        }
        const resultAgents = await DataStore.query(Agent, (e) =>
          e.workspaceID("eq", getActiveWorkspace()?.id)
        );
        dispatch("SET_AGENTS", resultAgents);
        const result = await AgentFunctions.deleteAgentFunction(opt.id);
        dispatch("SET_AGENTS_FUNCTIONS", [
          ...state.agentsFunctions?.filter((u) => u?.id !== opt.id),
        ]);
        navigateBack();
        AppToasty.show(
          `${Utility.upperCaseFirstLetter("dictionary")} ${t("deleted")}`,
          {
            type: "success",
          }
        );
      } catch (error) {
        console.error("dictionaryDeleteErrorCaption", error);
        AppToasty.show(
          `${
            t("dictionaryDeleteErrorCaption") + " "
          } ${Utility.upperCaseFirstLetter("dictionary")}`,
          {
            type: "danger",
          }
        );
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };
    const identifyOptByID = (id) => {
      return state.agentsFunctions.find((u) => u?.id === id);
    };
    const handleFunctionItemSave = async (v) => {
      if (v.model === "speed") v.model = "gpt-3.5-turbo-0613";
      else if (v.model === "accuracy") v.model = "gpt-4-0613";
      else v.model = "gpt-3.5-turbo-0613";
      if (v?.scope == "none") v.scope = null;
      v.workspaceID = getActiveWorkspace()?.id;
      v.authorisationType = v.authorisationType?.toLowerCase();
      v.requestType = v.requestType?.toLowerCase();

      v.parameters = JSON.stringify(v?.parameters || {});
      v.headers = JSON.stringify(v?.headers || {});
      v.staticArgs = JSON.stringify(v?.staticArgs || {});
      delete v?._lastChangedAt;
      delete v?.createdAt;
      delete v?.updatedAt;
      delete v?._deleted;
      delete v?.__typename;
      let newFunctions = [...state.agentsFunctions];
      try {
        let result;
        if (isEdit) {
          result = await AgentFunctions.updateAgentFunction(
            {
              id: selectedId,
              ...v,
            },
            identifyOptByID(selectedId)?._version
          );
          const _updatedObject = identifyOptByID(
            result.data?.updateAgentFunction?.id
          );
          const updateIndex = newFunctions.findIndex(
            (u) => u?.id === _updatedObject?.id
          );
          newFunctions[updateIndex] = result?.data?.updateAgentFunction;
        } else {
          result = await AgentFunctions.createAgentFunction({
            ...v,
          });

          newFunctions.push(result?.data?.createAgentFunction);
        }

        dispatch("SET_AGENTS_FUNCTIONS", [...newFunctions]);
        backTo && navigateBack();
        onFunctionCreated && onFunctionCreated("success");
      } catch (error) {
        console.error(error);
        onFunctionCreated && onFunctionCreated("error");
      } finally {
      }
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            height: 500,
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Function : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={handleFunctionItemSave}
            validationSchema={
              initialMapValues?.isDatabaseScript
                ? validationSchemaDB
                : initialMapValues?.isHtmlFn
                ? validationSchemaHtml
                : initialMapValues?.isChatFn
                ? validationSchemaChat
                : validationSchema
            }
            enableReinitialize
          >
            <FunctionSettingsAIView
              handleDeletion={handleDeletion}
              isEdit={isEdit}
              settingType={settingType}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [state.activeWorkspaceID, initialMapValues, state.agentsFunctions, isEdit]
    );
    return _view;
  }
);

export default FunctionSettingAI;
