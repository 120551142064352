import useApi from "@toothfairy/shared-api/useApi";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import dayjs from "dayjs";
import { throttle } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import envConfig from "../../envConfig";
import Constants from "../Constants";
import { useTranslation } from "react-i18next";
import trainingAPI from "../API/Training/trainingAPI";
import {
  generateInitialEditorValueWithTitle,
  initialEditorValue,
} from "../Screens/TestExample/commonUtils";
import useTrainModeSwitcher from "./useTrainModeSwitcher";
import { useCallback } from "react";
import { debounce } from "lodash";
import useWorkspaces from "./useWorkspaces";
import { useParams } from "../Router";

const useAutoSave = ({
  documentId,
  documentType,
  user,
  updateMethod,
  createMethod,
  isNLPTraining,
  isRCTraining,
  isGnTraining,
  savingInterval,
  route = "mrc_contents",
  initialValue,
  backupCommand,
  backupProperty,
  workspaceid,
  savesSkipsForBooting = 0,
  isTopicFetchingEnabled = true,
  isAGCEnabled,
  fakeCheckMethod,
  fakeCheckScope,
  fakeCheckExecutionInterval,
  fakeCheckSkipsForBooting = 1,
  language = "def",
  reInitiateOnParserMode = false,
  isTopicUpdateEnabledByDefault = false,
  isCnTraining,
}) => {
  const { documentId: _lastDocumentIdInBrowser } = useParams();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [isTopicUpdateModeEnabled, setIsTopicUpdateModeEnabled] =
    useState(null);
  const [saveRequested, setIsSaveRequested] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const _init = initialValue || state[backupProperty];
  const getMethod = trainingAPI.getDocumentById;
  const [isDirty, setIsDirty] = useState(false);
  const { goToParser, goToTrainMode } = useTrainModeSwitcher({ route });
  const { goToMrcDocument } = useTrainModeSwitcher({
    route,
  });
  const [isPDFLoaded, setIsPDFLoaded] = useState(false);
  const [lastCancellation, setLastCancellation] = useState(null);
  const [isFile, setIsFile] = useState(false);
  const { getActiveWorkspace } = useWorkspaces();
  const [value, setValue] = useState(initialValue || state[backupProperty]);
  const [trainingData, setTrainingData] = useState(null);
  const [editor, setEditor] = useState(null);
  const [editorQA, setEditorQA] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [documentStatus, setDocumentStatus] = useState("draft");
  const [extractImages, setExtractImages] = useState(false);
  const [isParser, setIsParser] = useState(!documentId);
  const [isReadyForRTC, setIsReadyForRTC] = useState(false);
  const [
    documentImgRetrievalInstructions,
    setDocumentImgRetrievalInstructions,
  ] = useState();
  const [isValidationData, setIsValidationData] = useState(false);
  const [isDocumentValid, setIsDocumentValid] = useState(false);
  const [rawText, setRawText] = useState(null);
  const [currentTime, setCurrentTime] = useState(dayjs().toDate().getTime());
  const [wordsCount, setWordsCount] = useState(0);
  const [charactersCount, setCharactersCount] = useState(0);
  const { t } = useTranslation();
  const [skippedSavesForBooting, setSkippedSavesForBooting] = useState(0);
  const [lastChange, setLastEditorChange] = useState(
    dayjs().toDate().getTime()
  );
  const [isEditorConnectedToSocket, setEditorConnected] =
    useState("disconnected");
  const [creationofNewDocumentInProgress, setCreationOfNewDocumentInProgress] =
    useState(false);
  const AppToasty = AppToast.useToast();
  const [isEditorLoaded, setIsEditorLoaded] = useState(true);

  const {
    data: createAPIResult,
    error: createAPIError,
    loading: createAPIInProgress,
    apiRequest: createAPIRequest,
    lastUpdate: lastCreationTime,
  } = useApi(createMethod, envConfig);

  const {
    data: getAPIResult,
    error: getAPIError,
    loading: getAPIInProgress,
    apiRequest: getAPIRequest,
    lastUpdate: lastrequestTime,
  } = useApi(getMethod, envConfig);

  const {
    data: updateAPIResult,
    error: updateAPIError,
    loading: updateAPIInProgress,
    apiRequest: updateAPIRequest,
    response: trainingDataUpdateAPIResponse,
    lastUpdate: lastUpdateTime,
  } = useApi(updateMethod, envConfig);

  const {
    data: fakeCheckAPIResult,
    error: fakeCheckAPIError,
    loading: fakeCheckAPIInProgress,
    apiRequest: fakeCheckAPIRequest,
    lastUpdate: lastExecutionTime,
    optimisticDataUpdate: fakeCheckOptimisticDataUpdate,
  } = useApi(fakeCheckMethod, envConfig);

  const generateAdaptedTopics = (topics) => {
    const adaptedTopics = [];
    for (let i = 0; i < topics.length; i++) {
      let newTopic = topics[i];
      if (newTopic) {
        adaptedTopics.push({
          ...newTopic,
          name: topics[i].label,
          category: topics[i].type,
        });
      }
    }
    return adaptedTopics;
  };
  useEffect(() => {
    if (isParser && reInitiateOnParserMode) {
      setValue(initialValue || initialEditorValue);
    }
  }, [isParser, reInitiateOnParserMode, initialValue]);
  const saveDocument = async ({
    documentId,
    value,
    topics,
    workspaceid,
    editorQA,
    editor,
    isValidationData,
    documentStatus,
    isTopicUpdateModeEnabled,
    _lastDocumentIdInBrowser,
  }) => {
    if (!lastrequestTime || !value) return;
    let nlpTrainingData = {};
    if (isNLPTraining) {
      nlpTrainingData = AppQuantumEditorUtils.Helpers.extractTrainingData(
        {
          children: editor?.children,
        },
        "__ner__"
      );
    } else if (isRCTraining && editorQA) {
      nlpTrainingData =
        AppQuantumEditorUtils.Helpers.extractRcTrainingData(editorQA);
    } else if (isGnTraining) {
      nlpTrainingData =
        AppQuantumEditorUtils.Helpers.extractGnTrainingData(editor);
    } else if (isCnTraining) {
      nlpTrainingData =
        AppQuantumEditorUtils.Helpers.extractCnTrainingData(editor);
    }
    if (
      workspaceid &&
      documentId == _lastDocumentIdInBrowser &&
      editor?.children?.length > 0
    ) {
      const markdown = AppQuantumEditorUtils.Helpers.slateToMarkdown(
        editor?.children
      );
      let payload = {
        id: documentId,
        workspaceid: workspaceid,
        document: JSON.stringify(editor?.children),
        trainingData: JSON.stringify({ data: nlpTrainingData.trainingData }),
        entitiesMap: JSON.stringify(nlpTrainingData.entitiesMap),
        intentsMap: JSON.stringify(nlpTrainingData.intentsMap),
        rawtext: markdown,
        scope: isValidationData ? "validation" : "training",
        type: documentType,
        userID: user?.id,
        status: documentStatus,
      };

      const trainingDataUpdateResult = await updateAPIRequest({
        fields: payload,
      });
    }
  };
  const executeFakeCheckRequest = async ({ value, workspace, scope }) => {
    if (
      !value ||
      AppQuantumEditorUtils.Helpers.getPlainText(value)?.trim() === ""
    ) {
      fakeCheckOptimisticDataUpdate({
        contents: [
          {
            output: {
              real_probability: 0,
              fake_probability: 0,
            },
            dictionaries: [],
            id: null,
            shortText: "",
          },
        ],
        workspaceid: workspace?.id,
      });
      return;
    }
    const payload = {
      contents: [
        {
          text: AppQuantumEditorUtils.Helpers.getPlainText(value),
          sourceLanguage: language,
        },
      ],
      token: workspace?.workspaceToken,
      workspaceid: workspace?.id,
    };
    const result = await fakeCheckAPIRequest(payload);
  };
  const saveDocumentTitle = async ({ documentId, workspaceid, title }) => {
    if (workspaceid && documentId && title) {
      await updateAPIRequest({
        fields: {
          id: documentId,
          workspaceid: workspaceid,
          title,
          userID: user?.id,
        },
      });
    }
  };

  // Execute method every 10 seconds if there is no change in the editor
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs().toDate().getTime());
    }, 5000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  const isInactive = false;
  const autoSave = useRef(
    debounce(
      ({
        documentId,
        value,
        workspaceid,
        skippedSavesForBooting,
        setSkippedSavesForBooting,
        editorQA,
        editor,
        isValidationData,
        documentStatus,
        isTopicUpdateModeEnabled,
        _lastDocumentIdInBrowser,
      }) => {
        if (skippedSavesForBooting >= savesSkipsForBooting)
          saveDocument({
            documentId,
            value,
            workspaceid,
            editorQA,
            editor,
            isValidationData,
            documentStatus,
            isTopicUpdateModeEnabled,
            _lastDocumentIdInBrowser,
          });
        else setSkippedSavesForBooting((skippedSavesForBooting += 1));
      },
      savingInterval || Constants.saveTimer
    )
  ).current;
  const autoExecuteFakeCheck = useRef(
    debounce(({ value, workspace, scope, language }) => {
      executeFakeCheckRequest({ value, workspace, scope, language });
    }, fakeCheckExecutionInterval)
  ).current;
  useEffect(() => {
    setLastEditorChange(dayjs().toDate().getTime());
    if (
      value &&
      !creationofNewDocumentInProgress &&
      isEditorConnectedToSocket === "connected" &&
      !isLoading &&
      !isInactive &&
      documentId == _lastDocumentIdInBrowser
    ) {
      autoSave({
        documentId,
        value,
        workspaceid,
        skippedSavesForBooting,
        setSkippedSavesForBooting,
        editorQA,
        editor,
        isValidationData,
        documentStatus,
        isTopicUpdateModeEnabled,
        _lastDocumentIdInBrowser,
      });
      if (isAGCEnabled) {
        autoExecuteFakeCheck({
          value,
          workspace: getActiveWorkspace(),
          scope: fakeCheckScope,
          language,
        });
      }
    }
  }, [value, editorQA, isValidationData, isAGCEnabled]);
  const saveTopics = async (selectedTopics) => {
    let payload = {
      id: documentId,
      workspaceid: workspaceid,
      userID: user?.id,
      topics: selectedTopics,
    };
    await updateAPIRequest({
      fields: payload,
    });
  };
  const saveDocumentStatus = async (status) => {
    let payload = {
      id: documentId,
      workspaceid: workspaceid,
      userID: user?.id,
      status,
    };
    await updateAPIRequest({
      fields: payload,
    });
  };
  const saveExtractImages = async (extract_images) => {
    let payload = {
      id: documentId,
      workspaceid: workspaceid,
      userID: user?.id,
      extract_images,
      img_retrieval_prompt: documentImgRetrievalInstructions,
    };
    await updateAPIRequest({
      fields: payload,
    });
  };
  useEffect(() => {
    if (
      isPDFLoaded &&
      !creationofNewDocumentInProgress &&
      !isLoading &&
      isTopicUpdateModeEnabled == false &&
      saveRequested
    ) {
      saveExtractImages(extractImages);
      setIsSaveRequested(null);
    }
  }, [
    extractImages,
    isPDFLoaded,
    creationofNewDocumentInProgress,
    isLoading,
    isTopicUpdateModeEnabled,
    saveRequested,
  ]);
  const prevDocumentIdRef = useRef();
  // change heer
  useEffect(() => {
    let prevDocumentId;
    if (isPDFLoaded) prevDocumentId = prevDocumentIdRef.current;
    else if (!isFile) prevDocumentId = prevDocumentIdRef.current;
    else return;

    // If this is not the first render and documentId hasn't changed...
    if (
      prevDocumentId !== undefined &&
      ((isFile && prevDocumentId === documentId) || !isFile)
    ) {
      if (
        selectedTopics &&
        isTopicUpdateModeEnabled == false &&
        saveRequested
      ) {
        saveTopics(selectedTopics?.map((u) => u?.id));
        saveDocumentStatus(documentStatus);
        saveExtractImages(extractImages);
        setIsSaveRequested(null);
      }
    }

    // Update previous documentId for the next render
    prevDocumentIdRef.current = documentId;
  }, [
    isPDFLoaded,
    isTopicUpdateModeEnabled,
    selectedTopics?.length,
    documentId,
    documentStatus,
    extractImages,
    saveRequested,
  ]);

  useEffect(() => {
    let nlpTrainingData = {};
    if (isNLPTraining && value) {
      nlpTrainingData = AppQuantumEditorUtils.Helpers.extractTrainingData(
        {
          children: value,
        },
        "__ner__"
      );
    } else if (isRCTraining && editorQA) {
      nlpTrainingData =
        AppQuantumEditorUtils.Helpers.extractRcTrainingData(editorQA);
    } else if (isGnTraining) {
      nlpTrainingData =
        AppQuantumEditorUtils.Helpers.extractGnTrainingData(editor);
    } else if (isCnTraining) {
      nlpTrainingData =
        AppQuantumEditorUtils.Helpers.extractCnTrainingData(editor);
    }
    setTrainingData(nlpTrainingData);
  }, [
    value,
    editorQA,
    JSON.stringify(editor),
    isGnTraining,
    isCnTraining,
    isParser,
  ]);

  useEffect(() => {
    setIsDirty(
      !(
        value?.length === 2 &&
        value[0]?.children[0]?.text === _init[0]?.children[0]?.text &&
        value[1]?.children[0]?.text === _init[1]?.children[0]?.text
      )
    );
  }, [value]);

  useEffect(() => {
    setWordsCount(AppQuantumEditorUtils.Utilities.wordsCount(rawText));
    setCharactersCount(AppQuantumEditorUtils.Utilities.charactesCount(rawText));
  }, [rawText]);
  useEffect(() => {
    setIsParser(!documentId);
    setIsPDFLoaded(false);
    setIsTopicUpdateModeEnabled(null);
  }, [documentId]);
  const handleSocketConnectionChange = useCallback(
    (value) => {
      setEditorConnected(value);
    },
    [setEditorConnected]
  );
  const handleCreationOfDocumentFromGnOutput = async (
    editorValue,
    title,
    documentType,
    redirect = true,
    selectedTopics
  ) => {
    try {
      if (!editorValue?.length > 0) {
        // raise an error
        throw new Error("No content to save");
      }
      setCreationOfNewDocumentInProgress(true);
      // add a new node to editorValue at the start of the document
      editorValue = [
        {
          type: "title",
          children: [{ text: title }],
        },
        ...editorValue,
      ];
      const documentPostResult = await createAPIRequest({
        data: [
          {
            workspaceid: workspaceid,
            document: JSON.stringify(editorValue),
            rawtext: AppQuantumEditorUtils.Helpers.getPlainText(
              editorValue,
              true,
              true
            ),
            trainingData: {},
            userID: user?.id,
            type: documentType,
            title: title,
            topics: selectedTopics?.map((u) => u?.id),
            folderid:
              documentType === "readComprehensionContent"
                ? "mrcRoot"
                : "qaRoot",
          },
        ],
      });
      setIsEditorLoaded(true);
      redirect &&
        goToMrcDocument({
          docID: documentPostResult.data[0].id,
        });
      AppToasty.show(t("DocumentCreationCaption"), {
        type: "success",
      });
    } catch (e) {
      console.error(e);
      AppToasty.show(t("DocumentCreationFailedCaption"), {
        type: "danger",
      });
    } finally {
      setCreationOfNewDocumentInProgress(false);
    }
  };
  const handleNewDocument = async (createEmpty, forcedEditorValue) => {
    try {
      setCreationOfNewDocumentInProgress(true);
      if (!state.trainingData?.data)
        state.trainingData = {
          data: [],
        };
      setIsEditorLoaded(false);
      const trainingDataPostResult = await createAPIRequest({
        data: [
          {
            workspaceid: workspaceid,
            document: createEmpty
              ? JSON.stringify(
                  forcedEditorValue || initialValue || initialEditorValue
                )
              : JSON.stringify(value),
            rawtext: AppQuantumEditorUtils.Helpers.getPlainText(
              value,
              true,
              true
            ),
            trainingData: {},
            userID: user?.id,
            type: documentType,
            topics: selectedTopics?.map((u) => u?.id),
            scope:
              !isParser && isValidationData
                ? "validation"
                : !isParser && "training",
          },
        ],
      });
      if (createEmpty) setValue(initialValue || initialEditorValue);

      goToTrainMode({
        docID: trainingDataPostResult.data[0].id,
      });
      state.trainingData.data.push(...trainingDataPostResult.data);
      dispatch("SET_TRAINING_DATA", state.trainingData);
      AppToasty.show(t("DocumentCreationCaption"), {
        type: "success",
      });
      setIsEditorLoaded(true);
    } catch (error) {
      console.log("Error", error);
      AppToasty.show(t("DocumentCreationFailedCaption"), {
        type: "danger",
      });
    } finally {
      setCreationOfNewDocumentInProgress(false);
    }
  };
  const generateEditorWithTitle = (title) => {
    if (title) return generateInitialEditorValueWithTitle(title);
    else return initialValue || initialEditorValue;
  };
  const handleNewMRCDocument = async (
    createEmpty,
    node,
    title,
    topics,
    status = "draft"
  ) => {
    try {
      const folderid = node?.data?.droppable ? node?.id : "mrcRoot";
      setCreationOfNewDocumentInProgress(true);
      if (!state.mrcDocuments) state.mrcDocuments = [];
      setIsEditorLoaded(false);
      const MrcDocumentsPostResult = await createAPIRequest({
        data: [
          {
            workspaceid: workspaceid,
            document: createEmpty
              ? JSON.stringify(generateEditorWithTitle(title))
              : JSON.stringify(value),
            rawtext: AppQuantumEditorUtils.Helpers.getPlainText(
              value,
              true,
              true
            ),
            userID: user?.id,
            type: documentType,
            topics: topics?.map((u) => u?.id),
            folderid: folderid || "mrcRoot",
            title,
            status,
          },
        ],
      });
      if (createEmpty) setValue(initialValue || initialEditorValue);
      if (MrcDocumentsPostResult.data) {
        goToTrainMode({
          docID: MrcDocumentsPostResult.data[0].id,
        });
        //
        state.mrcDocuments.push({
          id: MrcDocumentsPostResult.data[0].id,
          folderid: MrcDocumentsPostResult.data[0].folderid || "mrcRoot",
          title: MrcDocumentsPostResult.data[0].title || "No title",
        });

        dispatch("SET_MRC_DOCUMENTS", [...state.mrcDocuments]);
        AppToasty.show(t("DocumentCreationCaption"), {
          type: "success",
        });
        setIsEditorLoaded(true);
      } else {
        AppToasty.show(MrcDocumentsPostResult, {
          type: "danger",
        });
      }
    } catch (error) {
      console.error("Error", error);
      AppToasty.show(t("DocumentCreationFailedCaption"), {
        type: "danger",
      });
    } finally {
      setCreationOfNewDocumentInProgress(false);
    }
  };
  useEffect(() => {
    dispatch(backupCommand, value);
    setRawText(AppQuantumEditorUtils.Helpers.getPlainText(value));
  }, [value]);

  const fetchDocument = async (documentId, workspaceId) => {
    setIsReadyForRTC(false);
    const result = await getAPIRequest(
      documentId,
      [
        "topics",
        "workspaceid",
        "scope",
        "status",
        "type",
        "embedding_status",
        "completion_percentage",
        "extract_images",
        "img_retrieval_prompt",
      ],
      workspaceId
    );
    setIsValidationData(
      result?.data?.length > 0 && result?.data[0]?.scope === "validation"
    );
    setIsReadyForRTC(result?.data?.length > 0);
    setIsDocumentValid(result?.data?.length > 0);
    setIsFile(
      result?.data?.length > 0 &&
        (result?.data[0]?.type === "readComprehensionPdf" ||
          result?.data[0]?.type === "readComprehensionFile")
    );
    setExtractImages(
      result?.data?.length > 0 && result?.data[0]?.extract_images
    );
    setDocumentImgRetrievalInstructions(
      result?.data?.length > 0 && result?.data[0]?.img_retrieval_prompt
    );
    if (!isParser && result?.data?.length > 0 && result?.data[0]) {
      setSelectedTopics(result?.data[0]?.topics);
    }
  };

  useEffect(() => {
    if (documentId && state.activeWorkspaceID) {
      fetchDocument(documentId, state.activeWorkspaceID);
    }
  }, [documentId, state.activeWorkspaceID, lastCancellation]);

  const wordsAndCharactersCountFromEditorValue = (value) => {
    const _rawText = AppQuantumEditorUtils.Helpers.getPlainText(value);
    return {
      wordsCount: AppQuantumEditorUtils.Utilities.wordsCount(_rawText),
      charactersCount: AppQuantumEditorUtils.Utilities.charactesCount(_rawText),
    };
  };

  return {
    creationofNewDocumentInProgress,
    value,
    editor,
    setValue,
    setEditor,
    setIsParser,
    isParser,
    updateAPIResult,
    updateAPIError,
    createAPIError,
    updateAPIInProgress,
    createAPIInProgress,
    getAPIResult,
    getAPIRequest,
    updateAPIRequest,
    createAPIRequest,
    lastUpdateTime,
    lastCreationTime,
    lastChange,
    isEditorLoaded,
    saveDocument,
    handleSocketConnectionChange,
    createDocument: handleNewDocument,
    handleCreationOfDocumentFromGnOutput,
    handleNewMRCDocument,
    goToParser,
    selectedTopics,
    setSelectedTopics,
    isReadyForRTC,
    isDocumentValid,
    isDirty,
    rawText,
    wordsCount,
    charactersCount,
    wordsAndCharactersCountFromEditorValue,
    skippedSavesForBooting,
    generateAdaptedTopics,
    setIsLoading,
    saveDocumentTitle,
    isLoading,
    isInactive,
    editorQA,
    trainingData,
    setEditorQA,
    setIsValidationData,
    isValidationData,
    isSaved:
      lastUpdateTime > lastChange ||
      savesSkipsForBooting >= skippedSavesForBooting,
    fakeCheckAPIResult,
    fakeCheckAPIError,
    fakeCheckAPIInProgress,
    fakeCheckAPIRequest,
    setDocumentStatus,
    documentStatus,
    isTopicUpdateModeEnabled,
    setIsTopicUpdateModeEnabled,
    setIsPDFLoaded,
    isPDFLoaded,
    setExtractImages,
    extractImages,
    documentImgRetrievalInstructions,
    setDocumentImgRetrievalInstructions,
    setLastCancellation,
    lastrequestTime,
    saveRequested,
    setIsSaveRequested,
  };
};

export default useAutoSave;
