import React from "react";
import { View } from "react-native";
import Constants from "../../Constants";
import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import ToothFairyButton from "./ToothFairyButton";
import { useTranslation } from "react-i18next";
import useWorkspaces from "../../Hooks/useWorkspaces";
const BatchDownloadModalContent = ({
  type,
  onSelectionChange = () => {},
  enabled = true,
  selection = [],
  handleFormSubmit = () => {},
  filesResult = [],
  context,
}) => {
  const AppToasty = AppToast.useToast();
  const { t, i18n } = useTranslation();
  const { currentTheme } = AppTheme.useTheme();
  const { isModelDownloadEnabled } = useWorkspaces();
  const fileAvailable = (item, filesResult) => {
    if (context === "batch" && filesResult?.Contents) {
      const filesInS3 = filesResult?.Contents?.map(
        (u) => u?.Key?.split("/")[u?.Key?.split("/").length - 1]
      );
      filesInS3.push("input.csv");
      // match the entries in both the arrays and generate a new one with the matching entries
      if (filesInS3.includes(item?.filename)) {
        return true;
      }
    } else if (context === "training" && filesResult?.Contents) {
      const filesInS3 = filesResult?.Contents?.map(
        (u) => u?.Key?.split("/")[u?.Key?.split("/").length - 1]
      );
      if (filesInS3.includes(item?.filename)) {
        return true;
      }
    }
  };
  const baseFiles =
    context === "batch"
      ? Constants.BATCH_DOWNLOAD_OPTIONS
      : Constants.DOWNLOAD_OPTIONS;
  return (
    <View style={{ justifyContent: "center" }}>
      {baseFiles.map((i) => {
        if (
          (i.type === type || i.type === "ALL") &&
          fileAvailable(i, filesResult)
        ) {
          return (
            <AppText
              wrapperStyle={{ paddingHorizontal: 20, paddingVertical: 20 }}
              key={i.id || i.toString()}
              onLeftIconPress={
                enabled &&
                (() => {
                  if (!isModelDownloadEnabled() && i.id === "MODEL") {
                    AppToasty.show(t("modelDownloadDisabled"), {
                      placement: "bottom",
                      type: "warning",
                    });
                    return;
                  }
                  if (selection.includes(i.id)) {
                    selection = selection.filter((j) => j !== i.id);
                  } else {
                    selection.push(i.id);
                  }
                  onSelectionChange(selection);
                })
              }
              leftIconStyle={{
                paddingRight: 10,
              }}
              leftIcon={
                selection.find((u) => u === i.id)
                  ? AppAssets?.icons?.check
                  : AppAssets?.icons?.uncheck
              }
              leftIconColor={
                selection.find((u) => u === i.id) && enabled
                  ? currentTheme?.theme?.primary
                  : currentTheme?.theme?.grey
              }
            >
              {i.label || i.toString()}
            </AppText>
          );
        }
      })}
      <ToothFairyButton
        onPress={handleFormSubmit}
        wrapperStyle={{
          alignSelf: "center",
        }}
        title={"Download"}
      />
    </View>
  );
};

export default BatchDownloadModalContent;
