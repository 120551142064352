import Clipboard from "@react-native-clipboard/clipboard";
import useApi from "@toothfairy/shared-api/useApi";
import { AppText } from "@toothfairy/shared-ui";
import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppExpander from "@toothfairy/shared-ui/AppExpander";
import AppSecureTextInput from "@toothfairy/shared-ui/AppSecureTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import envConfig from "../../../envConfig";
import Files from "../../API/Files";
import useWorkspaces from "../../Hooks/useWorkspaces";
import AdminInfoLeaf from "../../Screens/Admin/Components/SubComponents/AdminInfoLeaf";
import utils from "../../Screens/Common/utils";
import { isLargeScreen, isMobileScreen } from "../../Screens/MainViewContainer";
import SavingNotification from "../TrainSwitcher/SavingNotification";
import DownloadModalContent from "./DownloadModalContent";
import ToothFairyButton from "./ToothFairyButton";
import ToothFairyClickableOption from "./ToothFairyClickableOption";
import ToothFairyJsonViewer from "./ToothFairyJsonViewer";
import ToothFairyLoadingPlaceholder from "./ToothFairyLoadingPlaceholder";
import ToothFairyModal from "./ToothFairyModal";
import ToothFairyResponsiveOptionsModal from "./ToothFairyResponsiveOptionsModal";
import ToothFairySupportButton from "./ToothFairySupportButton";
import ToothFairyUrlViewer from "./ToothFairyUrlViewer";

const ToothFairyOutput = ({
  isOutputExpanded,
  parsingInProgress,
  data,
  subCaption,
  isInModal,
  isOutputUpToDate,
  checkLength = true,
  isEventDriven = false,
  supportId,
  supportUrl,
  title = "Details",
  resultObjectTitle = "Request response",
  responseCopyBtnTitle = "Copy response",
  resultObjectIcon,
  requestPayload,
  path,
  method,
  trainingLogId,
  batchLogId,
  handleDataDownload,
  markTrainingLogAsActive,
  trainingInProgress,
  isSwapingInProgress,
  urlInputWidth,
  requestBodyType = "json",
  configObjectTitle,
  configObjectIcon,
  configObject,
  includeConfig = false,
  isSwapRequested,
  datasetOnly,
  showSummary = false,
  name,
  description = "No description",
  handleFieldUpdate = () => {},
  handleTrainingInitiation = () => {},
  handleFineTuningStart = () => {},
  handleFineTuningSetupOpening = () => {},
  createdBy,
  lastChangedAt,
  meta,
  trainingLogData,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const [isModalOptionsOpen, setIsModalOptionsOpen] = React.useState(false);
  const _styles = styleGenerator({
    currentTheme,
    flexDirection: "column",
    isOutputExpanded,
    isInModal,
  });
  const [isEditable, setIsEditable] = React.useState(false);
  const [isDescrEditable, setIsDescrEditable] = React.useState(false);
  const {
    getActiveWorkspace,
    getTrainingLogInfo,
    getTypeFromTrainingType,
    getUserName,
  } = useWorkspaces();
  const [selection, setSelection] = React.useState([]);
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { isTrainingLogIdActive } = useWorkspaces();
  const {
    data: filesResult,
    error: filesError,
    loading: filesInProgress,
    apiRequest: _filesRequest,
    response: filesResponse,
  } = useApi(Files.getDocumentsByLogId, envConfig);
  const handleClickOnTrain = () => {
    if (
      getTrainingLogInfo(trainingLogId)?.type == "nlpTraining" &&
      getActiveWorkspace()?.isSentimentReviewerEnabled != true
    ) {
      AppToasty.show(t("SentimentReviewerNotEnabled"), {
        placement: "bottom",
        type: "danger",
      });
    } else handleFineTuningSetupOpening(trainingLogId);
  };
  const defaultOnUtilityIconClick = (type) => {
    Clipboard.setString(
      JSON.stringify(type === "request" ? requestPayload : data)
    );
    AppToasty.show(t("JSONcopyCaption"), {
      type: "success",
    });
  };
  useEffect(() => {
    if (!isModalOptionsOpen || !isModalOpen) setSelection([]);
  }, [isModalOptionsOpen, isModalOpen]);

  useEffect(() => {
    return () => {
      setSelection([]);
    };
  }, []);

  const isUseModelEnabled =
    trainingInProgress ||
    isSwapingInProgress ||
    isSwapRequested ||
    data?.errorCode ||
    datasetOnly ||
    !isTrainingLogIdActive(trainingLogId);

  const generateAccuracy = (item, datasetOnly) => {
    if (
      item?.status === "completed" ||
      item?.status === "swapping" ||
      item?.status === "swapModelRequested" ||
      item?.status === "swapModelFailed"
    ) {
      if (item?.trainingConfig?.datasetOnly)
        return <AppText>Dataset only</AppText>;
      else if (item?.type === "nlpTraining") {
        return (
          <>
            <AppText>
              {`NER: (${utils.roundToTwo(
                item?.trainingMeta?.trainingScores?.ents_r
              )} - ${utils.roundToTwo(
                item?.trainingMeta?.trainingScores?.ents_f
              )} - ${utils.roundToTwo(
                item?.trainingMeta?.trainingScores?.ents_p
              )} )`}
            </AppText>
            <AppText>
              {`Intent: (${utils.roundToTwo(
                item?.trainingMeta?.trainingScores?.cats_macro_r
              )} - ${utils.roundToTwo(
                item?.trainingMeta?.trainingScores?.cats_macro_f
              )} - ${utils.roundToTwo(
                item?.trainingMeta?.trainingScores?.cats_macro_p
              )} )`}
            </AppText>
          </>
        );
      } else if (
        item?.type === "readComprehensionTraining" ||
        item?.type === "generativeTraining" ||
        item?.type === "conversationalTraining"
      ) {
        if (item?.trainingMeta?.metrics) {
          const trainingMeta = JSON.parse(item?.trainingMeta?.metrics);
          const lastItem = trainingMeta[trainingMeta.length - 1];
          return (
            <>
              {/* left as reference for allenNLP results */}
              {/* <AppText>
                {`Validation: (${utils.roundToTwo(
                  item?.trainingMeta?.validation_end_acc
                )})`}
              </AppText>
              <AppText>
                {`Model: (${utils.roundToTwo(
                  item?.trainingMeta?.training_end_acc
                )})`}
              </AppText> */}
              <AppText>
                {`Training Loss: (${utils.roundToTwo(
                  lastItem?.training_loss * 100
                )}%)`}
              </AppText>
              <AppText>
                {`Completion accuracy: (${utils.roundToTwo(
                  lastItem?.training_sequence_accuracy * 100
                )}%)`}
              </AppText>
              <AppText>
                {`Token prediction: (${utils.roundToTwo(
                  lastItem?.training_token_accuracy * 100
                )}%)`}
              </AppText>
            </>
          );
        }
      }
    }
  };
  const getTypeCode = (type) => {
    if (type === "nlpTraining") return "nlp";
    else if (type === "readComprehensionTraining") return "rc";
    else if (type === "generativeTraining") return "gn";
    else if (type === "conversationalTraining") return "cn";
  };
  const fetchData = async (trainingLogId, batchLogId) => {
    let type, context;
    const workspaceid = getActiveWorkspace()?.id;
    if (workspaceid && (trainingLogId || batchLogId) && requestPayload) {
      if (trainingLogId) {
        const _trainingLog = getTrainingLogInfo(trainingLogId);
        type = getTypeFromTrainingType(
          _trainingLog?.type,
          getTypeCode(_trainingLog?.type)
        ).toLowerCase();
      } else if (batchLogId) {
        type = getTypeFromTrainingType(requestPayload?.type).toLowerCase();
      }
      context = trainingLogId ? "training" : "batch";
      const result = await _filesRequest(
        workspaceid,
        trainingLogId || batchLogId,
        type,
        context
      );
    }
  };
  useEffect(() => {
    if (isModalOpen)
      fetchData(
        trainingLogId,
        batchLogId,
        getActiveWorkspace()?.id,
        requestPayload
      );
  }, [
    trainingLogId,
    batchLogId,
    getActiveWorkspace()?.id,
    requestPayload?.input?.type,
    requestPayload?.type,
    isModalOpen,
  ]);
  handleFormSubmit = () => {
    let type, context;
    if (trainingLogId) context = "training";
    else if (batchLogId) context = "batch";

    if (context === "training") {
      const trainingLog = getTrainingLogInfo(trainingLogId);
      type = getTypeFromTrainingType(
        trainingLog?.type,
        getTypeCode(trainingLog?.type)
      ).toLowerCase();
    } else if (context === "batch")
      type = getTypeFromTrainingType(requestPayload?.type).toLowerCase();
    handleDataDownload({
      workspaceid: getActiveWorkspace()?.id,
      logId: context === "training" ? trainingLogId : batchLogId,
      type,
      selection,
    });
  };

  const getChipColor = (type) => {
    if (type === "json") {
      return "primary";
    }
    if (type === "form-data") {
      return "green";
    }
    return "primary";
  };
  const getType = () => {
    if (batchLogId) return getTypeFromTrainingType(requestPayload?.type);
    else if (trainingLogId) {
      const _trainingLog = getTrainingLogInfo(trainingLogId);
      return getTypeFromTrainingType(
        _trainingLog?.type,
        getTypeCode(_trainingLog?.type)
      ).toLowerCase();
    }
  };
  const handleUseModel = () => {
    try {
      markTrainingLogAsActive(trainingLogId);
      AppToasty.show(t("ActivationChangeRequestCaption"), {
        placement: "bottom",
        type: "success",
      });
    } catch (e) {
      AppToasty.show(t("ActivationChangeRequestCaptionFailed"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  const _actionsChoice = () => {
    return (
      <ToothFairyResponsiveOptionsModal
        isModalOpen={isModalOptionsOpen}
        setIsModalOpen={setIsModalOptionsOpen}
        modalTitle={"Actions"}
      >
        {(trainingLogId || batchLogId) && (
          <>
            <ToothFairyClickableOption
              leftIcon={AppAssets.icons.save}
              onPress={() => setIsModalOpen(true)}
            >
              Download
            </ToothFairyClickableOption>
            {trainingLogId &&
              getTrainingLogInfo(trainingLogId)?.status == "datasetReady" && (
                <ToothFairyButton title="Train" onPress={handleClickOnTrain} />
              )}
            {trainingLogId &&
              getTrainingLogInfo(trainingLogId)?.trainingConfig?.datasetOnly !==
                true && (
                <ToothFairyClickableOption
                  leftIcon={AppAssets.icons.activeModel}
                  isClickable={
                    (isUseModelEnabled &&
                      getTrainingLogInfo(trainingLogId)?.trainingConfig
                        ?.datasetOnly !== true) ||
                    getTrainingLogInfo(trainingLogId)?.status !== "completed"
                  }
                  onPress={() => handleUseModel()}
                >
                  Use model
                </ToothFairyClickableOption>
              )}
          </>
        )}
      </ToothFairyResponsiveOptionsModal>
    );
  };
  const _downloadAppChoice = useMemo(() => {
    return (
      <ToothFairyModal
        modalScrollContainer={{
          justifyContent: "space-between",
        }}
        modalBackdrop={true}
        modalBackdropStatic={true}
        modalStyle={_styles.modalInput}
        modalTitle={"Download data"}
        isCloseButtonVisible
        closeModal={() => {
          setIsModalOpen(false);
        }}
        isModalVisible={isModalOpen}
        transparent={true}
      >
        {(filesInProgress || !filesResult) && (
          <ToothFairyLoadingPlaceholder
            caption="Loading files"
            style={{
              paddingTop: 50,
            }}
          />
        )}
        {(batchLogId || trainingLogId) && !filesInProgress && filesResult && (
          <DownloadModalContent
            context={batchLogId ? "batch" : "training"}
            filesResult={filesResult}
            selection={selection}
            onSelectionChange={(v) => {
              setSelection([...v]);
            }}
            type={getType()?.toUpperCase()}
            // formValidationSchema={formValidationSchema}
            // initialFormValues={initialFormValues}
            handleFormSubmit={handleFormSubmit}
          />
        )}
      </ToothFairyModal>
    );
  }, [
    isModalOpen,
    selection,
    JSON.stringify(requestPayload),
    filesInProgress,
    batchLogId,
    trainingLogId,
    JSON.stringify(getTrainingLogInfo(trainingLogId)),
  ]);

  return (
    <View style={_styles.outputContainer}>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <AppText
              wrapperStyle={{
                marginBottom: 0,
                marginTop: 0,
                justifyContent: "flex-start",
                paddingVertical: 5,
              }}
              style={_styles.title}
            >
              {trainingLogId
                ? "Fine-tuning details"
                : batchLogId
                ? "Batch details"
                : title}
            </AppText>
            {isLargeScreen() ? (
              <>
                {(batchLogId || trainingLogId) && (
                  <ToothFairyButton
                    onPress={() => {
                      setIsModalOpen(true);
                    }}
                    title={"Download"}
                  />
                )}
                {trainingLogId &&
                  getTrainingLogInfo(trainingLogId)?.status ==
                    "datasetReady" && (
                    <ToothFairyButton
                      title="Train"
                      onPress={handleClickOnTrain}
                    />
                  )}

                {((trainingLogId &&
                  getTrainingLogInfo(trainingLogId)?.trainingConfig
                    ?.datasetOnly !== true) ||
                  batchLogId) && (
                  <>
                    {trainingLogId && (
                      <ToothFairyButton
                        isDisabled={
                          !isUseModelEnabled ||
                          getTrainingLogInfo(trainingLogId)?.trainingConfig
                            ?.datasetOnly === true ||
                          getTrainingLogInfo(trainingLogId)?.status !==
                            "completed"
                        }
                        onPress={() => {
                          try {
                            markTrainingLogAsActive(trainingLogId);
                            AppToasty.show(
                              t("ActivationChangeRequestCaption"),
                              {
                                placement: "bottom",
                                type: "success",
                              }
                            );
                          } catch (e) {
                            AppToasty.show(
                              t("ActivationChangeRequestCaptionFailed"),
                              {
                                placement: "bottom",
                                type: "danger",
                              }
                            );
                          }
                        }}
                        title={"Use model"}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <ToothFairyButton
                onPress={() => {
                  setIsModalOptionsOpen(true);
                }}
                title="Actions"
              />
            )}
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {!isOutputUpToDate && data && (
              <SavingNotification
                color={"warning"}
                dotValue={1}
                label={"DataNotInSyncCaption"}
              />
            )}
            <ToothFairySupportButton
              _pageId={supportId}
              _url={`${supportUrl}/i/${supportId}`}
            />
          </View>
        </View>
      </View>

      {parsingInProgress ? (
        <AppActivityIndicator
          backgroundColor={currentTheme?.theme?.transparent}
          visible={true}
        />
      ) : (
        <View>
          {showSummary && (
            <AppExpander
              style={{ minWidth: 250 }}
              isExpandedOnLoad={true}
              title="Summary"
              icon={AppAssets.icons.summary}
            >
              <View
                style={{
                  flexDirection: isMobileScreen() ? "row" : "column",
                  paddingLeft: 10,
                  paddingBottom: 10,
                }}
              >
                <AppText
                  style={{
                    fontFamily: "boldFont",
                    color: currentTheme?.theme?.primary,
                  }}
                  wrapperStyle={{
                    marginRight: 10,
                  }}
                >
                  ID
                </AppText>

                <AdminInfoLeaf
                  wrapperStyle={{
                    alignSelf: "flex-start",
                  }}
                  value={batchLogId || trainingLogId || "No id"}
                  label="ID"
                  marginLeft={0}
                  showSimpleText={true}
                />
              </View>
              <View
                style={{
                  paddingLeft: 10,
                  maxWidth: 515,
                }}
              >
                <AppSecureTextInput
                  showTextInputLabel
                  label="Name"
                  placeholder="trainNamePlaceholder"
                  {..._styles.secureInputStyle}
                  type="TEXT"
                  textContainer={{
                    marginRight: 20,
                  }}
                  wrapperInputStyle={{
                    flex: 1,
                  }}
                  coreViewStyle={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  actionsButtonsViewStyle={{
                    marginLeft: 10,
                  }}
                  labelStyle={{
                    minWidth: isMobileScreen() ? 220 : 310,
                  }}
                  value={name}
                  isEditable={false}
                  showEditButtons={false}
                  initialValue={name}
                />
                <AppSecureTextInput
                  {..._styles.secureInputStyle}
                  showTextInputLabel
                  label="Description"
                  multiline={true}
                  numberOfLines={4}
                  placeholder="trainDescrPlaceholder"
                  type="text"
                  textContainer={{
                    paddingTop: 10,
                    marginRight: 20,
                  }}
                  wrapperInputStyle={{
                    flex: 1,
                  }}
                  coreViewStyle={{
                    justifyContent: "flex-start",
                  }}
                  actionsButtonsViewStyle={{
                    marginLeft: 10,
                  }}
                  labelStyle={{
                    minWidth: isMobileScreen() ? 220 : 310,
                  }}
                  value={description || "No description"}
                  isEditable={false}
                  showEditButtons={false}
                  initialValue={description}
                />
                <AppSecureTextInput
                  showTextInputLabel
                  label="Created by"
                  {..._styles.secureInputStyle}
                  type="TEXT"
                  value={name}
                  isEditable={false}
                  isEditEnabled={false}
                  initialValue={createdBy}
                />
                <AppSecureTextInput
                  showTextInputLabel
                  label="Last update"
                  {..._styles.secureInputStyle}
                  type="TEXT"
                  value={name}
                  isEditable={false}
                  isEditEnabled={false}
                  initialValue={dayjs(lastChangedAt).format("DD-MM-YYYY HH:mm")}
                />
                <AppSecureTextInput
                  showTextInputLabel
                  label="Duration in minutes"
                  {..._styles.secureInputStyle}
                  type="TEXT"
                  value={name}
                  isEditable={false}
                  isEditEnabled={false}
                  initialValue={
                    batchLogId
                      ? meta?.duration &&
                        parseFloat((meta?.duration || 0) / 60).toFixed(2)
                      : trainingLogId && trainingLogData?.duration
                  }
                />
                {trainingLogId &&
                  getTrainingLogInfo(trainingLogId)?.trainingConfig
                    ?.datasetOnly !== true && (
                    <>
                      <AppText
                        style={{
                          fontFamily: "boldFont",
                        }}
                        wrapperStyle={{
                          marginRight: 10,
                        }}
                      >
                        Accuracy
                      </AppText>
                      {trainingLogId && generateAccuracy(trainingLogData)}
                    </>
                  )}
              </View>
            </AppExpander>
          )}
          {batchLogId ? (
            <>
              <AppExpander
                style={{ minWidth: 250 }}
                isExpandedOnLoad={false}
                title="Request url"
                icon={AppAssets.icons.basePath}
                utilityIcon={AppAssets.icons.copy}
                onUtilityIconPress={defaultOnUtilityIconClick}
              >
                <ToothFairyUrlViewer
                  showSimpleText={true}
                  urlInputWidth={urlInputWidth}
                  method={method}
                  path={path}
                />
              </AppExpander>
              <AppExpander
                style={{ minWidth: 250 }}
                isExpandedOnLoad={false}
                title={`Request payload ${
                  requestBodyType ? `(${requestBodyType})` : ""
                }`}
                icon={AppAssets.icons.decision}
                utilityIcon={AppAssets.icons.copy}
                onUtilityIconPress={defaultOnUtilityIconClick}
              >
                <View
                  style={{
                    paddingLeft: 0,
                    flexDirection: "row",
                  }}
                >
                  {/* {!isMobileScreen() && requestPayload && (
                <AppTextChip
                  fontWeight="bold"
                  backgroundColor={
                    currentTheme?.theme[getChipColor(requestBodyType)]
                  }
                  borderColor={
                    currentTheme?.theme[getChipColor(requestBodyType)]
                  }
                  label={requestBodyType}
                  styleContainer={{
                    width: 100,
                    alignSelf: "center",
                    maxHeight: 30,
                  }}
                />
              )} */}
                  <View
                    style={{
                      paddingLeft: 30,
                      width: "100%",
                    }}
                  >
                    <ToothFairyJsonViewer
                      minLevelExpanded={2}
                      data={requestPayload}
                      isEmpty={!requestPayload}
                      subCaption={subCaption}
                    />
                  </View>
                </View>
              </AppExpander>
            </>
          ) : (
            !batchLogId &&
            !trainingLogId && (
              <View
                style={{
                  paddingLeft: 30,
                  width: "100%",
                }}
              >
                <ToothFairyJsonViewer
                  minLevelExpanded={4}
                  data={requestPayload}
                  isEmpty={!requestPayload}
                  subCaption={subCaption}
                />
              </View>
            )
          )}

          {includeConfig &&
            getTrainingLogInfo(trainingLogId)?.trainingConfig?.datasetOnly !==
              true && (
              <AppExpander
                style={{ minWidth: 250 }}
                isExpandedOnLoad={false}
                title={configObjectTitle}
                icon={configObjectIcon || AppAssets.icons.response}
              >
                <View
                  style={{
                    paddingLeft: 30,
                  }}
                >
                  <ToothFairyJsonViewer
                    data={configObject}
                    isEmpty={configObject === null}
                    subCaption={""}
                  />
                </View>
              </AppExpander>
            )}
          {(trainingLogId &&
            getTrainingLogInfo(trainingLogId)?.trainingConfig?.datasetOnly !==
              true) ||
            (batchLogId && (
              <AppExpander
                style={{ minWidth: 250 }}
                isExpandedOnLoad={false}
                title={resultObjectTitle}
                icon={resultObjectIcon || AppAssets.icons.response}
              >
                <View
                  style={{
                    paddingLeft: 30,
                  }}
                >
                  <ToothFairyJsonViewer
                    data={data}
                    isEmpty={
                      !((checkLength && data?.length > 0) || !checkLength)
                    }
                    subCaption={subCaption}
                  />
                </View>
              </AppExpander>
            ))}

          <View
            style={{
              flexDirection: "column",
              paddingLeft: 20,
            }}
          >
            {isEventDriven && (
              <AppText
                leftIconStyle={{
                  paddingRight: 17,
                }}
                wrapperStyle={{
                  alignItems: "center",
                }}
                iconSize={16}
                leftIcon={AppAssets?.icons?.idea}
                color={currentTheme?.theme?.orange}
              >
                asyncProcessingCaption
              </AppText>
            )}
          </View>
        </View>
      )}
      {_downloadAppChoice}
      {_actionsChoice()}
    </View>
  );
};

const styleGenerator = ({
  currentTheme,
  flexDirection,
  isOutputExpanded,
  isInModal,
}) => {
  return StyleSheet.create({
    title: {
      fontSize: currentTheme?.theme?.medium_font_size,
      fontWeight: "bold",
      // color: currentTheme?.theme?.white,
    },
    outputContainer: {
      flex: flexDirection === "row" ? 0.3 : null,
      paddingHorizontal: flexDirection === "row" ? 20 : 10,
      marginTop: flexDirection === "row" || isInModal ? 0 : 20,
      overflow: !isOutputExpanded ? "hidden" : "auto",
      height: isInModal && "100%",
      flexBasis: !isInModal && 250,
      minWidth: "100%",
      zIndex: -1,
    },
    modalInput: {
      height: 400,
      marginHorizontal: "auto",
      marginVertical: "auto",
      width: 600,
    },
    secureInputStyle: {
      textInputLabelStyle: {
        marginLeft: 0,
        marginBottom: 10,
      },
      style: {
        marginTop: 2,
      },
      wrapperStyleSecureTextInput: {
        maxWidth: 850,
        minWidth: 350,
        marginBottom: 10,
        marginRight: 15,
        flex: 1,
      },
      wrapperInputStyle: {
        flex: 1,
        marginRight: 10,
      },
      textInputLabelStyleCore: {
        marginBottom: 10,
      },
      textContainer: {
        margin: 0,
        padding: 10,
      },
    },
  });
};

export default ToothFairyOutput;
