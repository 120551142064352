import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import { showSideMenu } from "../../../../../App";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import { isLargeScreen } from "../../../MainViewContainer";
import ConnectionsSettingsAI from "./ConnectionsSettingsAI";
import ConnectionsSettingsToolbar from "./ConnectionsSettingsToolbar";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import connectionsHelper from "../../../../API/Connections/connectionsHelper";
import AgentFunctions from "../../../../API/AgentFunctions";

const ConnectionsList = ({ isFunctionsLoading, setDisplayedView }) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const { width } = useWindowDimensions();
  const { getPlanConfig } = useWorkspaces();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [showConnectionModal, setShowConnectionModal] = React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedConnections, setDisplayedConnections] = React.useState([]);
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { height } = useWindowDimensions();
  const [initialMapValues, setInitialMapValues] = useState({
    name: "",
    description: "",
    type: "sql",
    host: "",
    port: "",
    username: "",
    passwordSecret: "",
  });
  const columns = [
    {
      title: "ID",
      width: 0.00001,
    },
    {
      title: "DB Name",
      width: 0.25,
    },
    {
      title: "Description",
      width: 0.25,
    },
    {
      title: "DB Type",
      width: 0.1,
    },
    // {
    //   title: "Authorisation Type",
    // },
    {
      title: "Host",
      width: 0.3,
    },
    {
      title: "Actions",
      isAction: true,
      width: 0.1,
    },
  ];
  const onDbConnectionCreated = useCallback(
    (event) => {
      if (event === "success") {
        AppToasty.show(t("conectionCreationSuccessCaption"), {
          type: "success",
        });
        loadConnections(state?.activeWorkspaceID);
        setShowConnectionModal(false);
      } else
        AppToasty.show(t("connectionCreationFailedCaption"), {
          type: "danger",
        });
      formRef?.current?.resetForm();
      setShowConnectionModal(false);
      setSelectedIndex(null);
    },
    [
      AppToasty,
      t,
      setShowConnectionModal.formRef?.current,
      loadConnections,
      state?.activeWorkspaceID,
    ]
  );
  const loadConnections = async (workspaceID) => {
    const connectionsData = await connectionsHelper.getDatabaseConnections(
      workspaceID,
      null
    );

    let connections = connectionsData?.data?.connectionsByWorkspace?.items;
    connections =
      connections
        ?.map((u) => {
          if (u?._deleted !== true)
            return {
              ...u,
            };
        })
        ?.filter((u) => u != null) || [];
    dispatch("SET_DB_CONNECTIONS", connections || []);

    setDisplayedConnections(connections);
  };
  useEffect(() => {
    loadConnections(state?.activeWorkspaceID);
  }, [state?.activeWorkspaceID]);
  useEffect(() => {
    setDisplayedConnections([...state?.dbConnections]);
  }, [JSON.stringify([...state?.dbConnections])]);

  const connectionItemCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        key={Date.now().toString()}
        modalStyle={{
          maxHeight: "95%",
          height: "auto",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showConnectionModal}
        setIsModalOpen={setShowConnectionModal}
        modalTitle={
          isEditMode ? "connectionEditTitle" : "connectionCreationTitle"
        }
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowConnectionModal(false);
          setSelectedIndex(null);
        }}
      >
        <ConnectionsSettingsAI
          ref={formRef}
          initialMapValues={initialMapValues}
          onDbConnectionCreated={onDbConnectionCreated}
          isEdit={isEditMode}
          selectedId={displayedConnections[selectedIndex]?.id}
          opt={displayedConnections[selectedIndex]}
          setDisplayedView={setDisplayedView}
        />
      </ToothFairyModal>
    );
  }, [
    showConnectionModal,
    setShowConnectionModal,
    initialMapValues,
    isEditMode,
    selectedIndex,
    displayedConnections,
    isLargeScreen(),
  ]);
  const identifyOptByID = (id) => {
    return state.dbConnections.find((u) => u.id === id);
  };
  const handleConnectionsItemSelection = ({ item, index }) => {
    setSelectedIndex(index);
    setIsEditMode(true);
    let extendedItem = identifyOptByID(item.id);
    setInitialMapValues({
      ...extendedItem,
    });

    setShowConnectionModal(true);
  };
  const handleConnectionItemDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        const functionsData =
          await AgentFunctions.getAgentFunctionsByWorkspaceID({
            workspaceID: state?.activeWorkspaceID,
          });
        let functions = functionsData?.data?.functionsByWorkspace?.items;
        functions =
          functions
            ?.map((u) => {
              if (u?.dbConnectionID === displayedConnections[selectedIndex]?.id)
                return {
                  ...u,
                };
            })
            ?.filter((u) => u != null) || [];
        if (functions.length > 0) {
          AppToasty.show(t("connectionDeletionErrorCaptionDueToConflict"), {
            type: "danger",
          });
          return;
        }
        const _id = displayedConnections[selectedIndex]?.id;
        const result = await connectionsHelper.deleteDatabaseConnection(_id);
        dispatch("SET_DB_CONNECTIONS", [
          ...state?.dbConnections.filter((u) => u.id !== _id),
        ]);
        AppToasty.show(t("connectionDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      console.log(error);
      AppToasty.show(t("connectionDeletionErrorCaption"), {
        type: "danger",
      });
    }
  };
  const handleSearch = async (name, description, host, type) => {
    // find items in the list which cointain either the sourceText or targetText
    const filteredItems = state?.agentsFunctions.filter((item) => {
      return (
        item.name.toLowerCase().includes(name.toLowerCase()) &&
        item.description.toLowerCase().includes(description.toLowerCase()) &&
        item.host.toLowerCase().includes(host.toLowerCase()) &&
        item.type.toLowerCase().includes(type.toLowerCase())
      );
    });
    setDisplayedConnections(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleCreation = () => {
    setInitialMapValues({
      name: "",
      description: "",
      type: "sql",
      host: "",
      port: "",
      username: "",
      passwordSecret: "",
      database: "",
    });
    setIsEditMode(false);
    setShowConnectionModal(true);
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"connectionsItemCancelConfirmation"}
        caption="connectionsItemCancelCaption"
        subCaption="connectionsItemCancelSubCaption"
        confirmCaption={"connectionsItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={() => handleConnectionItemDeletion()}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [setShowChoice, showChoice, selectedItem, setSelectedItem]);

  return (
    <View
      style={{
        paddingLeft: 10,
        flex: 1,
        width: showSideMenu() ? width - (showSideMenu() ? 410 : 220) : "100%",
        minWidth: "100%",
      }}
    >
      {getPlanConfig()?.isDBFunctionsEnabled ? (
        <>
          <ConnectionsSettingsToolbar
            handleSearch={handleSearch}
            handleCreation={handleCreation}
          />
          {isFunctionsLoading ? (
            <AppActivityIndicator
              backgroundColor={currentTheme?.theme?.softBackgroundColor}
              visible={true}
              containerStyle={{
                backgroundColor: currentTheme?.theme?.transparent,
                minHeight: 250,
              }}
            />
          ) : (
            <>
              {displayedConnections.length > 0 ? (
                <AppTable
                  actionButtons
                  handleDocumentSelection={handleConnectionsItemSelection}
                  handleDocumentDeletion={(item, index) => {
                    setSelectedItem(item);
                    setSelectedIndex(index);
                    setShowChoice(true);
                  }}
                  style={{
                    zIndex: -1,
                    maxHeight: height - 180,
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                  headers={columns}
                  items={displayedConnections
                    ?.slice(
                      (currentPage - 1) * pageSize,
                      (currentPage - 1) * pageSize + pageSize
                    )
                    ?.map((u) => {
                      return {
                        id: u?.id,
                        name: u?.name || " ",
                        description: u?.description || " ",
                        type: u?.type?.toUpperCase() || " ",
                        host: u?.host,
                        actions: " ",
                      };
                    })}
                />
              ) : (
                <AppPlaceholderView
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    marginVertical: 60,
                    marginHorizontal: "auto",
                    flex: 1,
                    zIndex: -1,
                  }}
                  size={80}
                  icon={AppAssets.icons.orbit}
                  mainCaption="EmptyPlaceholder"
                  subCaption="ConnectionsSubCaption"
                />
              )}
              {displayedConnections?.length > pageSize && (
                <AppPagination
                  onPageChange={handlePageChange}
                  totalCount={displayedConnections?.length}
                  siblingCount={1}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  wrapperStyle={{
                    marginTop: 10,
                    alignSelf: "flex-end",
                  }}
                />
              )}
            </>
          )}
        </>
      ) : (
        <AppPlaceholderView
          accessibilityLabel="db-connections-availability"
          wrapperStyle={{
            maxWidth: 400,
            height: 200,
            marginHorizontal: "auto",
            marginTop: 50,
          }}
          size={60}
          icon={AppAssets.icons.accessDenied}
          mainCaption="DB connections are not available for your plan"
          subCaption="Upgrade to enterprise to enable DB connections"
        />
      )}
      {connectionItemCreationComponent}
      {_coreChoice}
    </View>
  );
};

export default ConnectionsList;
