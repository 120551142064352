import AppButton from "@toothfairy/shared-ui/AppButton";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { StyleSheet, View } from "react-native";
import ToothFairySearch from "../../../Components/ToothFairyComponents/ToothFairySearch";
import Constants from "../../../Constants";
import DateFilter from "./Leafs/DateFilter";
import OperationsFilter from "./Leafs/OperationsFilter";
import ToothFairySwitch from "../../../Components/ToothFairyComponents/ToothFairySwitch";

const HumanFeedbackFilter = ({
  startDate,
  endDate,
  onUpdateEndDate,
  onUpdateStartDate,
  triggerFetchLogs,
  selectedServiceType,
  selectedFilterLimit,
  setSelectedFilterLimit,
  setSelectedServiceType,
  showDates,
  showIncludeResolved,
  setIncludeResolved,
  includeResolved,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const styles = styleGenerator(currentTheme);
  return (
    <ToothFairySearch handleSearch={triggerFetchLogs}>
      {showDates && (
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          onUpdateEndDate={onUpdateEndDate}
          onUpdateStartDate={onUpdateStartDate}
        />
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <OperationsFilter
          items={[
            {
              label: "All",
              service: "",
            },
            {
              label: "Multi Language",
              service: "multilanguage",
            },
            {
              label: "Documentation",
              service: "documentation",
            },
            {
              label: "Reasoning",
              service: "reasoning",
            },
            {
              label: "Images extraction",
              service: "images",
            },
            {
              label: "Charts generation",
              service: "charting",
            },
            {
              label: "Live data",
              service: "api",
            },
          ]}
          propertyLabel="label"
          label="Type"
          selectedServiceType={selectedServiceType}
          setSelectedServiceType={setSelectedServiceType}
          selectedFilterLimit={selectedFilterLimit}
          setSelectedFilterLimit={setSelectedFilterLimit}
          minWidth={150}
          textAlign="flex-start"
        />
        {showIncludeResolved && (
          <ToothFairySwitch
            wrapperStyle={{
              maxWidth: 280,
              marginLeft: 20,
              backgroundColor: currentTheme?.theme?.inputBackgroundColor,
            }}
            label={"Show resolved"}
            onChange={(v) => {
              setIncludeResolved(v);
            }}
            isTicked={includeResolved}
          />
        )}
      </View>
    </ToothFairySearch>
  );
};

const styleGenerator = (currentTheme) => {
  return StyleSheet.create({
    button: {
      marginBottom: 5,
      height: Constants.appButtonHeight,
      width: 200,
      marginLeft: "auto",
      marginRight: "auto",
    },
    buttonLabel: {
      fontSize: currentTheme?.theme?.small_font_size,
    },
  });
};

export default HumanFeedbackFilter;
