import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import envConfig from "../../../envConfig";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";

const getLogs = async ({ workspaceId, service, name, start, end, queryId }) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/logs/fetchLogs?workspaceId=${workspaceId}&service=${service}&name=${name}&start=${start}&end=${end}`;
  if (queryId) url = url + `&queryId=${queryId}`;

  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getLogsByWorkspace = async ({
  workspaceId,
  service,
  start,
  end,
  nextToken,
  startFromHead = true,
  limit,
}) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/logs/fetchLogsByWorkspaceId?workspaceId=${workspaceId}&service=${service}&start=${start}&end=${end}&limit=${limit}`;
  if (nextToken) url = url + `&nextToken=${nextToken}`;
  if (startFromHead) url = url + `&startFromHead=${startFromHead}`;

  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const getLogTypes = async () => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/logs/fetchLogTypes`;

  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

export default { getLogs, getLogTypes, getLogsByWorkspace };
