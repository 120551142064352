import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppModal from "@toothfairy/shared-ui/AppModal";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { Platform } from "react-native";
import ToothFairyButton from "./ToothFairyButton";
import React from "react";

const ToothFairyChoice = ({
  showChoice,
  setShowChoice,
  onCancelButtonPress,
  onConfirmButtonPress,
  caption,
  subCaption,
  cancelCaption,
  confirmCaption,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <AppModal
      transparent
      modalStyle={
        Platform.OS === "web"
          ? {
              maxWidth: 600,
              maxHeight: "80%",
              margin: "auto",
              borderRadius: 20,
              marginHorizontal: "auto",
            }
          : {}
      }
      isModalVisible={showChoice}
      animationType="none"
      modalBackdrop={currentTheme?.theme?.softBackgroundColor}
      isCloseButtonVisible
      modalTitle=" "
      modalInnerContentStyle={{
        paddingHorizontal: 50,
        paddingVertical: 20,
      }}
      closeModal={() => setShowChoice(false)}
    >
      <AppChoice
        wrapperStyle={{ alignSelf: "center", maxWidth: 600 }}
        caption={caption}
        subCaption={subCaption}
        cancelCaption={cancelCaption}
        confirmCaption={confirmCaption}
        onConfirmButtonPress={onConfirmButtonPress}
        onCancelButtonPress={onCancelButtonPress}
        AppChoiceButton={ToothFairyButton}
      />
    </AppModal>
  );
};

export default ToothFairyChoice;
