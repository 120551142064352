import React, { useEffect, useState } from "react";
import useWorkspaces from "../Hooks/useWorkspaces";
import { Redirect, Route } from "./index";
import { routes } from "./routes";

const AdminRoute = (props) => {
  const { isUserAdmin, isUserLoadedInWorkspace, getActiveWorkspace } =
    useWorkspaces();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isUserAdmin() && isUserLoadedInWorkspace()) {
        setShouldRedirect(true);
      }
    }, 500); // change 2000 to the number of milliseconds you want to wait

    return () => clearTimeout(timer); // this will clear Timeout if component unmounts for any reason
  }, [isUserAdmin, isUserLoadedInWorkspace]);
  return shouldRedirect ? (
    <Redirect
      to={{
        pathname: routes.accessDenied,
      }}
    />
  ) : (
    <Route {...props} />
  );
};

export default AdminRoute;
