import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppModal from "@toothfairy/shared-ui/AppModal";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View, useWindowDimensions } from "react-native";
import LoadingPlaceholder from "../../Components/LoadingPlaceholder";
import Notification from "../../Components/Notification";
import PageTitle from "../../Components/PageTitle";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairyModal from "../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairySupportButton from "../../Components/ToothFairyComponents/ToothFairySupportButton";
import useWorkspaces from "../../Hooks/useWorkspaces";
import {
  isLargeScreen,
  isMobileScreen,
  minAppWidth,
} from "../MainViewContainer";
import TopicsSelectionComponent from "../MrcDocuments/Components/TopicsSelectionComponent";
import Styles from "../Styles/Styles";
import { emptyTitleValue } from "../TestExample/commonUtils";
import DocumentsImportModalContent from "./Components/DocumentsImportModalContent";
import FilterBar from "./Components/FilterBar";
const DocumentsScreen = ({
  onDeleteSelectedItem,
  currentPage,
  onItemPress,
  items,
  selectedItems,
  groupByProperty,
  trainingData,
  requestInProgress,
  NERStyles,
  IntentStyles,
  handleDocumentSelection,
  handleDocumentDeletion,
  handleImport,
  handleFileSelect,
  isImportInProgress,
  isImportStarted,
  importProgress,
  handlePageChange = () => {},
  handleChangeText = () => {},
  handleSearch = () => {},
  handleImportsGeneric = () => {},
  handleChangeOfImportType,
  handleChangeOfImportFile,
  textString,
  documentIdString,
  handleChangeDocumentIdString,
  isModalOpen,
  setIsModalOpen,
  formValidationSchema,
  initialFormValues,
  handleFormSubmit = () => {},
  documentType,
  handleDocumentTypeChange = () => {},
  handleTabChange = () => {},
  isImportEnabled,
  stopImportProcess,
  routes,
  initialTabFromRouting,
  isFileUploadInProgress,
  batchUploadStatus,
  isUploading,
  handleValidationOnly,
  isValidationOnly,
  handleDocumentCreation = () => {},
  createAPIInProgress,
  filePathGeneric,
  showModal,
  setShowModal,
  onTopicCreated = () => {},
  topicsForImport,
  setSelectedTopicsForImport,
  ...props
}) => {
  const largeColumns = [
    {
      title: "ID",
      width: 0.05,
    },
    {
      title: "Title",
      width: 0.1,
    },
    {
      title: "Document",
      width: 0.5,
    },
    {
      title: "Meta",
      width: 0.2,
    },
    {
      title: "Type",
      width: 0.1,
    },
    {
      title: "Actions",
      width: 0.00001,
    },
  ];
  const smallColumns = [
    {
      title: "ID",
      width: 0.2,
    },
    {
      title: "Title",
      width: 0.4,
    },
    {
      title: "Actions",
      width: 0.00001,
    },
  ];

  const { getWorkspaceEntities } = useWorkspaces();
  const [modalContentKey, setModalContentKey] = React.useState(null);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const { currentTheme } = AppTheme.useTheme();
  const dimensions = useWindowDimensions();
  const coreViewHeight = dimensions.height - 200;
  const { state } = AppStateManager.useAppStateManager();
  const _styles = styleGenerator(currentTheme, coreViewHeight);
  const { t } = useTranslation();
  const topics = getWorkspaceEntities("topic");
  const [normalisedTableItems, setNormalisedTableItems] = useState([]);
  const [columns, setColumns] = useState(largeColumns);
  let filesArray = [];
  if (filePathGeneric) filesArray = Array.from(filePathGeneric);
  useEffect(() => {
    setNormalisedTableItems(
      trainingData?.data?.map(
        (u) => {
          const titleNode =
            u?.document && u?.document[0]?.type === "title"
              ? u.document[0]
              : emptyTitleValue[0];
          const entities = [];
          if (u?.trainingdata?.data?.length > 0) {
            u?.trainingdata?.data?.map((u) => {
              if (u.UniqueEntities?.length > 0)
                entities.push(...u.UniqueEntities);
            });
          }
          u?.topics?.map((u) => {
            const match = topics?.filter((t) => t?.id === u)[0]?.label;
            if (match) {
              entities.push(match);
            }
          });
          if (
            (u?.document &&
              u?.document?.filter((e) => e.type !== "title")?.length > 0) ||
            u?.external_path
          )
            if (isLargeScreen())
              return {
                id: u?.id?.toString(),
                title: u?.external_path
                  ? u?.title
                  : AppQuantumEditorUtils.Helpers.generateStringFromElement(
                      titleNode
                    ) || "",
                document: u?.external_path
                  ? "Imported file"
                  : AppQuantumEditorUtils.Helpers.getPlainText(u?.document),
                meta: [...new Set(entities)]?.join(", "),
                type: u.type,
                actions: " ",
              };
            else {
              return {
                id: u?.id?.toString(),
                title: u?.external_path
                  ? u?.title
                  : AppQuantumEditorUtils.Helpers.generateStringFromElement(
                      titleNode
                    ) || "",
                actions: " ",
              };
            }
        },
        [isLargeScreen()]
      )
    );
    if (isLargeScreen()) setColumns(largeColumns);
    else setColumns(smallColumns);
  }, [trainingData?.data, isLargeScreen()]);
  const generateModalTitle = (modalContentKey) => {
    if (modalContentKey === "IMPORT") return "Import wizard";
    else if (modalContentKey === "DELETE_DOC") return "Delete document";
    else if (modalContentKey === "EXPORT") return "Export wizard";
  };
  const importDocumentsModal = (
    <ToothFairyModal
      transparent
      modalStyle={
        Platform.OS === "web"
          ? {
              maxWidth: 600,
              height: "auto",
              marginHorizontal: "auto",
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: 20,
            }
          : {}
      }
      isModalVisible={showModal}
      animationType="none"
      modalBackdrop={currentTheme?.theme?.softBackgroundColor}
      isCloseButtonVisible={!isFileUploadInProgress}
      modalTitle={"Import training data"}
      modalInnerContentStyle={{
        paddingHorizontal: 50,
        paddingVertical: 20,
      }}
      closeModal={() => {
        setShowModal(false);
        setSelectedTopicsForImport(null);
      }}
    >
      <>
        <View
          style={{
            padding: 20,
            overflowY: "auto",
          }}
        >
          <AppText
            wrapperStyle={{
              paddingBottom: 10,
            }}
            color={currentTheme?.theme?.primary}
          >
            Selected files:
          </AppText>
          {filesArray?.map(
            (file) =>
              file?.name && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: 10,
                    maxWidth: 450,
                  }}
                >
                  <AppText
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 400,
                    }}
                    fontWeight="bold"
                  >
                    {file?.name || "No file selected"}
                  </AppText>
                  {batchUploadStatus[file?.name] && (
                    <AppText
                      style={{
                        marginLeft: 10,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      fontWeight="bold"
                      color={
                        batchUploadStatus[file?.name] == 100
                          ? currentTheme?.theme?.green
                          : currentTheme?.theme?.orange
                      }
                    >
                      {` ${Math.floor(batchUploadStatus[file?.name])}%`}
                    </AppText>
                  )}
                </View>
              )
          )}
          <TopicsSelectionComponent
            showAddNew
            onTopicCreated={onTopicCreated}
            showEditIcon={false}
            isTopicUpdateModeEnabled={true}
            selectedTopics={topicsForImport}
            onSelectedTopicsChange={(v) => setSelectedTopicsForImport(v)}
            topics={topics}
            wrapperContainerStyle={{
              flex: 1,
            }}
            wrapperStyle={{
              marginBottom: 20,
            }}
          />

          <ToothFairyButton
            isDisabled={isFileUploadInProgress}
            title={isFileUploadInProgress ? "In progress" : "Upload"}
            onPress={handleImportsGeneric}
            wrapperStyle={{
              alignSelf: "center",
            }}
          />
        </View>
      </>
    </ToothFairyModal>
  );
  return (
    <AppBaseScreen
      isScrollable={true}
      contentContainerStyle={{
        // overflow: "auto",
        minHeight: 450,
      }}
    >
      <View
        style={{
          paddingHorizontal: 20,
          paddingTop: 10,
          minWidth: minAppWidth(),
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <PageTitle>Documents</PageTitle>
            <ToothFairySupportButton />
            <ToothFairyButton
              accessibilityLabel="importButton"
              title="Import data"
              onPress={(e) => {
                setModalContentKey("IMPORT");
                setIsModalOpen(true);
              }}
              isDisabled={!isImportEnabled}
            />
            <ToothFairyButton
              {...Styles.minifiedButtonStyle}
              isDisabled={!state?.isWorkspaceReady || createAPIInProgress}
              onPress={() => {
                handleDocumentCreation();
              }}
              title="Create new doc"
              accessibilityLabel="createNewDOC"
            />
          </View>
          <View style={{ flexDirection: "column" }}>
            {isImportInProgress && (
              <Notification
                label={"Training data import in progress"}
                dotValue={1}
                color={"orange"}
              />
            )}
            {isImportStarted && (
              <AppText
                isClickable
                isHoverable={false}
                leftIcon={AppAssets.icons.close}
                color={currentTheme?.theme?.red}
                onPress={stopImportProcess}
                iconSize={16}
                wrapperStyle={{
                  alignSelf: "flex-end",
                  marginTop: 10,
                }}
                style={{
                  paddingLeft: 10,
                }}
              >
                Abort import
              </AppText>
            )}
          </View>
        </View>
        <FilterBar
          routes={routes}
          textString={textString}
          documentType={documentType}
          documentIdString={documentIdString}
          handleChangeDocumentIdString={handleChangeDocumentIdString}
          handleDocumentTypeChange={handleDocumentTypeChange}
          handleChangeText={handleChangeText}
          items={items}
          onDeleteSelectedItem={onDeleteSelectedItem}
          onItemPress={onItemPress}
          selectedItems={selectedItems}
          handleSearch={handleSearch}
          handleTabChange={handleTabChange}
          initialTabFromRouting={initialTabFromRouting}
          handleValidationOnly={handleValidationOnly}
          isValidationOnly={isValidationOnly}
        />
        <View style={{ zIndex: -1 }}>
          {requestInProgress ? (
            <>
              <LoadingPlaceholder
                containerStyle={{
                  ..._styles.animation,
                  minHeight: 150,
                  justifyContent: "flex-end",
                }}
                visible={true}
                caption="Loading documents..."
              />
              {isImportInProgress && <AppText>{importProgress}</AppText>}
            </>
          ) : trainingData?.data?.length > 0 ? (
            <AppTable
              style={{
                zIndex: -1,
                height: dimensions.height - 373,
                minHeight: 400,
                overflow: "auto",
                padding: 10,
                maxWidth: isMobileScreen() && dimensions.width - 15,
              }}
              headers={columns}
              itemsPerPage={100}
              items={normalisedTableItems}
              mapSelectionIconWithStatus={(item) => {
                if (
                  trainingData?.data?.find((u) => u?.id === item?.id)
                    ?.source === "dataImporter"
                )
                  return AppAssets?.icons?.download;
              }}
              actionButtons={true}
              isItemEditable={(item) => {
                const match = trainingData?.data?.filter(
                  (u) => u.id == item.id
                )[0];

                return match?.source !== "api";
              }}
              handleDocumentSelection={handleDocumentSelection}
              handleDocumentDeletion={(document) => {
                setModalContentKey("DELETE_DOC");
                setDocumentToDelete(document);
                setIsModalOpen(true);
              }}
            />
          ) : (
            <AppPlaceholderView
              wrapperStyle={{
                width: "100%",
                height: "100%",
                marginVertical: 20,
                marginHorizontal: "auto",
              }}
              size={80}
              icon={AppAssets.icons.orbit}
              mainCaption="EmptyPlaceholder"
              subCaption="DocumentsSubCaption"
            />
          )}
          {trainingData?.totalResults > 0 && !requestInProgress && (
            <AppPagination
              onPageChange={handlePageChange}
              totalCount={trainingData?.totalResults}
              siblingCount={1}
              currentPage={currentPage}
              pageSize={100}
              wrapperStyle={[_styles.paginationWrapper]}
            />
          )}
        </View>
        <AppModal
          modalScrollContainer={{
            justifyContent: "space-between",
          }}
          modalBackdrop={true}
          modalBackdropStatic={true}
          modalStyle={_styles.modalInput}
          modalTitle={generateModalTitle(modalContentKey)}
          isCloseButtonVisible
          closeModal={() => {
            setIsModalOpen(false);
          }}
          isModalVisible={isModalOpen}
          transparent={true}
        >
          {modalContentKey === "IMPORT" ? (
            <DocumentsImportModalContent
              formValidationSchema={formValidationSchema}
              initialFormValues={initialFormValues}
              handleChangeOfImportFile={handleChangeOfImportFile}
              handleChangeOfImportType={handleChangeOfImportType}
              handleFormSubmit={handleFormSubmit}
            />
          ) : (
            <AppChoice
              wrapperStyle={{
                alignSelf: "center",
                maxWidth: 600,
                marginTop: 50,
              }}
              cancelCaption="documentDeleteCancelCaption"
              confirmCaption="documentDeleteConfirmCaption"
              onConfirmButtonPress={() => {
                handleDocumentDeletion(documentToDelete);
                setIsModalOpen(false);
              }}
              onCancelButtonPress={() => {
                setIsModalOpen(false);
                setDocumentToDelete(null);
                setModalContentKey("");
              }}
              AppChoiceButton={ToothFairyButton}
              caption="documentDeleteCaption"
              subCaption="documentDeleteSubCaption"
            />
          )}
        </AppModal>

        {/* LIST OF EDITORS WITH SPECIFIC MODE FOR DISPLAY NOT EDITABLE */}
      </View>
      {importDocumentsModal}
    </AppBaseScreen>
  );
};
const styleGenerator = (props, coreViewHeight) => {
  return StyleSheet.create({
    title: {
      fontSize: 26,
    },
    list: {
      minHeight: coreViewHeight,
    },
    animation: {
      backgroundColor: props?.theme?.transparent,
      minHeight: coreViewHeight,
    },
    paginationWrapper: {
      marginTop: 10,
      alignSelf: "flex-end",
    },
    modalInput: {
      height: 400,
      marginHorizontal: "auto",
      marginVertical: "auto",
      width: 600,
    },
  });
};

export default DocumentsScreen;
