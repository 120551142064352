import useAPI from "@toothfairy/shared-api/useApi";
import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import React, { useEffect } from "react";
import { View } from "react-native";
import envConfig from "../../../../../envConfig";
import chatsHelper from "../../../../API/Chats/chatsHelper";
import HumanFeedback from "../../../../API/HumanFeedback";
import messagesHelper from "../../../../API/Messages/messagesHelper";
import trainingAPI from "../../../../API/Training/trainingAPI";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { useHistory } from "../../../../Router";
import CorefeedbackViewForm from "./CoreFeedbackViewForm";
import Tuner from "../../../../API/Tuner";
import ToothFairyLoadingPlaceholder from "../../../../Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";

const FeedbackView = ({
  feedback,
  handleFeedbackUpdate,
  isFeedbackUpdateInProgress,
  setIsFeedbackUpdateInProgress,
  members,
  isChat,
}) => {
  const { getActiveWorkspace } = useWorkspaces();
  const history = useHistory();
  const { currentTheme } = AppTheme.useTheme();
  const [isAutoTunedOn, setIsAutoTunedOn] = React.useState(feedback?.autoTune);
  const [adminResolution, setAdminResolution] = React.useState(
    feedback?.adminResolution
  );
  const [isCreationInProgress, setIsCreationInProgress] = React.useState(false);
  const AppToasty = AppToast.useToast();
  const { user } = AppUser.useUser();
  const [messages, setMessages] = React.useState([]);
  const [chat, setChat] = React.useState({});
  const [stream, setStream] = React.useState({});
  const subFeedbackTypeLabel = AppUtilities?.subFeedbackTypeOptions[
    feedback?.humanFeedbackType
  ]?.find((u) => u?.id === feedback?.humanFeedbackSubType)?.label;
  const {
    data: createAPIResult,
    error: createAPIError,
    loading: createAPIInProgress,
    apiRequest: createAPIRequest,
    lastUpdate: lastCreationTime,
  } = useAPI(trainingAPI.postTrainingData, envConfig);
  const validationSchema = AppForms.yup.object().shape({
    // allow for no more than 4 words in sourceLanguage and targetLanguage
    adminResolution: AppForms.yup
      .string()
      .label("Resolution")
      .max(255, "Too long, maximum of 255 characters are allowed."),
    adminChecked: AppForms.yup.bool().nullable(),
  });
  const getChat = async () => {
    const result = await chatsHelper.getChat(feedback?.chatID);
    setChat(result?.data?.getChat);
  };
  const getStream = async () => {
    const result = await HumanFeedback.getOutputStream_(feedback?.id);
    console.log("Stream", result);
    setStream(result?.data?.getOutputStream);
  };
  const getChatMessages = async () => {
    let _messages = await messagesHelper.getChatMessages(feedback?.chatID);
    _messages = _messages?.data?.messagesByChat?.items?.filter(
      (u) => u?._deleted != true
    );
    _messages = _messages.map((message) => {
      if (typeof message.metadata === "string") {
        message.metadata = JSON.parse(message.metadata);
      }
      if (typeof message.callbackMetadata === "string") {
        message.callbackMetadata = JSON.parse(message.callbackMetadata);
      }
      return message;
    });
    const sortedMessage = _messages.sort((a, b) => {
      return new Date(a.creationTime) - new Date(b.creationTime);
    });
    setMessages(sortedMessage);
  };
  useEffect(() => {
    if (feedback?.chatID && isChat) {
      getChat();
      getChatMessages();
    } else if (feedback?.id && !isChat) {
      getStream();
    }
  }, [feedback?.chatID]);
  const onAutoTuneChanged = (v) => {
    setIsAutoTunedOn(v);
  };
  const onAdminResolutionChanged = (v) => {
    setAdminResolution(v);
  };
  const generateDocumentFromChatMessages = (_messages) => {
    const _title = chat?.name;
    const _document = [{ type: "title", children: [{ text: _title }] }];
    _messages.forEach((message) => {
      _document.push({
        type: "paragraph",
        children: [{ text: " " }],
      });
      _document.push({
        COMPLETION_TRAINED:
          message?.role == "assistant"
            ? message?.text?.trim()?.split("***")[0]
            : message?.text?.trim(),
        type: "editable-void",
        is_conversational: true,
        ROLE: message?.role,
        children: [{ text: "" }],
      });
    });
    _document.push({
      type: "paragraph",
      children: [{ text: " " }],
    });
    return _document;
  };
  const generateDocumentFromOutput = () => {
    const _title = "New Document";
    const _document = [{ type: "title", children: [{ text: _title }] }];
    _document.push({
      type: "paragraph",
      children: [{ text: " " }],
    });
    _document.push({
      COMPLETION_TRAINED: stream?.content?.trimLeft(),
      type: "editable-void",
      is_conversational: false,
      SYS_PROMPT: stream?.prompt,
      children: [{ text: "" }],
    });
    _document.push({
      type: "paragraph",
      children: [{ text: " " }],
    });
    return _document;
  };
  const handleCreationofDocument = async (values = {}) => {
    setIsCreationInProgress(true);
    await handleFeedbackUpdate({ id: feedback?.id, ...values }, false);
    let trainingDataPostResult;
    if (isChat) {
      let _messages = messages.slice(-50);
      if (isAutoTunedOn) {
        // return console.log("Auto tuning on", _messages, adminResolution);
        const __result = await Tuner.requestAutotuning({
          token: getActiveWorkspace()?.workspaceToken,
          workspaceid: getActiveWorkspace()?.id,
          initial_output: _messages?.map((u) => {
            return {
              role: u?.role,
              text: u?.text,
            };
          }),
          user_feedback: adminResolution,
        });
        if (__result?.status == 200) {
          _messages = __result?.data?.improved_output;
          for (let i = 0; i < _messages.length; i++) {
            _messages[i].text = _messages[i].content;
          }
        }
      }
      trainingDataPostResult = await createAPIRequest({
        data: [
          {
            workspaceid: getActiveWorkspace()?.id,
            document: JSON.stringify(
              generateDocumentFromChatMessages(_messages)
            ),
            rawtext: " ",
            trainingData: {},
            userID: user?.id,
            type: "conversationalTraining",
            status: "draft",
            scope: "training",
            topics: [],
            title: chat?.name,
          },
        ],
      });
    } else {
      trainingDataPostResult = await createAPIRequest({
        data: [
          {
            workspaceid: getActiveWorkspace()?.id,
            document: JSON.stringify(generateDocumentFromOutput()),
            rawtext: " ",
            trainingData: {},
            userID: user?.id,
            type: "generativeTraining",
            status: "draft",
            scope: "training",
            topics: [],
            title: chat?.name,
          },
        ],
      });
    }
    // console.log("trainingDataPostResult", trainingDataPostResult);
    AppToasty.show("Training document created", {
      placement: "top",
      type: "success",
    });
    setIsCreationInProgress(false);
    setTimeout(() => {
      history.push(
        `/workspaces/${getActiveWorkspace()?.id}/documents/${
          isChat ? "cn" : "gn"
        }/${trainingDataPostResult?.data?.[0]?.id}`
      );
    }, [1000]);
  };
  return isCreationInProgress || isFeedbackUpdateInProgress ? (
    <ToothFairyLoadingPlaceholder caption="Loading..." />
  ) : (
    <AppForms.AppForm
      initialValues={{
        adminResolution: feedback?.adminResolution || "",
        adminChecked: feedback?.adminChecked || false,
        autoTune: feedback?.autoTune || false,
      }}
      onSubmit={(v) => handleFeedbackUpdate({ ...v, id: feedback?.id })}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <View
        style={{
          paddingLeft: 23,
          paddingRight: 20,
          paddingTop: 20,
          rowGap: 10,
        }}
      >
        <AppText preventTranslation>
          <strong>ID:</strong> {feedback?.id}
        </AppText>
        <AppText preventTranslation>
          <strong>Username:</strong>{" "}
          {
            members?.find(
              (item) => item.id === feedback?.humanFeedbackProvidedBy
            )?.username
          }
        </AppText>
        <AppText preventTranslation>
          <strong>Feedback:</strong> {feedback?.humanFeedback}
        </AppText>
        <AppText preventTranslation>
          <strong>Type:</strong> {feedback?.humanFeedbackType}
        </AppText>
        <AppText preventTranslation>
          <strong>Sub Type:</strong> {subFeedbackTypeLabel || "None"}
        </AppText>
        <AppText preventTranslation>
          <strong>Comment:</strong> {feedback?.humanFeedbackComment || "None"}
        </AppText>
        {isChat ? (
          <AppText
            fontWeight="bold"
            preventTranslation
            isClickable
            color={currentTheme?.theme?.primary}
            onPress={() => {
              history.push(
                `/workspaces/${getActiveWorkspace()?.id}/chats/${
                  feedback?.chatID
                }`
              );
            }}
          >
            Go to chat
          </AppText>
        ) : (
          <View
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 10,
              paddingVertical: 10,
              borderTopWidth: 1,
              borderTopColor: currentTheme?.theme?.border_color,
              borderBottomWidth: 1,
              borderBottomColor: currentTheme?.theme?.border_color,
            }}
          >
            <AppText
              style={{
                fontWeight: "bold",
              }}
            >
              Prompt
            </AppText>
            <AppText>{stream?.prompt}</AppText>
            <AppText
              style={{
                fontWeight: "bold",
              }}
            >
              Output
            </AppText>
            <AppText>{stream?.content?.trimLeft()}</AppText>
          </View>
        )}
      </View>
      <CorefeedbackViewForm
        isCreationInProgress={isCreationInProgress}
        onAutoTuneChanged={onAutoTuneChanged}
        handleCreationofDocument={handleCreationofDocument}
        isChat={isChat}
        isFeedbackUpdateInProgress={isFeedbackUpdateInProgress}
        onAdminResolutionChanged={onAdminResolutionChanged}
      />
    </AppForms.AppForm>
  );
};

export default FeedbackView;
