import { DataStore } from "@aws-amplify/datastore";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { lastPathInWorkspace } from "../../../../App";
import workspacesHelper from "../../../API/Workspaces/workspacesHelper";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { useHistory } from "../../../Router";
import { EntityAI } from "../../../models";
import WorkspaceModalContent from "../../Workspaces/Components/WorkspaceModalContent";
import { formValidationSchema } from "../../Workspaces/WorkspacesScreenContainer";

const WorkspaceSettings = ({ readOnly }) => {
  const { t } = useTranslation();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { user } = AppUser.useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetPlan, setTargetPlan] = useState(false);
  const {
    getActiveWorkspace,
    generateBaseWorkspaceTrainingConfig,
    canUpdateSubscription,
  } = useWorkspaces();
  const [selectedWorkspace, setSelectedWorkspace] = useState();
  const [modalContentKey, setModalContentKey] = useState("UPDATE");
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(
    Constants.initialWorkspaceValues
  );

  const AppToasty = AppToast.useToast();
  const identifyWorkspaceByID = (id) => {
    return state.workspaces.map((u) => u.id).indexOf(id);
  };
  const handleValuesChange = (values) => {};
  useEffect(() => {
    if (state?.activeWorkspaceID && getActiveWorkspace()?.title)
      handleWorkspaceEdit(getActiveWorkspace());
  }, [state?.activeWorkspaceID, getActiveWorkspace()?.title]);

  const cancelPlanChange = async () => {
    const payload = [
      {
        property: "targetSubscriptionType",
        value: null,
      },
    ];
    const result = await workspacesHelper.updateWorkspace(
      selectedWorkspace.id,
      payload
    );
    state.workspaces[identifyWorkspaceByID(result.id)] = result;
    setInitialValues({
      ...initialValues,
      targetSubscriptionType: selectedWorkspace.subscriptionType,
      baseModel: selectedWorkspace?.baseModel?.toUpperCase() || "NONE",
      domain: selectedWorkspace?.domain?.toUpperCase() || "DEFAULT",
    });
    AppToasty.show(t("WorkspaceChangeOfPlanAbort"), { type: "success" });
    window.location.reload();
  };

  const handleWorkspaceEdit = (item) => {
    if (item) {
      setSelectedWorkspace(item);
      setInitialValues({
        workspaceID: item.id,
        workspaceName: item.title,
        workspaceType: item.type,
        workspaceMed: item.isMed ? true : false,
        subscriptionType: item.subscriptionType,
        targetSubscriptionType: item.targetSubscriptionType,
        maxUsers: item.maxUsers,
        baseModel: item?.baseModel?.toUpperCase() || "NONE",
        domain: item?.domain?.toUpperCase() || "DEFAULT",
      });
      setModalContentKey("UPDATE");
      setIsModalOpen(true);
    }
  };
  const handleWorkspaceDeletion = async () => {
    try {
      await workspacesHelper.deleteWorkspace(selectedWorkspace.id);
      setIsModalOpen(false);
      dispatch(
        "SET_WORKSPACES",
        state.workspaces.filter((u) => u.id !== selectedWorkspace.id)
      );
      lastPathInWorkspace(null);
      AppToasty.show(t("WorkspaceDeletionCaption"), { type: "success" });
    } catch (error) {
      AppToasty.show(t("WorkspaceDeletionFailedCaption"), { type: "danger" });
      console.error("Error while deleting the workspace", error);
    }
  };

  const handleFormSubmit = async (values) => {
    let result;
    try {
      if (modalContentKey === "NEW") {
        result = await workspacesHelper.createWorkspace({
          title: values.workspaceName,
          isMed: values.workspaceMed === "true",
          userID: user.id,
          visibility: "shared",
          type: values.workspaceType,
          users: [user.id],
          subscriptionType: values.subscriptionType,
          baseModel: !values.baseModel ? "none" : values.baseModel,
          trainingConfig: generateBaseWorkspaceTrainingConfig(
            values.subscriptionType
          ),
        });

        const resultAIEntityNER = await Promise.all(
          AppUtilities.baseEntities.map((ner) =>
            DataStore.save(
              new EntityAI({
                label: ner.label,
                backgroundColor: ner.backgroundColor,
                type: "ner",
                workspaceID: result.id,
                emoji: ner.icon,
              })
            )
          )
        );

        if (values.workspaceMed) {
          const medresultAIEntityNER = await Promise.all(
            AppUtilities.medEntities.map((ner) =>
              DataStore.save(
                new EntityAI({
                  label: ner.label,
                  backgroundColor: ner.backgroundColor,
                  type: "ner",
                  workspaceID: result.id,
                  emoji: ner.icon,
                })
              )
            )
          );
        }

        const resultAIEntityIntent = await Promise.all(
          Constants.generateBaseIntentsFromBaseModel(
            !values.baseModel ? "none" : values.baseModel
          )?.map((intent) =>
            DataStore.save(
              new EntityAI({
                label: intent.label,
                backgroundColor: intent.backgroundColor,
                type: "intent",
                workspaceID: result.id,
              })
            )
          )
        );

        setIsModalOpen(false);
        dispatch("SET_WORKSPACES", [...state.workspaces, result]);
        AppToasty.show(t("WorkspaceCreationCaption"), { type: "success" });
      } else if (modalContentKey === "UPDATE") {
        const payload = [
          {
            property: "title",
            value: values.workspaceName,
          },
          {
            property: "isMed",
            value: values.workspaceMed,
          },
          {
            property: "domain",
            value: !values.domain ? "default" : values.domain.toLowerCase(),
          },
          {
            property: canUpdateSubscription()
              ? "subscriptionType"
              : "targetSubscriptionType",
            value: values.subscriptionType,
          },
        ];
        let reloadRequired = false;
        if (
          values.subscriptionType !== getActiveWorkspace()?.subscriptionType
        ) {
          reloadRequired = true;
          payload.push({
            property: "lastChangeSubscriptionDate",
            value: new Date().toISOString(),
          });
        }

        result = await workspacesHelper.updateWorkspace(
          selectedWorkspace.id,
          payload
        );
        state.workspaces[identifyWorkspaceByID(result.id)] = result;
        setIsModalOpen(false);
        dispatch("SET_WORKSPACES", state.workspaces);
        AppToasty.show(t("WorkspaceUpdateCaption"), { type: "success" });
        if (reloadRequired) {
          window.location.reload();
        }
      }
    } catch (error) {
      AppToasty.show(t("WorkspaceUpdateFailedCaption"), { type: "danger" });
      console.error("Error while saving the workspace", error);
    }
  };
  return (
    <WorkspaceModalContent
      cancelPlanChange={cancelPlanChange}
      modalContentKey={modalContentKey}
      handleFormSubmit={handleFormSubmit}
      formValidationSchema={formValidationSchema}
      initialValues={initialValues}
      setModalContentKey={setModalContentKey}
      setIsModalOpen={setIsModalOpen}
      handleWorkspaceDeletion={handleWorkspaceDeletion}
      setTargetPlan={setTargetPlan}
      targetPlan={targetPlan}
      onValuesChange={handleValuesChange}
    />
  );
};

export default WorkspaceSettings;
