import API from "@aws-amplify/api";
import {
  createChat,
  createChatMessage,
  updateChatMessage,
} from "../../graphql/mutations";
import { messagesByChat, getChatMessage } from "../../graphql/queries";

const createChatMessage_ = async (object) => {
  return API.graphql({
    query: createChatMessage,
    variables: { input: object },
  });
};
const getChatMessage_ = async (id) => {
  return API.graphql({
    query: getChatMessage,
    variables: { id },
  });
};
const updateChatMessage_ = async (id, args) => {
  const original = await getChatMessage_(id);
  let _properties = {};
  args.forEach((item) => {
    _properties[item.property] = item.value;
  });
  return API.graphql({
    query: updateChatMessage,
    variables: {
      input: {
        id,
        _version: original?.data?.getChatMessage?._version,
        ..._properties,
      },
    },
  });
};

const getChatMessages_ = async (chatID) => {
  return API.graphql({
    query: messagesByChat,
    variables: { chatID, sortDirection: "DESC" },
  });
};

export default {
  createChatMessage: createChatMessage_,
  updateChatMessage: updateChatMessage_,
  getChatMessages: getChatMessages_,
};
