import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { Platform, StyleSheet } from "react-native";
import useSections from "../../Hooks/useSections";
import UserPreferencesNLP from "./Components/UserPreferencesNLP";
import ProfileSettingsContainer from "./Components/ProfileSettingsContainer";
import BillingDetailsContainer from "./Components/BillingDetailsContainer";
import Constants from "../../Constants";

const AccountScreen = ({ handleUserUpdate, setCurrentView, clearCache }) => {
  const { currentTheme } = AppTheme.useTheme();
  const { viewRenderer } = useSections();
  const styles = styleGenerator(currentTheme);

  const mapDisplayedViewWithComponent = (uid) => {
    switch (uid) {
      case "PROFILE_SETTINGS":
        return (
          <ProfileSettingsContainer
            setCurrentView={setCurrentView}
            handleUserUpdate={handleUserUpdate}
          />
        );
      case "NLP":
        return (
          <UserPreferencesNLP
            handleUserUpdate={handleUserUpdate}
            clearCache={clearCache}
            languagesData={Constants.availableLanguages}
          />
        );
      case "BILLING":
        return <BillingDetailsContainer handleUserUpdate={handleUserUpdate} />;

      default:
        return <></>;
    }
  };

  return viewRenderer(mapDisplayedViewWithComponent, styles);
};
const styleGenerator = (currentTheme) => {
  return StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 10,
      paddingHorizontal: 20,
    },
    icon: {
      borderRadius: 5,
      width: "auto",
      height: "auto",
    },
    modal: {
      borderRadius: 30,
      height: Platform.OS === "web" ? 500 : "95%",
      left: "auto",
      marginHorizontal: Platform.OS === "web" ? "auto" : "5%",
      marginVertical: "auto",
      top: "auto",
      width: Platform.OS === "web" ? 500 : "90%",
    },
    modalInnerContent: {
      paddingHorizontal: 20,
      paddingTop: "auto",
    },
    modalRightIcon: {
      position: "relative",
      top: "auto",
      right: "auto",
    },
    modalWrapper: {
      height: "auto",
    },
    title: {
      fontSize: currentTheme?.theme?.largest_font_size,
      fontWeight: "bold",
    },
  });
};

export default AccountScreen;
