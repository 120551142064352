import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React from "react";
import { Platform, View } from "react-native";
import AppSecureTextInput from "@toothfairy/shared-ui/AppSecureTextInput";
import { isLargeScreen } from "../../MainViewContainer";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../Constants";

const UserPreferencesNLP = ({
  handleUserUpdate,
  showReplyLanguage = true,
  languagesData,
  clearCache,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const AppToasty = AppToast.useToast();
  const { user } = AppUser.useUser();
  const { state } = AppStateManager.useAppStateManager();
  const [isUserInstructionsEditable, setIsUserInstructionsEditable] =
    React.useState(false);
  return (
    <View
      style={{
        width: Platform.OS === "web" && isLargeScreen() ? 450 : 250,
        marginTop: 20,
        alignSelf: "center",
      }}
    >
      {showReplyLanguage && state?.languagesConfig?.length > 0 && (
        <AppDropDown
          showTextInputLabel
          label="Output Language"
          inputLabelStyle={{
            marginLeft: 0,
          }}
          dropDownStyle={{
            minWidth: 100,
            borderColor: currentTheme?.theme?.border_color,
            maxWidth: !isLargeScreen() && 250,
          }}
          isElevated={false}
          dropdownIconColor="transparent"
          items={[
            { id: "def", name: "Default to input language" },
            ...state?.languagesConfig,
          ]}
          propertyLabel="name"
          propertyValue="id"
          selectedValue={user.replyLanguage}
          onValueChange={(itemValue, itemIndex) => {
            handleUserUpdate("replyLanguage", itemValue);
          }}
        />
      )}
      <AppDropDown
        showTextInputLabel
        label="App language"
        inputLabelStyle={{
          marginLeft: 0,
        }}
        enabled={false}
        isMandatory
        dropDownStyle={{
          borderColor: currentTheme?.theme?.border_color,
          maxWidth: !isLargeScreen() && 250,
        }}
        wrapperContainer={{
          paddingBottom: 20,
          paddingTop: 20,
        }}
        isElevated={false}
        dropdownIconColor="transparent"
        items={languagesData}
        propertyLabel="name"
        propertyValue="id"
        selectedValue={user.language}
        onValueChange={(itemValue, itemIndex) => {
          handleUserUpdate("language", itemValue);
        }}
      />
      <AppDropDown
        showTextInputLabel
        label="Timezone"
        isMandatory
        dropDownStyle={{
          borderColor: currentTheme?.theme?.border_color,
          maxWidth: !isLargeScreen() && 250,
        }}
        wrapperContainer={{
          paddingBottom: 15,
        }}
        inputLabelStyle={{
          marginLeft: 0,
        }}
        isElevated={false}
        dropdownIconColor="transparent"
        items={AppUtilities.timezoneData()}
        propertyLabel="name"
        propertyValue="id"
        selectedValue={user.timezone}
        onValueChange={(itemValue, itemIndex) => {
          handleUserUpdate("timezone", itemValue);
        }}
      />
      <AppSecureTextInput
        wrapperInputStyle={{ flex: 1, height: 300 }}
        maxCharacters={512}
        coreViewStyle={{
          gap: 5,
          alignItems: "flex-start",
        }}
        placeholder="Add personal instructions for the agents to know more about you and your preferences."
        actionsButtonsViewStyle={{
          paddingRight: 10,
          marginTop: isUserInstructionsEditable ? 15 : 0,
        }}
        textContainer={{
          height: 300,
          padding: 10,
        }}
        showTextInputLabel
        label="Personal instructions"
        multiline
        isEditable={isUserInstructionsEditable}
        onEditIconPress={() => setIsUserInstructionsEditable(true)}
        onCancelIconPress={() => setIsUserInstructionsEditable(false)}
        onSubmitIconPress={(v) => {
          if (v?.length > 512) {
            return AppToasty.show(
              "Instructions should not exceed 512 characters",
              { type: "danger" }
            );
          } else {
            setIsUserInstructionsEditable(false);
            handleUserUpdate("userInstructions", v);
          }
        }}
        initialValue={user?.userInstructions || "No instructions added yet."}
      />
      <ToothFairyButton
        title={"Clear cache"}
        onPress={() => clearCache()}
        isDeletion
        wrapperStyle={{
          marginTop: 30,
          minWidth: 150,
          height: Constants.appButtonHeight,
          alignSelf: "center",
        }}
      />
    </View>
  );
};

export default UserPreferencesNLP;
