import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import dayjs from "dayjs";
import React from "react";
import { View } from "react-native";
import Charting from "../../../Components/Charting";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import DashboardItem from "./DashboardItem";
import DashboardPlaceholder from "./DashboardPlaceholder";

const CostingView = ({
  consumptionAPIInProgress,
  costAPIInProgress,
  datasets,
  selectedDate,
  changeMonthByUnit,
  totalPredictionCost,
  totalTrainingCost,
  costChart,
  normalisedConsumption,
  normalisedTrainingConsumption,
}) => {
  const isCurrentMonth = dayjs().month() === selectedDate.month();
  const { currentTheme } = AppTheme.useTheme();
  const { getActiveWorkspace, isWorkspaceOnDemand, getPlanConfig } =
    useWorkspaces();
  const isWorkspaceBaseConsumption = isWorkspaceOnDemand();
  const isParseCost = datasets?.length > 0;
  function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
  }
  const _mappedLabel = (label) => {
    switch (label) {
      case "parser":
        return "Sentiment Reviewer";
      case "answerer":
        return "Business Analyst";
      case "generator":
        return "Content Creator";
      case "chatter":
        return "Chat Agent";
      default:
        return `Utility - ${label}`;
    }
  };
  const displayedConsumptions = ["parser", "answerer", "generator", "chatter"];
  return (
    <View>
      <AppText
        wrapperStyle={{
          width: "fit-content",
          alignSelf: "flex-end",
          paddingTop: 10,
          zIndex: 10,
        }}
        leftIcon={AppAssets.icons.navigationBack}
        rightIcon={AppAssets.icons.navigationTo}
        onRightIconPress={() => {
          if (!isCurrentMonth) changeMonthByUnit(1);
        }}
        rightIconColor={
          isCurrentMonth
            ? currentTheme?.theme?.grey
            : currentTheme?.theme?.primary
        }
        onLeftIconPress={() => {
          changeMonthByUnit(-1);
        }}
      >
        {selectedDate?.format("MMM YY")}
      </AppText>
      <DashboardItem
        isFetchInProgress={consumptionAPIInProgress}
        title="predictionCostingCaption"
        isHalfWidth={false}
      >
        {isParseCost && normalisedConsumption?.datasets?.length > 0 ? (
          <Charting.AppBarChart
            maxHeight={Constants.chartMaxHeight}
            options={{
              legend: {
                position: "bottom",
                display: true,
                labels: {
                  color: currentTheme?.theme?.font_color,
                  font: "normalFont",
                },
              },
              scales: {
                x: {
                  stacked: true,
                  grid: {
                    display: false,
                  },
                  ticks: {
                    color: currentTheme?.theme?.font_color,
                  },
                },
                y: {
                  type: "logarithmic",
                  stacked: true,
                  display: true,
                  position: "left",
                  ticks: {
                    color: currentTheme?.theme?.font_color,
                  },
                },
              },
            }}
            dataset={{
              labels: normalisedConsumption?.labels,
              datasets: [
                ...normalisedConsumption?.datasets
                  ?.map((u) => {
                    {
                      if (displayedConsumptions?.includes(u.label))
                        return {
                          label: _mappedLabel(u.label),
                          data: u.data,
                          borderColor: stringToColor(u.label),
                          borderRadius: 10,
                          backgroundColor: stringToColor(u.label),
                          yAxisID: "y",
                        };
                    }
                  })
                  ?.filter((u) => u),
              ],
            }}
          />
        ) : (
          <DashboardPlaceholder
            mainCaption="emptyPlaceholder"
            subCaption="Run your first request in the current month to start tracking consumption"
          />
        )}
      </DashboardItem>
      {(getPlanConfig()?.isTrainingNLPEnabled ||
        getPlanConfig()?.isTrainingQAEnabled ||
        getPlanConfig()?.isTrainingGNEnabled) && (
        <DashboardItem
          isFetchInProgress={consumptionAPIInProgress}
          title="trainingCostingCaption"
          isHalfWidth={false}
        >
          {isParseCost &&
          normalisedTrainingConsumption?.datasets?.length > 0 ? (
            <Charting.AppBarChart
              maxHeight={Constants.chartMaxHeight}
              options={{
                legend: {
                  position: "bottom",
                  display: true,
                  labels: {
                    color: currentTheme?.theme?.font_color,
                    font: "normalFont",
                  },
                },
                scales: {
                  x: {
                    stacked: true,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      color: currentTheme?.theme?.font_color,
                    },
                  },
                  y: {
                    type: "logarithmic",
                    stacked: true,
                    display: true,
                    position: "left",
                    ticks: {
                      color: currentTheme?.theme?.font_color,
                    },
                  },
                },
              }}
              dataset={{
                labels: normalisedTrainingConsumption?.labels,
                datasets: [
                  ...normalisedTrainingConsumption?.datasets?.map((u) => {
                    return {
                      label: u.label,
                      data: u.data,
                      borderColor: stringToColor(u.label),
                      borderRadius: 10,
                      backgroundColor: stringToColor(u.label),
                      yAxisID: "y",
                    };
                  }),
                ],
              }}
            />
          ) : (
            <DashboardPlaceholder
              mainCaption="emptyPlaceholder"
              subCaption="Run your first training job in the current month to start tracking consumption"
            />
          )}
        </DashboardItem>
      )}

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginVertical: 20,
        }}
      >
        {isWorkspaceBaseConsumption && Constants.showCost && (
          <>
            <DashboardItem
              isFetchInProgress={costAPIInProgress}
              title="costingPredictionEstimationCaption"
              isHalfWidth={true}
              additionalTitle={` ${totalPredictionCost}$`}
            >
              {costChart?._parsingCost &&
              costChart?._parsingCost?.reduce((a, b) => a + b, 0) > 0 ? (
                <Charting.AppDoughnut
                  maxHeight={Constants.chartMaxHeight}
                  dataset={{
                    labels: costChart?._parsingLabels,
                    datasets: [
                      {
                        label: "Sentences",
                        data: costChart?._parsingCost,
                        backgroundColor: costChart?._parsingLabels.map((u) =>
                          stringToColor(u)
                        ),
                      },
                    ],
                  }}
                />
              ) : (
                <DashboardPlaceholder
                  mainCaption="Nothing to show here yet..."
                  subCaption="Run your first request to start tracking consumption"
                />
              )}
            </DashboardItem>
            {(getPlanConfig()?.isTrainingNLPEnabled ||
              getPlanConfig()?.isTrainingQAEnabled ||
              getPlanConfig()?.isTrainingGNEnabled) && (
              <DashboardItem
                isFetchInProgress={costAPIInProgress}
                title="costingTrainingEstimationCaption"
                isHalfWidth={true}
                additionalTitle={` ${totalTrainingCost}$`}
              >
                {costChart?._trainingCost &&
                costChart?._trainingCost?.reduce((a, b) => a + b, 0) > 0 ? (
                  <Charting.AppDoughnut
                    maxHeight={Constants.chartMaxHeight}
                    dataset={{
                      labels: costChart?._trainingLabels,
                      datasets: [
                        {
                          label: "Training",
                          data: costChart?._trainingCost,
                          backgroundColor: costChart?._trainingLabels.map((u) =>
                            stringToColor(u)
                          ),
                        },
                      ],
                    }}
                  />
                ) : (
                  <DashboardPlaceholder
                    mainCaption="Nothing to show here yet..."
                    subCaption="Run your first training to start tracking consumption"
                  />
                )}
              </DashboardItem>
            )}
          </>
        )}
      </View>
    </View>
  );
};

export default CostingView;
