import { DataStore } from "@aws-amplify/datastore";
import { CustomPrompt } from "../../models";

const createPrompt = async (object) => {
  return DataStore.save(new CustomPrompt(object));
};

const updatePrompt = async (id, args) => {
  const original = await DataStore.query(CustomPrompt, id);
  return DataStore.save(
    CustomPrompt.copyOf(original, (updated) => {
      args.forEach((arg) => {
        updated[arg.property] = arg.value;
      });
    })
  );
};

const deletePrompt = async (id) => {
  return DataStore.delete(CustomPrompt, (ent) => ent.id("eq", id));
};

export default {
  createPrompt,
  updatePrompt,
  deletePrompt,
};
