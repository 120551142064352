import { AppText } from "@toothfairy/shared-ui";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import React from "react";
import { Platform, View } from "react-native";
import ToothFairySupportButton from "../../../Components/ToothFairyComponents/ToothFairySupportButton";
import Constants from "../../../Constants";

const DocumentsImportModalContent = ({
  handleChangeOfImportFile,
  handleChangeOfImportType,
  handleFormSubmit = () => {},
  formValidationSchema,
  initialFormValues,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { supportUrl } = Constants;
  return (
    <View style={{ justifyContent: "center" }}>
      <AppForms.AppForm
        enableReinitialize
        style={{
          minWidth: Platform.OS === "web" ? 300 : 250,
          alignSelf: "center",
          minHeight: 250,
          justifyContent: "space-between",
          paddingTop: 30,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={formValidationSchema}
        initialValues={initialFormValues}
      >
        <AppForms.AppFormDropDown
          enabled={true}
          onSelectionChange={(item) => handleChangeOfImportType(item)}
          formOptionValue={"id"}
          propertyLabel="title"
          propertyValue="id"
          name={"importType"}
          label="Data type"
          showTextInputLabel
          items={[
            {
              id: "TOPIC",
              title: "Generative AI",
            },
            {
              id: "CONV",
              title: "Conversational AI",
            },
            {
              id: "INTENT",
              title: "Intent detection",
            },
            {
              id: "NER",
              title: "NER",
            },
          ]}
          optionsWidth={"40%"}
          height={Platform.OS === "web" ? "auto" : 66}
        />
        {/* <AppForms.AppFormRadio
          enabled={false}
          formOptionValue={"id"}
          propertyLabel="title"
          propertyValue="id"
          name={"fileType"}
          label="File type"
          showTextInputLabel
          onSelectionChange={(item) => handleChangeOfImportFile(item)}
          items={[
            {
              id: "TXT",
              title: "TXT",
            },
            {
              id: "JSON",
              title: "JSON",
              disabled: true,
            },
            {
              id: "CSV",
              title: "CSV",
              disabled: true,
            },
          ]}
          optionsWidth={"40%"}
          height={Platform.OS === "web" ? "auto" : 66}
        /> */}
        <AppForms.AppSubmitButton
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              height: Constants.appButtonHeight,
              alignSelf: "center",
            },
          ]}
          title="Import"
          isEnabledOnFilled={true}
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <AppText
            color={currentTheme?.theme?.primary}
            style={{
              fontWeight: "bold",
              marginLeft: 10,
            }}
            leftIcon={AppAssets?.icons?.info}
            iconSize={18}
            isClickable
            onPress={() => {
              // open a new tab with the support url
              window.open(supportUrl, "_blank");
            }}
          >
            importWizardCaption
          </AppText>
          <ToothFairySupportButton _url={supportUrl} />
        </View>
      </AppForms.AppForm>
    </View>
  );
};

export default DocumentsImportModalContent;
