import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import AdminInfoLeaf from "../../../Admin/Components/SubComponents/AdminInfoLeaf";
import { isLargeScreen } from "../../../MainViewContainer";
import Utility from "../../../../Utility";
import Color from "../../../../Utility/Color";

const GenerationSettingsAIView = ({
  isEdit,
  settingType,
  handleDeletion,
  selectedId,
  customPrompt,
  handleForking = () => {},
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const entityName = "Prompting";

  const { state } = AppStateManager.useAppStateManager();
  const { getActiveWorkspace } = useWorkspaces();
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  const { setFieldValue, values } = AppForms.useFormikContext();
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.border_color,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
      borderRadius: 20,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let color = new Color(data?.backgroundColor || currentTheme?.theme?.grey);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? currentTheme?.theme?.white
            ? "white"
            : "black"
          : data?.backgroundColor || currentTheme?.theme?.font_color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data?.backgroundColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      let color = new Color(currentTheme?.theme?.primary);
      return {
        ...styles,
        backgroundColor: currentTheme?.theme?.primary_medium,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor || currentTheme?.theme?.font_color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: currentTheme?.theme?.white,
      ":hover": {
        backgroundColor: data?.backgroundColor,
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.lightGray,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
    }),
  };
  return (
    <>
      <>
        <View>
          {isEdit && (
            <>
              <AdminInfoLeaf
                textContainer={{
                  marginLeft: 5,
                  marginTop: 10,
                  marginBottom: 0,
                }}
                titleStyle={{
                  marginTop: 0,
                  marginLeft: 20,
                }}
                textStyle={{
                  marginLeft: 0,
                }}
                wrapperStyle={{
                  alignItems: "flex-start",
                  marginBottom: 20,
                }}
                title={"ID"}
                value={selectedId}
                width={420}
              />
              <AdminInfoLeaf
                textContainer={{
                  marginLeft: 5,
                  marginTop: 10,
                  marginBottom: 0,
                }}
                titleStyle={{
                  marginTop: 0,
                  marginLeft: 20,
                }}
                textStyle={{
                  marginLeft: 0,
                }}
                wrapperStyle={{
                  alignItems: "flex-start",
                  marginBottom: 20,
                }}
                title={"Prompt key"}
                value={customPrompt?.scope}
                width={420}
              />
            </>
          )}

          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Name`}
            placeholder={`Prompt name`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="label"
          />
        </View>

        <View>
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            textContainer={{
              minHeight: 245,
              paddingVertical: 10,
            }}
            multiline={true}
            label={`Prompt text`}
            placeholder={`Interpolation_Prompt_placeholder`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="interpolationString"
          />
        </View>
        <AppForms.AppFormMultiSelect
          name="availableToAgents"
          placeholder="Assign agents to prompt"
          hidePlaceholder={true}
          items={state?.agents}
          uniqueKey="id"
          showDropDowns={true}
          readOnlyHeadings={true}
          labelOption="label"
          groupByProperty="agentType"
          style={{ maxWidth: 800 }}
          wrapperContainerStyle={{
            flex: !isLargeScreen() ? 1 : 0.5,
            zIndex: 20,
            paddingTop: 10,
            marginBottom: 30,
          }}
          styles={colourStyles}
          options={state?.agents?.map((u) => {
            return {
              ...u,
              label: u?.label + " - " + u?.agentType,
              value: u?.id,
              backgroundColor: Utility?.mapAgentTypeToColour(u, currentTheme),
            };
          })}
        />
      </>
      <View style={{ alignItems: "center" }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title="Duplicate"
            onPress={() => {
              handleForking(values, selectedId);
            }}
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default GenerationSettingsAIView;
