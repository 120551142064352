import useAPI from "@toothfairy/shared-api/useApi";
import AppUser from "@toothfairy/shared-ui/AppUser";
import AppDateUtility from "@toothfairy/shared-ui/AppDateUtility";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import API_Consumption from "../../API/API_Consumption";
import trainingAPI from "../../API/Training/trainingAPI";
import usePath from "../../Hooks/usePath";
import useWorkspaces from "../../Hooks/useWorkspaces";
import DashboardScreen from "./DashboardScreen";
import { RequestType, TrainingLogType } from "../../models";

const DashboardScreenContainer = () => {
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState();
  const { params } = usePath();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { user } = AppUser.useUser();
  const {
    getLastWorkspaceModelMeta,
    getLastWorkspaceReadingComprehensionMeta,
    isNLPModelTrained,
    isRCModelTrained,
    isGNModelTrained,
    getActiveWorkspace,
    getActiveTrainingLog,
    isUserAdmin,
  } = useWorkspaces();
  const isAdmin = isUserAdmin();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const startDate = `${selectedDate.get("year")}-${selectedDate.format(
    "MM"
  )}-01`;
  const endDate = `${selectedDate.get("year")}-${selectedDate.format(
    "MM"
  )}-${selectedDate.daysInMonth()}`;

  const [nersBreakDown, setNersBreakDown] = useState();
  const [intentsBreakDown, setIntentsBreakDown] = useState();
  const [topicsBreakDown, setTopicsBreakDown] = useState();
  const [documentsBreakdown, setDocumentsBreakdown] = useState([0, 0]);

  const {
    data: costData,
    error: costAPIError,
    loading: costAPIInProgress,
    apiRequest: fetchCost,
    lastUpdate: lastcostDataUpdate,
  } = useAPI(API_Consumption.getCosts);

  const {
    data: trainingData,
    error: trainingAPIError,
    loading: trainingAPIInProgress,
    apiRequest: fetchTraining,
    lastUpdate: lastTrainingDataUpdate,
    optimisticDataUpdate,
  } = useAPI(trainingAPI.getDocumentsByWorkspace);
  const [normalisedConsumption, setNormalisedConsumption] = useState();
  const [normalisedTrainingConsumption, setNormalisedTrainingConsumption] =
    useState();
  const [totalTrainingCost, setTotalTrainingCost] = useState(0);
  const [totalPredictionCost, setTotalPredictionCost] = useState(0);
  useEffect(() => {
    if (costData?.apiUsage) {
      const normalisedData = costData?.apiUsage?.perDay;

      const normalisedArray = [];
      if (normalisedData?.length > 0)
        for (let i = 0; i < normalisedData.length; i++) {
          const couple = normalisedData[i];

          normalisedArray.push({
            date: dayjs(couple.date).format("D"),
            consumption: couple.requests,
          });
        }
      setLabels(
        AppDateUtility.getDaysInMonth(
          selectedDate.get("month") + 1,
          selectedDate.get("year")
        )
      );

      setDatasets(normalisedArray?.map((u) => u.consumption));
    }
  }, [lastcostDataUpdate, state?.activeWorkspaceID]);

  const fetchNers = () => {
    if (getActiveTrainingLog("nlp")?.trainingMeta?.meta?.ners) {
      const data = getActiveTrainingLog("nlp")?.trainingMeta?.meta?.ners;
      const enrichedNerData = [];
      for (let i = 0; i < data?.length; i++) {
        const ner = data[i];
        const matchingNER = state?.aiEntitiesConfig?.find(
          (u) => u.label === ner.name
        );
        const _color =
          matchingNER?.backgroundColor || AppUtilities.randomRGBA();
        enrichedNerData.push({
          ...ner,
          color: _color,
          legendFontColor: _color,
          legendFontFamily: "normalFont",
        });
        ner.color = _color;
        ner.legendFontColor = _color;
      }
      setNersBreakDown(enrichedNerData);
    }
  };
  const fetchIntents = () => {
    if (getActiveTrainingLog("nlp")?.trainingMeta?.meta?.intents) {
      const data = getActiveTrainingLog("nlp")?.trainingMeta?.meta?.intents;
      const enrichedIntentsData = [];
      for (let i = 0; i < data?.length; i++) {
        const ner = data[i];
        const matchingNER = state?.aiEntitiesConfig?.find(
          (u) => u.label === ner.name
        );
        const _color =
          matchingNER?.backgroundColor || AppUtilities.randomRGBA();
        enrichedIntentsData.push({
          ...ner,
          color: _color,
          legendFontColor: _color,
          legendFontFamily: "normalFont",
        });
        ner.color = _color;
        ner.legendFontColor = _color;
      }
      setIntentsBreakDown(enrichedIntentsData);
    }
  };
  const fetchTopics = async (aiEntitiesConfig) => {
    const result = await fetchTraining({
      workspaceID: getActiveWorkspace()?.id,
      countOnly: false,
      fields: ["topics"],
    });
    if (result) {
      const raw_topics = result?.data?.map((u) => u?.topics)?.flat();
      // from the array above generate a new array with the count of each topic and the id
      const topicsCount = raw_topics?.reduce((acc, curr) => {
        if (acc[curr]) {
          acc[curr] += 1;
        } else {
          acc[curr] = 1;
        }
        return acc;
      }, {});
      const topics = Object.keys(topicsCount).map((u) => ({
        id: u,
        count: topicsCount[u],
      }));
      const enrichedTopicsData = [];
      for (let i = 0; i < topics?.length; i++) {
        const topic = topics[i];
        const matchingTopic = aiEntitiesConfig?.find((u) => u.id === topic?.id);
        if (!matchingTopic) continue;
        const _color =
          matchingTopic?.backgroundColor || AppUtilities.randomRGBA();
        enrichedTopicsData.push({
          name: matchingTopic?.label || "Undefined",
          color: _color,
          legendFontColor: _color,
          legendFontFamily: "normalFont",
          count: topic.count,
        });
        topic.color = _color;
        topic.legendFontColor = _color;
      }
      setTopicsBreakDown(enrichedTopicsData);
    }
  };
  const fetchDocumentsByType = async (
    isNLPModelTrained,
    isRCModelTrained,
    isGNModelTrained
  ) => {
    const result = await Promise.all([
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "nlpTraining",
        pageLimit: 5000,
      }),
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "readComprehensionTraining",
        pageLimit: 5000,
      }),
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "readComprehensionContent",
        pageLimit: 5000,
      }),
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "generativeTraining",
        pageLimit: 5000,
      }),
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "questionAnswering",
      }),
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "readComprehensionPdf",
      }),
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "readComprehensionFile",
      }),
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "readComprehensionUrl",
        pageLimit: 5000,
      }),
      fetchTraining({
        workspaceID: state.activeWorkspaceID,
        countOnly: true,
        type: "conversationalTraining",
        pageLimit: 5000,
      }),
    ]);

    setDocumentsBreakdown([
      isNLPModelTrained ? result[0].totalResults : 0,
      isRCModelTrained ? result[1].totalResults : 0,
      result[2].totalResults,
      isGNModelTrained ? result[3].totalResults : 0,
      result[4].totalResults,
      result[5].totalResults,
      result[6].totalResults,
      result[7].totalResults,
      result[8].totalResults,
    ]);
  };
  useEffect(() => {
    if (getActiveWorkspace()?.workspaceTokenID) {
      fetchCost({
        workspaceId: getActiveWorkspace()?.id,
        month: selectedDate?.get("month") + 1,
        year: selectedDate?.get("year"),
      });
    }
  }, [
    JSON.stringify(selectedDate),
    state?.activeWorkspaceID,
    getActiveWorkspace()?.workspaceTokenID,
  ]);

  useEffect(() => {
    if (state?.activeWorkspaceID)
      fetchDocumentsByType(
        isNLPModelTrained(),
        isRCModelTrained(),
        isGNModelTrained()
      );
  }, [
    JSON.stringify(selectedDate),
    state?.activeWorkspaceID,
    isNLPModelTrained(),
    isRCModelTrained(),
  ]);

  useEffect(() => {
    if (state?.activeWorkspaceID && isNLPModelTrained()) {
      fetchNers();
      fetchIntents();
    }
  }, [
    state?.activeWorkspaceID,
    isNLPModelTrained(),
    getActiveTrainingLog("nlp")?.trainingMeta,
    selectedDate,
  ]);

  useEffect(() => {
    if (
      getActiveWorkspace()?.id &&
      state?.aiEntitiesConfig?.length > 0 &&
      documentsBreakdown?.length > 0
    ) {
      fetchTopics([...state?.aiEntitiesConfig]);
    }
  }, [
    getActiveWorkspace()?.id,
    state?.aiEntitiesConfig?.length,
    documentsBreakdown?.length,
  ]);

  const coreTrainingCost = costData?.trainingUsage?.trainingOverview;
  let _parsingCost = [];
  let _parsingLabels = [];
  let _trainingCost = [];
  let _trainingLabels = [];
  if (costData?.apiUsage?.costsOverview) {
    _parsingCost = Object.values(costData?.apiUsage?.costsOverview).map(
      (u) => u?.charge || 0
    );
    _parsingLabels = Object.keys(costData?.apiUsage?.costsOverview);
  }
  if (coreTrainingCost) {
    _trainingCost = Object.values(coreTrainingCost).map((u) => u?.charge || 0);
    _trainingLabels = Object.keys(coreTrainingCost);
  }
  const costChart = {
    _parsingLabels,
    _trainingLabels,
    _parsingCost,
    _trainingCost,
  };
  useEffect(() => {
    if (costData?.apiUsage?.perDayAndType) {
      setNormalisedConsumption(
        normalizeData(costData?.apiUsage?.perDayAndType)
      );
      let totalCost = Object.values(costData?.apiUsage?.costsOverview)?.reduce(
        (acc, curr) => acc + curr?.charge,
        0
      );
      totalCost = parseFloat(totalCost.toFixed(2));
      setTotalPredictionCost(totalCost);
    }
    if (costData?.trainingUsage?.perDayAndType) {
      setNormalisedTrainingConsumption(
        normalizeTrainingData(costData?.trainingUsage?.perDayAndType)
      );
      let totalCostForTraining = Object.values(
        costData?.trainingUsage?.trainingOverview
      )?.reduce((acc, curr) => acc + curr?.charge, 0);
      totalCostForTraining = parseFloat(totalCostForTraining.toFixed(2));
      setTotalTrainingCost(totalCostForTraining);
    }
  }, [costData?.apiUsage?.perDayAndType]);

  function normalizeData(inputData) {
    const keys = Object.keys(RequestType).map((u) => u.toLowerCase());

    // Initialize an empty object for each key
    const dataByKeys = keys.reduce((acc, key) => ({ ...acc, [key]: [] }), {});

    // Also store dates in order
    const dates = Object.keys(inputData).sort();

    // Go through each date
    dates.forEach((date) => {
      // And each key
      keys.forEach((key) => {
        const keyData = inputData[date][key];

        // If data exists and is an array (even if empty)
        if (Array.isArray(keyData)) {
          // Sum up the "words" values and add to the data for this key
          const sumSentences = keyData.reduce(
            (sum, { sentences }) => sum + (sentences || 0),
            0
          );
          dataByKeys[key].push(sumSentences);
        } else {
          // If data doesn't exist for this key on this date, just add zero
          dataByKeys[key].push(0);
        }
      });
    });

    // Convert the collected data into a format suitable for react-chartjs-2
    const datasets = keys.map((key) => ({
      label: key,
      data: dataByKeys[key],
      // Here you could customize the colors, etc. for each key
    }));
    const formattedLabels = dates.map((date) => {
      const [year, month, day] = date.split("-");
      return `${day}`;
    });
    return {
      labels: formattedLabels,
      datasets: datasets,
    };
  }

  function normalizeTrainingData(inputData) {
    const keys = ["Sentiment reviewer", "Reasoning", "Content Creator"];

    // Initialize an empty object for each key
    const dataByKeys = keys.reduce((acc, key) => ({ ...acc, [key]: [] }), {});

    // Also store dates in order (removing the time part)
    const dateData = {};
    Object.keys(inputData).forEach((dateStr) => {
      const dateOnlyStr = dateStr.split("T")[0];
      if (!dateData[dateOnlyStr]) {
        dateData[dateOnlyStr] = {};
      }
      Object.assign(dateData[dateOnlyStr], inputData[dateStr]);
    });

    const dates = Object.keys(dateData).sort();

    // Go through each date
    dates.forEach((date) => {
      // And each key
      keys.forEach((key) => {
        const keyData = dateData[date][key];

        // If data exists for this key on this date, add the "duration"
        if (keyData && typeof keyData.duration === "number") {
          dataByKeys[key].push(keyData.duration / 60);
        } else {
          // If data doesn't exist for this key on this date, just add zero
          dataByKeys[key].push(0);
        }
      });
    });

    // Convert the collected data into a format suitable for react-chartjs-2
    const datasets = keys.map((key) => ({
      label: key,
      data: dataByKeys[key],
      // Here you could customize the colors, etc. for each key
    }));
    const formattedLabels = dates.map((date) => {
      const [year, month, day] = date.split("-");
      return `${day}`;
    });

    return {
      labels: formattedLabels,
      datasets: datasets,
    };
  }

  return (
    <DashboardScreen
      isAdmin={isAdmin}
      initialTabFromRouting={params.sectionId}
      changeMonthByUnit={(int) =>
        setSelectedDate(selectedDate.add(int, "month"))
      }
      intentsCount={getActiveTrainingLog("nlp")?.trainingMeta?.totalntents}
      nersCount={getActiveTrainingLog("nlp")?.trainingMeta?.totalNers}
      normalisedConsumption={normalisedConsumption}
      normalisedTrainingConsumption={normalisedTrainingConsumption}
      // CHECK IF THIS IS CORRECT
      trainingDataCount={
        getActiveTrainingLog("nlp")?.trainingMeta?.intents &&
        JSON.parse(getActiveTrainingLog("nlp")?.trainingMeta?.intents)?.length >
          0
          ? JSON.parse(
              getActiveTrainingLog("nlp")?.trainingMeta?.intents
            )?.reduce((a, b) => ({
              count: a.count + b.count,
            }))?.count
          : 0
      }
      costData={costData}
      parsingCost={_parsingCost}
      trainingCost={_trainingCost}
      parsingLabels={_parsingLabels}
      costChart={costChart}
      trainingDuration={0}
      batchConsumption={costData?.batchUsage?.daily?.map(
        (u) => u?.parsedSentencesCount
      )}
      lastTrainingDate={AppDateUtility.ConvertDateToDateStringFormat(
        getActiveTrainingLog("nlp")?.trainingMeta?.lastTraining,
        "D MMM YY"
      )}
      intentsBreakDown={intentsBreakDown}
      nersBreakDown={nersBreakDown}
      topicsBreakDown={topicsBreakDown}
      consumptionAPIInProgress={costAPIInProgress}
      costAPIInProgress={costAPIInProgress}
      selectedDate={selectedDate}
      labels={labels}
      datasets={datasets}
      documentsBreakdown={documentsBreakdown}
      trainingAPIInProgress={trainingAPIInProgress}
      totalPredictionCost={totalPredictionCost}
      totalTrainingCost={totalTrainingCost}
    />
  );
};

export default DashboardScreenContainer;
