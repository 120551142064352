import AppTable from "@toothfairy/shared-ui/AppTable";
import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";
import LogsFilter from "../SubComponents/LogsFilter";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";

const LogsView = ({
  logs,
  isLogsFetchingInProgress,
  startDate,
  endDate,
  onUpdateStartDate,
  onUpdateEndDate,
  triggerFetchLogs,
  logTypes,
  fetchingLogTypes,
  selectedServiceType,
  selectedFilterLimit,
  setSelectedFilterLimit,
  setSelectedServiceType,
  isLoadMoreAvailable,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const columns = [
    {
      title: "Datetime",
    },
    {
      title: "Log",
    },
  ];
  return (
    <View style={{ minWidth: "100%", paddingLeft: 10 }}>
      <LogsFilter
        fetchingLogTypes={fetchingLogTypes}
        logTypes={logTypes}
        triggerFetchLogs={triggerFetchLogs}
        startDate={startDate}
        endDate={endDate}
        onUpdateStartDate={onUpdateStartDate}
        onUpdateEndDate={onUpdateEndDate}
        selectedServiceType={selectedServiceType}
        selectedFilterLimit={selectedFilterLimit}
        setSelectedFilterLimit={setSelectedFilterLimit}
        setSelectedServiceType={setSelectedServiceType}
      />
      {isLogsFetchingInProgress ? (
        <AppActivityIndicator
          backgroundColor={currentTheme?.theme?.softBackgroundColor}
          visible={true}
          containerStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
            minHeight: 250,
          }}
        />
      ) : (
        <>
          <AppTable style={{ zIndex: -1 }} headers={columns} items={logs} />
          <ToothFairyButton
            title={"Load more"}
            isDisabled={!isLoadMoreAvailable || isLogsFetchingInProgress}
            onPress={() => triggerFetchLogs(true)}
            wrapperStyle={{
              alignSelf: "center",
              paddingVertical: 10,
            }}
          />
        </>
      )}
    </View>
  );
};

export default LogsView;
