const horizontalBarOptions = (currentTheme) => {
  return {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Horizontal Bar Chart",
      },
      legend: {
        labels: {
          color: currentTheme?.theme?.font_color,
        },
      },
    },
  };
};

const verticalBarOptions = (currentTheme) => {
  return {
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Horizontal Bar Chart",
      },
      legend: {
        labels: {
          color: currentTheme?.theme?.font_color,
        },
      },
    },
  };
};

export default {
  horizontalBarOptions,
  verticalBarOptions,
};
