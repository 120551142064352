import React from "react";
import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const PageTitle = ({
  children,
  fontSize,
  style,
  marginRight,
  accessibilityLabel,
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <></>
    // <AppText
    //   {...props}
    //   accessibilityLabel={accessibilityLabel || "screenTitle"}
    //   wrapperStyle={{
    //     marginBottom: 0,
    //     marginTop: 0,
    //     marginRight: marginRight,
    //     width: "auto",
    //   }}
    //   style={[
    //     style,
    //     {
    //       fontSize: fontSize || currentTheme?.theme?.largest_font_size,
    //       fontWeight: "bold",
    //     },
    //   ]}
    //   // size={currentTheme?.theme?.medium_font_size}
    // >
    //   {children}
    // </AppText>
  );
};

export default PageTitle;
