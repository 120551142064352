import React from "react";
import { View } from "react-native";
import { Popable } from "react-native-popable";
import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import WorkspaceChip from "../../Workspaces/Components/WorkspaceChip";
import useWorkspaces from "../../../Hooks/useWorkspaces";
// ners is an array of objects with the following structure:
// {
//   "type": "string",
//   "value": "string"
// }
const NerViewComponent = ({
  chunk_id,
  ners = [],
  maxNerByChunk = 10,
  showHover = true,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { getWorkspaceEntities } = useWorkspaces();
  const mapNERWithIcon = (ner) => {
    return `${
      getWorkspaceEntities("ner")?.find(
        (e) => e?.label?.toLowerCase() === ner?.toLowerCase()
      )?.emoji || "📋"
    } ${ner} -`;
  };
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 5,
        flexWrap: "wrap",
      }}
    >
      {ners &&
        ners?.length > 0 &&
        ners?.slice(0, maxNerByChunk).map((ner, i) => {
          return (
            <Popable
              visible={showHover}
              numberOfLines={2}
              backgroundColor={currentTheme?.theme?.tooltipBackgroundColor}
              key={`${chunk_id}_pop_${i}`}
              style={[
                {
                  top: 30,
                  zIndex: 20,
                  maxWidth: 300,
                },
              ]}
              content={ner?.type}
              action={showHover ? "hover" : "xyz"}
              position="bottom"
            >
              <WorkspaceChip
                key={`${chunk_id}_ner_${i}`}
                label={`${mapNERWithIcon(ner?.type)} ${ner?.value}`}
              />
            </Popable>
          );
        })}
      {ners && ners?.length > maxNerByChunk && (
        <AppText
          style={{
            fontSize: currentTheme?.theme?.extra_small_font_size,
            marginLeft: 5,
            fontWeight: "bold",
          }}
          preventTranslation
        >
          +{ners?.length - maxNerByChunk} more
        </AppText>
      )}
    </View>
  );
};

export default NerViewComponent;
