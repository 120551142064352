import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppQuantumChat from "@toothfairy/shared-ui/AppQuantumChat";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect, useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { appDimensions } from "../MainViewContainer";
import { useHistory } from "../../Router";

const AgentScreen = ({
  messages,
  setMessages,
  user,
  handleSend = () => {},
  onQuestionPress,
  placeholder = "Ask me anything",
  agentTitle = "ToothFairyAI",
  textToDisplayWhileLoading = "**...**",
  disclaimer = "The information provided might be inaccurate and is not intended to be used for commercial agreements or legal proceedings.",
  isMessagesLoaded,
  languages,
  onLanguageChange = () => {},
  selectedLanguage,
  isAIStreaming,
  showTitle = false,
  onCancel = () => {},
  inputText,
  setInputText,
  loadingMessageId,
  isLoading,
  S3downloadUrlRequest = () => {},
  handleFeedback = () => {},
  agentImage,
  agentColor,
  allowFeedback = false,
  agentLogo,
  allowStop = false,
  isAgentFetched,
  isLastMessageEmpty,
  showDarkMode = true,
  showReloadInDisclosure = true,
  coder = false,
  workspaceId,
  workspace,
  quickQuestions,
  showSplash = false,
  splashTitle,
  logoBackground,
  showTimeForResponse,
  showDetectedLanguage,
  setWinner,
  isWinner,
  agentId,
  _agentName,
  setAgent,
  onAgentChange = () => {},
}) => {
  const history = useHistory();
  const { currentTheme } = AppTheme.useTheme();
  const { height } = useWindowDimensions();
  const [outputString, setOutputString] = useState("");
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [composerReady, setComposerReady] = useState(false);

  const [suppressDisabledPlaceholder, setSuppressDisabledPlaceholder] =
    useState(true);
  const [agentName, setAgentName] = useState("");
  useEffect(() => {
    setAgentName(_agentName);
  }, [agentId, _agentName]);

  useEffect(() => {
    if (isMessagesLoaded && currentCharIndex < placeholder?.length) {
      const timer = setTimeout(() => {
        setOutputString(
          (prevOutput) => prevOutput + placeholder[currentCharIndex]
        );
        setCurrentCharIndex((prevIndex) => prevIndex + 1);
      }, 1);

      return () => clearTimeout(timer);
    } else if (currentCharIndex === placeholder?.length) {
      setComposerReady(true);
      setSuppressDisabledPlaceholder(false);
    }
  }, [placeholder, currentCharIndex, isMessagesLoaded]);

  return (
    <div
      className="tf-chat-arena-container"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        flex: 1,
        flexDirection: "column",
        backgroundColor: currentTheme?.theme?.backgroundColor,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        border: `1px solid ${currentTheme?.theme?.border_color}`,
        maxWidth: "50%",
        height: "100%",
      }}
    >
      <div
        className="tf-agent-header"
        style={{
          backgroundColor: logoBackground || currentTheme?.theme?.lightGrey,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
          marginHorizontal: 5,
          alignSelf: "flex-start",
        }}
      >
        <AppIcon
          icon={AppAssets?.icons?.thumbsUp}
          wrapperStyle={{
            marginLeft: 10,
            marginBottom: 3,
          }}
          color={
            isWinner
              ? currentTheme?.theme?.primary
              : currentTheme?.theme?.mediumGrey
          }
          size={20}
          isClickable
          onPress={() => {
            setWinner(agentId);
          }}
        />
        <AppDropDown
          dropDownStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
            width: 315,
            marginLeft: 20,
            borderColor: currentTheme?.theme?.transparent,
          }}
          isElevated={false}
          dropdownIconColor="transparent"
          items={state?.agents
            ?.filter((u) => u?.mode === state?.selectedArenaMode)
            ?.map((u) => {
              return {
                label: u?.label,
                id: u?.id,
              };
            })}
          propertyLabel="label"
          propertyValue="id"
          selectedValue={agentId}
          onValueChange={(itemValue, itemIndex) => {
            setAgent({ id: itemValue });
          }}
        />
        <AppIcon
          icon={AppAssets?.icons?.link}
          wrapperStyle={{
            marginLeft: 10,
            marginBottom: 3,
          }}
          color={currentTheme?.theme?.primary}
          size={20}
          isClickable
          onPress={() => {
            history.push(
              `/workspaces/${workspaceId}/settings/roles_settings/${agentId}`
            );
          }}
        />
      </div>

      <div
        style={{
          minHeight: state?.isChatOpen ? 300 : 50,
          width: "100%",
          paddingTop: 5,
        }}
        className="tf-chat-widget"
      >
        <div className="tf-agent">
          <View
            style={{
              flex: 1,
              height: "100%",
              marginTop: 5,
              paddingHorizontal: 5,
            }}
          >
            {/* <View
                  style={{
                    width: appDimensions().width < 1500 && "100%",
                    paddingTop: isMobileScreen() ? 10 : 60,
                    justifyContent: "space-between",
                    backgroundColor:
                      currentTheme?.theme?.website_contrast_background_color_opaque,
                  }}
                >
                  <FaqBadge onQuestionPress={onQuestionPress} />
                  <SettingsBadge
                    languages={languages}
                    onLanguageChange={onLanguageChange}
                    selectedLanguage={selectedLanguage}
                  />
                </View> */}
            <View
              style={{
                flexDirection: "row",
                alignSelf: "flex-start",
              }}
            >
              {showTitle && (
                <AppText
                  style={{
                    fontSize: currentTheme?.theme?.largest_font_size,
                  }}
                  fontWeight="bold"
                  wrapperStyle={{
                    marginTop: 0,
                    alignSelf: appDimensions().width < 860 && "flex-start",
                  }}
                  color={currentTheme?.theme?.primary}
                >
                  Ask {agentTitle}
                </AppText>
              )}
            </View>

            <div className="tf-chat-container">
              <AppQuantumChat
                // agentDefaultIcon={
                //   agentImage || require("../../assets/favicon.png")
                // }
                customChartingConfig={workspace?.customChartingConfig}
                coder={coder}
                isChatEmpty={messages?.length == 1}
                sendOnEnter
                renderMarkdown
                isWidget
                workspaceid={workspaceId}
                loadEarlier={false}
                messages={messages}
                setMessages={setMessages}
                onSendEvent={handleSend}
                user={{
                  id: user?.id,
                  name: user?.username,
                }}
                agentColor={agentColor || currentTheme?.theme?.primary}
                chatStyle={{
                  height: height - 315,
                }}
                showSplash={showSplash}
                showDuration={showTimeForResponse}
                showLanguage={showDetectedLanguage}
                splashTitle={splashTitle}
                inverted={false}
                showUserAvatar
                messagesContainerStyle={{
                  marginTop: -10,
                }}
                renderAvatarOnTop
                isDayDisplayed={false}
                allowFeedback={allowFeedback}
                handleFeedback={handleFeedback}
                maxComposerHeight={60}
                composerHeight={60}
                sortOrder="asc"
                placeholderDisabled="The agent is responding..."
                isComposerDisabled={!composerReady || isAIStreaming}
                textColor={"darkGrey"}
                isTyping={true}
                showIconForGenericUser
                textToDisplayWhileLoading={textToDisplayWhileLoading}
                onCancel={onCancel}
                inputText={inputText}
                setInputText={setInputText}
                renderWithVanillaInput={false}
                onLoadEarlier={() => {}}
                S3downloadUrlRequest={S3downloadUrlRequest}
              ></AppQuantumChat>
            </div>
          </View>
        </div>
      </div>
    </div>
  );
};

export default AgentScreen;
