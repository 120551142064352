import React from "react";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const AnswerLanguageDropDown = ({ setLanguage, language, languages }) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <AppDropDown
      showTextInputLabel
      label="Output lang."
      inputLabelStyle={{
        marginLeft: 0,
        marginRight: 10,
        marginTop: 10,
      }}
      dropDownStyle={{
        borderColor: currentTheme?.theme?.border_color,
        maxWidth: 250,
      }}
      wrapperContainer={{
        flexDirection: "row",
        alignItems: "baseline",
      }}
      isElevated={false}
      dropdownIconColor="transparent"
      items={
        languages ? [{ id: "def", name: "Auto-detect" }, ...languages] : []
      }
      propertyLabel="name"
      propertyValue="id"
      selectedValue={language}
      onValueChange={(itemValue, itemIndex) => {
        setLanguage(itemValue);
      }}
    />
  );
};

export default AnswerLanguageDropDown;
