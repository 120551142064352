import useAPI from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Logs from "../../../API/Logs";
import LogsView from "../Components/LogsView";

const LogsScreen = () => {
  const { state } = AppStateManager.useAppStateManager();
  const { t } = useTranslation();
  const fetchLimit = 50;
  const {
    data: logTypes,
    error: errorFetchingLogTypes,
    loading: fetchingLogTypes,
    apiRequest: fetchLogTypes,
    lastUpdated: lastUpdatedLogTypes,
  } = useAPI(Logs.getLogTypes);
  const {
    data: getLogsData,
    error: getLogsError,
    loading: getLogsInProgress,
    apiRequest: fetchLogs,
    lastUpdated: lastGetLogs,
  } = useAPI(Logs.getLogsByWorkspace);

  const [start, setStart] = useState(dayjs().startOf("month").toDate());
  const [end, setEnd] = useState(dayjs().endOf("month").toDate());
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [selectedFilterLimit, setSelectedFilterLimit] = useState(200);
  const [displayedLogs, setDisplayedLogs] = useState([]);
  const [lastForwardToken, setLastForwardToken] = useState(null);
  useEffect(() => {
    if (logTypes?.logTypes?.length > 0) {
      setSelectedServiceType(logTypes?.logTypes[0]);
    }
  }, [logTypes?.logTypes?.length]);
  const triggerFetchLogs = async (incremental) => {
    if (
      selectedServiceType !== "" &&
      start !== null &&
      end !== null &&
      typeof selectedServiceType === "string"
    ) {
      setLastForwardToken(getLogsData?.result?.nextForwardToken);
      const response = await fetchLogs({
        workspaceId: state.activeWorkspaceID,
        service: selectedServiceType,
        start: start.getTime(),
        end: end.getTime(),
        nextToken: incremental && getLogsData?.result?.nextForwardToken,
        limit: selectedFilterLimit,
      });
      const events = response?.result?.events;

      if (incremental) {
        setDisplayedLogs(
          displayedLogs.concat(
            events?.map((log) => {
              return {
                timestamp: AppUtilities.dateConverter
                  .ConvertTimeStampToDateString(log.timestamp, "en", t, true)
                  .toString(),
                message: JSON.parse(log.message)?.message,
              };
            })
          )
        );
      } else {
        setDisplayedLogs(
          events?.map((log) => {
            return {
              timestamp: AppUtilities.dateConverter
                .ConvertTimeStampToDateString(log.timestamp, "en", t, true)
                .toString(),
              message: JSON.parse(log.message)?.message,
            };
          })
        );
      }
    }
  };

  const handleUpdateStartDate = (v) => {
    setStart(v);
  };
  const handleUpdateEndDate = (v) => {
    setEnd(v);
  };

  useEffect(() => {
    fetchLogTypes();
  }, [state?.activeWorkspaceID]);

  useEffect(() => {
    if (state?.activeWorkspaceID) {
      triggerFetchLogs();
    }
  }, [selectedServiceType, state.activeWorkspaceID]);

  useEffect(() => {
    if (getLogsError) {
      setDisplayedLogs([]);
    }
  }, [getLogsError]);

  return (
    <LogsView
      isLoadMoreAvailable={
        getLogsData?.result?.nextForwardToken !== lastForwardToken &&
        !getLogsError
      }
      selectedServiceType={selectedServiceType}
      selectedFilterLimit={selectedFilterLimit}
      setSelectedFilterLimit={setSelectedFilterLimit}
      setSelectedServiceType={setSelectedServiceType}
      logTypes={logTypes}
      fetchingLogTypes={fetchingLogTypes}
      triggerFetchLogs={triggerFetchLogs}
      onUpdateStartDate={handleUpdateStartDate}
      onUpdateEndDate={handleUpdateEndDate}
      startDate={start}
      endDate={end}
      logs={displayedLogs}
      isLogsFetchingInProgress={getLogsInProgress}
    />
  );
};

export default LogsScreen;
