import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import envConfig from "../../../envConfig";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import API from "@aws-amplify/api";
import { updateOutputStream } from "../../graphql/mutations";
import { getOutputStream } from "../../graphql/queries";

const getHumanFeedback = async ({
  workspaceid,
  nextToken,
  limit,
  feedbackType,
  includeResolved,
  mainFeedbackType,
}) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/rlhf/getHumanFeedback`;
  const payload = await AppAuth.Auth.currentSession();

  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    {
      workspaceid,
      paginationToken: nextToken,
      limit,
      feedbackType,
      includeResolved,
      mainFeedbackType,
    },
    true,
    null,
    payload
  );
};
const getOutputStream_ = async (id) => {
  return API.graphql({
    query: getOutputStream,
    variables: { id },
  });
};

const updateFeedbackOnStream = async (id, args) => {
  const original = await getOutputStream_(id);
  let _properties = {};
  args.forEach((item) => {
    _properties[item.property] = item.value;
  });
  return API.graphql({
    query: updateOutputStream,
    variables: {
      input: {
        id,
        _version: original?.data?.getOutputStream?._version,
        ..._properties,
      },
    },
  });
};

export default {
  getHumanFeedback,
  updateFeedbackOnStream,
  getOutputStream_,
};
