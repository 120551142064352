import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React from "react";
import { Platform, View } from "react-native";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../MainViewContainer";

const ChartingSettingsView = ({ onSubmit = () => {}, isSaveInProgress }) => {
  const { currentTheme } = AppTheme.useTheme();
  const { getActiveWorkspace } = useWorkspaces();
  const AppToasty = AppToast.useToast();
  const chartingSchema = AppForms.yup.object().shape({
    primaryColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    secondaryColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    tertiaryColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    primaryTextColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    primaryBorderColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    lineColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    plotPrimaryColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    plotSecondaryColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    plotTertiaryColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
    xyBackgroundColor: AppForms.yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, "Invalid hex color"),
  });

  return (
    <View
      style={{
        marginHorizontal: "auto",
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 340,
      }}
    >
      <AppForms.AppForm
        style={{
          minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 250,
          maxWidth: !isLargeScreen() && 250,
          alignSelf: !isLargeScreen() && "center",
        }}
        initialValues={{
          primaryColor:
            getActiveWorkspace()?.customChartingConfig?.primaryColor,
          secondaryColor:
            getActiveWorkspace()?.customChartingConfig?.secondaryColor,
          tertiaryColor:
            getActiveWorkspace()?.customChartingConfig?.tertiaryColor,
          primaryTextColor:
            getActiveWorkspace()?.customChartingConfig?.primaryTextColor,
          primaryBorderColor:
            getActiveWorkspace()?.customChartingConfig?.primaryBorderColor,
          lineColor: getActiveWorkspace()?.customChartingConfig?.lineColor,
          plotPrimaryColor:
            getActiveWorkspace()?.customChartingConfig?.plotPrimaryColor,
          plotSecondaryColor:
            getActiveWorkspace()?.customChartingConfig?.plotSecondaryColor,
          plotTertiaryColor:
            getActiveWorkspace()?.customChartingConfig?.plotTertiaryColor,
        }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={chartingSchema}
      >
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          placeholder="Primary Color (hex)"
          showTextInputLabel
          label="Primary Color"
          name="primaryColor"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          placeholder="Secondary Color (hex)"
          showTextInputLabel
          label="Secondary Color"
          name="secondaryColor"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="Tertiary Color"
          name="tertiaryColor"
          placeholder="Tertiary Color (hex)"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="Primary text color"
          name="primaryTextColor"
          placeholder="Primary text color (hex)"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="Primary border color"
          name="primaryBorderColor"
          placeholder="Primary border color (hex)"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="Line Color"
          name="lineColor"
          placeholder="Line Color (hex)"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="Plot Primary Color"
          name="plotPrimaryColor"
          placeholder="Plot Primary Color (hex)"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="Plot Secondary Color"
          name="plotSecondaryColor"
          placeholder="Plot Secondary Color (hex)"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="Plot Tertiary Color"
          name="plotTertiaryColor"
          placeholder="Plot Tertiary Color (hex)"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            marginTop: 10,
          }}
          showTextInputLabel
          label="XY Background Color"
          name="xyBackgroundColor"
          placeholder="Plot Background Color (hex)"
        />

        <AppForms.AppSubmitButton
          title={"Save"}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 30,
              alignSelf: "center",
              height: Constants.appButtonHeight,
            },
          ]}
        />
      </AppForms.AppForm>
    </View>
  );
};

export default ChartingSettingsView;
