const objectCleaner = (obj) => {
  // clean object from empty strings , nulls and empty arrays
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] === "" ||
      (typeof obj[key] === "string" && obj[key]?.trim() === "")
    )
      delete obj[key];
    if (obj[key] === null) delete obj[key];
    if (Array.isArray(obj[key]) && obj[key].length === 0) delete obj[key];
  });
  return obj;
};

export default {
  objectCleaner,
};
