import styled from "@emotion/native";
import { View } from "react-native";
import { AppText } from "@toothfairy/shared-ui";
import React from "react";
import Constants from "../../Constants";

export const SideBar = styled(View, {
  shouldForwardProp: (prop) => prop !== "width",
})`
  height: 100vh;
  z-index: -1;
  background-color: rgb(246, 249, 252);
  width: ${({ width }) =>
    width <= Constants.screenResponsiveWidth
      ? `${Constants.sideMenuWidth}px`
      : `${Constants.sideMenuWidth}px`};
  justify-content: flex-start;
  align-items: flex-start;
  display: ${({ showMenu }) => (showMenu ? "flex" : "none")};
`;

export const PinMenuText = styled(AppText)`
  padding: 10px 25px;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

export const PinMenu = ({ click }) => (
  <PinMenuText onClick={click}>Pin menu</PinMenuText>
);
