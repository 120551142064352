import useApi from "@toothfairy/shared-api/useApi";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import envConfig from "../../envConfig";
import useWorkspaces from "./useWorkspaces";

const useAutoRequest = ({
  apiMethod,
  executionInterval = 10000,
  initialValue,
  executionSkipsForBooting = 0,
  forceJSONparsing = true,
  scope,
  isEnabled = true,
  initialLanguage = "def",
}) => {
  const [value, setValue] = useState(initialValue);
  const [language, setLanguage] = useState(initialLanguage);
  const { getActiveWorkspace } = useWorkspaces();
  const [lastChange, setLastChange] = useState(dayjs().toDate().getTime());
  const [skippedExecutionsForBooting, setSkippedExecutionsForBooting] =
    useState(0);

  const {
    data: autoExecuteAPIResult,
    error: autoExecuteAPIError,
    loading: autoExecuteAPIInProgress,
    apiRequest: autoExecuteAPIRequest,
    lastUpdate: lastExecutionTime,
    optimisticDataUpdate: autoExecuteOptimisticDataUpdate,
  } = useApi(apiMethod, envConfig, forceJSONparsing);

  const executeRequest = async ({ value, workspace, scope }) => {
    if (
      !value ||
      AppQuantumEditorUtils.Helpers.getPlainText(value)?.trim() === ""
    ) {
      autoExecuteOptimisticDataUpdate({
        contents: [
          {
            output: {
              real_probability: 0,
              fake_probability: 0,
            },
            dictionaries: [],
            id: null,
            shortText: "",
          },
        ],
        workspaceid: workspace?.id,
      });
      return;
    }

    if (scope === "fakeContentCheck")
      await handleCheckOnFakeData({ value, workspace });
  };

  const autoExecute = useRef(
    debounce(({ value, workspace, scope, language }) => {
      if (skippedExecutionsForBooting >= executionSkipsForBooting)
        executeRequest({ value, workspace, scope, language });
      else setSkippedExecutionsForBooting(skippedExecutionsForBooting + 1);
    }, executionInterval)
  ).current;

  useEffect(() => {
    if (scope && isEnabled) {
      setLastChange(dayjs().toDate().getTime());
      autoExecute({
        value,
        workspace: getActiveWorkspace(),
        scope,
        language,
      });
    }
  }, [AppQuantumEditorUtils.Helpers.getPlainText(value), language, scope]);

  const handleCheckOnFakeData = ({ value, workspace }) => {
    const payload = {
      contents: [
        {
          text: AppQuantumEditorUtils.Helpers.getPlainText(value),
          sourceLanguage: language,
        },
      ],
      token: workspace?.workspaceToken,
      workspaceid: workspace?.id,
    };
    return autoExecuteAPIRequest(payload);
  };

  return {
    value,
    setValue,
    language,
    setLanguage,
    setLastChange,
    lastChange,
    autoExecuteAPIResult,
    autoExecuteAPIError,
    autoExecuteAPIInProgress,
    lastExecutionTime,
    setSkippedExecutionsForBooting,
  };
};

export default useAutoRequest;
