import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect, useState, useRef } from "react";
import { Tree } from "react-arborist";
import { useWindowDimensions, View } from "react-native";
import { TreeviewProvider } from "../../Hooks/useTreeView";
import { minAppWidth } from "../../Screens/MainViewContainer";
import Utils from "./Components/Utils";
function AppTreeView({
  foldersData,
  title,
  handleClickOnNode,
  selectedNode,
  setDialogActionOnTree,
  deleteNode,
  isEmpty,
  onDrop,
  initialOpen,
  showCollapseTree = false,
  isCollapsed,
  setIsCollapsed,
  showIconsOnSelection = false,
  requireNormalisation = true,
  onMove,
  deleteAction,
  editLeafAction,
  editLeafParentAction,
  allowParentEdit = true,
  fixedNodeIds,
  heightOffset = 200,
  indent = 22,
  minWidth = 330,
  disableTFDrop,
  disableTFDrag,
  preventSortingByName,
  filterPlaceholder = "Filter",
  treemode = "DEFAULT",
  secondaryActionTitle,
  secondaryActionCallback = () => {},
  setSelectedNodeForTreeView,
  allowParentAction,
  onParentActionClick = () => {},
  ...props
}) {
  const treeRef = useRef();
  const { height } = useWindowDimensions();
  const [treeData, setTreeData] = useState(null);
  const [term, setTerm] = useState("");
  function listToTree(data, options) {
    options = options || {};
    var ID_KEY = options.idKey || "id";
    var PARENT_KEY = options.parentKey || "parent";
    var CHILDREN_KEY = options.childrenKey || "children";

    var tree = [],
      childrenOf = {};
    var item, id, parentId;

    for (var i = 0, length = data.length; i < length; i++) {
      item = data[i];
      id = item[ID_KEY];
      parentId = item[PARENT_KEY] || "root";
      // every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId != "root") {
        // init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        // push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    }

    return tree;
  }
  const normaliseDataForTree = () => {
    // using the parentid inside the flat array folderData recursively group the data inside a children array
    const result = listToTree(foldersData, {
      idKey: "id",
      parentKey: "parent",
    });
    return result;
  };
  function sortTree(tree) {
    if (preventSortingByName) return tree;
    // Sort the array
    tree.sort((a, b) => a?.name?.localeCompare(b?.name));

    // Sort children arrays recursively
    tree.forEach((item) => {
      if (item?.children) {
        sortTree(item?.children);
      }
    });

    return tree;
  }
  useEffect(() => {
    if (requireNormalisation) setTreeData(sortTree(normaliseDataForTree()));
    else setTreeData(sortTree(foldersData));
  }, [foldersData]);
  const ref = React.useRef(null);
  const [availableWidth, setAvailableWidth] = React.useState(0);
  const handleDrop = (newTreeData, rest) => {
    const { dragSourceId, dropTargetId } = rest;
    onDrop({
      newTreeData,
      dragSourceId,
      dropTargetId,
      rest,
    });
  };

  useEffect(() => {
    setAvailableWidth(ref.current.clientWidth - 50);
  });

  const { currentTheme, mode } = AppTheme.useTheme();
  return (
    <TreeviewProvider
      onSelectionChanged={handleClickOnNode}
      onDrop={handleDrop}
      onDeletion={deleteNode}
      setDialogActionOnTree={setDialogActionOnTree}
      showIconsOnSelection={showIconsOnSelection}
      selectedNode={selectedNode}
      treeData={treeData}
      deleteAction={deleteAction}
      editLeafAction={editLeafAction}
      editLeafParentAction={editLeafParentAction}
      allowParentEdit={allowParentEdit}
      allowParentAction={allowParentAction}
      onParentActionClick={onParentActionClick}
      fixedNodeIds={fixedNodeIds}
      treemode={treemode}
    >
      <div
        ref={ref}
        style={{
          marginTop: 5,
          zIndex: 10,
          // height: "100%",
          maxWidth: isCollapsed ? 14 : "35%",
          minWidth: !isCollapsed && (minWidth || minAppWidth() - 50),
          // overflowY: !isCollapsed && "auto",
          backgroundColor: currentTheme?.theme?.backgroundColor,
        }}
        id="main-tree-view"
        onClick={(e) => {
          // get element clicked on
          if (e.target.id === "main-tree-view") {
            handleClickOnNode(null);
          }
        }}
      >
        <View
          style={{
            flexDirection: "column",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {showCollapseTree && (
              <AppIcon
                icon={
                  isCollapsed
                    ? AppAssets.icons.arrow_right
                    : AppAssets.icons.arrow_down
                }
                isClickable
                onPress={() => {
                  setIsCollapsed(!isCollapsed);
                }}
                wrapperStyle={{
                  position: "relative",
                }}
              />
            )}
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {title && !isCollapsed && (
                <AppText
                  wrapperStyle={{
                    marginLeft: 5,
                  }}
                  fontWeight="bold"
                >
                  {title}
                </AppText>
              )}
              {secondaryActionTitle && !isCollapsed && (
                <AppText
                  wrapperStyle={{
                    alignSelf: "center",
                  }}
                  isClickable
                  style={{
                    color: currentTheme?.theme?.primary,
                  }}
                  fontWeight="bold"
                  onPress={secondaryActionCallback}
                  rightIcon={AppAssets.icons.arrow_down}
                  iconSize={14}
                >
                  {secondaryActionTitle}
                </AppText>
              )}
            </View>
          </View>
          {!isCollapsed && (
            <AppTextInput
              editable={true}
              iconColor={currentTheme?.theme?.grey}
              value={term}
              onChangeText={(text) => setTerm(text)}
              placeholder={filterPlaceholder}
              leftIcon={AppAssets.icons.search}
              textContainer={{
                paddingHorizontal: 10,
              }}
              style={{
                marginLeft: 5,
              }}
            />
          )}
          {!isCollapsed && treeData && (
            <Tree
              ref={treeRef}
              selection={selectedNode?.id}
              className="tree"
              openByDefault={initialOpen}
              data={treeData}
              width={"100%"}
              height={height - heightOffset}
              rowHeight={32}
              indent={indent}
              // renderCursor={Utils.Cursor}
              searchTerm={term}
              onMove={onMove}
              // renderRow={Utils.Row}
              paddingBottom={32}
              renderDragPreview={Utils.DragPreview}
              disableEdit={(data) => data.readOnly}
              // renderDragPreview={Utils.DragPreview}
              disableDrop={({ parentNode }) => {
                return disableTFDrop || parentNode.data.droppable !== true;
              }}
              disableDrag={(data) =>
                disableTFDrag || data.id === "mrcRoot" || data.id === "qaRoot"
              }
              onClick={() => {
                setSelectedNodeForTreeView && setSelectedNodeForTreeView(null);
              }}
            >
              {Utils.Node}
            </Tree>
          )}
        </View>
      </div>
    </TreeviewProvider>
  );
}

export default AppTreeView;
