import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { forwardRef, useMemo } from "react";
import dictionariesHelper from "../../../../API/Dictionaries/dictionariesHelper";
import useSections from "../../../../Hooks/useSections";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import DictionarySettingsAIView from "../SubComponents/DictionarySettingsAIView";
import AppToast from "@toothfairy/shared-ui/AppToast";
import { useTranslation } from "react-i18next";
import Utility from "../../../../Utility";
import { isLargeScreen } from "../../../MainViewContainer";

const DictionarySettingsAI = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onMappingCreated,
      selectedId,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();

    const { state, dispatch } = AppStateManager.useAppStateManager();
    const { getWorkspaceBaseModel } = useWorkspaces();
    const validationSchema = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      sourceLanguage: AppForms.yup.string().required().label(),
      targetLanguage: AppForms.yup.string().required().label(),
      sourceText: AppForms.yup
        .string()
        .test(
          "len",
          "Must be no more than 4 words",
          (val) => val?.split(" ")?.length < 5
        )
        .required(),
      targetText: AppForms.yup
        .string()
        .test(
          "len",
          "Must be no more than 4 words",
          (val) => val?.split(" ")?.length < 5
        )
        .required(),
      description: AppForms.yup.string().nullable(),
      pos: AppForms.yup.string().nullable(),
    });

    const handleDeletion = async () => {
      try {
        const result = await dictionariesHelper.deleteDictionaryItem(opt.id);
        dispatch("SET_AI_DICTIONARIES_CONFIG", [
          ...state.aiDictionariesConfig?.filter((u) => u?.id !== opt.id),
        ]);
        navigateBack();
        AppToasty.show(
          `${Utility.upperCaseFirstLetter("dictionary")} ${t("deleted")}`,
          {
            type: "success",
          }
        );
      } catch (error) {
        console.error("dictionaryDeleteErrorCaption", error);
        AppToasty.show(
          `${
            t("dictionaryDeleteErrorCaption") + " "
          } ${Utility.upperCaseFirstLetter("dictionary")}`,
          {
            type: "danger",
          }
        );
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };
    const identifyOptByID = (id) => {
      return state.aiDictionariesConfig.map((u) => u.id).indexOf(id);
    };
    // create a function that checks if two strings might have one or more full words in common
    const checkWordsInCommon = (str1, str2) => {
      const str1Words = str1.split(" ");
      const str2Words = str2.split(" ");
      return str1Words.some((word) => str2Words.includes(word));
    };
    const handleDictionaryItemSave = async (v) => {
      try {
        if (v.sourceLanguage === v.targetLanguage && v.sourceLanguage != "en") {
          return onMappingCreated("inconsistent_language_mapping");
        } else if (
          v.sourceLanguage === "en" &&
          v.targetLanguage === "en" &&
          checkWordsInCommon(
            v.sourceText?.toLowerCase(),
            v.targetText.toLowerCase()
          )
        ) {
          return onMappingCreated("en_language_mapping");
        } else if (v.sourceLanguage !== "en" && v.targetLanguage !== "en") {
          return onMappingCreated("en_language_not_found_mapping");
        }
        let result;
        const payload = [
          {
            property: "sourceLanguage",
            value: v.sourceLanguage,
          },
          {
            property: "targetLanguage",
            value: v.targetLanguage,
          },
          {
            property: "sourceText",
            value: v.sourceText,
          },
          {
            property: "targetText",
            value: v.targetText,
          },
          {
            property: "description",
            value: v.description,
          },
          {
            property: "pos",
            value: v.pos,
          },
        ];
        if (isEdit) {
          result = await dictionariesHelper.updateDictionaryItem(
            selectedId,
            payload
          );
          state.aiDictionariesConfig[identifyOptByID(result.id)] = result;
        } else {
          result = await dictionariesHelper.createDictionaryItem({
            sourceLanguage: v.sourceLanguage,
            targetLanguage: v?.targetLanguage,
            sourceText: v?.sourceText,
            description: v?.description,
            targetText: v.targetText,
            pos: v.pos,
            workspaceID: state.activeWorkspaceID,
          });
          state.aiDictionariesConfig.push(result);
        }
        dispatch("SET_AI_DICTIONARIES_CONFIG", [...state.aiDictionariesConfig]);
        backTo && navigateBack();
        onMappingCreated && onMappingCreated("success");
      } catch (error) {
        console.error(error);
        onMappingCreated && onMappingCreated("error");
      }
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            height: 500,
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Mapping : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={handleDictionaryItemSave}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <DictionarySettingsAIView
              handleDeletion={handleDeletion}
              isEdit={isEdit}
              settingType={settingType}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [
        state.activeWorkspaceID,
        initialMapValues,
        state.aiDictionariesConfig,
        isEdit,
      ]
    );
    return _view;
  }
);

export default DictionarySettingsAI;
