import React, { useEffect } from "react";
import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { View } from "react-native";
import { displayedToasties } from "../../../App";

const ToothFairyToast = ({
  type,
  successColor,
  dangerColor,
  warningColor,
  message,
  showTitle = false,
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const mapTypeWithColor = () => {
    switch (type) {
      case "success":
        return currentTheme?.theme?.green;
      case "danger":
        return currentTheme?.theme?.red;
      case "warning":
        return currentTheme?.theme?.orange;
      default:
        return currentTheme?.theme?.gray;
    }
  };
  // use effect when component unmounts
  useEffect(() => {
    return () => {
      displayedToasties(null);
    };
  }, []);
  return (
    <View
      style={{
        marginHorizontal: "auto",
        backgroundColor: mapTypeWithColor(),
        marginTop: 10,
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 10,
        alignItems: "center",
        alignContent: "center",
        minWidth: 300,
        zIndex: 1000,
      }}
    >
      {showTitle && (
        <AppText
          fontWeight="bold"
          style={{
            fontSize: currentTheme?.theme?.medium_font_size,
            textTransform: "capitalize",
          }}
          color={currentTheme?.theme?.white}
          wrapperStyle={{
            marginBottom: 5,
          }}
        >
          {type}
        </AppText>
      )}

      <AppText
        fontWeight="bold"
        style={{ fontSize: currentTheme?.theme?.small_font_size }}
        color={currentTheme?.theme?.white}
      >
        {message}
      </AppText>
    </View>
  );
};

export default ToothFairyToast;
