import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import { showSideMenu } from "../../../../../App";
import AgentFunctions from "../../../../API/AgentFunctions";
import Agents from "../../../../API/Agents";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { useHistory, useParams } from "../../../../Router";
import Utility, {
  createUniqueCode,
  generateRowId,
} from "../../../../Utility/index";
import { isMobileScreen } from "../../../MainViewContainer";
import QaSettingsAI from "./QaSettingsAI";
import QaConfigToolbar from "./SubComponents/QaConfigToolbar";
import ToothFairyLoadingPlaceholder from "../../../../Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";
import Constants from "../../../../Constants";
import colorsMappings from "../../../Common/colorsMappings";
import execEnvsHelper from "../../../../API/ExecutionEnvironments/execEnvsHelper";
import channelsHelper from "../../../../API/Channels/channelsHelper";

const QaConfigList = ({ isQaRolesLoading }) => {
  const { currentTheme } = AppTheme.useTheme();
  const history = useHistory();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [availableFunctions, setAvailableFunctions] = useState([]);
  const [customCodeExecutionEnvironments, setCustomCodeExecutionEnvironments] =
    useState([]);
  const [agentsChannels, setAgentsChannels] = useState([]);
  const [availableFunctionsLoaded, setAvailableFunctionsLoaded] =
    useState(false);
  const [minimisedTable, setMinisedTable] = useState(false);
  const [baseAgents, setBaseAgents] = useState([]);
  const [refreshTimestamp, setNewRefreshTimestamp] = useState(
    Date.now().toString()
  );
  const [agentTypeToCreate, setAgentTypeToCreate] = useState(null);
  const generateMeanigfulAgentName = (agent) => {
    if (agent?.agentType === "Chatbot") {
      if (agent?.mode === "chatter") {
        return "Casual";
      }
      if (agent?.mode === "coder") {
        return "Coder";
      }
      if (agent?.mode === "retriever") {
        return "Retriever";
      }
      if (agent?.mode === "planner") {
        return "Planner";
      } else {
        return "Retriever";
      }
    } else if (agent?.agentType === "ContentCreator") {
      return "Content Creator";
    } else if (agent?.agentType === "BusinessAnalyst") {
      return "Business Analyst";
    } else {
      return "Casual";
    }
  };
  const {
    getActiveWorkspace,
    getActiveTrainingLog,
    getPlanConfig,
    getWorkspaceUsers,
    getAgent,
  } = useWorkspaces();
  let _options = [
    {
      label: "Chat",
      id: "Chatbot",
      icon: AppAssets?.icons?.quantumChat,
    },
  ];
  if (getPlanConfig()?.isContentCreatorEnabled) {
    _options.push({
      label: "Content Creator",
      id: "ContentCreator",
      icon: AppAssets?.icons?.writer,
    });
  }
  if (getPlanConfig()?.isBusinessAnalystEnabled) {
    _options.push({
      label: "Business Analyst",
      id: "BusinessAnalyst",
      icon: AppAssets?.icons?.ba,
    });
  }
  const [showQaRoleModal, setShowQaRoleModal] = React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [modalTitle, setModalTitle] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedRoles, setDisplayedRoles] = React.useState([]);
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { width, height } = useWindowDimensions();
  const formRef = useRef();
  const [initialMapValues, setInitialMapValues] = useState({
    // type: "",
    label: "",
    // promptPlaceholder: "",
    interpolationString: "",
    description: "",
    defaultAnswer: "",
    inhibitionPassage: "",
    qaUrl: getActiveTrainingLog("qa")?.id,
    temperature: 0.5,
    blender: true,
    largeLogo: null,
    voiceFileKey: null,
    icoUrl: null,
    subject: null,
    goals: "",
    disclaimer: null,
    quickQuestions: null,
    allowFeedback: false,
    preventWidgetUsage: true,
    color: null,
    darkColor: null,
    logoBackground: null,
    darkLogoBackground: null,
    placeholderInputMessage: null,
    placeholderMessageOnLoading: null,
    messageOnLaunch: null,
    pertinencePassage: "",
    mode: "retriever",
    minRetrievalScore: 0.65,
    topK: 5,
    maxHistory: 3,
    maxTokens: 500,
    showAgentName: false,
    maxTopics: 2,
    topicEnhancer: false,
    allowedTopics: [],
    summarisation: false,
    compressor: false,
    docTopK: 2,
    recencyImportance: 0,
    maxRecencyIncrease: 0,
    recencyTopKIncreaseFactor: 3,
    keywordsWeightInRetrieval: 0,
    extendedOutput: false,
    actions: "",
    agentFunctions: [],
    hasFunctions: false,
    hasImages: false,
    allowImagesUpload: false,
    allowInternetSearch: false,
    allowImagesGeneration: false,
    allowDeepInternetSearch: false,
    internetSearchModes: ["search"],
    internetSearchLocation: "Australia",
    bannedDomains: [],
    maxUrlsForInternetSearch: 5,
    allowVideosUpload: false,
    allowAudiosUpload: false,
    allowDocsUpload: false,
    hasNER: false,
    showReferencesToInternalDocs: false,
    advancedLanguageDetection: false,
    restrictedAccess: false,
    restrictedAccessUsers: null,
    keyWordsForKnowledgeBase: false,
    promptTopKeywords: 5,
    splashTitle: null,
    showSplashWhenEmpty: false,
    plainTextOutput: false,
    generateExtendedReport: false,
    hasModeration: false,
    customModerations: Constants.defaultModerations,
    showTimeForResponse: false,
    customCodeExecutionEnvironments: [],
    showDetectedLanguage: false,
    isMultiLanguageEnabled: true,
    icon: null,
    llmProvider: "tf",
    llmBaseModel: "sorcerer",
    functionCallingProvider: "tf",
    functionCallingBaseModel: "sorcerer",
    maxImagesGenerated: 4,
    hasCode: false,
    agentsPool: null,
    maxPlanningSteps: 10,
    plannerExecutionAttempts: 3,
    plannerRequiresApprovalForPlanExecution: true,
    planningInstructions: null,
    reviewInstructions: null,
    phoneNumberAsSender: null,
    channelCallbackDelay: null,
    whatsappNumberAsSender: null,
    emailAsSender: null,
    communicationServices: null,
    allowAudiosGeneration: false,
    autoVoiceMode: false,
    customToolingInstructions: "",
  });
  const generateColourForSearchModes = (mode) => {
    if (mode === "search") {
      return currentTheme?.theme?.primary;
    } else if (mode === "news") {
      return currentTheme?.theme?.green;
    } else if (mode === "images") {
      return currentTheme?.theme?.orange;
    } else if (mode == "videos") {
      return currentTheme?.theme?.red;
    } else if (mode == "shopping") {
      return currentTheme?.theme?.purple;
    } else {
      return currentTheme?.theme?.primary;
    }
  };
  const enhanceAgents = (agentsPoolIds) => {
    return agentsPoolIds?.map((id) => {
      const _agent = getAgent(id);
      if (_agent)
        return {
          id: _agent["id"],
          label: _agent["label"],
          name: _agent["label"],
          value: _agent["id"],
          category: _agent["agentType"],
          backgroundColor:
            colorsMappings.mapBackgroundColorWithAgentTypeAndMode(
              _agent,
              currentTheme
            ),
        };
    });
  };
  useEffect(() => {
    setInitialMapValues({
      ...initialMapValues,
      qaUrl: getActiveTrainingLog("qa")?.id,
      recencyImportance: initialMapValues?.recencyImportance || 0,
      keywordsWeightInRetrieval:
        initialMapValues?.keywordsWeightInRetrieval || 0,
      channelCallbackDelay: initialMapValues?.channelCallbackDelay,
      maxPlanningSteps: initialMapValues?.maxPlanningSteps || 10,
      maxImagesGenerated:
        initialMapValues?.maxImagesGenerated > 0
          ? initialMapValues?.maxImagesGenerated
          : 4,
      plannerExecutionAttempts: initialMapValues?.plannerExecutionAttempts || 3,
      plannerRequiresApprovalForPlanExecution:
        initialMapValues?.plannerRequiresApprovalForPlanExecution != false,

      agentsPool: enhanceAgents(initialMapValues?.agentsPool),
      internetSearchModes: initialMapValues?.internetSearchModes?.map((u) => ({
        value: u,
        label: u,
        backgroundColor: generateColourForSearchModes(u),
      })),
    });
  }, [getActiveTrainingLog("qa")?.id, agentTypeToCreate]);
  useEffect(() => {
    fetchBaseAgents();
  }, [getActiveWorkspace()?.id]);
  const fetchBaseAgents = async () => {
    const resultBaseAgents = await Agents.getBaseAgentsByWorkspaceID();
    setBaseAgents(resultBaseAgents?.data?.agentsByWorkspace?.items);
  };
  const params = useParams();
  useEffect(() => {
    if (params?.optId && state?.agents?.length > 0) {
      handleRoleItemSelection({
        item: { id: params?.optId },
      });
    }
  }, [params?.optId, state?.agents?.length, refreshTimestamp]);
  useEffect(() => {
    if (!showQaRoleModal) {
      history.push(
        `/workspaces/${getActiveWorkspace()?.id}/settings/roles_settings`
      );
    }
  }, [showQaRoleModal]);
  useEffect(() => {
    setMinisedTable(width < 1124);
  }, [width]);
  const columns = [
    {
      title: "ID",
      width: 0.3,
    },
    {
      title: "Name",
      width: 0.2,
    },
    // {
    //   title: "Prompt length",
    // },

    {
      title: "Domain",
      width: 0.3,
    },
    {
      title: "Type",
      width: 0.2,
    },
    {
      title: "Actions",
      isAction: true,
    },
  ];
  const handleDuplication = async (id) => {
    try {
      const item = state?.agents?.find((u) => u.id === id);
      let newRole = {
        ...item,
        label: item.label + " - Copy",
      };
      delete newRole.id;
      delete newRole.createdAt;
      delete newRole.updatedAt;
      delete newRole._version;
      delete newRole._deleted;
      delete newRole._lastChangedAt;
      delete newRole._typename;

      const creationResult = await Agents.createAgent(newRole);
      const newAgentsArray = [...state?.agents];
      newAgentsArray.push(creationResult);
      dispatch("SET_AGENTS", newAgentsArray);
      AppToasty.show(t("qaDuplicationSuccessCaption"), {
        type: "success",
      });
    } catch (error) {
      AppToasty.show(t("qaDuplicationErrorCaption"), {
        type: "danger",
      });
      console.log("error", error);
    }
  };
  const handleGenerationItemSave = async (values) => {
    if (
      values?.bannedDomains != null &&
      values?.bannedDomains != "" &&
      values?.bannedDomains.length > 0 &&
      typeof values?.bannedDomains === "string"
    ) {
      values.bannedDomains = values.bannedDomains.split(",");
      // loop through all the banned domains and remove any empty strings
      values.bannedDomains = values.bannedDomains
        .filter((u) => u)
        ?.map((u) => u.trim());
    } else {
      values.bannedDomains = values.bannedDomains || [];
    }
    if (values?.llmBaseModel == null) values.llmBaseModel = "sorcerer";
    if (values?.llmProvider == null) values.llmProvider = "tf";
    if (values?.functionCallingProvider == null)
      values.functionCallingProvider = "tf";
    if (values?.functionCallingModel == null)
      values.functionCallingModel = "sorcerer";
    if (values?.qaUrl == "") values.qaUrl = null;
    values.agentsPool = values?.agentsPool?.map((u) => u.id);
    const hasAllowedTopics =
      values && values.allowedTopics && values.allowedTopics.length > 0;
    const isRetrieverChatbot =
      values &&
      ["retriever", "speed", "accuracy"].includes(values.mode) &&
      (values.agentType === "Chatbot" || values.agentType == null);
    const hasAgentFunctions =
      values && values.agentFunctions && values.agentFunctions.length > 0;
    if (
      !hasAllowedTopics &&
      ((isRetrieverChatbot &&
        !hasAgentFunctions &&
        !values?.allowInternetSearch) ||
        values.agentType === "BusinessAnalyst")
    ) {
      AppToasty.show(
        values.agentType === "BusinessAnalyst"
          ? "Please select at least one topic for this agent"
          : "Please select at least one topic, a function or enable internet search for this agent",
        {
          type: "danger",
        }
      );
      return;
    }
    if (
      values?.mode == "planner" &&
      (values?.agentsPool == null || values?.agentsPool?.length < 1)
    ) {
      AppToasty.show(
        "Please select at least one agent to use for the execution of plans for this planner",
        {
          type: "danger",
        }
      );
      return;
    }
    try {
      if (isEditMode && selectedIndex !== null) {
        const editedRole = state?.agents[selectedIndex];

        if (editedRole) {
          editedRole.label = values.label;
          editedRole.interpolationString = values.interpolationString;
          editedRole.customToolingInstructions =
            values.customToolingInstructions;
          editedRole.description = values.description;
          editedRole.defaultAnswer = values.defaultAnswer;
          editedRole.inhibitionPassage = values.inhibitionPassage;
          editedRole.qaUrl = values.qaUrl;
          editedRole.temperature = values.temperature;
          editedRole.blender = values.blender;
          editedRole.largeLogo = values.largeLogo;
          editedRole.voiceFileKey = values.voiceFileKey;
          editedRole.icoUrl = values.icoUrl;
          editedRole.darkColor = values.darkColor;
          editedRole.logoBackground = values.logoBackground;
          editedRole.darkLogoBackground = values.darkLogoBackground;
          editedRole.goals = values.goals;
          editedRole.disclaimer = values.disclaimer;
          editedRole.quickQuestions = values.quickQuestions;
          editedRole.allowFeedback = values.allowFeedback;
          editedRole.maxImagesGenerated = values.maxImagesGenerated;
          editedRole.baseImageGenerationModel = values.baseImageGenerationModel;
          editedRole.agentsPool = values.agentsPool;
          editedRole.maxPlanningSteps = values.maxPlanningSteps;
          editedRole.plannerExecutionAttempts = values.plannerExecutionAttempts;
          editedRole.plannerRequiresApprovalForPlanExecution =
            values.plannerRequiresApprovalForPlanExecution;
          editedRole.planningInstructions = values.planningInstructions;
          editedRole.reviewInstructions = values.reviewInstructions;
          editedRole.preventWidgetUsage = values.preventWidgetUsage;
          editedRole.color = values.color;
          editedRole.placeholderInputMessage = values.placeholderInputMessage;
          editedRole.placeholderMessageOnLoading =
            values.placeholderMessageOnLoading;
          editedRole.messageOnLaunch = values.messageOnLaunch;
          editedRole.pertinencePassage = values.pertinencePassage;
          editedRole.subject = values.subject;
          editedRole.charting = values.charting;
          editedRole.hasTopicsContext = values.hasTopicsContext;
          editedRole.mode = values.mode;
          editedRole.topK = values.topK;
          editedRole.maxTokens = values.maxTokens;
          editedRole.showAgentName = values.showAgentName;
          editedRole.maxTopics = values.maxTopics;
          editedRole.topicEnhancer = values.topicEnhancer;
          editedRole.allowedTopics = JSON.stringify(values.allowedTopics);
          editedRole.summarisation = values.summarisation;
          editedRole.compressor = values.compressor;
          editedRole.icon = values.icon;
          editedRole.docTopK = values.docTopK;
          editedRole.llmProvider = values.llmProvider;
          editedRole.llmBaseModel = values.llmBaseModel;
          editedRole.functionCallingProvider = values.functionCallingProvider;
          editedRole.functionCallingModel = values.functionCallingModel;
          editedRole.recencyImportance = values.recencyImportance;
          editedRole.maxRecencyIncrease = values.maxRecencyIncrease;
          editedRole.recencyTopKIncreaseFactor =
            values.recencyTopKIncreaseFactor;
          editedRole.keywordsWeightInRetrieval =
            values.keywordsWeightInRetrieval || 0;
          editedRole.extendedOutput = values.extendedOutput;
          editedRole.maxHistory = values.maxHistory;
          editedRole.hasFunctions = values.hasFunctions;
          editedRole.hasImages = values.hasImages;
          editedRole.hasCode = values.hasCode;
          editedRole.allowImagesUpload = values.allowImagesUpload;
          editedRole.allowDeepInternetSearch = values.allowDeepInternetSearch;
          editedRole.internetSearchModes = values.internetSearchModes?.map(
            (u) => u?.value
          );
          editedRole.internetSearchLocation = values.internetSearchLocation;
          editedRole.allowInternetSearch = values.allowInternetSearch;
          editedRole.allowImagesGeneration = values.allowImagesGeneration;
          editedRole.bannedDomains = values.bannedDomains;
          editedRole.maxUrlsForInternetSearch =
            values.maxUrlsForInternetSearch || 5;
          editedRole.allowAudiosUpload = values.allowAudiosUpload;
          editedRole.allowDocsUpload = values.allowDocsUpload;
          editedRole.allowVideosUpload = values.allowVideosUpload;
          editedRole.hasNER = values.hasNER;
          editedRole.showReferencesToInternalDocs =
            values.showReferencesToInternalDocs;
          editedRole.promptTopKeywords = values.promptTopKeywords;
          editedRole.splashTitle = values.splashTitle;
          editedRole.showSplashWhenEmpty = values.showSplashWhenEmpty;
          editedRole.keyWordsForKnowledgeBase = values.keyWordsForKnowledgeBase;
          editedRole.advancedLanguageDetection =
            values.advancedLanguageDetection;
          editedRole.agentFunctions = values.agentFunctions?.map(
            (item) => item.id
          );
          editedRole.plainTextOutput = values.plainTextOutput;
          editedRole.generateExtendedReport = values.generateExtendedReport;
          editedRole.minRetrievalScore = values.minRetrievalScore;
          editedRole.hasModeration = values.hasModeration;
          editedRole.customModerations = values.customModerations;
          editedRole.showTimeForResponse = values.showTimeForResponse;
          editedRole.customCodeExecutionEnvironments =
            values.customCodeExecutionEnvironments?.map((item) => item.id);
          editedRole.showDetectedLanguage = values.showDetectedLanguage;
          editedRole.isMultiLanguageEnabled = values.isMultiLanguageEnabled;
          editedRole.restrictedAccess = values.restrictedAccess;
          editedRole.phoneNumberAsSender = values.phoneNumberAsSender;
          editedRole.channelCallbackDelay = values.channelCallbackDelay;
          editedRole.whatsappNumberAsSender = values.whatsappNumberAsSender;
          editedRole.emailAsSender = values.emailAsSender;
          editedRole.allowAudiosGeneration = values.allowAudiosGeneration;
          editedRole.autoVoiceMode = values.autoVoiceMode;
          editedRole.communicationServices = values.communicationServices?.map(
            (item) => item.id
          );
          editedRole.restrictedAccessUsers = values.restrictedAccessUsers?.map(
            (item) => item.id
          );
        }
        const editResult = await Agents.updateAgent(editedRole.id, [
          {
            property: "label",
            value: values.label,
          },
          {
            property: "interpolationString",
            value: values.interpolationString,
          },
          {
            property: "customToolingInstructions",
            value: values.customToolingInstructions,
          },
          {
            property: "description",
            value: values.description,
          },
          {
            property: "defaultAnswer",
            value: values.defaultAnswer,
          },
          {
            property: "inhibitionPassage",
            value: values.inhibitionPassage,
          },
          {
            property: "qaUrl",
            value: values.qaUrl,
          },
          {
            property: "temperature",
            value: values.temperature,
          },
          {
            property: "icon",
            value: values.icon,
          },
          {
            property: "blender",
            value: values.blender,
          },
          {
            property: "largeLogo",
            value: values.largeLogo,
          },
          {
            property: "voiceFileKey",
            value: values.voiceFileKey,
          },
          {
            property: "icoUrl",
            value: values.icoUrl,
          },
          {
            property: "darkColor",
            value: values.darkColor,
          },
          {
            property: "logoBackground",
            value: values.logoBackground,
          },
          {
            property: "darkLogoBackground",
            value: values.darkLogoBackground,
          },
          {
            property: "goals",
            value: values.goals,
          },
          {
            property: "disclaimer",
            value: values.disclaimer,
          },
          {
            property: "quickQuestions",
            value: values.quickQuestions,
          },
          {
            property: "allowFeedback",
            value: values.allowFeedback,
          },
          {
            property: "agentsPool",
            value: values.agentsPool,
          },
          {
            property: "maxPlanningSteps",
            value: values.maxPlanningSteps,
          },
          {
            property: "plannerExecutionAttempts",
            value: values.plannerExecutionAttempts,
          },
          {
            property: "plannerRequiresApprovalForPlanExecution",
            value: values.plannerRequiresApprovalForPlanExecution,
          },
          {
            property: "maxImagesGenerated",
            value: values.maxImagesGenerated,
          },
          {
            property: "baseImageGenerationModel",
            value: values.baseImageGenerationModel,
          },
          {
            property: "planningInstructions",
            value: values.planningInstructions,
          },
          {
            property: "reviewInstructions",
            value: values.reviewInstructions,
          },
          {
            property: "preventWidgetUsage",
            value: values.preventWidgetUsage,
          },
          {
            property: "color",
            value: values.color,
          },
          {
            property: "isMultiLanguageEnabled",
            value: values.isMultiLanguageEnabled,
          },
          {
            property: "placeholderInputMessage",
            value: values.placeholderInputMessage,
          },
          {
            property: "placeholderMessageOnLoading",
            value: values.placeholderMessageOnLoading,
          },
          {
            property: "messageOnLaunch",
            value: values.messageOnLaunch,
          },
          {
            property: "pertinencePassage",
            value: values.pertinencePassage,
          },
          {
            property: "subject",
            value: values.subject,
          },
          {
            property: "charting",
            value: values.charting,
          },
          {
            property: "hasTopicsContext",
            value: values.hasTopicsContext,
          },
          {
            property: "mode",
            value: values.mode,
          },
          {
            property: "topK",
            value: values.topK,
          },
          {
            property: "maxTokens",
            value: values.maxTokens,
          },
          {
            property: "showAgentName",
            value: values.showAgentName,
          },
          {
            property: "maxTopics",
            value: values.maxTopics,
          },
          {
            property: "topicEnhancer",
            value: values.topicEnhancer,
          },
          {
            property: "allowedTopics",
            value: JSON.stringify(values.allowedTopics),
          },
          {
            property: "summarisation",
            value: values.summarisation,
          },
          {
            property: "allowAudiosGeneration",
            value: values.allowAudiosGeneration,
          },
          {
            property: "autoVoiceMode",
            value: values.autoVoiceMode,
          },

          {
            property: "compressor",
            value: values.compressor,
          },
          {
            property: "docTopK",
            value: values.docTopK,
          },
          {
            property: "llmProvider",
            value: values.llmProvider,
          },
          {
            property: "llmBaseModel",
            value: values.llmBaseModel,
          },
          {
            property: "functionCallingProvider",
            value: values.functionCallingProvider,
          },
          {
            property: "functionCallingModel",
            value: values.functionCallingModel,
          },
          {
            property: "recencyImportance",
            value: values.recencyImportance,
          },
          {
            property: "maxRecencyIncrease",
            value: values.maxRecencyIncrease,
          },
          {
            property: "recencyTopKIncreaseFactor",
            value: values.recencyTopKIncreaseFactor,
          },
          {
            property: "keywordsWeightInRetrieval",
            value: values.keywordsWeightInRetrieval,
          },
          {
            property: "extendedOutput",
            value: values.extendedOutput,
          },
          {
            property: "maxHistory",
            value: values.maxHistory,
          },
          {
            property: "workspaceID",
            value: getActiveWorkspace()?.id,
          },
          {
            property: "hasFunctions",
            value: values.hasFunctions,
          },
          {
            property: "hasImages",
            value: values.hasImages,
          },
          {
            property: "hasCode",
            value: values.hasCode,
          },
          {
            property: "allowImagesUpload",
            value: values.allowImagesUpload,
          },

          {
            property: "allowInternetSearch",
            value: values.allowInternetSearch,
          },
          {
            property: "allowImagesGeneration",
            value: values.allowImagesGeneration,
          },
          {
            property: "allowDeepInternetSearch",
            value: values.allowDeepInternetSearch,
          },

          {
            property: "internetSearchLocation",
            value: values.internetSearchLocation,
          },
          {
            property: "internetSearchModes",
            value: values.internetSearchModes?.map((u) => u?.value),
          },
          {
            property: "bannedDomains",
            value: values.bannedDomains,
          },
          {
            property: "maxUrlsForInternetSearch",
            value: values.maxUrlsForInternetSearch,
          },
          {
            property: "allowAudiosUpload",
            value: values.allowAudiosUpload,
          },
          {
            property: "allowDocsUpload",
            value: values.allowDocsUpload,
          },
          {
            property: "allowVideosUpload",
            value: values.allowVideosUpload,
          },
          {
            property: "hasNER",
            value: values.hasNER,
          },
          {
            property: "showReferencesToInternalDocs",
            value: values.showReferencesToInternalDocs,
          },
          {
            property: "advancedLanguageDetection",
            value: values.advancedLanguageDetection,
          },
          {
            property: "restrictedAccess",
            value: values.restrictedAccess,
          },
          {
            property: "restrictedAccessUsers",
            value: values.restrictedAccessUsers?.map((item) => item.id),
          },
          {
            property: "promptTopKeywords",
            value: values.promptTopKeywords,
          },
          {
            property: "splashTitle",
            value: values.splashTitle,
          },
          {
            property: "showSplashWhenEmpty",
            value: values.showSplashWhenEmpty,
          },
          {
            property: "keyWordsForKnowledgeBase",
            value: values.keyWordsForKnowledgeBase,
          },
          {
            property: "agentFunctions",
            value: values.agentFunctions?.map((item) => item.id),
          },
          {
            property: "plainTextOutput",
            value: values.plainTextOutput,
          },
          {
            property: "generateExtendedReport",
            value: values.generateExtendedReport,
          },
          {
            property: "minRetrievalScore",
            value: values.minRetrievalScore,
          },
          {
            property: "hasModeration",
            value: values.hasModeration,
          },
          {
            property: "customModerations",
            value: values.customModerations,
          },
          {
            property: "showTimeForResponse",
            value: values.showTimeForResponse,
          },
          {
            property: "customCodeExecutionEnvironments",
            value: values.customCodeExecutionEnvironments?.map((u) => u?.id),
          },
          {
            property: "showDetectedLanguage",
            value: values.showDetectedLanguage,
          },

          {
            property: "communicationServices",
            value: values.communicationServices?.map((item) => item.id),
          },
          {
            property: "phoneNumberAsSender",
            value: values.phoneNumberAsSender,
          },
          {
            property: "channelCallbackDelay",
            value: values.channelCallbackDelay,
          },
          {
            property: "whatsappNumberAsSender",
            value: values.whatsappNumberAsSender,
          },
          {
            property: "emailAsSender",
            value: values.emailAsSender,
          },
        ]);
        const newAgentsArray = [...state?.agents];
        newAgentsArray[selectedIndex] = editResult;
        dispatch("SET_AGENTS", newAgentsArray);
      } else {
        let newRole = {
          type: createUniqueCode(generateRowId()),
          label: values.label,
          temperature: parseFloat(values.temperature),
          blender: values.blender,
          largeLogo: values.largeLogo,
          voiceFileKey: values.voiceFileKey,
          icoUrl: values.icoUrl,
          darkColor: values.darkColor,
          logoBackground: values.logoBackground,
          darkLogoBackground: values.darkLogoBackground,
          qaUrl: values.qaUrl,
          inhibitionPassage: values.inhibitionPassage,
          defaultAnswer: values.defaultAnswer,
          description: values.description,
          interpolationString: values.interpolationString || "",
          customToolingInstructions: values.customToolingInstructions || "",
          goals: values.goals,
          disclaimer: values.disclaimer,
          quickQuestions: values.quickQuestions,
          allowFeedback: values.allowFeedback,
          agentsPool: values.agentsPool,
          maxPlanningSteps: values.maxPlanningSteps,
          plannerExecutionAttempts: values.plannerExecutionAttempts,
          planningInstructions: values.planningInstructions,
          reviewInstructions: values.reviewInstructions,
          plannerRequiresApprovalForPlanExecution:
            values.plannerRequiresApprovalForPlanExecution,
          preventWidgetUsage: values.preventWidgetUsage,
          color: values.color,
          placeholderInputMessage: values.placeholderInputMessage,
          placeholderMessageOnLoading: values.placeholderMessageOnLoading,
          messageOnLaunch: values.messageOnLaunch,
          hasMemory: values.hasMemory,
          charting: values.charting,
          blender: values.blender,
          largeLogo: values.largeLogo,
          icoUrl: values.icoUrl,
          hasTopicsContext: values.hasTopicsContext,
          pertinencePassage: values.pertinencePassage,
          advancedLanguageDetection: values.advancedLanguageDetection,
          splashTitle: values.splashTitle,
          showSplashWhenEmpty: values.showSplashWhenEmpty,
          promptTopKeywords: values.promptTopKeywords,
          keyWordsForKnowledgeBase: values.keyWordsForKnowledgeBase,
          subject: values.subject,
          mode: values.mode,
          maxTokens: values.maxTokens,
          showAgentName: values.showAgentName,
          maxTopics: values.maxTopics,
          topicEnhancer: values.topicEnhancer,
          isMultiLanguageEnabled: values.isMultiLanguageEnabled,
          restrictedAccess: values.restrictedAccess,
          restrictedAccessUsers: values.restrictedAccessUsers?.map(
            (item) => item.id
          ),
          allowedTopics: JSON.stringify(values.allowedTopics),
          topK: values.topK,
          summarisation: values.summarisation,
          maxHistory: values.maxHistory,
          compressor: values.compressor,
          docTopK: values.docTopK,
          llmProvider: values.llmProvider,
          llmBaseModel: values.llmBaseModel,
          functionCallingProvider: values.functionCallingProvider,
          functionCallingModel: values.functionCallingModel,
          recencyImportance: values.recencyImportance,
          maxRecencyIncrease: values.maxRecencyIncrease,
          recencyTopKIncreaseFactor: values.recencyTopKIncreaseFactor,
          keywordsWeightInRetrieval: values.keywordsWeightInRetrieval || 0,
          extendedOutput: values.extendedOutput,
          workspaceID: getActiveWorkspace()?.id,
          hasImages: values.hasImages,
          hasCode: values.hasCode,
          allowImagesUpload: values.allowImagesUpload,
          allowAudiosUpload: values.allowAudiosUpload,
          allowDocsUpload: values.allowDocsUpload,
          allowVideosUpload: values.allowVideosUpload,
          allowInternetSearch: values.allowInternetSearch,
          allowImagesGeneration: values.allowImagesGeneration,
          allowDeepInternetSearch: values.allowDeepInternetSearch,
          internetSearchModes: values.internetSearchModes?.map((u) => u?.value),
          internetSearchLocation: values.internetSearchLocation,
          bannedDomains: values.bannedDomains,
          maxUrlsForInternetSearch: values.maxUrlsForInternetSearch || 5,
          hasNER: values.hasNER,
          showReferencesToInternalDocs: values.showReferencesToInternalDocs,
          hasFunctions: values.hasFunctions,
          agentFunctions: values.agentFunctions?.map((item) => item.id),
          agentType: values.agentType,
          plainTextOutput: values.plainTextOutput,
          generateExtendedReport: values.generateExtendedReport,
          minRetrievalScore: values.minRetrievalScore,
          hasModeration: values.hasModeration,
          customModerations: values.customModerations,
          showTimeForResponse: values.showTimeForResponse,
          customCodeExecutionEnvironments:
            values.customCodeExecutionEnvironments?.map((item) => item.id),
          showDetectedLanguage: values.showDetectedLanguage,
          treeOfThoughts: values.treeOfThoughts,
          isGlobal: false,
          icon: values.icon,
          allowAudiosGeneration: values.allowAudiosGeneration,
          autoVoiceMode: values.autoVoiceMode,
          phoneNumberAsSender: values.phoneNumberAsSender,
          channelCallbackDelay: values.channelCallbackDelay,
          whatsappNumberAsSender: values.whatsappNumberAsSender,
          emailAsSender: values.emailAsSender,
          communicationServices: values.communicationServices?.map(
            (item) => item.id
          ),
        };
        const creationResult = await Agents.createAgent(newRole);
        const newAgentsArray = [...state?.agents];
        newAgentsArray.push(creationResult);
        dispatch("SET_AGENTS", newAgentsArray);
        setShowQaRoleModal(false);
      }
      AppToasty.show(
        t(isEditMode ? "qaUpdateSuccessCaption" : "qaCreationSuccessCaption"),
        {
          type: "success",
        }
      );
      setNewRefreshTimestamp(Date.now().toString());
      // setShowQaRoleModal(false);
    } catch (error) {
      AppToasty.show(t("qaCreationErrorCaption"), {
        type: "danger",
      });
      console.log("error", error);
    } finally {
      // formRef?.current?.resetForm();
      // setShowQaRoleModal(false);
      // setSelectedIndex(null);
    }
  };
  const CustomQaConfigView = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          height: "95%",
          maxWidth: 700,
          width: isMobileScreen() ? "99%" : "90%",
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        key={selectedIndex}
        isModalOpen={showQaRoleModal}
        setIsModalOpen={setShowQaRoleModal}
        modalTitle={`${
          isEditMode ? "Edit Agent" : "Create Agent"
        } - ${modalTitle}`}
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowQaRoleModal(false);
          setSelectedIndex(null);
          setAgentTypeToCreate(null);
        }}
      >
        {availableFunctionsLoaded ? (
          <QaSettingsAI
            availableFunctions={availableFunctions}
            customCodeExecutionEnvironments={customCodeExecutionEnvironments}
            agentsChannels={agentsChannels}
            displayedRoles={displayedRoles}
            initialMapValues={initialMapValues}
            handleGenerationItemSave={handleGenerationItemSave}
            isEdit={isEditMode}
            selectedId={
              displayedRoles &&
              displayedRoles[selectedIndex] &&
              displayedRoles[selectedIndex]?.id
            }
            isChatBot={
              (displayedRoles[selectedIndex]?.agentType !== "ContentCreator" &&
                displayedRoles[selectedIndex]?.agentType !==
                  "BusinessAnalyst" &&
                !agentTypeToCreate) ||
              agentTypeToCreate === "Chatbot"
            }
            selectedIndex={selectedIndex}
            setShowQaRoleModal={setShowQaRoleModal}
            ref={formRef}
            isBusinessAnalyst={
              displayedRoles[selectedIndex]?.agentType === "BusinessAnalyst" ||
              agentTypeToCreate === "BusinessAnalyst"
            }
            isContentCreator={
              displayedRoles[selectedIndex]?.agentType === "ContentCreator" ||
              agentTypeToCreate === "ContentCreator"
            }
          />
        ) : (
          <ToothFairyLoadingPlaceholder caption="Loading agent..." />
        )}
      </ToothFairyModal>
    );
  });
  const loadAvailableFunctions = async () => {
    try {
      const result = await AgentFunctions.getAgentFunctionsByWorkspaceID({
        workspaceID: getActiveWorkspace()?.id,
      });
      let functions = result?.data?.functionsByWorkspace?.items;
      functions =
        functions
          ?.map((u) => {
            if (u?._deleted !== true)
              return {
                ...u,
              };
          })
          ?.filter((u) => u != null) || [];
      setAvailableFunctions(functions);
      setAvailableFunctionsLoaded(true);
      return functions;
    } catch {
      setAvailableFunctionsLoaded(false);
      return [];
    }
  };
  const loadCustomEnvironments = async () => {
    try {
      const result = await execEnvsHelper.getCustomCodeExecutionEnvironments(
        getActiveWorkspace()?.id
      );
      let functions =
        result?.data?.customCodeExecutionEnvironmentsByWorkspace?.items;
      functions =
        functions
          ?.map((u) => {
            if (u?._deleted !== true)
              return {
                ...u,
              };
          })
          ?.filter((u) => u != null) || [];
      setCustomCodeExecutionEnvironments(functions);
      // setAvailableFunctionsLoaded(true);
      return functions;
    } catch {
      // setAvailableFunctionsLoaded(false);
      return [];
    }
  };
  const loadChannels = async () => {
    try {
      const result = await channelsHelper.getChannels(getActiveWorkspace()?.id);
      let functions = result?.data?.CommunicationServicesByWorkspace?.items;
      functions =
        functions
          ?.map((u) => {
            if (u?._deleted !== true)
              return {
                ...u,
              };
          })
          ?.filter((u) => u != null) || [];
      setAgentsChannels(functions);
      // setAvailableFunctionsLoaded(true);
      return functions;
    } catch {
      // setAvailableFunctionsLoaded(false);
      return [];
    }
  };
  useEffect(() => {
    if (state?.agents) {
      setDisplayedRoles(state?.agents);
    }
  }, [JSON.stringify(state?.agents)]);

  useEffect(() => {
    if (getActiveWorkspace()?.id) {
      loadAvailableFunctions();
      loadCustomEnvironments();
      loadChannels();
    }
  }, [getActiveWorkspace()?.id]);

  const handleRoleItemSelection = async ({ item, index }) => {
    let functions = [];
    let envs = [];
    if (!availableFunctionsLoaded) {
      functions = await loadAvailableFunctions();
    } else {
      functions = availableFunctions;
    }
    envs = await loadCustomEnvironments();
    channels = await loadChannels();
    if (
      item?.bannedDomains != null &&
      Array.isArray(item?.bannedDomains) &&
      item?.bannedDomains.length > 0
    ) {
      item.bannedDomains = item.bannedDomains.join(",");
    } else {
      item.bannedDomains = "";
    }
    setSelectedIndex(state?.agents?.findIndex((u) => u?.id === item?.id));
    setIsEditMode(true);
    const enhancedItem = state?.agents?.find((u) => u.id === item.id);
    setModalTitle(enhancedItem?.label);
    const enhancedFunctions = enhancedItem?.agentFunctions?.map((item) => {
      const _item = functions?.find((u) => u.id === item);
      if (!_item) return;
      return {
        ..._item,
        label: _item?.name,
        value: _item?.id,
        backgroundColor: currentTheme?.theme?.primary,
      };
    });

    const enhancedEnvs = enhancedItem?.customCodeExecutionEnvironments?.map(
      (item) => {
        const _item = envs?.find((u) => u.id === item);
        if (!_item) return;
        return {
          ..._item,
          label: _item?.name,
          value: _item?.id,
          backgroundColor: currentTheme?.theme?.primary,
        };
      }
    );

    const enhancedChannels = enhancedItem?.communicationServices?.map(
      (item) => {
        const _item = channels?.find((u) => u.id === item);
        if (!_item) return;
        return {
          ..._item,
          label: _item?.name,
          value: _item?.id,
          backgroundColor: currentTheme?.theme?.primary,
        };
      }
    );

    const enhancedRestricedUsers = enhancedItem?.restrictedAccessUsers?.map(
      (item) => {
        const _item = getWorkspaceUsers()?.find((u) => u.id === item);
        if (!_item) return;
        return {
          ..._item,
          label: _item?.username || _item?.user_email,
          value: _item?.id,
          backgroundColor: AppUtilities.stringToHslColor(
            _item?.username || _item?.user_email,
            71
          ),
        };
      }
    );
    setInitialMapValues({
      ...enhancedItem,
      agentFunctions: enhancedFunctions
        ?.filter((u) => u != null)
        ?.map((u) => {
          return {
            ...u,
            label: u?.name + " - " + Utility?.mapFunctionTypeToLabel(u),
            value: u?.id,
            backgroundColor: Utility?.mapFunctionTypeToColour(u, currentTheme),
          };
        }),
      agentsPool: enhancedItem?.agentsPool?.map((item) => {
        const _item = state?.agents?.find((u) => u.id === item);
        if (!_item) return;
        return {
          ..._item,
          label: _item?.label,
          value: _item?.id,
          backgroundColor: currentTheme?.theme?.primary,
        };
      }),
      customCodeExecutionEnvironments: enhancedEnvs
        ?.filter((u) => u != null)
        ?.map((u) => {
          return {
            ...u,
            label: u?.name + " - " + Utility?.mapFunctionTypeToLabel("chat"),
            value: u?.id,
            backgroundColor: Utility?.mapFunctionTypeToColour(u, currentTheme),
          };
        }),
      communicationServices: enhancedChannels
        ?.filter((u) => u != null)
        ?.map((u) => {
          return {
            ...u,
            label: u?.name + " - " + Utility?.mapFunctionTypeToLabel("chat"),
            value: u?.id,
            backgroundColor: Utility?.mapFunctionTypeToColour(u, currentTheme),
          };
        }),
      restrictedAccessUsers: enhancedRestricedUsers?.filter((u) => u != null),
      isMultiLanguageEnabled: enhancedItem?.isMultiLanguageEnabled != false,
      plannerRequiresApprovalForPlanExecution:
        enhancedItem?.plannerRequiresApprovalForPlanExecution != false,
      recencyImportance: enhancedItem?.recencyImportance || 0,
      maxUrlsForInternetSearch: enhancedItem?.maxUrlsForInternetSearch || 5,
      keywordsWeightInRetrieval: enhancedItem?.keywordsWeightInRetrieval || 0,
      maxImagesGenerated: enhancedItem?.maxImagesGenerated || 4,
      channelCallbackDelay: enhancedItem?.channelCallbackDelay || 0,
      maxPlanningSteps: enhancedItem?.maxPlanningSteps || 5,
      plannerExecutionAttempts: enhancedItem?.plannerExecutionAttempts || 2,
      internetSearchModes:
        enhancedItem?.internetSearchModes?.map((u) => ({
          label: u,
          value: u,
          backgroundColor: generateColourForSearchModes(u),
        })) || [],
    });
    setShowQaRoleModal(true);
  };
  const handleRoleItemeDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        // delete item from the list at the index of the selected item and create a new array
        const roleToDelete = displayedRoles[selectedIndex];
        const deletionResult = await Agents.deleteAgent(roleToDelete?.id);
        const newAgents = state?.agents?.filter(
          (agent) => agent.id !== roleToDelete?.id
        );
        dispatch("SET_AGENTS", [...newAgents]);

        AppToasty.show(t("qaDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      console.log("error", error);
      AppToasty.show(t("qaDeletionFailedCaption"), {
        type: "danger",
      });
    }
  };
  const handleCreation = (id, label) => {
    setIsEditMode(false);
    setShowQaRoleModal(true);
    setModalTitle(label);
    setAgentTypeToCreate(id);
    const baseAgentConfig = baseAgents?.find((u) => {
      if (id == "Chatbot") return u?.mode == "retriever";
      else {
        return u?.agentType == id;
      }
    });
    setInitialMapValues({
      ...baseAgentConfig,
      preventWidgetUsage: true,
      label: "New agent",
    });
  };
  const handleSearch = async (qaUrl, label, interpolationString) => {
    const filteredItems = state?.agents?.filter((item) => {
      return (
        item?.label?.toLowerCase()?.includes(label?.toLowerCase()) &&
        item?.interpolationString
          ?.toLowerCase()
          ?.includes(interpolationString?.toLowerCase())
      );
    });

    setDisplayedRoles(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"qaItemCancelConfirmation"}
        caption="qaItemCancelCaption"
        subCaption="qaItemCancelSubCaption"
        confirmCaption={"qaItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={handleRoleItemeDeletion}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [
    setShowChoice,
    showChoice,
    selectedItem,
    setSelectedItem,
    selectedIndex,
    handleRoleItemeDeletion,
  ]);

  const isEnabledToOperate = (item) => {
    if (
      (state?.agents?.find((u) => u?.id == item?.id)?.agentType ==
        "ContentCreator" &&
        !getPlanConfig()?.isContentCreatorEnabled) ||
      (state?.agents?.find((u) => u?.id == item?.id)?.agentType ==
        "BusinessAnalyst" &&
        !getPlanConfig()?.isBusinessAnalystEnabled)
    ) {
      AppToasty.show("You are not enabled to modify/delete this base agent", {
        type: "danger",
      });
      return false;
    } else return true;
  };
  const shortenName = (agentType) => {
    if (agentType == "ContentCreator") return "CC";
    else if (agentType == "BusinessAnalyst") return "BA";
    else if (agentType == "Planner") return "PL";
    else return "CH";
  };
  return (
    <View
      style={{
        minWidth: "100%",
        flex: 1,
        width: width - (showSideMenu() ? 400 : 220),
        paddingLeft: 10,
      }}
    >
      <QaConfigToolbar
        handleSearch={handleSearch}
        handleCreation={handleCreation}
        options={_options}
      />
      {isQaRolesLoading ? (
        <AppActivityIndicator
          backgroundColor={currentTheme?.theme?.softBackgroundColor}
          visible={true}
          containerStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
            minHeight: 250,
          }}
        />
      ) : (
        <>
          {displayedRoles?.length > 0 ? (
            <AppTable
              isItemDeletable={(item) => {
                _item = state?.items;
                return true;
              }}
              isItemEditable={(item) => {
                return true;
              }}
              isItemActionable={(item) => {
                return true;
              }}
              marginRightBeforeActions={30}
              actionButtons
              handleDocumentSelection={(item) => {
                if (isEnabledToOperate(item)) {
                  history.push(
                    `/workspaces/${
                      getActiveWorkspace()?.id
                    }/settings/roles_settings/${item.id}`
                  );
                  handleRoleItemSelection(item);
                }
              }}
              handleDocumentDeletion={(item, index) => {
                if (isEnabledToOperate(item)) {
                  setSelectedItem(item);
                  setSelectedIndex(index);
                  setShowChoice(true);
                }
              }}
              mainActionLabel="View"
              showDropDownMenuForActions
              onClickMainAction={(item, index) => {
                if (isEnabledToOperate(item)) {
                  history.push(
                    `/workspaces/${
                      getActiveWorkspace()?.id
                    }/settings/roles_settings/${item.id}`
                  );
                  handleRoleItemSelection(item);
                }
              }}
              actionButtonStyle={{
                minWidth: 50,
              }}
              onActionItemPress={(id, item, index) => {
                if (id === "EDIT") {
                  if (isEnabledToOperate(item)) {
                    history.push(
                      `/workspaces/${
                        getActiveWorkspace()?.id
                      }/settings/roles_settings/${item.id}`
                    );
                    handleRoleItemSelection(item);
                  }
                } else if (id === "DELETE") {
                  if (isEnabledToOperate(item)) {
                    setSelectedItem(item);
                    setSelectedIndex(index);
                    setShowChoice(true);
                  }
                } else if (id === "DUPLICATE") {
                  if (isEnabledToOperate(item)) {
                    handleDuplication(item?.id);
                  }
                }
              }}
              actions={[
                {
                  label: "Edit",
                  id: "EDIT",
                  icon: AppAssets.icons.edit,
                },
                {
                  label: "Duplicate",
                  id: "DUPLICATE",
                  icon: AppAssets.icons.duplicate,
                },
                {
                  label: "Delete",
                  id: "DELETE",
                  icon: AppAssets.icons.delete,
                },
              ]}
              style={{
                zIndex: -1,
                height: height - 200,
                paddingRight: 10,
                overflowY: "auto",
                overflowX: "hidden",
              }}
              headers={columns}
              items={displayedRoles
                ?.slice(
                  (currentPage - 1) * pageSize,
                  (currentPage - 1) * pageSize + pageSize
                )
                ?.map((u) => {
                  if (
                    !u?.isGlobal ||
                    (u?.isGlobal &&
                      getPlanConfig()?.isBusinessAnalystEnabled &&
                      u?.agentType == "BusinessAnalyst") ||
                    (getPlanConfig()?.isContentCreatorEnabled &&
                      u?.agentType == "ContentCreator") ||
                    u?.agentType == "Chatbot"
                  )
                    return {
                      id: u.id,
                      label: u.label,
                      interpolationString: u?.subject || "No domain specified",
                      agentType: minimisedTable
                        ? shortenName(u.agentType)
                        : generateMeanigfulAgentName(u),
                      actions: " ",
                    };
                })}
            />
          ) : (
            <AppPlaceholderView
              wrapperStyle={{
                width: "100%",
                height: "100%",
                marginVertical: 20,
                marginHorizontal: "auto",
                flex: 1,
                zIndex: -1,
              }}
              size={80}
              icon={AppAssets.icons.orbit}
              mainCaption="EmptyPlaceholder"
              subCaption="QaSubCaption"
            />
          )}
          {displayedRoles?.length > pageSize && (
            <AppPagination
              onPageChange={handlePageChange}
              totalCount={displayedRoles?.length}
              siblingCount={1}
              currentPage={currentPage}
              pageSize={pageSize}
              wrapperStyle={{
                marginTop: 10,
                alignSelf: "flex-end",
              }}
            />
          )}
        </>
      )}
      {CustomQaConfigView}
      {_coreChoice}
    </View>
  );
};

export default QaConfigList;
