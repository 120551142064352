import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React from "react";
import { useTranslation } from "react-i18next";
import TopicsSelectionComponent from "../../Screens/MrcDocuments/Components/TopicsSelectionComponent";
import ToothFairyButton from "./ToothFairyButton";
import ToothFairySwitch from "./ToothFairySwitch";

const ToothFairySaveOutput = ({
  setIsSaveOutputModalOpen,
  handleCreationOfDocumentFromQaOutput,
  topics,
  topicLabelOption = "name",
}) => {
  const { currentTheme } = AppTheme.useTheme();

  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const [isRedirectRequired, setIsRedirectRequired] = React.useState(false);
  const [isInclusionOfPassageRequired, setIsInclusionOfPassageRequired] =
    React.useState(true);
  const [documentTitle, setDocumentTitle] = React.useState("");
  const [selectedTopics, setSelectedTopics] = React.useState([]);
  const { state } = AppStateManager.useAppStateManager();
  let counter = 0;
  return (
    <AppChoice
      wrapperStyle={{
        alignSelf: "center",
        maxWidth: 500,
        width: "95%",
        justifyContent: "space-between",
      }}
      caption={`You are about to save the document generated`}
      subCaption="Just a few more details and you are good to go"
      cancelCaption="Click 'Cancel' to abort"
      confirmCaption="Click 'Confirm' to save the document"
      onConfirmButtonPress={async () => {
        counter++;
        if (counter > 1) {
          return;
        }
        if (!documentTitle || documentTitle?.trim().length === 0) {
          return AppToasty.show("Please enter a document title", {
            type: "danger",
          });
        }
        if (selectedTopics.length === 0) {
          return AppToasty.show("Please select at least one topic", {
            type: "danger",
          });
        }

        try {
          await handleCreationOfDocumentFromQaOutput({
            documentTitle,
            selectedTopics,
            isInclusionOfPassageRequired,
            isRedirectRequired,
          });
        } catch (error) {
          console.error(error);
          // Handle any errors here
        } finally {
          setIsSaveOutputModalOpen(false);
          setDocumentTitle("");
          setSelectedTopics([]);
          setIsRedirectRequired(false);
          setIsInclusionOfPassageRequired(true);
          counter = 0;
        }
      }}
      onCancelButtonPress={() => {
        setIsSaveOutputModalOpen(false);
      }}
      AppChoiceButton={ToothFairyButton}
    >
      <AppTextInput
        placeholder="documentTitlePlaceholder"
        style={{
          marginTop: 2,
        }}
        wrapperInputStyle={{
          marginBottom: 20,
          marginTop: 20,
        }}
        textContainer={{
          margin: 0,
        }}
        type="text"
        value={documentTitle}
        onChangeText={(text) => {
          setDocumentTitle(text);
        }}
      />
      <TopicsSelectionComponent
        onSelectedTopicsChange={(v) => {
          setSelectedTopics(v);
        }}
        showEditIcon={false}
        isTopicUpdateModeEnabled={true}
        selectedTopics={selectedTopics}
        topics={topics}
        placeholder="Select topics"
        hidePlaceholder={true}
        uniqueKey="id"
        showDropDowns={true}
        readOnlyHeadings={true}
        labelOption={topicLabelOption}
        groupByProperty="category"
        style={{ maxWidth: 800 }}
        wrapperContainerStyle={{
          flex: 1,
          paddingBottom: 25,
        }}
      />
      <ToothFairySwitch
        wrapperStyle={{
          flex: 1,

          backgroundColor: currentTheme?.theme?.inputBackgroundColor,
          marginTop: 0,
          zIndex: -1,
          marginBottom: 25,
        }}
        label={"Include prompt"}
        onChange={(v) => {
          setIsInclusionOfPassageRequired(v);
        }}
        isTicked={isInclusionOfPassageRequired}
      />
      <ToothFairySwitch
        wrapperStyle={{
          flex: 1,

          backgroundColor: currentTheme?.theme?.inputBackgroundColor,
          marginTop: 0,
          zIndex: -1,
        }}
        label={"Redirect to document"}
        onChange={(v) => {
          setIsRedirectRequired(v);
        }}
        isTicked={isRedirectRequired}
      />
    </AppChoice>
  );
};

export default ToothFairySaveOutput;
