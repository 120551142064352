import React from "react";
import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { View } from "react-native";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const ModelsList = () => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View
      style={{
        minWidth: 300,
      }}
    ></View>
  );
};

export default ModelsList;
