import AppToast from "@toothfairy/shared-ui/AppToast";
import { useCallback, useEffect, useRef, useState } from "react";
import media from "../API/Media";
import useWorkspaces from "../Hooks/useWorkspaces";

const useToothFairyAudioGeneration = (token, workspaceid) => {
  const generateSpeech = (newText, chatid, lastMessageId) => {
    // Assuming the API endpoint and token are available as variables
    const data = {
      workspaceid: workspaceid,
      text: newText,
      chatid: chatid,
      lastMessageId: lastMessageId,
    };

    return media.generateAudio({
      token,
      data,
    });
  };
  return { generateSpeech };
};

export const useTextToSpeech = (replyOneShot = true) => {
  const AppToasty = AppToast.useToast();
  const [text, setText] = useState("");
  const [processedText, setProcessedText] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const audioContext = useRef(
    new (window.AudioContext || window.webkitAudioContext)()
  );

  const { getActiveWorkspace } = useWorkspaces();
  const { generateSpeech } = useToothFairyAudioGeneration(
    getActiveWorkspace()?.workspaceToken,
    getActiveWorkspace()?.id
  );
  const [chatid, setChatid] = useState(null);
  const [lastMessageId, setLastMessageId] = useState(null);

  const listenToAudio = async (presignedUrl) => {
    try {
      const audio = new Audio(presignedUrl);
      document.body.appendChild(audio);
      audioRef.current = audio;
      setIsPlaying(true);
      audioRef.current.play().catch((e) => {
        setIsPlaying(false);
        AppToasty.show("Error playing audio", {
          placement: "bottom",
          type: "danger",
        });
      });
      audio.onended = () => {
        document.body.removeChild(audio);
        setIsPlaying(false);
      };
    } catch (error) {
      console.error("Error fetching audio:", error);
    }
  };

  const updateText = useCallback(
    (newText, chatid, lastMessageId, download = false) => {
      setChatid(chatid);
      setLastMessageId(lastMessageId);
      if (download) downloadAudio(newText);
      else setText(newText);
    },
    []
  );

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const audioRef = useRef(new Audio());
  const audioQueue = useRef([]);

  const [generatingAudio, setGeneratingAudio] = useState(false);
  const handleAuthorizeAndPlay = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // First, try to play a silent audio to get user interaction
      await audioRef.current.play();
      setIsAuthorized(true);
    } catch (err) {
      setError("Failed to authorize or play audio");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleAuthorizeAndPlay();
  }, []);

  useEffect(() => {
    if (text?.length > 1 && replyOneShot && !isProcessing) {
      setIsProcessing(true);
      setProcessedText(text);
      const startTime = Date.now();
      generateSpeech(text, chatid, lastMessageId)
        .then((response) => {
          if (response.data.contents[0].includes("https://"))
            listenToAudio(response.data.contents[0]);
          else {
            AppToasty.show(`Error while generating audio...`, {
              type: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Error generating speech:", error);
          const processingTime = (Date.now() - startTime) / 1000; // Convert to seconds

          if (processingTime >= 28 && processingTime <= 32) {
            AppToasty.show(
              `The audio generation is taking longer than expected, when available you will see the file under the text of the message chosen`,
              {
                type: "warning",
              }
            );
          } else {
            AppToasty.show(`Error while generating audio...`, {
              type: "danger",
            });
          }
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  }, [text, replyOneShot]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const handleEnded = () => setIsPlaying(false);
      audioElement.addEventListener("ended", handleEnded);
      return () => {
        audioElement.removeEventListener("ended", handleEnded);
        setText("");
        setProcessedText("");
        setIsPlaying(false);
        audioElement.pause();
        audioElement.currentTime = 0;
        audioElement.src = "";
      };
    }
  }, []);

  const stopAudio = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
    setText("");
    setProcessedText("");
  };

  const downloadAudio = (text) => {
    if (!isProcessing) {
      setIsProcessing(true);
      generateSpeech(text)
        .then((response) => {
          const audioData = response.data;
          const blob = new Blob([audioData], { type: "audio/mpeg" });
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "speech.mp3";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error generating speech:", error);
          AppToasty.show(`Error while generating audio...`, {
            type: "danger",
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  };
  return {
    updateText,
    currentText: text,
    processedText,
    isSpeechGettingProcessed: isProcessing,
    stopAudio,
    isPlaying,
    setIsPlaying,
    listenToAudio,
  };
};
