import React, { useContext, useEffect, useState } from "react";
import * as Localization from "expo-localization";

export const initialValues = {
  user: {
    userNickname: "",
    userEmail: "",
    userCognitoID: "",
    userType: "",
    isOnboarded: null,
    timezone: "",
  },
  isIncognito: false,
  setIsIncognito: (v) => {},
  updateUser: (u) => {},
};

export const UserContext = React.createContext(initialValues);
// new line custom hook

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ userNickname: "" });
  const [isIncognito, setIsIncognito] = useState(true);
  const updateUser = (u) => {
    setUser(u);
  };
  useEffect(() => {
    setUser({ ...user, timezone: Localization.timezone });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: user,
        isIncognito: isIncognito,
        setIsIncognito: setIsIncognito,
        updateUser: updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const { user, isIncognito, setIsIncognito, updateUser } =
    useContext(UserContext);
  return {
    user,
    isIncognito,
    setIsIncognito,
    updateUser,
  };
};
