import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";

const ChatScriptLeaf = ({ id, fn }) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View
      style={{
        flexDirection: "column",
        borderBottomWidth: 0.5,
        borderBottomColor: currentTheme?.theme?.darkGrey,
        marginVertical: 10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <AppText
          style={{
            fontWeight: "bold",
            textOverflow: "ellipsis",
            maxWidth: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: currentTheme?.theme?.extra_small_font_size,
          }}
          color={currentTheme?.theme?.primary}
          wrapperStyle={{
            marginVertical: 3,
            maxWidth: "80%",
            flex: 1,
          }}
        >
          {fn?.name + " - " + fn?.type}
        </AppText>
        {/* {chunk_id && scoring > 0 && (
              <AppText
                style={{
                  fontSize: currentTheme?.theme?.extra_small_font_size,
                }}
                fontWeight="bold"
              >
                {`Accuracy - ${(scoring * 100)?.toFixed(2)}%`}
              </AppText>
            )} */}
      </View>
      <View>
        <AppText
          style={{
            textOverflow: "ellipsis",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: currentTheme?.theme?.extra_small_font_size,
          }}
          wrapperStyle={{
            marginVertical: 3,
            maxWidth: "100%",
            flex: 1,
          }}
        >
          {`Scenario: ${fn?.description}`}
        </AppText>
        <AppText
          style={{
            textOverflow: "ellipsis",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: currentTheme?.theme?.extra_small_font_size,
          }}
          wrapperStyle={{
            marginVertical: 3,
            maxWidth: "100%",
            flex: 1,
          }}
        >
          {`Response: ${fn?.response}`}
        </AppText>
      </View>
    </View>
  );
};

export default ChatScriptLeaf;
