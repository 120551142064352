import API, { graphqlOperation } from "@aws-amplify/api";
import { useWhisper } from "@chengsokdara/use-whisper";
import useApi from "@toothfairy/shared-api/useApi";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../../shared/src/styles/sharedStyles.css";
import { displayedToasties } from "../../../App";
import envConfig from "../../../envConfig";
import Generator from "../../API/Generator";
import streamHelper from "../../API/Stream/streamHelper";
import trainingAPI from "../../API/Training/trainingAPI";
import useAutoRequest from "../../Hooks/useAutoRequest";
import useAutoSave from "../../Hooks/useAutoSave";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useParams } from "../../Router";
import { onUpdateOutputStreamByIDEvent } from "../../graphql/subscriptions";
import { initialEditorValueForQuestions } from "../TestExample/commonUtils";
import GeneratorScreen from "./GeneratorScreen";
import Files from "../../API/Files";
import * as unified from "unified";
import markdown from "remark-parse";
import slate from "remark-slate";
import Utility, { generateRowId } from "../../Utility";
import Prompts from "../../API/Prompts";
import HumanFeedback from "../../API/HumanFeedback";
import useRecording from "../../Hooks/useRecording";

const GeneratorScreenContainer = () => {
  const { documentId, type } = useParams();
  const [streamid, setStreamid] = useState(null);
  const [streamStatus, setStreamStatus] = useState(null);
  const [streamObject, setStreamObject] = useState(null);
  const [isTrainerNoDocumentId, setIsTrainerNoDocumentId] = useState(false);
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [isEssayModeOn, setIsEssayModeOn] = useState(true);
  const { t } = useTranslation();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const scope = state?.generatorUserSelection?.scope;
  const styles = state?.generatorUserSelection?.styles;
  const {
    getActiveWorkspace,
    getWorkspaceEntities,
    agcEnabled,
    generatorConfigData,
    generatorConfigInProgress,
    generateAdaptedTopics,
    handleGenerationOfCustomPrompt,
  } = useWorkspaces();
  const AppToasty = AppToast.useToast();
  const { user } = AppUser.useUser();
  const [nlpData, setNLPData] = useState([]);
  const [nlpConfig, setNlpConfig] = useState({});
  const [eEditor, setEEditor] = useState(null);
  const [essayCharactersCount, setEssayCharactersCount] = useState(0);
  const [essayWordsCount, setEssayWordsCount] = useState(0);
  const [maxOutputContentLength, setMaxOutputContentLength] = useState(800);
  const [requestPayload, setRequestPayload] = useState(null);
  const [agent, setAgent] = useState(null);
  const [lastQuestionsDataTimeStamp, setLastQuestionsDataTimeStamp] =
    useState(0);
  const [lastNlPDataTimeStamp, setLastNLPDataTimeStamp] = useState(0);
  const topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic?.label;
    topic.category = topic.type;
    return topic;
  });

  const forceEditorWithValue = (value, markdown = true) => {
    if (eEditor?.children || markdown) {
      setEValue(value);
      if (!markdown) {
        eEditor.children = value;
        setEEditor(eEditor);
      }
    }
  };
  const forcePassageWithValue = (value) => {
    if (editor?.children) {
      setValue(value);
      editor.children = value;
      setEditor(editor);
    }
  };
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useApi(Files.downloadUrlGeneration, envConfig);

  const { transcript, startRecording, stopRecording } = useRecording({
    onForcedStop: stopRecording,
  });
  useEffect(() => {
    if (!agent) {
      setAgent(
        state?.agents?.find(
          (u) => u?.agentType === "ContentCreator" && u?.isGlobal === true
        )?.id
      );
    }
  }, [state?.agents?.length]);
  useEffect(() => {
    // cleanup
    return () => {
      dispatch("SET_GENERATOR_USER_SELECTION", {
        ...state?.generatorUserSelection,
        scope: "generation",
      });
      setPrompt("");
    };
  }, []);

  const handleOnRecorderClick = (v) => {
    try {
      if (v) {
        startRecording();
        // show the toast message
        AppToasty.show(t("recordingStarted"), {
          placement: "bottom",
          type: "success",
        });
      } else {
        stopRecording();
        AppToasty.show(t("recordingStopped"), {
          placement: "bottom",
          type: "success",
        });
      }
    } catch (error) {
      AppToasty.show(t("recordingError"), {
        placement: "bottom",
        type: "danger",
      });
    }
  };
  useEffect(() => {
    AppQuantumEditorUtils.Utilities.insertTextAtSelection(
      editor,
      transcript || ""
    );
  }, [transcript]);
  const {
    isInactive,
    value,
    editor,
    updateAPIResult,
    updateAPIError,
    createAPIError,
    updateAPIInProgress,
    createAPIInProgress,
    updateAPIRequest,
    createAPIRequest,
    getAPIRequest,
    lastUpdateTime,
    lastCreationTime,
    lastChange,
    isEditorLoaded,
    saveDocument,
    handleSocketConnectionChange,
    createDocument,
    setValue,
    setEditor,
    setIsValidationData,
    isTopicUpdateModeEnabled,
    setIsTopicUpdateModeEnabled,
    isValidationData,
    editorQA,
    setEditorQA,
    getAPIResult,
    goToParser,
    selectedTopics,
    setSelectedTopics,
    isDirty,
    isParser,
    setIsParser,
    rawText,
    charactersCount,
    wordsCount,
    wordsAndCharactersCountFromEditorValue,
    isSaved,
    trainingData,
    handleCreationOfDocumentFromGnOutput,
    setIsSaveRequested,
  } = useAutoSave({
    documentId,
    workspaceid: getActiveWorkspace()?.id,
    user,
    updateMethod: trainingAPI.updateTrainingData,
    createMethod: trainingAPI.postTrainingData,
    isGnTraining: type == "gn",
    isCnTraining: type == "cn",
    route: type,
    documentType:
      type == "gn" ? "generativeTraining" : "conversationalTraining",
    backupProperty: type == "gn" ? "initialGNValue" : "initialCNValue",
    backupCommand:
      type == "gn" ? "SET_INITIAL_GN_VALUE" : "SET_INITIAL_CN_VALUE",
    savesSkipsForBooting: 0,
    reInitiateOnParserMode: true,
    initialValue: initialEditorValueForQuestions,
  });
  useEffect(() => {
    setLastQuestionsDataTimeStamp(new Date().getTime());
    const result = wordsAndCharactersCountFromEditorValue(eValue);
    setEssayWordsCount(result.wordsCount);
    setEssayCharactersCount(result.charactersCount);
  }, [eValue]);

  const [selectedNers, setSelectedNers] = useState([]);
  const [isTopicModeOn, setIsTopicModeOn] = useState(false);
  const [isSummaryGenerationOn, setIsSummaryGenerationModeOn] = useState(true);
  const [isTopicTrainingScope, setisTopicTrainingScopeAsTopic] =
    useState(false);
  const [prompt, setPrompt] = useState("");

  const onSelectedTopicsChange = (selectedItems) => {
    setSelectedTopics(selectedItems);
    setIsSaveRequested(Date.now()?.toString());
  };
  const onSelectedNersChange = (selectedItems) => {
    setSelectedNers(selectedItems);
  };
  const [
    showConfirmationModalForTraining,
    setShowConfirmationModalForTraining,
  ] = useState(false);
  // this.state = { editorState: EditorState.createEmpty() };
  // let editorHTML;
  const handleFeedback = async (
    _id,
    type,
    feedbackType,
    feedbackComment,
    subFeedbackType
  ) => {
    try {
      const result = await HumanFeedback.updateFeedbackOnStream(_id, [
        {
          property: "humanFeedback",
          value: type,
        },
        {
          property: "humanFeedbackType",
          value: feedbackType,
        },
        {
          property: "humanFeedbackComment",
          value: feedbackComment,
        },
        {
          property: "humanFeedbackSubType",
          value: subFeedbackType,
        },
        {
          property: "humanFeedbackProvidedBy",
          value: user?.id,
        },
      ]);
      console.log("feedback update result", result);
      AppToasty.show("Feedback saved", {
        type: "success",
      });
    } catch (error) {
      console.log("error", error);
      AppToasty.show("Feedback could not be saved", {
        type: "danger",
      });
    }

    // change here
  };

  const {
    data: generateData,
    error: generateError,
    loading: generateLoading,
    apiRequest: generateRequest,
  } = useApi(Generator.getContent, envConfig, true);

  const {
    value: eValue,
    setValue: setEValue,
    language: forcedLanguage,
    setLanguage: setForcedLanguage,
    autoExecuteAPIError: fakeCheckError,
    autoExecuteAPIInProgress: fakeCheckInProgress,
    autoExecuteAPIResult: fakeCheckData,
    lastChange: lastFakeCheckChange,
    lastExecutionTime: lastFakeCheckExecutionTime,
    setLastChange: setLastFakeCheckChange,
    setSkippedExecutionsForBooting: setSkippedFakeCheckExecutionsForBooting,
  } = useAutoRequest({
    apiMethod: Generator.fakeCheck,
    executionInterval: 10000,
    initialValue: initialEditorValueForQuestions,
    workspaceid: getActiveWorkspace()?.id,
    executionSkipsForBooting: 0,
    scope: "fakeContentCheck",
    isEnabled: agcEnabled,
    initialLanguage: state?.generatorUserSelection?.language || "def",
  });

  const handleCreationOfDocument = ({
    documentTitle,
    selectedTopics,
    isInclusionOfPassageRequired,
    isRedirectRequired,
  }) => {
    let outputValue = null;
    let _outputValue = null;
    unified
      .unified()
      .use(markdown)
      .use(slate)
      .process(eValue[0]?.children[0]?.text, (err, file) => {
        if (err) throw err;
        _outputValue = file?.result;
      });
    if (isInclusionOfPassageRequired)
      outputValue = [
        ...value,
        {
          type: "title",
          children: [{ text: "Generated content", bold: true }],
        },
        ..._outputValue,
      ];
    else outputValue = [..._outputValue];
    handleCreationOfDocumentFromGnOutput(
      Utility.purifySlateObject(outputValue),
      documentTitle || "No title",
      "readComprehensionContent",
      isRedirectRequired,
      selectedTopics
    );
  };
  useEffect(() => {
    if (forcedLanguage) {
      dispatch("SET_GENERATOR_USER_SELECTION", {
        ...state?.generatorUserSelection,
        language: forcedLanguage,
      });
    }
  }, [forcedLanguage]);
  let _lastPrompt = "";
  useEffect(() => {
    if (prompt && prompt !== _lastPrompt) {
      console.log("prompt", prompt);
      console.log("_lastPrompt", _lastPrompt);
      _lastPrompt = prompt;
      let mergedItems = [
        // ...state?.generationConfigData?.prompts,
        ...generatorConfigData.map((item) => ({ ...item, isCustom: true })),
      ];

      // console.log("prompt", sty);
      promptData = mergedItems.find(
        (u) => u.type?.toString() === prompt?.toString()
      );
      if (promptData && !promptData?.isCustom)
        forcePassageWithValue([
          {
            type: "paragraph",
            children: [
              {
                text: "",
              },
            ],
          },
        ]);
      else {
        forcePassageWithValue([
          {
            type: "paragraph",
            children: [{ text: promptData?.interpolationString || "" }],
          },
        ]);
      }
      _lastPrompt = promptData?.interpolationString || "";
    }
  }, [
    prompt,
    JSON.stringify(state?.configData?.generator),
    JSON.stringify(generatorConfigData),
    getActiveWorkspace()?.domain,
    state?.generatorUserSelection?.styles,
    _lastPrompt,
  ]);
  const textToDisplay = "**...**";
  const delay = 0;
  let streamSubscription;
  let timerId;
  const handleCancellation = () => {
    if (streamid) {
      streamObject?.unsubscribe();
      setStreamStatus("fulfilled");
      clearTimeout(timerId);
    }
  };

  useEffect(() => {
    if (streamid) {
      streamSubscription = API.graphql(
        graphqlOperation(onUpdateOutputStreamByIDEvent, {
          id: streamid,
        })
      ).subscribe({
        next: (data) => {
          let rawMessage = data?.value?.data?.onUpdateOutputStreamByIDEvent;
          rawMessage.metadata = JSON.parse(rawMessage.metadata);
          const msg = { element: rawMessage };
          setStreamStatus(msg?.element?.status);
          if (msg?.element?.status === "inProgress" || "fulfilled") {
            forceEditorWithValue([
              {
                type: "paragraph",
                children: [{ text: msg?.element?.content }],
              },
            ]);
          }
        },
      });
      setStreamObject(streamSubscription);
    }
    return () => {
      streamSubscription?.unsubscribe();
      clearTimeout(timerId);
    };
  }, [streamid]);
  useEffect(() => {
    if (streamid) {
      for (let i = 0; i <= textToDisplay.length; i++) {
        timerId = setTimeout(() => {
          forceEditorWithValue([
            {
              type: "paragraph",
              children: [{ text: textToDisplay.slice(0, i) }],
            },
          ]);
        }, i * delay);
      }
    }
  }, [streamid]);
  const handleParse = async () => {
    if (rawText?.length > 0) {
      if (rawText?.length < 16) {
        return AppToasty.show(
          t("Please enter at least 16 characters to generate text"),
          { type: "danger" }
        );
      }
      const lastNode = editor.children[editor.children.length - 1];
      rawOptions = getActiveWorkspace()?.parsingConfig;

      try {
        // const result = await generateRequest(payload);
        const stream = await streamHelper.createOutputStream({
          content: "",
          workspaceID: getActiveWorkspace().id,
          type: "generator",
          status: "pending",
        });
        if (stream) {
          setStreamStatus("pending");
          setStreamid(stream?.id);
          const payload = {
            prompts: [
              {
                text: rawText,
                scope: scope,
                styles: [styles],
                targetLanguage: forcedLanguage,
              },
            ],
            agentid: agent,
            // passage: rawText,
            topics: selectedTopics.map((u) => u?.id),
            options: {
              temperature: rawOptions?.temperature,
              maxTokens: maxOutputContentLength,
              topP: rawOptions?.topP,
              presencePenalty: rawOptions?.presencePenalty,
              frequencyPenalty: rawOptions?.frequencyPenalty,
            },
            token: getActiveWorkspace().workspaceToken,
            workspaceid: getActiveWorkspace().id,
            stream: "true",
            streamid: stream?.id,
          };
          // AppToasty.show(t("Content generation started"), {
          //   type: "success",
          // });
          const result = await generateRequest(payload);
          if (result?.errorCode) {
            console.log("error", result);
            AppToasty.show(
              t("Error while generating content - " + result?.errorDetail),
              {
                type: "danger",
              }
            );
            forceEditorWithValue(initialEditorValueForQuestions);
          }
        }
      } catch (error) {
        console.log("error", error);
        AppToasty.show(
          t("Error while generating content - " + error?.message),
          {
            type: "danger",
          }
        );
        forceEditorWithValue(initialEditorValueForQuestions);
      }
    } else {
      AppToasty.show(
        t("Please enter at least 16 characters to generate text"),
        {
          type: "danger",
        }
      );
    }
  };
  useEffect(() => {
    if (documentId && getActiveWorkspace()?.id)
      getAPIRequest(
        documentId,
        ["topics", "workspaceid"],
        getActiveWorkspace().id
      );
  }, [documentId, getActiveWorkspace()?.id]);
  useEffect(() => {
    if (!isParser) setIsSummaryGenerationModeOn(false);
  }, [isParser]);
  useEffect(() => {
    if (isEssayModeOn) setIsTopicModeOn(false);
  }, [isEssayModeOn]);

  const handleEditorError = (event) => {
    console.error("error", event);
    if (event.message)
      if (!displayedToasties()) {
        const id = AppToasty.show(t(event.message), {
          type: "danger",
        });
        displayedToasties(id);
      }
  };
  const handleOutputCopy = () => {
    AppToasty.show(t("Copied to clipboard"), {
      type: "success",
    });
  };
  useEffect(() => {
    if (getActiveWorkspace()?.parsingConfig?.maxTokens)
      setMaxOutputContentLength(getActiveWorkspace().parsingConfig.maxTokens);
  }, [getActiveWorkspace()?.parsingConfig?.maxTokens]);
  const handleCheckOnFakeData = async () => {
    const payload = {
      contents: [
        {
          text: AppQuantumEditorUtils.Helpers.getPlainText(eValue),
          sourceLanguage: forcedLanguage,
        },
      ],
      token: getActiveWorkspace().workspaceToken,
      workspaceid: getActiveWorkspace().id,
    };
    const result = await fakeCheckRequest(payload);
    if (result?.contents) {
      if (result?.contents[0]?.output?.fake_probability) {
        AppToasty.show(t("Authenticity check successful"), {
          type: "success",
        });
      } else {
        AppToasty.show(t("Authenticity check failed"), {
          type: "danger",
        });
      }
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <GeneratorScreen
        configData={generatorConfigData}
        scope={scope}
        streamid={streamid}
        fakeCheckData={fakeCheckData}
        styles={styles}
        setScope={(v) => {
          dispatch("SET_GENERATOR_USER_SELECTION", {
            ...state?.generatorUserSelection,
            scope: v,
          });
        }}
        setStyles={(v) => {
          dispatch("SET_GENERATOR_USER_SELECTION", {
            ...state?.generatorUserSelection,
            styles: v,
          });
        }}
        scopePlaceholder={
          generatorConfigData?.allowedPrompts?.find((s) => s.type === scope)
            ?.promptPlaceholder
        }
        requestPayload={requestPayload}
        passageForcedLanguage={state?.generatorUserSelection?.language}
        setPassageForcedLanguage={setForcedLanguage}
        isInactive={isInactive}
        isTopicTrainingScope={isTopicTrainingScope}
        setisTopicTrainingScopeAsTopic={setisTopicTrainingScopeAsTopic}
        createAPIInProgress={createAPIInProgress}
        setIsTopicModeOn={setIsTopicModeOn}
        isTopicModeOn={isTopicModeOn}
        onSelectedTopicsChange={onSelectedTopicsChange}
        selectedTopics={selectedTopics}
        setSelectedTopics={setSelectedTopics}
        parseInProgress={
          (generateLoading ||
            streamStatus === "pending" ||
            streamStatus === "inProgress") &&
          streamStatus !== "fulfilled"
        }
        agent={agent}
        setAgent={setAgent}
        fakeCheckInProgress={fakeCheckInProgress}
        storeInProgress={updateAPIInProgress}
        isSaved={isSaved}
        storeInError={updateAPIError}
        onSocketConnectionChange={handleSocketConnectionChange}
        user={user}
        handleParse={handleParse}
        setEditor={setEditor}
        setEditorQA={setEditorQA}
        value={value}
        setValue={setValue}
        eValue={eValue}
        setEValue={setEValue}
        isOutputUpToDate={true}
        nlpData={nlpData}
        nlpConfig={nlpConfig}
        setNlpConfig={setNlpConfig}
        showConfirmationModalForTraining={showConfirmationModalForTraining}
        setShowConfirmationModalForTraining={
          setShowConfirmationModalForTraining
        }
        isTopicUpdateModeEnabled={isTopicUpdateModeEnabled}
        setIsTopicUpdateModeEnabled={setIsTopicUpdateModeEnabled}
        handleGenerationOfCustomPrompt={handleGenerationOfCustomPrompt}
        documentId={documentId}
        isEditorLoaded={isEditorLoaded}
        isParser={isParser}
        setIsParser={setIsParser}
        handleNewDocument={createDocument}
        handleCheckOnFakeData={handleCheckOnFakeData}
        routeToParsing={goToParser}
        setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
        isTrainerNoDocumentId={isTrainerNoDocumentId}
        isDirty={isDirty}
        handleEditorError={handleEditorError}
        isSummaryGenerationOn={isSummaryGenerationOn}
        setIsSummaryGenerationModeOn={setIsSummaryGenerationModeOn}
        selectedNers={selectedNers}
        onSelectedNersChange={onSelectedNersChange}
        rawText={rawText}
        charactersCount={charactersCount}
        wordsCount={wordsCount}
        workspaceid={getActiveWorkspace()?.id}
        trainingData={trainingData}
        setIsEssayModeOn={setIsEssayModeOn}
        isEssayModeOn={isEssayModeOn}
        initialSummaryState={initialEditorValueForQuestions}
        prompt={prompt}
        setPrompt={setPrompt}
        eEditor={eEditor}
        agcEnabled={agcEnabled}
        setEEditor={setEEditor}
        generateData={generateData}
        setIsValidationData={setIsValidationData}
        isValidationData={isValidationData}
        configInProgress={generatorConfigInProgress}
        handleCreationOfDocumentFromGnOutput={handleCreationOfDocument}
        handleContentLenghtChange={(v) => {
          setMaxOutputContentLength(v);
        }}
        maxOutputContentLength={maxOutputContentLength}
        topics={generateAdaptedTopics(topics)}
        handleOnRecorderClick={handleOnRecorderClick}
        handleCancellation={handleCancellation}
        S3downloadUrlRequest={S3downloadUrlRequest}
        handleOutputCopy={handleOutputCopy}
        trainingContentType={type}
        setFeedbackModalOpen={setFeedbackModalOpen}
        isFeedbackModalOpen={isFeedbackModalOpen}
        handleFeedback={handleFeedback}
        setIsSaveRequested={setIsSaveRequested}
      />
    </Suspense>
  );
};

export default GeneratorScreenContainer;
