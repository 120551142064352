import React from "react";
import useWorkspaces from "../Hooks/useWorkspaces";
import { Redirect, Route, useParams } from "./index";
import { routes } from "./routes";

const PowerUserRoute = (props) => {
  const {
    isUserAdmin,
    isUserAIEngineer,
    isUserContentAdmin,
    isUserLoadedInWorkspace,
  } = useWorkspaces();

  const section = props?.computedMatch?.params?.sectionId;
  let isAllowed = false;
  if (
    (section === "topic" ||
      section === "topic_new" ||
      section === "topic_edit") &&
    (isUserContentAdmin() || isUserAIEngineer())
  ) {
    isAllowed = true;
  } else if (isUserAIEngineer()) {
    isAllowed = true;
  }
  return !isAllowed && isUserLoadedInWorkspace() ? (
    <Redirect
      to={{
        pathname: routes.accessDenied,
      }}
    />
  ) : (
    <Route {...props} />
  );
};

export default PowerUserRoute;
