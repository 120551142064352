import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import channelsHelper from "../../../../API/Channels/channelsHelper";
import secretGenerator from "../../../../API/SecretGenerator";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import ChannelSettingsAIView from "../SubComponents/ChannelSettingsAIView";

const ChannelsSettingsAI = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onChannelCreated,
      selectedId,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();
    const { state, dispatch } = AppStateManager.useAppStateManager();
    const { getActiveWorkspace } = useWorkspaces();
    const [isSaveInProgress, setIsSaveInProgress] = React.useState(false);
    const validationSchema = AppForms.yup.object().shape({
      channel: AppForms.yup.string().required().label("Channel"),
      name: AppForms.yup
        .string()
        .required()
        .label("Name")
        .matches(
          /^[a-zA-Z0-9_]*$/,
          "Only alphanumeric characters and underscores are allowed without spaces."
        )
        .max(64, "Too long, maximum of 64 characters are allowed."),
      description: AppForms.yup.string().label("Description").max(255),
      tokenSecret: AppForms.yup.string().when("type", {
        is: (type) => type != "ses",
        then: AppForms.yup.string().required().label("API Key"),
        otherwise: AppForms.yup.string().notRequired().nullable(),
      }),
      senderid: AppForms.yup.string().when("channel", {
        is: (channel) => channel != "email",
        then: AppForms.yup.string().required().label("Client ID"),
        otherwise: AppForms.yup.string().notRequired(),
      }),
    });

    const handleDeletion = async () => {
      try {
        console.log("result of deletion", opt);
        const result = await channelsHelper.deleteChannel(opt.id);
        dispatch("SET_CHANNELS", [
          ...state?.channels?.filter((u) => u?.id !== opt.id),
        ]);
        navigateBack();
        AppToasty.show(`Channel deleted`, {
          type: "success",
        });
      } catch (error) {
        console.log("Error deleting auth", error);
        AppToasty.show(`Channel could not be deleted`, {
          type: "danger",
        });
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };
    const identifyOptByID = (id) => {
      return state?.channels.find((u) => u?.id === id);
    };
    const handleChannelItemSave = async (v) => {
      setIsSaveInProgress(true);
      v.workspaceID = getActiveWorkspace()?.id;
      if (v?.config) v.config = JSON.stringify(v.config, null, 2);
      delete v?._lastChangedAt;
      delete v?.createdAt;
      delete v?.updatedAt;
      delete v?._deleted;
      delete v?.__typename;
      const psw = v?.type == "oauth" ? v?.clientSecret : v?.tokenSecret;
      delete v?.tokenSecret;
      let newAuthorisations = [...state?.channels];
      console.log("v", v);
      try {
        let result;
        if (isEdit) {
          result = await channelsHelper.updateChannel(opt.id, v);
          console.log("Edit result", result);
          const _updatedObject = identifyOptByID(
            result.data?.updateCommunicationService?.id
          );
          const updateIndex = newAuthorisations.findIndex(
            (u) => u?.id === _updatedObject?.id
          );
          newAuthorisations[updateIndex] =
            result?.data?.updateCommunicationService;
        } else {
          if (state?.channels?.find((u) => u?.name === v?.name)) {
            AppToasty.show("Channel with this name already exists", {
              type: "danger",
            });
            setIsSaveInProgress(false);
            return;
          }
          result = await channelsHelper.createChannel({
            ...v,
          });
          console.log("Channel", result);
          const _id = result?.data?.createCommunicationService?.id;
          if (v?.type != "local" && v?.type != "aws") {
            const secretResult = await secretGenerator.storeSecret({
              workspaceid: v.workspaceID,
              id: _id,
              passwordSecretValue: psw,
            });
            if (secretResult?.data?.status === "Success") {
              const payload =
                v?.type == "oauth"
                  ? {
                      clientSecret: secretResult?.data?.name,
                    }
                  : {
                      tokenSecret: secretResult?.data?.name,
                    };
              const _updatedObject = await channelsHelper.updateChannel(
                _id,
                payload
              );
            } else {
              throw new Error("Secret could not be created");
            }
          }
          newAuthorisations.push(result?.data?.updateCommunicationService);
        }

        dispatch("SET_CHANNELS", [...newAuthorisations]);
        backTo && navigateBack();
        onChannelCreated && onChannelCreated("success");
      } catch (error) {
        console.error(error);
        onChannelCreated && onChannelCreated("error");
      } finally {
        setIsSaveInProgress(false);
      }
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            height: "auto",
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Channel : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={handleChannelItemSave}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <ChannelSettingsAIView
              handleDeletion={handleDeletion}
              isEdit={isEdit}
              settingType={settingType}
              isSaveInProgress={isSaveInProgress}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [
        state.activeWorkspaceID,
        initialMapValues,
        state?.channels,
        isEdit,
        AppToasty,
        isSaveInProgress,
      ]
    );
    return _view;
  }
);

export default ChannelsSettingsAI;
