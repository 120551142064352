import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppTab from "@toothfairy/shared-ui/AppTab";
import React from "react";
import { View } from "react-native";
import PageTitle from "../../Components/PageTitle";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairySupportButton from "../../Components/ToothFairyComponents/ToothFairySupportButton";
import useWorkspaces from "../../Hooks/useWorkspaces";
import AppToast from "@toothfairy/shared-ui/AppToast";
import CostingView from "./Components/CostingView";
import ModelView from "./Components/ModelView";

const DashboardScreen = ({
  labels,
  datasets,
  selectedDate,
  changeMonthByUnit,
  consumptionAPIInProgress,
  costAPIInProgress,
  parsingCost,
  trainingCost,
  nersBreakDown,
  intentsBreakDown,
  topicsBreakDown,
  documentsBreakdown,
  trainingDuration,
  trainingAPIInProgress,
  initialTabFromRouting,
  batchConsumption,
  costChart,
  normalisedConsumption,
  normalisedTrainingConsumption,
  totalPredictionCost,
  totalTrainingCost,
  isAdmin,
}) => {
  const { handleTabSelection, getLastTabSelectedByScreen } = useWorkspaces();
  const routes = [{ key: "second", title: "Overview", routeId: "model" }];
  if (isAdmin) {
    routes.push({ key: "first", title: "Usage", routeId: "usage" });
  }

  const tabIndexFromRouting = routes
    .map((u) => u.routeId)
    .indexOf(initialTabFromRouting);
  const AppToasty = AppToast.useToast();
  const _initialIndex =
    tabIndexFromRouting > -1
      ? tabIndexFromRouting
      : getLastTabSelectedByScreen("dashboard");

  const costs = () => (
    <CostingView
      labels={labels}
      consumptionAPIInProgress={consumptionAPIInProgress}
      costAPIInProgress={costAPIInProgress}
      datasets={datasets}
      parsingCost={parsingCost}
      trainingCost={trainingCost}
      trainingDuration={trainingDuration}
      selectedDate={selectedDate}
      changeMonthByUnit={changeMonthByUnit}
      batchConsumption={batchConsumption}
      costChart={costChart}
      normalisedConsumption={normalisedConsumption}
      normalisedTrainingConsumption={normalisedTrainingConsumption}
      totalPredictionCost={totalPredictionCost}
      totalTrainingCost={totalTrainingCost}
    />
  );

  const model = () => (
    <ModelView
      intentsBreakDown={intentsBreakDown}
      nersBreakDown={nersBreakDown}
      topicsBreakDown={topicsBreakDown}
      documentsBreakdown={documentsBreakdown}
      trainingAPIInProgress={trainingAPIInProgress}
    />
  );
  return (
    <AppBaseScreen>
      <View style={{ paddingHorizontal: 20, paddingBottom: 10 }}>
        <AppTab
          initialIndex={_initialIndex}
          onTabChange={(route) => {
            handleTabSelection(route, routes, "dashboard");
          }}
          renderSceneObject={{
            second: model,
            first: costs,
          }}
          routes={routes}
        />
      </View>
    </AppBaseScreen>
  );
};

export default DashboardScreen;
