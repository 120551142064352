import AppSwitch from "@toothfairy/shared-ui/AppSwitch";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";

const ToothFairySwitch = ({
  wrapperStyle,
  labelColor = "darkGrey",
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <AppSwitch
      wrapperStyle={[
        {
          backgroundColor: props.disabled
            ? currentTheme?.theme?.grey
            : currentTheme?.theme?.lightGrey,
          maxHeight: 30,
          paddingHorizontal: 10,
          borderRadius: 20,
          paddingVertical: 18,
        },
        wrapperStyle,
      ]}
      labelTextStyle={{
        fontSize: currentTheme?.theme?.small_font_size,
        color: props.disabled
          ? currentTheme?.theme?.darkGrey
          : currentTheme?.theme[labelColor],
      }}
      showTextInputLabel={true}
      {...props}
      displayIsTicked={true}
    />
  );
};

export default ToothFairySwitch;
