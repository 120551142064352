import AppSecureTextInput from "@toothfairy/shared-ui/AppSecureTextInput";
import React, { useState } from "react";

const ToothFairySlider = ({
  onSubmit,
  initialValue,
  label,
  maximumValue = 25,
  minimumValue = 1,
  wrapperStyle,
  unitLabel,
  step = 1,
}) => {
  const [isTrainingIterationsEditable, setIsTrainingIterationsEditable] =
    useState(false);
  return (
    <AppSecureTextInput
      inputType="SLIDER"
      showValue
      label={label}
      showTextInputLabel
      step={step}
      style={{ width: "100%", paddingHorizontal: 10 }}
      coreViewStyle={{ minHeight: 40 }}
      wrapperStyle={[{ flex: 1, flexDirection: "row" }, wrapperStyle]}
      maximumValue={maximumValue}
      minimumValue={minimumValue}
      actionsButtonsViewStyle={{ paddingRight: 10 }}
      isEditable={isTrainingIterationsEditable}
      onEditIconPress={() => setIsTrainingIterationsEditable(true)}
      onCancelIconPress={() => setIsTrainingIterationsEditable(false)}
      onSubmitIconPress={(v) => {
        setIsTrainingIterationsEditable(false);
        onSubmit(v);
      }}
      initialValue={initialValue}
      unitLabel={unitLabel}
    />
  );
};

export default ToothFairySlider;
