import React, { useContext } from "react";

const initialValues = {
  displayedView: "MAIN",
};

export const TreeviewContext = React.createContext(initialValues);

export const TreeviewProvider = ({
  children,
  treeData,
  onSelectionChanged = (v) => {},
  onDeletion = (v) => {},
  onEdit = (v) => {},
  onDrag = (v) => {},
  onDrop = (v) => {},
  setDialogActionOnTree = (v) => {},
  showIconsOnSelection = false,
  selectedNode = {},
  removeNode = (v) => {},
  editLeafParentAction = "EDIT_FOLDER",
  editLeafAction = "EDIT_DOCUMENT",
  deleteAction = "DELETE_NODE",
  allowParentEdit = true,
  fixedNodeIds = [],
  treemode = "DEFAULT",
  allowParentAction = false,
  onParentActionClick = (v) => {},
}) => {
  const deleteChildrenById = (nodeId) => {
    const node = findNodeById(nodeId, treeData);
    if (node.children) {
      node.children.forEach((child) => {
        removeById(child.id, treeData);
      });
    }
  };

  // create a function to update a node by id
  const updateNodeById = (nodeId, Tree, updatedNode) =>
    Tree.reduce(
      (acc, obj) =>
        obj.id === nodeId
          ? [...acc, updatedNode]
          : [
              ...acc,
              {
                ...obj,
                ...(obj.children && {
                  children: updateNodeById(nodeId, obj.children, updatedNode),
                }),
              },
            ],
      []
    );

  // create a function to add a child to a node by id
  const addChildById = (nodeId, Tree, child) =>
    Tree.reduce(
      (acc, obj) =>
        obj.id === nodeId
          ? [
              ...acc,
              {
                ...obj,
                children: [...(obj.children || []), child],
              },
            ]
          : [
              ...acc,
              {
                ...obj,
                ...(obj.children && {
                  children: addChildById(nodeId, obj.children, child),
                }),
              },
            ],
      []
    );

  const removeById = (nodeId, Tree) =>
    Tree.reduce(
      (acc, obj) =>
        obj.id === nodeId
          ? acc
          : [
              ...acc,
              {
                ...obj,
                ...(obj.children && {
                  children: removeById(obj.children, nodeId),
                }),
              },
            ],
      []
    );

  const findNodeById = (nodeId, Tree) =>
    Tree.some(({ id }) => id === nodeId)
      ? (({ children, ...rest } = Tree.find(({ id }) => id === nodeId)),
        [{ ...rest }])
      : Tree.flatMap(({ children = [] }) => findNodeById(nodeId, children));
  return (
    <TreeviewContext.Provider
      value={{
        treeData,
        onSelectionChanged,
        onDeletion,
        onEdit,
        onDrag,
        onDrop,
        setDialogActionOnTree,
        showIconsOnSelection,
        selectedNode,
        deleteChildrenById,
        updateNodeById,
        addChildById,
        removeById,
        findNodeById,
        removeNode,
        editLeafParentAction,
        editLeafAction,
        deleteAction,
        allowParentEdit,
        fixedNodeIds,
        treemode,
        allowParentAction,
        onParentActionClick,
      }}
    >
      {children}
    </TreeviewContext.Provider>
  );
};

export const useTreeview = () => {
  const {
    onSelectionChanged,
    onDeletion,
    onEdit,
    onDrag,
    onDrop,
    treeData,
    setDialogActionOnTree,
    showIconsOnSelection,
    selectedNode,
    deleteChildrenById,
    updateNodeById,
    addChildById,
    removeById,
    findNodeById,
    removeNode,
    editLeafParentAction,
    editLeafAction,
    deleteAction,
    allowParentEdit,
    fixedNodeIds,
    treemode,
    allowParentAction,
    onParentActionClick,
  } = useContext(TreeviewContext);
  return {
    onSelectionChanged,
    onDeletion,
    onEdit,
    onDrag,
    onDrop,
    treeData,
    setDialogActionOnTree,
    showIconsOnSelection,
    selectedNode,
    deleteChildrenById,
    updateNodeById,
    addChildById,
    removeById,
    findNodeById,
    editLeafParentAction,
    editLeafAction,
    deleteAction,
    allowParentEdit,
    fixedNodeIds,
    treemode,
    allowParentAction,
    onParentActionClick,
  };
};

export default useTreeview;
