import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect } from "react";
import Constants from "../../../../Constants";

const CorefeedbackViewForm = ({
  isFeedbackUpdateInProgress,
  isCreationInProgress,
  handleCreationofDocument,
  isChat,
  onAutoTuneChanged = () => {},
  onAdminResolutionChanged = () => {},
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { values } = AppForms.useFormikContext();
  useEffect(() => {
    onAutoTuneChanged(values.autoTune);
  }, [values.autoTune]);
  useEffect(() => {
    onAdminResolutionChanged(values.adminResolution);
  }, [values.adminResolution]);
  return (
    <>
      <AppForms.AppFormField
        showTextInputLabel
        wrapperInputStyle={{
          paddingTop: 10,
          marginHorizontal: 5,
        }}
        textContainer={{
          minHeight: 120,
          paddingVertical: 10,
          marginHorizontal: 20,
        }}
        multiline={true}
        label={`Resolution`}
        placeholder="Insert details of what the issue with the response was and how it should be resolved. The information will be used to generate the new training document."
        keyboardtype="default"
        textContextType="label"
        autoCorrect={false}
        name="adminResolution"
      />
      <AppForms.AppFormSwitch
        // disabled={true}
        wrapperStyle={[
          {
            backgroundColor: currentTheme?.theme?.lightGrey,
            maxHeight: 30,
            paddingHorizontal: 10,
            borderRadius: 20,
            paddingVertical: 18,
            marginHorizontal: 23,
            marginTop: 5,
            marginBottom: 5,
          },
        ]}
        labelTextStyle={{
          fontSize: currentTheme?.theme?.small_font_size,
          color: currentTheme?.theme?.darkGrey,
        }}
        showTextInputLabel={true}
        label={"Mark as resolved"}
        displayIsTicked={true}
        name="adminChecked"
      />
      <AppForms.AppFormSwitch
        disabled={values?.adminResolution?.length < 10 || !isChat}
        wrapperStyle={[
          {
            backgroundColor: currentTheme?.theme?.lightGrey,
            maxHeight: 30,
            paddingHorizontal: 10,
            borderRadius: 20,
            paddingVertical: 18,
            marginHorizontal: 23,
            marginTop: 5,
            marginBottom: 5,
          },
        ]}
        labelTextStyle={{
          fontSize: currentTheme?.theme?.small_font_size,
          color: currentTheme?.theme?.darkGrey,
        }}
        showTextInputLabel={true}
        label={"Autotune from resolution"}
        displayIsTicked={true}
        name="autoTune"
      />

      <AppText
        leftIcon={AppAssets?.icons?.magic}
        iconSize={18}
        color={currentTheme?.theme?.primary}
        isClickable={!isCreationInProgress}
        style={{
          marginLeft: 10,
          textDecorationColor: currentTheme?.theme?.primary,
        }}
        wrapperStyle={{
          padding: 15,
          marginHorizontal: 12,
        }}
        onPress={() => handleCreationofDocument(values)}
      >
        {`Click here to create a training document from this ${
          isChat ? "chat" : "output"
        }`}
      </AppText>

      <AppForms.AppSubmitButton
        title={"Save"}
        isDisabled={isFeedbackUpdateInProgress}
        awesomeIconColor={currentTheme?.theme?.white}
        backgroundColor={currentTheme?.theme?.primary}
        btnContentStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        style={[
          {
            height: Constants.appButtonHeight,
            width: "auto",
            shadowColor: currentTheme?.theme?.transparent,
            marginHorizontal: 10,
          },
        ]}
        btnLabelStyle={{ fontSize: 16 }}
        wrapperStyle={[
          {
            width: "fit-content",
            minWidth: 150,
            marginTop: 20,
            alignSelf: "center",
            height: Constants.appButtonHeight,
          },
        ]}
      />
    </>
  );
};

export default CorefeedbackViewForm;
