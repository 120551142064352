import React, { useRef, useEffect } from "react";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";

function hexToRGB(hex, alpha) {
  let expandedHex = hex;
  if (hex.length === 4) {
    expandedHex = "#" + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
  }
  const r = parseInt(expandedHex.slice(1, 3), 16);
  const g = parseInt(expandedHex.slice(3, 5), 16);
  const b = parseInt(expandedHex.slice(5, 7), 16);
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

const ChatWebInput = ({
  placeholder = "Ask me anything",
  onSend = () => {},
  isStreaming,
  onCancel = () => {},
  agentColor,
  allowStop = false,
  isLastMessageEmpty,
  quickQuestions = [],
  isChatEmpty = false,
}) => {
  const AppToasty = AppToast.useToast();
  const { currentTheme, mode } = AppTheme.useTheme();
  const textareaRef = useRef();
  const [text, setText] = React.useState("");
  const [inputReady, setInputReady] = React.useState(false);
  const [isTyping, setIsTyping] = React.useState(false);
  const [prevScrollHeight, setPrevScrollHeight] = React.useState(0);
  const [isScrollingUp, setIsScrollingUp] = React.useState(true);
  const [prevScrollTop, setPrevScrollTop] = React.useState(0);
  useEffect(() => {
    let currentText = "";
    const words = placeholder?.split(" ");
    if (words?.length > 0)
      setTimeout(() => {
        const intervalId = setInterval(() => {
          if (words.length > 0) {
            currentText += words.shift() + " ";
            setText(currentText);
          } else {
            clearInterval(intervalId);
            setInputReady(true);
            setTimeout(() => {
              document?.querySelector("textarea")?.focus();
            }, 100);
          }
        }, 30); // 8 seconds,
      }, 20);

    // Cleanup function
  }, [placeholder]); // Run once when component mounts
  // get focus on input when component mounts
  React.useEffect(() => {
    if (!isStreaming) document.querySelector("textarea").focus();
  }, [isStreaming]);
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const handleScroll = () => {
        const currentScrollHeight = textarea.scrollHeight;
        const scrollTop = textarea.scrollTop;

        console.log("scroll", scrollTop, currentScrollHeight);
        if (textarea.scrollTop < prevScrollTop) {
          // Scrolling up
          setIsScrollingUp(true);
        } else {
          // Scrolling down
          setIsScrollingUp(false);
        }

        // Update the previous scrollTop value
        setPrevScrollTop(textarea.scrollTop);
        // If the user is scrolling up, remove the scroll event listener
        if (prevScrollHeight > currentScrollHeight || isScrollingUp) {
          textarea.removeEventListener("scroll", handleScroll);
          setPrevScrollHeight(currentScrollHeight);
          return;
        }

        // If the user is typing, set the scroll position to the bottom
        // if (isTyping) {
        //   textarea.scrollTop = currentScrollHeight;
        //   setPrevScrollHeight(currentScrollHeight);
        // }
      };

      textarea.addEventListener("scroll", handleScroll);
      textarea.addEventListener("input", () => setIsTyping(true));
      textarea.addEventListener("compositionstart", () => setIsTyping(true));
      textarea.addEventListener("compositionend", () => setIsTyping(false));

      // Set the initial scroll position to the bottom
      // textarea.scrollTop = textarea.scrollHeight;
      setPrevScrollHeight(textarea.scrollHeight);

      return () => {
        textarea.removeEventListener("scroll", handleScroll);
        textarea.removeEventListener("input", () => setIsTyping(true));
        textarea.removeEventListener("compositionstart", () =>
          setIsTyping(true)
        );
        textarea.removeEventListener("compositionend", () =>
          setIsTyping(false)
        );
      };
    }
  }, [isTyping]);
  const handleKeyDown = (event) => {
    const textarea = textareaRef.current;

    if (event.key === "Enter") {
      if (textarea && event.shiftKey) {
        requestAnimationFrame(() => {
          textarea.scrollTop = textarea.scrollTop += 25;
        });
      }
      if (!event.shiftKey) {
        event.preventDefault();
        if (textareaRef.current.value?.length > 10000) {
          return AppToasty.show("Maximum 10000 characters allowed", {
            type: "danger",
          });
        }
        if (!isStreaming) {
          onSend(event.target.value);
          event.target.value = "";
        }
      }
    }
  };

  const handleSend = () => {
    if (textareaRef.current.value?.length > 10000) {
      return AppToasty.show("Maximum 10000 characters allowed", {
        type: "danger",
      });
    }
    if (!isStreaming) {
      onSend(textareaRef.current.value);
      textareaRef.current.value = null;
      document.querySelector("textarea").focus();
    }
  };

  return (
    <div
      style={{
        width: "auto",
        display: "flex",
        flexDirection: "column",
        zIndex: -1,
        padding: 10,
        minHeight: 90,
      }}
      className="tf-chat-input-wrapper"
    >
      <div
        className="tf-chat-input"
        style={{
          width: "auto",
          display: "flex",
          flexDirection: "row",
          paddingTop: 10,
          borderRadius: 10,
          border: mode === "blue" && "1px solid #456491",
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: currentTheme?.theme?.lightGrey,
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <textarea
          disabled={!inputReady}
          ref={textareaRef}
          placeholder={text}
          className="tf-chat-input-textarea"
          style={{
            fontFamily: "normalFont",
            fontSize: currentTheme?.theme?.small_font_size,
            color: currentTheme?.theme?.font_color,
            backgroundColor: currentTheme?.theme?.transparent,
            flex: 1,
            resize: "none",
          }}
          onKeyDown={handleKeyDown}
        />
        <div
          className="tf-chat-input-icon"
          style={{
            width: 30,
          }}
        >
          <AppIcon
            icon={
              isStreaming
                ? AppAssets?.icons?.stopGeneration
                : AppAssets?.icons?.email
            }
            color={
              mode === "blue"
                ? currentTheme?.theme?.white
                : isStreaming
                ? currentTheme?.theme?.red
                : agentColor || currentTheme?.theme?.primary
            }
            size={20}
            wrapperStyle={{
              marginRight: 0,
              marginLeft: 8,
              marginTop: 0,
              justifyContent: "center",
              alignSelf: "baseline",
            }}
            isClickable={!isLastMessageEmpty}
            onPress={!isLastMessageEmpty && isStreaming ? onCancel : handleSend}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatWebInput;
