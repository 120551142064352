import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppExpander from "@toothfairy/shared-ui/AppExpander";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { AppText, AppIcon } from "@toothfairy/shared-ui";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import AppToast from "@toothfairy/shared-ui/AppToast";
import ToothFairyButton from "../../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../../Constants";
import useWorkspaces from "../../../../../Hooks/useWorkspaces";
import Color from "../../../../../Utility/Color";
import { isLargeScreen } from "../../../../MainViewContainer";
import AdminInfoLeaf from "../../../../Admin/Components/SubComponents/AdminInfoLeaf";
import ImagePlaceholder from "../../../../../Components/ImagePlaceholder";
import trainingAPI from "../../../../../API/Training/trainingAPI";
import Files from "../../../../../API/Files";
import envConfig from "../../../../../../envConfig";
import useAPI from "@toothfairy/shared-api/useApi";
import axios from "axios";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import Utility, { generateRowId } from "../../../../../Utility";
import hostingsHelper from "../../../../../API/Hostings/hostingsHelper";
import colorsMappings from "../../../../Common/colorsMappings";
import AudioPlaceholder from "../../../../../Components/AudioPlaceholder";
import AudioRecorder from "../../../../../Components/AudioRecorder";
const controller = new AbortController();
const internetSearchLocations = [
  {
    name: "Australia",
  },
  {
    name: "Canada",
  },
  {
    name: "New Zealand",
  },
  {
    name: "United States",
  },
  {
    name: "United Kingdom",
  },
];

const _proModels = [
  {
    name: "Mixtral 8x22b",
    id: "mistralai/Mixtral-8x22B-Instruct-v0.1",
    subscriptionPlan: "pro",
  },
  // {
  //   name: "Llama 3 70b",
  //   id: "meta-llama/Llama-3-70b-chat-hf",
  //   subscriptionPlan: "pro",
  // },
  {
    name: "Llama 3.1 70b",
    id: "meta-llama/Meta-Llama-3.1-70B-Instruct-Turbo",
  },
  {
    name: "Qwen 2 72b",
    id: "Qwen/Qwen2-72B-Instruct",
  },
  {
    name: "Llama 3.1 405b",
    id: "meta-llama/Meta-Llama-3.1-405B-Instruct-Turbo",
  },
];

const _baseModels = [
  {
    name: "Sorcerer",
    id: "sorcerer",
    tfOnly: true,
  },
  {
    name: "Mystica",
    id: "mystica",
    tfOnly: true,
  },
  {
    name: "Mistral 7b v0.1",
    id: "mistralai/Mistral-7B-Instruct-v0.1",
  },
  {
    name: "Mistral 7b v0.2",
    id: "mistralai/Mistral-7B-Instruct-v0.2",
  },
  {
    name: "Mistral 7b v0.3",
    id: "mistralai/Mistral-7B-Instruct-v0.3",
  },
  {
    name: "Mixtral 8x7b",
    id: "mistralai/Mixtral-8x7B-Instruct-v0.1",
  },
  // {
  //   name: "Llama 3 8b",
  //   id: "meta-llama/Llama-3-8b-chat-hf",
  // },
  {
    name: "Llama 3.1 8b",
    id: "meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo",
  },
  {
    name: "Gemma 2 9b",
    id: "google/gemma-2-9b-it",
  },
  {
    name: "Gemma 2 27b",
    id: "google/gemma-2-27b-it",
  },
];

const QaSettingsAIView = ({
  isEdit,
  settingType,
  handleDeletion,
  availableFunctions,
  agentsChannels,
  isChatBot,
  isBusinessAnalyst,
  initialMapValues,
  isContentCreator,
  customCodeExecutionEnvironments,
}) => {
  const { currentTheme, mode } = AppTheme.useTheme();
  const entityName = "Prompting";
  const AppToasty = AppToast.useToast();
  const [agentImage, setAgentImage] = React.useState(null);
  const [agentLogo, setAgentLogo] = React.useState(null);
  const [codeExpanded, setCodeExpanded] = React.useState(false);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const {
    getTrainingLogsWithModels,
    getWorkspaceEntities,
    getPlanConfig,
    getActiveWorkspace,
    getWorkspaceUsers,
    getAgents,
  } = useWorkspaces();
  const workspaceid = getActiveWorkspace()?.id;
  const [fileUploadInProgress, setFileUploadInProgress] = React.useState(false);
  const [fileUploadProgress, setFileUploadProgress] = React.useState(0);
  const [disableMultiModal, setDisableMultiModal] = React.useState(false);
  const [disableDueToTranslation, setDisableDueToTranslation] =
    React.useState(false);
  const [codeUploadDisabled, setCodeUploadDisabled] = useState(false);
  const [errorsOnModerationRules, setErrorsOnModerationRules] = React.useState(
    []
  );
  const [audioFileUploadProgress, setAudioFileUploadProgress] = useState(null);
  const [initialLoadCompleted, setInitialLoadCompleted] = React.useState(false);
  const [customHostings, setCustomHostings] = React.useState([]);
  const [baseLlmModels, setBaseLlmModels] = React.useState([]);
  const [functionCallingModels, setFunctionCallingModels] = React.useState([]);
  const [baseLlmProviders, setBaseLlmProviders] = React.useState([]);
  const [baseImageModels, setBaseImageModels] = React.useState([]);
  const [agentVoice, setAgentVoice] = React.useState(null);
  const [functionCallingProviders, setFunctionCallingProviders] =
    React.useState([]);
  const generateModes = () => {
    const _baseModes = [];
    _baseModes.push({
      name: "Retriever",
      id: "retriever",
      description:
        "Well suited for RAG workflows, extensive function calling, API integration and internet searching",
    });
    _baseModes.push({
      name: "Coder",
      id: "coder",
      description:
        "Programming tasks support, code completion and code execution for workflows and processes automation",
    });
    _baseModes.push({
      name: "Casual",
      id: "chatter",
      description:
        "The most versatible agent mode well-rounded for everyday tasks such as file analysis, image generation and content creation",
    });
    if (getActiveWorkspace()?.subscriptionType != "base") {
      _baseModes.push({
        name: "Planner",
        id: "planner",
        description:
          "Our latest agent mode designed to define and execute an execution plan leveraging multiple agents to accomplish complex tasks",
      });
    }

    return _baseModes;
  };
  const { setFieldValue, values } = AppForms.useFormikContext();
  const handleRecordingCompleted = async (blob) => {
    console.log("Recording completed", blob);
    setIsAudioRecording(false);
    const _file = new File([blob], "audio.wav", {
      type: "audio/wav",
    });
    const audioId = generateRowId();
    const result = await S3uploadUrlRequest({
      filename: `${getActiveWorkspace()?.id}/${audioId}.wav`,
      importType: "imported_audio_files",
      contentType: "wav",
    });
    const upload = await axios.request({
      method: "put",
      url: JSON.parse(result?.body)?.uploadURL,
      data: _file,
      headers: {
        "Content-Type": _file.type,
      },
      signal: controller.signal,
      onUploadProgress: (p) => {
        // if (controller.signal.aborted) return;
        setAudioFileUploadProgress(Math.round((p.loaded * 100) / p.total));
        if (p.loaded === p.total) {
          setAudioFileUploadProgress(100);
        }
      },
    });
    console.log("Upload result", upload);
    if (JSON.parse(result?.body).uploadURL) {
      setFieldValue(
        "voiceFileKey",
        `imported_audio_voices/${getActiveWorkspace()?.id}/${audioId}.wav`
      );
    }
  };
  const handleRecordingStarted = () => {
    setIsAudioRecording(true);
    console.log("Recording started");
  };
  const isTfModel =
    values?.llmBaseModel == "sorcerer" ||
    values?.llmBaseModel == "mystica" ||
    values?.llmBaseModel == null;
  const isKnowledgeEnabled =
    !values?.mode ||
    values?.mode == "retriever" ||
    values?.mode == "speed" ||
    values?.mode == "accuracy" ||
    values?.mode == "coder" ||
    !isChatBot;
  const isCharacterNeeded = isChatBot && values?.mode != "mathematician";
  const _modes = generateModes();
  const widgetString = isEdit
    ? `<div id="tfChatWidget" style="position: fixed; bottom: 10px; right: 10px; z-index: 10;">
  <style>
    #tfChatWidget { border: none; background-color: transparent; }
    #tfChatWidget.open { border: 0.5px solid grey; background-color: white; }
    .tfChatBtn { border: none; border-radius: 5px; padding: 10px 15px; margin: 5px; cursor: pointer; transition: background 0.3s; }
    #tfWidget { display: none; width: 360px; height: 400px; border: none; }
  </style>
  <iframe id="tfWidget" src="https://agent.${
    envConfig?.REACT_APP_ENV == "dev"
      ? "toothfairylab.link"
      : "toothfairyai.com"
  }/${workspaceid}/${initialMapValues?.id}"></iframe>
  <div style="display: flex; justify-content: space-between">
    <button id="tfOpenChat" onclick="tfToggleChat(true)" class="tfChatBtn">Chat with ${
      initialMapValues?.label
    }</button>
    <button id="tfStartNew" onclick="tfStartNewChat()" style="display: none;" class="tfChatBtn">New chat</button>
    <button id="tfCloseChat" onclick="tfToggleChat(false)" style="display: none;" class="tfChatBtn">Close chat</button>
  </div>
</div>

<script>
  function tfToggleChat(open) {
    const widget = document.getElementById('tfChatWidget');
    widget.classList.toggle('open', open);
    document.getElementById('tfWidget').style.display = open ? 'block' : 'none';
    ['tfOpenChat', 'tfStartNew', 'tfCloseChat'].forEach(id => 
      document.getElementById(id).style.display = open ? (id === 'tfOpenChat' ? 'none' : 'block') : (id === 'tfOpenChat' ? 'block' : 'none')
    );
  }

  function tfStartNewChat() {
    document.getElementById('tfWidget').src += '';
    tfToggleChat(true);
  }
</script>`
    : `Code will be generated after saving`;
  const {
    data: S3uploadUrlData,
    loading: urlUploadGenerationInProgress,
    apiRequest: S3uploadUrlRequest,
    response: S3uploadResponse,
  } = useAPI(trainingAPI.bulkImportUrlGeneration, envConfig);
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);

  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  const getUrl = async (path, isLargeLogo) => {
    const _url = await S3downloadUrlRequest({
      filename: `imported-image/${workspaceid}/${path}`,
      context: "pdf",
      workspaceid,
    });
    if (isLargeLogo && _url?.url) setAgentLogo(_url?.url);
    else if (_url?.url) setAgentImage(_url?.url);
    else if (isLargeLogo) setAgentLogo(null);
    else setAgentImage(null);
  };
  const getCustomHostings = async () => {
    try {
      const result = await hostingsHelper.getHostings(workspaceid);
      setCustomHostings(
        result?.data?.HostingsByWorkspace?.items?.filter(
          (u) => u?._deleted != true
        ) || []
      );
    } catch (error) {
      console.log("Error getting hostings", error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setInitialLoadCompleted(true);
    }, [2000]);
  }, []);
  useEffect(() => {
    if (values?.llmProvider && initialLoadCompleted) {
      if (
        baseLlmModels?.length > 0 &&
        !baseLlmModels?.find((u) => u?.id == values?.llmBaseModel)
      ) {
        setFieldValue("llmBaseModel", baseLlmModels[0]?.id);
      }
    }
  }, [
    values?.llmProvider,
    initialLoadCompleted,
    JSON.stringify(baseLlmModels),
  ]);
  useEffect(() => {
    if (workspaceid) getCustomHostings();
  }, [workspaceid]);
  useEffect(() => {
    if (getActiveWorkspace()?.subscriptionType) {
      setBaseLlmProviders(
        matchProvidersWithSubscription(
          getActiveWorkspace()?.subscriptionType,
          false,
          customHostings
        )
      );
      setBaseImageModels([
        {
          name: "Mystica SD",
          id: "mystica_sd",
        },
        {
          name: "Mystica realism SD",
          id: "mystica_realism_sd",
        },
      ]);
      setFunctionCallingProviders(
        matchProvidersWithSubscription(
          getActiveWorkspace()?.subscriptionType,
          true,
          customHostings
        )
      );

      setBaseLlmModels(
        matchModelsWithProviders(
          values?.llmProvider,
          getActiveWorkspace()?.subscriptionType,
          false,
          customHostings
        )
      );
      setFunctionCallingModels(
        matchModelsWithProviders(
          values?.functionCallingProvider,
          getActiveWorkspace()?.subscriptionType,
          true,
          customHostings
        )
      );
    }
  }, [
    values?.functionCallingProvider,
    values?.llmProvider,
    JSON.stringify(customHostings),
    getActiveWorkspace()?.subscriptionType,
  ]);
  useEffect(() => {
    if (values?.icon) {
      getUrl(values?.icon);
    } else {
      setAgentImage(null);
    }
  }, [values?.icon]);
  useEffect(() => {
    if (values?.largeLogo) {
      getUrl(values?.largeLogo, true);
    } else {
      setAgentLogo(null);
    }
  }, [values?.largeLogo]);
  useEffect(() => {
    if (values?.voiceFileKey) {
      getUrl(values?.voiceFileKey, true);
    } else {
      setAgentVoice(null);
    }
  }, [values?.voiceFileKey]);
  useEffect(() => {
    if (
      getActiveWorkspace()?.subscriptionType != "enterprise" &&
      getActiveWorkspace()?.enableExperimentalFeatures != true
    ) {
      setFieldValue("llmProvider", "tf");
      setFieldValue("functionCallingProvider", "tf");
    }
    if (
      getActiveWorkspace()?.subscriptionType == "base" &&
      _proModels?.map((u) => u?.id)?.includes(values?.llmBaseModel)
    ) {
      setFieldValue("llmBaseModel", "sorcerer");
    }
    if (
      getActiveWorkspace()?.subscriptionType == "base" &&
      (values?.functionCallingModel ==
        "mistralai/Mixtral-8x22B-Instruct-v0.1" ||
        values?.functionCallingModel == "meta-llama/Llama-3-70b-chat-hf")
    ) {
      setFieldValue("functionCallingModel", "sorcerer");
    }
  }, [getActiveWorkspace()?.subscriptionType]);
  useEffect(() => {
    if (!isEdit) {
      if (isChatBot) {
        setFieldValue(
          "maxTokens",
          values?.mode === "coder" || values?.mode === "chatter" ? 2048 : 512
        );
        setFieldValue("temperature", values?.temperature || 0.5);
        setFieldValue("showReferencesToInternalDocs", true);
        setFieldValue(
          "maxHistory",
          values?.mode === "coder" || values?.mode === "chatter" ? 50 : 3
        );
        setFieldValue("topK", 4);
        setFieldValue("docTopK", 3);
        setFieldValue("hasTopicsContext", isKnowledgeEnabled);
        setFieldValue("hasFunctions", isKnowledgeEnabled);
      } else {
        setFieldValue("hasTopicsContext", true);
      }
    }
  }, [isChatBot, isKnowledgeEnabled, values?.mode, isEdit]);
  useEffect(() => {
    if (values?.hasTopicsContext != true) setFieldValue("blender", false);
  }, [values?.hasTopicsContext]);
  useEffect(() => {
    if (
      values?.mode == "coder" &&
      values?.allowDocsUpload == true &&
      values?.hasCode == true
    ) {
      setCodeUploadDisabled(false);
      setFieldValue("allowDocsUpload", !values?.hasCode);
      setTimeout(() => {
        setCodeUploadDisabled(true);
      }, 300);
    } else if (values?.mode == "coder" && values?.hasCode != true)
      setCodeUploadDisabled(false);
  }, [values?.hasCode, values?.hasCode, values?.mode]);
  useEffect(() => {
    if (values?.isMultiLanguageEnabled) {
      setFieldValue("charting", false);
      setTimeout(() => {
        setDisableDueToTranslation(true);
      }, 500);
    } else {
      setDisableDueToTranslation(false);
    }
  }, [values?.isMultiLanguageEnabled]);
  const handleAudioSelection = async (file) => {
    if (!file) {
      setFieldValue("voiceFileKey", null);
      return;
    }
    // Extract the file name and extension
    // generate a random file name
    const filename = file.name;
    const _fileN = String(Date.now()) + filename;
    const contentType = filename.slice(
      (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
    );
    const result = await S3uploadUrlRequest({
      filename: `${workspaceid}/${_fileN}`,
      importType: "imported_audio_voices",
      contentType,
    });
    const upload = await axios.request({
      method: "put",
      url: JSON.parse(result?.body)?.uploadURL,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (p) => {
        setFileUploadInProgress(true);
        setFileUploadProgress(Math.round((p.loaded * 100) / p.total));
        if (p.loaded === p.total) {
          setFileUploadInProgress(false);
          setFileUploadProgress(100);
        }
      },
    });
    if (JSON.parse(result?.body).uploadURL) {
      setFieldValue(
        "voiceFileKey",
        `imported_audio_voices/${workspaceid}/${_fileN}`
      );
    }
  };
  const handleIconSelection = async (file, isLargeLogo) => {
    console.log("file", file, isLargeLogo);
    if (!file && isLargeLogo) {
      setFieldValue("largeLogo", null);
      return;
    } else if (!file) {
      setFieldValue("icon", null);
      return;
    }
    // Extract the file name and extension
    // generate a random file name
    const filename = file.name;
    const _fileN = String(Date.now()) + filename;
    const contentType = filename.slice(
      (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
    );
    const result = await S3uploadUrlRequest({
      filename: `${workspaceid}/${_fileN}`,
      importType: "imported-image",
      contentType,
    });
    const upload = await axios.request({
      method: "put",
      url: JSON.parse(result?.body)?.uploadURL,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (p) => {
        setFileUploadInProgress(true);
        setFileUploadProgress(Math.round((p.loaded * 100) / p.total));
        if (p.loaded === p.total) {
          setFileUploadInProgress(false);
          setFileUploadProgress(100);
        }
      },
    });
    if (JSON.parse(result?.body).uploadURL) {
      setFieldValue(isLargeLogo ? "largeLogo" : "icon", _fileN);
    }
  };
  useEffect(() => {
    if (
      values?.plainTextOutput ||
      (values?.llmBaseModel != "sorcerer" &&
        values?.llmBaseModel != "mystica" &&
        values?.llmBaseModel != null)
    ) {
      setFieldValue("charting", false);
      setFieldValue("hasImages", false);
      setFieldValue("allowDocsUpload", false);
      setTimeout(() => {
        setDisableMultiModal(true);
      }, 500);
    } else {
      setDisableMultiModal(false);
    }
  }, [
    values?.plainTextOutput,
    values?.llmProvider,
    values?.llmBaseModel,
    values?.isMultiLanguageEnabled,
  ]);
  useEffect(() => {
    if (!values?.allowAudiosGeneration) {
      setFieldValue("autoVoiceMode", false);
    }
  }, [values?.allowAudiosGeneration]);
  useEffect(() => {
    if (values?.restrictedAccess) {
      setFieldValue("preventWidgetUsage", true);
    }
  }, [values?.restrictedAccess]);
  function getMaxTokens(
    isContentCreator,
    isChatBot,
    getActiveWorkspace,
    values
  ) {
    if (isContentCreator) {
      return getActiveWorkspace()?.isExtendedContentCreatorEnabled
        ? 12000
        : 6000;
    } else if (isChatBot) {
      if (
        values?.mode === "coder" ||
        values?.mode === "chatter" ||
        values?.mode === "retriever"
      )
        return 4096;
      else {
        return 4096;
      }
    } else {
      return 4096;
    }
  }

  const _topics = getWorkspaceEntities("topic")?.map((topic) => {
    topic.name = topic["label"];
    topic.category = topic["type"];
    return { ...topic };
  });

  const _agents = getAgents()
    ?.filter((u) => u?.agentType == "Chatbot" && u?.mode != "planner")
    ?.map((agent) => {
      return {
        id: agent["id"],
        label: agent["label"],
        name: agent["label"],
        value: agent["id"],
        category: agent["agentType"],
        backgroundColor: colorsMappings.mapBackgroundColorWithAgentTypeAndMode(
          agent,
          currentTheme
        ),
      };
    });
  const appendCustomProviders = (baseModels, customHostings, type) => {
    const togetherHostings = customHostings?.filter((u) => u?.type == type);
    for (const hosting of togetherHostings) {
      baseModels.push({
        name: hosting?.name,
        id: hosting?.name,
      });
    }
    return baseModels;
  };

  const matchProvidersWithSubscription = (
    subscriptionPlan,
    functionCalling = false,
    customHostings = []
  ) => {
    if (functionCalling) {
      let baseModels = [{ name: "ToothFairyAI", id: "tf" }];
      if (customHostings?.find((u) => u?.type == "together"))
        appendCustomProviders(baseModels, customHostings, "together");

      if (customHostings?.find((u) => u?.type == "azure_openai"))
        appendCustomProviders(baseModels, customHostings, "azure_openai");
      if (customHostings?.find((u) => u?.type == "aws"))
        appendCustomProviders(baseModels, customHostings, "aws");
      return baseModels;
    }
    let hostingModels = [{ name: "ToothFairyAI", id: "tf" }] || [];
    if (customHostings?.find((u) => u?.type == "together"))
      appendCustomProviders(hostingModels, customHostings, "together");
    if (customHostings?.find((u) => u?.type == "replicate"))
      appendCustomProviders(hostingModels, customHostings, "replicate");
    if (
      subscriptionPlan === "enterprise" ||
      getActiveWorkspace()?.enableExperimentalFeatures
    ) {
      if (customHostings?.find((u) => u?.type == "aws"))
        appendCustomProviders(hostingModels, customHostings, "aws");
      if (customHostings?.find((u) => u?.type == "openai"))
        appendCustomProviders(hostingModels, customHostings, "openai");
      if (customHostings?.find((u) => u?.type == "azure_openai"))
        appendCustomProviders(hostingModels, customHostings, "azure_openai");
      if (customHostings?.find((u) => u?.type == "anthropic"))
        appendCustomProviders(hostingModels, customHostings, "anthropic");
      if (customHostings?.find((u) => u?.type == "google"))
        appendCustomProviders(hostingModels, customHostings, "google");
      if (customHostings?.find((u) => u?.type == "local"))
        appendCustomProviders(hostingModels, customHostings, "local");
    }
    return hostingModels;
  };

  useEffect(() => {
    if (values?.llmProvider && values?.llmBaseModel != null) {
    }
  }, [values?.llmProvider]);
  const matchModelsWithProviders = (
    llmProvider,
    subscriptionPlan,
    functionCalling = false,
    customHostings = []
  ) => {
    const llmProviderType = customHostings?.find(
      (u) => u?.name == llmProvider
    )?.type;
    const customModels =
      customHostings
        ?.filter((u) => u?.name == llmProvider)
        ?.map((u) => {
          if (u?.modelName)
            return {
              name: u?.modelName,
              id: u?.modelName,
            };
        })
        ?.filter((u) => u) || [];
    if (functionCalling) {
      switch (llmProviderType) {
        case "tf":
          return [
            {
              name: "Sorcerer",
              id: "sorcerer",
            },
            {
              name: "Mystica",
              id: "mystica",
            },
          ];
        case "together":
          return [
            {
              name: "Mistral 7b",
              id: "mistralai/Mistral-7B-Instruct-v0.1",
            },
            {
              name: "Mixtral 8x7b",
              id: "mistralai/Mixtral-8x7B-Instruct-v0.1",
            },
            ...customModels,
          ];
        case "aws":
          return [
            {
              name: "Claude Sonnet",
              id: "anthropic.claude-3-sonnet-20240229-v1:0",
            },
            {
              name: "Claude Haiku",
              id: "anthropic.claude-3-haiku-20240307-v1:0",
            },
            {
              name: "Mistral Large",
              id: "mistral.mistral-large-2402-v1:0",
            },
            ...customModels,
          ];
        case "azure_openai":
          return customModels;
        default:
          return [
            {
              name: "Sorcerer",
              id: "sorcerer",
            },
            {
              name: "Mystica",
              id: "mystica",
            },
          ];
      }
    }
    // here I need to load the hosting records so that I can populate the dropdown correctly
    else
      switch (llmProviderType) {
        case "tf":
          const baseModel = [..._baseModels];
          if (
            subscriptionPlan === "pro" ||
            subscriptionPlan === "enterprise" ||
            getActiveWorkspace()?.enableExperimentalFeatures
          )
            baseModel.push(..._proModels);

          return baseModel;

        case "aws":
          if (
            subscriptionPlan === "enterprise" ||
            getActiveWorkspace()?.enableExperimentalFeatures
          )
            return [
              {
                name: "Mistral 7b",
                id: "mistral.mistral-7b-instruct-v0:2",
              },
              {
                name: "Mixtral 8x7b",
                id: "mistral.mixtral-8x7b-instruct-v0:1",
              },
              {
                name: "Mistral Large",
                id: "mistral.mistral-large-2402-v1:0",
              },
              {
                name: "Claude Sonnet",
                id: "anthropic.claude-3-sonnet-20240229-v1:0",
              },
              {
                name: "Claude Haiku",
                id: "anthropic.claude-3-haiku-20240307-v1:0",
              },
              ...customModels,
            ];
          else return [];
        case "openai":
          if (
            subscriptionPlan === "enterprise" ||
            getActiveWorkspace()?.enableExperimentalFeatures
          )
            return [
              {
                name: "GPT 4 Omni",
                id: "gpt-4o",
              },
              ...customModels,
            ];
          else return [];
        case "azure_openai":
          if (
            subscriptionPlan === "enterprise" ||
            getActiveWorkspace()?.enableExperimentalFeatures
          )
            return customModels;
          else return [];
        case "together":
          return [
            ..._baseModels?.filter((u) => u?.tfOnly != true),
            ..._proModels,
            ...customModels,
          ];
        case "replicate":
          return [
            {
              name: "Mistral 7b v0.1",
              id: "mistralai/Mistral-7B-Instruct-v0.1",
            },
            {
              name: "Mistral 7b v0.1",
              id: "mistralai/Mistral-7B-Instruct-v0.2",
            },
            {
              name: "Mixtral 8x7b",
              id: "mistralai/Mixtral-8x7B-Instruct-v0.1",
            },
            {
              name: "Llama 3 8b",
              id: "meta/meta-llama-3-8b-instruct",
            },
            {
              name: "Llama 3 70b",
              id: "meta/meta-llama-3-70b-instruct",
            },
            ...customModels,
          ];
        case "anthropic":
          return [
            {
              name: "Claude 3 Sonnet",
              id: "claude-3-sonnet-20240229",
            },
            {
              name: "Claude 3 Haiku",
              id: "claude-3-haiku-20240307",
            },
            {
              name: "Claude 3 Opus",
              id: "claude-3-opus-20240229",
            },
            {
              name: "Claude 3.5 Sonnet",
              id: "claude-3-5-sonnet-20240620",
            },
            ...customModels,
          ];
        case "google":
          return [
            {
              name: "Gemini 1.5 Pro",
              id: "gemini-1.5-pro",
            },
            {
              name: "Gemini 1.5 Pro Experimental",
              id: "gemini-1.5-pro-exp-0801",
            },
            {
              name: "Gemini 1.5 Flash",
              id: "gemini-1.5-flash",
            },
            ...customModels,
          ];
        case "local":
          return [...customModels];

        default:
          const baseModels = [..._baseModels];
          if (
            subscriptionPlan === "pro" ||
            subscriptionPlan === "enterprise" ||
            getActiveWorkspace()?.enableExperimentalFeatures
          )
            baseModels.push(..._proModels);
          return baseModels;
      }
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.border_color,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
      borderRadius: 20,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let color = new Color(data?.backgroundColor || currentTheme?.theme?.grey);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? currentTheme?.theme?.white
            ? "white"
            : "black"
          : data?.backgroundColor,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data?.backgroundColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      let color = new Color(data?.backgroundColor || currentTheme?.theme?.grey);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor,
      ":hover": {
        backgroundColor: data?.backgroundColor,
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.lightGray,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
    }),
  };

  return (
    <>
      {isChatBot && (
        <ImagePlaceholder
          onFileSelection={handleIconSelection}
          imagePath={agentImage}
          isFileUploadInProgress={fileUploadInProgress}
          fileUploadProgress={fileUploadProgress}
        />
      )}

      {isEdit && (
        <AdminInfoLeaf
          wrapperStyle={{
            alignSelf: "flex-start",
            marginBottom: 10,
          }}
          textContainer={{
            marginLeft: 5,
            marginTop: 10,
            marginBottom: 0,
          }}
          titleStyle={{
            marginTop: 0,
            marginLeft: 20,
          }}
          textStyle={{
            marginLeft: 0,
          }}
          title={"ID"}
          value={initialMapValues?.id}
          width={420}
        />
      )}
      <View>
        <AppForms.AppFormField
          showTextInputLabel
          isMandatory
          wrapperInputStyle={{
            paddingTop: 10,
          }}
          label={`Name`}
          placeholder={`Agent name`}
          keyboardtype="default"
          textContextType="label"
          autoCorrect={false}
          name="label"
        />
      </View>
      <View>
        <AppForms.AppFormField
          showTextInputLabel
          wrapperInputStyle={{
            paddingTop: 10,
          }}
          textContainer={{
            minHeight: 120,
            paddingVertical: 10,
          }}
          multiline={true}
          label={`Domain`}
          placeholder={`subject_name_placeholder`}
          keyboardtype="default"
          textContextType="label"
          autoCorrect={false}
          name="subject"
        />
      </View>
      {values?.mode != null && values?.mode != "planner" && (
        <View>
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            multiline={true}
            label={`Agent description`}
            placeholder={`agent_description_placeholder`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="description"
          />
        </View>
      )}
      <View>
        {isChatBot && (
          <View>
            <AppForms.AppFormDropDown
              isElevated={false}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: !isLargeScreen() && 250,
              }}
              showTextInputLabel
              label="Mode"
              isMandatory
              name="mode"
              items={_modes}
              propertyLabel="name"
              propertyValue="id"
            />
            <AppText
              leftIcon={AppAssets?.icons?.info}
              wrapperStyle={{
                paddingHorizontal: 10,
              }}
              color={currentTheme?.theme?.orange}
              style={{
                marginLeft: 10,
              }}
              iconSize={14}
            >
              {generateModes()?.find((u) => u?.id == values?.mode)?.description}
            </AppText>
          </View>
        )}

        {values?.mode === "custom" && (
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Overview"
            isMandatory
            name="qaUrl"
            items={
              [
                { name: "Default", propertyValue: "default_qa_model" },
                ...getTrainingLogsWithModels("readComprehensionTraining"),
              ] || []
            }
            propertyLabel="name"
            propertyValue="id"
          />
        )}
      </View>
      {isKnowledgeEnabled && (
        <>
          {isChatBot && values?.mode != "coder" && (
            <View>
              <AppForms.AppFormSwitch
                wrapperStyle={[
                  {
                    backgroundColor: isEdit
                      ? currentTheme?.theme?.grey
                      : currentTheme?.theme?.lightGrey,
                    maxHeight: 30,
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    paddingVertical: 18,
                    margin: 5,
                    marginTop: 10,
                  },
                ]}
                labelTextStyle={{
                  fontSize: currentTheme?.theme?.small_font_size,
                  color: currentTheme?.theme?.darkGrey,
                }}
                showTextInputLabel={true}
                label={"Step by step reasoning"}
                displayIsTicked={true}
                name="blender"
                value={values?.blender}
                disabled={
                  values?.hasTopicsContext != true &&
                  values?.hasFunctions != true
                }
              />
            </View>
          )}
        </>
      )}
      {isChatBot && values?.mode == "coder" && (
        <View>
          <AppForms.AppFormSwitch
            wrapperStyle={[
              {
                backgroundColor: isEdit
                  ? currentTheme?.theme?.grey
                  : currentTheme?.theme?.lightGrey,
                maxHeight: 30,
                paddingHorizontal: 10,
                borderRadius: 20,
                paddingVertical: 18,
                margin: 5,
                marginTop: 10,
              },
            ]}
            labelTextStyle={{
              fontSize: currentTheme?.theme?.small_font_size,
              color: currentTheme?.theme?.darkGrey,
            }}
            showTextInputLabel={true}
            label={"Code execution"}
            displayIsTicked={true}
            name="hasCode"
            value={values?.hasCode}
            disabled={false}
          />
          <AppForms.AppFormSwitch
            wrapperStyle={[
              {
                backgroundColor: isEdit
                  ? currentTheme?.theme?.grey
                  : currentTheme?.theme?.lightGrey,
                maxHeight: 30,
                paddingHorizontal: 10,
                borderRadius: 20,
                paddingVertical: 18,
                margin: 5,
                marginTop: 20,
              },
            ]}
            labelTextStyle={{
              fontSize: currentTheme?.theme?.small_font_size,
              color: currentTheme?.theme?.darkGrey,
            }}
            showTextInputLabel={true}
            label={"Code upload"}
            displayIsTicked={true}
            name="allowDocsUpload"
            value={values?.allowDocsUpload}
            disabled={!isTfModel || codeUploadDisabled}
          />
        </View>
      )}

      <View>
        {isChatBot && values?.mode == "coder" && values?.hasCode && (
          <View
            style={{
              zIndex: 10000,
            }}
          >
            <AppExpander
              expanderStyle={{
                zIndex: 10000,
              }}
              style={{ minWidth: 250, zIndex: 20 }}
              isExpandedOnLoad={true}
              title="Execution environments settings"
              icon={AppAssets.icons.code}
            >
              <View
                style={{
                  paddingLeft: 10,
                  zIndex: 20,
                }}
              >
                <AppForms.AppFormMultiSelect
                  name="customCodeExecutionEnvironments"
                  placeholder="Assign code environments"
                  hidePlaceholder={true}
                  items={customCodeExecutionEnvironments}
                  uniqueKey="id"
                  showDropDowns={true}
                  readOnlyHeadings={true}
                  labelOption="name"
                  groupByProperty="type"
                  style={{ maxWidth: 800 }}
                  wrapperContainerStyle={{
                    flex: !isLargeScreen() ? 1 : 0.5,
                    zIndex: 20,
                    paddingTop: 10,
                    marginBottom: 30,
                  }}
                  styles={colourStyles}
                  options={customCodeExecutionEnvironments?.map((u) => {
                    return {
                      ...u,
                      label:
                        u?.name +
                        " - " +
                        Utility?.mapFunctionTypeToLabel("chat"),
                      value: u?.id,
                      backgroundColor: Utility?.mapFunctionTypeToColour(
                        u,
                        currentTheme
                      ),
                    };
                  })}
                />
              </View>
              <AppText
                style={{
                  paddingLeft: 5,

                  fontSize: currentTheme?.theme?.extra_small_font_size,
                }}
                wrapperStyle={{
                  top: -10,
                  justifyContent: "center",
                }}
                iconSize={12}
                leftIcon={AppAssets?.icons?.question_icon}
              >
                Only one environment can be used at a each turn depending on the
                code function used.
              </AppText>
            </AppExpander>
          </View>
        )}
        {values?.hasTopicsContext && isKnowledgeEnabled && (
          <AppExpander
            style={{ minWidth: 250 }}
            isExpandedOnLoad={true}
            title="Knowledge settings"
            icon={AppAssets.icons.notes}
          >
            <View
              style={{
                paddingLeft: 10,
              }}
            >
              <AppForms.AppFormMultiSelect
                name="allowedTopics"
                topErrorOffset={-21}
                touchedByDefault
                placeholder="Assign topics"
                hidePlaceholder={true}
                items={_topics}
                uniqueKey="id"
                showDropDowns={true}
                readOnlyHeadings={true}
                labelOption="label"
                groupByProperty="type"
                style={{ maxWidth: 800 }}
                wrapperContainerStyle={{
                  flex: !isLargeScreen() ? 1 : 0.5,
                  zIndex: 20,
                  paddingTop: 10,
                  marginBottom: 40,
                }}
                styles={colourStyles}
                options={_topics.map((u) => {
                  return {
                    ...u,
                    value: u?.id,
                  };
                })}
              />
              {isChatBot && (
                <View
                  style={{
                    marginLeft: 20,
                  }}
                >
                  <AppForms.AppFormSlider
                    step={1}
                    maximumValue={5}
                    minimumValue={1}
                    label="Max history"
                    name="maxHistory"
                    showEditButtons={true}
                  />
                </View>
              )}

              <View
                style={{
                  paddingBottom: 20,
                }}
              >
                <AppForms.AppFormSwitch
                  // disabled={isEdit}
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                      marginTop: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Keywords for knowledge base query"}
                  displayIsTicked={true}
                  name="keyWordsForKnowledgeBase"
                  value={values?.keyWordsForKnowledgeBase}
                />
              </View>
              <View
                style={{
                  marginLeft: 20,
                }}
              >
                <AppForms.AppFormSlider
                  step={1}
                  maximumValue={10}
                  minimumValue={1}
                  label="Max keywords"
                  name="promptTopKeywords"
                />
              </View>
              <View
                style={{
                  marginLeft: 20,
                }}
              >
                <AppForms.AppFormSlider
                  step={1}
                  maximumValue={
                    isBusinessAnalyst
                      ? getActiveWorkspace()
                          ?.isExtendedBusinessAnalystReportEnabled
                        ? 50
                        : 25
                      : 20
                  }
                  minimumValue={1}
                  label="TopK"
                  name="topK"
                  showEditButtons={true}
                />
              </View>
              <View
                style={{
                  marginLeft: 20,
                }}
              >
                <AppForms.AppFormSlider
                  step={1}
                  maximumValue={
                    isBusinessAnalyst
                      ? getActiveWorkspace()
                          ?.isExtendedBusinessAnalystReportEnabled
                        ? 50
                        : 25
                      : 20
                  }
                  minimumValue={1}
                  label="Doc TopK"
                  name="docTopK"
                  showEditButtons={true}
                />
              </View>
              <View
                style={{
                  marginLeft: 20,
                }}
              >
                <AppForms.AppFormSlider
                  step={0.01}
                  maximumValue={0.99}
                  minimumValue={0.01}
                  label="Min retrieval accuracy"
                  name="minRetrievalScore"
                />
                <AppForms.AppFormSlider
                  step={1}
                  maximumValue={5}
                  minimumValue={0}
                  label="Recency importance"
                  name="recencyImportance"
                />
                <AppForms.AppFormSlider
                  step={1}
                  maximumValue={5}
                  minimumValue={0}
                  label="Keywords importance"
                  name="keywordsWeightInRetrieval"
                />
              </View>

              {isBusinessAnalyst &&
                getActiveWorkspace()
                  ?.isExtendedBusinessAnalystReportEnabled && (
                  <View
                    style={{
                      marginLeft: 0,
                    }}
                  >
                    <AppForms.AppFormSwitch
                      // disabled={isEdit}
                      // disabled={true}
                      wrapperStyle={[
                        {
                          backgroundColor: isEdit
                            ? currentTheme?.theme?.grey
                            : currentTheme?.theme?.lightGrey,
                          maxHeight: 30,
                          paddingHorizontal: 10,
                          borderRadius: 20,
                          paddingVertical: 18,
                          margin: 5,
                        },
                      ]}
                      labelTextStyle={{
                        fontSize: currentTheme?.theme?.small_font_size,
                        color: currentTheme?.theme?.darkGrey,
                      }}
                      showTextInputLabel={true}
                      label={"Extended report"}
                      displayIsTicked={true}
                      name="generateExtendedReport"
                      value={values?.generateExtendedReport}
                    />
                  </View>
                )}
            </View>
          </AppExpander>
        )}
      </View>
      {isChatBot && isKnowledgeEnabled && values?.mode != "coder" && (
        <View
          style={{
            zIndex: 10000,
          }}
        >
          <AppExpander
            expanderStyle={{
              zIndex: 10000,
            }}
            style={{ minWidth: 250, zIndex: 20 }}
            isExpandedOnLoad={false}
            title="Functions settings"
            icon={AppAssets.icons.agentFunction}
          >
            <View
              style={{
                paddingLeft: 10,
                zIndex: 20,
              }}
            >
              <AppForms.AppFormMultiSelect
                name="agentFunctions"
                placeholder="Assign functions"
                hidePlaceholder={true}
                items={availableFunctions}
                uniqueKey="id"
                showDropDowns={true}
                readOnlyHeadings={true}
                labelOption="name"
                groupByProperty="type"
                style={{ maxWidth: 800 }}
                wrapperContainerStyle={{
                  flex: !isLargeScreen() ? 1 : 0.5,
                  zIndex: 20,
                  paddingTop: 10,
                  marginBottom: 30,
                }}
                styles={colourStyles}
                options={availableFunctions.map((u) => {
                  return {
                    ...u,
                    label: u?.name + " - " + Utility?.mapFunctionTypeToLabel(u),
                    value: u?.id,
                    backgroundColor: Utility?.mapFunctionTypeToColour(
                      u,
                      currentTheme
                    ),
                  };
                })}
              />
            </View>
            <AppText
              style={{
                paddingLeft: 5,

                fontSize: currentTheme?.theme?.extra_small_font_size,
              }}
              wrapperStyle={{
                top: -10,
                justifyContent: "center",
              }}
              iconSize={12}
              leftIcon={AppAssets?.icons?.question_icon}
            >
              Chat scripts and HTML functions override both API and DB functions
              when either of them are triggered
            </AppText>
          </AppExpander>
        </View>
      )}

      {isChatBot && isKnowledgeEnabled && values?.mode != "coder" && (
        <AppExpander
          style={{ minWidth: 250, zIndex: -1 }}
          title="Internet search"
          icon={AppAssets.icons.network}
        >
          <AppForms.AppFormSwitch
            wrapperStyle={[
              {
                backgroundColor: isEdit
                  ? currentTheme?.theme?.grey
                  : currentTheme?.theme?.lightGrey,
                maxHeight: 30,
                paddingHorizontal: 10,
                borderRadius: 20,
                paddingVertical: 18,
                marginHorizontal: 5,
                marginVertical: 20,
              },
            ]}
            labelTextStyle={{
              fontSize: currentTheme?.theme?.small_font_size,
              color: currentTheme?.theme?.darkGrey,
            }}
            showTextInputLabel={true}
            label={"Allow internet search"}
            displayIsTicked={true}
            name="allowInternetSearch"
            value={values?.allowInternetSearch}
          />
          <View
            style={{
              paddingLeft: 0,
              zIndex: 20,
            }}
          >
            <AppForms.AppFormMultiSelect
              name="internetSearchModes"
              placeholder="Select search modes"
              hidePlaceholder={true}
              items={[
                {
                  label: "search",
                  value: "search",
                  backgroundColor: currentTheme?.theme?.primary,
                },
                {
                  label: "news",
                  value: "news",
                  backgroundColor: currentTheme?.theme?.green,
                },
                {
                  label: "images",
                  value: "images",
                  backgroundColor: currentTheme?.theme?.orange,
                },
                {
                  label: "shopping",
                  value: "shopping",
                  backgroundColor: currentTheme?.theme?.purple,
                },
                {
                  label: "videos",
                  value: "videos",
                  backgroundColor: currentTheme?.theme?.red,
                },
              ]}
              uniqueKey="value"
              showDropDowns={true}
              readOnlyHeadings={true}
              labelOption="name"
              groupByProperty="type"
              style={{ maxWidth: 800 }}
              wrapperContainerStyle={{
                flex: !isLargeScreen() ? 1 : 0.5,
                zIndex: 20,
                paddingTop: 10,
                marginBottom: 30,
                paddingHorizontal: 3,
              }}
              styles={colourStyles}
              options={[
                {
                  label: "search",
                  value: "search",
                  backgroundColor: currentTheme?.theme?.primary,
                },
                {
                  label: "news",
                  value: "news",
                  backgroundColor: currentTheme?.theme?.green,
                },
                {
                  label: "images",
                  value: "images",
                  backgroundColor: currentTheme?.theme?.orange,
                },
                {
                  label: "shopping",
                  value: "shopping",
                  backgroundColor: currentTheme?.theme?.purple,
                },
                {
                  label: "videos",
                  value: "videos",
                  backgroundColor: currentTheme?.theme?.red,
                },
              ]}
            />
            <AppForms.AppFormDropDown
              isElevated={false}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: !isLargeScreen() && 250,
              }}
              showTextInputLabel
              label="Internet search location"
              isMandatory
              name="internetSearchLocation"
              items={internetSearchLocations?.map((u) => {
                {
                  return {
                    ...u,
                    id: u?.name,
                  };
                }
              })}
              propertyLabel="name"
              propertyValue="id"
            />
          </View>
          <View
            style={{
              marginLeft: 20,
              paddingLeft: 0,
            }}
          >
            <AppForms.AppFormSlider
              step={1}
              maximumValue={10}
              minimumValue={1}
              label="Max search results"
              name="maxUrlsForInternetSearch"
              showEditButtons={true}
            />
          </View>

          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline={true}
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            errorOffset={0}
            label={`Excluded domains from search`}
            placeholder={`Insert the domains you do not want to include in the search seperated by a comma (e.g. https://www.example.com,https://www.example2.com)`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="bannedDomains"
          />
          <AppForms.AppFormSwitch
            wrapperStyle={[
              {
                backgroundColor: isEdit
                  ? currentTheme?.theme?.grey
                  : currentTheme?.theme?.lightGrey,
                maxHeight: 30,
                paddingHorizontal: 10,
                borderRadius: 20,
                paddingVertical: 18,
                marginHorizontal: 5,
                marginVertical: 20,
              },
            ]}
            labelTextStyle={{
              fontSize: currentTheme?.theme?.small_font_size,
              color: currentTheme?.theme?.darkGrey,
            }}
            showTextInputLabel={true}
            label={"Allow deep search"}
            displayIsTicked={true}
            name="allowDeepInternetSearch"
            value={values?.allowDeepInternetSearch}
          />
        </AppExpander>
      )}
      {isChatBot && isCharacterNeeded && (
        <AppExpander
          expanderStyle={{
            zIndex: -1,
          }}
          style={{ minWidth: 250, zIndex: -1 }}
          title="Agent instructions"
          icon={AppAssets.icons.persona}
        >
          <View
            style={{
              paddingLeft: 10,
            }}
          >
            <View>
              <AppForms.AppFormField
                showTextInputLabel
                isMandatory
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                textContainer={{
                  minHeight: 480,
                  paddingVertical: 10,
                }}
                multiline={true}
                label={`Agent role and instructions`}
                placeholder={`Interpolation_role_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="interpolationString"
              />
              <AppForms.AppFormField
                showTextInputLabel
                isMandatory
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                textContainer={{
                  minHeight: 480,
                  paddingVertical: 10,
                }}
                multiline={true}
                label={`Agent tooling guidelines`}
                placeholder={`guidelines_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="customToolingInstructions"
              />
            </View>
            <View>
              <AppForms.AppFormField
                showTextInputLabel
                isMandatory
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                textContainer={{
                  minHeight: 120,
                  paddingVertical: 10,
                }}
                multiline={true}
                label={`Default answer`}
                placeholder={`default_answer_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="defaultAnswer"
              />
            </View>
            <View>
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 240,
                  paddingVertical: 10,
                }}
                label={`Goals`}
                placeholder={`goals_passage_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="goals"
              />
            </View>

            <View>
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 120,
                  paddingVertical: 10,
                }}
                label={`Inhibition passage`}
                placeholder={`inhibition_passage_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="inhibitionPassage"
              />
            </View>
            <View>
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 120,
                  paddingVertical: 10,
                }}
                label={`Pertinance passage`}
                placeholder={`pertinance_passage_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="pertinencePassage"
              />
            </View>
          </View>
        </AppExpander>
      )}
      <View
        style={{
          zIndex: 10,
        }}
      >
        {isChatBot && values?.mode == "planner" && (
          <AppExpander
            expanderStyle={{
              zIndex: 10000,
            }}
            style={{ minWidth: 250, zIndex: 20 }}
            title="Planner tools"
            icon={AppAssets.icons.agentsPlan}
          >
            <View
              style={{
                paddingLeft: 10,
              }}
            >
              <AppForms.AppFormMultiSelect
                name="agentsPool"
                topErrorOffset={-21}
                touchedByDefault
                placeholder="Available agents for planning"
                hidePlaceholder={true}
                items={_agents}
                uniqueKey="id"
                showDropDowns={true}
                readOnlyHeadings={true}
                labelOption="label"
                groupByProperty="type"
                style={{ maxWidth: 800 }}
                wrapperContainerStyle={{
                  flex: !isLargeScreen() ? 1 : 0.5,
                  zIndex: 20,
                  paddingTop: 10,
                  marginBottom: 40,
                }}
                styles={colourStyles}
                options={_agents.map((u) => {
                  return {
                    ...u,
                    value: u?.id,
                  };
                })}
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 480,
                  paddingVertical: 10,
                }}
                label={`Planning instructions`}
                placeholder={`planning_instructions_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="planningInstructions"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 240,
                  paddingVertical: 10,
                }}
                label={`Review instructions`}
                placeholder={`review_instructions_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="reviewInstructions"
              />
              <AppForms.AppFormSecureTextInput
                minimumValue={1}
                maximumValue={10}
                step={1}
                label="Max execution steps"
                inputType="SLIDER"
                name="maxPlanningSteps"
              />
              <AppForms.AppFormSecureTextInput
                minimumValue={1}
                maximumValue={5}
                step={1}
                label="Max re-attempts"
                inputType="SLIDER"
                name="plannerExecutionAttempts"
              />
              <AppForms.AppFormSwitch
                // disabled={isEdit}
                // disabled={true}
                wrapperStyle={[
                  {
                    backgroundColor: isEdit
                      ? currentTheme?.theme?.grey
                      : currentTheme?.theme?.lightGrey,
                    maxHeight: 30,
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    paddingVertical: 18,
                    margin: 5,
                  },
                ]}
                labelTextStyle={{
                  fontSize: currentTheme?.theme?.small_font_size,
                  color: currentTheme?.theme?.darkGrey,
                }}
                showTextInputLabel={true}
                label={"Approval before execution"}
                displayIsTicked={true}
                name="plannerRequiresApprovalForPlanExecution"
                value={values?.plannerRequiresApprovalForPlanExecution}
              />
            </View>
          </AppExpander>
        )}
      </View>
      {isChatBot && !["coder"].includes(values?.mode) && (
        <AppExpander
          style={{ minWidth: 250, zIndex: -1 }}
          title="Agent tools"
          icon={AppAssets.icons.advanced}
        >
          {values?.mode != "planner" && (
            <View
              style={{
                paddingLeft: 10,
              }}
            >
              <View>
                <AppForms.AppFormSwitch
                  // disabled={isEdit}
                  // disabled={true}
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Summarised memory"}
                  displayIsTicked={true}
                  name="summarisation"
                  value={values?.summarisation}
                />
              </View>
              {getPlanConfig()?.isLongTermMemoryEnabled && (
                <View>
                  <AppForms.AppFormSwitch
                    // disabled={isEdit}
                    disabled={values?.summarisation == false}
                    wrapperStyle={[
                      {
                        backgroundColor: isEdit
                          ? currentTheme?.theme?.grey
                          : currentTheme?.theme?.lightGrey,
                        maxHeight: 30,
                        paddingHorizontal: 10,
                        borderRadius: 20,
                        paddingVertical: 18,
                        margin: 5,
                        marginTop: 20,
                      },
                    ]}
                    labelTextStyle={{
                      fontSize: currentTheme?.theme?.small_font_size,
                      color: currentTheme?.theme?.darkGrey,
                    }}
                    showTextInputLabel={true}
                    label={"Long term memory"}
                    displayIsTicked={true}
                    name="hasMemory"
                    value={values?.hasMemory}
                  />
                </View>
              )}

              <View>
                <AppForms.AppFormSwitch
                  // disabled={isEdit}
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                      marginTop: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Multilingual"}
                  displayIsTicked={true}
                  name="isMultiLanguageEnabled"
                  value={values?.isMultiLanguageEnabled !== false}
                />
              </View>
            </View>
          )}

          {isChatBot && values?.mode != "coder" && (
            <>
              <View
                style={{
                  paddingLeft: 10,
                }}
              >
                <AppForms.AppFormSwitch
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                      marginTop: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Generate charts"}
                  displayIsTicked={true}
                  name="charting"
                  value={values?.charting}
                  disabled={disableMultiModal || disableDueToTranslation}
                />
                {["chatter", "planner"]?.includes(values?.mode) && (
                  <AppForms.AppFormSwitch
                    wrapperStyle={[
                      {
                        backgroundColor: isEdit
                          ? currentTheme?.theme?.grey
                          : currentTheme?.theme?.lightGrey,
                        maxHeight: 30,
                        paddingHorizontal: 10,
                        borderRadius: 20,
                        paddingVertical: 18,
                        margin: 5,
                        marginTop: 20,
                      },
                    ]}
                    labelTextStyle={{
                      fontSize: currentTheme?.theme?.small_font_size,
                      color: currentTheme?.theme?.darkGrey,
                    }}
                    showTextInputLabel={true}
                    label={"Enable docs upload"}
                    displayIsTicked={true}
                    name="allowDocsUpload"
                    value={values?.allowDocsUpload}
                    disabled={disableMultiModal && values?.mode != "planner"}
                  />
                )}

                <AppForms.AppFormSwitch
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                      marginTop: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Enable images upload"}
                  displayIsTicked={true}
                  name="allowImagesUpload"
                  value={values?.allowImagesUpload}
                />
                <AppForms.AppFormSwitch
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                      marginTop: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Enable audio upload"}
                  displayIsTicked={true}
                  name="allowAudiosUpload"
                  value={values?.allowAudiosUpload}
                />
                <AppForms.AppFormSwitch
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                      marginTop: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Enable video upload"}
                  displayIsTicked={true}
                  name="allowVideosUpload"
                  value={values?.allowVideosUpload}
                />
                <>
                  <AppForms.AppFormSwitch
                    wrapperStyle={[
                      {
                        backgroundColor: isEdit
                          ? currentTheme?.theme?.grey
                          : currentTheme?.theme?.lightGrey,
                        maxHeight: 30,
                        paddingHorizontal: 10,
                        borderRadius: 20,
                        paddingVertical: 18,
                        margin: 5,
                        marginTop: 20,
                      },
                    ]}
                    labelTextStyle={{
                      fontSize: currentTheme?.theme?.small_font_size,
                      color: currentTheme?.theme?.darkGrey,
                    }}
                    showTextInputLabel={true}
                    label={"Enable audio generation"}
                    displayIsTicked={true}
                    name="allowAudiosGeneration"
                    value={values?.allowAudiosGeneration}
                    disabled={getActiveWorkspace()?.subscriptionType == "base"}
                  />

                  <AppForms.AppFormSwitch
                    wrapperStyle={[
                      {
                        backgroundColor: isEdit
                          ? currentTheme?.theme?.grey
                          : currentTheme?.theme?.lightGrey,
                        maxHeight: 30,
                        paddingHorizontal: 10,
                        borderRadius: 20,
                        paddingVertical: 18,
                        margin: 5,
                        marginTop: 20,
                      },
                    ]}
                    labelTextStyle={{
                      fontSize: currentTheme?.theme?.small_font_size,
                      color: currentTheme?.theme?.darkGrey,
                    }}
                    showTextInputLabel={true}
                    label={`Enable speech to speech${
                      getActiveWorkspace()?.subscriptionType != "enterprise"
                        ? " - Enterprise only"
                        : ""
                    } `}
                    displayIsTicked={true}
                    name="autoVoiceMode"
                    value={values?.autoVoiceMode}
                    disabled={
                      !values?.allowAudiosGeneration ||
                      getActiveWorkspace()?.subscriptionType != "enterprise"
                    }
                  />
                  <View>
                    <AudioPlaceholder
                      audioPath={values?.voiceFileKey}
                      onFileSelection={(file) => handleAudioSelection(file)}
                      isFileUploadInProgress={fileUploadInProgress}
                      fileUploadProgress={fileUploadProgress}
                      isVoiceRecordingInProgress={isAudioRecording}
                    >
                      <AudioRecorder
                        onRecordingCompleted={handleRecordingCompleted}
                        onRecordingStarted={handleRecordingStarted}
                        style={{
                          justifyContent: "flex-start",
                          marginTop: 0,
                          marginLeft: 10,
                        }}
                        onForcedStop={() => {
                          setIsAudioRecording(false);
                        }}
                      />
                    </AudioPlaceholder>
                  </View>
                </>
              </View>
              {isKnowledgeEnabled && values?.mode != "coder" && (
                <View
                  style={{
                    paddingLeft: 10,
                  }}
                >
                  <AppForms.AppFormSwitch
                    wrapperStyle={[
                      {
                        backgroundColor: isEdit
                          ? currentTheme?.theme?.grey
                          : currentTheme?.theme?.lightGrey,
                        maxHeight: 30,
                        paddingHorizontal: 10,
                        borderRadius: 20,
                        paddingVertical: 18,
                        margin: 5,
                        marginTop: 20,
                      },
                    ]}
                    labelTextStyle={{
                      fontSize: currentTheme?.theme?.small_font_size,
                      color: currentTheme?.theme?.darkGrey,
                    }}
                    showTextInputLabel={true}
                    label={"Enhance answers with NER"}
                    displayIsTicked={true}
                    name="hasNER"
                    value={values?.hasNER}
                  />
                  <AppForms.AppFormSwitch
                    wrapperStyle={[
                      {
                        backgroundColor: isEdit
                          ? currentTheme?.theme?.grey
                          : currentTheme?.theme?.lightGrey,
                        maxHeight: 30,
                        paddingHorizontal: 10,
                        borderRadius: 20,
                        paddingVertical: 18,
                        margin: 5,
                        marginTop: 20,
                      },
                    ]}
                    labelTextStyle={{
                      fontSize: currentTheme?.theme?.small_font_size,
                      color: currentTheme?.theme?.darkGrey,
                    }}
                    showTextInputLabel={true}
                    label={"Retrieve images from docs"}
                    displayIsTicked={true}
                    name="hasImages"
                    value={values?.hasImages}
                    disabled={disableMultiModal}
                  />
                </View>
              )}
            </>
          )}
          {values?.mode == "planner" && (
            <AppText
              style={{
                paddingLeft: 5,
                fontWeight: "bold",
                fontSize: currentTheme?.theme?.extra_small_font_size,
              }}
              wrapperStyle={{
                paddingVertical: 5,
                top: 10,
                justifyContent: "center",
              }}
              iconSize={12}
              iconColor={currentTheme?.theme?.orange}
              leftIcon={AppAssets?.icons?.warning}
              color={currentTheme?.theme?.orange}
            >
              Planners must have access to agents with the necessary tools to
              perform tasks that involve files
            </AppText>
          )}
        </AppExpander>
      )}
      {isChatBot && values?.mode == "chatter" && (
        <AppExpander
          style={{ minWidth: 250, zIndex: -1 }}
          title="Images generation"
          icon={AppAssets.icons.images}
        >
          <AppForms.AppFormSwitch
            wrapperStyle={[
              {
                backgroundColor: isEdit
                  ? currentTheme?.theme?.grey
                  : currentTheme?.theme?.lightGrey,
                maxHeight: 30,
                paddingHorizontal: 10,
                borderRadius: 20,
                paddingVertical: 18,
                marginHorizontal: 5,
                marginVertical: 20,
              },
            ]}
            labelTextStyle={{
              fontSize: currentTheme?.theme?.small_font_size,
              color: currentTheme?.theme?.darkGrey,
            }}
            showTextInputLabel={true}
            label={"Allow images generation"}
            displayIsTicked={true}
            name="allowImagesGeneration"
            value={values?.allowImagesGeneration}
          />
          <View
            style={{
              marginLeft: 0,
              paddingLeft: 0,
            }}
          >
            <AppForms.AppFormDropDown
              isElevated={false}
              dropDownStyle={{
                borderColor: currentTheme?.theme?.border_color,
                maxWidth: !isLargeScreen() && 250,
              }}
              showTextInputLabel
              label="Image generation model"
              isMandatory
              name="baseImageGenerationModel"
              items={baseImageModels}
              propertyLabel="name"
              propertyValue="id"
            />
            <View
              style={{
                marginLeft: 20,
                marginTop: 10,
              }}
            >
              <AppForms.AppFormSlider
                step={1}
                maximumValue={4}
                minimumValue={1}
                label="Max images generated"
                name="maxImagesGenerated"
                showEditButtons={true}
              />
            </View>
          </View>
        </AppExpander>
      )}
      {isChatBot &&
        values?.restrictedAccess != true &&
        values?.mode != "planner" && (
          <AppExpander
            style={{ minWidth: 250, zIndex: 1 }}
            isExpandedOnLoad={false}
            title="Web widget"
            icon={AppAssets.icons.widget}
          >
            <AppText
              style={{
                paddingLeft: 30,
                paddingBottom: 5,
              }}
              fontWeight="bold"
            >
              Splash Logo
            </AppText>
            <ImagePlaceholder
              onFileSelection={(file) => handleIconSelection(file, true)}
              imagePath={agentLogo}
              isFileUploadInProgress={fileUploadInProgress}
              fileUploadProgress={fileUploadProgress}
              isLargeLogo
              style={{
                alignSelf: "flex-start",
                marginLeft: 10,
                paddingLeft: 0,
              }}
            />

            {/* largeLogo */}
            <View
              style={{
                paddingLeft: 10,
                zIndex: 20,
              }}
            >
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 120,
                  paddingVertical: 10,
                }}
                label={`First message`}
                placeholder={`message_launch_text_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="messageOnLaunch"
              />
              <AppForms.AppFormSwitch
                wrapperStyle={[
                  {
                    backgroundColor: isEdit
                      ? currentTheme?.theme?.grey
                      : currentTheme?.theme?.lightGrey,
                    maxHeight: 30,
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    paddingVertical: 18,
                    marginHorizontal: 5,
                    marginVertical: 20,
                  },
                ]}
                labelTextStyle={{
                  fontSize: currentTheme?.theme?.small_font_size,
                  color: currentTheme?.theme?.darkGrey,
                }}
                showTextInputLabel={true}
                label={"Show splash message"}
                displayIsTicked={true}
                name="showSplashWhenEmpty"
                value={values?.showSplashWhenEmpty}
              />
              <AppForms.AppFormSwitch
                wrapperStyle={[
                  {
                    backgroundColor: isEdit
                      ? currentTheme?.theme?.grey
                      : currentTheme?.theme?.lightGrey,
                    maxHeight: 30,
                    paddingHorizontal: 10,
                    borderRadius: 20,
                    paddingVertical: 18,
                    marginHorizontal: 5,
                    marginVertical: 20,
                  },
                ]}
                labelTextStyle={{
                  fontSize: currentTheme?.theme?.small_font_size,
                  color: currentTheme?.theme?.darkGrey,
                }}
                showTextInputLabel={true}
                label={"Show agent name"}
                displayIsTicked={true}
                name="showAgentName"
                value={values?.showAgentName}
              />

              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 120,
                  paddingVertical: 10,
                }}
                label={`Input placeholder`}
                placeholder={`input_text_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="placeholderInputMessage"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  paddingVertical: 10,
                }}
                label={`Loading placeholder`}
                placeholder={`loading_text_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="placeholderMessageOnLoading"
              />

              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 120,
                  paddingVertical: 10,
                }}
                label={`Disclaimer`}
                placeholder={`disclaimer_placeholder`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="disclaimer"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                textContainer={{
                  paddingVertical: 10,
                }}
                errorOffset={-13}
                label={`Icons color in light mode`}
                placeholder={`Hex color (e.g. #5d94d6)`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="color"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                textContainer={{
                  paddingVertical: 10,
                }}
                errorOffset={-13}
                label={`Icons color in dark mode`}
                placeholder={`Hex color (e.g. #5d94d6)`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="darkColor"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                textContainer={{
                  paddingVertical: 10,
                }}
                errorOffset={-13}
                label={`Splash background in light mode`}
                placeholder={`Hex color (e.g. #5d94d6)`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="logoBackground"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                textContainer={{
                  paddingVertical: 10,
                }}
                errorOffset={-13}
                label={`Splash background in dark mode`}
                placeholder={`Hex color (e.g. #5d94d6)`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="darkLogoBackground"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                textContainer={{
                  paddingVertical: 10,
                }}
                errorOffset={-13}
                label={`Favicon url`}
                placeholder={`https://mydomain.com/favicon.ico`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="icoUrl"
              />
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                multiline={true}
                textContainer={{
                  minHeight: 120,
                  paddingVertical: 10,
                }}
                errorOffset={0}
                label={`Initial questions`}
                placeholder={`Insert up top 3 initial questions seperated by a semicolon`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="quickQuestions"
              />
              {isEdit && (
                <AppText
                  wrapperStyle={{
                    alignSelf: "flex-start",
                    marginBottom: 10,
                    width: "100%",
                    marginTop: 10,
                  }}
                  leftIcon={AppAssets?.icons?.link}
                  leftIconStyle={{
                    marginLeft: 20,
                  }}
                  style={{
                    color: currentTheme?.theme?.primary,
                    left: 55,
                    fontWeight: "bold",
                    position: "absolute",
                  }}
                  rightIcon={AppAssets?.icons?.quickCopy}
                  isClickable
                  onPress={() => {
                    // open in new tab
                    window.open(
                      `https://agent.${
                        envConfig?.REACT_APP_ENV == "dev"
                          ? "toothfairylab.link"
                          : "toothfairyai.com"
                      }/${workspaceid}/${initialMapValues?.id}`,
                      "_blank"
                    );
                  }}
                  onRightIconPress={() => {
                    AppToasty.show("Copied to clipboard", {
                      type: "success",
                    });
                    navigator.clipboard.writeText(
                      `https://agent.${
                        envConfig?.REACT_APP_ENV == "dev"
                          ? "toothfairylab.link"
                          : "toothfairyai.com"
                      }/${workspaceid}/${initialMapValues?.id}`
                    );
                  }}
                  textStyle={{
                    marginLeft: 0,
                  }}
                  width={420}
                >
                  Go to widget
                </AppText>
              )}

              <View>
                <AppForms.AppFormSwitch
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      marginHorizontal: 5,
                      marginVertical: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Block external usage"}
                  displayIsTicked={true}
                  name="preventWidgetUsage"
                  value={values?.preventWidgetUsage}
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  marginLeft: 20,
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <AppText
                    wrapperStyle={{
                      paddingBottom: 10,
                    }}
                    fontWeight="bold"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Add this agent to your website
                  </AppText>
                  <AppText
                    wrapperStyle={{
                      paddingBottom: 10,
                    }}
                    style={{
                      fontSize: 14,
                    }}
                    iconSize={16}
                    rightIcon={
                      codeExpanded
                        ? AppAssets?.icons?.collapse
                        : AppAssets?.icons?.expand
                    }
                    isClickable
                    onRightIconPress={() => {
                      setCodeExpanded(!codeExpanded);
                    }}
                  >
                    Copy and paste this code into your website HTML
                  </AppText>
                </View>
                <AppIcon
                  icon={AppAssets?.icons?.quickCopy}
                  isClickable
                  onPress={async () => {
                    try {
                      AppToasty.show("HTML copied to clipboard", {
                        type: "success",
                      });
                      await navigator.clipboard.writeText(widgetString);
                    } catch (error) {
                      AppToasty.show("Error copying to clipboard", {
                        type: "danger",
                      });
                    }
                  }}
                />
              </View>
              {codeExpanded && (
                <pre
                  style={{
                    padding: 16,
                    border: `1px solid ${currentTheme?.theme?.color}`,
                    borderRadius: 20,
                    backgroundColor: currentTheme?.theme?.inputBackgroundColor,
                    overflow: "auto",
                    maxHeight: 300,
                  }}
                >
                  <code
                    style={{
                      fontFamily: "monospace",
                      fontSize: currentTheme?.theme?.extra_small_font_size,
                      color: currentTheme?.theme?.font_color,
                    }}
                  >
                    {widgetString}
                  </code>
                </pre>
              )}
            </View>
          </AppExpander>
        )}

      {isChatBot && (
        <AppExpander
          style={{ minWidth: 250, zIndex: -1 }}
          title="Moderation and Feedback"
          icon={AppAssets.icons.scan}
        >
          <View
            style={{
              paddingLeft: 10,
            }}
          >
            <AppForms.AppFormSwitch
              wrapperStyle={[
                {
                  backgroundColor: isEdit
                    ? currentTheme?.theme?.grey
                    : currentTheme?.theme?.lightGrey,
                  maxHeight: 30,
                  paddingHorizontal: 10,
                  borderRadius: 20,
                  paddingVertical: 18,
                  margin: 5,
                  marginTop: 20,
                },
              ]}
              labelTextStyle={{
                fontSize: currentTheme?.theme?.small_font_size,
                color: currentTheme?.theme?.darkGrey,
              }}
              showTextInputLabel={true}
              label={"Allow feedback"}
              displayIsTicked={true}
              name="allowFeedback"
              value={values?.allowFeedback}
            />
            <AppForms.AppFormSwitch
              // disabled={isEdit}
              // disabled={true}
              wrapperStyle={[
                {
                  backgroundColor: isEdit
                    ? currentTheme?.theme?.grey
                    : currentTheme?.theme?.lightGrey,
                  maxHeight: 30,
                  paddingHorizontal: 10,
                  borderRadius: 20,
                  paddingVertical: 18,
                  margin: 5,
                  marginTop: 20,
                },
              ]}
              labelTextStyle={{
                fontSize: currentTheme?.theme?.small_font_size,
                color: currentTheme?.theme?.darkGrey,
              }}
              showTextInputLabel={true}
              label={"Content moderation"}
              displayIsTicked={true}
              name="hasModeration"
              value={values?.hasModeration}
            />
            <AppForms.AppFormFieldJSONV2
              showTextInputLabel
              isMandatory
              wrapperInputStyle={{
                paddingTop: 0,
              }}
              wrapperContainer={{
                height: 450,
                paddingTop: 10,
              }}
              mode="code"
              // schema={validations.parametersVal}
              label={`Moderation rules`}
              name="customModerations"
              darkMode={mode === "dark"}
            />
          </View>
        </AppExpander>
      )}
      <AppExpander
        style={{ minWidth: 250, zIndex: -1 }}
        title="Hosting and models"
        icon={AppAssets.icons.hosting}
      >
        <View
          style={{
            paddingLeft: 0,
          }}
        >
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="LLM provider"
            isMandatory
            name="llmProvider"
            items={baseLlmProviders}
            propertyLabel="name"
            propertyValue="id"
          />
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Base model"
            isMandatory
            name="llmBaseModel"
            items={baseLlmModels}
            propertyLabel="name"
            propertyValue="id"
          />
          {isChatBot && ["retriever", "planner"].includes(values?.mode) && (
            <>
              <AppForms.AppFormDropDown
                isElevated={false}
                dropDownStyle={{
                  borderColor: currentTheme?.theme?.border_color,
                  maxWidth: !isLargeScreen() && 250,
                }}
                showTextInputLabel
                label="Functions provider"
                isMandatory
                name="functionCallingProvider"
                items={functionCallingProviders}
                propertyLabel="name"
                propertyValue="id"
              />
              <AppForms.AppFormDropDown
                isElevated={false}
                dropDownStyle={{
                  borderColor: currentTheme?.theme?.border_color,
                  maxWidth: !isLargeScreen() && 250,
                }}
                showTextInputLabel
                label="Functions model"
                isMandatory
                name="functionCallingModel"
                items={functionCallingModels}
                propertyLabel="name"
                propertyValue="id"
              />
            </>
          )}
        </View>
      </AppExpander>
      {isChatBot && (
        <AppExpander
          style={{ minWidth: 250, zIndex: -1 }}
          title="Agent channels"
          icon={AppAssets.icons.channels}
          iconSize={20}
        >
          <View
            style={{
              paddingLeft: 0,
            }}
          >
            <AppForms.AppFormMultiSelect
              name="communicationServices"
              placeholder="Assign channels"
              hidePlaceholder={true}
              items={agentsChannels}
              uniqueKey="id"
              showDropDowns={true}
              readOnlyHeadings={true}
              labelOption="name"
              groupByProperty="channel"
              style={{ maxWidth: 800 }}
              wrapperContainerStyle={{
                flex: !isLargeScreen() ? 1 : 0.5,
                zIndex: 20,
                paddingTop: 10,
                marginBottom: 30,
              }}
              styles={colourStyles}
              options={agentsChannels?.map((u) => {
                return {
                  ...u,
                  label: u?.name + " - " + Utility?.mapFunctionTypeToLabel(u),
                  value: u?.id,
                  backgroundColor: Utility?.mapFunctionTypeToColour(
                    u,
                    currentTheme
                  ),
                };
              })}
            />
            <AppForms.AppFormField
              showTextInputLabel
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              errorOffset={0}
              label={`Phone number`}
              placeholder={`Insert the phone number the agent should send messages from`}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name="phoneNumberAsSender"
            />
            <AppForms.AppFormField
              showTextInputLabel
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              errorOffset={0}
              label={`Whatsapp number`}
              placeholder={`Insert the whatsapp number the agent should send messages from`}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name="whatsappNumberAsSender"
            />
            <AppForms.AppFormField
              showTextInputLabel
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              errorOffset={0}
              label={`Email address`}
              placeholder={`Insert the email address the agent should send messages from`}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name="emailAsSender"
            />
            <View
              style={{
                marginLeft: 20,
                marginTop: 10,
              }}
            >
              <AppForms.AppFormSecureTextInput
                minimumValue={0}
                maximumValue={120}
                step={1}
                label="Delivery callback delay (seconds)"
                inputType="SLIDER"
                name="channelCallbackDelay"
              />
            </View>
          </View>
        </AppExpander>
      )}

      <AppExpander
        style={{ minWidth: 250, zIndex: -1 }}
        title="Advanced settings"
        icon={AppAssets.icons.experiment}
      >
        <View
          style={{
            paddingLeft: 10,
          }}
        >
          <View
            style={{
              marginLeft: 10,
              marginTop: 10,
            }}
          >
            <AppForms.AppFormSecureTextInput
              minimumValue={0.1}
              maximumValue={1}
              step={0.1}
              label="Temperature"
              inputType="SLIDER"
              name="temperature"
            />

            <AppForms.AppFormSlider
              step={1}
              maximumValue={getMaxTokens(
                isContentCreator,
                isChatBot,
                getActiveWorkspace,
                values
              )}
              minimumValue={50}
              label="Max output tokens"
              name="maxTokens"
              showEditButtons={true}
            />
          </View>
          <View>
            {isChatBot && (
              <>
                {isKnowledgeEnabled && (
                  <AppForms.AppFormSwitch
                    wrapperStyle={[
                      {
                        backgroundColor: isEdit
                          ? currentTheme?.theme?.grey
                          : currentTheme?.theme?.lightGrey,
                        maxHeight: 30,
                        paddingHorizontal: 10,
                        borderRadius: 20,
                        paddingVertical: 18,
                        margin: 5,
                        marginTop: 20,
                      },
                    ]}
                    labelTextStyle={{
                      fontSize: currentTheme?.theme?.small_font_size,
                      color: currentTheme?.theme?.darkGrey,
                    }}
                    showTextInputLabel={true}
                    label={"Plain text output"}
                    displayIsTicked={true}
                    name="plainTextOutput"
                    value={values?.plainTextOutput}
                  />
                )}
                {(isChatBot || isContentCreator) && isKnowledgeEnabled && (
                  <View>
                    <AppForms.AppFormSwitch
                      wrapperStyle={[
                        {
                          backgroundColor: isEdit
                            ? currentTheme?.theme?.grey
                            : currentTheme?.theme?.lightGrey,
                          maxHeight: 30,
                          paddingHorizontal: 10,
                          borderRadius: 20,
                          paddingVertical: 18,
                          margin: 5,
                          marginTop: 20,
                        },
                      ]}
                      labelTextStyle={{
                        fontSize: currentTheme?.theme?.small_font_size,
                        color: currentTheme?.theme?.darkGrey,
                      }}
                      showTextInputLabel={true}
                      label={"Show docs refs"}
                      displayIsTicked={true}
                      name="showReferencesToInternalDocs"
                      value={values?.showReferencesToInternalDocs}
                    />
                  </View>
                )}

                <AppForms.AppFormSwitch
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                      marginTop: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Show response time"}
                  displayIsTicked={true}
                  name="showTimeForResponse"
                  value={values?.showTimeForResponse}
                />
                <AppForms.AppFormSwitch
                  wrapperStyle={[
                    {
                      backgroundColor: isEdit
                        ? currentTheme?.theme?.grey
                        : currentTheme?.theme?.lightGrey,
                      maxHeight: 30,
                      paddingHorizontal: 10,
                      borderRadius: 20,
                      paddingVertical: 18,
                      margin: 5,
                      marginTop: 20,
                    },
                  ]}
                  labelTextStyle={{
                    fontSize: currentTheme?.theme?.small_font_size,
                    color: currentTheme?.theme?.darkGrey,
                  }}
                  showTextInputLabel={true}
                  label={"Show detected language"}
                  displayIsTicked={true}
                  name="showDetectedLanguage"
                  value={values?.showDetectedLanguage}
                />
              </>
            )}
          </View>
          <>
            <AppForms.AppFormSwitch
              wrapperStyle={[
                {
                  backgroundColor: isEdit
                    ? currentTheme?.theme?.grey
                    : currentTheme?.theme?.lightGrey,
                  maxHeight: 30,
                  paddingHorizontal: 10,
                  borderRadius: 20,
                  paddingVertical: 18,
                  margin: 5,
                  marginTop: 20,
                },
              ]}
              labelTextStyle={{
                fontSize: currentTheme?.theme?.small_font_size,
                color: currentTheme?.theme?.darkGrey,
              }}
              showTextInputLabel={true}
              label={"Restrict access"}
              displayIsTicked={true}
              name="restrictedAccess"
              value={values?.restrictedAccess}
            />
            {values?.restrictedAccess && (
              <AppForms.AppFormMultiSelect
                name="restrictedAccessUsers"
                topErrorOffset={-21}
                touchedByDefault
                placeholder="Allow access to specific users"
                hidePlaceholder={true}
                items={getWorkspaceUsers()}
                uniqueKey="id"
                showDropDowns={true}
                readOnlyHeadings={true}
                style={{ maxWidth: 800 }}
                wrapperContainerStyle={{
                  flex: !isLargeScreen() ? 1 : 0.5,
                  zIndex: 20,
                  paddingTop: 10,
                  marginBottom: 40,
                }}
                styles={colourStyles}
                options={getWorkspaceUsers().map((u) => {
                  return {
                    ...u,
                    value: u?.id,
                    label: u?.username || u?.user_email,
                    category: "user",
                    backgroundColor: AppUtilities.stringToHslColor(
                      u?.username || u?.user_email,
                      71
                    ),
                  };
                })}
              />
            )}
          </>
        </View>
      </AppExpander>
      <View style={{ alignItems: "center", zIndex: -1 }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              zIndex: -1,
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default QaSettingsAIView;
