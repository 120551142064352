import axios from "axios";
import { useState } from "react";
import envConfig from "../../envConfig";
import Constants from "../Constants";

const useSupport = (section) => {
  const [data, setData] = useState([]);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const mapSectionWithUrl = (section) => {
    const { supportUrl } = Constants;
    // Can be improved by supplying common URL and appending the path parameter.
    switch (section) {
      case "RC_PARSER":
        return {
          url: supportUrl,
          pageId: 13,
        };
      case "NLP_PARSER":
        return {
          url: `${supportUrl}/i/6`,
          pageId: 6,
        };

      case "NLP_DOCUMENTS":
        return {
          url: `${supportUrl}/i/14`,
          pageId: 14,
        };

      case "NLP_TRAINING":
        return {
          url: `${supportUrl}/i/15`,
          pageId: 15,
        };
      case "NLP_TRAINING_PRF":
        return {
          url: `${supportUrl}/i/15`,
          pageId: 15,
        };
      case "SETTINGS_GENERAL":
        return {
          url: `${supportUrl}/i/18`,
          pageId: 18,
        };
      case "WORKSPACES_GENERAL":
        return {
          url: `${supportUrl}/i/18`,
          pageId: 18,
        };
      case "SETTINGS_INTENT":
        return {
          url: `${supportUrl}/i/28`,
          pageId: 28,
        };
      case "SETTINGS_NER":
        return {
          url: `${supportUrl}/i/8`,
          pageId: 8,
        };
      case "SETTINGS_TOPIC":
        return {
          url: `${supportUrl}/i/67`,
          pageId: 67,
        };
      case "SETTINGS_TRAINING":
        return {
          url: `${supportUrl}/i/30`,
          pageId: 30,
        };
      case "DASHBOARD_MODEL":
        return {
          url: `${supportUrl}/i/12`,
          pageId: 12,
        };
      case "DASHBOARD_COST":
        return {
          url: `${supportUrl}/i/12`,
          pageId: 12,
        };
      case "PROFILE_ANALYSIS":
        return {};
      default:
        return {
          url: `${supportUrl}`,
          pageId: null,
        };
    }
  };
  const getRawContent = async (_section, _pageId) => {
    const pageId = _pageId || mapSectionWithUrl(_section)?.pageId;
    const { supportDomain } = Constants;
    try {
      if (!pageId) throw new Error("No content found");
      setLoading(true);
      const gqlStatement = `query PageQuery ($id: Int!) {
        pages {
          single(id: $id) {
            title
            content
          }
        }
      }
      `;
      const _response = await axios({
        url: `${supportDomain}/graphql`,
        method: "post",
        headers: {
          Authorization: "Bearer " + envConfig.REACT_APP_SUPPORT_TOKEN,
        },
        data: {
          query: `${gqlStatement}`,
          variables: {
            id: pageId,
          },
        },
      });
      setLoading(false);
      if (!_response?.data) {
        setData([]);
        return setError(true);
      }
      setError(false);
      setResponse(_response);
      return _response;
    } catch (_error) {
      throw _error;
    }
  };
  return {
    url: mapSectionWithUrl(section)?.url,
    data,
    response,
    error,
    loading,
    getRawContent,
  };
};

export default useSupport;
