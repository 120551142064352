import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppButtonMenu from "@toothfairy/shared-ui/AppButtonMenu";
import { showSideMenu } from "../../../../../App";

const FunctionSettingsToolbar = ({
  searchBtnLabel = "Search",
  searchBtnAccLabel = "searchButton",
  handleSearch = () => {},
  handleCreation = () => {},
  isDBFunctionsEnabled = false,
  isHtmlFunctionsEnabled = false,
}) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [requestType, setRequestType] = React.useState("");
  const { currentTheme } = AppTheme.useTheme();
  const { width } = useWindowDimensions();
  const _options = [
    {
      label: "API function",
      id: "NEW_API_FUNCTION",
      icon: AppAssets.icons.api,
    },
  ];
  if (isDBFunctionsEnabled) {
    _options.push({
      label: "DB function",
      id: "NEW_DB_FUNCTION",
      icon: AppAssets.icons.data,
    });
  }
  if (isHtmlFunctionsEnabled) {
    _options.push({
      label: "HTML function",
      id: "NEW_HTML_FUNCTION",
      icon: AppAssets.icons.html,
    });
  }
  _options.push({
    label: "Chat function",
    id: "NEW_CHAT_FUNCTION",
    icon: AppAssets.icons.quantumChat,
  });
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: -10,
        zIndex: -1,
        width: showSideMenu() ? width - (showSideMenu() ? 470 : 300) : "100%",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={name}
          onChangeText={setName}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Name"
        />
        {/* <AppTextInput
          value={description}
          onChangeText={setDescription}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Description"
        /> */}
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={url}
          onChangeText={setUrl}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Endpoint"
        />
        {/* <AppTextInput
          value={requestType}
          onChangeText={setRequestType}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Type"
        /> */}
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <ToothFairyButton
          wrapperStyle={{ marginTop: 10 }}
          title={searchBtnLabel}
          accessibilityLabel={searchBtnAccLabel}
          onPress={() => handleSearch(name, description, url, requestType)}
        />
        <AppButtonMenu
          mainActionLabel={"New function"}
          onClickMainAction={() => {
            handleCreation("NEW_API_FUNCTION");
          }}
          containerStyle={{
            paddingTop: 10,
          }}
          wrapperStyle={{
            backgroundColor: currentTheme?.theme?.primary,
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderRadius: 20,
            width: 180,
          }}
          labelStyle={{
            fontWeight: "bold",
          }}
          style={{
            top: 45,
            fontFamily: "boldFont",
            width: 175,
          }}
          menuItemStyle={{
            marginLeft: 10,
            fontSize: currentTheme?.theme?.small_font_size,
          }}
          color={currentTheme?.theme?.black}
          rightIconColor={currentTheme?.theme?.black}
          onClickMenuItem={(id) => {
            if (
              id === "NEW_API_FUNCTION" ||
              id === "NEW_DB_FUNCTION" ||
              id === "NEW_HTML_FUNCTION" ||
              id === "NEW_CHAT_FUNCTION"
            ) {
              handleCreation(id);
            } else {
              handleCreation("NEW_API_FUNCTION");
            }
          }}
          options={_options}
        />
      </View>
    </View>
  );
};

export default FunctionSettingsToolbar;
