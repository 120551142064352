import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import envConfig from "../../../envConfig";

const getDocumentsByLogId = async (workspaceid, logId, type, context) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/documents/getAvailableFilesForLog?workspaceid=${workspaceid}&logId=${logId}&type=${type}&context=${context}`;
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const downloadUrlGeneration = async ({
  workspaceid,
  logId,
  type,
  filename,
  context,
}) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/documents/requestDownloadURL?filename=${filename}&type=${type}&workspaceid=${workspaceid}&logId=${logId}&context=${context}`;
  return secureRequest(
    apiClient.apiClient,
    "GET",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

const bulkImportFileUpload = async (url, file) => {
  return secureRequest(
    apiClient.apiClient,
    "PUT",
    url,
    null,
    file,
    true,
    null,
    null
  );
};

export default {
  downloadUrlGeneration,
  bulkImportFileUpload,
  getDocumentsByLogId,
};
