const shallowCompare = (obj1, obj2) => Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
var CUSTOMEPOCH = 1300000000000; // artificial epoch
const generateRowId = function () {
  let shardId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2;
  var ts = new Date().getTime() - CUSTOMEPOCH; // limit to recent
  var randid = Math.floor(Math.random() * 512);
  ts = ts * 64; // bit-shift << 6
  ts = ts + shardId;
  return ts * 512 + randid % 512;
};

export { generateRowId as g, shallowCompare as s };
