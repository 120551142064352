import useApi from "@toothfairy/shared-api/useApi";
import AppQuantumEditorUtils from "@toothfairy/shared-ui/AppQuantumEditorUtils";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Suspense,
} from "react";
import { useTranslation } from "react-i18next";
import "../../../../shared/src/styles/sharedStyles.css";
import { displayedToasties } from "../../../App";
import envConfig from "../../../envConfig";
import NLP from "../../API/NLP";
import trainingAPI from "../../API/Training/trainingAPI";
import useAutoSave from "../../Hooks/useAutoSave";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useParams } from "../../Router";
import TestAnExampleScreen from "./TestAnExampleScreen";
import { emptyEditorValue } from "./commonUtils";

const TestAnExampleScreenContainer = ({ navigation }) => {
  const { documentId } = useParams();
  const [isTrainerNoDocumentId, setIsTrainerNoDocumentId] = useState(false);
  const { t } = useTranslation();
  const { getActiveWorkspace } = useWorkspaces();
  const AppToasty = AppToast.useToast();
  const { user } = AppUser.useUser();
  const [payload, setPayload] = useState(null);
  const [nlpData, setNLPData] = useState(null);
  const [lastNlPDataTimeStamp, setLastNLPDataTimeStamp] = useState(0);
  const { state } = AppStateManager.useAppStateManager();
  const editorRef = React.useRef();

  const _entities = useMemo(() => {
    const NERs = state?.aiEntitiesConfig?.filter((u) => u.type === "ner") || [];
    const Intents =
      state?.aiEntitiesConfig?.filter((u) => u.type === "intent") || [];
    return { NERs, Intents };
  }, [JSON.stringify(state?.aiEntitiesConfig)]);

  const [nlpConfig, setNlpConfig] = useState({});

  const {
    value,
    editor,
    updateAPIError,
    createAPIError,
    updateAPIInProgress,
    createAPIInProgress,
    lastUpdateTime,
    lastChange,
    isEditorLoaded,
    handleSocketConnectionChange,
    createDocument,
    setValue,
    setEditor,
    goToParser,
    setIsValidationData,
    isValidationData,
    isDirty,
    isParser,
    setIsParser,
    charactersCount,
    wordsCount,
    isSaved,
    isInactive,
    trainingData,
  } = useAutoSave({
    documentId,
    workspaceid: getActiveWorkspace()?.id,
    user,
    updateMethod: trainingAPI.updateTrainingData,
    createMethod: trainingAPI.postTrainingData,
    isNLPTraining: true,
    route: "nlp",
    documentType: "nlpTraining",
    backupCommand: "SET_INITIAL_NLP_VALUE",
    backupProperty: "initialNLPValue",
    initialValue: emptyEditorValue,
  });

  const {
    error: parseError,
    loading: parseInProgress,
    apiRequest: _parseBulkText,
  } = useApi(NLP.bulkParseText, envConfig);

  useEffect(() => {
    setNlpConfig({
      timezone: user.timezone,
    });
  }, [JSON.stringify(user)]);

  useEffect(() => {
    if (!isParser) setIsTrainerNoDocumentId(!documentId);
  }, [documentId, isParser]);

  const isNodeParsable = (nodeText) => {
    return nodeText.trim() !== "" && nodeText.split(" ").length > 2;
  };
  const isNodeAlreadyParsed = (block) => {
    return block.isParsed === true;
  };
  const extractDataFromText = (nodeText) => {
    return _parseText({
      text: nodeText,
      timezone: nlpConfig.timezone,
      isSplit: getActiveWorkspace()?.parsingConfig?.isSentenceSplitEnabled,
      isMyself: getActiveWorkspace()?.parsingConfig?.isImplicitSelfEnabled,
      isMedNERenabled: getActiveWorkspace()?.isMed,
      baseUrl: envConfig?.REACT_APP_REST_ENDPOINT,
      workspaceID: state.activeWorkspaceID,
      token: getActiveWorkspace()?.workspaceToken,
    });
  };

  const attachNER = (data, i, j, bufferData) => {
    const isNested = j !== -1;
    const coreNerData = data?.map((u) => u?.data?.entities)?.flat();

    const coreIntentsData = data?.map((u) => u?.data?.intents)?.flat();
    const bufferEntitiesList = [];
    _entities.NERs.forEach((ner) => {
      let entitiesFiltered = coreNerData?.filter((u) => u?.name === ner?.label);

      for (let i = 0; i < entitiesFiltered?.length; i++) {
        const ent = entitiesFiltered[i];

        if (ent && ent?.values?.length > 0) {
          ent?.values?.forEach((_ent) => {
            _ent.label = ner.label;
          });
          bufferEntitiesList.push(...ent?.values);
        }
      }
    });
    bufferEntitiesList.sort(AppUtilities.sortBy("idx"));

    bufferEntitiesList
      ?.filter((u) => u?.idx !== u?.end)
      ?.forEach((entity, z) => {
        const path = AppQuantumEditorUtils.Helpers.findNERPathInElement(
          { ...editor },
          i,
          j,
          ["ner"],
          entity.idx,
          entity.end,
          false
        );
        if (!path) return;
        const enrichedElement =
          AppQuantumEditorUtils.Helpers.enrichElementWithAbsoluteStartEnd(
            { ...editor },
            i,
            j,
            ["ner"]
          );
        const bufferPathNodeIndex = isNested ? 2 : 1;
        let bufferPathIDX = null;
        if (path?.length > 0 && path[bufferPathNodeIndex] > 0)
          bufferPathIDX =
            enrichedElement.children[path[bufferPathNodeIndex] - 1].end;
        else bufferPathIDX = 0;
        editor.attachNER(
          { coreNER: [entity.label] },
          AppQuantumEditorUtils.Helpers.generateExplicitPathFromLeafPathAndOffset(
            { ...path }[0],
            { ...path }[1],
            isNested ? { ...path }[2] : -1,
            entity.idx - bufferPathIDX,
            entity.end - bufferPathIDX
          ),
          true
        );
      });
    editor.attachProperty("INTENT", coreIntentsData, isNested ? [i, j] : [i]);
    bufferData.push(data);
    return bufferData;
  };

  const handleEditorError = (event) => {
    if (event.message) {
      if (!displayedToasties()) {
        const id = AppToasty.show(t(event.message), {
          type: "danger",
        });
        displayedToasties(id);
      }
    }
  };

  const generatePayload = useCallback(
    (value) => {
      sentences = [];
      for (let i = 0; i < value.length; i++) {
        const block = value[i];
        let nodeText;
        if (block.type === "title") continue;
        if (block.type === "paragraph") {
          nodeText =
            AppQuantumEditorUtils.Helpers.generateStringFromElement(block);
          sentences.push({
            text: nodeText,
            id: i,
          });
        } else if (
          block.type === "numbered-list" ||
          block.type === "bulleted-list"
        ) {
          for (let j = 0; j < block.children.length; j++) {
            const listItem = block.children[j];
            nodeText =
              AppQuantumEditorUtils.Helpers.generateStringFromElement(listItem);
            sentences.push({
              text: nodeText,
              id: i + "-" + j,
            });
          }
        }
      }
      return sentences;
    },

    [value]
  );

  const handleParse = async () => {
    try {
      let bufferData = [];
      let shortSentencesDetected = 0;
      const rawText = AppQuantumEditorUtils.Helpers.getPlainText(value);

      if (rawText?.trim() === "" || rawText?.trim()?.split(" ") <= 3) {
        AppToasty.show(t("Please enter at least 3 words"), {
          type: "danger",
        });
        return;
      }
      const sentences = generatePayload(value);
      setPayload(
        NLP.generatePayload({
          sentences,
          timezone: nlpConfig.timezone,
          isSplit: getActiveWorkspace()?.parsingConfig?.isSentenceSplitEnabled,
          isMyself: getActiveWorkspace()?.parsingConfig?.isImplicitSelfEnabled,
          isMedNERenabled: getActiveWorkspace()?.isMed,
          workspaceID: getActiveWorkspace()?.id,
          forcedLanguage: null,
        })
      );
      const nlpData = await _parseBulkText({
        sentences,
        timezone: nlpConfig.timezone,
        isSplit: getActiveWorkspace()?.parsingConfig?.isSentenceSplitEnabled,
        isMyself: getActiveWorkspace()?.parsingConfig?.isImplicitSelfEnabled,
        isMedNERenabled: getActiveWorkspace()?.isMed,
        workspaceID: getActiveWorkspace()?.id,
        token: getActiveWorkspace().workspaceToken,
        forcedLanguage: null,
      });
      if (nlpData?.errorCode) {
        setNLPData(nlpData);
        throw new Error(nlpData.errorMessage + " - " + nlpData.errorDetail);
      }
      for (let p = 0; p < nlpData.length; p++) {
        const nlpElementData = nlpData[p];

        const stringId = nlpElementData.id.toString();
        const path = stringId.includes("-")
          ? stringId?.split("-")?.map((u) => parseInt(u))
          : [nlpElementData?.id];
        const isNested = path?.length == 2;
        editor.attachProperty(
          "isParsed",
          true,
          isNested ? [path[0], path[1]] : [nlpElementData?.id]
        );
        editor.attachProperty("lastParsed", new Date().getTime(), [
          isNested ? [path[0], path[1]] : [nlpElementData?.id],
        ]);
        // split nlpElementData?.id using - as separator

        if (nlpElementData && nlpElementData?.sentences?.length > 0)
          bufferData = attachNER(
            // continue here
            nlpElementData?.sentences,
            path[0],
            isNested ? path[1] : -1,
            bufferData
          );
      }
      setNLPData(nlpData);
      setLastNLPDataTimeStamp(new Date().getTime());
      const errorWhileParsing = parseError || !bufferData?.length > 0;
      AppToasty.show(
        errorWhileParsing
          ? t("NLPTransformationFailedCaption")
          : shortSentencesDetected > 0
          ? t("NLPTransformationCaptionWithShortSentences")
          : t("NLPTransformationCaption"),
        {
          type: errorWhileParsing
            ? "danger"
            : shortSentencesDetected > 0
            ? "warning"
            : "success",
        }
      );
    } catch (error) {
      console.error("Error while parsing", error);
      AppToasty.show(t(error), {
        type: "danger",
      });
    }
  };

  const handleSocketSyncChange = (isSync) => {
    // if (isSync && !isEditorLoaded) setIsEditorLoaded(true);
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TestAnExampleScreen
        isInactive={isInactive}
        setIsParser={setIsParser}
        setIsTrainerNoDocumentId={setIsTrainerNoDocumentId}
        isTrainerNoDocumentId={isTrainerNoDocumentId}
        user={user}
        parseInProgress={parseInProgress}
        storeInProgress={updateAPIInProgress || createAPIInProgress}
        createAPIInProgress={createAPIInProgress}
        isSaved={isSaved}
        // The 1000 has been added to make sure that the editor is loaded before the first parse timestamp is set
        isOutputUpToDate={lastChange < lastNlPDataTimeStamp + 1000}
        storeInError={updateAPIError || createAPIError}
        onSocketConnectionChange={handleSocketConnectionChange}
        onSocketSyncChange={handleSocketSyncChange}
        NERStyles={_entities.NERs}
        IntentStyles={_entities.Intents}
        handleParse={handleParse}
        routeToParsing={goToParser}
        handleNewDocument={createDocument}
        value={value}
        setValue={setValue}
        setEditor={setEditor}
        nlpData={nlpData}
        nlpConfig={nlpConfig}
        setNlpConfig={setNlpConfig}
        documentId={documentId}
        isEditorLoaded={isEditorLoaded}
        isParser={isParser}
        isDirty={isDirty}
        handleEditorError={handleEditorError}
        wordsCount={wordsCount}
        charactersCount={charactersCount}
        payload={payload}
        trainingData={trainingData}
        workspaceid={getActiveWorkspace()?.id}
        setIsValidationData={setIsValidationData}
        isValidationData={isValidationData}
        ref={editorRef}
      />
    </Suspense>
  );
};

export default TestAnExampleScreenContainer;
