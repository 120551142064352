import AppChoice from "@toothfairy/shared-ui/AppChoice";
import React from "react";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../Constants";

const TrainingBaseModelSelection = ({
  selectedTab,
  setIsTrainingInitiationModalOpen,
  handleBaseModelInitiation,
}) => {
  return (
    <AppChoice
      wrapperStyle={{ alignSelf: "center", maxWidth: 600 }}
      caption={`You are about to go back to the ${Constants.mapKeysToNames(
        selectedTab?.key
      )} base model`}
      subCaption="The process will bring back the base model to the selected workspace without deleting the training data nor the models that have been generated"
      cancelCaption="Click 'Cancel' to abort"
      confirmCaption="Click 'Confirm' to start the process"
      onConfirmButtonPress={() => {
        handleBaseModelInitiation();
        setIsTrainingInitiationModalOpen(false);
      }}
      onCancelButtonPress={() => {
        setIsTrainingInitiationModalOpen(false);
      }}
      AppChoiceButton={ToothFairyButton}
    ></AppChoice>
  );
};

export default TrainingBaseModelSelection;
