import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import React from "react";

const DashboardPlaceholder = ({ ...props }) => {
  return (
    <AppPlaceholderView
      wrapperStyle={{
        width: 335,
        height: "auto",
        minHeight: 200,
        marginVertical: "auto",
        marginHorizontal: "auto",
      }}
      size={80}
      icon={AppAssets.icons.orbit}
      {...props}
    />
  );
};

export default DashboardPlaceholder;
