import {
  emptyEditorValue,
  initialEditorValue,
  initialEditorValueForAnswerer,
} from "../Screens/TestExample/commonUtils";

export default {
  isWorkspaceCollabReady: null,
  isOpen: false,
  isWorkspaceReady: false,
  accountRefreshRequired: false,
  foldersLoaded: false,
  trainingLogs: [],
  aiEntitiesConfig: [],
  aiDictionariesConfig: [],
  languagesConfig: [],
  trainingLogPage: 0,
  activeWorkspaceUsers: [],
  accessCredentials: [],
  dbConnections: [],
  authorisations: [],
  appLogs: [],
  workspacePlan: {},
  sites: [],
  channels: [],
  chatsLoaded: false,
  configData: {},
  generationConfigData: {},
  generatorUserSelection: {
    styles: "default",
    language: "def",
    scope: "generation",
  },
  agentsFunctions: [],
  agents: [],
  execEnvs: [],
  hostings: [],
  customPrompts: [],
  appLogsReady: false,
  initialNLPValue: initialEditorValue,
  initialRCValue: initialEditorValueForAnswerer,
  initialGNValue: emptyEditorValue,
  initialCNValue: emptyEditorValue,
  initialMRCValue: initialEditorValueForAnswerer,
  treeViewData: [],
  chats: [],
  mrcDocuments: [],
  selectedArenaMode: "none",
  mrcFolders: [],
  batchLogs: [],
  appTabSelection: [
    {
      key: "training",
      lastIndex: 0,
    },
    {
      key: "dashboard",
      lastIndex: 0,
    },
    {
      key: "settings",
      lastIndex: null,
    },
    {
      key: "admin",
      lastIndex: null,
    },
    {
      key: "documents",
      lastIndex: 0,
    },
    {
      key: "profile",
      lastIndex: null,
    },
    {
      key: "batch",
      lastIndex: 0,
    },
  ],
};
