import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import envConfig from "../../../envConfig";
import Common from "../Common";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";

const generateRequestPayload = ({
  prompts,
  token,
  workspaceid,
  options,
  topics,
  stream,
  streamid,
  agentid,
}) => {
  let body = {
    prompts,
    token,
    workspaceid,
    options,
    topics,
    stream,
    streamid,
    agentid,
  };
  return Common.objectCleaner(body);
};
const generateGenerationConfigPayload = ({ config, workspaceid }) => {
  let body = {
    config,
    workspaceid,
  };
  return body;
};
const generateFakeCheckRequestPayload = ({ contents, token, workspaceid }) => {
  let body = {
    contents,
    token,
    workspaceid,
  };
  return Common.objectCleaner(body);
};

const getContent = async ({
  prompts,
  token,
  workspaceid,
  options,
  topics,
  stream,
  streamid,
  agentid,
}) => {
  if (!token) return { data: null };
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/generator`;
  const body = generateRequestPayload({
    prompts,
    token,
    workspaceid,
    options,
    topics,
    stream,
    streamid,
    agentid,
  });
  return secureRequest(client, "POST", url, null, body, false, headers);
};

const getConfig = async ({ id }) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/generate/config?workspaceid=${id}`;
  return secureRequest(client, "GET", url, null, null, true, null, payload);
};

const updateConfig = async ({ id, config }) => {
  const payload = await AppAuth.Auth.currentSession();
  const url = `${envConfig.REACT_APP_REST_ENDPOINT}/generate/editConfig`;
  const body = generateGenerationConfigPayload({
    config,
    workspaceid: id,
  });
  return secureRequest(client, "POST", url, null, body, true, null, payload);
};

const fakeCheck = async ({ contents, token, workspaceid }) => {
  if (!token) return { data: null };
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/detector`;
  const body = generateFakeCheckRequestPayload({
    contents,
    token,
    workspaceid,
  });
  return secureRequest(client, "POST", url, null, body, false, headers);
};

export default {
  getContent,
  generateRequestPayload,
  getConfig,
  fakeCheck,
  updateConfig,
};
