import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import AdminInfoLeaf from "../../../Admin/Components/SubComponents/AdminInfoLeaf";

const hosting_types = [
  {
    id: "together",
    name: "Together AI",
  },
  {
    id: "replicate",
    name: "Replicate AI",
  },

  {
    id: "aws",
    name: "Bedrock",
    enterprise: true,
  },
  {
    id: "anthropic",
    name: "Anthropic",
    enterprise: true,
  },
  {
    id: "azure_openai",
    name: "Azure",
    enterprise: true,
  },
  {
    id: "openai",
    name: "OpenAI",
    enterprise: true,
  },
  {
    id: "google",
    name: "Google",
    enterprise: true,
  },
  {
    id: "local",
    name: "Local",
    enterprise: true,
  },
];

const HostingSettingsAIView = ({
  isEdit,
  settingType,
  handleDeletion,
  isSaveInProgress,
}) => {
  const { currentTheme, mode } = AppTheme.useTheme();
  const { getPlanConfig, getActiveWorkspace } = useWorkspaces();
  const entityName = "Hosting mapping";
  const { setFieldValue, values, validationSchema } =
    AppForms.useFormikContext();
  const [initialLoadCompleted, setInitialLoadCompleted] = React.useState(false);
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  // const { setFieldValue, values } = AppForms.useFormikContext();
  // useEffect(() => {
  //   if (values?.sourceLanguage !== "en") setFieldValue("targetLanguage", "en");
  //   if (values?.targetLanguage !== "en") setFieldValue("sourceLanguage", "en");
  // }, [values]);
  useEffect(() => {
    setTimeout(() => {
      setInitialLoadCompleted(true);
    }, [2000]);
  }, []);

  useEffect(() => {
    if (values?.customModel && initialLoadCompleted) {
      setFieldValue("baseModel", "mistralai/Mistral-7B-Instruct-v0.1");
    } else {
      setFieldValue("baseModel", null);
    }
  }, [values?.customModel, initialLoadCompleted]);
  return (
    <>
      <>
        <View>
          {isEdit && (
            <AdminInfoLeaf
              wrapperStyle={{
                alignSelf: "flex-start",
                marginBottom: 10,
              }}
              textContainer={{
                marginLeft: 5,
                marginTop: 10,
                marginBottom: 0,
              }}
              titleStyle={{
                marginTop: 0,
                marginLeft: 20,
              }}
              textStyle={{
                marginLeft: 0,
              }}
              title={"ID"}
              value={values?.id}
              width={420}
            />
          )}
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Provider name`}
            placeholder={`Provider name`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="name"
          />
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            label={`Description`}
            placeholder={`Description of what the provider is used for`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="description"
          />
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Type"
            isMandatory
            name="type"
            items={hosting_types
              .filter((item) => {
                if (
                  !item.enterprise ||
                  (item.enterprise &&
                    (getPlanConfig()?.isEnterprise ||
                      getActiveWorkspace()?.enableExperimentalFeatures == true))
                ) {
                  return item;
                }
              })
              .filter((item) => item)}
            propertyLabel="name"
            propertyValue="id"
          />

          {!["none", "local", "aws"].includes(values?.type) && (
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              disabled={isEdit}
              label={`Token`}
              placeholder={
                isEdit
                  ? "API keys cannot be changed after creation"
                  : `The API key to use to authorise the request`
              }
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name={isEdit ? "$xc" : "tokenSecret"}
            />
          )}
          {values?.type == "local" && (
            <View
              style={{
                zIndex: -1,
              }}
            >
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                isMandatory
                label={`Endpoint`}
                placeholder={`Local endpoint (e.g. http://172.168.0.1:5000)`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="endpoint"
              />
            </View>
          )}
          {values?.type == "azure_openai" && (
            <View
              style={{
                zIndex: -1,
              }}
            >
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                isMandatory
                label={`Endpoint`}
                placeholder={`Azure endpoint`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="endpoint"
              />
              <AppForms.AppFormField
                showTextInputLabel
                isMandatory
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                label={`Api version`}
                placeholder={
                  "The version of the API to use. For example, 2023-04-01"
                }
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name={"apiVersion"}
              />
            </View>
          )}
        </View>
      </>
      {(values?.type == "azure_openai" || values?.type == "local") && (
        <View>
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Model name`}
            placeholder={`Model name`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="modelName"
          />
        </View>
      )}
      <View style={{ alignItems: "center" }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          isDisabled={isSaveInProgress}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default HostingSettingsAIView;
