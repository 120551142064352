import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import { useHistory } from "../Router";

const useTrainModeSwitcher = ({ route, isMrc = false }) => {
  const history = useHistory();
  const { state } = AppStateManager.useAppStateManager();
  const goToTrainMode = ({ docID }) => {
    history.push(
      `/workspaces/${state.activeWorkspaceID}${
        route != "mrc_contents" && route != "nlp" ? "/documents" : ""
      }/${route}/${docID}`
    );
  };
  const goToParser = () => {
    history.push(`/workspaces/${state.activeWorkspaceID}/${route}`);
  };
  const goToMrcDocument = ({ docID }) => {
    history.push(
      `/workspaces/${state.activeWorkspaceID}/mrc_contents/${docID}`
    );
  };
  return {
    goToTrainMode,
    goToParser,
    goToMrcDocument,
  };
};

export default useTrainModeSwitcher;
