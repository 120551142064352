import React from "react";
import useWorkspaces from "../Hooks/useWorkspaces";
import { Redirect, Route } from "./index";
import { routes } from "./routes";

const AIEngineerRoute = (props) => {
  const {
    isUserAIEngineer,
    isUserLoadedInWorkspace,
    getPlanConfig,
    getActiveWorkspace,
  } = useWorkspaces();
  // check if the user at least has the one training option
  const isAllowedToTrain = () => {
    return true;
    // getPlanConfig()?.isTrainingNLPEnabled ||
    // getPlanConfig()?.isTrainingQAEnabled ||
    // getPlanConfig()?.isTrainingGNEnabled
  };
  return (!isUserAIEngineer() && isUserLoadedInWorkspace()) ||
    (getPlanConfig() && isAllowedToTrain() === false) ? (
    <Redirect
      to={{
        pathname: routes.accessDenied,
      }}
    />
  ) : (
    <Route {...props} />
  );
};

export default AIEngineerRoute;
