import React from "react";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import SingleSignOnScreen from "../Components/SingleSignOnScreen";
import envConfig from "../../../../envConfig";
import workspacesHelper from "../../../API/Workspaces/workspacesHelper";

const SingleSignOnContainer = () => {
  const { getActiveWorkspace } = useWorkspaces();
  const AppToasty = AppToast.useToast();
  const handleSubmit = (v) => {
    try {
      const { ssoType, federationMetadataUrl, ssoDomain } = v;
      const result = workspacesHelper.updateWorkspace(getActiveWorkspace().id, [
        {
          property: "ssoType",
          value: ssoType,
        },
        {
          property: "federationMetadataUrl",
          value: federationMetadataUrl,
        },
        {
          property: "ssoDomain",
          value: ssoDomain,
        },
      ]);
      AppToasty.show("SSO settings updated", {
        type: "success",
      });
    } catch (e) {
      console.log("e", e);
      AppToasty.show("SSO settings failed to update", {
        type: "danger",
      });
    }
  };
  if (
    getActiveWorkspace()?.subscriptionType === "enterprise" ||
    envConfig.REACT_APP_ENV == "dev"
  ) {
    return <SingleSignOnScreen onSubmit={handleSubmit} />;
  } else {
    return (
      <AppPlaceholderView
        accessibilityLabel="sso-availability"
        wrapperStyle={{
          maxWidth: 400,
          height: 200,
          marginHorizontal: "auto",
          marginTop: 50,
        }}
        size={60}
        icon={AppAssets.icons.accessDenied}
        mainCaption="SSO is not available for your plan"
        subCaption="Upgrade to enterprise to enable SSO"
      />
    );
  }
};

export default SingleSignOnContainer;
