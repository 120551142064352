import React, { useRef } from "react";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { View } from "react-native";
import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppToast from "@toothfairy/shared-ui/AppToast";

const ImagePlaceholder = ({
  onFileSelection = () => {},
  imagePath,
  isFileUploadInProgress,
  fileUploadProgress,
  isLargeLogo,
  style,
}) => {
  const fileInputRef = useRef(null);
  const AppToasty = AppToast.useToast();
  const { currentTheme } = AppTheme.useTheme();
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const handlePlaceholderClick = () => {
    fileInputRef.current.click();
  };
  const isValidFileSize = (file) => {
    const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
    return file.size <= maxSizeInBytes;
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!isValidFileSize(file)) {
      return AppToasty.show(
        "File size exceeds 1MB. Please upload a smaller image.",
        {
          type: "danger",
        }
      );
    }
    // Handle the uploaded file here
    // For now, let's just log it to the console
    onFileSelection(file);

    // Implement your image validation logic here
  };

  return (
    <View
      style={[
        {
          cursor: "pointer",
          border:
            !imagePath &&
            `1px solid ${
              currentTheme?.theme[isFileUploadInProgress ? "orange" : "primary"]
            }`,
          padding: !imagePath && 5,
          textAlign: "center",
          width: isLargeLogo ? 128 : 36,
          height: isLargeLogo ? "auto" : 36,
          alignSelf: "center",
          borderRadius: 20,
        },
        style,
      ]}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageUpload}
        // limit the input to only accept images
        accept="image/png"
      />
      {imagePath && !isFileUploadInProgress ? (
        <div
          onMouseEnter={() => {
            setIsMouseOver(true);
          }}
          onMouseLeave={() => {
            setIsMouseOver(false);
          }}
        >
          {isMouseOver && (
            <AppIcon
              icon={AppAssets.icons.close}
              size={12}
              color={currentTheme?.theme?.red}
              wrapperStyle={{
                position: "absolute",
                top: -7,
                right: -7,
                zIndex: 1,
              }}
              isClickable
              onPress={() => {
                onFileSelection(null);
              }}
            />
          )}
          <img
            src={imagePath}
            style={{
              borderRadius: 20,
              border: `1px solid ${currentTheme?.theme?.primary}`,
            }}
            onClick={() => fileInputRef.current.click()}
            alt="Uploaded icon"
            width={36 * (isLargeLogo ? 3 : 1)}
            height={isLargeLogo ? "auto" : 36}
          />
        </div>
      ) : isFileUploadInProgress && fileUploadProgress !== 100 ? (
        <AppText
          style={{
            fontSize: 14,
            fontWeight: "bold",
          }}
          color={currentTheme?.theme?.orange}
        >{`${fileUploadProgress}%`}</AppText>
      ) : (
        <AppIcon
          style={{
            alignSelf: "center",
          }}
          isClickable
          onPress={() => fileInputRef.current.click()}
          icon={AppAssets.icons.image}
          size={24}
        />
      )}
    </View>
  );
};

export default ImagePlaceholder;
