import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect } from "react";
import { Platform, StyleSheet } from "react-native";
import UsersInviteContainer from "../../Components/UsersInvite/UsersInviteContainer";
import usePath from "../../Hooks/usePath";
import useSections from "../../Hooks/useSections";
import WorkspaceSettings from "../Settings/Components/WorkspaceSettings";
import ApiIntegration from "./Components/ApiIntengrationView";
import LogsContainer from "./Containers/LogsContainer";
import NotificationsContainer from "./Containers/NotificationsContainer";
import SingleSignOnContainer from "./Containers/SingleSignOnContainer";
import HumanFeedbackContainer from "./Containers/HumanFeedbackContainer";
import ChartingSettingsContainer from "./Containers/ChartingSettingsContainer";
import EmbeddingsSettingsContainer from "./Containers/EmbeddingsSettingsContainer";
const AdminScreen = ({ workspace, handleWorkspaceUpdate }) => {
  const { currentTheme } = AppTheme.useTheme();
  const { viewRenderer, setDisplayedView } = useSections();
  const styles = styleGenerator(currentTheme);
  const { params } = usePath();
  useEffect(() => {
    if (params.sectionId) {
      setDisplayedView(params.sectionId?.toUpperCase());
    }
  }, [params.sectionId]);

  const mapDisplayedViewWithComponent = (uid) => {
    switch (uid) {
      case "WORKSPACE_SETTINGS":
        return <WorkspaceSettings readOnly={false} />;
      case "USERS":
        return <UsersInviteContainer />;
      case "API_INTEGRATION":
        return <ApiIntegration />;
      case "SSO":
        return <SingleSignOnContainer />;
      case "LOGS":
        return <LogsContainer workspace={workspace} />;
      case "NOTIFICATIONS":
        return <NotificationsContainer workspace={workspace} />;
      case "HUMAN_FEEDBACK":
        return <HumanFeedbackContainer workspace={workspace} />;
      case "CHARTING":
        return <ChartingSettingsContainer workspace={workspace} />;
      case "EMBEDDING":
        return <EmbeddingsSettingsContainer workspace={workspace} />;
      default:
        return <></>;
    }
  };

  return viewRenderer(mapDisplayedViewWithComponent, styles);
};
const styleGenerator = (currentTheme) => {
  return StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 10,
      paddingHorizontal: 20,
    },
    icon: {
      borderRadius: 5,
      width: "auto",
      height: "auto",
    },
    modal: {
      borderRadius: 30,
      height: Platform.OS === "web" ? 500 : "95%",
      left: "auto",
      marginHorizontal: Platform.OS === "web" ? "auto" : "5%",
      marginVertical: "auto",
      top: "auto",
      width: Platform.OS === "web" ? 500 : "90%",
    },
    modalInnerContent: {
      paddingHorizontal: 20,
      paddingTop: "auto",
    },
    modalRightIcon: {
      position: "relative",
      top: "auto",
      right: "auto",
    },
    modalWrapper: {
      height: "auto",
    },
    title: {
      fontSize: currentTheme?.theme?.largest_font_size,
      fontWeight: "bold",
    },
  });
};

export default AdminScreen;
