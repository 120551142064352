import React from "react";
import AppOutputFeedback from "@toothfairy/shared-ui/AppOutputFeedback";
const FeedbackView = ({ message, handleFeedback = () => {} }) => {
  return (
    <AppOutputFeedback
      message={message}
      handleFeedback={handleFeedback}
      isOutputStream
    />
  );
};

export default FeedbackView;
