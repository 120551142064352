import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import AdminInfoLeaf from "../../../Admin/Components/SubComponents/AdminInfoLeaf";
import envConfig from "../../../../../envConfig";

const CustomExecutionSettingsAIView = ({
  isEdit,
  settingType,
  handleDeletion,
  selectedId,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const entityName = "Code exec.";

  const { state } = AppStateManager.useAppStateManager();
  const { getActiveWorkspace } = useWorkspaces();
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  const { setFieldValue, values } = AppForms.useFormikContext();

  return (
    <>
      <>
        <View>
          {isEdit && (
            <AdminInfoLeaf
              wrapperStyle={{
                alignSelf: "flex-start",
                marginBottom: 10,
              }}
              textContainer={{
                marginLeft: 5,
                marginTop: 10,
                marginBottom: 0,
              }}
              titleStyle={{
                marginTop: 0,
                marginLeft: 20,
              }}
              textStyle={{
                marginLeft: 0,
              }}
              title={"ID"}
              value={values?.id}
              width={420}
            />
          )}
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Code execution env. name`}
            placeholder={`Name of the execution environment`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="name"
          />
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            label={`Description`}
            placeholder={`Description of what the code execution environment is used for`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="description"
          />
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Remote environment name`}
            placeholder={`Name of the remote environment that will be used to execute the code`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="functionName"
          />
          {values?.functionName && values?.functionName?.trim() != "" && (
            <AppForms.AppFormField
              showTextInputLabel
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              multiline
              textContainer={{
                minHeight: 120,
                paddingVertical: 10,
              }}
              label={`Available libraries`}
              placeholder={`List of the available libraries`}
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name="availableLibraries"
            />
          )}

          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            label={`Code execution instructions`}
            placeholder={`Additional set of instructions to be used to run and generate code executions`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="customExecutionInstructions"
          />
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 360,
              paddingVertical: 10,
            }}
            label={`Predefined code snippet`}
            placeholder={`Code that will be used to base the generation of the executable code by the agent`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="customExecutionCode"
          />
          <AppForms.AppFormSwitch
            // disabled={isEdit}
            // disabled={true}
            wrapperStyle={[
              {
                backgroundColor: isEdit
                  ? currentTheme?.theme?.grey
                  : currentTheme?.theme?.lightGrey,
                maxHeight: 30,
                paddingHorizontal: 10,
                borderRadius: 20,
                paddingVertical: 18,
                margin: 5,
              },
            ]}
            labelTextStyle={{
              fontSize: currentTheme?.theme?.small_font_size,
              color: currentTheme?.theme?.darkGrey,
            }}
            showTextInputLabel={true}
            label={"Enable external API calls"}
            displayIsTicked={true}
            name="allowExternalAPI"
            value={values?.allowExternalAPI}
            disabled={
              getActiveWorkspace()?.subscriptionType != "enterprise" &&
              envConfig?.REACT_APP_ENV != "dev"
            }
          />
        </View>
      </>
      <View style={{ alignItems: "center" }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default CustomExecutionSettingsAIView;
