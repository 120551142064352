import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React from "react";
import { Platform, View } from "react-native";
import ToothFairySlider from "../../../../Components/ToothFairyComponents/ToothFairySlider";
import ToothFairySupportButton from "../../../../Components/ToothFairyComponents/ToothFairySupportButton";
import ToothFairySwitch from "../../../../Components/ToothFairyComponents/ToothFairySwitch";
import Constants from "../../../../Constants";
import useSections from "../../../../Hooks/useSections";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen, minAppWidth } from "../../../MainViewContainer";
import PreferencesGPT from "./PreferencesGPT";

const AnalysisPreferencesMRC = ({ handleUpdate, showReplyLanguage = true }) => {
  const { currentTheme } = AppTheme.useTheme();
  const { marginLeftUnderAppExpander } = useSections();
  const { getActiveWorkspace, getPlanConfig } = useWorkspaces();
  const { user } = AppUser.useUser();
  const { MRC_OPTIONS } = Constants;

  return (
    <View
      style={{
        width:
          Platform.OS === "web" && isLargeScreen() ? 450 : minAppWidth() - 50,
        marginTop: 20,
        marginLeft: marginLeftUnderAppExpander,
      }}
    >
      {MRC_OPTIONS.map((u) => {
        return (
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "space-between",
              marginBottom: 30,
            }}
          >
            <ToothFairySwitch
              wrapperStyle={{
                flex: 1,
                marginRight: 20,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
              }}
              label={u.label}
              onChange={(v) => {
                handleUpdate("parsingConfig", {
                  ...getActiveWorkspace().parsingConfig,
                  [u?.logicalPropertyName]: v,
                });
              }}
              isTicked={
                getActiveWorkspace()?.parsingConfig &&
                getActiveWorkspace()?.parsingConfig[u?.logicalPropertyName]
              }
            />
            <ToothFairySupportButton _pageId={34} />
          </View>
        );
      })}
      {getPlanConfig()?.isBatchJobsEnabled && (
        <ToothFairySlider
          label="Max questions (batch only)"
          onSubmit={(v) =>
            handleUpdate("parsingConfig", {
              ...getActiveWorkspace().parsingConfig,
              questionsLimit: v,
            })
          }
          initialValue={
            getActiveWorkspace()?.parsingConfig?.questionsLimit ||
            Constants.questionsLimit
          }
        />
      )}

      <ToothFairySlider
        minimumValue={1}
        maximumValue={10}
        label="Doc TopK"
        onSubmit={(v) =>
          handleUpdate("parsingConfig", {
            ...getActiveWorkspace().parsingConfig,
            qaDocTopK: v,
          })
        }
        initialValue={
          getActiveWorkspace()?.parsingConfig?.qaDocTopK || Constants.qaDocTopK
        }
      />
      <PreferencesGPT
        isQA={true}
        step={5}
        handleUpdate={handleUpdate}
        frequencyPenaltyProperty={"qaFrequencyPenalty"}
        presencePenaltyProperty="qaPresencePenalty"
        temperatureProperty={"qaTemperature"}
        maxTokensProperty={"qaMaxTokens"}
        topPProperty={"qaTopP"}
        TopKProperty={"qaTopK"}
      />
    </View>
  );
};

export default AnalysisPreferencesMRC;
