import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import envConfig from "../../../envConfig";
import Common from "../Common";

const generateRequestPayload = ({
  chatid,
  token,
  workspaceid,
  includeReadComprehensionData,
  forcedLanguage,
  uniqueAnswer,
  answerByParagraph,
  messages,
  storeMessages,
  stream,
  nextMessageId,
  agentid,
  baseImage,
}) => {
  let body = {
    chatid,
    workspaceid,
    includeReadComprehensionData,
    forcedLanguage,
    uniqueAnswer,
    answerByParagraph,
    messages,
    storeMessages,
    stream,
    nextMessageId,
    agentid,
    baseImage,
  };

  return Common.objectCleaner(body);
};

const getAnswerinChat = async ({
  chatid,
  token,
  workspaceid,
  includeReadComprehensionData,
  forcedLanguage,
  uniqueAnswer,
  answerByParagraph,
  messages,
  storeMessages = false,
  stream,
  nextMessageId,
  agentid,
  baseImage,
  methodName = "chatter",
}) => {
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/${methodName}`;

  const body = generateRequestPayload({
    chatid,
    token,
    workspaceid,
    includeReadComprehensionData,
    forcedLanguage,
    uniqueAnswer,
    answerByParagraph,
    messages,
    storeMessages,
    stream,
    nextMessageId,
    agentid,
    baseImage,
  });

  return secureRequest(client, "POST", url, null, body, false, headers);
};

export default {
  getAnswerinChat,
};
