import React from "react";
import ToothFairyModal from "./ToothFairyModal";

const ToothFairyResponsiveOptionsModal = ({
  children,
  isModalOpen,
  setIsModalOpen,
  modalTitle,
  height = 400,
  ...props
}) => {
  return (
    <ToothFairyModal
      modalStyle={{
        height: height,
        margin: "auto",
        width: "95%",
        maxWidth: 500,
        marginLeft: "auto",
        marginRight: "auto",
      }}
      modalInnerContentStyle={{
        padding: 30,
        justifyContent: "space-evenly",
        gap: 15,
      }}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      modalTitle={modalTitle}
      {...props}
    >
      {children}
    </ToothFairyModal>
  );
};

export default ToothFairyResponsiveOptionsModal;
