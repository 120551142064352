import { AppIcon } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppMultipleSelectInput from "@toothfairy/shared-ui/AppMultipleSelectInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { isLargeScreen } from "../../MainViewContainer";
import Color from "../../../Utility/Color";
import ToothFairyModal from "../../../Components/ToothFairyComponents/ToothFairyModal";
import SettingsAI from "../../Settings/Components/SettingsAI";
import AppToast from "@toothfairy/shared-ui/AppToast";

const TopicsSelectionComponent = ({
  isTopicUpdateModeEnabled,
  selectedTopics,
  setIsTopicUpdateModeEnabled,
  onSelectedTopicsChange,
  topics,
  showEditIcon = true,
  placeholder = "Select topics",
  wrapperContainerStyle,
  wrapperStyle,
  showAddNew = false,
  onTopicCreated = () => {},
  ...props
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTopics, setNewTopics] = useState([]);
  const AppToasty = AppToast.useToast();
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.border_color,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
      borderRadius: 20,
    }),
    input: (base) => ({
      ...base,
      color: currentTheme?.theme?.font_color, // This would change the color of the text inside the input
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let color = new Color(data?.backgroundColor);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? currentTheme?.theme?.white
            ? "white"
            : "black"
          : data?.backgroundColor,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data?.backgroundColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      let color = new Color(data?.backgroundColor || currentTheme?.theme?.grey);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data?.backgroundColor,
      ":hover": {
        backgroundColor: data?.backgroundColor,
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.lightGray,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
    }),
  };
  const _newTopic = useMemo(() => {
    return (
      <ToothFairyModal
        modalTitle={"Add New Topic"}
        modalStyle={{
          height: "auto",
          margin: "auto",
          width: "95%",
          maxHeight: "99%",
          maxWidth: 515,
          marginLeft: "auto",
          marginRight: "auto",
        }}
        modalInnerContentStyle={{
          padding: 30,
          justifyContent: "space-evenly",
        }}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <SettingsAI
          settingType="topic"
          onEntityCreated={(v) => {
            setIsModalOpen(false);
            if (v == "success") {
              setTimeout(() => {
                AppToasty.show("Topic added successfully", {
                  type: "success",
                });
              });
              onTopicCreated();
            } else {
              setTimeout(() => {
                AppToasty.show("An issue occurred while adding the topic", {
                  type: "error",
                });
              });
            }
          }}
        />
      </ToothFairyModal>
    );
  }, [topics, isModalOpen]);

  const [tempTopics, setTempTopics] = useState(selectedTopics || []);
  useEffect(() => {
    setTempTopics(selectedTopics);
  }, [selectedTopics]);

  const removeDuplicates = (array) => {
    if (array && typeof array === "object" && array instanceof Array) {
      let unique = array
        ?.map((e) => e && e.id)
        ?.map((e, i, final) => final.indexOf(e) === i && i)
        ?.filter((e) => array[e])
        ?.map((e) => array[e]);
      return unique;
    } else return array;
  };

  return (
    <View
      style={[
        {
          flexDirection: "row",
          alignItems: "center",

          zIndex: 1,
          minWidth: "50%",
          flex: 1,
        },
        wrapperStyle,
      ]}
    >
      <AppMultipleSelectInput
        onSelectionChange={(items) => {
          if (showEditIcon) setTempTopics(items);
          else {
            onSelectedTopicsChange(items);
          }
        }}
        selectedItems={removeDuplicates(tempTopics)}
        placeholder={placeholder}
        hidePlaceholder={true}
        disablePreSelectedValues={!isTopicUpdateModeEnabled}
        isDisabled={!isTopicUpdateModeEnabled}
        items={removeDuplicates(topics)}
        uniqueKey="id"
        showDropDowns={true}
        readOnlyHeadings={true}
        labelOption={"name"}
        groupByProperty="category"
        style={{ maxWidth: 800 }}
        wrapperContainerStyle={[
          { flex: !isLargeScreen() ? 1 : 0.8 },
          wrapperContainerStyle,
        ]}
        styles={colourStyles}
        options={removeDuplicates(
          topics.map((u) => {
            return {
              ...u,
              value: u?.id,
            };
          })
        )}
        {...props}
      />
      {showEditIcon && (
        <AppIcon
          isClickable={true}
          icon={
            isTopicUpdateModeEnabled
              ? AppAssets.icons.check
              : AppAssets.icons.edit
          }
          onPress={() => {
            if (isTopicUpdateModeEnabled) {
              onSelectedTopicsChange(tempTopics);
            }

            setIsTopicUpdateModeEnabled(!isTopicUpdateModeEnabled);
          }}
          size={18}
          style={{ marginLeft: 10 }}
        />
      )}
      {showAddNew && isTopicUpdateModeEnabled && (
        <AppIcon
          isClickable={true}
          icon={AppAssets.icons.add_icon}
          onPress={() => {
            setIsModalOpen(!isModalOpen);
          }}
          size={20}
          style={{ marginHorizontal: 10 }}
        />
      )}
      {_newTopic}
    </View>
  );
};

export default TopicsSelectionComponent;
