import { AppText } from "@toothfairy/shared-ui";
import AppButton from "@toothfairy/shared-ui/AppButton";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View, useWindowDimensions } from "react-native";
import Constants from "../../../Constants";
import { useHistory } from "../../../Router";
import { isLargeScreen } from "../../MainViewContainer";
import WorkspaceFormView from "./WorkspaceFormView";

const WorkspaceForm = forwardRef(
  (
    {
      modalContentKey,
      handleFormSubmit,
      formValidationSchema,
      initialValues,
      setModalContentKey,
      setIsModalOpen,
      hasPaymentMethod,
      allowTrial,
      cancelPlanChange,
      targetPlan,
      setTargetPlan,
      selectedWorkspace,
      workspaceMutationInProgress,
      onPlanSelection,
      onValuesChange,
      onSubmit,
      forceEnabled,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { currentTheme } = AppTheme.useTheme();
    const dimensions = useWindowDimensions();
    const { user } = AppUser.useUser();
    const coreViewHeight = dimensions.height - 150;
    const _styles = styleGenerator(currentTheme, coreViewHeight);
    const { onboardingData, workspaceCreationData } = Constants;
    const notAllowedToEditoWorkspaces =
      !hasPaymentMethod && user?.stripeCustomerData?.balance > -0.001;
    // && !allowTrial && !isUserOnTrial();
    const _formMinHeight = useMemo(() => {
      if (notAllowedToEditoWorkspaces) return 100;
      else return 380;
    }, [notAllowedToEditoWorkspaces, modalContentKey]);
    return (
      <>
        {/* <AppChoice
          wrapperStyle={{ alignSelf: "center", maxWidth: 600, marginTop: 100 }}
          cancelCaption="Click 'Cancel' to close this window"
          confirmCaption="Click 'Confirm' to confirm the selection of a plan"
          onConfirmButtonPress={() => {}}
          onCancelButtonPress={() => {}}
          AppChoiceButton={ToothFairyButton}
          caption="Choose your ToothFairyAI plan"
          subCaption="Changing plans can be done at any time once a month"
        /> */}
        <AppForms.AppForm
          ref={ref}
          enableReinitialize
          onValuesChange={onValuesChange}
          style={{
            minWidth: 350,
            minHeight: _formMinHeight,

            alignSelf: "center",
            justifyContent: "space-between",
            paddingTop: 20,
          }}
          onSubmit={handleFormSubmit}
          validationSchema={formValidationSchema}
          initialValues={initialValues}
        >
          {notAllowedToEditoWorkspaces ? (
            <View
              style={{
                paddingVertical: 10,
                flexDirection: "row",
                justifyContent: "center",
                maxWidth: 350,
              }}
            >
              <AppText style={{ maxWidth: 350 }} preventTranslation={true}>
                {t("noTrialNoPaymentCaption")}{" "}
                <AppText
                  isClickable={true}
                  preventTranslation={true}
                  onPress={() => history.push("/profile/billing")}
                  style={{
                    fontWeight: "bold",
                    color: currentTheme?.theme?.blue,
                  }}
                >
                  {t("your payment details")}
                </AppText>
              </AppText>
            </View>
          ) : (
            <>
              <WorkspaceFormView
                selectedWorkspace={selectedWorkspace}
                modalContentKey={modalContentKey}
                onboardingData={onboardingData}
                workspaceCreationData={workspaceCreationData}
                cancelPlanChange={cancelPlanChange}
                targetPlan={targetPlan}
                setTargetPlan={setTargetPlan}
                onPlanSelection={onPlanSelection}
              />
              <View>
                <AppForms.AppSubmitButton
                  isDisabled={
                    notAllowedToEditoWorkspaces || workspaceMutationInProgress
                  }
                  onSubmit={onSubmit}
                  forceEnabled={!workspaceMutationInProgress}
                  awesomeIconColor={currentTheme?.theme?.white}
                  backgroundColor={currentTheme?.theme?.primary}
                  btnContentStyle={{
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  style={[
                    {
                      height: Constants.appButtonHeight,
                      width: "auto",
                      shadowColor: currentTheme?.theme?.transparent,
                      marginHorizontal: 10,
                    },
                  ]}
                  btnLabelStyle={{ fontSize: 16 }}
                  wrapperStyle={[
                    {
                      width: "fit-content",
                      minWidth: 150,
                      marginTop: 20,
                      height: Constants.appButtonHeight,
                      alignSelf: "center",
                    },
                  ]}
                  title="Save"
                />
                {modalContentKey !== "NEW" && (
                  <AppButton
                    isSecondary
                    style={_styles.deleteButton}
                    btnLabelStyle={{ color: currentTheme?.theme?.red }}
                    backgroundColor={currentTheme?.theme?.white}
                    isDisabled={
                      (!hasPaymentMethod && !allowTrial) ||
                      workspaceMutationInProgress
                    }
                    title={"Delete"}
                    onPress={() => {
                      setModalContentKey("DELETE");
                      setIsModalOpen(true);
                    }}
                  />
                )}
              </View>
            </>
          )}
        </AppForms.AppForm>
      </>
    );
  }
);

const styleGenerator = (props, coreViewHeight) => {
  return StyleSheet.create({
    submitBotton: {
      marginTop: 10,
      alignSelf: "center",
      minWidth: 100,
    },
    deleteButton: {
      marginTop: 10,
      alignSelf: "center",
      minWidth: 130,
      height: Constants.appButtonHeight,
      shadowColor: "rgba(0, 0, 0, 0)",
    },
  });
};

export default WorkspaceForm;
