import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { useWindowDimensions, View } from "react-native";
import AppDoughnut from "./Components/Doughnut";
import BattlesOverview from "./Components/BattlesOverview";
import ModelsList from "./Components/ModelsList";
import ArenaHeader from "../Components/ArenaHeader";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";

const DashboardScreen = ({ dashboardData }) => {
  const { currentTheme, mode, setMode } = AppTheme.useTheme();
  const { height } = useWindowDimensions();

  const { state } = AppStateManager?.useAppStateManager();
  const doughnutChartOptions = {
    legend: {
      labels: {
        color: currentTheme?.theme?.font_color,
      },
    },
  };

  return (
    <View
      style={{
        display: "flex",
        height: height - 45,
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <ArenaHeader
        backRoute={{
          label: "Go to arena",
          route: "/arena",
        }}
        currentRoute={{ label: "Leaderboard" }}
      />
      {state?.selectedArenaMode != "none" ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            height: "80%",
            marginTop: 50,
          }}
        >
          <View
            style={{
              flex: 1,
              minWidth: 350,
            }}
          >
            <AppDoughnut
              options={doughnutChartOptions}
              maxHeight={600}
              dataset={{
                labels: dashboardData?._leaderBoardAgents?.map((u) => u?.label),
                datasets: [
                  {
                    label: "Wins",
                    data: dashboardData?._leaderBoardAgents?.map(
                      (u) => u?.wins
                    ),
                    backgroundColor: [
                      currentTheme?.theme?.primary,
                      currentTheme?.theme?.mediumGrey,
                      currentTheme?.theme?.orange,
                      currentTheme?.theme?.red,
                      currentTheme?.theme?.purple,
                      currentTheme?.theme?.yellow,
                    ],
                  },
                ],
              }}
            />
          </View>
          <View
            style={{
              justifyContent: "space-around",
              padding: 20,
            }}
          >
            <BattlesOverview dashboardData={dashboardData} />
            <ModelsList />
          </View>
        </View>
      ) : (
        <AppPlaceholderView
          wrapperStyle={{
            top: "30%",
          }}
          accessibilityLabel="arena-placeholder"
          size={60}
          icon={AppAssets.icons.orbit}
          mainCaption={"Please select an agent mode"}
          subCaption={"The arena compares only agents of the same type"}
        />
      )}
    </View>
  );
};

export default DashboardScreen;
