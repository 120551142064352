import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";
import Constants from "../Constants";

const useScreenSize = () => {
  const dimensions = useWindowDimensions();
  const [isLargeScreen, setLargeScreen] = useState(
    dimensions?.width >= Constants.screenResponsiveWidth
  );
  const [marginLeftUnderAppExpander, setMarginLeftUnderAppExpander] = useState(
    dimensions?.width >= Constants.screenResponsiveWidth ? 42 : 0
  );
  useEffect(() => {
    const _largeScreen = dimensions?.width >= Constants.screenResponsiveWidth;
    setLargeScreen(_largeScreen);
    setMarginLeftUnderAppExpander(_largeScreen ? 42 : 0);
  }, [dimensions?.width]);

  return { isLargeScreen, marginLeftUnderAppExpander };
};

export default useScreenSize;
