import React, { useState } from "react";
import config from "../../Auth/aws-exports";
import AppAuth from "../../../../shared-ui/AppAuthScreen";
import ToothFairyLoadingPlaceholder from "../../Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";
import envConfig from "../../../envConfig";
import SSO from "../../API/SSO";
import AppToast from "@toothfairy/shared-ui/AppToast";

const AuthScreen = ({
  onCognitoUserAuthenticated,
  setCurrentView,
  isFederatedSignInProgress,
  setFederatedSignInProgress,
}) => {
  const isNode = false;
  const handleSignIn = async () => {
    console.log("on sign in");
    setCurrentView("mainNav");
  };
  const AppToasty = AppToast.useToast();
  return isFederatedSignInProgress ? (
    <ToothFairyLoadingPlaceholder caption="Logging in with provider..." />
  ) : (
    <AppAuth.AppAuthScreen
      config={config}
      setFederatedSignInProgress={setFederatedSignInProgress}
      getSSOproviderName={SSO.getSSOproviderName}
      brandImage={
        isNode
          ? require("../../assets/logoTitle.png").default
          : require("../../assets/logoTitle.png")
      }
      bgImage={
        isNode
          ? require("../../assets/graphics/meshOverlayOfCircuitsWithBlueBg.jpg")
              .default
          : require("../../assets/graphics/meshOverlayOfCircuitsWithBlueBg.jpg")
      }
      iconApple={
        isNode
          ? require("../../../../shared/src/assets/social/logoApple.png")
              .default
          : require("../../../../shared/src/assets/social/logoApple.png")
      }
      iconEmail={
        isNode
          ? require("../../../../shared/src/assets/brand/logo_createaccount.png")
              .default
          : require("../../../../shared/src/assets/brand/logo_createaccount.png")
      }
      iconFacebook={
        isNode
          ? require("../../../../shared/src/assets/social/logo_facebook.png")
              .default
          : require("../../../../shared/src/assets/social/logo_facebook.png")
      }
      iconGoogle={
        isNode
          ? require("../../../../shared/src/assets/social/logo_google.png")
              .default
          : require("../../../../shared/src/assets/social/logo_google.png")
      }
      iconMicrosoft={
        isNode
          ? require("../../../../shared/src/assets/social/microsoft.png")
              .default
          : require("../../../../shared/src/assets/social/microsoft.png")
      }
      // iconSignIn={
      //   isNode
      //     ? require("../../assets/favicon.png").default
      //     : require("../../assets/favicon.png")
      // }
      updateAuth={setCurrentView}
      onSignIn={handleSignIn}
      isSignInWithGoogleEnabled={true}
      isSSOwithAzureAD={true}
      onProviderNotFound={() => {
        AppToasty.show(
          "The domain email is not allowed to authenticate. Please contact your administrator.",
          {
            type: "danger",
          }
        );
      }}
    />
  );
};

export default AuthScreen;
