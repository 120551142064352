import secureRequest from "@toothfairy/shared-api/secureAPI";
import envConfig from "../../../envConfig";
import client from "@toothfairy/shared-api/src/Common/client";

const processAudio = async ({ token, data }) => {
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/media/audio`;
  const body = data;

  return secureRequest(client, "POST", url, null, body, false, headers);
};

const processVideo = async ({ token, data }) => {
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/media/video`;
  const body = data;

  return secureRequest(client, "POST", url, null, body, false, headers);
};

const generateAudio = async ({ token, data }) => {
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/media/audio_generation`;
  const body = data;

  return secureRequest(client, "POST", url, null, body, false, headers);
};

export default {
  processAudio,
  processVideo,
  generateAudio,
};
