import API from "@aws-amplify/api";
import { createArenaBattle, updateArenaBattle } from "../../graphql/mutations";
import { getArenaBattle, arenaBattlesByWorkspace } from "../../graphql/queries";

const createBattle_ = async (object, authToken) => {
  return API.graphql({
    query: createArenaBattle,
    variables: { input: object },
  });
};
const getBattle_ = async (id) => {
  return API.graphql({
    query: getArenaBattle,
    variables: { id },
  });
};

const getBattlesByWorkspace = async (workspaceId) => {
  return API.graphql({
    query: arenaBattlesByWorkspace,
    variables: { workspaceID: workspaceId },
  });
};

const updateBattle = async (id, args, authToken) => {
  const original = await getBattle_(id);
  let _properties = {};
  args.forEach((item) => {
    _properties[item.property] = item.value;
  });
  return API.graphql({
    query: updateArenaBattle,
    variables: {
      input: {
        id,
        _version: original?.data?.getArenaBattle?._version,
        ..._properties,
      },
    },
  });
};

export default {
  createBattle_,
  getBattle_,
  updateBattle,
  getBattlesByWorkspace,
};
