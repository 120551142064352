import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import envConfig from "../../../envConfig";

const getDocumentsByVectorDB = async ({ text, token, workspaceid, topK }) => {
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/searcher`;
  const body = {
    text,
    workspaceid,
    topK,
  };

  return secureRequest(client, "POST", url, null, body, false, headers);
};

export default {
  getDocumentsByVectorDB,
};
