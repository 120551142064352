import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { forwardRef, useEffect, useState } from "react";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import { useHistory } from "../../../Router";
import BillingDetailsContainer from "../../Account/Components/BillingDetailsContainer";
import WorkspaceForm from "./WorkspaceForm";

const WorkspaceModalContent = forwardRef(
  (
    {
      modalContentKey,
      handleFormSubmit,
      formValidationSchema,
      initialValues,
      setShareInput,
      setModalContentKey,
      setIsModalOpen,
      handleWorkspaceDeletion,
      cancelPlanChange,
      targetPlan,
      setTargetPlan,
      selectedWorkspace,
      workspaceMutationInProgress,
      onPlanSelection,
      onValuesChange,
      onSubmit,
      forceEnabled,
    },
    ref
  ) => {
    const history = useHistory();
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
    const [allowTrial, setAllowTrial] = useState(false);
    const { user } = AppUser.useUser();

    useEffect(() => {
      setHasPaymentMethod(
        user?.stripeCustomerData?.invoice_settings?.default_payment_method !==
          null
      );
      setAllowTrial(user?.stripeCustomerData?.allowTrial);
    }, [user?.stripeCustomerData]);

    if (modalContentKey === "NEW" || modalContentKey === "UPDATE") {
      return (
        <WorkspaceForm
          selectedWorkspace={selectedWorkspace}
          hasPaymentMethod={hasPaymentMethod}
          allowTrial={allowTrial}
          formValidationSchema={formValidationSchema}
          initialValues={initialValues}
          handleFormSubmit={handleFormSubmit}
          modalContentKey={modalContentKey}
          setIsModalOpen={setIsModalOpen}
          setModalContentKey={setModalContentKey}
          cancelPlanChange={cancelPlanChange}
          targetPlan={targetPlan}
          setTargetPlan={setTargetPlan}
          workspaceMutationInProgress={workspaceMutationInProgress}
          ref={ref}
          onPlanSelection={onPlanSelection}
          onValuesChange={onValuesChange}
          onSubmit={onSubmit}
          forceEnabled={forceEnabled}
        />
      );
    } else if (modalContentKey === "DELETE") {
      return (
        <AppChoice
          wrapperStyle={{ alignSelf: "center", maxWidth: 600, marginTop: 100 }}
          cancelCaption="Click 'Cancel' to abort the deletion of the workspace"
          confirmCaption="Click 'Confirm' to permanently delete the workspace"
          onConfirmButtonPress={() => {
            handleWorkspaceDeletion();
            setIsModalOpen(false);
            history.push("/workspaces");
          }}
          onCancelButtonPress={() => {
            setIsModalOpen(false);
            setModalContentKey("UPDATE");
          }}
          AppChoiceButton={ToothFairyButton}
          caption="You are about to delete a workspace"
          subCaption="Once done there is no turning back, are you sure?"
        />
      );
    } else if (modalContentKey === "BILLING") {
      return <BillingDetailsContainer isWizard />;
    }
  }
);

export default WorkspaceModalContent;
