import apiClient from "@toothfairy/shared-api/apiClient";
import secureRequest from "@toothfairy/shared-api/secureAPI";
import envConfig from "../../../envConfig";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";

const storeSecret = async ({ workspaceid, id, passwordSecretValue }) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/secrets/store`;
  const payload = await AppAuth.Auth.currentSession();
  const passwordSecretName = `${workspaceid}/${id}`;
  return secureRequest(
    apiClient.apiClient,
    "POST",
    url,
    null,
    {
      passwordSecretName,
      passwordSecretValue,
    },
    true,
    null,
    payload
  );
};

const deleteSecret = async ({ workspaceid, id }) => {
  let url = `${envConfig.REACT_APP_REST_ENDPOINT}/secrets/delete?id=${id}&workspaceid=${workspaceid}`;
  const payload = await AppAuth.Auth.currentSession();
  return secureRequest(
    apiClient.apiClient,
    "DELETE",
    url,
    null,
    null,
    true,
    null,
    payload
  );
};

export default {
  storeSecret,
  deleteSecret,
};
