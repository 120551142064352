import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Filler,
  Legend,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
  ArcElement,
} from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  LogarithmicScale
);

defaults.font.family = "normalFont";
defaults.font.size = 14;
defaults.scale.ticks.precision = 0;

const AppDoughnut = ({ dataset, maxHeight, options, isSumRequired }) => {
  const { currentTheme } = AppTheme.useTheme();

  return (
    <Doughnut
      style={{
        // backgroundColor: currentTheme?.theme.softBackgroundColor,
        borderRadius: 10,
        maxHeight,
      }}
      options={{
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              footer: function (tooltipItem) {
                let sum = tooltipItem[0].dataset.data[tooltipItem[0].dataIndex];
                return isSumRequired ? `Cost: ${sum}€` : "";
              },
            },
          },
          legend: {
            position: "bottom",
            labels: {
              color: currentTheme?.theme?.font_color,
            },
          },
          title: {
            display: true,
            text: "Wins by agents",
            font: {
              size: 20,
              family: "normalFont",
              weight: "bold",
            },
            color: currentTheme?.theme?.font_color,
          },
          filler: {
            propagate: true,
          },
        },
      }}
      data={dataset}
    />
  );
};

export default AppDoughnut;
