import { DataStore, syncExpression } from "@aws-amplify/datastore";
import API, { graphqlOperation } from "@aws-amplify/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useApi from "@toothfairy/shared-api/useApi";
import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View, useWindowDimensions } from "react-native";
import { makeVar, useReactiveVar } from "react-reactive-var";
import {
  currentUserId,
  forcedClosed,
  isConnectedToCloud,
  pinnedSideMenu,
  showSideMenu,
  userType,
} from "../../App";
import envConfig from "../../envConfig";
import appConfigHelper from "../API/AppConfig/appConfigHelper";
import Languages from "../API/Languages";
import workspacesAPI from "../API/Workspaces/workspacesAPI";
import ToothFairyLoadingPlaceholder from "../Components/ToothFairyComponents/ToothFairyLoadingPlaceholder";
import Constants from "../Constants";
import useWorkspaces from "../Hooks/useWorkspaces";
import { DeepLinking, Router } from "../Router";
import socket from "../Socket";
import {
  AgentFunction,
  AppLog,
  Authorisation,
  Hosting,
  BatchJobLog,
  Chat,
  CustomPrompt,
  DatabaseConnection,
  DictionaryItem,
  EntityAI,
  Folders,
  GenerationConfig,
  OutputStream,
  RequestLog,
  Site,
  SubscriptionBaseConfig,
  SubscriptionPricingPlan,
  TrainingLog,
  User,
  UserType,
  UserWorkspaceType,
  Workspace,
  ArenaBattle,
  CustomCodeExecutionEnvironment,
  CommunicationService,
} from "../models";
import MainViewScreen from "./MainViewScreen";
import OnboardingScreen from "./Onboarding/OnboardingScreen";
import { ChatMessage } from "../models";
import { Agent } from "../models";
import { userToWorkspaceMapping } from "../graphql/queries";

export const appDimensions = makeVar({ width: 0, height: 0 });
export const lastMembersChange = makeVar(dayjs().toDate().getTime());
export const globalAppConfig = makeVar({});
export const isLargeScreen = makeVar(true);
export const isMobileScreen = makeVar(false);
export const isLandscapeMobile = makeVar(false);
export const isVeryLargeScreen = makeVar(false);
export const isLargerScreen = makeVar(false);
export const minAppWidth = makeVar(365);
const listPredicate = (c, list, field) => {
  return c.or((c) => list.reduce((_c, operand) => _c[field]("eq", operand), c));
};

const MainViewContainer = ({
  isLocalDBReady,
  children,
  authUserSub,
  serverWorkspaces,
  currentView,
  stripeCustomerData,
  _isInitialisationInProgress,
}) => {
  const userID = useReactiveVar(currentUserId);
  const _isConnectedToCloud = useReactiveVar(isConnectedToCloud);
  const _lastMembersChange = useReactiveVar(lastMembersChange);
  const AppToasty = AppToast.useToast();
  const { t, i18n } = useTranslation();
  const [preferencesLoaded, setPreferencesLoaded] = useState(false);
  const { setMode, mode } = AppTheme.useTheme();
  const { height, width } = useWindowDimensions();
  const { getActiveWorkspace, updateWorkspaceInContext, isUserAdmin } =
    useWorkspaces();
  const {
    data: languagesData,
    error: languagesError,
    loading: languagesRequestInProgress,
    apiRequest: languagesRequestFetchRequest,
    response: languagesRequestResponse,
  } = useApi(Languages.getAvailableLanguages, envConfig);
  isLargeScreen(width > (globalAppConfig()?.screenResponsiveWidth || 768));
  isLargerScreen(
    width > (globalAppConfig()?.largerScreenResponsiveWidth || 1024)
  );
  isMobileScreen(width < (globalAppConfig()?.mobileResponsiveWidth || 420));
  isLandscapeMobile(width < (globalAppConfig()?.mobileResponsiveWidth || 550));
  isVeryLargeScreen(
    width > (globalAppConfig()?.veryLargeScreenResponsiveWidth || 1200)
  );
  const fetchAppConfig = useCallback(async () => {
    if (isLocalDBReady) {
      // wait 2 seconds for local db to be ready
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const result = await appConfigHelper.listAppConfig();
      if (result.length === 0) {
        throw new Error("App config not found");
      }
      globalAppConfig(result[0]);
    }
  }, [isLocalDBReady]);
  useEffect(() => {
    languagesRequestFetchRequest();
  }, []);
  useEffect(() => {
    fetchAppConfig();
  }, []);
  useEffect(() => {
    dispatch(
      "SET_LANGUAGES_CONFIG",
      languagesData?.languages?.map((language) => {
        return {
          name: language[1],
          id: language[0],
        };
      })
    );
  }, [languagesData?.length]);
  useEffect(() => {
    if (userID)
      DataStore.configure({
        // fullSyncInterval: 60 * 24 * 15, // 15 days
        syncPageSize: 1000, // "limit" sent to graphql
        maxRecordsToSync: 2000000,
        syncExpressions: [
          syncExpression(User, () => {
            if (authUserSub) {
              return (user) => user.owner("eq", authUserSub);
            }
          }),
          syncExpression(Workspace, () => {
            if (userID) {
              return (workspace) =>
                workspace.users("contains", userID).disabled("ne", true);
            }
          }),
          syncExpression(TrainingLog, () => {
            if (serverWorkspaces?.length > 0) {
              return (trainingLog) =>
                listPredicate(trainingLog, serverWorkspaces, "workspaceID");
            } else return (trainingLog) => trainingLog.id("eq", "XXX");
          }),
          syncExpression(Site, () => {
            if (serverWorkspaces?.length > 0) {
              return (site) =>
                listPredicate(site, serverWorkspaces, "workspaceID");
            } else return (site) => site.id("eq", "XXX");
          }),
          syncExpression(EntityAI, () => {
            if (serverWorkspaces?.length > 0) {
              return (entity) =>
                listPredicate(entity, serverWorkspaces, "workspaceID");
            } else return (entity) => entity.id("eq", "XXX");
          }),
          syncExpression(SubscriptionPricingPlan, () => {
            return (subscriptionPricingPlan) =>
              subscriptionPricingPlan.id("eq", "XXX");
          }),
          syncExpression(SubscriptionBaseConfig, () => {
            return (subscriptionBaseConfig) =>
              subscriptionBaseConfig.id("eq", "XXX");
          }),
          syncExpression(AppLog, () => {
            return (request) => request.id("eq", "XXX");
          }),
          syncExpression(CustomPrompt, () => {
            if (serverWorkspaces?.length > 0) {
              return (prompt) =>
                listPredicate(prompt, serverWorkspaces, "workspaceID");
            } else return (prompt) => prompt.id("eq", "XXX");
          }),
          syncExpression(Agent, () => {
            if (serverWorkspaces?.length > 0) {
              return (agent) =>
                listPredicate(agent, serverWorkspaces, "workspaceID");
            } else return (agent) => agent.id("eq", "XXX");
          }),
          syncExpression(OutputStream, () => {
            return (request) => request.id("eq", "XXX");
          }),
          syncExpression(RequestLog, () => {
            return (request) => request.id("eq", "XXX");
          }),

          syncExpression(Chat, async () => {
            if (serverWorkspaces?.length > 0) {
              return (chat) =>
                listPredicate(chat, serverWorkspaces, "workspaceID");

              // const chats = await usersAPI.getAvailableChats(
              //   user.username,
              //   serverWorkspaces.map((element) => `'${element}'`).join(", ")
              // );
              // const syncChats = chats?.data?.data?.map((u) => u?.id) || [];
              // if (syncChats?.length > 0)
              //   return (chat) => listPredicate(chat, syncChats, "id");
            } else return (chat) => chat.id("eq", "XXX");
          }),
          syncExpression(Folders, () => {
            if (serverWorkspaces?.length > 0) {
              return (folder) =>
                listPredicate(folder, serverWorkspaces, "workspaceID");
            } else return (folder) => folder.id("eq", "XXX");
          }),
          syncExpression(BatchJobLog, () => {
            if (serverWorkspaces?.length > 0) {
              return (batchLog) =>
                listPredicate(batchLog, serverWorkspaces, "workspaceID");
            } else return (batchLog) => batchLog.id("eq", "XXX");
          }),
          syncExpression(UserWorkspaceType, () => {
            return (usertype) => usertype.id("eq", "XXX");
          }),
          syncExpression(ChatMessage, () => {
            return (mes) => mes.id("eq", "XXX");
          }),
          syncExpression(DictionaryItem, () => {
            if (serverWorkspaces?.length > 0) {
              return (dictonaryItem) =>
                listPredicate(dictonaryItem, serverWorkspaces, "workspaceID");
            } else return (dictonaryItem) => dictonaryItem.id("eq", "XXX");
          }),
          syncExpression(AgentFunction, () => {
            if (serverWorkspaces?.length > 0) {
              return (agentFunction) =>
                listPredicate(agentFunction, serverWorkspaces, "workspaceID");
            } else return (agentFunction) => agentFunction.id("eq", "XXX");
          }),
          syncExpression(DatabaseConnection, () => {
            if (serverWorkspaces?.length > 0) {
              return (dbConnection) =>
                listPredicate(dbConnection, serverWorkspaces, "workspaceID");
            } else return (dbConnection) => dbConnection.id("eq", "XXX");
          }),
          syncExpression(Authorisation, () => {
            if (serverWorkspaces?.length > 0) {
              return (authorisation) =>
                listPredicate(authorisation, serverWorkspaces, "workspaceID");
            } else return (authorisation) => authorisation.id("eq", "XXX");
          }),
          syncExpression(Hosting, () => {
            if (serverWorkspaces?.length > 0) {
              return (hosting) =>
                listPredicate(hosting, serverWorkspaces, "workspaceID");
            } else return (hosting) => hosting.id("eq", "XXX");
          }),
          syncExpression(ArenaBattle, () => {
            return (battle) => battle.id("eq", "XXX");
          }),
          syncExpression(CustomCodeExecutionEnvironment, () => {
            return (battle) => battle.id("eq", "XXX");
          }),
          syncExpression(CommunicationService, () => {
            return (battle) => battle.id("eq", "XXX");
          }),
        ],
      });
  }, [authUserSub, serverWorkspaces?.length, userID]);

  const [readyForSubs, setReadyForSubscriptions] = useState(true);
  const { user, isIncognito, setIsIncognito, updateUser, fetchUser } =
    AppUser.useUser();

  const { dispatch, state } = AppStateManager.useAppStateManager();
  const sideMenuOpen = useReactiveVar(showSideMenu);
  const [_styles, setStyles] = useState(styles({ height, sideMenuOpen }));
  const userEmail = user?.user_email;

  useEffect(() => {
    if (state?.activeWorkspaceID && state?.isWorkspaceCollabReady !== true) {
      console.log("JOINING WORKSPACE");
      socket.joinWorkspace(state?.activeWorkspaceID, user?.username);
      socket.listenToNotifications((err, data) => {
        switch (data?.event) {
          case "init":
            dispatch("SET_WORKSPACE_ACTIVE_USERS", data?.msg?.users);
            dispatch("SET_WORKSPACE_COLLAB_READY", true);
            break;
          case "user-joined":
            dispatch("SET_WORKSPACE_ACTIVE_USERS", [
              ...state?.activeWorkspaceUsers,
              data?.msg,
            ]);
            break;
          case "user-left":
            const sItemIndex = state?.activeWorkspaceUsers
              .map((i) => i.id)
              .indexOf(data?.msg?.id);
            state?.activeWorkspaceUsers.splice(sItemIndex, 1);
            dispatch("SET_WORKSPACE_ACTIVE_USERS", state?.activeWorkspaceUsers);
          case "connect_error":
            if (state?.isWorkspaceCollabReady !== false)
              dispatch("SET_WORKSPACE_COLLAB_READY", false);
          default:
            break;
        }
        if (err) {
          return;
        }
      });
    }
    return () => {
      socket.leaveWorkspace(state?.activeWorkspaceID, user?.username);
    };
  }, [
    state?.activeWorkspaceID,
    _isConnectedToCloud,
    state?.isWorkspaceCollabReady,
  ]);

  useEffect(() => {
    if (state?.activeWorkspaceID) {
      loadEntities(state?.activeWorkspaceID);
      loadTrainingLogs(state?.activeWorkspaceID);
      loadAppLogs(state?.activeWorkspaceID);
      loadBatchLogs(state?.activeWorkspaceID);
      loadAgents(state?.activeWorkspaceID);
      loadCustomPrompts(state?.activeWorkspaceID);
      loadSites(state?.activeWorkspaceID);
      loadBaseGenerationConfig();
    }
  }, [state?.activeWorkspaceID]);

  useEffect(() => {
    if (getActiveWorkspace()?.userID && user?.id) {
      loadAccessCredentials();
    }
  }, [getActiveWorkspace()?.userID, user?.id]);

  useEffect(() => {
    if (state?.activeWorkspaceID && state?.workspaces) {
      const workspace = state?.workspaces?.find(
        (wp) => wp.id === state?.activeWorkspaceID
      );
      if (workspace?.workspaceToken && workspace?.workspaceTokenID)
        dispatch("SET_WORKSPACE_READY", true);
    }
  }, [state?.activeWorkspaceID, state?.workspaces]);

  useEffect(() => {
    // Unclear whether this is the correct way of doing it as no clear docs provided #310
    const userSubscription = DataStore.observe(User, (c) =>
      c.owner("eq", authUserSub)
    ).subscribe((msg) => {});

    return () => {
      userSubscription?.unsubscribe();
    };
  }, [authUserSub]);

  useEffect(() => {
    const openWorkspaces =
      state?.workspaces
        ?.filter(
          (u) => !u.workspaceToken || !u.workspaceTokenID || !u.activeUntil
        )
        ?.map((u) => u?.id) || [];
    // Unclear whether this is the correct way of doing it as no clear docs provided #310
    const workspaceSubscription = DataStore.observe(Workspace, (c) =>
      listPredicate(c, openWorkspaces, "id").id("eq", state?.activeWorkspaceID)
    ).subscribe((msg) => {
      let currentWorkspace = { ...getActiveWorkspace() };
      if (
        msg?.element?.workspaceToken &&
        msg?.element?.workspaceTokenID &&
        !state?.isWorkspaceReady
      ) {
        dispatch("SET_WORKSPACE_READY", true);
        AppToasty.show(t("AiReady"), { type: "success" });
      }
      // CONTINUE LATER
      if (msg?.element?.workspaceToken && !currentWorkspace?.workspaceToken) {
        currentWorkspace.workspaceToken = msg?.element?.workspaceToken;
        updateWorkspaceInContext(currentWorkspace);
      }
    });

    const workspacesSubscription = DataStore.observe(Workspace, (c) =>
      listPredicate(c, openWorkspaces, "id")
    ).subscribe((msg) => {
      // check applied to ensure the model returned has all the fields
      if (
        msg?.element?.activeUntil &&
        state?.workspaces?.find((w) => w.id === msg?.element?.id)
      ) {
        let newWorkspaces = [...state?.workspaces];
        let workspaceIndex = newWorkspaces?.findIndex(
          (w) => w.id === msg?.element?.id
        );
        if (
          workspaceIndex !== -1 &&
          !newWorkspaces[workspaceIndex].activeUntil
        ) {
          let newWorkspace = { ...newWorkspaces[workspaceIndex] };
          newWorkspace.activeUntil = msg?.element?.activeUntil;
          newWorkspace.workspaceToken = msg?.element?.workspaceToken;
          newWorkspace.workspaceTokenID = msg?.element?.workspaceTokenID;
          newWorkspace.subscriptionType = msg?.element?.subscriptionType;
          newWorkspace.subscriptionId = msg?.element?.subscriptionId;
          newWorkspace.subscriptionStatus = msg?.element?.subscriptionStatus;
          newWorkspace.targetSubscriptionType =
            msg?.element?.targetSubscriptionType;
          newWorkspaces[workspaceIndex] = newWorkspace;
          dispatch("SET_WORKSPACES", [...newWorkspaces]);
        }
      }
    });
    const activeWorkspacesSubscription = DataStore.observe(Workspace, (c) =>
      c.id("eq", state?.activeWorkspaceID)
    ).subscribe((msg) => {
      // check applied to ensure the model returned has all the fields
      if (msg?.element?.activeUntil) {
        if (msg?.opType === "UPDATE") {
          if (getActiveWorkspace()?.id !== msg?.element?.id) {
            handleStateUpdateFromSubMessage(
              msg,
              "workspaces",
              "SET_WORKSPACES"
            );
          }
        }
      }
    });

    return () => {
      workspaceSubscription?.unsubscribe();
      workspacesSubscription?.unsubscribe();
      activeWorkspacesSubscription?.unsubscribe();
    };
  }, [
    state?.activeWorkspaceID,
    state?.isWorkspaceReady,
    state?.workspaces?.length,
    getActiveWorkspace()?.nlpUrl,
    getActiveWorkspace()?.qaUrl,
  ]);
  const loadUsersWorkspace = async (activeWorkspaceID) => {
    let data;
    try {
      if (_isConnectedToCloud) {
        const result = await workspacesAPI.getWorkspaceUsers(activeWorkspaceID);
        data = result?.data?.getUsersWorkspace;
        const jsonValue = JSON.stringify(data);
        await AsyncStorage.setItem("workspaceUsers", jsonValue);
      } else {
        const stringValue = AsyncStorage.getItem("workspaceUsers");
        data = JSON.parse(stringValue);
      }
    } catch (error) {
      console.error("loadUsersWorkspace error", error);
    }
    dispatch("SET_WORKSPACE_USERS", data);
  };
  const loadPlanWorkspace = async (activeWorkspaceID) => {
    let data;
    try {
      if (_isConnectedToCloud) {
        const result = await workspacesAPI.getPlanWorkspace(activeWorkspaceID);
        data = result?.data?.getPlanWorkspace;
      }
    } catch (error) {
      console.error("loadPlanWorkspace error", error);
    }
    dispatch("SET_WORKSPACE_PLAN", data);
  };
  const handleStateUpdateFromSubMessage = (msg, stateProperty, dispatchFn) => {
    const objectIndex = state[stateProperty]?.findIndex(
      (log) => log.id === msg?.element?.id
    );
    try {
      switch (msg?.opType) {
        case "UPDATE":
          if (objectIndex >= 0) {
            const updatedProperty = [...state[stateProperty]];
            updatedProperty[objectIndex] = msg?.element;
            dispatch(dispatchFn, updatedProperty);
          }
          break;
        case "INSERT":
          if (state[stateProperty]?.length > 0)
            dispatch(dispatchFn, [...state[stateProperty], msg?.element]);
          else dispatch(dispatchFn, [msg?.element]);
          break;
        case "DELETE":
          if (objectIndex >= 0) {
            state[stateProperty].splice(objectIndex, 1);
            dispatch(dispatchFn, [...state[stateProperty]]);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Notification could not be managed", error);
    }
  };

  useEffect(() => {
    let trainingLogSubscription;
    const openLogs = state?.trainingLogs
      ?.filter(
        (u) =>
          u.status === "dispatched" ||
          u.status === "inProgress" ||
          u.status === "cancellationRequested" ||
          u.status === "swapping" ||
          u.status === "swapModelRequested"
      )
      ?.map((u) => u?.id);
    trainingLogSubscription?.unsubscribe();
    if (state?.trainingLogs?.length > 0 && openLogs?.length > 0) {
      trainingLogSubscription = DataStore.observe(TrainingLog, (c) =>
        listPredicate(c, openLogs, "id").workspaceID(
          "eq",
          state?.activeWorkspaceID
        )
      ).subscribe((msg) =>
        handleStateUpdateFromSubMessage(
          msg,
          "trainingLogs",
          "SET_TRAINING_LOGS"
        )
      );
    }
    return () => {
      trainingLogSubscription?.unsubscribe();
    };
  }, [JSON.stringify(state?.trainingLogs)]);

  useEffect(() => {
    let entitiesSubscription;
    if (state?.aiEntitiesConfig?.length > 0) {
      entitiesSubscription = DataStore.observe(EntityAI, (c) =>
        c.workspaceID("eq", state?.activeWorkspaceID)
      ).subscribe((msg) => {
        handleStateUpdateFromSubMessage(
          msg,
          "aiEntitiesConfig",
          "SET_AI_ENTITIES_CONFIG"
        );
      });
    }
    return () => {
      entitiesSubscription?.unsubscribe();
    };
  }, [JSON.stringify(state?.aiEntitiesConfig)]);

  useEffect(() => {
    let chatsSubscription;
    if (state?.activeWorkspaceID) {
      chatsSubscription = DataStore.observe(Chat, (c) =>
        c.workspaceID("eq", state?.activeWorkspaceID)
      ).subscribe((msg) => {
        handleStateUpdateFromSubMessage(msg, "chats", "SET_CHATS");
      });
    }
    return () => {
      chatsSubscription?.unsubscribe();
    };
  }, [state?.activeWorkspaceID, JSON.stringify(state?.chats)]);

  useEffect(() => {
    let batchLogsSubscription;
    if (state?.activeWorkspaceID) {
      batchLogsSubscription = DataStore.observe(BatchJobLog, (c) =>
        c.workspaceID("eq", state?.activeWorkspaceID)
      ).subscribe((msg) => {
        handleStateUpdateFromSubMessage(msg, "batchLogs", "SET_BATCH_LOGS");
      });
    }
    return () => {
      batchLogsSubscription?.unsubscribe();
    };
  }, [state?.activeWorkspaceID, state?.batchLogs]);

  useEffect(() => {
    let dictionariesSubscription;
    if (state?.aiDictionariesConfig?.length > 0) {
      dictionariesSubscription = DataStore.observe(DictionaryItem, (c) =>
        c.workspaceID("eq", state?.activeWorkspaceID)
      ).subscribe((msg) => {
        handleStateUpdateFromSubMessage(
          msg,
          "aiDictionariesConfig",
          "SET_AI_DICTIONARIES_CONFIG"
        );
      });
    }
    return () => {
      dictionariesSubscription?.unsubscribe();
    };
  }, [JSON.stringify(state?.aiDictionariesConfig)]);

  useEffect(() => {
    let sitesSubscription;
    if (state?.sites?.length > 0) {
      sitesSubscription = DataStore.observe(Site, (c) =>
        c.workspaceID("eq", state?.activeWorkspaceID)
      ).subscribe((msg) => {
        handleStateUpdateFromSubMessage(msg, "sites", "SET_SITES");
      });
    }
    return () => {
      sitesSubscription?.unsubscribe();
    };
  }, [JSON.stringify(state?.sites)]);

  useEffect(() => {
    if (state?.activeWorkspaceID) {
      loadUsersWorkspace(state?.activeWorkspaceID);
      loadPlanWorkspace(state?.activeWorkspaceID);
    }
  }, [state?.activeWorkspaceID, _isConnectedToCloud, _lastMembersChange]);

  useEffect(() => {
    pinnedSideMenu(width > Constants.screenResponsiveWidth);
  }, [width]);

  useEffect(() => {
    if (!forcedClosed()) showSideMenu(width > Constants.screenResponsiveWidth);
  }, [width]);

  useEffect(() => {
    appDimensions({ width, height });
  }, [width, height]);

  useEffect(() => {
    setStyles(styles({ width, height, sideMenuOpen }));
  }, [sideMenuOpen, width, height]);

  const handleThemeSelect = (theme) => {
    setMode(theme);
  };

  const loadWorkspaces = async (loggedUser) => {
    const workSpaces = await DataStore.query(Workspace, (e) =>
      e.disabled("ne", true)
    );
    dispatch("SET_WORKSPACES", [...workSpaces]);
  };
  const loadBaseGenerationConfig = async () => {
    const baseGenerationConfig = await DataStore.query(GenerationConfig);
    dispatch("SET_GENERATION_CONFIG_DATA", baseGenerationConfig[0]);
  };
  const loadTrainingLogs = async (workspaceID) => {
    const resultLogs = await DataStore.query(
      TrainingLog,
      (e) => e.workspaceID("eq", workspaceID)
      // {
      //   page: state?.trainingLogPage,
      //   limit: 10,
      // }
    );
    dispatch("SET_TRAINING_LOGS", resultLogs);
  };
  const loadSites = async (workspaceID) => {
    const resultSites = await DataStore.query(
      Site,
      (e) => e.workspaceID("eq", workspaceID)
      // {
      //   page: state?.trainingLogPage,
      //   limit: 10,
      // }
    );
    dispatch("SET_SITES", resultSites);
  };
  const loadAgents = async (workspaceID) => {
    let resultAgents;
    if (isUserAdmin()) {
      resultAgents = await DataStore.query(Agent, (c) =>
        c.workspaceID("eq", workspaceID)
      );
    } else {
      resultAgents = await DataStore.query(Agent, (c) =>
        c.and((c) => [
          c.workspaceID("eq", workspaceID),
          c.or((c) => [
            c.and((c) => [
              c.restrictedAccess("eq", true),
              c.restrictedAccessUsers("contains", user.id),
            ]),
            c.restrictedAccess("ne", true),
          ]),
        ])
      );
    }

    dispatch(
      "SET_AGENTS",
      resultAgents?.sort((a, b) => (a?.label > b?.label ? 1 : -1))
    );
  };
  const loadCustomPrompts = async (workspaceID) => {
    const resultPrompts = await DataStore.query(
      CustomPrompt,
      (e) => e.workspaceID("eq", workspaceID)
      // {
      //   page: state?.trainingLogPage,
      //   limit: 10,
      // }
    );
    dispatch("SET_CUSTOM_PROMPTS", resultPrompts);
  };
  const loadBatchLogs = async (workspaceID) => {
    const resultLogs = await DataStore.query(
      BatchJobLog,
      (e) => e.workspaceID("eq", workspaceID)
      // {
      //   page: state?.trainingLogPage,
      //   limit: 10,
      // }
    );
    dispatch("SET_BATCH_LOGS", resultLogs);
  };
  const loadEntities = async (workspaceID) => {
    const aiEntities = await DataStore.query(EntityAI, (e) =>
      e.workspaceID("eq", workspaceID)
    );
    dispatch("SET_AI_ENTITIES_CONFIG", aiEntities);
  };

  const loadAppLogs = async (workspaceID) => {
    const appLogs = await DataStore.query(AppLog, (e) =>
      e.workspaceID("eq", workspaceID)
    );
    dispatch("SET_APP_LOGS", appLogs);
    dispatch("SET_APP_LOGS_READY", true);
  };
  const loadAccessCredentials = async () => {
    if (user.id === getActiveWorkspace()?.userID) {
      userType(UserType.ROOT);
    } else {
      const result = await API.graphql(
        graphqlOperation(userToWorkspaceMapping, {
          workspaceID: getActiveWorkspace()?.id,
          userID: {
            eq: user.id,
          },
        })
      );
      dispatch(
        "SET_ACCESS_CREDENTIALS",
        result?.data?.userToWorkspaceMapping?.items
      );
      const config = result?.data?.userToWorkspaceMapping?.items;
      if (config?.length > 0) {
        userType(config[0].userType);
      } else {
        userType(UserType.USER);
      }
    }
    console.log("user logged in of type", userType());
  };
  // There is a bug here on how the DataStore.query is invoked @milanmk
  const handleUserInitiation = useCallback(async () => {
    if (isLocalDBReady) {
      const loggedUser = await DataStore.query(User, (c) =>
        c.owner("eq", authUserSub)
      );

      if (loggedUser?.length > 0 && loggedUser[0]) {
        setIsIncognito(!loggedUser[0]?.isOnboarded);
        updateUser({
          ...user,
          ...loggedUser[0],
          cognitoUsername: user.username,
          stripeCustomerData,
        });
        handleThemeSelect(loggedUser[0].theme);
        i18n.changeLanguage(loggedUser[0].language);
        loadWorkspaces(loggedUser);
        setPreferencesLoaded(true);
      } else setIsIncognito(true);
    }
  }, [isLocalDBReady]);

  useEffect(() => {
    if (user?.user_email) {
      handleUserInitiation();
    }
  }, [isLocalDBReady, user?.user_email, handleUserInitiation]);

  useEffect(() => {
    if (currentView === "mainNav") {
      fetchUser();
    }
  }, [currentView]);

  const mainViewScreen = useMemo(() => {
    return (
      <MainViewScreen height={height} width={width} sideMenuOpen={sideMenuOpen}>
        {children}
      </MainViewScreen>
    );
  }, [width, height, sideMenuOpen, children]);

  return isIncognito && isLocalDBReady ? (
    <OnboardingScreen
      user={user}
      themeMode={mode}
      dispatch={dispatch}
      handleThemeSelect={handleThemeSelect}
      setIsIncognito={setIsIncognito}
      updateUser={updateUser}
    />
  ) : !isIncognito && isLocalDBReady && preferencesLoaded ? (
    <AppBaseScreen isScrollable={Platform.OS === "web" && false}>
      <View style={_styles.container}>
        <Router>
          {Platform.OS !== "web" && <DeepLinking />}
          {mainViewScreen}
        </Router>
      </View>
    </AppBaseScreen>
  ) : (
    !_isInitialisationInProgress &&
    !(!isIncognito && isLocalDBReady) && <ToothFairyLoadingPlaceholder />
  );
};

export default MainViewContainer;

const styles = ({ height, sideMenuOpen, width }) =>
  StyleSheet.create({
    container: {
      height: "100%",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflow: "hidden",
    },
    content: {
      height: height - 46,
      maxHeight: height - 46,
      width: "100%",
      maxWidth: width - (sideMenuOpen ? 150 : 0),
    },
  });
