import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import envConfig from "../../../envConfig";
import Common from "../Common";

const generateRequestPayload = ({
  passage,
  questions,
  token,
  topics,
  isTopicModeOn,
  workspaceid,
  includeReadComprehensionData,
  forcedLanguage,
  uniqueAnswer,
  answerByParagraph,
  stream,
  streamid,
  agentid,
}) => {
  let body = {
    passage,
    questions,
    topics,
    workspaceid,
    type: isTopicModeOn ? "topic" : "passage",
    includeReadComprehensionData,
    forcedLanguage,
    uniqueAnswer,
    answerByParagraph,
    stream,
    streamid,
    agentid,
  };

  return Common.objectCleaner(body);
};

const getAnswers = async ({
  passage,
  questions,
  token,
  topics,
  isTopicModeOn,
  workspaceid,
  includeReadComprehensionData,
  forcedLanguage,
  uniqueAnswer,
  answerByParagraph,
  stream,
  streamid,
  agentid,
}) => {
  if (!questions || !token) return { data: null };
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/answerer`;

  const body = generateRequestPayload({
    passage,
    questions,
    token,
    topics,
    isTopicModeOn,
    workspaceid,
    includeReadComprehensionData,
    forcedLanguage,
    uniqueAnswer,
    answerByParagraph,
    stream,
    streamid,
    agentid,
  });

  return secureRequest(client, "POST", url, null, body, false, headers);
};

export default {
  getAnswers,
  generateRequestPayload,
};
