import React from "react";
import { View, useWindowDimensions } from "react-native";
import AppExpander from "@toothfairy/shared-ui/AppExpander";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import ToothFairySlider from "../../../Components/ToothFairyComponents/ToothFairySlider";
import ToothFairySwitch from "../../../Components/ToothFairyComponents/ToothFairySwitch";
import Constants from "../../../Constants";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import useScreenSize from "../../../Hooks/useScreenSize";
import { isLargeScreen } from "../../MainViewContainer";

const TrainingSettings = ({ workspace, handleWorkspaceUpdate }) => {
  const { getActiveWorkspace, isPriorityTrainingEnabled, getPlanConfig } =
    useWorkspaces();
  const { width } = useWindowDimensions();
  const { marginLeftUnderAppExpander } = useScreenSize();
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View
      style={{
        zIndex: -1,
      }}
    >
      {(getPlanConfig()?.isTrainingNLPEnabled ||
        getPlanConfig()?.isTrainingGNEnabled ||
        getPlanConfig()?.isTrainingQAEnabled) && (
        <AppExpander
          style={{ minWidth: isLargeScreen() ? 530 : 250 }}
          isExpandedOnLoad={true}
          title="Common"
          icon={AppAssets.icons.settings}
        >
          <View
            style={{
              marginLeft: isLargeScreen() ? 42 : 0,
              maxWidth: 450,
              paddingLeft: 5,
            }}
          >
            <ToothFairySlider
              maximumValue={360}
              minimumValue={10}
              label="Max queue time (min.)"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  maxQueueWaitTime: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.maxQueueWaitTime ||
                Constants.maxQueueWaitTime
              }
            />
            <ToothFairySwitch
              wrapperStyle={{
                marginLeft: 0,
                marginTop: 20,
                maxWidth: !isLargeScreen() ? width - 60 : 385,
                backgroundColor: currentTheme?.theme?.inputBackgroundColor,
              }}
              labelColor="font_color"
              showTextInputLabel={true}
              disabled={!isPriorityTrainingEnabled()}
              label={"GPU scrap mode"}
              isTicked={
                !isPriorityTrainingEnabled()
                  ? true
                  : getActiveWorkspace()?.trainingConfig
                      ?.enableManagedSpotTraining
              }
              displayIsTicked={true}
              onChange={(v) => {
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  enableManagedSpotTraining: v,
                });
              }}
            />
          </View>
        </AppExpander>
      )}

      {getPlanConfig()?.isTrainingGNEnabled && (
        <AppExpander
          style={{ minWidth: isLargeScreen() ? 530 : 250 }}
          isExpandedOnLoad={false}
          title="Generative AI"
          icon={AppAssets.icons.writer}
        >
          <View
            style={{
              marginLeft: marginLeftUnderAppExpander,
              maxWidth: 450,
              paddingLeft: 5,
            }}
          >
            <ToothFairySlider
              step={1}
              maximumValue={100}
              minimumValue={1}
              label="Learning rate (%)"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  learningRatGN: v / 1000,
                })
              }
              initialValue={
                (workspace?.trainingConfig?.learningRatGN ||
                  Constants.learningRatGN) * 1000
              }
            />
            <ToothFairySlider
              step={1}
              maximumValue={5000}
              minimumValue={1}
              label="Min dataset for training test"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  datasetTestSizeThresholdGN: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.datasetTestSizeThresholdGN ||
                Constants.datasetTestSizeThresholdGN
              }
            />
            <ToothFairySlider
              step={1}
              maximumValue={35}
              minimumValue={1}
              label="Test dataset size (%)"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  testSizeGN: v / 100,
                })
              }
              initialValue={
                (workspace?.trainingConfig?.testSizeGN ||
                  Constants.testSizeGN) * 100
              }
            />
            <ToothFairySlider
              maximumValue={10}
              minimumValue={1}
              label="Max epochs"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  trainingEpochsGN: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.trainingEpochsGN ||
                Constants.baseTrainingGNEpochs
              }
            />
            <ToothFairySlider
              step={10}
              maximumValue={256}
              minimumValue={32}
              label="Batch size"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  batchSizeGN: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.batchSizeGN || Constants.batchSizeGN
              }
            />
          </View>
        </AppExpander>
      )}
      {getPlanConfig()?.isTrainingQAEnabled && (
        <AppExpander
          style={{ minWidth: isLargeScreen() ? 530 : 250 }}
          isExpandedOnLoad={false}
          title="Conversational AI"
          icon={AppAssets.icons.answerer}
        >
          <View
            style={{
              marginLeft: marginLeftUnderAppExpander,
              maxWidth: 450,
              paddingLeft: 0,
            }}
          >
            <ToothFairySlider
              step={1}
              maximumValue={100}
              minimumValue={1}
              label="Learning rate (%)"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  learningRateRC: v / 1000,
                })
              }
              initialValue={
                (workspace?.trainingConfig?.learningRateRC ||
                  Constants.learningRateRC) * 1000
              }
            />
            <ToothFairySlider
              step={1}
              maximumValue={5000}
              minimumValue={1}
              label="Min dataset for training test"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  datasetTestSizeThresholdRC: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.datasetTestSizeThresholdRC ||
                Constants.datasetTestSizeThresholdRC
              }
            />
            <ToothFairySlider
              step={1}
              maximumValue={35}
              minimumValue={1}
              label="Test dataset size (%)"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  testSizeRC: v / 100,
                })
              }
              initialValue={
                (workspace?.trainingConfig?.testSizeRC ||
                  Constants.testSizeRC) * 100
              }
            />
            <ToothFairySlider
              maximumValue={100}
              minimumValue={10}
              label="Max epochs"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  trainingEpochsRC: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.trainingEpochsRC ||
                Constants.baseTrainingRCEpochs
              }
            />
            <ToothFairySlider
              step={10}
              maximumValue={300}
              minimumValue={32}
              label="Batch size"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  batchSizeRC: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.batchSizeRC || Constants.batchSizeRC
              }
            />
            {/* <ToothFairySwitch
            wrapperStyle={{
              marginLeft: 0,
              marginTop: 20,
              backgroundColor: currentTheme?.theme?.inputBackgroundColor,
              maxWidth: !isLargeScreen() ? width - 60 : 385,
            }}
            labelColor="font_color"
            showTextInputLabel={true}
            label={"Use RC as source"}
            isTicked={
              getActiveWorkspace()?.trainingConfig
                ?.includeReadComprehensionDataInTraining
            }
            displayIsTicked={true}
            onChange={(v) => {
              handleWorkspaceUpdate("trainingConfig", {
                ...getActiveWorkspace().trainingConfig,
                includeReadComprehensionDataInTraining: v,
              });
            }}
          /> */}
          </View>
        </AppExpander>
      )}
      {getPlanConfig()?.isTrainingNLPEnabled && (
        <AppExpander
          style={{ minWidth: isLargeScreen() ? 530 : 250 }}
          isExpandedOnLoad={false}
          title="Sentiment reviewer"
          icon={AppAssets.icons.trainingLog}
        >
          <View
            style={{
              marginLeft: marginLeftUnderAppExpander,
              maxWidth: 450,
              paddingLeft: 5,
            }}
          >
            <ToothFairySlider
              step={1}
              maximumValue={100}
              minimumValue={1}
              label="Learning rate (%)"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  learningRateNLP: v / 1000,
                })
              }
              initialValue={
                (workspace?.trainingConfig?.learningRateNLP ||
                  Constants.learningRateNLP) * 1000
              }
            />
            <ToothFairySlider
              step={1}
              maximumValue={5000}
              minimumValue={1}
              label="Min dataset for training test"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  datasetTestSizeThresholdNLP: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.datasetTestSizeThresholdNLP ||
                Constants.datasetTestSizeThresholdNLP
              }
            />
            <ToothFairySlider
              step={1}
              maximumValue={35}
              minimumValue={1}
              label="Test dataset size (%)"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  testSizeNLP: v / 100,
                })
              }
              initialValue={
                (workspace?.trainingConfig?.testSizeNLP ||
                  Constants.testSizeNLP) * 100
              }
            />
            <ToothFairySlider
              minimumValue={100}
              maximumValue={2000}
              step={10}
              label="Max epochs"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  trainingEpochsNLP: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.trainingEpochsNLP ||
                Constants.baseTrainingNLPEpochs
              }
            />
            <ToothFairySlider
              step={10}
              maximumValue={300}
              minimumValue={32}
              label="Batch size"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  batchSizeNLP: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.batchSizeNLP ||
                Constants.batchSizeNLP
              }
            />
            <ToothFairySlider
              maximumValue={80000}
              minimumValue={20000}
              step={100}
              label="Max steps"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  maxStepsNLP: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.maxStepsNLP || Constants.maxStepsNLP
              }
            />
            <ToothFairySlider
              step={50}
              maximumValue={2400}
              minimumValue={800}
              label="Patience"
              onSubmit={(v) =>
                handleWorkspaceUpdate("trainingConfig", {
                  ...getActiveWorkspace().trainingConfig,
                  patienceNLP: v,
                })
              }
              initialValue={
                workspace?.trainingConfig?.patienceNLP || Constants.patienceNLP
              }
            />
          </View>
        </AppExpander>
      )}
      {!getPlanConfig()?.isTrainingNLPEnabled &&
        !getPlanConfig()?.isTrainingQAEnabled &&
        !getPlanConfig()?.isTrainingGNEnabled && (
          <AppPlaceholderView
            wrapperStyle={{
              width: "100%",
              height: "100%",
              marginVertical: 20,
              marginHorizontal: "auto",
              flex: 1,
            }}
            size={80}
            icon={AppAssets.icons.orbit}
            mainCaption="EmptyPlaceholder"
            subCaption="SettingsSubCaption"
          />
        )}
    </View>
  );
};

export default TrainingSettings;
