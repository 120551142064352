export default class Color {
  constructor(hex) {
    hex = hex.startsWith("#") ? hex.slice(1) : hex;
    this.r = parseInt(hex.slice(0, 2), 16);
    this.g = parseInt(hex.slice(2, 4), 16);
    this.b = parseInt(hex.slice(4, 6), 16);
  }

  alpha(a) {
    this.a = a;
    return this;
  }

  css() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }
}
