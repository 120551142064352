import React from "react";
import { View } from "react-native";
import ToothFairyTab from "../../../Components/ToothFairyComponents/ToothFairyTab";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import NLPView from "./NLPView";
import RCView from "./RCView";
import GNView from "./GNView";

const FilterBar = ({
  textString,
  documentType,
  handleDocumentTypeChange,
  handleChangeText,
  documentIdString,
  handleChangeDocumentIdString,
  items,
  onDeleteSelectedItem,
  onItemPress,
  selectedItems,
  handleSearch,
  handleTabChange,
  routes,
  initialTabFromRouting,
  handleValidationOnly,
  isValidationOnly,
}) => {
  const { getLastTabSelectedByScreen } = useWorkspaces();
  const tabIndexFromRouting = routes
    .map((u) => u.routeId)
    .indexOf(initialTabFromRouting);

  const _initialIndex =
    tabIndexFromRouting > -1
      ? tabIndexFromRouting
      : getLastTabSelectedByScreen("documents");
  const nlpView = () => (
    <NLPView
      textString={textString}
      handleChangeText={handleChangeText}
      items={items}
      onDeleteSelectedItem={onDeleteSelectedItem}
      onItemPress={onItemPress}
      selectedItems={selectedItems}
      documentIdString={documentIdString}
      handleChangeDocumentIdString={handleChangeDocumentIdString}
      handleSearch={handleSearch}
      handleValidationOnly={handleValidationOnly}
      isValidationOnly={isValidationOnly}
    />
  );

  const readingComprehensionView = () => (
    <RCView
      textString={textString}
      handleChangeText={handleChangeText}
      items={items}
      onDeleteSelectedItem={onDeleteSelectedItem}
      onItemPress={onItemPress}
      selectedItems={selectedItems}
      documentIdString={documentIdString}
      handleChangeDocumentIdString={handleChangeDocumentIdString}
      handleSearch={handleSearch}
      handleValidationOnly={handleValidationOnly}
      isValidationOnly={isValidationOnly}
    />
  );

  const contentGenerationView = () => (
    <GNView
      textString={textString}
      handleChangeText={handleChangeText}
      items={items}
      onDeleteSelectedItem={onDeleteSelectedItem}
      onItemPress={onItemPress}
      selectedItems={selectedItems}
      documentIdString={documentIdString}
      handleChangeDocumentIdString={handleChangeDocumentIdString}
      handleSearch={handleSearch}
      handleValidationOnly={handleValidationOnly}
      isValidationOnly={isValidationOnly}
    />
  );
  const conversationalView = () => (
    <GNView
      textString={textString}
      handleChangeText={handleChangeText}
      items={items}
      onDeleteSelectedItem={onDeleteSelectedItem}
      onItemPress={onItemPress}
      selectedItems={selectedItems}
      documentIdString={documentIdString}
      handleChangeDocumentIdString={handleChangeDocumentIdString}
      handleSearch={handleSearch}
      handleValidationOnly={handleValidationOnly}
      isValidationOnly={isValidationOnly}
      isChat={true}
    />
  );

  return (
    <View style={{ flexDirection: "column", justifyContent: "space-between" }}>
      {routes?.length > 0 && (
        <ToothFairyTab
          initialIndex={_initialIndex}
          isAnimated={false}
          onTabChange={handleTabChange}
          renderSceneObject={{
            nlpTraining: nlpView,
            readComprehensionTraining: readingComprehensionView,
            generativeTraining: contentGenerationView,
            conversationalTraining: conversationalView,
          }}
          routes={routes}
        />
      )}
    </View>
  );
};

export default FilterBar;
