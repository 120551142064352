import { DataStore } from "@aws-amplify/datastore";
import AppAuth from "@toothfairy/shared-ui/AppAuthScreen";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React from "react";
import AppUser from "@toothfairy/shared-ui/AppUser";
import { useTranslation } from "react-i18next";
import usersAPI from "../../../API/Users/usersAPI";
import usersHelper from "../../../API/Users/usersHelper";
import Constants from "../../../Constants";
import { User } from "../../../models";
import { useHistory } from "../../../Router";
import ProfileSettingsScreen from "./ProfileSettingsScreen";

const ProfileSettings = ({ handleUserUpdate, setCurrentView, clearCache }) => {
  const { state } = AppStateManager.useAppStateManager();
  const { t } = useTranslation();
  const history = useHistory();
  const AppToasty = AppToast.useToast();
  const { signOut } = AppAuth.useAuth();
  const getWorkspaceIndex = (id) => {
    return state?.workspaces?.map((element) => element.id).indexOf(id);
  };

  const { user, updateUser } = AppUser.useUser();
  const { onboardingData } = Constants;

  const formValidationSchema = AppForms.yup.object().shape({
    name: AppForms.yup.string().required().label("Your name"),
    timezone: AppForms.yup.string().required().label("Your timezone"),
    theme: AppForms.yup.string().required().label("Your theme"),
  });

  const handleFormSubmit = async (formValues) => {
    try {
      await usersHelper.updateUser(user.id, [
        { property: "theme", value: formValues.theme },
        { property: "timezone", value: formValues.timezone },
        { property: "username", value: formValues.name },
      ]);
      updateUser({
        ...user,
        theme: formValues.theme,
        timezone: formValues.timezone,
        username: formValues.name,
      });
      AppToasty.show(t("ProfileUpdateCaption"), { type: "success" });
    } catch (error) {
      AppToasty.show(t("ProfileUpdateFailedCaption"), { type: "danger" });
      console.error("Error while updating profile", error);
    }
  };

  const handleUserDeletion = async () => {
    try {
      const original = await DataStore.query(User, user.id);
      await usersAPI.permanentlyDeleteUser(user.id, original._version);
      await DataStore.clear();
      setTimeout(() => {
        signOut()
          .then(async () => {
            AppToasty.show(t("LogoutMessage"), {
              placement: "top",
              type: "success",
            });
            setCurrentView("auth");
            history.push("/");
            window.location.reload();
          })
          .catch((error) => {
            console.error("Auth - logout failed", error);
            AppToasty.show(t("LogoutFailure"), {
              placement: "top",
              type: "danger",
            });
          });
      }, 2000);
    } catch (error) {
      AppToasty.show(t("ProfileDeleteFailedCaption"), { type: "danger" });
      console.error("Error while deleting profile", error);
    }
  };

  return (
    <ProfileSettingsScreen
      handleUserDeletion={handleUserDeletion}
      handleFormSubmit={handleFormSubmit}
      handleUserUpdate={handleUserUpdate}
      formValidationSchema={formValidationSchema}
      onboardingData={onboardingData}
      user={user}
      workspace={
        state?.workspaces?.length > 0 &&
        state?.workspaces[getWorkspaceIndex(state.activeWorkspaceID)]
      }
    />
  );
};

export default ProfileSettings;
