import useAPI from "@toothfairy/shared-api/useApi";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppUtilities from "@toothfairy/shared-ui/AppUtilities";
import AppToast from "@toothfairy/shared-ui/AppToast";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HumanFeedback from "../../../API/HumanFeedback";
import HumanFeedbackView from "../Components/HumanFeedbackView";
import messagesHelper from "../../../API/Messages/messagesHelper";
import workspacesAPI from "../../../API/Workspaces/workspacesAPI";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import usePath from "../../../Hooks/usePath";
import streamHelper from "../../../API/Stream/streamHelper";

const routes = [
  {
    key: "conversationalFeedback",
    title: "Conversational AI",
    routeId: "cn",
    shortTitle: "CN",
  },
  {
    key: "generativeFeedback",
    title: "Generative AI",
    routeId: "gn",
    shortTitle: "GN",
  },
];

const HumanFeedbackContainer = () => {
  const { params } = usePath();
  const AppToasty = AppToast.useToast();
  const { state } = AppStateManager.useAppStateManager();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [mainFeedbackType, setMainFeedbackType] = useState(
    "conversationalFeedback"
  );
  const { getActiveWorkspace, handleTabSelection } = useWorkspaces();
  const fetchLimit = 1000;
  const pageSize = 100;
  const {
    data: getFeedbackData,
    error: getFeedbackError,
    loading: getFeedbackInProgress,
    apiRequest: fetchFeedback,
    lastUpdated: lastFeedbackFetch,
  } = useAPI(HumanFeedback.getHumanFeedback);
  const [isFeedbackUpdateInProgress, setIsFeedbackUpdateInProgress] =
    useState(false);
  const handleTabChange = (route) => {
    setMainFeedbackType(route?.key);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // change here
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [start, setStart] = useState(dayjs().startOf("month").toDate());
  const [end, setEnd] = useState(dayjs().endOf("month").toDate());
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [displayedFeedback, setDisplayedfeedback] = useState([]);
  const [rawFeedback, setRawFeedback] = useState([]);
  const [includeResolved, setIncludeResolved] = useState(false);
  const [lastForwardToken, setLastForwardToken] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [members, setMembers] = useState([]);
  useEffect(() => {
    if (getActiveWorkspace()?.id) loadMembers();
  }, [getActiveWorkspace()?.id]);
  const loadMembers = async () => {
    const result = await workspacesAPI.getWorkspaceUsers(
      getActiveWorkspace()?.id
    );
    const data = result?.data?.getUsersWorkspace;
    const sharedWorkspaceUsers =
      data?.map((item) => ({
        email: item.user_email,
        username: item.username,
        id: item.id,
      })) || [];
    setMembers(sharedWorkspaceUsers);
  };

  const fetchLogs = async () => {
    if (start !== null && end !== null) {
      const response = await fetchFeedback({
        workspaceid: state.activeWorkspaceID,
        nextToken: lastForwardToken,
        limit: fetchLimit,
        feedbackType:
          selectedFeedbackType == "[object Object]"
            ? undefined
            : selectedFeedbackType,
        includeResolved: includeResolved,
        mainFeedbackType: mainFeedbackType,
      });
      setRawFeedback(response?.items);
      setDisplayedfeedback(
        response?.items
          ?.sort((a, b) => b._lastChangedAt - a._lastChangedAt)
          ?.map((log) => {
            return {
              ID: log.id,
              type: log.humanFeedbackType || " ",
              message: log.humanFeedbackComment || " ",
              timestamp: AppUtilities.dateConverter
                .ConvertTimeStampToDateString(log._lastChangedAt, "en", t, true)
                .toString(),
              adminChecked: log.adminChecked?.toString() || "false",
              actions: " ",
            };
          })
        // Sort by timestamp in descending order
      );
      setLastForwardToken(response?.nextToken);
    }
  };
  const handleFeedbackUpdate = async (v, closeModal = true) => {
    try {
      setIsFeedbackUpdateInProgress(true);
      if (mainFeedbackType == "conversationalFeedback")
        await messagesHelper.updateChatMessage(v?.id, [
          {
            property: "adminResolution",
            value: v?.adminResolution,
          },
          {
            property: "adminChecked",
            value: v?.adminChecked,
          },
          {
            property: "autoTune",
            value: v?.autoTune,
          },
        ]);
      else {
        await streamHelper.updateOutputStream(v?.id, [
          {
            property: "adminResolution",
            value: v?.adminResolution,
          },
          {
            property: "adminChecked",
            value: v?.adminChecked,
          },
          {
            property: "autoTune",
            value: v?.autoTune,
          },
        ]);
      }
      setDisplayedfeedback((prev) => {
        const index = prev.findIndex((item) => item.ID === v?.id);
        prev[index].adminChecked = v.adminChecked?.toString() || "false";
        return [...prev];
      });
      setRawFeedback((prev) => {
        const index = prev.findIndex((item) => item.id === v?.id);
        prev[index].adminResolution = v?.adminResolution;
        prev[index].adminChecked = v?.adminChecked;
        return [...prev];
      });
      AppToasty.show("Feedback updated successfully", {
        type: "success",
      });
    } catch (error) {
      console.log("error", error);
      AppToasty.show("Feedback could not be updated", {
        type: "danger",
      });
    } finally {
      setIsFeedbackUpdateInProgress(false);
      setShowFeedbackModal(!closeModal);
    }
  };
  const handleUpdateStartDate = (v) => {
    setStart(v);
  };
  const handleUpdateEndDate = (v) => {
    setEnd(v);
  };

  useEffect(() => {
    if (state?.activeWorkspaceID) {
      fetchLogs();
    }
  }, [state.activeWorkspaceID, lastUpdate, mainFeedbackType]);
  useEffect(() => {
    setLastForwardToken(null);
  }, [includeResolved]);
  return (
    <HumanFeedbackView
      getFeedbackInProgress={getFeedbackInProgress}
      start={start}
      end={end}
      handleUpdateStartDate={handleUpdateStartDate}
      handleUpdateEndDate={handleUpdateEndDate}
      displayedFeedback={displayedFeedback}
      fetchLogs={fetchLogs}
      selectedFeedbackType={selectedFeedbackType}
      setSelectedFeedbackType={setSelectedFeedbackType}
      setDisplayedfeedback={setDisplayedfeedback}
      setLastForwardToken={setLastForwardToken}
      setShowFeedbackModal={setShowFeedbackModal}
      showFeedbackModal={showFeedbackModal}
      setSelectedIndex={setSelectedIndex}
      selectedIndex={selectedIndex}
      rawFeedback={rawFeedback}
      handleFeedbackUpdate={handleFeedbackUpdate}
      isFeedbackUpdateInProgress={isFeedbackUpdateInProgress}
      setIsFeedbackUpdateInProgress={setIsFeedbackUpdateInProgress}
      setIncludeResolved={setIncludeResolved}
      includeResolved={includeResolved}
      members={members}
      handlePageChange={handlePageChange}
      currentPage={currentPage}
      pageSize={pageSize}
      routes={routes}
      initialTabFromRouting={params.sectionId}
      handleTabChange={handleTabChange}
      mainFeedbackType={mainFeedbackType}
    />
  );
};

export default HumanFeedbackContainer;
