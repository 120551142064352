import React from "react";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { AppText } from "@toothfairy/shared-ui";
import { View } from "react-native";
import ToothFairySlider from "../../../Components/ToothFairyComponents/ToothFairySlider";
import Constants from "../../../Constants";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";

const FineTuningConfigSelection = ({
  trainingConfig,
  setTrainingConfig,
  trainingLogId,
  handleFineTuningStart = () => {},
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View
      style={{
        marginLeft: 20,
        maxWidth: 450,
        paddingLeft: 5,
      }}
    >
      <ToothFairySlider
        step={0.0001}
        maximumValue={0.01}
        minimumValue={0.0001}
        label="Learning rate (%)"
        onSubmit={(v) =>
          setTrainingConfig({
            ...trainingConfig,
            learningRateGN: v,
          })
        }
        initialValue={trainingConfig?.learningRateGN || Constants.learningRatGN}
      />
      <ToothFairySlider
        step={1}
        maximumValue={5000}
        minimumValue={1}
        label="Min dataset for training test"
        onSubmit={(v) =>
          setTrainingConfig({
            ...trainingConfig,
            datasetTestSizeThresholdGN: v,
          })
        }
        initialValue={
          trainingConfig?.datasetTestSizeThresholdGN ||
          Constants.datasetTestSizeThresholdGN
        }
      />
      <ToothFairySlider
        step={1}
        maximumValue={35}
        minimumValue={1}
        label="Test dataset size (%)"
        onSubmit={(v) =>
          setTrainingConfig({
            ...trainingConfig,
            testSizeGN: v,
          })
        }
        initialValue={trainingConfig?.testSizeGN || Constants.testSizeGN * 100}
      />
      <ToothFairySlider
        maximumValue={20}
        minimumValue={1}
        label="Max epochs"
        onSubmit={(v) =>
          setTrainingConfig({
            ...trainingConfig,
            trainingEpochsGN: v,
          })
        }
        initialValue={
          trainingConfig?.trainingEpochsGN || Constants.baseTrainingGNEpochs
        }
      />
      <ToothFairySlider
        step={1}
        maximumValue={64}
        minimumValue={32}
        label="Batch size"
        onSubmit={(v) =>
          setTrainingConfig({
            ...trainingConfig,
            batchSizeGN: v,
          })
        }
        initialValue={trainingConfig?.batchSizeGN || Constants.batchSizeGN}
      />

      <ToothFairyButton
        wrapperStyle={{
          marginTop: 10,
          alignSelf: "center",
        }}
        title="Start"
        onPress={() => handleFineTuningStart(trainingLogId, trainingConfig)}
      />
    </View>
  );
};

export default FineTuningConfigSelection;
