import AppFadeView from "@toothfairy/shared-ui/AppFadeView";
import AppForms from "@toothfairy/shared-ui/AppForms";
import AppMenu from "@toothfairy/shared-ui/AppMenu";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import connectionsHelper from "../../../../API/Connections/connectionsHelper";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import ConnectionSettingsAIView from "../SubComponents/ConnectionSettingsAIView";
import secretGenerator from "../../../../API/SecretGenerator";

const ConnectionsSettingAI = forwardRef(
  (
    {
      sectionTitle,
      initialMapValues,
      settingType,
      isEdit,
      opt,
      setDisplayedView,
      backTo,
      onDbConnectionCreated,
      selectedId,
    },
    ref
  ) => {
    const AppToasty = AppToast.useToast();
    const { t } = useTranslation();

    const { state, dispatch } = AppStateManager.useAppStateManager();
    const { getActiveWorkspace } = useWorkspaces();

    const validationSchema = AppForms.yup.object().shape({
      // allow for no more than 4 words in sourceLanguage and targetLanguage
      name: AppForms.yup
        .string()
        .required()
        .label("DB Name")
        .max(255, "Too long, maximum of 255 characters are allowed."),
      description: AppForms.yup.string().label("Description").max(255),
      type: AppForms.yup.string().required().label("DB Type").max(10),
      host: AppForms.yup.string().required().label("Host").max(255).nullable(),
      port: AppForms.yup
        .number()
        .required()
        .label("Port")
        .max(65535)
        .nullable(),
      username: AppForms.yup
        .string()
        .required()
        .label("Username")
        .max(255)
        .nullable(),
      password: AppForms.yup
        .string()
        .required()
        .label("Password")
        .max(255)
        .nullable(),
    });

    const handleDeletion = async () => {
      try {
        const result = await connectionsHelper.deleteDatabaseConnection(opt.id);
        dispatch("SET_DB_CONNECTIONS", [
          ...state.dbConnections?.filter((u) => u?.id !== opt.id),
        ]);
        navigateBack();
        AppToasty.show(`DB connection deleted`, {
          type: "success",
        });
      } catch (error) {
        console.error("dictionaryDeleteErrorCaption", error);
        AppToasty.show(`DB connection could not be deleted`, {
          type: "danger",
        });
      }
    };
    const navigateBack = () => {
      setDisplayedView(backTo);
    };
    const identifyOptByID = (id) => {
      return state.agentsFunctions.find((u) => u?.id === id);
    };
    const handleFunctionItemSave = async (v) => {
      v.workspaceID = getActiveWorkspace()?.id;
      v.type = v.type.toLowerCase();
      delete v?._lastChangedAt;
      delete v?.createdAt;
      delete v?.updatedAt;
      delete v?._deleted;
      delete v?.__typename;
      const psw = v?.password;
      delete v?.password;
      let newConnections = [...state.dbConnections];
      try {
        let result;
        if (isEdit) {
          result = await connectionsHelper.updateDatabaseConnection(opt.id, v);
          const _updatedObject = identifyOptByID(
            result.data?.updateDatabaseConnection?.id
          );
          const updateIndex = newConnections.findIndex(
            (u) => u?.id === _updatedObject?.id
          );
          newConnections[updateIndex] = result?.data?.updateDatabaseConnection;
        } else {
          result = await connectionsHelper.createDatabaseConnection({
            ...v,
          });
          const _id = result?.data?.createDatabaseConnection?.id;
          const secretResult = await secretGenerator.storeSecret({
            workspaceid: v.workspaceID,
            id: _id,
            passwordSecretValue: psw,
          });
          if (secretResult?.data?.status === "Success") {
            const _updatedObject =
              await connectionsHelper.updateDatabaseConnection(_id, {
                passwordSecret: secretResult?.data?.name,
              });
          } else {
            throw new Error("Secret could not be created");
          }
          newConnections.push(result?.data?.createDatabaseConnection);
        }

        dispatch("SET_DB_CONNECTIONS", [...newConnections]);
        backTo && navigateBack();
        onDbConnectionCreated && onDbConnectionCreated("success");
      } catch (error) {
        console.error(error);
        onDbConnectionCreated && onDbConnectionCreated("error");
      } finally {
      }
    };

    const _view = useMemo(
      () => (
        <AppFadeView
          style={{
            horizontalMargin: 10,
            paddingHorizontal: 20,
            minWidth: isLargeScreen() ? "100%" : 300,
            paddingBottom: 10,
            height: "auto",
          }}
        >
          {backTo && (
            <AppMenu.DefaultBackOptionComponent
              navigateBack={navigateBack}
              sectionTitle={"Connection : " + sectionTitle}
              isClickable
              isBackIconDisplayed
            />
          )}

          <AppForms.AppForm
            ref={ref}
            initialValues={initialMapValues}
            onSubmit={handleFunctionItemSave}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <ConnectionSettingsAIView
              handleDeletion={handleDeletion}
              isEdit={isEdit}
              settingType={settingType}
            />
          </AppForms.AppForm>
        </AppFadeView>
      ),
      [
        state.activeWorkspaceID,
        initialMapValues,
        state.dbConnections,
        isEdit,
        AppToasty,
      ]
    );
    return _view;
  }
);

export default ConnectionsSettingAI;
