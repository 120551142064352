import React from "react";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import LoadingPlaceholder from "../LoadingPlaceholder";

const ToothFairyLoadingPlaceholder = ({
  caption = "loadingAppCaption",
  style,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <LoadingPlaceholder
      caption={caption}
      visible={true}
      style={[
        {
          position: "absolute",
          zIndex: 10,
          height: "100%",
          width: "100%",
          backgroundColor: currentTheme?.theme?.backgroundColor,
        },
        style,
      ]}
    />
  );
};

export default ToothFairyLoadingPlaceholder;
