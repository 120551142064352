import Clipboard from "@react-native-clipboard/clipboard";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import useWorkspace from "../../../Hooks/useWorkspaces";
import AdminInfoLeaf from "./SubComponents/AdminInfoLeaf";

const ApiIntegrationView = () => {
  const { getActiveWorkspace, isTF_API_AccessEnabled } = useWorkspace();
  const { currentTheme } = AppTheme.useTheme();
  const AppToasty = AppToast.useToast();
  const { t } = useTranslation();
  const handleTokenClick = () => {
    Clipboard.setString(getActiveWorkspace().workspaceToken);
    AppToasty.show(t("WorkspaceTokenCopiedCaption"), {
      type: "success",
    });
  };
  const handleWorkspaceIdClick = () => {
    Clipboard.setString(getActiveWorkspace().id);
    AppToasty.show(t("WorkspaceIdCopiedCaption"), {
      type: "success",
    });
  };

  return (
    <View
      style={{
        marginHorizontal: "auto",
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 340,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <AdminInfoLeaf
        wrapperStyle={{
          alignSelf: "flex-start",
          width: "100%",
        }}
        caption={"WorkspaceIDKeySectionCaption"}
        subcaption={
          isTF_API_AccessEnabled()
            ? "WorkspaceIDKeySectionSubCaption"
            : "WorkspaceIdNotEnabledCaption"
        }
        subCaptionColor={
          !isTF_API_AccessEnabled() && currentTheme?.theme?.primary
        }
        title={"WorkspaceIdTitle"}
        accessibilityLabel={"WorkspaceId"}
        value={getActiveWorkspace()?.id}
        handleClick={handleWorkspaceIdClick}
      />
      <AdminInfoLeaf
        wrapperStyle={{
          alignSelf: "flex-start",
          width: "100%",
        }}
        value={getActiveWorkspace()?.workspaceToken}
        handleClick={handleTokenClick}
        caption={"ApiKeySectionCaption"}
        subcaption={"ApiKeySectionSubCaption"}
        title={"WorkspaceTokenTitle"}
        accessibilityLabel={"WorkspaceToken"}
        hideShowIcon={true}
      />
    </View>
  );
};

export default ApiIntegrationView;
