import AppForms from "@toothfairy/shared-ui/AppForms";
import Clipboard from "@react-native-clipboard/clipboard";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";
import Constants from "../../../../Constants";
import useWorkspaces from "../../../../Hooks/useWorkspaces";
import { isLargeScreen } from "../../../MainViewContainer";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AdminInfoLeaf from "../../../Admin/Components/SubComponents/AdminInfoLeaf";

const provider_types = [
  {
    id: "twilio",
    name: "Twilio",
    type: "sms",
    is_enterprise: false,
  },
  {
    id: "sms_magic",
    name: "SMS Magic",
    type: "sms",
    is_enterprise: true,
  },
  {
    id: "sms_magic_sf",
    name: "SMS Magic via Salesforce",
    type: "sms",
    is_enterprise: true,
  },
  {
    id: "whatsapp",
    name: "Whatsapp",
    type: "whatsapp",
    is_enterprise: false,
  },
  {
    id: "twilio",
    name: "Twilio for Whatsapp",
    type: "whatsapp",
    is_enterprise: false,
  },
  {
    id: "sendgrid",
    name: "Sendgrid",
    type: "email",
    is_enterprise: false,
  },
  {
    id: "ses",
    name: "Amazon SES",
    type: "email",
    is_enterprise: true,
  },
];

const channel_types = [
  {
    id: "sms",
    name: "SMS",
  },
  {
    id: "whatsapp",
    name: "Whatsapp",
  },
  {
    id: "email",
    name: "Email",
  },
];

const ChannelSettingsAIView = ({
  isEdit,
  settingType,
  handleDeletion,
  isSaveInProgress,
}) => {
  const AppToasty = AppToast.useToast();
  const { currentTheme, mode } = AppTheme.useTheme();
  const { getPlanConfig, getActiveWorkspace } = useWorkspaces();
  const entityName = "Channel mapping";
  const { setFieldValue, values, validationSchema } =
    AppForms.useFormikContext();
  const [initialLoadCompleted, setInitialLoadCompleted] = React.useState(false);
  const _title = useCallback(() => {
    return isEdit ? `Save` : `Create`;
  }, [entityName, isEdit]);
  // const { setFieldValue, values } = AppForms.useFormikContext();
  // useEffect(() => {
  //   if (values?.sourceLanguage !== "en") setFieldValue("targetLanguage", "en");
  //   if (values?.targetLanguage !== "en") setFieldValue("sourceLanguage", "en");
  // }, [values]);
  useEffect(() => {
    setTimeout(() => {
      setInitialLoadCompleted(true);
    }, [2000]);
  }, []);

  useEffect(() => {
    if (
      values?.channel &&
      (!values?.provider ||
        !provider_types
          ?.filter((u) => u?.type == values?.channel)
          ?.includes(values?.provider)) &&
      values?.id == null
    ) {
      if (values?.channel == "sms") setFieldValue("provider", "twilio");
      else if (values?.channel == "whatsapp")
        setFieldValue("provider", "whatsapp");
      else if (values?.channel == "email")
        setFieldValue("provider", "sendgrid");
    } else if (!values?.channel && !values?.provider) {
      setFieldValue("channel", "sms");
      setFieldValue("provider", "twilio");
    }
  }, [values?.channel]);
  return (
    <>
      <>
        <View>
          {isEdit && (
            <AdminInfoLeaf
              wrapperStyle={{
                alignSelf: "flex-start",
                marginBottom: 10,
              }}
              textContainer={{
                marginLeft: 5,
                marginTop: 10,
                marginBottom: 0,
              }}
              titleStyle={{
                marginTop: 0,
                marginLeft: 20,
              }}
              textStyle={{
                marginLeft: 0,
              }}
              title={"ID"}
              value={values?.id}
              width={420}
            />
          )}
          <AppForms.AppFormField
            showTextInputLabel
            isMandatory
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            label={`Provider name`}
            placeholder={`Provider name`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="name"
          />
          <AppForms.AppFormField
            showTextInputLabel
            wrapperInputStyle={{
              paddingTop: 10,
            }}
            multiline
            textContainer={{
              minHeight: 120,
              paddingVertical: 10,
            }}
            label={`Description`}
            placeholder={`Description of what the provider is used for`}
            keyboardtype="default"
            textContextType="label"
            autoCorrect={false}
            name="description"
          />
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Channel type"
            isMandatory
            name="channel"
            items={channel_types}
            propertyLabel="name"
            propertyValue="id"
          />
          <AppForms.AppFormDropDown
            isElevated={false}
            dropDownStyle={{
              borderColor: currentTheme?.theme?.border_color,
              maxWidth: !isLargeScreen() && 250,
            }}
            showTextInputLabel
            label="Provider"
            isMandatory
            name="provider"
            items={provider_types?.filter(
              (u) =>
                u?.type === values?.channel &&
                ((u?.is_enterprise &&
                  getActiveWorkspace()?.subscriptionType == "enterprise") ||
                  u?.is_enterprise == false)
            )}
            propertyLabel="name"
            propertyValue="id"
          />
          {!["ses"].includes(values?.provider) && (
            <AppForms.AppFormField
              showTextInputLabel
              isMandatory
              wrapperInputStyle={{
                paddingTop: 10,
              }}
              disabled={isEdit}
              label={`Token`}
              placeholder={
                isEdit
                  ? "API keys cannot be changed after creation"
                  : `The API key to use to authorise the request`
              }
              keyboardtype="default"
              textContextType="label"
              autoCorrect={false}
              name={isEdit ? "$xc" : "tokenSecret"}
            />
          )}
          {/* change here */}
          {(values?.channel == "sms" || values?.channel == "whatsapp") && (
            <View
              style={{
                zIndex: -1,
              }}
            >
              <AppForms.AppFormField
                showTextInputLabel
                wrapperInputStyle={{
                  paddingTop: 10,
                }}
                isMandatory
                label={`Account ID`}
                placeholder={`The account/sender id required by the provider`}
                keyboardtype="default"
                textContextType="label"
                autoCorrect={false}
                name="senderid"
              />
            </View>
          )}
          {values?.id && values?.provider && (
            <AdminInfoLeaf
              wrapperStyle={{
                alignSelf: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                paddingLeft: 20,
                paddingRight: 30,
              }}
              marginLeft={0}
              value={`https://api.toothfairyai.com/webhooks/${
                values?.provider
              }/${getActiveWorkspace()?.id}`}
              showSimpleText
              simpleTextWrapperStyle={{
                paddingVertical: 10,
              }}
              handleClick={() => {
                // copy to clipbopard
                try {
                  Clipboard.setString(
                    `https://api.toothfairyai.com/webhooks/${
                      values?.provider
                    }/${getActiveWorkspace()?.id}`
                  );
                  AppToasty.show("Webhook url copied", {
                    type: "success",
                  });
                } catch (error) {
                  console.log(error);
                  AppToasty.show("Could not copy value"),
                    {
                      type: "danger",
                    };
                }
              }}
              caption={
                "Use this webhook on the channel provider admin panel to allow agents to react to incoming messages"
              }
              subcaption={
                "Be aware the webhook url is shared among the agents using the same channel provider"
              }
              title={"Channel webhook"}
              accessibilityLabel={"Channel webhook"}
              hideShowIcon={true}
            />
          )}
        </View>
      </>

      <View style={{ alignItems: "center" }}>
        <AppForms.AppSubmitButton
          title={_title()}
          awesomeIconColor={currentTheme?.theme?.white}
          backgroundColor={currentTheme?.theme?.primary}
          isDisabled={isSaveInProgress}
          btnContentStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          style={[
            {
              height: Constants.appButtonHeight,
              width: "auto",
              shadowColor: currentTheme?.theme?.transparent,
              marginHorizontal: 10,
            },
          ]}
          btnLabelStyle={{ fontSize: 16 }}
          wrapperStyle={[
            {
              width: "fit-content",
              minWidth: 150,
              marginTop: 20,
              height: Constants.appButtonHeight,
            },
          ]}
        />
        {isEdit && (
          <ToothFairyButton
            title={"Delete"}
            onPress={handleDeletion}
            isDeletion
            wrapperStyle={{
              marginTop: 30,
              minWidth: 150,
              height: Constants.appButtonHeight,
            }}
          />
        )}
      </View>
    </>
  );
};

export default ChannelSettingsAIView;
