import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";
import ToothFairySwitch from "../../../Components/ToothFairyComponents/ToothFairySwitch";
import { useWindowDimensions } from "react-native";

const CommonSearchTextAndId = ({
  documentIdString,
  handleChangeDocumentIdString,
  textString,
  handleChangeText,
  handleValidationOnly,
  isValidationOnly,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const { width } = useWindowDimensions();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        maxWidth: width - 80,
        paddingBottom: 5,
        flexWrap: "wrap",
      }}
    >
      <AppTextInput
        value={documentIdString}
        onChangeText={handleChangeDocumentIdString}
        style={{
          fontSize: 16,
          marginLeft: 0,
          flex: 0.5,
          minWidth: 200,
        }}
        placeholder="Search by id"
      />
      <AppTextInput
        wrapperInputStyle={{
          flex: 1,
          minWidth: 200,
        }}
        value={textString}
        onChangeText={handleChangeText}
        style={{
          fontSize: 16,
          marginLeft: 0,
          flex: 0.5,
        }}
        placeholder="Search by text"
      />
      <ToothFairySwitch
        wrapperStyle={{
          flex: 1,
          marginRight: 20,
          backgroundColor: currentTheme?.theme?.inputBackgroundColor,
          marginLeft: 5,
          minWidth: 200,
        }}
        label={"Show validation"}
        onChange={(v) => {
          handleValidationOnly(v);
        }}
        isTicked={isValidationOnly}
      />
    </View>
  );
};

export default CommonSearchTextAndId;
