import { AppText } from "@toothfairy/shared-ui";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";

const OperationsFilter = ({
  items,
  selectedServiceType,
  setSelectedServiceType,
  selectedFilterLimit,
  setSelectedFilterLimit,
  label = "Service",
  propertyLabel = "service",
  propertyValue = "service",
  minWidth = 250,
  textAlign = "center",
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        zIndex: -1,
        marginLeft: 5,
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center", zIndex: -1 }}>
        <AppText wrapperStyle={{ marginRight: 10 }}>{label}</AppText>
        <AppDropDown
          dropDownStyle={{
            minWidth: minWidth,
            textAlign: textAlign,
            marginLeft: 20,
            borderColor: currentTheme?.theme?.border_color,
            zIndex: -1,
          }}
          isElevated={false}
          dropdownIconColor="transparent"
          items={items}
          propertyLabel={propertyLabel}
          propertyValue={propertyValue}
          selectedValue={selectedServiceType}
          onValueChange={(itemValue, itemIndex) => {
            setSelectedServiceType(itemValue);
            // dispatch("SET_ACTIVE_WORKSPACE_ID", itemValue);
            // updatePath({ workspaceId: itemValue });
          }}
        />
      </View>
      {/* <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 20,
          zIndex: -1,
        }}
      >
        <AppText wrapperStyle={{ marginRight: 10 }}>Records to fetch</AppText>
        <AppDropDown
          dropDownStyle={{
            minWidth: 90,

            textAlign: "center",
            borderColor: currentTheme?.theme?.border_color,
            zIndex: -1,
          }}
          isElevated={false}
          dropdownIconColor="transparent"
          items={[50, 100, 150, 200]}
          selectedValue={selectedFilterLimit}
          onValueChange={(itemValue, itemIndex) => {
            setSelectedFilterLimit(itemValue);
            // dispatch("SET_ACTIVE_WORKSPACE_ID", itemValue);
            // updatePath({ workspaceId: itemValue });
          }}
        />
      </View> */}
    </View>
  );
};

export default OperationsFilter;
