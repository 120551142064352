import { Platform } from "react-native";
import Constants from "../../Constants";

const minifiedButtonStyle = {
  btnContentStyle: {
    paddingHorizontal: 0,
  },
  //   #106 horrible workaround
  style: {
    height: Constants.appButtonHeight,
    width: 150,
  },
  btnLabelStyle: {
    fontSize: 16,
  },
};

export default { minifiedButtonStyle };
