import { DataStore } from "@aws-amplify/datastore";
import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { AppText } from "@toothfairy/shared-ui";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import dictionariesHelper from "../../../../API/Dictionaries/dictionariesHelper";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import { DictionaryItem } from "../../../../models";
import { isLargeScreen } from "../../../MainViewContainer";
import SettingsToolbar from "../SubComponents/SettingsToolbar";
import DictionarySettingsAI from "./DictionarySettingsAI";

const DictionariesList = ({ isDictionariesLoading }) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [showDictionaryModal, setShowDictonaryModal] = React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedDictionaries, setDisplayedDictionaries] = React.useState([]);
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { height } = useWindowDimensions();
  const [initialMapValues, setInitialMapValues] = useState({
    sourceLanguage: "en",
    targetLanguage: "en",
    sourceText: "",
    targetText: "",
    description: "",
    pos: "",
  });
  const columns = [
    {
      title: "Source Language",
    },
    {
      title: "Source Text",
    },
    {
      title: "Target Language",
    },
    {
      title: "Target Text",
    },
    {
      title: "Description",
    },
    {
      title: "Actions",
      isAction: true,
    },
  ];
  const onMappingCreated = useCallback(
    (event) => {
      if (event === "success") {
        AppToasty.show(t("mappingCreationSuccessCaption"), {
          type: "success",
        });
        setShowDictonaryModal(false);
      } else if (event === "inconsistent_language_mapping")
        AppToasty.show(t("inconsistentLanguageMappingCaption"), {
          type: "danger",
        });
      else if (event === "en_language_not_found_mapping")
        AppToasty.show(t("enLanguageNotFoundMappingCaption"), {
          type: "danger",
        });
      else if (event === "en_language_mapping")
        AppToasty.show(t("enLanguageMappingCaption"), {
          type: "danger",
        });
      else
        AppToasty.show(t("mappingCreationFailedCaption"), {
          type: "danger",
        });
    },
    [AppToasty, t, setShowDictonaryModal]
  );
  const loadDictionaries = async (workspaceID) => {
    const dictionaries = await DataStore.query(DictionaryItem, (e) =>
      e.workspaceID("eq", workspaceID)
    );
    dispatch("SET_AI_DICTIONARIES_CONFIG", dictionaries);
    setDisplayedDictionaries(dictionaries);
  };
  useEffect(() => {
    loadDictionaries(state?.activeWorkspaceID);
  }, [state?.activeWorkspaceID]);
  useEffect(() => {
    setDisplayedDictionaries(state?.aiDictionariesConfig);
  }, [state?.aiDictionariesConfig]);

  const dictonaryItemCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        modalStyle={{
          maxHeight: isLargeScreen() ? 585 : 500,
          height: "95%",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showDictionaryModal}
        setIsModalOpen={setShowDictonaryModal}
        modalTitle={
          isEditMode ? "dictorionaryEditTitle" : "dictorionaryCreationTitle"
        }
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowDictonaryModal(false);
          setSelectedIndex(null);
        }}
      >
        <DictionarySettingsAI
          ref={formRef}
          initialMapValues={initialMapValues}
          onMappingCreated={onMappingCreated}
          isEdit={isEditMode}
          selectedId={displayedDictionaries[selectedIndex]?.id}
        />
      </ToothFairyModal>
    );
  }, [
    showDictionaryModal,
    setShowDictonaryModal,
    initialMapValues,
    isEditMode,
    selectedIndex,
    displayedDictionaries,
  ]);

  const handleDictionaryItemSelection = ({ item, index }) => {
    setSelectedIndex(index);
    setIsEditMode(true);
    setInitialMapValues(item);
    setShowDictonaryModal(true);
  };
  const handleDictionaryItemDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        const _id = displayedDictionaries[selectedIndex]?.id;
        const result = await dictionariesHelper.deleteDictionaryItem(_id);
        dispatch("SET_AI_DICTIONARIES_CONFIG", [
          ...state?.aiDictionariesConfig.filter((u) => u.id !== _id),
        ]);
        AppToasty.show(t("mappingDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      AppToasty.show(t("mappingDeletionFailedCaption"), {
        type: "danger",
      });
    }
  };
  const handleSearch = async (
    sourceText,
    targetText,
    sourceLang,
    targetLang
  ) => {
    // find items in the list which cointain either the sourceText or targetText
    const filteredItems = state?.aiDictionariesConfig.filter((item) => {
      return (
        item.sourceText.toLowerCase().includes(sourceText.toLowerCase()) &&
        item.targetText.toLowerCase().includes(targetText.toLowerCase()) &&
        item.targetLanguage.toLowerCase().includes(targetLang.toLowerCase()) &&
        item.sourceLanguage.toLowerCase().includes(sourceLang.toLowerCase())
      );
    });
    setDisplayedDictionaries(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleCreation = () => {
    setInitialMapValues({
      sourceLanguage: "en",
      sourceText: "",
      targetLanguage: "en",
      targetText: "",
      description: "",
    });
    setIsEditMode(false);
    setShowDictonaryModal(true);
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"dictionaryItemCancelConfirmation"}
        caption="dictionaryItemCancelCaption"
        subCaption="dictionaryItemCancelSubCaption"
        confirmCaption={"dictionaryItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={() => handleDictionaryItemDeletion()}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [setShowChoice, showChoice, selectedItem, setSelectedItem]);

  return (
    <View style={{ minWidth: "100%", paddingLeft: 10, flex: 1 }}>
      <SettingsToolbar
        handleSearch={handleSearch}
        handleCreation={handleCreation}
      />
      {isDictionariesLoading ? (
        <AppActivityIndicator
          backgroundColor={currentTheme?.theme?.softBackgroundColor}
          visible={true}
          containerStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
            minHeight: 250,
          }}
        />
      ) : (
        <>
          {displayedDictionaries.length > 0 ? (
            <AppTable
              actionButtons
              handleDocumentSelection={handleDictionaryItemSelection}
              handleDocumentDeletion={(item, index) => {
                setSelectedItem(item);
                setSelectedIndex(index);
                setShowChoice(true);
              }}
              style={{
                zIndex: -1,
                maxHeight: height - 180,
                overflowY: "auto",
                overflowX: "hidden",
                flex: 1,
              }}
              headers={columns}
              items={displayedDictionaries
                ?.slice(
                  (currentPage - 1) * pageSize,
                  (currentPage - 1) * pageSize + pageSize
                )
                ?.map((u) => {
                  return {
                    sourceLanguage: u.sourceLanguage,
                    sourceText: u.sourceText,
                    targetLanguage: u.targetLanguage,
                    targetText: u.targetText,
                    description: u.description,
                    actions: " ",
                  };
                })}
            />
          ) : (
            <AppPlaceholderView
              wrapperStyle={{
                width: "100%",
                height: "100%",
                marginVertical: 20,
                marginHorizontal: "auto",
                flex: 1,
              }}
              size={80}
              icon={AppAssets.icons.orbit}
              mainCaption="EmptyPlaceholder"
              subCaption="DictonarySubCaption"
            />
          )}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: -10,
            }}
          >
            <AppText
              style={{
                textDecoration: "underline",
                marginLeft: 5,
              }}
              iconSize={16}
              color={currentTheme?.theme?.font_color}
              leftIcon={AppAssets?.icons?.info}
            >
              Dictionaries changes might take up to 10 minutes to take effect
            </AppText>

            {displayedDictionaries?.length > pageSize && (
              <AppPagination
                onPageChange={handlePageChange}
                totalCount={displayedDictionaries?.length}
                siblingCount={1}
                currentPage={currentPage}
                pageSize={pageSize}
                wrapperStyle={{
                  alignSelf: "flex-end",
                }}
              />
            )}
          </View>
        </>
      )}

      {dictonaryItemCreationComponent}
      {_coreChoice}
    </View>
  );
};

export default DictionariesList;
