import React from "react";
import { View } from "react-native";
import { AppText } from "@toothfairy/shared-ui";
const DataLeaf = ({ label, data }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        padding: 10,
        justifyContent: "flex-start",
      }}
    >
      <AppText
        style={{
          fontFamily: "boldFont",
          marginRight: 5,
        }}
      >
        {label}
      </AppText>
      <AppText>{data}</AppText>
    </View>
  );
};

export default DataLeaf;
