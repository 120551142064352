import React, { useRef, useEffect, useState } from "react";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { View } from "react-native";
import { AppIcon, AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppToast from "@toothfairy/shared-ui/AppToast";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import trainingAPI from "../../../API/Training/trainingAPI";
import Files from "../../../API/Files";
import useAPI from "@toothfairy/shared-api/useApi";
import axios from "axios";
import envConfig from "../../../../envConfig";
import AudioRecorder from "../../../Components/AudioRecorder";
import { generateRowId } from "../../../Utility";
import AppButtonMenu from "@toothfairy/shared-ui/AppButtonMenu";
import Media from "../../../API/Media";
import ChatMediaLeaf from "./ChatMediaLeaf";
const controller = new AbortController();
const ChatWebInput = ({
  placeholder = "Ask me anything...",
  isSpeechGettingProcessed = false,
  stopAudio = () => {},
  onSend = () => {},
  isStreaming,
  onCancel = () => {},
  allowStop = false,
  isLastMessageEmpty,
  showWidgetChatsHistory,
  canInteractWithChat,
  quickQuestions = [],
  isChatEmpty = false,
  primaryAgentId,
  allowImagesUpload = false,
  allowAudiosUpload = false,
  allowVideosUpload = false,
  allowFileUpload = false,
  allowCodeUpload = false,
  onImageUploaded = () => {},
  onAudioUploaded = () => {},
  onVideoUploaded = () => {},
  onFileUploaded = () => {},
  onFilesUploaded = () => {},
  videoUrl,
  setVideoUrl,
  imageUrl,
  setImageUrl,
  audioUrl,
  setAudioUrl,
  fileUrl,
  setFileUrl,
  fileUrls,
  setFileUrls,
  isAutoReplyMode = false,
  isPlaying = false,
}) => {
  // const abortController = new AbortController();
  const additionalFiles = allowCodeUpload
    ? ", .py, .js, .java, .cpp, .c, .cs, .php, .rb, .swift, .go, .ts, .rs, .kt, .scala, .m, .r, .pl, .sh, .sql, .vb, .lua, .dart, .groovy, .jl, .hs, .f90, .pas, .elm, .clj"
    : "";
  // // Get the abort signal from the controller
  // const { signal } = abortController;
  const source = axios.CancelToken.source();
  const AppToasty = AppToast.useToast();
  const { currentTheme } = AppTheme.useTheme();
  const textareaRef = useRef();
  const [isTyping, setIsTyping] = useState(false);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [filesUploadProgress, setFilesUploadProgress] = useState([]);
  const [recordingUploadInProgress, setRecordingUploadInProgress] =
    useState(false);
  const [recordingInProgress, setRecordingInProgress] = useState(false);
  const [recordingUploadProgress, setRecordingUploadProgress] = useState(0);
  const fileInputRef = useRef(null);
  const videoFileInputRef = useRef(null);
  const audioFileInputRef = useRef(null);
  const fileUploadRef = useRef(null);
  const [fileExtension, setFileExtension] = useState("");
  const [options, setOptions] = useState([]);
  const {
    loading: audioProcessingInProgress,
    apiRequest: audioProcessingRequest,
    wasInvoked: audioProcessingInvoked,
    data: audioProcessingResult,
  } = useAPI(Media.processAudio, null, () => {});

  useEffect(() => {
    const _options = [];
    if (allowImagesUpload)
      _options.push({
        label: "Upload image",
        id: "NEW_IMAGE",
        icon: AppAssets.icons.image,
      });
    if (allowAudiosUpload)
      _options.push({
        label: "Upload audio",
        id: "NEW_AUDIO",
        icon: AppAssets.icons.audio,
      });
    if (allowVideosUpload)
      _options.push({
        label: "Upload video",
        id: "NEW_VIDEO",
        icon: AppAssets.icons.video,
      });
    if (allowFileUpload)
      _options.push({
        label: "Upload files",
        id: "NEW_FILE",
        icon: AppAssets.icons.files,
      });
    setOptions(_options);
  }, [
    allowAudiosUpload,
    allowImagesUpload,
    allowVideosUpload,
    allowFileUpload,
  ]);
  const {
    generatorConfigData,
    handleGenerationOfCustomPrompt,
    isUserContentAdmin,
    getActiveWorkspace,
  } = useWorkspaces();
  const {
    data: S3uploadUrlData,
    loading: urlUploadGenerationInProgress,
    apiRequest: S3uploadUrlRequest,
    response: S3uploadResponse,
  } = useAPI(trainingAPI.bulkImportUrlGeneration, envConfig);
  const {
    data: S3downloadUrlData,
    loading: urldownloadGenerationInProgress,
    apiRequest: S3downloadUrlRequest,
    response: S3downloadResponse,
  } = useAPI(Files.downloadUrlGeneration, envConfig);
  const onRecordingUploaded = async (url) => {
    const result = await audioProcessingRequest({
      token: getActiveWorkspace()?.workspaceToken,
      data: {
        audio_file: url,
        workspaceid: getActiveWorkspace()?.id,
        chat_mode: true,
      },
    });
    if (result?.contents != undefined) {
      if (isAutoReplyMode) onSend(result?.contents);
      else {
        textareaRef.current.value += `${result?.contents}`;
      }
    }
  };
  const handleRecordingStarted = () => {
    setRecordingInProgress(true);
  };
  const detectExtensionFromFileName = (fileUurl) => {
    //  create code to extract extension from this file "https://files-to-import-aus-dev.s3.ap-southeast-2.amazonaws.com/imported_doc_files/48922607-0f1a-4993-8ff0-cf3bcfac7033/1720417034341betaupdate.html?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAZ3SLB4WH5U5OOSF3%2F20240708%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20240708T053714Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaDmFwLXNvdXRoZWFzdC0yIkcwRQIgfnq3fswhgWrhIaHryJlCTDG4Yjr9jJPECMSp2MNBx98CIQCrUjyaOZl%2Brix30A1xmDSDtT%2F544AqRDYMPWnsqMnD0Sq3Awj%2B%2F%2F%2F%2F%2..."
    const url = new URL(fileUurl);
    const pathname = url.pathname;
    const lastSlashIndex = pathname.lastIndexOf("/");
    if (lastSlashIndex !== -1) {
      const filename = pathname.substring(lastSlashIndex + 1);
      const dotIndex = filename.lastIndexOf(".");
      if (dotIndex !== -1) {
        return filename.substring(dotIndex + 1);
      }
    }
    return "";
  };

  const detectFileName = (fileUurl) => {
    //  create code to extract the filename from this file "https://files-to-import-aus-dev.s3.ap-southeast-2.amazonaws.com/imported_doc_files/48922607-0f1a-4993-8ff0-cf3bcfac7033/1720417034341betaupdate.html?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAZ3SLB4WH5U5OOSF3%2F20240708%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20240708T053714Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEEUaDmFwLXNvdXRoZWFzdC0yIkcwRQIgfnq3fswhgWrhIaHryJlCTDG4Yjr9jJPECMSp2MNBx98CIQCrUjyaOZl%2Brix30A1xmDSDtT%2F544AqRDYMPWnsqMnD0Sq3Awj%2B%2F%2F%2F%2F%2..."
    const url = new URL(fileUurl);
    const pathname = url.pathname;
    const lastSlashIndex = pathname.lastIndexOf("/");
    if (lastSlashIndex !== -1) {
      const filename = pathname.substring(lastSlashIndex + 1);
      const dotIndex = filename.lastIndexOf(".");
      if (dotIndex !== -1) {
        return filename.substring(0, dotIndex);
      }
    }
    return "";
  };
  const handleRecordingCompleted = async (blob) => {
    setRecordingInProgress(false);
    const _file = new File([blob], "audio.wav", {
      type: "audio/wav",
    });
    const audioId = generateRowId();
    const result = await S3uploadUrlRequest({
      filename: `${getActiveWorkspace()?.id}/${audioId}.wav`,
      importType: "imported_audio_files",
      contentType: "wav",
    });
    const upload = await axios.request({
      method: "put",
      url: JSON.parse(result?.body)?.uploadURL,
      data: _file,
      headers: {
        "Content-Type": _file.type,
      },
      signal: controller.signal,
      onUploadProgress: (p) => {
        // if (controller.signal.aborted) return;
        setRecordingUploadInProgress(true);
        setRecordingUploadProgress(Math.round((p.loaded * 100) / p.total));
        if (p.loaded === p.total) {
          setRecordingUploadInProgress(false);
          setRecordingUploadProgress(100);
        }
      },
    });
    if (JSON.parse(result?.body).uploadURL) {
      onRecordingUploaded(
        `imported_audio_files/${getActiveWorkspace()?.id}/${audioId}.wav`
      );
    }
  };
  const isValidFileSize = (file, mb = 5) => {
    const maxSizeInBytes = mb * 1024 * 1024;
    return file.size <= maxSizeInBytes;
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!isValidFileSize(file)) {
      return AppToasty.show(
        "File size exceeds 5MB. Please upload a smaller image.",
        {
          type: "danger",
        }
      );
    }
    handleFileSelection(file);
  };
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (!isValidFileSize(file, 500)) {
      return AppToasty.show(
        "File size exceeds 500MB. Please upload a smaller video.",
        {
          type: "danger",
        }
      );
    }
    handleFileSelection(file, "video");
  };
  const handleFileUpload = (event) => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (!isValidFileSize(files[i], 500)) {
        return AppToasty.show(
          `File ${files[i].name} size exceeds 500MB. Please upload a smaller file.`,
          {
            type: "danger",
          }
        );
      }
    }
    handleBatchFilesSelection(files, "file");
  };
  const handleAudioUpload = (event) => {
    const file = event.target.files[0];
    if (!isValidFileSize(file, 500)) {
      return AppToasty.show(
        "File size exceeds 500MB. Please upload a smaller audio.",
        {
          type: "danger",
        }
      );
    }
    handleFileSelection(file, "audio");
  };
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  let _prompts = [];
  if (generatorConfigData?.length > 0) {
    _prompts = generatorConfigData
      ?.filter((u) => {
        if (u?.availableToAgents?.includes(primaryAgentId)) return u;
      })
      ?.filter((u) => u);
  }
  // get focus on input when component mounts
  React.useEffect(() => {
    document.querySelector("textarea").focus();
  }, []);
  const getUrl = async (path, type = "image") => {
    let initialPath = "";
    setFileExtension(path?.split(".")?.pop());
    if (type == "image") {
      initialPath = "imported-image";
    } else if (type == "audio") {
      initialPath = "imported_audio_files";
    } else if (type == "video") {
      initialPath = "imported_video_files";
    } else if (type == "file") {
      initialPath = "imported_doc_files";
    } else {
      return "";
    }
    const _url = await S3downloadUrlRequest({
      filename: `${initialPath}/${getActiveWorkspace()?.id}/${path}`,
      context: "pdf",
      workspaceid: getActiveWorkspace()?.id,
    });
    if (_url?.url && type == "image") setImageUrl(_url?.url);
    if (_url?.url && type == "video") setVideoUrl(_url?.url);
    if (_url?.url && type == "audio") setAudioUrl(_url?.url);
    if (_url?.url && type == "file") return _url?.url;
  };

  const handleBatchFilesSelection = async (files, type = "file") => {
    try {
      if (!files) return;

      const filesArray = Array.from(files);
      const uploadedFilesUrls = [];
      const presignedFilesUrls = [];

      // Initialize progress tracking
      setFileUploadInProgress(true);
      const initialProgress = filesArray.map((_, i) => ({
        id: i,
        progress: 0,
      }));
      setFilesUploadProgress(initialProgress);
      if (
        filesArray?.length > 10 ||
        filesArray?.length + fileUrls?.length > 10
      ) {
        AppToasty.show("File limit exceeded. Maximum 10 files allowed.", {
          type: "danger",
        });
        return;
      }

      let totalProgress = 0;

      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i];
        let filename = file.name;
        filename = filename.replace(/[^a-zA-Z0-9.]/g, "");
        const _fileN = String(Date.now()) + filename;
        const regex = /^[a-zA-Z0-9\s-()[\]]*(\.[a-zA-Z0-9]+)?$/;
        if (!filename.match(regex)) {
          return AppToasty.show(
            "File name can only contain alphanumeric characters, whitespace characters, hyphens, parentheses, and square brackets.",
            {
              type: "danger",
            }
          );
        }
        if (filename?.length > 100) {
          return AppToasty.show(
            "File name can not be more than 100 characters.",
            {
              type: "danger",
            }
          );
        }
        const contentType = filename.slice(
          (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
        );
        let importType = "imported-image";
        if (type === "video") {
          importType = "imported_video_files";
        } else if (type === "audio") {
          importType = "imported_audio_files";
        } else if (type === "file") {
          importType = "imported_doc_files";
        }
        const result = await S3uploadUrlRequest({
          filename: `${getActiveWorkspace()?.id}/${_fileN}`,
          importType,
          contentType,
        });

        const upload = await axios.request({
          method: "put",
          url: JSON.parse(result?.body)?.uploadURL,
          data: file,
          headers: { "Content-Type": file.type },
          cancelToken: source.token,
          onUploadProgress: (p) => {
            const fileProgress = Math.round((p.loaded * 100) / p.total);

            setFilesUploadProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[i] = { ...newProgress[i], progress: fileProgress };
              totalProgress =
                newProgress.reduce((sum, file) => sum + file.progress, 0) /
                filesArray.length;
              setFileUploadProgress(Math.round(totalProgress));
              return newProgress;
            });
          },
        });
        if (JSON.parse(result?.body).uploadURL) {
          const _url = await getUrl(_fileN, type);
          uploadedFilesUrls.push(
            `imported_doc_files/${getActiveWorkspace()?.id}/${_fileN}`
          );
          presignedFilesUrls.push(_url);
        }
      }
      if (type === "file") {
        onFilesUploaded(uploadedFilesUrls, presignedFilesUrls);
      }
    } catch (error) {
      console.error("Upload error:", error);
      setFileUploadInProgress(false);
    } finally {
      setFileUploadInProgress(false);
    }
  };

  const handleFileSelection = async (file, type = "image") => {
    if (!file) return;
    let filename = file.name;
    filename = filename.replace(/[^a-zA-Z0-9.]/g, "");
    const _fileN = String(Date.now()) + filename;
    const regex = /^[a-zA-Z0-9\s-()[\]]*(\.[a-zA-Z0-9]+)?$/;
    if (!filename.match(regex)) {
      return AppToasty.show(
        "File name can only contain alphanumeric characters, whitespace characters, hyphens, parentheses, and square brackets.",
        {
          type: "danger",
        }
      );
    }
    if (filename?.length > 100) {
      return AppToasty.show("File name can not be more than 100 characters.", {
        type: "danger",
      });
    }
    const contentType = filename.slice(
      (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
    );
    let importType = "imported-image";
    if (type === "video") {
      importType = "imported_video_files";
    } else if (type === "audio") {
      importType = "imported_audio_files";
    } else if (type === "file") {
      importType = "imported_doc_files";
    }

    const result = await S3uploadUrlRequest({
      filename: `${getActiveWorkspace()?.id}/${_fileN}`,
      importType,
      contentType,
    });
    const upload = await axios.request({
      method: "put",
      url: JSON.parse(result?.body)?.uploadURL,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
      // signal: signal,
      cancelToken: source.token,
      onUploadProgress: (p) => {
        setFileUploadInProgress(true);
        setFileUploadProgress(Math.round((p.loaded * 100) / p.total));
        if (p.loaded === p.total) {
          setFileUploadInProgress(false);
          setFileUploadProgress(100);
        }
      },
    });
    if (JSON.parse(result?.body).uploadURL) {
      const _url = getUrl(_fileN, type);

      if (type === "image") {
        onImageUploaded(`imported-image/${getActiveWorkspace()?.id}/${_fileN}`);
        setImageUrl(_url);
      }
      if (type === "audio") {
        onAudioUploaded(
          `imported_audio_files/${getActiveWorkspace()?.id}/${_fileN}`
        );
      }
      if (type === "video") {
        onVideoUploaded(
          `imported_video_files/${getActiveWorkspace()?.id}/${_fileN}`
        );
      }
    }
  };
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const handleScroll = () => {
        const currentScrollHeight = textarea.scrollHeight;
        const scrollTop = textarea.scrollTop;

        if (textarea.scrollTop < prevScrollTop) {
          // Scrolling up
          setIsScrollingUp(true);
        } else {
          // Scrolling down
          setIsScrollingUp(false);
        }

        // Update the previous scrollTop value
        setPrevScrollTop(textarea.scrollTop);
        // If the user is scrolling up, remove the scroll event listener
        if (prevScrollHeight > currentScrollHeight || isScrollingUp) {
          textarea.removeEventListener("scroll", handleScroll);
          setPrevScrollHeight(currentScrollHeight);
          return;
        }

        // If the user is typing, set the scroll position to the bottom
        if (isTyping) {
          textarea.scrollTop = currentScrollHeight;
          setPrevScrollHeight(currentScrollHeight);
        }
      };

      textarea.addEventListener("scroll", handleScroll);
      textarea.addEventListener("input", () => setIsTyping(true));
      textarea.addEventListener("compositionstart", () => setIsTyping(true));
      textarea.addEventListener("compositionend", () => setIsTyping(false));

      // Set the initial scroll position to the bottom
      textarea.scrollTop = textarea.scrollHeight;
      setPrevScrollHeight(textarea.scrollHeight);

      return () => {
        textarea.removeEventListener("scroll", handleScroll);
        textarea.removeEventListener("input", () => setIsTyping(true));
        textarea.removeEventListener("compositionstart", () =>
          setIsTyping(true)
        );
        textarea.removeEventListener("compositionend", () =>
          setIsTyping(false)
        );
      };
    }
  }, [isTyping]);

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      !isStreaming &&
      !audioProcessingInProgress &&
      !fileUploadInProgress
    ) {
      event.preventDefault();
      if (textareaRef.current.value?.length > 10000) {
        return AppToasty.show("Maximum 10000 characters allowed", {
          type: "danger",
        });
      }
      onSend(event.target.value);
      event.target.value = "";
    }
  };

  const handleSend = () => {
    if (textareaRef.current.value?.length > 10000) {
      return AppToasty.show("Maximum 10000 characters allowed", {
        type: "danger",
      });
    }
    if (!isStreaming && textareaRef.current.value?.trim()?.length > 0) {
      onSend(textareaRef.current.value);
      textareaRef.current.value = null;
      document.querySelector("textarea").focus();
    }
  };
  const onValueChange = (itemValue, itemIndex) => {
    if (itemIndex === 0) return textareaRef.current.value;
    textareaRef.current.value = generatorConfigData.find(
      (u) => itemValue == u?.id
    )?.interpolationString;
    setSelectedPrompt(null);
  };
  return (
    <View
      style={{
        width: "100%",
      }}
    >
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        {!(showWidgetChatsHistory || canInteractWithChat === false) && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: _prompts.length > 0 && 0,
              marginLeft: 10,
              gap: 5,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {imageUrl && (
                <ChatMediaLeaf onUploaded={onImageUploaded} url={imageUrl} />
              )}
              {videoUrl && (
                <ChatMediaLeaf
                  onUploaded={onVideoUploaded}
                  url={videoUrl}
                  type="video"
                />
              )}
              {audioUrl && (
                <ChatMediaLeaf
                  onUploaded={onAudioUploaded}
                  url={audioUrl}
                  type="audio"
                />
              )}
              {fileUrls &&
                fileUrls.map((fileUrl, index) => {
                  return (
                    <ChatMediaLeaf
                      onUploaded={() => onFilesUploaded(null, null, index)}
                      url={fileUrl}
                      type="file"
                      fileName={detectFileName(fileUrl)}
                      fileExtension={detectExtensionFromFileName(fileUrl)}
                    />
                  );
                })}
              {recordingUploadInProgress && (
                <AppText
                  color={currentTheme?.theme?.orange}
                  style={{
                    fontSize: currentTheme?.theme?.extra_small_font_size,
                  }}
                  preventTranslation
                >
                  Transcription in progress{" "}
                  <strong>{recordingUploadProgress}%</strong>
                </AppText>
              )}
              {fileUploadInProgress && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    minWidth: 140,
                    marginBottom: 7,
                  }}
                >
                  <AppText
                    color={currentTheme?.theme?.orange}
                    style={{
                      fontSize: currentTheme?.theme?.extra_small_font_size,
                    }}
                    wrapperStyle={{
                      marginRight: 5,
                    }}
                    preventTranslation
                  >
                    Upload in progress <strong>{fileUploadProgress}%</strong>
                  </AppText>
                  {/* <AppIcon
                  icon={AppAssets?.icons?.close}
                  size={16}
                  color={currentTheme?.theme?.red}
                  isClickable
                  onPress={() => {
                    controller.abort();
                    // source.cancel("Upload process cancelled by user.");
                    setFileUploadInProgress(false);
                    setFileUploadProgress(0);
                  }}
                /> */}
                </View>
              )}
              {_prompts.length > 0 && (
                <AppDropDown
                  showTextInputLabel={false}
                  label=""
                  inputLabelStyle={{
                    marginLeft: 0,
                  }}
                  dropdownIconColor={currentTheme?.theme?.primary}
                  wrapperContainer={{
                    paddingBottom: 8,
                    paddingHorizontal: 8,
                  }}
                  dropDownStyle={{
                    minWidth: 100,
                    borderColor: currentTheme?.theme?.transparent,
                    backgroundColor: currentTheme?.theme?.primary_soft,
                    maxWidth: 250,
                    color: currentTheme?.theme?.primary,
                    fontSize: currentTheme?.theme?.extra_small_font_size,
                  }}
                  isElevated={false}
                  items={[
                    { id: null, label: "Select prompt" },
                    ...generatorConfigData
                      ?.filter((u) => {
                        if (u?.availableToAgents?.includes(primaryAgentId))
                          return u;
                      })
                      ?.filter((u) => u),
                  ]}
                  propertyLabel="label"
                  propertyValue="id"
                  selectedValue={selectedPrompt}
                  onValueChange={(itemValue, itemIndex) => {
                    onValueChange(itemValue, itemIndex);
                  }}
                />
              )}
              {isUserContentAdmin() && (
                <AppText
                  isClickable
                  color={currentTheme?.theme?.primary}
                  style={{
                    fontSize: currentTheme?.theme?.extra_small_font_size,
                    fontFamily: "boldFont",
                  }}
                  wrapperStyle={{
                    paddingBottom: 8,
                    paddingLeft: 5,
                  }}
                  onPress={() => {
                    handleGenerationOfCustomPrompt(
                      textareaRef.current.value,
                      [primaryAgentId],
                      false
                    );
                  }}
                >
                  Save prompt
                </AppText>
              )}
            </View>
            {isSpeechGettingProcessed && (
              <AppText
                color={currentTheme?.theme?.orange}
                leftIcon={AppAssets?.icons?.info}
                iconSize={16}
                style={{
                  fontSize: currentTheme?.theme?.extra_small_font_size,
                  fontFamily: "boldFont",
                  marginLeft: 5,
                }}
                wrapperStyle={{
                  paddingBottom: 8,
                  paddingLeft: 5,
                  marginRight: 10,
                }}
              >
                Generating audio...
              </AppText>
            )}
            {isPlaying && (
              <AppText
                isClickable
                isHoverable={false}
                color={currentTheme?.theme?.red}
                leftIcon={AppAssets?.icons?.stopPlaying}
                style={{
                  fontSize: currentTheme?.theme?.extra_small_font_size,
                  fontFamily: "boldFont",
                  marginLeft: 5,
                }}
                wrapperStyle={{
                  paddingBottom: 8,
                  paddingLeft: 5,
                  marginRight: 10,
                }}
                onPress={() => {
                  stopAudio();
                }}
              >
                Stop audio
              </AppText>
            )}
          </View>
        )}
        {!(showWidgetChatsHistory || canInteractWithChat === false) &&
          isChatEmpty &&
          quickQuestions &&
          quickQuestions?.length > 0 && (
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                flexWrap: "wrap",
                marginBottom: 5,
                gap: 5,
              }}
            >
              {quickQuestions?.map((question, index) => (
                <AppText
                  isClickable
                  key={index}
                  onPress={() => onSend(question)}
                  wrapperStyle={{
                    paddingVertical: 5,
                    paddingRight: 10,
                    paddingLeft: 5,
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: currentTheme?.theme?.transparent,
                    backgroundColor: currentTheme?.theme?.primary_soft,
                  }}
                  style={{
                    marginLeft: 5,
                    fontSize: currentTheme?.theme?.extra_small_font_size,
                  }}
                  color={currentTheme?.theme?.primary}
                  leftIcon={AppAssets?.icons?.question_icon}
                  iconSize={16}
                >
                  {question}
                </AppText>
              ))}
            </View>
          )}
      </View>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: currentTheme?.theme?.lightGrey,
          justifyContent: "space-between",
          maxHeight: 120,
          marginBottom: 10,
          height: 120,
          marginLeft: 10,
          borderRadius: 10,
        }}
      >
        <textarea
          ref={textareaRef}
          placeholder={
            showWidgetChatsHistory || canInteractWithChat === false
              ? "Cannot interact with chat"
              : audioProcessingInProgress
              ? "Processing audio..."
              : fileUploadInProgress
              ? "Uploading file..."
              : recordingInProgress
              ? "Listening..."
              : isSpeechGettingProcessed // Add this condition
              ? "Generating speech..."
              : placeholder
          }
          style={{
            fontFamily: "normalFont",
            fontSize: currentTheme?.theme?.small_font_size,
            color: currentTheme?.theme?.font_color,
            backgroundColor: currentTheme?.theme?.transparent,
            flex: 1,
          }}
          disabled={
            showWidgetChatsHistory ||
            canInteractWithChat === false ||
            audioProcessingInProgress ||
            isSpeechGettingProcessed
          }
          onKeyDown={handleKeyDown}
        />
        {options?.length > 0 && (
          <View>
            <AppButtonMenu
              mainActionLabel={options[0]?.label}
              onClickMainAction={() => {
                if (options[0]?.id == "NEW_IMAGE") fileInputRef.current.click();
                if (options[0]?.id == "NEW_AUDIO")
                  audioFileInputRef.current.click();
                if (options[0]?.id == "NEW_VIDEO")
                  videoFileInputRef.current.click();
                if (options[0]?.id == "NEW_FILE") fileUploadRef.current.click();
              }}
              wrapperStyle={{
                backgroundColor: currentTheme?.theme?.primary_medium,
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRadius: 20,
              }}
              containerStyle={{
                justifyContent: "flex-end",
                marginTop: 5,
              }}
              menuItemIconSize={18}
              labelStyle={{
                fontWeight: "bold",
              }}
              style={{
                top: options?.length > 3 ? -50 : -19,
                fontFamily: "boldFont",
                minWidth: 90,
                color: currentTheme?.theme?.font_color,
                fontSize: currentTheme?.theme?.extra_small_font_size,
              }}
              menuItemStyle={{
                marginLeft: 10,
                fontSize: currentTheme?.theme?.extra_small_font_size,
              }}
              color={currentTheme?.theme?.font_color}
              rightIconColor={currentTheme?.theme?.font_color}
              iconSize={12}
              onClickMenuItem={(id) => {
                if (id === "NEW_IMAGE") {
                  fileInputRef.current.click();
                } else if (id === "NEW_AUDIO") {
                  audioFileInputRef.current.click();
                } else if (id === "NEW_VIDEO") {
                  videoFileInputRef.current.click();
                } else if (id === "NEW_FILE") {
                  fileUploadRef.current.click();
                }
              }}
              anchorPosition="top"
              options={options}
            />
          </View>
        )}

        {allowAudiosUpload && !isStreaming && (
          <AudioRecorder
            onRecordingCompleted={handleRecordingCompleted}
            onRecordingStarted={handleRecordingStarted}
            style={{
              justifyContent: "flex-start",
              marginTop: 7,
              marginLeft: 10,
            }}
          />
        )}
        {!showWidgetChatsHistory && canInteractWithChat && (
          <AppIcon
            icon={
              !isStreaming
                ? AppAssets?.icons?.email
                : AppAssets?.icons?.stopGeneration
            }
            color={isStreaming && currentTheme?.theme?.red}
            size={20}
            wrapperStyle={{
              marginRight: 0,
              marginLeft: 8,
              marginTop: 6,
              justifyContent: "center",
              alignSelf: "baseline",
            }}
            isClickable={!fileUploadInProgress && !audioProcessingInProgress}
            onPress={!isLastMessageEmpty && isStreaming ? onCancel : handleSend}
          />
        )}
      </View>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleImageUpload}
        // limit the input to only accept images
        accept="image/png"
      />
      <input
        type="file"
        ref={videoFileInputRef}
        style={{ display: "none" }}
        onChange={handleVideoUpload}
        // limit the input to only accept images
        accept="video/mp4"
      />
      <input
        type="file"
        ref={audioFileInputRef}
        style={{ display: "none" }}
        onChange={handleAudioUpload}
        // limit the input to only accept images
        accept="audio/wav, audio/mp4"
      />
      <input
        type="file"
        ref={fileUploadRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
        accept={`.pdf, .csv, .doc, .docx, .xls, .xlsx, .html, .txt, .md${additionalFiles}`}
        multiple
        // allow only 10 files to be uploaded at a time
      />
    </View>
  );
};

export default ChatWebInput;
