import { AppText, AppIcon } from "@toothfairy/shared-ui";
import AppDot from "@toothfairy/shared-ui/AppDot";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { View } from "react-native";
import AppAssets from "@toothfairy/shared-ui/AppAssets";

const SavingNotification = ({
  label,
  isProgress,
  color,
  dotValue,
  isDotVisible = true,
  icon,
  showEditBtn,
  isEditEnabled,
  setIsEditEnabled,
  setLastCancellation,
  isParser = true,
  setIsSaveRequested,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        // alignSelf: "flex-end",
        marginRight: 5,
        minWidth: isParser ? 120 : 20,
        justifyContent: "flex-end",
      }}
    >
      {showEditBtn && (
        <>
          {isEditEnabled && (
            <AppIcon
              isClickable
              onPress={() => {
                setLastCancellation(Date.now());
              }}
              icon={AppAssets?.icons?.close}
              wrapperStyle={{
                marginRight: 5,
                marginTop: 2,
              }}
              color={currentTheme?.theme?.red}
              size={16}
            />
          )}
          <AppText
            isClickable
            onPress={() => {
              setIsEditEnabled(!isEditEnabled);
              if (!isEditEnabled && setIsSaveRequested)
                setIsSaveRequested(Date.now()?.toString());
            }}
            leftIcon={AppAssets?.icons?.edit}
            isHoverable={false}
            style={{
              marginLeft: 10,
            }}
            wrapperStyle={{
              paddingLeft: 5,
            }}
            fontWeight="bold"
            color={currentTheme?.theme?.primary}
            iconSize={16}
          >
            {isEditEnabled ? "Submit" : "Edit header"}
          </AppText>
          <AppIcon
            icon={AppAssets?.icons?.separator}
            color={currentTheme?.theme?.mediumGrey}
            isActive={false}
            size={18}
            wrapperStyle={{
              marginHorizontal: 10,
            }}
          />
        </>
      )}

      {isDotVisible && (
        <AppDot
          forcedValue={dotValue}
          dotSize={8}
          dotStyle={{
            marginRight: -5,
            backgroundColor: currentTheme?.theme[color],
          }}
        />
      )}
      <AppText
        style={{
          marginLeft: 5,
          fontSize: currentTheme?.theme?.medium_font_size,
        }}
        fontWeight={"bold"}
        leftIcon={!isDotVisible && icon}
        iconSize={18}
        color={currentTheme?.theme[color]}
      ></AppText>
    </View>
  );
};

export default SavingNotification;
