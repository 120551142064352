import React from "react";
import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppSwitch from "@toothfairy/shared-ui/AppSwitch";
import { AppText } from "@toothfairy/shared-ui";
import { View } from "react-native";
import { useReactiveVar } from "react-reactive-var";
import { appDimensions } from "../../MainViewContainer";

const DashboardItem = ({
  isFetchInProgress,
  children,
  title,
  isToogleVisible,
  toogleLabel,
  isTicked,
  handleTick = () => {},
  isHalfWidth = true,
  additionalTitle,
  ...props
}) => {
  const { width } = useReactiveVar(appDimensions);
  const { currentTheme } = AppTheme.useTheme();
  return isFetchInProgress ? (
    <AppActivityIndicator
      backgroundColor={currentTheme?.theme?.softBackgroundColor}
      visible={true}
      containerStyle={{
        backgroundColor: currentTheme?.theme?.transparent,
        minHeight: 250,
      }}
    />
  ) : (
    <View
      style={{
        alignItems: "center",
        width: isHalfWidth && width > 640 ? "48%" : "100%",
        minWidth: 300,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: currentTheme?.theme?.border_color,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignSelf: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <AppText
          wrapperStyle={{
            marginBottom: 0,
            marginTop: 0,
            alignSelf: "flex-start",
            paddingVertical: 20,
            paddingLeft: 20,
          }}
          style={{
            fontSize: currentTheme?.theme?.large_font_size,
            fontWeight: "bold",
          }}
          // size={currentTheme?.theme?.medium_font_size}
        >
          {title}
        </AppText>
        {additionalTitle && (
          <AppText
            wrapperStyle={{
              marginBottom: 0,
              marginTop: 0,
              alignSelf: "flex-start",
              paddingVertical: 20,
              paddingLeft: 0,
            }}
            style={{
              fontSize: currentTheme?.theme?.large_font_size,
              fontWeight: "bold",
            }}
            color={currentTheme?.theme?.primary}
            // size={currentTheme?.theme?.medium_font_size}
          >
            {additionalTitle}
          </AppText>
        )}

        {isToogleVisible && (
          <AppSwitch
            showTextInputLabel={true}
            label={toogleLabel}
            isTicked={isTicked}
            onChange={handleTick}
          />
        )}
      </View>

      {children}
    </View>
  );
};

export default DashboardItem;
