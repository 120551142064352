import React from "react";
import ToothFairySearch from "../../../Components/ToothFairyComponents/ToothFairySearch";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import CommonEntityFilter from "./CommonEntityFilter";
import CommonSearchTextAndId from "./CommonSearchTextAndId";

const GNView = ({
  documentIdString,
  handleChangeDocumentIdString,
  textString,
  handleChangeText,
  items,
  onDeleteSelectedItem,
  onItemPress,
  selectedItems,
  handleSearch,
  handleValidationOnly,
  isValidationOnly,
}) => {
  const { getWorkspaceEntities } = useWorkspaces();
  const entities = getWorkspaceEntities("topic")?.map((entity) => {
    return {
      ...entity,
      name: entity?.label,
      category: entity?.type,
    };
  });
  return (
    <ToothFairySearch handleSearch={handleSearch}>
      <CommonSearchTextAndId
        documentIdString={documentIdString}
        handleChangeDocumentIdString={handleChangeDocumentIdString}
        textString={textString}
        handleChangeText={handleChangeText}
        entityType="GN"
        handleValidationOnly={handleValidationOnly}
        isValidationOnly={isValidationOnly}
      />
      <CommonEntityFilter
        placeholder="DocumentsGNFilterPlaceholder"
        items={entities}
        onDeleteSelectedItem={onDeleteSelectedItem}
        onItemPress={onItemPress}
        selectedItems={selectedItems}
      />
    </ToothFairySearch>
  );
};

export default GNView;
