import React from "react";
import ToothFairySearch from "../../../Components/ToothFairyComponents/ToothFairySearch";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import CommonEntityFilter from "./CommonEntityFilter";
import CommonSearchTextAndId from "./CommonSearchTextAndId";

const RCView = ({
  documentIdString,
  handleChangeDocumentIdString,
  textString,
  handleChangeText,
  items,
  onDeleteSelectedItem,
  onItemPress,
  selectedItems,
  handleSearch,
  handleValidationOnly,
  isValidationOnly,
}) => {
  const { getWorkspaceEntities } = useWorkspaces();

  const topics = getWorkspaceEntities("topic")?.map((topic) => {
    return {
      ...topic,
      name: topic?.label,
      category: topic?.type,
    };
  });
  return (
    <ToothFairySearch handleSearch={handleSearch}>
      <CommonSearchTextAndId
        documentIdString={documentIdString}
        handleChangeDocumentIdString={handleChangeDocumentIdString}
        textString={textString}
        handleChangeText={handleChangeText}
        entityType="RC"
        handleValidationOnly={handleValidationOnly}
        isValidationOnly={isValidationOnly}
      />
      {/* <CommonEntityFilter
        placeholder="DocumentsRCFilterPlaceholder"
        items={topics}
        onDeleteSelectedItem={onDeleteSelectedItem}
        onItemPress={onItemPress}
        selectedItems={selectedItems}
      /> */}
    </ToothFairySearch>
  );
};

export default RCView;
