import { AppText } from "@toothfairy/shared-ui";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppBaseScreen from "@toothfairy/shared-ui/AppBaseScreen";
import AppChoice from "@toothfairy/shared-ui/AppChoice";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppQuantumChat from "@toothfairy/shared-ui/AppQuantumChat";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Platform, View, useWindowDimensions } from "react-native";
import AppJoyRide from "../../Components/Joyride";
import LoadingPlaceholder from "../../Components/LoadingPlaceholder";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import ToothFairyClickableOption from "../../Components/ToothFairyComponents/ToothFairyClickableOption";
import ToothFairyModal from "../../Components/ToothFairyComponents/ToothFairyModal";
import ToothFairyResponsiveOptionsModal from "../../Components/ToothFairyComponents/ToothFairyResponsiveOptionsModal";
import ToothFairySupportButton from "../../Components/ToothFairyComponents/ToothFairySupportButton";
import AppTreeView from "../../Components/TreeView";
import useUserHelperPreferences from "../../Hooks/useUserHelperPreferences";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { useHistory } from "../../Router";
import {
  isLargeScreen,
  isMobileScreen,
  minAppWidth,
} from "../MainViewContainer";
import TopicsSelectionComponent from "../MrcDocuments/Components/TopicsSelectionComponent";
import ChatCreationForm from "./Components/ChatCreationForm";
import ChatWebInput from "./Components/ChatWebInput";
import ReferencesView from "./Components/ReferencesView";
import Utility from "../../Utility";

const steps_first_interaction = [
  {
    disableBeacon: true,
    target: ".upload_docs",
    content:
      "You can start chatting with your docs by uploading them to the Knowledge Hub.",
    placement: "right",
  },
];

const steps_second_interaction = [
  {
    disableBeacon: true,
    target: ".start_chat",
    content:
      "Once you have uploaded your docs and the learning process is complete, you can start chatting with them.",
    placement: "left",
  },
];

const ChatsScreenView = ({
  handleClickOnNode,
  SaveChat,
  selectedNode,
  deleteNode,
  showModal,
  setShowModal,
  setDialogActionOnTree,
  treeViewActionContext,
  isTreeDataLoading,
  selectedTopics,
  onSelectedTopicsChange,
  topics,
  onDrop,
  _coreTreeViewData,
  selectedNodeToDelete,
  creationofNewDocumentInProgress,
  isCollapsed,
  setIsCollapsed,
  onMove,
  chatId,
  primaryAgent,
  isLoading,
  messages,
  setMessages,
  onLoadEarlier = () => {},
  chatObject,
  isAIStreaming,
  isAIReplyingOtherUsers,
  onCancelGeneration = () => {},
  onSendEvent = () => {},
  handleShowSourcesDetails = () => {},
  handleParentActionClick = () => {},
  handleFeedback = () => {},
  handleClickOnWidgetChatsHistory = () => {},
  listenToAudio = () => {},
  inputText,
  setInputText,
  allMessagesLoaded,
  subscriptionObj,
  streamingMessageID,
  documents,
  documentId,
  setTreeViewActionContext,
  S3downloadUrlRequest,
  workspaceid,
  errorWithSubscription,
  handleCopy = () => {},
  showSourcesModal,
  setShowSourcesModal,
  selectedSources,
  isMoreToLoad,
  creationOfNewChatInProgress,
  allowStop = false,
  setIsChatHistoryLoaded,
  isLastMessageEmpty,
  showWidgetChatsHistory,
  setShowWidgetChatsHistory,
  canInteractWithChat,
  onImageUploaded,
  imageUrl,
  setImageUrl,
  onAudioUploaded,
  audioUrl,
  setAudioUrl,
  onFileUploaded,
  fileUrl,
  setFileUrl,
  onVideoUploaded,
  videoUrl,
  setVideoUrl,
  onImageSelected = () => {},
  selectedImageKey,
  onFilesUploaded = () => {},
  fileUrls,
  setFileUrls,
  forceAgentsName,
  activeStep,
  planningExecution,
  totalSteps,
  setSelectedNode,
  isSpeechGettingProcessed,
  updateTextForSpeech = () => {},
  stopAudio = () => {},
  isPlaying,
  lastUpdateMessageTime,
}) => {
  const formRef = useRef();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const [agentImage, setAgentImage] = useState(null);
  const history = useHistory();
  const { currentTheme } = AppTheme.useTheme();
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isPlannerModalShown, setShowPlannerModal] = useState(false);
  const { user } = AppUser.useUser();
  const { updateUserHelperPreferences } = useUserHelperPreferences();
  const { getActiveWorkspace, loadChats, getPlanConfig, isUserContentAdmin } =
    useWorkspaces();
  const { height } = useWindowDimensions();
  const _selectedNodeType = selectedNode?.data?.droppable ? "node" : "chat";
  const fetchAgentImage = async () => {
    const _url = await S3downloadUrlRequest({
      filename: `imported-image/${workspaceid}/${primaryAgent?.icon}`,
      context: "pdf",
      workspaceid,
    });
    setAgentImage(_url?.url);
  };

  useEffect(() => {
    if (primaryAgent?.icon) fetchAgentImage();
    else {
      setAgentImage(null);
    }
  }, [primaryAgent?.icon]);
  useEffect(() => {
    if (!showModal) {
      formRef?.current?.resetForm();
      setShowModal(false);
      setTreeViewActionContext("");
    }
  }, [showModal]);
  const getAgentObject = (agentId) => {
    return state?.agents?.find((u) => u.id == agentId);
  };
  const mapModeWithLabel = (mode) => {
    switch (mode) {
      case "planner":
        return "Planner";
      case "chatter":
        return "Casual";
      case "coder":
        return "Coder";
      case "retriever":
        return "Retriever";
      default:
        return "Unknown";
    }
  };

  const getAgentDescription = (agent) => {
    return `${agent?.label} - ${mapModeWithLabel(agent?.mode)}`;
  };

  const constructLabelForAgentTools = (primaryAgent) => {
    // the idea is to say whether the agent has access to functions, internet and topics. The statement should be something like "Agent has access to functions, internet and topics"
    const _tools = [];
    if (primaryAgent?.mode == "planner") {
      return `This planner agent has access to ${primaryAgent?.agentsPool?.length} agents`;
    } else {
      if (
        primaryAgent?.hasTopicsContext &&
        primaryAgent?.allowedTopics?.length > 0
      )
        _tools.push("knowledge hub");
      if (primaryAgent?.agentFunctions?.length > 0) _tools.push("functions");
      if (primaryAgent?.allowInternetSearch) _tools.push("internet search");
      if (primaryAgent?.hasCode) _tools.push("code execution");
      if (primaryAgent?.allowImagesGeneration) _tools.push("image generation");
      if (_tools.length > 1) {
        return `This agent has access to ${_tools
          .slice(0, -1)
          .join(", ")} and ${_tools.slice(-1)}`;
      } else if (_tools?.length == 0) {
        return `This agent has no access to any tools`;
      } else {
        return `This agent has access to ${_tools[0]}`;
      }
    }
  };

  const _plannerModalView = useMemo(() => {
    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                width: "95%",
                maxWidth: 600,
                height: "auto",
                marginHorizontal: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={isPlannerModalShown}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle={"Agents available"}
        modalInnerContentStyle={{
          paddingHorizontal: 20,
          paddingVertical: 20,
        }}
        closeModal={() => {
          setShowPlannerModal(false);
        }}
      >
        <View
          style={{
            gap: 5,
          }}
        >
          {primaryAgent?.agentsPool?.map((agent) => {
            const _agent = getAgentObject(agent);
            return (
              <AppText
                wrapperStyle={{
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: 5,
                  paddingVertical: 5,
                }}
                iconSize={16}
                leftIcon={Utility.mapAgentTypeWithIcon(_agent, AppAssets)}
                key={agent}
                rightIcon={AppAssets?.icons?.goTo}
                onRightIconPress={() => {
                  history.push(
                    `/workspaces/${
                      getActiveWorkspace()?.id
                    }/settings/roles_settings/${agent}`
                  );
                }}
              >
                {getAgentDescription(_agent)}
              </AppText>
            );
          })}
        </View>
      </ToothFairyModal>
    );
  }, [setShowPlannerModal, isPlannerModalShown]);

  const _coreModalView = useMemo(() => {
    const handleFormSubmit = (values) => {
      if (treeViewActionContext === "NEW_CHAT") SaveChat({}, values, false);
      else if (treeViewActionContext === "EDIT_CHAT")
        SaveChat(selectedNode, values, true);
    };
    const generateModalTitle = () => {
      if (treeViewActionContext === "NEW_CHAT") return "New Chat";
      else if (treeViewActionContext === "EDIT_CHAT") return "Edit chat";
    };
    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                width: "95%",
                maxWidth: 600,
                height: "auto",
                marginHorizontal: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={showModal}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle={generateModalTitle()}
        modalInnerContentStyle={{
          paddingHorizontal: 20,
          paddingVertical: 20,
        }}
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowModal(false);
          setTreeViewActionContext("");
        }}
      >
        {treeViewActionContext === "DELETE_NODE" ? (
          <AppChoice
            wrapperStyle={{
              alignSelf: "center",
              maxWidth: 600,
              marginTop: 50,
            }}
            cancelCaption={`${_selectedNodeType}DeleteCancelCaption`}
            confirmCaption={`${_selectedNodeType}DeleteConfirmCaption`}
            onConfirmButtonPress={() => {
              deleteNode(selectedNodeToDelete);
            }}
            onCancelButtonPress={() => {
              setShowModal(false);
            }}
            AppChoiceButton={ToothFairyButton}
            caption={`${_selectedNodeType}DeleteCaption`}
            subCaption={`${_selectedNodeType}DeleteSubCaption`}
          />
        ) : (
          <ChatCreationForm
            ref={formRef}
            creationofNewDocumentInProgress={creationofNewDocumentInProgress}
            treeViewActionContext={treeViewActionContext}
            selectedNode={selectedNode}
            handleFormSubmit={handleFormSubmit}
            documents={documents}
          />
        )}
      </ToothFairyModal>
    );
  }, [
    setShowModal,
    SaveChat,
    // createDocument,
    currentTheme,
    showModal,
    isLargeScreen(),
    selectedNode?.id,
    treeViewActionContext,
    creationofNewDocumentInProgress,
    documents?.length,
  ]);

  const _coreSourcesModelView = useMemo(() => {
    return (
      <ToothFairyModal
        transparent
        modalStyle={
          Platform.OS === "web"
            ? {
                width: "95%",
                maxWidth: 600,
                maxHeight: "99%",
                minHeight: 300,
                height: "auto",
                marginHorizontal: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: 20,
              }
            : {}
        }
        isModalVisible={showSourcesModal}
        animationType="none"
        modalBackdrop={currentTheme?.theme?.softBackgroundColor}
        isCloseButtonVisible
        modalTitle={"Data sources and functions"}
        modalInnerContentStyle={{
          paddingHorizontal: 20,
          paddingVertical: 20,
        }}
        closeModal={() => {
          setShowSourcesModal(false);
        }}
      >
        <ReferencesView selectedSources={selectedSources} />
      </ToothFairyModal>
    );
  }, [JSON.stringify(selectedSources), showSourcesModal]);

  const topicSelectionComponent = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: !isMobileScreen() && 10,
          zIndex: 1,
          marginLeft: 10,
          minWidth: "50%",
          flex: 1,
        }}
      >
        {/* {primaryAgent?.hasTopicsContext &&
        primaryAgent?.allowedTopics?.length > 0 ? (
          <TopicsSelectionComponent
            showEditIcon={false}
            placeholder="Filter topics"
            isTopicUpdateModeEnabled={isAIStreaming || allowStop ? false : true}
            selectedTopics={selectedTopics}
            onSelectedTopicsChange={onSelectedTopicsChange}
            topics={
              primaryAgent?.allowedTopics?.map((u) => {
                return {
                  ...u,
                  // label: u.name,
                  name: u.label || u.name,
                  backgroundColor:
                    topics?.find((t) => t.id === u.id)?.backgroundColor ||
                    currentTheme?.theme?.grey,
                  emoji: topics?.find((t) => t.id === u.id)?.emoji || "📁",
                };
              }) || []
            }
          />
        ) : ( */}
        <AppText
          style={{
            fontWeight: "bold",
          }}
          color={currentTheme?.theme?.orange}
          wrapperStyle={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: isMobileScreen() && 180,
            paddingRight: 5,
          }}
          iconSize={16}
          rightIcon={primaryAgent?.mode == "planner" && AppAssets?.icons?.info}
          onRightIconPress={() => {
            if (primaryAgent?.mode == "planner") {
              setShowPlannerModal(true);
            }
          }}
        >
          {constructLabelForAgentTools(primaryAgent)}
        </AppText>
        {planningExecution == "plan_execution_in_progress" && (
          <AppText
            style={{
              fontWeight: "bold",
            }}
            color={currentTheme?.theme?.primary}
            preventTranslation
          >
            {" "}
            Running step: {activeStep || 1} of {totalSteps || 0}
          </AppText>
        )}

        {/* )} */}
      </View>
    );
  }, [
    onSelectedTopicsChange,
    JSON.stringify(selectedTopics),
    JSON.stringify(topics),
    JSON.stringify(primaryAgent),
    isLargeScreen(),
    isMobileScreen(),
    allowStop,
    isAIStreaming,
  ]);
  const agentView = useMemo(() => {
    return (
      <AppText
        style={{
          marginLeft: 10,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: 200,
        }}
        wrapperStyle={{
          marginRight: 20,
          padidngVertical: 10,
          paddingLeft: 10,
        }}
        fontWeight="bold"
        leftIcon={Utility.mapAgentTypeWithIcon(primaryAgent, AppAssets)}
      >{`${primaryAgent?.label}`}</AppText>
    );
  }, [JSON.stringify(primaryAgent)]);
  const chatNameComponent = useMemo(() => {
    const language =
      chatObject?.language && chatObject?.language !== "def"
        ? ` - ${
            state?.languagesConfig?.find((u) => u?.id == chatObject?.language)
              ?.name
          }
          `
        : "";
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: 20,
          flexWrap: "wrap",
        }}
      >
        <AppText
          style={{
            marginHorizontal: 10,

            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: isLargeScreen() ? 600 : 300,
            fontSize: currentTheme?.theme?.large_font_size,
          }}
          wrapperStyle={{
            marginRight: 20,
          }}
          fontWeight="bold"
        >{`${chatObject?.name}${language}`}</AppText>
        <AppText
          leftIcon={
            chatObject?.visibility === "private"
              ? AppAssets.icons.private
              : AppAssets.icons.users
          }
          style={{
            marginLeft: 10,
          }}
          leftIconColor={
            chatObject?.visibility !== "private" && currentTheme?.theme?.orange
          }
          fontWeight="bold"
          color={
            chatObject?.visibility === "private" && currentTheme?.theme?.orange
          }
          wrapperStyle={{
            paddingLeft: 10,
          }}
        >
          {
            // chat visibility to upper first letter
            chatObject?.visibility?.charAt(0).toUpperCase() +
              chatObject?.visibility?.slice(1)
          }
        </AppText>
      </View>
    );
  }, [JSON.stringify(chatObject), state?.languagesConfig, isLargeScreen()]);
  const coreChatView = useMemo(() => {
    return (
      ((primaryAgent?.icon && agentImage) || !primaryAgent?.icon) && (
        <AppQuantumChat
          disableEditingFeedback={!canInteractWithChat}
          allowStop={allowStop}
          agentDefaultIcon={agentImage || require("../../assets/favicon.png")}
          sendOnEnter
          onCopy={handleCopy}
          renderMarkdown
          loadEarlier={allMessagesLoaded}
          messages={messages}
          setMessages={setMessages}
          showAgentName={forceAgentsName || primaryAgent?.showAgentName}
          coder={primaryAgent?.mode === "coder" || forceAgentsName}
          noDelay={true}
          onSendEvent={onSendEvent}
          handleShowSourcesDetails={handleShowSourcesDetails}
          user={{
            id: user?.id,
            name: user?.username,
          }}
          chatStyle={{
            height: height - 200,
          }}
          inverted={false}
          showUserAvatar
          messagesContainerStyle={{
            marginTop: -7,
          }}
          chatid={chatObject?.id}
          allowFeedback={primaryAgent?.allowFeedback}
          handleFeedback={handleFeedback}
          listenToAudio={listenToAudio}
          maxComposerHeight={60}
          composerHeight={60}
          sortOrder="asc"
          placeholderDisabled="The agent is responding..."
          isComposerDisabled={isAIStreaming}
          allowUserToTypeWhileGeneratingResponse={!isAIReplyingOtherUsers}
          onCancel={onCancelGeneration}
          inputText={inputText}
          setInputText={setInputText}
          renderWithVanillaInput={false}
          onLoadEarlier={onLoadEarlier}
          S3downloadUrlRequest={S3downloadUrlRequest}
          workspaceid={workspaceid}
          showDuration={primaryAgent?.showTimeForResponse}
          showSplash={primaryAgent?.showSplashWhenEmpty}
          splashTitle={primaryAgent?.messageOnLaunch}
          showLanguage={primaryAgent?.showDetectedLanguage}
          showProcessingMetadata={true}
          rlhf={
            chatObject?.visibility === "private" ||
            chatObject?.visibility === "widget"
          }
          isLastMessageEmpty={isLastMessageEmpty}
          customChartingConfig={getActiveWorkspace()?.customChartingConfig}
          isChatEmpty={messages?.length == 0}
          onImageSelected={onImageSelected}
          selectedImageKey={selectedImageKey}
          isSpeechGettingProcessed={isSpeechGettingProcessed}
          updateTextForSpeech={updateTextForSpeech}
          showTextToSpeech={primaryAgent?.allowAudiosGeneration}
        >
          <ChatWebInput
            isPlaying={isPlaying}
            isSpeechGettingProcessed={isSpeechGettingProcessed}
            text={inputText}
            setText={setInputText}
            isStreaming={
              (isAIStreaming &&
                (lastUpdateMessageTime == null ||
                  Date.now() - lastUpdateMessageTime < 3000)) ||
              (primaryAgent?.mode == "planner" && isAIStreaming)
            }
            onCancel={onCancelGeneration}
            onSend={onSendEvent}
            stopAudio={stopAudio}
            allowStop={allowStop}
            isLastMessageEmpty={isLastMessageEmpty}
            showWidgetChatsHistory={showWidgetChatsHistory}
            canInteractWithChat={canInteractWithChat}
            isChatEmpty={messages?.length == 0}
            primaryAgentId={primaryAgent?.id}
            quickQuestions={primaryAgent?.quickQuestions
              ?.split(";")
              ?.map((u) => u?.trim()?.replace(/\s+/g, " "))}
            allowImagesUpload={primaryAgent?.allowImagesUpload}
            allowAudiosUpload={primaryAgent?.allowAudiosUpload}
            allowVideosUpload={primaryAgent?.allowVideosUpload}
            isAutoReplyMode={primaryAgent?.autoVoiceMode}
            allowFileUpload={
              primaryAgent?.hasCode || primaryAgent?.allowDocsUpload
            }
            allowCodeUpload={
              primaryAgent?.allowDocsUpload && primaryAgent?.mode == "coder"
            }
            onImageUploaded={onImageUploaded}
            onAudioUploaded={onAudioUploaded}
            onFileUploaded={onFileUploaded}
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            audioUrl={audioUrl}
            setAudioUrl={setAudioUrl}
            onVideoUploaded={onVideoUploaded}
            videoUrl={videoUrl}
            setVideoUrl={setVideoUrl}
            placeholder={
              selectedImageKey
                ? "How would you like to refine the image generated?"
                : "Ask me anything..."
            }
            onFilesUploaded={onFilesUploaded}
            fileUrls={fileUrls}
            setFileUrls={setFileUrls}
          />
        </AppQuantumChat>
      )
    );
  });
  const _treeView = (_coreTreeViewData) => {
    return (isTreeDataLoading || _coreTreeViewData === null) && !isCollapsed ? (
      <LoadingPlaceholder
        style={{
          padding: 10,
          minWidth: 330,
        }}
        visible
        caption="Loading..."
      />
    ) : (
      <AppTreeView
        secondaryActionTitle={
          isUserContentAdmin()
            ? showWidgetChatsHistory
              ? " from widget"
              : " from app"
            : null
        }
        indent={20}
        secondaryActionCallback={handleClickOnWidgetChatsHistory}
        allowParentEdit={false}
        allowParentAction={true}
        onParentActionClick={handleParentActionClick}
        requireNormalisation={false}
        showCollapseTree={true}
        title="Chats"
        treemode="CHATS"
        preventSortingByName={true}
        foldersData={[..._coreTreeViewData]}
        selectedNode={selectedNode}
        handleClickOnNode={handleClickOnNode}
        deleteNode={deleteNode}
        setDialogActionOnTree={setDialogActionOnTree}
        isEmpty={_coreTreeViewData?.length === 0}
        onDrop={onDrop}
        initialOpen={false}
        minWidth={330}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        showIconsOnSelection={!showWidgetChatsHistory}
        onMove={onMove}
        deleteAction="DELETE_NODE"
        editLeafAction="EDIT_CHAT"
        editLeafParentAction="EDIT_CHAT"
        heightOffset={isMoreToLoad ? 185 : 165}
        disableTFDrag={true}
      />
    );
  };
  const _actionsChoice = () => {
    return (
      <ToothFairyResponsiveOptionsModal
        isModalOpen={isModalOptionsOpen}
        setIsModalOpen={setIsModalOptionsOpen}
        modalTitle={"Actions"}
        height={300}
      >
        <ToothFairyClickableOption
          leftIcon={Utility.mapAgentTypeWithIcon(primaryAgent, AppAssets)}
          onPress={() => {
            if (creationOfNewChatInProgress) return;
            setIsModalOptionsOpen(false);
            setDialogActionOnTree("NEW_CHAT");
          }}
        >
          New Chat
        </ToothFairyClickableOption>
      </ToothFairyResponsiveOptionsModal>
    );
  };
  const _coreHeaderComponent = useMemo(() => {
    return (
      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 5 }}
      >
        <ToothFairySupportButton />

        <>
          {/* <AppButtonMenu
            mainActionLabel={"New Chat"}
            onClickMainAction={() => {}}
            wrapperStyle={{
              backgroundColor: currentTheme?.theme?.primary,
              paddingVertical: 5,
              paddingHorizontal: 10,
              borderRadius: 20,
            }}
            labelStyle={{
              fontWeight: "bold",
            }}
            style={{
              top: 35,
              fontFamily: "boldFont",
              minWidth: 130,
              zIndex: 20,
              maxHeight: 300,
              overflowY: "auto",
            }}
            menuItemStyle={{
              marginLeft: 10,
              fontSize: currentTheme?.theme?.small_font_size,
            }}
            color={currentTheme?.theme?.black}
            rightIconColor={currentTheme?.theme?.black}
            onClickMenuItem={(id) => {
              setSelectedAgent(id);
            }}
            options={state?.agents?.map((u) => {
              return {
                label: u?.label,
                id: u?.id,
              };
            })}
          /> */}
          <div
            className="start_chat"
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              !creationOfNewChatInProgress && setDialogActionOnTree("NEW_CHAT")
            }
          >
            <ToothFairyButton
              wrapperStyle={{
                marginLeft: -15,
              }}
              isDisabled={creationOfNewChatInProgress}
              title="New Chat"
            />
          </div>

          <div
            className="upload_docs"
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              history.push(
                `/workspaces/${getActiveWorkspace()?.id}/mrc_contents`
              )
            }
          >
            <ToothFairyButton
              wrapperStyle={{
                marginLeft: -15,
              }}
              title="Upload docs"
            />
          </div>
        </>
      </View>
    );
  }, [setDialogActionOnTree, isLargeScreen(), JSON.stringify(state?.agents)]);
  return (
    <AppBaseScreen
      isScrollable={true}
      contentContainerStyle={{
        overflow: "auto",
        flex: 1,
      }}
    >
      <AppJoyRide
        handleCallback={(e) => {
          if (user?.userHelperPreferences?.knowledgeHubVisited) {
            if (e?.action == "next" && e?.index == 0) {
              updateUserHelperPreferences(user, { chatsTourCompleted: true });
              formRef?.current?.resetForm();
              setTreeViewActionContext("NEW_CHAT");
              setShowModal(true);
            }
          } else if (!user?.userHelperPreferences?.knowledgeHubVisited)
            if (e?.action == "next" && e?.index == 0) {
              history.push(
                `/workspaces/${getActiveWorkspace()?.id}/mrc_contents`
              );
              updateUserHelperPreferences(user, { knowledgeHubVisited: true });
            }
        }}
        run={
          user?.userHelperPreferences?.knowledgeHubVisited != true ||
          user?.userHelperPreferences?.chatsTourCompleted != true
        }
        steps={
          user?.userHelperPreferences?.knowledgeHubVisited
            ? steps_second_interaction
            : steps_first_interaction
        }
        spotlightClicks={true}
      />
      <View
        style={{
          paddingHorizontal: 20,
          paddingTop: 5,
          paddingBottom: 0,
          minWidth: minAppWidth(),
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            maxWidth: isMobileScreen() && 300,
          }}
        >
          {_coreHeaderComponent}
        </View>
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            zIndex: -1,
          }}
        >
          <View
            style={{
              flexDirection: "column",
              height: "100%",
            }}
          >
            {_treeView(_coreTreeViewData)}
            {isMoreToLoad &&
              !isTreeDataLoading &&
              _coreTreeViewData?.length > 0 &&
              !isCollapsed && (
                <AppText
                  wrapperStyle={{
                    alignSelf: "center",
                    marginTop: 5,
                  }}
                  isClickable
                  style={{
                    color: currentTheme?.theme?.primary,
                  }}
                  fontWeight="bold"
                  onPress={async () => {
                    setIsChatHistoryLoaded(false);
                    await loadChats(showWidgetChatsHistory);
                    setIsChatHistoryLoaded(true);
                  }}
                >
                  Load more
                </AppText>
              )}
          </View>

          {errorWithSubscription ? (
            <AppPlaceholderView
              accessibilityLabel="chat-placeholder"
              wrapperStyle={{
                width: 400,
                height: 200,
                marginVertical: "auto",
                marginHorizontal: "auto",
              }}
              size={60}
              icon={AppAssets.icons.orbit}
              mainCaption={"ChatSubscriptionErrorMainCaption"}
              subCaption={"ChatSubscriptionErrorSubCaption"}
            />
          ) : (isLoading || !chatObject?.name) && chatId ? (
            <LoadingPlaceholder
              style={{
                padding: 10,
              }}
              visible
              caption="Loading chat"
            />
          ) : chatId ? (
            <View style={{ flex: 1, minWidth: minAppWidth() - 40 }}>
              <View
                style={{
                  zIndex: 3,
                }}
              >
                {chatNameComponent}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingVertical: 0,
                    zIndex: 3,
                    minHeight: 78,
                    flexWrap: "wrap",
                    paddingBottom: isMobileScreen() && 10,
                  }}
                >
                  {documentId ? (
                    <AppText
                      wrapperStyle={{
                        paddingHorizontal: 10,
                        paddingVertical: 28,
                      }}
                      leftIcon={AppAssets.icons?.notes}
                      style={{
                        marginLeft: 10,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: isMobileScreen() && 200,
                      }}
                    >
                      {documents.find((u) => u?.id == documentId)?.title ||
                        "Document unavailable or in learning status"}
                    </AppText>
                  ) : (
                    topicSelectionComponent
                  )}
                  {agentView}
                </View>
              </View>
              {coreChatView}
            </View>
          ) : (
            <AppPlaceholderView
              accessibilityLabel="chat-placeholder"
              wrapperStyle={{
                width: 300,
                height: 200,
                marginVertical: "auto",
                marginHorizontal: "auto",
              }}
              size={60}
              icon={AppAssets.icons.orbit}
              mainCaption={
                chatId ? "ChatDoesNotExistCaption" : "SelectChatCaption"
              }
              subCaption={
                chatId ? "ChatDoesNotExistSubCaption" : "SelectChatSubCaption"
              }
            />
          )}
        </View>
      </View>
      {_coreModalView}
      {_coreSourcesModelView}
      {_plannerModalView}
      {_actionsChoice()}
    </AppBaseScreen>
  );
};

export default ChatsScreenView;
