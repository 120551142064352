import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { View, useWindowDimensions } from "react-native";
import { showSideMenu } from "../../../../../App";
import AgentFunctions from "../../../../API/AgentFunctions";
import ToothFairyChoice from "../../../../Components/ToothFairyComponents/ToothFairyChoice";
import ToothFairyModal from "../../../../Components/ToothFairyComponents/ToothFairyModal";
import { isLargeScreen } from "../../../MainViewContainer";
import ChannelsSettingsAI from "./ChannelsSettingsAI";
import ChannelsSettingsToolbar from "./ChannelsSettingsToolbar";
import channelsHelper from "../../../../API/Channels/channelsHelper";
import useWorkspaces from "../../../../Hooks/useWorkspaces";

const ChannelsList = ({ isFunctionsLoading, setDisplayedView }) => {
  const formRef = useRef();
  const { currentTheme } = AppTheme.useTheme();
  const { width } = useWindowDimensions();
  const { state, dispatch } = AppStateManager.useAppStateManager();
  const { getActiveWorkspace } = useWorkspaces();
  const [showHostingModal, setShowHostingModal] = React.useState(false);
  const [showChoice, setShowChoice] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageSize = 50;
  const [displayedChannels, setDisplayedChannels] = React.useState([]);
  const { t } = useTranslation();
  const AppToasty = AppToast.useToast();
  const { height } = useWindowDimensions();
  const [initialMapValues, setInitialMapValues] = useState({
    name: "",
    description: "",
    channel: null,
    provider: null,
    senderid: "",
    fromNumber: "",
    tokenSecret: "",
    isActive: true,
  });
  const columns = [
    {
      title: "ID",
      width: 0.2,
    },
    {
      title: "Name",
      width: 0.25,
    },
    {
      title: "Description",
      width: 0.25,
    },
    {
      title: "Channel",
      width: 0.25,
    },
    {
      title: "Actions",
      isAction: true,
      width: 0.1,
    },
  ];

  const onChannelCreated = useCallback(
    (event) => {
      if (event === "success") {
        AppToasty.show(t("channelCreationSuccessCaption"), {
          type: "success",
        });
        loadChannels(state?.activeWorkspaceID);
        setShowHostingModal(false);
      } else
        AppToasty.show(t("channelCreationFailedCaption"), {
          type: "danger",
        });
      formRef?.current?.resetForm();
      setShowHostingModal(false);
      setSelectedIndex(null);
    },
    [AppToasty, t, loadChannels, state?.activeWorkspaceID]
  );
  const loadChannels = async (workspaceID) => {
    const channelsData = await channelsHelper.getChannels(workspaceID, null);
    console.log("channelsData", channelsData);
    let channels = channelsData?.data?.CommunicationServicesByWorkspace?.items;
    channels =
      channels
        ?.map((u) => {
          if (u?._deleted !== true)
            return {
              ...u,
            };
        })
        ?.filter((u) => u != null) || [];
    dispatch("SET_CHANNELS", channels || []);

    setDisplayedChannels(channels);
  };
  useEffect(() => {
    loadChannels(state?.activeWorkspaceID);
  }, [state?.activeWorkspaceID]);
  useEffect(() => {
    setDisplayedChannels([...state?.channels]);
  }, [JSON.stringify([...state?.channels])]);

  const hostingItemCreationComponent = useMemo(() => {
    return (
      <ToothFairyModal
        key={Date.now().toString()}
        modalStyle={{
          maxHeight: "95%",
          height: "auto",
          maxWidth: isLargeScreen() ? 700 : 350,
          marginHorizontal: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          margin: "auto",
        }}
        isModalOpen={showHostingModal}
        setIsModalOpen={setShowHostingModal}
        modalTitle={isEditMode ? "channelEditTitle" : "channelCreationTitle"}
        closeModal={() => {
          formRef?.current?.resetForm();
          setShowHostingModal(false);
          setSelectedIndex(null);
        }}
      >
        <ChannelsSettingsAI
          ref={formRef}
          initialMapValues={initialMapValues}
          onChannelCreated={onChannelCreated}
          isEdit={isEditMode}
          selectedId={displayedChannels[selectedIndex]?.id}
          opt={displayedChannels[selectedIndex]}
          setDisplayedView={setDisplayedView}
        />
      </ToothFairyModal>
    );
  }, [
    showHostingModal,
    setShowHostingModal,
    initialMapValues,
    isEditMode,
    selectedIndex,
    displayedChannels,
    isLargeScreen(),
  ]);
  const identifyOptByID = (id) => {
    return state.channels.find((u) => u.id === id);
  };
  const handleHostingItemSelection = ({ item, index }) => {
    setSelectedIndex(index);
    setIsEditMode(true);
    let extendedItem = identifyOptByID(item.id);
    setInitialMapValues({
      ...extendedItem,
    });

    setShowHostingModal(true);
  };
  const handleHostingItemDeletion = async (item, index) => {
    try {
      setShowChoice(false);
      if (selectedIndex !== null) {
        const functionsData = await channelsHelper.getChannels(
          state?.activeWorkspaceID,
          null
        );
        let functions =
          functionsData?.data?.CommunicationServicesByWorkspace?.items;
        functions =
          functions
            ?.map((u) => {
              if (u?.llmProvider === displayedChannels[selectedIndex]?.id)
                return {
                  ...u,
                };
            })
            ?.filter((u) => u != null) || [];
        if (functions.length > 0) {
          AppToasty.show(t("hostingDeletionErrorCaptionDueToConflict"), {
            type: "danger",
          });
          return;
        }
        const _id = displayedChannels[selectedIndex]?.id;
        const result = await channelsHelper.deleteChannel(_id);
        dispatch("SET_CHANNELS", [
          ...state?.channels.filter((u) => u.id !== _id),
        ]);
        AppToasty.show(t("hostingDeletionSuccessCaption"), {
          type: "success",
        });
      } else {
        throw new Error("No item selected");
      }
    } catch (error) {
      console.log(error);
      AppToasty.show(t("authorisationDeletionErrorCaption"), {
        type: "danger",
      });
    }
  };
  const handleSearch = async (name, description, host, type) => {
    // find items in the list which cointain either the sourceText or targetText
    const filteredItems = state?.channels.filter((item) => {
      return (
        item?.name?.toLowerCase()?.includes(name?.toLowerCase()) &&
        item?.description?.toLowerCase()?.includes(description?.toLowerCase())
      );
    });
    setDisplayedChannels(filteredItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleCreation = () => {
    setInitialMapValues({
      name: "",
      description: "",
      channel: null,
      provider: null,
      senderid: "",
      fromNumber: "",
      tokenSecret: "",
      isActive: true,
    });
    setIsEditMode(false);
    setShowHostingModal(true);
  };
  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"hostingsItemCancelConfirmation"}
        caption="hostingsItemCancelCaption"
        subCaption="hostingsItemCancelSubCaption"
        confirmCaption={"hostingsItemDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={() => handleHostingItemDeletion()}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [setShowChoice, showChoice, selectedItem, setSelectedItem]);

  return (
    <View
      style={{
        paddingLeft: 10,
        flex: 1,
        width: showSideMenu() ? width - (showSideMenu() ? 410 : 220) : "100%",
        minWidth: "100%",
      }}
    >
      <>
        <ChannelsSettingsToolbar
          handleSearch={handleSearch}
          handleCreation={handleCreation}
        />
        {isFunctionsLoading ? (
          <AppActivityIndicator
            backgroundColor={currentTheme?.theme?.softBackgroundColor}
            visible={true}
            containerStyle={{
              backgroundColor: currentTheme?.theme?.transparent,
              minHeight: 250,
            }}
          />
        ) : (
          <>
            {displayedChannels.length > 0 ? (
              <AppTable
                actionButtons
                handleDocumentSelection={handleHostingItemSelection}
                handleDocumentDeletion={(item, index) => {
                  setSelectedItem(item);
                  setSelectedIndex(index);
                  setShowChoice(true);
                }}
                style={{
                  zIndex: -1,
                  maxHeight: height - 180,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                headers={columns}
                items={displayedChannels
                  ?.slice(
                    (currentPage - 1) * pageSize,
                    (currentPage - 1) * pageSize + pageSize
                  )
                  ?.map((u) => {
                    return {
                      id: u?.id || " ",
                      name: u?.name || " ",
                      description: u?.description || " ",
                      channel: u?.channel?.toUpperCase() || " ",
                      actions: " ",
                    };
                  })}
              />
            ) : (
              <AppPlaceholderView
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  marginVertical: 60,
                  marginHorizontal: "auto",
                  flex: 1,
                  zIndex: -1,
                }}
                size={80}
                icon={AppAssets.icons.orbit}
                mainCaption="EmptyPlaceholder"
                subCaption="ChannelsSubCaption"
              />
            )}
            {displayedChannels?.length > pageSize && (
              <AppPagination
                onPageChange={handlePageChange}
                totalCount={displayedChannels?.length}
                siblingCount={1}
                currentPage={currentPage}
                pageSize={pageSize}
                wrapperStyle={{
                  marginTop: 10,
                  alignSelf: "flex-end",
                }}
              />
            )}
          </>
        )}
      </>

      {hostingItemCreationComponent}
      {_coreChoice}
    </View>
  );
};

export default ChannelsList;
