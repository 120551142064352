import AppForms from "@toothfairy/shared-ui/AppForms";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import React from "react";
import { Platform, View } from "react-native";
import Constants from "../../../Constants";
import countries from "../../../Constants/countries.json";
import useSections from "../../../Hooks/useSections";
import { isLargeScreen } from "../../MainViewContainer";

const addressFormValidationSchema = AppForms.yup.object().shape({
  name: AppForms.yup.string().required().label("Your name"),
  addressLine1: AppForms.yup.string().required().label("Address"),
  addressLine2: AppForms.yup.string().nullable(),
  postalCode: AppForms.yup.string().required().label("Postal code"),
  city: AppForms.yup.string().required().label("City"),
  state: AppForms.yup.string().nullable().label("State"),
  country: AppForms.yup.string().required().label("Country"),
  // vatId: AppForms.yup.string().nullable()
});

const BillingAddress = ({
  stripeCustomerData,
  saveCustomerDetails,
  isWizard,
  saveInProgress,
  goToPortal,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  return (
    <AppForms.AppForm
      style={{
        minWidth: Platform.OS === "web" && isLargeScreen() ? 350 : 250,
        maxWidth: !isLargeScreen() && 250,
        alignSelf: !isLargeScreen() && "center",
      }}
      initialValues={{
        name: stripeCustomerData?.name || "",
        addressLine1: stripeCustomerData?.address?.line1,
        addressLine2: stripeCustomerData?.address?.line2,
        postalCode: stripeCustomerData?.address?.postal_code,
        city: stripeCustomerData?.address?.city,
        state: stripeCustomerData?.address?.state,
        country: stripeCustomerData?.address?.country,
      }}
      enableReinitialize
      onSubmit={saveCustomerDetails}
      validationSchema={addressFormValidationSchema}
    >
      <AppForms.AppFormField
        showTextInputLabel
        label="Name"
        isMandatory
        name="name"
      />

      <AppForms.AppFormField
        showTextInputLabel
        label="Address"
        isMandatory
        name="addressLine1"
      />

      <AppForms.AppFormField name="addressLine2" />
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <AppForms.AppFormField
          formFieldContainerStyle={{
            flex: 1,
          }}
          showTextInputLabel
          label="Postal code"
          isMandatory
          name="postalCode"
        />
        <AppForms.AppFormField
          formFieldContainerStyle={{
            flex: 1,
          }}
          showTextInputLabel
          label="State"
          isMandatory
          name="state"
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <AppForms.AppFormField
          formFieldContainerStyle={{
            flex: 1,
            paddingBottom: 0,
          }}
          showTextInputLabel
          label="City"
          isMandatory
          name="city"
        />

        <AppForms.AppFormDropDown
          formFieldContainerStyle={{
            flex: 1,
          }}
          isElevated={false}
          dropDownStyle={{
            borderColor: currentTheme?.theme?.border_color,
            maxWidth: !isLargeScreen() && 250,
          }}
          showTextInputLabel
          label="Country"
          isMandatory
          name="country"
          values={countries}
          items={Object.entries(countries).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
        />
      </View>
      <AppForms.AppSubmitButton
        isDisabled={saveInProgress}
        isEnabledOnFilled={isWizard}
        awesomeIconColor={currentTheme?.theme?.white}
        backgroundColor={currentTheme?.theme?.primary}
        btnContentStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        style={[
          {
            height: Constants.appButtonHeight,
            width: "auto",
            shadowColor: currentTheme?.theme?.transparent,
            marginHorizontal: 10,
          },
        ]}
        btnLabelStyle={{ fontSize: 16 }}
        wrapperStyle={[
          {
            width: "fit-content",
            minWidth: 150,
            marginTop: 20,
            height: Constants.appButtonHeight,
            alignSelf: "center",
          },
        ]}
        title={isWizard ? "Next" : "Save"}
      />
    </AppForms.AppForm>
  );
};

export default BillingAddress;
