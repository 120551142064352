import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppToast from "@toothfairy/shared-ui/AppToast";
import AppUser from "@toothfairy/shared-ui/AppUser";
import React, { useCallback, Suspense } from "react";
import workspacesHelper from "../../API/Workspaces/workspacesHelper";
import { SectionsProvider } from "../../Hooks/useSections";
import useWorkspaces from "../../Hooks/useWorkspaces";
import SettingsScreen from "./SettingsScreen";
import { useTranslation } from "react-i18next";
import { useHistory } from "../../Router";
import usePath from "../../Hooks/usePath";
import { userType } from "../../../App";

const SettingsScreenContainer = () => {
  const {
    updateWorkspaceInContext,
    getActiveWorkspace,
    getLastTabSelectedByScreen,
    getLastOptionSelectedByScreen,
    handleSectionSelection,
    isUserAdmin,
    isUserAIEngineer,
    getPlanConfig,
  } = useWorkspaces();
  const history = useHistory();
  const { params } = usePath();
  const AppToasty = AppToast.useToast();
  const { state } = AppStateManager.useAppStateManager();
  const { user } = AppUser.useUser();
  const { t } = useTranslation();
  const _lastTabSelected = getLastTabSelectedByScreen("settings", "INTENT");
  const lastOptionSelected = getLastOptionSelectedByScreen("settings");
  const _isLoaded = params.sectionId && getActiveWorkspace()?.userID;
  let _defaultView, _defaultOptId;
  if (_isLoaded) {
    _defaultView = params.sectionId?.toUpperCase() || _lastTabSelected;
    _defaultOptId = params.optId || lastOptionSelected;
    if (!_defaultView) {
      _defaultView = "ROLES_SETTINGS";
    }
  }

  const optionsDataGenerator = useCallback(() => {
    let options = [];
    if (!userType()) return [];
    options = [
      {
        isNavigation: true,
        icon: AppAssets.icons.bookmark,
        uid: "TOPIC",
        label: "Topic settings",
        subOptions: [],
        supportVisible: true,
      },
    ];
    if (isUserAIEngineer()) {
      options = [
        {
          isNavigation: true,
          icon: AppAssets.icons.answerer,
          uid: "ROLES_SETTINGS",
          label: "Agents",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.writer,
          uid: "GENERATOR_SETTINGS",
          label: "Prompts",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "TOPIC",
          label: "Topics",
          subOptions: [],
          supportVisible: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "FUNCTIONS",
          label: "Functions",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "DB_CONNECTIONS",
          label: "Connections",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "AUTHORISATIONS",
          label: "Authorisations",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "HOSTINGS",
          label: "Providers",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "CHANNELS",
          label: "Channels",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "EXEC_ENVS",
          label: "Environments",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "SITES",
          label: "Domains",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
        {
          isNavigation: true,
          icon: AppAssets.icons.bookmark,
          uid: "DICTIONARY",
          label: "Dictionaries",
          subOptions: [],
          supportVisible: true,
          fullWidth: true,
          aiEngineerOnly: true,
        },
      ];
      if (
        getPlanConfig()?.isTrainingNLPEnabled ||
        getPlanConfig()?.isTrainingGNEnabled ||
        getPlanConfig()?.isTrainingQAEnabled
      ) {
        options.push({
          isNavigation: true,
          icon: AppAssets.icons.trainAI,
          uid: "TRAINING",
          label: "Training",
          subOptions: [],
          supportVisible: true,
          aiEngineerOnly: true,
        });
      }

      options.push(
        ...[
          {
            isNavigation: true,
            icon: AppAssets.icons.trainAI,
            isBackHidden: false,
            uid: "INTENT",
            label: "Intents",
            subOptions: [],
            supportVisible: true,
            aiEngineerOnly: true,
          },
          {
            isNavigation: true,
            icon: AppAssets.icons.ai,
            uid: "NER",
            label: "NERs",
            subOptions: [],
            supportVisible: true,
            aiEngineerOnly: true,
          },
        ]
      );
    }
    if (getActiveWorkspace()?.id) return options;
  }, [
    JSON.stringify(getActiveWorkspace()),
    JSON.stringify(state.aiEntitiesConfig),
    userType(),
  ]);
  const handleWorkspaceUpdate = async (property, value) => {
    try {
      const result = await workspacesHelper.updateWorkspace(
        state?.activeWorkspaceID,
        [{ property, value }]
      );
      updateWorkspaceInContext(result);
      AppToasty.show(t("WorkspaceUpdateCaption"), {
        type: "success",
      });
    } catch (error) {
      AppToasty.show(t("WorkspaceUpdateFailedCaption"), {
        type: "danger",
      });
      console.error("An error occured while updating the workspace", error);
    }
  };
  return _defaultView ? (
    <SectionsProvider
      onDisplayedViewChanged={(section, opt_id) => {
        // check if opt_id is a string
        let _appendix = "";
        if (section?.includes("_EDIT") || section == "ROLES_SETTINGS") {
          if (typeof opt_id === "string") _appendix = `${opt_id}`;
          else if (section == "ROLES_SETTINGS" && opt_id) _appendix = opt_id;
          else
            _appendix =
              opt_id &&
              (opt_id?.type === "topic" ||
                opt_id?.type === "ner" ||
                opt_id?.type === "intent")
                ? opt_id?.id
                : "";
        }
        handleSectionSelection(section, "settings");
        if (state.activeWorkspaceID && section && section !== "MAIN")
          history.push(
            `/workspaces/${
              state.activeWorkspaceID
            }/settings/${section.toLowerCase()}/${_appendix}`
          );
      }}
      optionsDataGenerator={optionsDataGenerator}
      defaultViewUID={_defaultView}
      defaultOptionUID={_defaultOptId}
      sectionsMainTitle="Workspace settings"
    >
      <Suspense fallback={<div>Loading...</div>}>
        <SettingsScreen
          workspace={state?.workspaces?.find(
            (u) => u?.id === state?.activeWorkspaceID
          )}
          handleWorkspaceUpdate={handleWorkspaceUpdate}
        />
      </Suspense>
    </SectionsProvider>
  ) : (
    <></>
  );
};

export default SettingsScreenContainer;
