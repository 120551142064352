import API from "@aws-amplify/api";
import {
  createAuthorisation,
  deleteAuthorisation,
  updateAuthorisation,
} from "../../graphql/mutations";
import {
  authorisationsByWorkspace,
  getAuthorisation,
} from "../../graphql/queries";
const createAuthorisation_ = async (object) => {
  return API.graphql({
    query: createAuthorisation,
    variables: { input: object },
  });
};
const getAuthorisation_ = async (id) => {
  return API.graphql({
    query: getAuthorisation,
    variables: { id },
  });
};
const updateAuthorisation_ = async (id, _properties = {}) => {
  const original = await getAuthorisation_(id);
  return API.graphql({
    query: updateAuthorisation,
    variables: {
      input: {
        id,
        _version: original?.data?.getAuthorisation?._version,
        ..._properties,
      },
    },
  });
};

const deleteAuthorisation_ = async (id) => {
  const original = await getAuthorisation_(id);
  return API.graphql({
    query: deleteAuthorisation,
    variables: {
      input: {
        id,
        _version: original?.data?.getAuthorisation?._version,
      },
    },
  });
};

const getAuthorisations_ = async (workspaceID, nextToken) => {
  return API.graphql({
    query: authorisationsByWorkspace,
    variables: {
      workspaceID,
      nextToken,
    },
  });
};

export default {
  createAuthorisation: createAuthorisation_,
  updateAuthorisation: updateAuthorisation_,
  deleteAuthorisation: deleteAuthorisation_,
  getAuthorisation: getAuthorisation_,
  getAuthorisations: getAuthorisations_,
};
