import React from "react";
import AccessDeniedScreen from "../Screens/AccessDenied";
import AccountScreenContainer from "../Screens/Account/AccountScreenContainer";
import AdminScreenContainer from "../Screens/Admin/AdminScreenContainer";
import AnswererScreenContainer from "../Screens/Answerer/AnswererScreenContainer";
import BatchScreenContainer from "../Screens/Batch/BatchScreenContainer";
import DashboardScreenContainer from "../Screens/Dashboard/DashboardScreenContainer";
import DocumentsScreenContainer from "../Screens/Documents/DocumentsScreenContainer";
import GeneratorScreenContainer from "../Screens/Generator/GeneratorScreenContainer";
import MrcDocumentsContainer from "../Screens/MrcDocuments/MrcDocumentsContainer";
import PageNotFound from "../Screens/PageNotFound";
import SettingsScreenContainer from "../Screens/Settings/SettingsScreenContainer";
import TestAnExampleScreenContainer from "../Screens/TestExample/TestAnExampleScreenContainer";
import TrainingScreenContainer from "../Screens/Training/TrainingScreenContainer";
import WorkspacesScreenContainer from "../Screens/Workspaces/WorkspacesScreenContainer";
import ChatsScreenContainer from "../Screens/Chats/ChatsScreenContainer";
import AdminRoute from "./AdminRoute";
import { Redirect, Route, Switch } from "./index";
import ProRoute from "./ProRoute";
import PowerUserRoute from "./PowerUserRoute";
import AIEngineerRoute from "./AIEngineerRoute";
import ContentCreatorRoute from "./ContentCreatorRoute";
import SentimentReviewerRoute from "./SentimentReviewerRoute";
import BusinessAnalystRoute from "./BusinessAnalystRoute";
import TFRoute from "./TFRoute";
import ErrorPage from "../Screens/ErrorPage";
import Controller from "../Screens/Arena/Controller";
import DashboardContainer from "../Screens/Arena/Dashboard/DashboardContainer";

export const routes = {
  dashboard: "/workspaces/:workspaceId/dashboard/:sectionId?",
  nlp: "/workspaces/:workspaceId/nlp/:documentId?",
  answerer: "/workspaces/:workspaceId/answerer/:documentId?",
  rc_training: "/workspaces/:workspaceId/documents/rc/:documentId",
  gn_training: "/workspaces/:workspaceId/documents/:type/:documentId",
  documents: "/workspaces/:workspaceId/documents/:sectionId?",
  chats: "/workspaces/:workspaceId/chats/:chatId?",
  mrc_contents: "/workspaces/:workspaceId/mrc_contents/:documentId?",
  generator: "/workspaces/:workspaceId/generator/:documentId?",
  training: "/workspaces/:workspaceId/training/:sectionId?",
  batch: "/workspaces/:workspaceId/batch/:sectionId?",
  settings: "/workspaces/:workspaceId/settings/:sectionId?/:optId?",
  profile: "/profile/:sectionId?",
  workspaces: "/workspaces",
  admin: "/workspaces/:workspaceId/admin/:sectionId?",
  arena: "/workspaces/:workspaceId/arena/:sectionId?",
  arenaLeaderboard: "/workspaces/:workspaceId/arena_leaderboard",
  pagenotfound: "/pagenotfound",
  accessDenied: "/accessdenied",
  landingPage: "/workspaces/:workspaceId",
  error: "/error",
};

const Routes = ({ currentView, setCurrentView }) => {
  return (
    <Switch>
      <SentimentReviewerRoute
        path={routes.nlp}
        exact
        component={TestAnExampleScreenContainer}
      />
      <BusinessAnalystRoute
        path={routes.answerer}
        exact
        component={AnswererScreenContainer}
      />
      <BusinessAnalystRoute
        path={routes.rc_training}
        exact
        component={AnswererScreenContainer}
      />
      <Route
        path={routes.mrc_contents}
        exact
        component={MrcDocumentsContainer}
      />
      <ContentCreatorRoute
        path={routes.generator}
        exact
        component={GeneratorScreenContainer}
      />
      <ContentCreatorRoute
        path={routes.gn_training}
        exact
        component={GeneratorScreenContainer}
      />
      <AIEngineerRoute
        path={routes.documents}
        exact
        component={DocumentsScreenContainer}
      />
      <Route path={routes.chats} exact component={ChatsScreenContainer} />

      <AIEngineerRoute
        path={routes.training}
        exact
        component={TrainingScreenContainer}
      />
      <ProRoute path={routes.batch} exact component={BatchScreenContainer} />
      <Route path={routes.profile} exact>
        <AccountScreenContainer setCurrentView={setCurrentView} />
      </Route>
      <Route
        path={routes.workspaces}
        exact
        component={WorkspacesScreenContainer}
      />
      <PowerUserRoute
        path={routes.settings}
        exact
        component={SettingsScreenContainer}
      />
      <Route exact path="/">
        {currentView !== "auth" && <Redirect to={routes.workspaces} />}
      </Route>
      <Route exact path="/signUp">
        {currentView !== "auth" && <Redirect to={routes.workspaces} />}
      </Route>
      <Route exact path="/google_signup">
        {currentView !== "auth" && <Redirect to={routes.workspaces} />}
      </Route>
      <Route exact path="/code_confirmation/:token?">
        {currentView !== "auth" && <Redirect to={routes.workspaces} />}
      </Route>
      <AdminRoute
        path={routes.dashboard}
        exact
        component={DashboardScreenContainer}
        isDashboard={true}
      />
      <AdminRoute path={routes.arena} exact component={Controller} />
      <AdminRoute
        path={routes.arenaLeaderboard}
        exact
        component={DashboardContainer}
      />
      {/* component={DashboardScreenContainer} */}
      {/* /> */}
      <Route path={routes.accessDenied} exact component={AccessDeniedScreen} />
      <AdminRoute path={routes.admin} exact component={AdminScreenContainer} />
      <Route path={routes.error} exact component={ErrorPage} />
      <TFRoute path={routes.landingPage} exact component={() => <></>} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
