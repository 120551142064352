import AppTextInput from "@toothfairy/shared-ui/AppTextInput";
import React from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../../Components/ToothFairyComponents/ToothFairyButton";

const FunctionSettingsToolbar = ({
  searchBtnLabel = "Search",
  searchBtnAccLabel = "searchButton",
  handleSearch = () => {},
  handleCreation = () => {},
}) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [status, setStatus] = React.useState("");
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: -10,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <AppTextInput
          value={url}
          onChangeText={setUrl}
          style={{
            fontSize: 16,
            marginLeft: 0,
          }}
          placeholder="Domain"
        />
      </View>
      <ToothFairyButton
        wrapperStyle={{ marginTop: 10 }}
        title={searchBtnLabel}
        accessibilityLabel={searchBtnAccLabel}
        onPress={() => handleSearch(url)}
      />
      <ToothFairyButton
        wrapperStyle={{
          alignSelf: "center",
          marginTop: 10,
        }}
        title="Add domain"
        onPress={handleCreation}
      />
    </View>
  );
};

export default FunctionSettingsToolbar;
