export const initialState = {
  NERStyles: [
    {
      backgroundColor: "#ffff00",
      icon: "🏛️",
      label: "PLACE",
    },
    {
      backgroundColor: "#ff0000",
      icon: "🙋",
      label: "PERSON",
    },
    {
      backgroundColor: "#00ff00",
      icon: "👪",
      label: "ORG",
    },
    {
      backgroundColor: "#0000ff",
      icon: "🎓",
      label: "TITLE",
    },
    {
      backgroundColor: "#808080",
      icon: "📅",
      label: "TIME",
    },
  ],
};
export const initialEditorValueForAnswerer = [
  {
    type: "title",
    children: [{ text: "Document title" }],
  },
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
export const initialEditorValueForQuestions = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
export const generateInitialEditorValueWithTitle = (title) => {
  return [
    {
      type: "title",
      children: [{ text: title }],
    },
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];
};
export const initialEditorValue = [
  {
    type: "title",
    children: [{ text: "Document title" }],
  },
  {
    type: "paragraph",
    isParsed: false,
    children: [{ text: "" }],
  },
];

export const emptyEditorValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

export const emptyTitleValue = [
  {
    type: "title",
    children: [{ text: "No Title" }],
  },
];

export const generateEditorValueFromSentence = (sentence) => [
  {
    type: "title",
    children: [{ text: "Imported Document" }],
  },
  {
    type: "paragraph",

    children: [{ text: sentence }],
  },
];

const NERStyles = [
  {
    backgroundColor: "#ffff00",
    icon: "🏛️",
    label: "PLACE",
  },
  {
    backgroundColor: "#ff0000",
    icon: "🙋",
    label: "PERSON",
  },
  {
    backgroundColor: "#00ff00",
    icon: "👪",
    label: "ORG",
  },
  {
    backgroundColor: "#0000ff",
    icon: "🎓",
    label: "TITLE",
  },
  {
    backgroundColor: "#808080",
    icon: "📅",
    label: "TIME",
  },
];

export const initialValue = [
  // {
  //   type: "title",
  //   children: [{ text: "This is the NER and Intent Training test" }],
  // },
  // {
  //   type: "paragraph",
  //   children: [{ text: "This is the first line" }],
  // },
  {
    type: "paragraph",
    children: [{ text: "I want to go out with Jona and Gab today" }],
  },
  {
    type: "paragraph",
    children: [{ text: "Try it out for yourself!" }],
  },
];
