import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppExpander from "@toothfairy/shared-ui/AppExpander";
import React, { useEffect } from "react";
import { View } from "react-native";
import trainingAPI from "../../../API/Training/trainingAPI";
import LoadingPlaceholder from "../../../Components/LoadingPlaceholder";
import useWorkspaces from "../../../Hooks/useWorkspaces";
import FunctionLeaf from "./FunctionLeaf";
import ReferenceLeaf from "./ReferenceLeaf";
import ChatScriptLeaf from "./ChatScriptLeaf";
import NerViewComponent from "../../MrcDocuments/Components/NerViewComponent";

const ReferencesView = ({ selectedSources }) => {
  const { getActiveWorkspace } = useWorkspaces();
  const [references, setReferences] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const fetchSources = async () => {
    try {
      setIsLoading(true);
      setReferences([]);
      const promises = selectedSources?.docs?.map((source) => {
        return trainingAPI.getEmebddingById(
          source.id,
          getActiveWorkspace()?.id
        );
      });
      const results = await Promise.all(promises);
      setReferences(
        results?.map((result) => {
          return result?.data?.data;
        })
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedSources?.docs?.length > 0) {
      fetchSources();
    } else {
      setReferences([]);
    }
  }, [JSON.stringify(selectedSources)]);
  const dataSources = selectedSources?.fn?.filter((u) => u?.type == "api");
  const dbSources = selectedSources?.fn?.filter((u) => u?.type == "db");
  const chatFunctions = selectedSources?.fn?.filter(
    (u) =>
      u?.type == "suggestion" ||
      u?.type == "nextQuestion" ||
      u?.type == "script"
  );
  const htmlFunctions = selectedSources?.fn?.filter((u) => u?.type == "html");
  return isLoading ? (
    <LoadingPlaceholder visible caption="Loading..." />
  ) : (
    <View>
      {
        <AppExpander
          expanderStyle={{
            padding: 0,
          }}
          style={{ minWidth: 250 }}
          isExpandedOnLoad={references?.length > 0}
          title={`Documentation (${references?.length || 0})`}
          icon={AppAssets.icons.notes}
          isEnabled={references?.length > 0}
        >
          {references?.map((reference, i) => {
            return (
              <View
                style={{
                  paddingLeft: 0,
                }}
              >
                <ReferenceLeaf
                  key={`${i}-${Date.now()?.toString()}`}
                  {...reference}
                  selectedSources={selectedSources?.docs}
                />
                <NerViewComponent
                  showHover={false}
                  chunk_id={i}
                  ners={reference?.ners}
                />
              </View>
            );
          })}
        </AppExpander>
      }

      <AppExpander
        expanderStyle={{
          padding: 0,
        }}
        style={{ minWidth: 250 }}
        isExpandedOnLoad={dataSources?.length > 0}
        title={`API functions (${dataSources?.length || 0})`}
        icon={AppAssets.icons.api}
        isEnabled={dataSources?.length > 0}
      >
        {dataSources?.map((fn, i) => {
          return (
            <FunctionLeaf key={`${i}-${Date.now()?.toString()}`} fn={fn} />
          );
        })}
      </AppExpander>

      <AppExpander
        expanderStyle={{
          padding: 0,
        }}
        style={{ minWidth: 250 }}
        isExpandedOnLoad={dbSources?.length > 0}
        title={`DB functions (${dbSources?.length || 0})`}
        icon={AppAssets.icons.data}
        isEnabled={dbSources?.length > 0}
      >
        {dbSources?.map((fn, i) => {
          return (
            <FunctionLeaf key={`${i}-${Date.now()?.toString()}`} fn={fn} />
          );
        })}
      </AppExpander>

      <AppExpander
        expanderStyle={{
          padding: 0,
        }}
        style={{ minWidth: 250 }}
        isExpandedOnLoad={chatFunctions?.length > 0}
        title={`Chat functions (${chatFunctions?.length || 0})`}
        icon={AppAssets.icons.quantumChat}
        isEnabled={chatFunctions?.length > 0}
      >
        {chatFunctions?.map((fn, i) => {
          return (
            <ChatScriptLeaf key={`${i}-${Date.now()?.toString()}`} fn={fn} />
          );
        })}
      </AppExpander>
      <AppExpander
        expanderStyle={{
          padding: 0,
        }}
        style={{ minWidth: 250 }}
        isExpandedOnLoad={htmlFunctions?.length > 0}
        title={`HTML functions (${htmlFunctions?.length || 0})`}
        icon={AppAssets.icons.html}
        isEnabled={htmlFunctions?.length > 0}
      >
        {htmlFunctions?.map((fn, i) => {
          return (
            <ChatScriptLeaf key={`${i}-${Date.now()?.toString()}`} fn={fn} />
          );
        })}
      </AppExpander>
    </View>
  );
};

export default ReferencesView;
