import React from "react";
import usersAPI from "../API/Users/usersAPI";
import usersHelper from "../API/Users/usersHelper";
import AppUser from "@toothfairy/shared-ui/AppUser";

const useUserHelperPreferences = () => {
  const { user, updateUser } = AppUser.useUser();
  const updateUserHelperPreferences = async (user, obj) => {
    const userHelperPreferences = await usersAPI.getUser({ id: user?.id });
    let existingPreferences =
      userHelperPreferences?.data?.getUser?.userHelperPreferences || {};
    if (typeof existingPreferences === "string") {
      existingPreferences = JSON.parse(existingPreferences);
    }
    const newValue = { ...existingPreferences, ...obj };
    usersHelper.updateUser(user?.id, [
      {
        property: "userHelperPreferences",
        value: JSON.stringify(newValue),
      },
    ]);
    updateUser({ ...user, userHelperPreferences: newValue });
  };

  const isUserHelperToBeDisplayed = (property) => {
    const userHelperPreferences = user?.userHelperPreferences || {};
    if (typeof userHelperPreferences === "string") {
      userHelperPreferences = JSON.parse(userHelperPreferences);
    }
    return !userHelperPreferences[property];
  };
  return {
    updateUserHelperPreferences,
    isUserHelperToBeDisplayed,
  };
};

export default useUserHelperPreferences;
