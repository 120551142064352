import AppAssets from "@toothfairy/shared-ui/AppAssets";
import { AppText } from "@toothfairy/shared-ui";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppStateManager from "@toothfairy/shared-ui/AppStateManager";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import { useHistory } from "../../Router";
import React, { useEffect } from "react";
import { View } from "react-native";

const ErrorPage = () => {
  const { currentTheme } = AppTheme.useTheme();
  const { dispatch } = AppStateManager.useAppStateManager();
  const history = useHistory();
  return (
    <View
      style={{
        width: "50%",
        height: "50%",
        marginVertical: "auto",
        marginHorizontal: "auto",

        alignItems: "center",
      }}
    >
      <AppPlaceholderView
        wrapperStyle={{
          height: "auto",
          paddingBottom: 20,
        }}
        size={80}
        color={currentTheme?.theme?.red}
        icon={AppAssets.icons.networkError}
        mainCaption="Ops! Something went wrong. Please try again later."
        mainCaptionStyle={{
          fontSize: currentTheme?.theme?.large_font_size,
        }}
      />
      <AppText
        isClickable
        isHoverable={false}
        style={{
          textDecoration: "underline",
          color: currentTheme?.theme?.primary,
          fontFamily: "boldFont",
        }}
        onPress={() => {
          history.push("/workspaces");
        }}
      >
        Go back to workspaces
      </AppText>
    </View>
  );
};

export default ErrorPage;
