import { AppText } from "@toothfairy/shared-ui";
import AppActivityIndicator from "@toothfairy/shared-ui/AppActivityIndicator";
import AppAssets from "@toothfairy/shared-ui/AppAssets";
import AppDropDown from "@toothfairy/shared-ui/AppDropDown";
import AppModal from "@toothfairy/shared-ui/AppModal";
import AppMultipleSelectInput from "@toothfairy/shared-ui/AppMultipleSelectInput";
import AppPagination from "@toothfairy/shared-ui/AppPagination";
import AppPlaceholderView from "@toothfairy/shared-ui/AppPlaceholderView";
import AppTable from "@toothfairy/shared-ui/AppTable";
import AppTheme from "@toothfairy/shared-ui/AppTheme";
import AppToast from "@toothfairy/shared-ui/AppToast";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useMemo } from "react";
import { Platform, StyleSheet, View, useWindowDimensions } from "react-native";
import ToothFairyButton from "../../Components/ToothFairyComponents/ToothFairyButton";
import useWorkspaces from "../../Hooks/useWorkspaces";
import { UserType } from "../../models";
import ToothFairyChoice from "../ToothFairyComponents/ToothFairyChoice";
import UsersToolbar from "./Leaf/UsersToolBar";

const columns = [
  {
    title: "ID",
    width: 0.3,
  },
  {
    title: "Name",
    width: 0.5,
  },
  {
    title: "Type",
    width: 0.2,
  },
  {
    title: "Actions",
    isAction: true,
  },
];

dayjs.extend(relativeTime);
const UsersInvite = ({
  setShowModal,
  showModal,
  shareInputValue,
  shareMembers,
  handleMemberAddition,
  handleMembersInvite,
  setShareInputValue,
  handleMembersChange,
  shareMembersRef,
  isAdmin,
  forcedValidationError,
  userEmailRequestInProgress,
  handleCreation = () => {},
  handleSearch = () => {},
  usersLoading,
  setShowChoice,
  showChoice,
  handleUserDeletion = () => {},
  handleUserSelection = () => {},
  handleMemberDelete = () => {},
  selectedItem,
  setSelectedItem,
  setSelectedIndex,
  selectedIndex,
  pageSize = 25,
  currentPage = 1,
  usersAccess,
  selectedUserType,
  setSelectedUserType,
  validateSelection = () => {},
  setSelectedUserName,
  selectedUserName,
  emailValidated,
  isEditMode,
  setIsEditMode,
  usersCount,
  changeInProgress,
  userEmailRequestFetchRequest,
  newUsers = [],
}) => {
  const AppToasty = AppToast.useToast();
  const { currentTheme } = AppTheme.useTheme();
  const { getMaxUsers, getActiveWorkspace, isUserAdmin } = useWorkspaces();
  const { width, height } = useWindowDimensions();
  const styles = generateStyles(currentTheme, width);
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.border_color,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
      borderRadius: 20,
      color: currentTheme?.theme?.font_color,
    }),
    input: (base) => ({
      ...base,
      color: currentTheme?.theme?.font_color, // This would change the color of the text inside the input
    }),
    option: (styles, { data }) => {
      let color = currentTheme?.theme?.font_color;
      return {
        ...styles,
        backgroundColor: currentTheme?.theme?.transparent,
        color: currentTheme?.theme?.font_color,
        cursor: "pointer",

        ":active": {
          ...styles[":active"],
          backgroundColor: currentTheme?.theme?.primary,
        },
        ":hover": {
          ...styles[":hover"],
          backgroundColor: currentTheme?.theme?.primary,
        },
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      color: currentTheme?.theme?.font_color,
    }),
    multiValue: (styles, { data }) => {
      let color = currentTheme?.theme?.font_color;
      return {
        ...styles,
        backgroundColor: currentTheme?.theme?.primary,
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: currentTheme?.theme?.font_color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: currentTheme?.theme?.font_color,
      ":hover": {
        backgroundColor: currentTheme?.theme?.primary,
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: currentTheme?.theme?.inputBackgroundColor,
      borderColor: currentTheme?.theme?.lightGray,
      fontFamily: "normalFont",
      fontSize: currentTheme?.theme?.small_font_size,
    }),
  };
  // from UserType generate an array with id and name
  const userTypes = Object.keys(UserType).map((key) => ({
    id: UserType[key],
    name: key,
  }));
  const promiseOptions = (inputValue) => {
    return userEmailRequestFetchRequest(inputValue).then((data) => {
      if (data && data.getUserIDByEmail) {
        // Map the results to the expected format for react-select
        return data.getUserIDByEmail.map((user) => ({
          value: user.id,
          label: user.username || user.user_email,
        }));
      } else {
        return [];
      }
    });
  };

  const _coreModalForInvite = (
    <AppModal
      modalBackdrop={true}
      modalBackdropStatic={false}
      modalStyle={styles.modal}
      modalTitle={isEditMode ? "Edit user" : "Invite user"}
      isCloseButtonVisible
      closeModal={() => setShowModal(false)}
      isModalVisible={showModal}
      transparent={true}
      modalInnerContentStyle={{
        height: "100%",
        minHeight: 400,
        justifyContent: "space-between",
      }}
      modalScrollContainer={{
        height: "100%",
      }}
    >
      <View>
        {!isEditMode ? (
          <>
            <AppText
              wrapperStyle={{
                paddingTop: 20,
                paddingBottom: 10,
                marginLeft: 10,
              }}
              style={{
                fontWeight: "bold",
              }}
            >
              Look up the user you want to invite via email address
            </AppText>
            <AppMultipleSelectInput
              accessibilityLabel="users_inv"
              onSelectionChange={(v) => {
                if (shareMembers?.find((u) => u?.id == v?.value)) {
                  AppToasty.show("User already added", {
                    type: "danger",
                  });
                } else {
                  handleMemberAddition(v);
                }
              }}
              selectedItems={[]}
              labelOption={"username"}
              placeholder="Find users"
              isAsync={true}
              promiseOptions={promiseOptions}
              uniqueKey="id"
              showDropDowns={true}
              readOnlyHeadings={true}
              styles={colourStyles}
            />
          </>
        ) : (
          <>
            <AppText
              wrapperStyle={{
                paddingTop: 20,
                paddingBottom: 10,
                marginLeft: 10,
              }}
            >
              User ID
            </AppText>
            <AppText
              wrapperStyle={{
                paddingTop: 10,
                paddingBottom: 0,
                marginLeft: 10,
              }}
              style={{
                color: currentTheme?.theme?.primary,
                fontWeight: "bold",
              }}
            >
              {selectedItem?.id}
            </AppText>
          </>
        )}
        <View
          style={{
            zIndex: -1,
            height: 290,
            justifyContent: "space-between",
          }}
        >
          <View>
            <AppText
              wrapperStyle={{
                paddingTop: 20,
                paddingBottom: 10,
                marginLeft: 10,
              }}
            >
              Username
            </AppText>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
                flexWrap: "wrap",
              }}
            >
              {newUsers?.map((u) => {
                return (
                  <AppText
                    wrapperStyle={{
                      paddingTop: 10,
                      paddingBottom: 0,
                      marginLeft: 10,
                    }}
                    style={{
                      color: currentTheme?.theme?.primary,
                      fontWeight: "bold",
                    }}
                  >
                    {u?.label || "No user selected"}
                  </AppText>
                );
              })}
            </View>

            <AppText
              wrapperStyle={{
                paddingTop: 20,
                paddingBottom: 10,
                marginLeft: 10,
              }}
            >
              Select the access level of the users
            </AppText>
            <AppDropDown
              dropDownStyle={{
                maxWidth: 300,
                marginLeft: 5,
                borderColor: currentTheme?.theme?.border_color,
              }}
              isElevated={false}
              dropdownIconColor="transparent"
              items={userTypes.filter(
                (u) => u.id !== UserType.ROOT && u.id !== UserType.BILLING_ADMIN
              )}
              propertyLabel="name"
              propertyValue="id"
              selectedValue={selectedUserType}
              onValueChange={(itemValue, itemIndex) => {
                setSelectedUserType(itemValue);
              }}
            />
          </View>

          <ToothFairyButton
            // awesomeIcon={AppAssets.icons.email}
            // {...Styles.minifiedButtonStyle}
            isDisabled={
              !emailValidated ||
              !isAdmin ||
              shareMembers?.length >= getMaxUsers() ||
              changeInProgress
            }
            wrapperStyle={{ alignSelf: "center", paddingBottom: 20 }}
            title={isEditMode ? "Update" : "Invite"}
            onPress={handleMembersInvite}
          />
        </View>
      </View>
    </AppModal>
  );

  const _coreChoice = useMemo(() => {
    return (
      <ToothFairyChoice
        cancelCaption={"userCancelConfirmation"}
        caption="userCancelCaption"
        subCaption="userCancelSubCaption"
        confirmCaption={"userDeleteConfirmation"}
        onCancelButtonPress={() => setShowChoice(false)}
        onConfirmButtonPress={handleMemberDelete}
        setShowChoice={setShowChoice}
        showChoice={showChoice}
      />
    );
  }, [
    setShowChoice,
    showChoice,
    selectedItem,
    setSelectedItem,
    selectedIndex,
    handleUserDeletion,
  ]);
  return (
    <View
      style={{
        minWidth: "100%",
        flex: 1,
      }}
    >
      <UsersToolbar
        handleSearch={handleSearch}
        handleCreation={handleCreation}
        usersCount={usersCount}
      />
      {usersLoading ? (
        <AppActivityIndicator
          backgroundColor={currentTheme?.theme?.softBackgroundColor}
          visible={true}
          containerStyle={{
            backgroundColor: currentTheme?.theme?.transparent,
            minHeight: 250,
          }}
        />
      ) : (
        <>
          {shareMembers?.length > 0 ? (
            <AppTable
              marginRightBeforeActions={30}
              actionButtons
              isItemDeletable={(item) => {
                return isUserAdmin();
              }}
              isItemEditable={(item) => {
                return isUserAdmin();
              }}
              handleDocumentSelection={(item, index) => {
                if (!validateSelection(item)) return;
                setIsEditMode(true);
                handleUserSelection(item);
              }}
              handleDocumentDeletion={(item, index) => {
                if (!validateSelection(item)) return;

                setSelectedItem(item);
                setSelectedIndex(index);
                setShowChoice(true);
              }}
              style={{
                zIndex: -1,
                maxHeight: height - 200,
                paddingRight: 10,
                overflowY: "auto",
                overflowX: "hidden",
              }}
              headers={columns}
              items={shareMembers
                ?.slice(
                  (currentPage - 1) * pageSize,
                  (currentPage - 1) * pageSize + pageSize
                )
                ?.map((u) => {
                  const extendedUser = usersAccess?.find(
                    (obj) => obj?.userID === u?.id
                  );
                  return {
                    id: u?.id || "",
                    label: u?.username || "No name registered yet",
                    type: extendedUser?.userType || "",
                    // qaUrl: u.qaUrl || "",
                    // promptPlaceholder: u.promptPlaceholder,

                    actions: " ",
                  };
                })}
            />
          ) : (
            <AppPlaceholderView
              wrapperStyle={{
                width: "50%",
                height: "50%",
                marginVertical: "auto",
                marginHorizontal: "auto",
              }}
              size={80}
              icon={AppAssets.icons.orbit}
              mainCaption="Nothing to show here yet..."
              subCaption="Add your first team member"
            />
          )}
          {shareMembers?.length > pageSize && (
            <AppPagination
              onPageChange={handlePageChange}
              totalCount={shareMembers?.length}
              siblingCount={1}
              currentPage={currentPage}
              pageSize={pageSize}
              wrapperStyle={{
                marginTop: 10,
                alignSelf: "flex-end",
              }}
            />
          )}
        </>
      )}
      {_coreModalForInvite}
      {_coreChoice}
    </View>
  );
};
const generateStyles = ({ currentTheme, width }) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingVertical: 5,
      paddingHorizontal: 15,
      height: 45,
      zIndex: 10,
      backgroundColor: currentTheme?.theme?.backgroundColor,
      shadowOpacity: 0.3,
      shadowRadius: 4.84,
      opacity: 0.9,
      shadowColor: currentTheme?.theme?.shadowColor,
      shadowOffset: {
        width: 3,
        height: 3,
      },
    },
    modal: {
      maxHeight: "95%",
      marginHorizontal: Platform.OS === "web" ? "auto" : "5%",
      marginVertical: "auto",
      width: Platform.OS === "web" && width > 500 ? "60%" : "98%",
      maxWidth: 600,
    },
    listContentContainer: {
      paddingBottom: 20,
    },
    listHeader: {
      backgroundColor: currentTheme?.theme?.lightGrey,
    },
    listItemContainer: {
      flexDirection: "row",
      padding: 10,
    },
  });

export default UsersInvite;
