import AppCreditCard from "@toothfairy/shared-ui/AppCreditCard";
import { AppText } from "@toothfairy/shared-ui";
import React from "react";
import { View } from "react-native";
import ToothFairyButton from "../../../Components/ToothFairyComponents/ToothFairyButton";

const BillingPaymentMethod = ({
  handlePaymentPortalOpening,
  stripeCustomerData,
  paymentMethod,
}) => {
  return (
    <View>
      {paymentMethod ? (
        <AppCreditCard
          expiry={`${paymentMethod.exp_month}/${paymentMethod.exp_year
            .toString()
            .slice(-2)}`}
          focused="number"
          name={stripeCustomerData.name}
          number={`************${paymentMethod.last4}`}
          issuer={paymentMethod?.brand}
        />
      ) : (
        <AppText>noPaymentCaption</AppText>
      )}

      <ToothFairyButton
        onPress={handlePaymentPortalOpening}
        paddingLeft={10}
        paddingRight={10}
        wrapperStyle={{
          marginHorizontal: "auto",
          marginTop: 20,
        }}
        title="Edit payment method"
      />
    </View>
  );
};

export default BillingPaymentMethod;
