import secureRequest from "@toothfairy/shared-api/secureAPI";
import client from "@toothfairy/shared-api/src/Common/client";
import envConfig from "../../../envConfig";
import Common from "../Common";

const generateRequestPayload = ({
  token,
  workspaceid,
  initial_output,
  user_feedback,
}) => {
  let body = {
    token,
    workspaceid,
    initial_output,
    user_feedback,
  };

  return Common.objectCleaner(body);
};

const requestAutotuning = async ({
  token,
  workspaceid,
  initial_output,
  user_feedback,
}) => {
  let headers;
  if (token) headers = { "x-api-key": token };
  let url = `${envConfig.REACT_APP_AI_REST_ENDPOINT}/autotuner`;

  const body = generateRequestPayload({
    token,
    workspaceid,
    initial_output,
    user_feedback,
  });

  return secureRequest(client, "POST", url, null, body, false, headers);
};

export default {
  requestAutotuning,
};
