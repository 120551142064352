import React from "react";
import { View } from "react-native";
import { AppText } from "@toothfairy/shared-ui";
import AppTheme from "@toothfairy/shared-ui/AppTheme";

const ReferenceLeaf = ({
  id,
  raw_text,
  title,
  external_path,
  type,
  selectedSources,
  chunk_id,
  style,
}) => {
  const { currentTheme } = AppTheme.useTheme();
  const scoring = selectedSources.find(
    (source) => source.id == chunk_id
  )?.scoring;
  return (
    <View
      style={[
        {
          flexDirection: "column",
          borderBottomWidth: 0.5,
          borderBottomColor: currentTheme?.theme?.darkGrey,
        },
        style,
      ]}
    >
      {id ? (
        <>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <AppText
              style={{
                fontWeight: "bold",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                zIndex: -1,
              }}
              color={currentTheme?.theme?.primary}
              wrapperStyle={{
                marginVertical: 10,
                maxWidth: "100%",
                flex: 1,
              }}
            >
              {title}
            </AppText>
            {chunk_id && scoring > 0 && (
              <AppText
                style={{
                  fontSize: currentTheme?.theme?.extra_small_font_size,
                }}
                fontWeight="bold"
              >
                {`Accuracy - ${(scoring * 100)?.toFixed(2)}%`}
              </AppText>
            )}
          </View>

          <AppText
            style={{
              fontSize: currentTheme?.theme?.extra_small_font_size,
            }}
          >
            {raw_text}
          </AppText>
        </>
      ) : (
        <AppText
          wrapperStyle={{
            marginVertical: 10,
          }}
          style={{
            fontSize: currentTheme?.theme?.extra_small_font_size,
          }}
          fontWeight="bold"
          color={currentTheme?.theme?.orange}
        >
          {`Reference has been removed from Knowledge Hub`}
        </AppText>
      )}
    </View>
  );
};

export default ReferenceLeaf;
